
import { gql } from '@apollo/client';

export const CUSTOMER_ADD_DECEASED_TO_ESTATE = gql`
mutation CustomerAddDeceasedToEstate($data: ValidatedEstateUpdateInput!, $where: EstateWhereUniqueInput2) {
  updateUserEstate(data: $data, where: $where) {
    id
    deceased {
      id
      firstName
      lastName
    }
  }
}`
  