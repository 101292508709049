
import { gql } from '@apollo/client';

export const GET_ASSETS_BY_ESTATE_ID = gql`
  
  query GetAssetsByEstateId($estateId: String!) {
  getAssetsByEstateId(estateId: $estateId) {
    assetAccount {
      accountNumber
      assetSubcategory
      institution
    }
    status
    probateJurisdiction
    probateEstate
    inTrust
    id
    finalValue
    estimatedValue
    estateId
    dodValue
    description
    assetCategory
  }
}

`
  