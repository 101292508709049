
import { gql } from '@apollo/client';

export const UPDATE_ONE_DECEASED = gql`
  mutation UpdateOneDeceased($data: DeceasedUpdateInput2!, $where: DeceasedWhereUniqueInput2!) {
    updateOneDeceased(data: $data, where: $where) {
      id
      firstName
      lastName
      podState
      state
      dateOfBirth
      dateOfDeath
    }
  }
`;
  