
import { gql } from '@apollo/client';

export const UPDATE_ONE_USER = gql`
mutation UpdateOneUser($data: UserUpdateInput2!, $where: UserWhereUniqueInput2!) {
  updateOneUser(data: $data, where: $where) {
    id
    firstName
    lastName
    email
  }
}`
  