import { FC, useContext, useEffect, useState } from 'react';
import { useRoleNavigate } from "../../hooks/useRoleNavigate";

import { Container, Typography } from '@mui/material';

import { AccountContext } from "../../providers/Authentication";
import { LayoutContext } from '../../providers/LayoutProvider';
import { ReactComponent as TalkBubbleSvgIcon } from '../../assets/icons/navigation/talk-bubble-icon.svg';
import { ReactComponent as OverviewSvgIcon } from '../../assets/icons/navigation/overview-icon.svg';
import { ReactComponent as HomeViewSvgIcon } from '../../assets/icons/navigation/home-view-icon.svg';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useDecision } from '@optimizely/react-sdk';
import LinkButton from '../DesignSystem/atoms/LinkButton/LinkButton';
import './customerNav.scss';

const CustomerNav: FC = () => {
  const [notificationFlag] = useDecision('notifications_feature', {autoUpdate: true});
  const navigate = useRoleNavigate();
  const {hasAdminAuthorization} = useContext(AccountContext);
  const {showCareTeamConnectButton, setShowCareTeamConnectButton} = useContext(LayoutContext)
  const [ showCareLink, setShowCareLink ] = useState(false)
  const [documentsFeatureFlag] = useDecision('documents_page', { autoUpdate: true });
  useEffect(() =>{
    setShowCareLink(showCareTeamConnectButton)
  }, [showCareTeamConnectButton])
  const handleLinkClick = (path: string) => {
    navigate(path);
  };
  const handleLinkKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement> | React.KeyboardEvent<HTMLSpanElement>, 
    path: string,
  ) => {
    if (e.key === 'Enter') {
      navigate(path);
    }
  };
  const handleCareTeamLinkClick = () => {
    setShowCareTeamConnectButton(false)
    navigate("/careteam")
  }

  return (
    <Container className="pageNavContainer">
      <Container className={`linksContainer__inner`}>
        <LinkButton
          ariaLabel='Go to Home Page'
          variant='borderless'
          handleClick={() => handleLinkClick("/home")}
          handleKeyDown={(e) => handleLinkKeyDown(e, "/home")}
        >
          <div className={"navButtonContainer" + (window.location.pathname.includes("/home") ? " selected" : "")}>
            <HomeViewSvgIcon className='svgNavIcon' />    
            <Typography>Home</Typography>
          </div>
        </LinkButton>

        <LinkButton
          ariaLabel='Go to Estate Page'
          variant='borderless'
          handleClick={() => handleLinkClick("/estate")}
          handleKeyDown={(e) => handleLinkKeyDown(e, "/estate")}
        >
          <div className={"navButtonContainer" + (window.location.pathname.includes("/estate") ? " selected" : "")}>
            <OverviewSvgIcon className='svgNavIcon' />
            <Typography>Estate</Typography>
          </div>
        </LinkButton>

        {/* Customer Document Link  */}
        {!hasAdminAuthorization &&
          <LinkButton
            ariaLabel='Go to Documents Page'
            variant='borderless'
            handleClick={() => handleLinkClick("/documents")}
            handleKeyDown={(e) => handleLinkKeyDown(e, "/documents")}
          >
            <div  className={"navButtonContainer" + (window.location.pathname.includes("/documents") ? " selected" : "")}>
              <DescriptionOutlinedIcon className='svgNavIcon' />
              <Typography>Documents</Typography>
            </div>
          </LinkButton>
        }


        {/* Admin Document Link */}
        { hasAdminAuthorization && 
          <LinkButton
            ariaLabel='Go to Documents Page'
            variant='borderless'
            href='https://alix.account.box.com/login'
            newTab
          >
            <div  className={"navButtonContainer" + (window.location.pathname.includes("/documents") ? " selected" : "")}>
              <DescriptionOutlinedIcon className='svgNavIcon' />
              <Typography>Documents</Typography>
            </div>
          </LinkButton>
        }

        {
          !hasAdminAuthorization && (
            <LinkButton
              ariaLabel='Go to Care Team Page'
              variant='borderless'
              handleClick={() => handleLinkClick("/careteam")}
              handleKeyDown={(e) => handleLinkKeyDown(e, "/careteam")}
            >
              <div className={"navButtonContainer" + (window.location.pathname.includes("/careteam") ? " selected" : "")}>
                <TalkBubbleSvgIcon className='svgNavIcon' />
                <Typography>Care Team</Typography>
              </div>
            </LinkButton>
          )
        }

        {
          notificationFlag.enabled && hasAdminAuthorization && (
            <LinkButton
              ariaLabel='Go to Notifications Center'
              variant='borderless'
              handleClick={() => handleLinkClick("/notifications")}
              handleKeyDown={(e) => handleLinkKeyDown(e, "/notifications")}
            >
              <div className={"navButtonContainer" + (window.location.pathname.includes("/notificationsManagement") ? " selected" : "")}>
                <TalkBubbleSvgIcon className='svgNavIcon' />
                <Typography>Notifications</Typography>
              </div>
            </LinkButton>
          )
        }
      </Container>
    </Container>
  )
}

export default CustomerNav;