import { FC, useContext, useEffect, useState } from "react";
import ActionList from "../../components/ActionList/ActionList";
import { ActionCardStatus, SortOrder, useGetKeyDatesQuery, useGetUserEstateActionCardsQuery, useGetUserEstateQuery } from "../../types/graphql";
import { EstateContext } from "../../providers/EstateProvider";
import { DesktopLayout } from "../../components/Layouts";
import { useScreenWidth } from "../../helpers";
import { AccountContext } from "../../providers/Authentication";
import { ActionContext } from "../../providers/ActionProvider";
import CareTeamCard from "../../components/CareTeamCard/CareTeamCard";
import { LayoutContext } from "../../providers/LayoutProvider";

import styles from './Desktop.module.scss';
import Typography from "../../components/DesignSystem/atoms/Typography/Typography";
import EstateChart from "../../components/DesignSystem/molecules/EstateChart/EstateChart";
import { SubHeader } from "../../components/Headers";
import RecentActivityWrap from "./RecentActivityWrap/RecentActivityWrap";
import { ManageDeceased, ManageUser } from "../../components/Managment";
import KeyDateList from "../../components/DesignSystem/molecules/KeyDateList/KeyDateList";
import { useQuery } from "@apollo/client";
import { GET_USER_ESTATE_QUERY } from "../../apollo/queries/getUserEstateQuery";
import { GET_USER_ESTATE_ACTION_CARDS } from "../../apollo/queries/getUserEstateActionCards";

const DesktopView: FC = () => {
  const {
    currentEstate, setCurrentEstate, selectedEstateId, setDeceased, setRefetchCurrentEstate, deceased,
  } = useContext(EstateContext);
  const { showAdminTools } = useContext(AccountContext);
  const {
    setActions, setRefetchActionList, setActionsLoading,
  } = useContext(ActionContext);
  const screenWidth = useScreenWidth()
  const [keyDates, setKeyDates] = useState<any[]>([])

  const { setShowCareTeamConnectButton } = useContext(LayoutContext)

  const statusFilter = showAdminTools ?
    [ActionCardStatus.New,
      ActionCardStatus.Draft,
      ActionCardStatus.InProgress,
      ActionCardStatus.Completed,
    ] :
    [
      ActionCardStatus.New,
      // ActionCardStatus.InProgress,
    ]
  const {
    refetch: actionsRefetch, data: actionData, loading: actionsLoading,
  } = useQuery(GET_USER_ESTATE_ACTION_CARDS, {
    variables: {
      estateId: selectedEstateId,
      where: { status: { in: statusFilter } },
      orderBy: [
        { status: SortOrder.Asc },
        { updatedAt: SortOrder.Desc },
      ],
    },
    onError: (error) => {
      alert(error)
    },
  });

  
  const { data: estateData, refetch: estateRefetch } = useQuery(GET_USER_ESTATE_QUERY, {
    variables: { estateId: selectedEstateId },
    onError: (error) => {
      console.error('Error in EstateView', error);
    },
  })

  useEffect(() => {
    if (screenWidth < 800) setShowCareTeamConnectButton(false)
    // insure scrolling is enabled
    document.body.style.overflow = 'auto';
  }, [])

  useEffect(() => {
    const actionCards = actionData?.getUserEstate?.actionCards
    setActions(actionCards)
    const estateDeceased = actionData?.getUserEstate?.deceased
    estateDeceased && setDeceased(estateDeceased)
    setActionsLoading(actionsLoading)
  }, [actionData, actionsLoading])

  // // Get data from the backend
  // const {refetch: estateRefetch, data: estateData} = useGetUserEstateQuery(
  //   {
  //     variables: {estateId: selectedEstateId},
  //     onError: (error) => {
  //       console.error('Error in EstateView', error);
  //     },
  //   }, 
  // );

  useEffect(() => {
    if (estateData?.getUserEstate?.id) {
      if (!estateData.getUserEstate.deceased && deceased) {
        estateData.getUserEstate.deceased = deceased
      }
      setCurrentEstate(estateData)
      const estateDeceased = estateData?.getUserEstate?.deceased
      estateDeceased && setDeceased({ ...estateDeceased })
    }
  }, [estateData]);

  useEffect(() => {
    setRefetchCurrentEstate(() => estateRefetch)
    setRefetchActionList(() => actionsRefetch)
  }, [actionsRefetch, estateRefetch]);

  useGetKeyDatesQuery(
    {
      variables: { estateId: selectedEstateId },
      onCompleted: (data) => {
        setKeyDates(data.getUserEstate?.keyDates || [])
      },
    },
  )

  return (
    <DesktopLayout
      adminDeceasedTools={true}
      adminCustomerTools={true}
      includeSubHeader={false}
    >
      <div className={styles.homeViewContainer}>
        <div className={styles.header}>
          <SubHeader />
          {showAdminTools &&
            <div className={styles.managementTools}>
              <ManageDeceased />
              <ManageUser />
            </div>
          }
          <EstateChart className={styles.estateChart} />
        </div>
        <ActionList className={styles.actionList} showHeader direction="horizontal" gap={32} listItemWidth={'48%'} listItemMaxWidth={`calc(50% - ${32}px`} flex={`1 0 calc(50% - ${32}px)`} listItemMinWidth={'387px'} />
        <RecentActivityWrap
          className={styles.recentActivityWrap}
          listItemMaxWidth={`387px`}
          gap={20}
        />

        {keyDates && keyDates.length > 0 && (
          <div className={styles.keyDatesContainer}>
            <Typography font="UI1" variant="H5" className={styles.keyDatesHeader}>Key Dates</Typography>
            <KeyDateList
              testId='keyDateList'
              className={styles.keyDateList}
              wrap={true}
              gap={20}
              direction='horizontal'
              listItemWidth='387px'
            />
          </div>
        )}
        <CareTeamCard className={styles.careTeam} />
      </div>
    </DesktopLayout>
  );
};

export default DesktopView;