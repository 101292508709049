
import { gql } from '@apollo/client';

export const UPDATE_ONE_CONTENT = gql`
mutation UpdateOneContent($data: ContentUpdateInput2!, $where: ContentWhereUniqueInput2!) {
  updateOneContent(data: $data, where: $where) {
    id
    header
    status
    completeBy
    completedAt
  }
}`
  