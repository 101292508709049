
import { gql } from '@apollo/client';

export const GET_USER_ESTATE_ACTION_CARDS = gql`
  query GetUserEstateActionCards($estateId: String, $where: ActionCardWhereInput2,  $orderBy: [ActionCardOrderByWithRelationInput2!]) {
    getUserEstate(estateId: $estateId) {
      id
      deceased {
        id
        firstName
        lastName
        podState
        state
        dateOfBirth
        dateOfDeath
      }
      componentBreakDown {
        type
        subType
        sum
        percentage
        count
        total
      }
      actionCards(where: $where, orderBy: $orderBy) {
        id
        estateId
        componentId
        completedAt
        estateDetailId
        type
        status
        name
        description
        encryptedReply
        locked
        assignee
        formFieldName
        appointmentTime
        appointmentLocation
        calendarIcs
        calendarLink
        documentDownloadUrl
        documentUploadUrl
        documentSignUrl
        mapUrl
        createdAt
        updatedAt
        component {
          id
          name
          subType
          type
          accountNumber
          descriptor
        }
        task {
          name
          shortName
        }
        estateDetail {
          id
          name
          updatedAt
          value
        }
      }
    }
  }
`
  