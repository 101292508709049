import { FC, useContext, useEffect, useState } from 'react'
import './index.scss'
import Typography from '../../../DesignSystem/atoms/Typography/Typography'
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton'
import { LayoutContext } from '../../../../providers/LayoutProvider'
import { ContentCompleteByType, ContentStatus, GetOneContentDocument, SortOrder, useDeleteOneContentMutation, useGetOneContentQuery } from '../../../../types/graphql'
import { ActionContext } from '../../../../providers/ActionProvider'
import { EstateContext } from '../../../../providers/EstateProvider'
import { useParams } from 'react-router-dom-v5-compat'
import { EstateComponentContext } from '../../../../providers/EstateComponentProvider'
import { ApolloError, useQuery } from '@apollo/client'

interface DeleteOneContentFormProps {
  testId?: string
}


type ContentQueryReturn = {
  __typename?: "Content" | undefined;
  id: string;
  completeByType: ContentCompleteByType;
  completeBy: any;
  displayDate: string;
  body: string;
  componentId?: string | null | undefined;
  header: string;
  status: ContentStatus;
} 

const DeleteOneContentForm: FC<DeleteOneContentFormProps> = ({testId}) => {
  const {
    setDisplayModal,
    setModalContents,
  } = useContext(LayoutContext)
  const { estateComponentDetailsListRefetch } = useContext(EstateComponentContext)

  // const {estateComponentId} = useParams();
  const { selectedComponentContentCardId } = useContext(EstateComponentContext)
  const [entity, setEntity] = useState<ContentQueryReturn | null | undefined>();
  
  const {refetch} = useQuery(GetOneContentDocument, {
    variables: {where: {id: selectedComponentContentCardId}},
    onCompleted: (data) => {
      setEntity(data.getContent)
    },
    onError: (e: ApolloError) => {
      console.error("Failed getOneContentQuery call");
      console.error(e.message);
      alert(e.message);
    },
  });

  useEffect(() => {
    setEntity(null)
    refetch()
  }, [selectedComponentContentCardId])


  
  const [ deleteOneContentMutation ] = useDeleteOneContentMutation({
    variables: {where: {id: selectedComponentContentCardId}},
    onCompleted: (data) => {
      // handle any actions needed on complete
      estateComponentDetailsListRefetch()
      alert(`Successfully deleted content name: ${entity?.header}`);
    },
    onError: (error) => {
      // handle errors as desired
      alert(error)
    },
  })

  const handleDeleteClick = () => {
    //delete mutation here
    deleteOneContentMutation()
    setDisplayModal(false)
  }

  const handleCancelClick = () => {
    setDisplayModal(false)
    setModalContents(<></>)
  }

  return (
    <div data-testid={testId} className='deleteOneContentFormContainer'>
      <Typography font='UI1' variant='H3' className='formHeader deleteOneContentFormHeader'>
        <span>Delete Detail Card</span>
      </Typography>
      <div className='formBody'>
        <Typography font='UI1' variant='P1'>
          <span>You are deleting:</span>
        </Typography>
        <Typography font='UI1' variant='P1'>
          <strong>
            <span>{entity?.header}</span>
          </strong>
        </Typography>
        <Typography font='UI1' variant='P1'>
          <strong>
            <span>{entity?.displayDate}</span>
          </strong>
        </Typography>
        <Typography font='UI1' variant='P1'>
          <span>Are you sure you want to delete this content?</span>
        </Typography>
        <Typography font='UI1' variant='P1'>
          <span>Deletion is permanent and cannot be undone.</span>
        </Typography>
      </div>
      <div className='actionButtonContainer'>
        <ActionButton ariaLabel={'delete the selected content card'} variant='solid' handleClick={handleDeleteClick}>
          Delete
        </ActionButton>
        <ActionButton ariaLabel={'cancel delete'} handleClick={handleCancelClick}>
          Cancel
        </ActionButton>
      </div>
    </div>
  )
}

export default DeleteOneContentForm