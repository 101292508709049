import { FC, useContext, useEffect } from 'react'
import './ComponentDetailCardList.scss'
import { Content2, SortOrder, useGetEstateComponentQuery } from '../../../../types/graphql'
import List from '../../atoms/List/List'
import { EstateComponentContext } from '../../../../providers/EstateComponentProvider'
import ContentCard from '../ContentCard/ContentCard'
import useAutoScroll from '../../../../hooks/useAutoScroll'
import useGetParamValue from '../../../../hooks/useGetParamValue'

interface ComponentDetailCardListProps {
  testId?: string,
  estateComponentId: string,
}

const ComponentDetailCardList: FC<ComponentDetailCardListProps> = ({
  testId,
  estateComponentId,
}) => {
  const { setEstateComponentDetailsListRefetch } = useContext(EstateComponentContext);
  const contentId = useGetParamValue('contentId')

  // add query to get component details aka component.contents
  const {data, refetch:contentRefetch} = useGetEstateComponentQuery({
    variables: {
      componentId: estateComponentId,
      orderBy: [
        {completeBy: SortOrder.Asc},
      ],
    },
    onError: (error) => {
      console.error(error)
      alert(error)
    },
  });

  useEffect(() => {
    contentRefetch && setEstateComponentDetailsListRefetch(() => contentRefetch)
  }, [contentRefetch])

  useEffect(() => {
    if (!data) return;
    if (contentId) useAutoScroll( contentId, 'showFull')
    else  useAutoScroll( 'COMPLETED', 'showBottom')
  }, [ data]);

  return (
    <div data-testid={testId} className='componentDetailCardListContainer'>
      <List
        data={data?.getEstateComponent?.contents}
        gap={16}
        wrap={false}
        renderItem={(content: Content2, index: number, parentRef: any) => (
          <ContentCard
            contentId={content.id}
            status={content.status}
            user={content.user}
            title={content.header}
            body={content.body}
            date={content.displayDate}
            includeDate={true}
            parentRef={parentRef}
            renderOpen={contentId === `id-${content.id}`}
          />
        )}
      />
    </div>
  )
}

export default ComponentDetailCardList
