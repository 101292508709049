import { FC, useContext, useEffect, useState } from 'react'
import './index.scss';
import formData from './formData.json'

import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { ActionCardUpdateInput2, AssigneeType, ActionCardType, ActionCardStatus } from '../../../../types/graphql';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { LayoutContext } from '../../../../providers/LayoutProvider';
import { Autocomplete, Container, TextField, Typography, Switch } from '@mui/material';
import { handleNestedValues } from '../../formHelpers'
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton';
import { ActionContext } from '../../../../providers/ActionProvider';
import { EstateContext } from '../../../../providers/EstateProvider';
import { formatActionName } from '../../../../helpers/functions'
import LoadingOverlay from '../../../../views/LoadingOverlay';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import the styles
import FolderSelector from '../../formikInputs/FolderSelector/FolderSelector';
import { toast } from 'react-toastify';
import { GET_USER_ESTATE_QUERY } from '../../../../apollo/queries/getUserEstateQuery';
import { EstateComponent } from '../../../../views/EstateView';
import { UPDATE_ONE_ACTION_CARD } from '../../../../apollo/mutations/updateOneActionCard';
import { CREATE_FOLDER } from '../../../../apollo/mutations/createFolder';

type UpdateOneActionCardFormProps = {
  testId?: string
}

const UpdateOneActionCardForm: FC<UpdateOneActionCardFormProps> = ({ testId }) => {
  // provide the update entity as this variable
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const {
    currentAction: entity,
    refetchActionList,
    setCurrentAction,
  } = useContext(ActionContext)
  const {
    setDisplayModal,
    setModalContents,
  } = useContext(LayoutContext)
  const { selectedEstateId } = useContext(EstateContext)

  const [updateOneActionCardMutation] = useMutation(UPDATE_ONE_ACTION_CARD);
  const [createFolderMutation] = useMutation(CREATE_FOLDER);
  const [editFolder, setEditFolder] = useState(false);
  const [options, setOptions] = useState<any>({
    "estate": [
      {
        "id": selectedEstateId,
        "displayValue": "Current Estate",
      },
    ],
    "component": [],
    "assignee": [
      {
        "submitValue": "CareTeam",
        "displayValue": "CareTeam",
      },
      {
        "submitValue": "Customer",
        "displayValue": "Customer",
      },
      {
        "submitValue": "OpsTeam",
        "displayValue": "OpsTeam",
      },
      {
        "submitValue": "ServiceAccount",
        "displayValue": "ServiceAccount",
      },
    ],
    "type": [
      {
        "submitValue": "DocumentSign",
        "displayValue": "Document Sign",
      },
      {
        "submitValue": "FormFields",
        "displayValue": "Receive Info",
      },
      {
        "submitValue": "Manual",
        "displayValue": "Send Info",
      },
      {
        "submitValue": "ScheduleAppointment",
        "displayValue": "Schedule Appointment",
      },
    ],
    "status": [
      {
        "submitValue": "CANCELED",
        "displayValue": "Canceled",
      },
      {
        "submitValue": "COMPLETED",
        "displayValue": "Completed",
      },
      {
        "submitValue": "DRAFT",
        "displayValue": "Draft",
      },
      {
        "submitValue": "IN_PROGRESS",
        "displayValue": "InProgress",
      },
      {
        "submitValue": "NEW",
        "displayValue": "New",
      },
    ],
  })

  useEffect(() => {
    refetchComponents()
  }, [entity])

  
  const {
    loading: componentsLoading,
    refetch: refetchComponents,
  } = useQuery(GET_USER_ESTATE_QUERY, {
    variables: { estateId: selectedEstateId },
    onCompleted: (data) => {
      const c = data.getUserEstate?.estateComponents.map((component: EstateComponent) => {
        return ({
          id: component.id,
          displayValue: formatActionName(component),
        })
      })
      setOptions((options: any) => {
        const newOptions = {
          ...options,
          component: c,
        }
        return newOptions
      })
    },
    onError: (e) => {
      console.error('Error in UpdateOneActionCard', e);
    },
  });


  const handleSubmit = async (
    values: ActionCardUpdateInput2,
    { resetForm }: { resetForm: any },
  ) => {
    if (!selectedEstateId) return;
    const nestedValues = handleNestedValues(values, formData) as ActionCardUpdateInput2

    // Create the folder in S3 if showCreateFolder is true
    if (showCreateFolder && nestedValues.documentUploadUrl) {
      const createFolderResponse = await createFolderMutation({
        variables: {
          data: {
            estateId: selectedEstateId,
            folderName: nestedValues.documentUploadUrl, 
          }, 
        },
      });

      if (createFolderResponse.errors) {
        console.error('Error creating folder in S3');
        console.error(createFolderResponse.errors);
        toast('Error creating folder in S3');
        return;
      }
    }

    updateOneActionCardMutation({
      variables: {
        data: nestedValues,
        where: { id: entity?.id },
      },
      onCompleted: () => {
        refetchActionList()
        setDisplayModal(false);
        setCurrentAction(null)
        resetForm();
      },
      onError: (e: ApolloError) => {
        console.error("Failed updateOneActionCardMutation call");
        console.error(e.message);
        resetForm();
        alert(e.message);
      },
    });
  }

  const initialValues = {
    estate: entity?.estateId || '',
    component: entity?.componentId || '',
    assignee: entity?.assignee || AssigneeType.Customer,
    type: entity?.type || ActionCardType.DocumentSign,
    status: entity?.status || ActionCardStatus.Draft,
    name: entity?.name || '',
    description: entity?.description || '',
    encryptedReply: entity?.encryptedReply || false,
    locked: entity?.locked || false,
    formFieldName: entity?.formFieldName || '',
    appointmentLocation: entity?.appointmentLocation || '',
    appointmentTime: entity?.appointmentTime || '',
    calendarIcs: entity?.calendarIcs || '',
    calendarLink: entity?.calendarLink || '',
    documentUploadUrl: entity?.documentUploadUrl || '',
    documentSignUrl: entity?.documentSignUrl || '',
    mapUrl: entity?.mapUrl || '',
  };

  const validationSchema = Yup.object().shape({
    estate: Yup.string(),
    component: Yup.string(),
    assignee: Yup.string(),
    type: Yup.string(),
    status: Yup.string(),
    name: Yup.string(),
    description: Yup.string(),
    encryptedReply: Yup.boolean(),
    locked: Yup.boolean(),
    formFieldName: Yup.string().nullable(),
    appointmentLocation: Yup.string(),
    appointmentTime: Yup.date(),
    calendarIcs: Yup.string(),
    calendarLink: Yup.string(),
    documentUploadUrl: Yup.string().nullable(),
    documentSignUrl: Yup.string(),
    mapUrl: Yup.string(),
  }).test({
    name: "atLeastOneRequired",
    test: function (values) {
      if (values.type === ActionCardType.FormFields) {
        const isValid = ["formFieldName", "documentUploadUrl"].some(field => !!((values as { [key: string]: any })[field]));

        if (isValid) return true;
        // You have to do the create error with path for formik form
        return this.createError({
          path: "documentUploadUrl",
          message: "At least one field must be set",
        })
      } else {
        return true
      }
    },
  });

  return (
    <div data-testid={testId} className='updateOneActionCardFormContainer'>
      {componentsLoading ?
        <LoadingOverlay />
        :
        <>
          <Typography variant='h4' className='updateOneActionCardFormHeader'>
            Update One Action Card Form
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors, touched, submitCount, setFieldValue, values,
            }) => (
              <Form noValidate>
                <Container className="formContainer">
                  <Typography variant="h5">Relationships</Typography>
                  <Container className="formSection">

                    <div className={"inputWrapper estateFieldWrapper dropdownInputWrapper"} key={"estate0"} data-testid={testId}>
                      <Autocomplete
                        options={options.estate || []}
                        getOptionLabel={(option: { id: number, displayValue: string }) => option.displayValue}
                        renderInput={(params: any) =>
                          <Field
                            {...params}
                            className={"estateInput fieldInput dropdownInput"}
                            required={false}
                            id={"estate"}
                            label={"Estate"}
                            name={"estate"}
                            type={"text"}
                            autoComplete={"estate"}
                            as={TextField}
                            error={submitCount > 0 && touched.estate && !!errors.estate}
                          />
                        }
                        defaultValue={options.estate[0]}
                        onChange={(_, value) => setFieldValue("estate", value?.id)}
                      />
                      <ErrorMessage name={"estate"} component="div" className="inputErrorMessage" />
                    </div>

                    <div className={"inputWrapper componentFieldWrapper dropdownInputWrapper"} key={"component1"} data-testid={testId}>
                      <Autocomplete
                        options={options.component || []}
                        getOptionLabel={(option: { id: number, displayValue: string }) => option.displayValue}
                        renderInput={(params: any) =>
                          <Field
                            {...params}
                            className={"componentInput fieldInput dropdownInput"}
                            required={false}
                            id={"component"}
                            label={"Component"}
                            name={"component"}
                            type={"text"}
                            autoComplete={"component"}
                            as={TextField}
                            error={submitCount > 0 && touched.component && !!errors.component}
                          />
                        }
                        defaultValue={options.component.find((option: any) => option.id === initialValues.component)}
                        onChange={(_, value) => {
                          setFieldValue("component", value?.id)
                        }}
                      />
                      <ErrorMessage name={"component"} component="div" className="inputErrorMessage" />
                    </div>

                    <div className={"inputWrapper assigneeFieldWrapper dropdownInputWrapper"} key={"assignee3"} data-testid={testId}>
                      <Autocomplete
                        disabled
                        options={options.assignee || []}
                        getOptionLabel={(option: { submitValue: string, displayValue: string }) => option.displayValue}
                        renderInput={(params: any) =>
                          <Field
                            {...params}
                            className={"assigneeInput fieldInput dropdownInput"}
                            required={false}
                            id={"assignee"}
                            label={"Assignee"}
                            name={"assignee"}
                            type={"text"}
                            autoComplete={"assignee"}
                            as={TextField}
                            error={submitCount > 0 && touched.assignee && !!errors.assignee}
                          />
                        }
                        defaultValue={options.assignee.find((option: any) => option.submitValue === initialValues.assignee)}
                        onChange={(_, value) => setFieldValue("assignee", value?.submitValue)}
                      />
                      <ErrorMessage name={"assignee"} component="div" className="inputErrorMessage" />
                    </div>
                  </Container>

                  <hr />

                  <Typography variant="h5">Required Information</Typography>
                  <Container className="formSection">

                    <div className={"inputWrapper typeFieldWrapper dropdownInputWrapper"} key={"type4"} data-testid={testId}>
                      <Autocomplete
                        options={options.type || []}
                        getOptionLabel={(option: { submitValue: string, displayValue: string }) => option.displayValue}
                        renderInput={(params: any) =>
                          <Field
                            {...params}
                            className={"typeInput fieldInput dropdownInput"}
                            required={false}
                            id={"type"}
                            label={"Type"}
                            name={"type"}
                            type={"text"}
                            autoComplete={"type"}
                            as={TextField}
                            error={submitCount > 0 && touched.type && !!errors.type}
                          />
                        }
                        defaultValue={options.type.find((option: any) => option.submitValue === initialValues.type)}
                        onChange={(_, value) => setFieldValue("type", value?.submitValue)}
                      />
                      <ErrorMessage name={"type"} component="div" className="inputErrorMessage" />
                    </div>

                    <div className={"inputWrapper statusFieldWrapper dropdownInputWrapper"} key={"status5"} data-testid={testId}>
                      <Autocomplete
                        options={options.status || []}
                        getOptionLabel={(option: { submitValue: string, displayValue: string }) => option.displayValue}
                        renderInput={(params: any) =>
                          <Field
                            {...params}
                            className={"statusInput fieldInput dropdownInput"}
                            required={false}
                            id={"status"}
                            label={"Status"}
                            name={"status"}
                            type={"text"}
                            autoComplete={"status"}
                            as={TextField}
                            error={submitCount > 0 && touched.status && !!errors.status}
                          />
                        }
                        defaultValue={options.status.find((option: any) => option.submitValue === initialValues.status)}
                        onChange={(_, value) => setFieldValue("status", value?.submitValue)}
                      />
                      <ErrorMessage name={"status"} component="div" className="inputErrorMessage" />
                    </div>

                    <div className={"inputWrapper nameFieldWrapper textInputWrapper"} key={"name6"} data-testid={testId}>
                      <Field
                        className={"nameInput fieldInput textInput"}
                        required={false}
                        id={"name"}
                        label={"Name"}
                        name={"name"}
                        type={"text"}
                        autoComplete={"name"}
                        as={TextField}
                        error={submitCount > 0 && touched.name && !!errors.name}
                      />
                      <ErrorMessage name={"name"} component="div" className="inputErrorMessage" />
                    </div>

                    <div className={"inputWrapper descriptionFieldWrapper textInputWrapper"} key={"description7"} data-testid={testId}>
                      <label>Description</label>
                      <ReactQuill
                        className={"descriptionInput fieldInput textInput"}
                        value={values.description || ""}
                        onChange={(content) => setFieldValue("description", content)}
                        id={"description"}
                      />
                      {errors.description && touched.description && <div className="inputErrorMessage">{errors.description}</div>
                      }
                    </div>
                  </Container>

                  <hr />

                  <Typography variant="h5">Additional Information</Typography>
                  <Container className="formSection">

                    <div className={"inputWrapper lockedFieldWrapper booleanInputWrapper"} key={"locked0"} data-testid={testId}>
                      <Typography className="booleanLabel" variant="body1" align="left">Locked</Typography>
                      <Typography className="booleanNegative">No</Typography>
                      <Field
                        className={"lockedInput fieldInput booleanInput"}
                        required={false}
                        id={"locked"}
                        name={"locked"}
                        type={"checkbox"}
                        as={Switch}
                        error={submitCount > 0 && touched.locked && !!errors.locked}
                      />
                      <Typography className="booleanPositive">Yes</Typography>
                      <ErrorMessage name={"locked"} component="div" className="inputErrorMessage" />
                    </div>

                    {
                      values.type === ActionCardType.FormFields &&
                      <div className={"inputWrapper encryptedReplyFieldWrapper booleanInputWrapper"} key={"encryptedReply8"} data-testid={testId}>
                        <Typography className="booleanLabel" variant="body1" align="left">Encrypted Reply</Typography>
                        <Typography className="booleanNegative">No</Typography>
                        <Field
                          className={"encryptedReplyInput fieldInput booleanInput"}
                          required={false}
                          id={"encryptedReply"}
                          name={"encryptedReply"}
                          type={"checkbox"}
                          as={Switch}
                          error={submitCount > 0 && touched.encryptedReply && !!errors.encryptedReply}
                        />
                        <Typography className="booleanPositive">Yes</Typography>
                        <ErrorMessage name={"encryptedReply"} component="div" className="inputErrorMessage" />
                      </div>
                    }

                    {
                      values.type === ActionCardType.FormFields &&
                      <div className={"inputWrapper formFieldNameFieldWrapper textInputWrapper"} key={"formFieldName9"} data-testid={testId}>
                        <Field
                          className={"formFieldNameInput fieldInput textInput"}
                          required={false}
                          id={"formFieldName"}
                          label={"Form Field Name"}
                          name={"formFieldName"}
                          type={"text"}
                          autoComplete={"formFieldName"}
                          as={TextField}
                          error={submitCount > 0 && touched.formFieldName && !!errors.formFieldName}
                          value={values.formFieldName || ''}
                          onChange={(event: any) => {
                            // Convert empty string back to null for form values
                            setFieldValue('formFieldName', event.target.value ? event.target.value : null);
                          }}
                        />
                        <ErrorMessage name={"formFieldName"} component="div" className="inputErrorMessage" />
                      </div>
                    }

                    {
                      values.type === ActionCardType.Manual &&
                      <div className={"inputWrapper appointmentLocationFieldWrapper textInputWrapper"} key={"appointmentLocation10"} data-testid={testId}>
                        <label>Appointment Details</label>
                        <ReactQuill
                          className={"appointmentLocationInput fieldInput textInput"}
                          value={values.appointmentLocation || ""}
                          onChange={(content) => setFieldValue("appointmentLocation", content)}
                        />
                        {errors.appointmentLocation && touched.appointmentLocation && <div className="inputErrorMessage">{errors.appointmentLocation}</div>
                        }
                      </div>
                    }

                    {
                      values.type === ActionCardType.ScheduleAppointment &&
                      <div className={"inputWrapper calendarLinkFieldWrapper textInputWrapper"} key={"calendarLink13"} data-testid={testId}>
                        <Field
                          className={"calendarLinkInput fieldInput textInput"}
                          required={false}
                          id={"calendarLink"}
                          label={"Calendar Link"}
                          name={"calendarLink"}
                          type={"text"}
                          autoComplete={"calendarLink"}
                          as={TextField}
                          error={submitCount > 0 && touched.calendarLink && !!errors.calendarLink}
                        />
                        <ErrorMessage name={"calendarLink"} component="div" className="inputErrorMessage" />
                      </div>
                    }

                    {/* By default, just show "Current Folder" text */}
                    {!editFolder && values.type === ActionCardType.DocumentUpload && (
                      <div className={"inputWrapper textInputWrapper currentFolderContainer"}>
                        <Typography variant="body1">{`Current Folder: "${values.documentUploadUrl}"`}</Typography>
                        <ActionButton
                          ariaLabel={'Change Folder'}
                          variant="solid"
                          className="changeFolderButton transparent"
                          handleClick={() => {
                            setEditFolder(true);
                          }}
                        >
                          Change Folder
                        </ActionButton>
                      </div>
                    )}

                    {/* if the user has decided to change the folder show the following */}
                    {editFolder && (
                      <>
                        {(values.type === ActionCardType.DocumentUpload ||
                          values.type === ActionCardType.FormFields) &&
                          !showCreateFolder &&
                          <div className={"inputWrapper documentUploadUrlFieldWrapper textInputWrapper"} key={"documentUploadUrl15"} data-testid={testId}>
                            <FolderSelector
                              fieldName="documentUploadUrl"
                              labelText="Choose Folder to Upload to"
                              initialValue={values.documentUploadUrl}
                            />
                            <ActionButton
                              ariaLabel={'Show Create Folder Field'}
                              variant="solid"
                              className="createFolderButton transparent"
                              handleClick={() => {
                                setShowCreateFolder(!showCreateFolder);
                              }}
                            >
                              + Create a new folder
                            </ActionButton>
                          </div>
                        }

                        {
                          values.type === ActionCardType.DocumentUpload && showCreateFolder &&
                          <div className={"inputWrapper documentUploadUrlFieldWrapper textInputWrapper"} key={"documentUploadUrl17"} data-testid={testId}>
                            <Field
                              className={"documentUploadUrlInput fieldInput textInput"}
                              required={false}
                              id={"documentUploadUrl"}
                              label={"Type Name of Folder to Create for Upload"}
                              name={"documentUploadUrl"}
                              type={"text"}
                              autoComplete={"documentUploadUrl"}
                              as={TextField}
                              error={submitCount > 0 && touched.documentUploadUrl && !!errors.documentUploadUrl}
                            />
                            <ErrorMessage name={"documentUploadUrl"} component="div" className="inputErrorMessage" />
                            <ActionButton
                              ariaLabel={'Show Create Folder Field'}
                              variant="solid"
                              className="createFolderButton transparent"
                              handleClick={() => {
                                setShowCreateFolder(!showCreateFolder);
                              }}
                            >
                              Use Existing Folder
                            </ActionButton>
                          </div>
                        }
                      </>
                    )}

                    {
                      values.type === ActionCardType.DocumentSign &&
                      <div className={"inputWrapper documentSignUrlFieldWrapper textInputWrapper"} key={"documentSignUrl16"} data-testid={testId}>
                        <Field
                          className={"documentSignUrlInput fieldInput textInput"}
                          required={false}
                          id={"documentSignUrl"}
                          label={"Document Sign Url"}
                          name={"documentSignUrl"}
                          type={"text"}
                          autoComplete={"documentSignUrl"}
                          as={TextField}
                          error={submitCount > 0 && touched.documentSignUrl && !!errors.documentSignUrl}
                        />
                        <ErrorMessage name={"documentSignUrl"} component="div" className="inputErrorMessage" />
                      </div>
                    }

                    {
                      values.type === ActionCardType.Manual &&
                      <div className={"inputWrapper mapUrlFieldWrapper textInputWrapper"} key={"mapUrl17"} data-testid={testId}>
                        <Field
                          className={"mapUrlInput fieldInput textInput"}
                          required={false}
                          id={"mapUrl"}
                          label={"Map Url"}
                          name={"mapUrl"}
                          type={"text"}
                          autoComplete={"mapUrl"}
                          as={TextField}
                          error={submitCount > 0 && touched.mapUrl && !!errors.mapUrl}
                        />
                        <ErrorMessage name={"mapUrl"} component="div" className="inputErrorMessage" />
                      </div>
                    }
                  </Container>

                </Container>

                <Container className="buttonsContainer">
                  <ActionButton
                    ariaLabel="Submit form"
                    variant="outline"
                    className="submitFormButton"
                    handleClick={() => {
                      setDisplayModal(false);
                      setModalContents(null);
                    }}
                  >
                    Cancel
                  </ActionButton>

                  <ActionButton
                    type="submit"
                    ariaLabel="Submit form"
                    variant="solid"
                    className="submitFormButton"
                  >
                    Submit
                  </ActionButton>
                </Container>
              </Form>
            )}
          </Formik>
        </>
      }
    </div>
  )
}

export default UpdateOneActionCardForm