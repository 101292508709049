import { FC, useContext, useState } from 'react'
import './index.scss';
import formData from './formData.json'

import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { UserUpdateInput2, useGetCurrentUserLazyQuery } from '../../../../types/graphql';
import { ApolloError, useMutation } from '@apollo/client';
import { LayoutContext } from '../../../../providers/LayoutProvider';
import { Container, TextField } from '@mui/material';
import { handleNestedValues, USStatesAndTerritoriesAbbreviations } from '../../formHelpers'
import Typography from '../../../DesignSystem/atoms/Typography/Typography';
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton';
import { UserContext } from '../../../../providers/UserProvider';
import { UPDATE_ONE_USER } from '../../../../apollo/mutations/updateOneUser';

  type UpdateAdminUserFormProps = {
    testId?: string
  }

const UpdateAdminUserForm: FC<UpdateAdminUserFormProps> = ({testId}) => {
  const [getCurrentUser, {loading}] = useGetCurrentUserLazyQuery(); 
  const { currentUser, setCurrentUser } = useContext(UserContext)
  // provide the update entity as this variable
  const entity:any = currentUser?.getCurrentUser 
      
  const { setDisplayModal, setModalContents } = useContext(LayoutContext)
  const [ updateOneUserMutation ] = useMutation(UPDATE_ONE_USER);
  const [ options, setOptions] = useState<any>({})

  const handleSubmit = async (
    values: UserUpdateInput2,
    { resetForm }: { resetForm: any },
  ) => {
    //pull in related mutation
    const nestedValues = handleNestedValues(values, formData) as UserUpdateInput2
    console.log('nestedValues', nestedValues)
    updateOneUserMutation({
      variables: {
        data: nestedValues,
        where: {id: entity?.id},
      },
      onCompleted: (data) => {
        getCurrentUser({
          onCompleted: (data) => {
            setCurrentUser(data)
          },
        })
        setDisplayModal(false);
        resetForm();
      }, 
      onError: (e: ApolloError) => {
        console.error("Failed updateOneUserMutation call");
        console.error(e.message);
        resetForm();
        alert(e.message);
      },
    });
  }

  const initialValues = {
    firstName: entity?.firstName || '',
    lastName: entity?.lastName || '',
    avatar: entity?.avatar || '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    avatar: Yup.string(), 
  });

  return (
    <div data-testid={testId} className='updateOneUserFormContainer'>
      <Typography font='UI1' variant='H4'  className='updateOneUserFormHeader'>
        Update Your Information
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          errors, touched, submitCount, setFieldValue, values, 
        }) => (
          <Form noValidate>
            <Container className="formContainer">
              <Container className="formSection">

                <div className={"inputWrapper firstNameFieldWrapper textInputWrapper"} key={"firstName0"} data-testid={testId}>
                  <Field
                    className={"firstNameInput fieldInput textInput"}
                    required={false}
                    id={"firstName"}
                    label={"First Name"}
                    name={"firstName"}
                    type={"text"}
                    autoComplete={"firstName"}
                    as={TextField}
                    error={submitCount > 0 && touched.firstName && !!errors.firstName}
                  />
                  <ErrorMessage name={"firstName"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper lastNameFieldWrapper textInputWrapper"} key={"lastName1"} data-testid={testId}>
                  <Field
                    className={"lastNameInput fieldInput textInput"}
                    required={false}
                    id={"lastName"}
                    label={"Last Name"}
                    name={"lastName"}
                    type={"text"}
                    autoComplete={"lastName"}
                    as={TextField}
                    error={submitCount > 0 && touched.lastName && !!errors.lastName}
                  />
                  <ErrorMessage name={"lastName"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper avatarFieldWrapper textInputWrapper"} key={"avatar2"} data-testid={testId}>
                  <Field
                    className={"avatarInput fieldInput textInput"}
                    required={false}
                    id={"avatar"}
                    label={"Avatar"}
                    name={"avatar"}
                    type={"text"}
                    autoComplete={"avatar"}
                    as={TextField}
                    error={submitCount > 0 && touched.avatar && !!errors.avatar}
                  />
                  <ErrorMessage name={"avatar"} component="div" className="inputErrorMessage"/>
                </div>
              </Container>
            </Container>

            <Container className="buttonsContainer">
              <ActionButton
                ariaLabel="Submit form"
                variant="outline"
                className="submitFormButton"
                handleClick={() => {
                  setDisplayModal(false);
                  setModalContents(null);
                }}
              >
                Cancel
              </ActionButton>

              <ActionButton
                type="submit"
                ariaLabel="Submit form"
                variant="solid"
                className="submitFormButton"
              >
                 Submit
              </ActionButton>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default UpdateAdminUserForm