import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: any; output: any };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: { input: any; output: any };
  /** GraphQL Scalar representing the Prisma.Decimal type, based on Decimal.js library. */
  Decimal: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
};

export type ActionCard = {
  __typename?: "ActionCard";
  _count?: Maybe<ActionCardCount>;
  appointmentLocation?: Maybe<Scalars["String"]["output"]>;
  appointmentTime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  assignee?: Maybe<AssigneeType>;
  calendarIcs?: Maybe<Scalars["String"]["output"]>;
  calendarLink?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completedBy?: Maybe<Scalars["String"]["output"]>;
  component?: Maybe<Component>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  documentDownloadUrl?: Maybe<Scalars["String"]["output"]>;
  documentSignUrl?: Maybe<Scalars["String"]["output"]>;
  documentUploadUrl?: Maybe<Scalars["String"]["output"]>;
  encryptedReply: Scalars["Boolean"]["output"];
  estate: Estate;
  estateDetail?: Maybe<EstateDetail>;
  estateDetailId?: Maybe<Scalars["String"]["output"]>;
  estateId: Scalars["String"]["output"];
  followUpActionCard?: Maybe<Scalars["JSON"]["output"]>;
  formFieldName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  locked: Scalars["Boolean"]["output"];
  mapUrl?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  notifications: Array<EstateNotification>;
  promoted: Scalars["Boolean"]["output"];
  requiresFollowUp: Scalars["Boolean"]["output"];
  status: ActionCardStatus;
  task?: Maybe<Task>;
  taskId?: Maybe<Scalars["String"]["output"]>;
  taskName?: Maybe<Scalars["String"]["output"]>;
  type: ActionCardType;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type ActionCardComponentArgs = {
  where?: InputMaybe<ComponentWhereInput>;
};

export type ActionCardEstateDetailArgs = {
  where?: InputMaybe<EstateDetailWhereInput>;
};

export type ActionCardNotificationsArgs = {
  cursor?: InputMaybe<EstateNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<EstateNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EstateNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateNotificationWhereInput>;
};

export type ActionCardTaskArgs = {
  where?: InputMaybe<TaskWhereInput>;
};

export type ActionCard2 = {
  __typename?: "ActionCard2";
  appointmentLocation?: Maybe<Scalars["String"]["output"]>;
  appointmentTime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  assignee?: Maybe<AssigneeType>;
  calendarIcs?: Maybe<Scalars["String"]["output"]>;
  calendarLink?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completedBy?: Maybe<Scalars["String"]["output"]>;
  component?: Maybe<Component2>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  documentDownloadUrl?: Maybe<Scalars["String"]["output"]>;
  documentSignUrl?: Maybe<Scalars["String"]["output"]>;
  documentUploadUrl?: Maybe<Scalars["String"]["output"]>;
  encryptedReply: Scalars["Boolean"]["output"];
  estate: Estate2;
  estateDetail?: Maybe<EstateDetail2>;
  estateDetailId?: Maybe<Scalars["String"]["output"]>;
  estateId: Scalars["String"]["output"];
  followUpActionCard?: Maybe<Scalars["JSON"]["output"]>;
  formFieldName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  locked: Scalars["Boolean"]["output"];
  mapUrl?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  promoted: Scalars["Boolean"]["output"];
  requiresFollowUp: Scalars["Boolean"]["output"];
  status: ActionCardStatus;
  /** Retaining this for backwards compatibility with olders versions of the Native App, but we do not currently support this field so it will always be undefined */
  task?: Maybe<Task2>;
  taskId?: Maybe<Scalars["String"]["output"]>;
  taskName?: Maybe<Scalars["String"]["output"]>;
  type: ActionCardType;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type ActionCardCount = {
  __typename?: "ActionCardCount";
  notifications: Scalars["Int"]["output"];
};

export type ActionCardCountNotificationsArgs = {
  where?: InputMaybe<EstateNotificationWhereInput>;
};

export type ActionCardCountAggregate = {
  __typename?: "ActionCardCountAggregate";
  _all: Scalars["Int"]["output"];
  appointmentLocation: Scalars["Int"]["output"];
  appointmentTime: Scalars["Int"]["output"];
  assignee: Scalars["Int"]["output"];
  calendarIcs: Scalars["Int"]["output"];
  calendarLink: Scalars["Int"]["output"];
  completedAt: Scalars["Int"]["output"];
  completedBy: Scalars["Int"]["output"];
  componentId: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  description: Scalars["Int"]["output"];
  documentDownloadUrl: Scalars["Int"]["output"];
  documentSignUrl: Scalars["Int"]["output"];
  documentUploadUrl: Scalars["Int"]["output"];
  encryptedReply: Scalars["Int"]["output"];
  estateDetailId: Scalars["Int"]["output"];
  estateId: Scalars["Int"]["output"];
  followUpActionCard: Scalars["Int"]["output"];
  formFieldName: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  locked: Scalars["Int"]["output"];
  mapUrl: Scalars["Int"]["output"];
  name: Scalars["Int"]["output"];
  promoted: Scalars["Int"]["output"];
  requiresFollowUp: Scalars["Int"]["output"];
  status: Scalars["Int"]["output"];
  taskId: Scalars["Int"]["output"];
  taskName: Scalars["Int"]["output"];
  type: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type ActionCardCountOrderByAggregateInput = {
  appointmentLocation?: InputMaybe<SortOrder>;
  appointmentTime?: InputMaybe<SortOrder>;
  assignee?: InputMaybe<SortOrder>;
  calendarIcs?: InputMaybe<SortOrder>;
  calendarLink?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrder>;
  completedBy?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  documentDownloadUrl?: InputMaybe<SortOrder>;
  documentSignUrl?: InputMaybe<SortOrder>;
  documentUploadUrl?: InputMaybe<SortOrder>;
  encryptedReply?: InputMaybe<SortOrder>;
  estateDetailId?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  followUpActionCard?: InputMaybe<SortOrder>;
  formFieldName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  locked?: InputMaybe<SortOrder>;
  mapUrl?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  promoted?: InputMaybe<SortOrder>;
  requiresFollowUp?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  taskId?: InputMaybe<SortOrder>;
  taskName?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ActionCardCreateInput = {
  appointmentLocation?: InputMaybe<Scalars["String"]["input"]>;
  appointmentTime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  assignee?: InputMaybe<AssigneeType>;
  calendarIcs?: InputMaybe<Scalars["String"]["input"]>;
  calendarLink?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutActionCardsInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentDownloadUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentSignUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentUploadUrl?: InputMaybe<Scalars["String"]["input"]>;
  encryptedReply?: InputMaybe<Scalars["Boolean"]["input"]>;
  estate: EstateCreateNestedOneWithoutActionCardsInput;
  estateDetail?: InputMaybe<EstateDetailCreateNestedOneWithoutActionCardInput>;
  followUpActionCard?: InputMaybe<Scalars["JSON"]["input"]>;
  formFieldName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutActionCardInput>;
  promoted?: InputMaybe<Scalars["Boolean"]["input"]>;
  requiresFollowUp?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<ActionCardStatus>;
  task?: InputMaybe<TaskCreateNestedOneWithoutActionCardsInput>;
  taskName?: InputMaybe<Scalars["String"]["input"]>;
  type: ActionCardType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type ActionCardCreateInput2 = {
  appointmentLocation?: InputMaybe<Scalars["String"]["input"]>;
  appointmentTime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  assignee?: InputMaybe<AssigneeType>;
  calendarIcs?: InputMaybe<Scalars["String"]["input"]>;
  calendarLink?: InputMaybe<Scalars["String"]["input"]>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutActionCardsInput2>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentDownloadUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentSignUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentUploadUrl?: InputMaybe<Scalars["String"]["input"]>;
  encryptedReply?: InputMaybe<Scalars["Boolean"]["input"]>;
  estate: EstateCreateNestedOneWithoutActionCardsInput2;
  followUpActionCard?: InputMaybe<Scalars["JSON"]["input"]>;
  formFieldName?: InputMaybe<Scalars["String"]["input"]>;
  locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  status?: InputMaybe<ActionCardStatus>;
  type: ActionCardType;
};

export type ActionCardCreateManyComponentInput = {
  appointmentLocation?: InputMaybe<Scalars["String"]["input"]>;
  appointmentTime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  assignee?: InputMaybe<AssigneeType>;
  calendarIcs?: InputMaybe<Scalars["String"]["input"]>;
  calendarLink?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentDownloadUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentSignUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentUploadUrl?: InputMaybe<Scalars["String"]["input"]>;
  encryptedReply?: InputMaybe<Scalars["Boolean"]["input"]>;
  estateDetailId?: InputMaybe<Scalars["String"]["input"]>;
  estateId: Scalars["String"]["input"];
  followUpActionCard?: InputMaybe<Scalars["JSON"]["input"]>;
  formFieldName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  promoted?: InputMaybe<Scalars["Boolean"]["input"]>;
  requiresFollowUp?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<ActionCardStatus>;
  taskId?: InputMaybe<Scalars["String"]["input"]>;
  taskName?: InputMaybe<Scalars["String"]["input"]>;
  type: ActionCardType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type ActionCardCreateManyComponentInputEnvelope = {
  data: Array<ActionCardCreateManyComponentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActionCardCreateManyEstateInput = {
  appointmentLocation?: InputMaybe<Scalars["String"]["input"]>;
  appointmentTime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  assignee?: InputMaybe<AssigneeType>;
  calendarIcs?: InputMaybe<Scalars["String"]["input"]>;
  calendarLink?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentDownloadUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentSignUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentUploadUrl?: InputMaybe<Scalars["String"]["input"]>;
  encryptedReply?: InputMaybe<Scalars["Boolean"]["input"]>;
  estateDetailId?: InputMaybe<Scalars["String"]["input"]>;
  followUpActionCard?: InputMaybe<Scalars["JSON"]["input"]>;
  formFieldName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  promoted?: InputMaybe<Scalars["Boolean"]["input"]>;
  requiresFollowUp?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<ActionCardStatus>;
  taskId?: InputMaybe<Scalars["String"]["input"]>;
  taskName?: InputMaybe<Scalars["String"]["input"]>;
  type: ActionCardType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type ActionCardCreateManyEstateInputEnvelope = {
  data: Array<ActionCardCreateManyEstateInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActionCardCreateManyInput = {
  appointmentLocation?: InputMaybe<Scalars["String"]["input"]>;
  appointmentTime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  assignee?: InputMaybe<AssigneeType>;
  calendarIcs?: InputMaybe<Scalars["String"]["input"]>;
  calendarLink?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentDownloadUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentSignUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentUploadUrl?: InputMaybe<Scalars["String"]["input"]>;
  encryptedReply?: InputMaybe<Scalars["Boolean"]["input"]>;
  estateDetailId?: InputMaybe<Scalars["String"]["input"]>;
  estateId: Scalars["String"]["input"];
  followUpActionCard?: InputMaybe<Scalars["JSON"]["input"]>;
  formFieldName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  promoted?: InputMaybe<Scalars["Boolean"]["input"]>;
  requiresFollowUp?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<ActionCardStatus>;
  taskId?: InputMaybe<Scalars["String"]["input"]>;
  taskName?: InputMaybe<Scalars["String"]["input"]>;
  type: ActionCardType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type ActionCardCreateManyTaskInput = {
  appointmentLocation?: InputMaybe<Scalars["String"]["input"]>;
  appointmentTime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  assignee?: InputMaybe<AssigneeType>;
  calendarIcs?: InputMaybe<Scalars["String"]["input"]>;
  calendarLink?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentDownloadUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentSignUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentUploadUrl?: InputMaybe<Scalars["String"]["input"]>;
  encryptedReply?: InputMaybe<Scalars["Boolean"]["input"]>;
  estateDetailId?: InputMaybe<Scalars["String"]["input"]>;
  estateId: Scalars["String"]["input"];
  followUpActionCard?: InputMaybe<Scalars["JSON"]["input"]>;
  formFieldName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  promoted?: InputMaybe<Scalars["Boolean"]["input"]>;
  requiresFollowUp?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<ActionCardStatus>;
  taskName?: InputMaybe<Scalars["String"]["input"]>;
  type: ActionCardType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type ActionCardCreateManyTaskInputEnvelope = {
  data: Array<ActionCardCreateManyTaskInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActionCardCreateNestedManyWithoutComponentInput = {
  connect?: InputMaybe<Array<ActionCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ActionCardCreateOrConnectWithoutComponentInput>
  >;
  create?: InputMaybe<Array<ActionCardCreateWithoutComponentInput>>;
  createMany?: InputMaybe<ActionCardCreateManyComponentInputEnvelope>;
};

export type ActionCardCreateNestedManyWithoutEstateInput = {
  connect?: InputMaybe<Array<ActionCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ActionCardCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<ActionCardCreateWithoutEstateInput>>;
  createMany?: InputMaybe<ActionCardCreateManyEstateInputEnvelope>;
};

export type ActionCardCreateNestedManyWithoutTaskInput = {
  connect?: InputMaybe<Array<ActionCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ActionCardCreateOrConnectWithoutTaskInput>
  >;
  create?: InputMaybe<Array<ActionCardCreateWithoutTaskInput>>;
  createMany?: InputMaybe<ActionCardCreateManyTaskInputEnvelope>;
};

export type ActionCardCreateNestedOneWithoutEstateDetailInput = {
  connect?: InputMaybe<ActionCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActionCardCreateOrConnectWithoutEstateDetailInput>;
  create?: InputMaybe<ActionCardCreateWithoutEstateDetailInput>;
};

export type ActionCardCreateNestedOneWithoutNotificationsInput = {
  connect?: InputMaybe<ActionCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActionCardCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<ActionCardCreateWithoutNotificationsInput>;
};

export type ActionCardCreateOrConnectWithoutComponentInput = {
  create: ActionCardCreateWithoutComponentInput;
  where: ActionCardWhereUniqueInput;
};

export type ActionCardCreateOrConnectWithoutEstateDetailInput = {
  create: ActionCardCreateWithoutEstateDetailInput;
  where: ActionCardWhereUniqueInput;
};

export type ActionCardCreateOrConnectWithoutEstateInput = {
  create: ActionCardCreateWithoutEstateInput;
  where: ActionCardWhereUniqueInput;
};

export type ActionCardCreateOrConnectWithoutNotificationsInput = {
  create: ActionCardCreateWithoutNotificationsInput;
  where: ActionCardWhereUniqueInput;
};

export type ActionCardCreateOrConnectWithoutTaskInput = {
  create: ActionCardCreateWithoutTaskInput;
  where: ActionCardWhereUniqueInput;
};

export type ActionCardCreateWithoutComponentInput = {
  appointmentLocation?: InputMaybe<Scalars["String"]["input"]>;
  appointmentTime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  assignee?: InputMaybe<AssigneeType>;
  calendarIcs?: InputMaybe<Scalars["String"]["input"]>;
  calendarLink?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentDownloadUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentSignUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentUploadUrl?: InputMaybe<Scalars["String"]["input"]>;
  encryptedReply?: InputMaybe<Scalars["Boolean"]["input"]>;
  estate: EstateCreateNestedOneWithoutActionCardsInput;
  estateDetail?: InputMaybe<EstateDetailCreateNestedOneWithoutActionCardInput>;
  followUpActionCard?: InputMaybe<Scalars["JSON"]["input"]>;
  formFieldName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutActionCardInput>;
  promoted?: InputMaybe<Scalars["Boolean"]["input"]>;
  requiresFollowUp?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<ActionCardStatus>;
  task?: InputMaybe<TaskCreateNestedOneWithoutActionCardsInput>;
  taskName?: InputMaybe<Scalars["String"]["input"]>;
  type: ActionCardType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type ActionCardCreateWithoutEstateDetailInput = {
  appointmentLocation?: InputMaybe<Scalars["String"]["input"]>;
  appointmentTime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  assignee?: InputMaybe<AssigneeType>;
  calendarIcs?: InputMaybe<Scalars["String"]["input"]>;
  calendarLink?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutActionCardsInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentDownloadUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentSignUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentUploadUrl?: InputMaybe<Scalars["String"]["input"]>;
  encryptedReply?: InputMaybe<Scalars["Boolean"]["input"]>;
  estate: EstateCreateNestedOneWithoutActionCardsInput;
  followUpActionCard?: InputMaybe<Scalars["JSON"]["input"]>;
  formFieldName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutActionCardInput>;
  promoted?: InputMaybe<Scalars["Boolean"]["input"]>;
  requiresFollowUp?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<ActionCardStatus>;
  task?: InputMaybe<TaskCreateNestedOneWithoutActionCardsInput>;
  taskName?: InputMaybe<Scalars["String"]["input"]>;
  type: ActionCardType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type ActionCardCreateWithoutEstateInput = {
  appointmentLocation?: InputMaybe<Scalars["String"]["input"]>;
  appointmentTime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  assignee?: InputMaybe<AssigneeType>;
  calendarIcs?: InputMaybe<Scalars["String"]["input"]>;
  calendarLink?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutActionCardsInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentDownloadUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentSignUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentUploadUrl?: InputMaybe<Scalars["String"]["input"]>;
  encryptedReply?: InputMaybe<Scalars["Boolean"]["input"]>;
  estateDetail?: InputMaybe<EstateDetailCreateNestedOneWithoutActionCardInput>;
  followUpActionCard?: InputMaybe<Scalars["JSON"]["input"]>;
  formFieldName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutActionCardInput>;
  promoted?: InputMaybe<Scalars["Boolean"]["input"]>;
  requiresFollowUp?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<ActionCardStatus>;
  task?: InputMaybe<TaskCreateNestedOneWithoutActionCardsInput>;
  taskName?: InputMaybe<Scalars["String"]["input"]>;
  type: ActionCardType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type ActionCardCreateWithoutNotificationsInput = {
  appointmentLocation?: InputMaybe<Scalars["String"]["input"]>;
  appointmentTime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  assignee?: InputMaybe<AssigneeType>;
  calendarIcs?: InputMaybe<Scalars["String"]["input"]>;
  calendarLink?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutActionCardsInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentDownloadUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentSignUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentUploadUrl?: InputMaybe<Scalars["String"]["input"]>;
  encryptedReply?: InputMaybe<Scalars["Boolean"]["input"]>;
  estate: EstateCreateNestedOneWithoutActionCardsInput;
  estateDetail?: InputMaybe<EstateDetailCreateNestedOneWithoutActionCardInput>;
  followUpActionCard?: InputMaybe<Scalars["JSON"]["input"]>;
  formFieldName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  promoted?: InputMaybe<Scalars["Boolean"]["input"]>;
  requiresFollowUp?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<ActionCardStatus>;
  task?: InputMaybe<TaskCreateNestedOneWithoutActionCardsInput>;
  taskName?: InputMaybe<Scalars["String"]["input"]>;
  type: ActionCardType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type ActionCardCreateWithoutTaskInput = {
  appointmentLocation?: InputMaybe<Scalars["String"]["input"]>;
  appointmentTime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  assignee?: InputMaybe<AssigneeType>;
  calendarIcs?: InputMaybe<Scalars["String"]["input"]>;
  calendarLink?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutActionCardsInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentDownloadUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentSignUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentUploadUrl?: InputMaybe<Scalars["String"]["input"]>;
  encryptedReply?: InputMaybe<Scalars["Boolean"]["input"]>;
  estate: EstateCreateNestedOneWithoutActionCardsInput;
  estateDetail?: InputMaybe<EstateDetailCreateNestedOneWithoutActionCardInput>;
  followUpActionCard?: InputMaybe<Scalars["JSON"]["input"]>;
  formFieldName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutActionCardInput>;
  promoted?: InputMaybe<Scalars["Boolean"]["input"]>;
  requiresFollowUp?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<ActionCardStatus>;
  taskName?: InputMaybe<Scalars["String"]["input"]>;
  type: ActionCardType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type ActionCardGroupBy = {
  __typename?: "ActionCardGroupBy";
  _count?: Maybe<ActionCardCountAggregate>;
  _max?: Maybe<ActionCardMaxAggregate>;
  _min?: Maybe<ActionCardMinAggregate>;
  appointmentLocation?: Maybe<Scalars["String"]["output"]>;
  appointmentTime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  assignee?: Maybe<AssigneeType>;
  calendarIcs?: Maybe<Scalars["String"]["output"]>;
  calendarLink?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completedBy?: Maybe<Scalars["String"]["output"]>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  documentDownloadUrl?: Maybe<Scalars["String"]["output"]>;
  documentSignUrl?: Maybe<Scalars["String"]["output"]>;
  documentUploadUrl?: Maybe<Scalars["String"]["output"]>;
  encryptedReply: Scalars["Boolean"]["output"];
  estateDetailId?: Maybe<Scalars["String"]["output"]>;
  estateId: Scalars["String"]["output"];
  followUpActionCard?: Maybe<Scalars["JSON"]["output"]>;
  formFieldName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  locked: Scalars["Boolean"]["output"];
  mapUrl?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  promoted: Scalars["Boolean"]["output"];
  requiresFollowUp: Scalars["Boolean"]["output"];
  status: ActionCardStatus;
  taskId?: Maybe<Scalars["String"]["output"]>;
  taskName?: Maybe<Scalars["String"]["output"]>;
  type: ActionCardType;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type ActionCardListRelationFilter = {
  every?: InputMaybe<ActionCardWhereInput>;
  none?: InputMaybe<ActionCardWhereInput>;
  some?: InputMaybe<ActionCardWhereInput>;
};

export type ActionCardMaxAggregate = {
  __typename?: "ActionCardMaxAggregate";
  appointmentLocation?: Maybe<Scalars["String"]["output"]>;
  appointmentTime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  assignee?: Maybe<AssigneeType>;
  calendarIcs?: Maybe<Scalars["String"]["output"]>;
  calendarLink?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completedBy?: Maybe<Scalars["String"]["output"]>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  documentDownloadUrl?: Maybe<Scalars["String"]["output"]>;
  documentSignUrl?: Maybe<Scalars["String"]["output"]>;
  documentUploadUrl?: Maybe<Scalars["String"]["output"]>;
  encryptedReply?: Maybe<Scalars["Boolean"]["output"]>;
  estateDetailId?: Maybe<Scalars["String"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  formFieldName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  locked?: Maybe<Scalars["Boolean"]["output"]>;
  mapUrl?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  promoted?: Maybe<Scalars["Boolean"]["output"]>;
  requiresFollowUp?: Maybe<Scalars["Boolean"]["output"]>;
  status?: Maybe<ActionCardStatus>;
  taskId?: Maybe<Scalars["String"]["output"]>;
  taskName?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<ActionCardType>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type ActionCardMaxOrderByAggregateInput = {
  appointmentLocation?: InputMaybe<SortOrder>;
  appointmentTime?: InputMaybe<SortOrder>;
  assignee?: InputMaybe<SortOrder>;
  calendarIcs?: InputMaybe<SortOrder>;
  calendarLink?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrder>;
  completedBy?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  documentDownloadUrl?: InputMaybe<SortOrder>;
  documentSignUrl?: InputMaybe<SortOrder>;
  documentUploadUrl?: InputMaybe<SortOrder>;
  encryptedReply?: InputMaybe<SortOrder>;
  estateDetailId?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  formFieldName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  locked?: InputMaybe<SortOrder>;
  mapUrl?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  promoted?: InputMaybe<SortOrder>;
  requiresFollowUp?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  taskId?: InputMaybe<SortOrder>;
  taskName?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ActionCardMinAggregate = {
  __typename?: "ActionCardMinAggregate";
  appointmentLocation?: Maybe<Scalars["String"]["output"]>;
  appointmentTime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  assignee?: Maybe<AssigneeType>;
  calendarIcs?: Maybe<Scalars["String"]["output"]>;
  calendarLink?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completedBy?: Maybe<Scalars["String"]["output"]>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  documentDownloadUrl?: Maybe<Scalars["String"]["output"]>;
  documentSignUrl?: Maybe<Scalars["String"]["output"]>;
  documentUploadUrl?: Maybe<Scalars["String"]["output"]>;
  encryptedReply?: Maybe<Scalars["Boolean"]["output"]>;
  estateDetailId?: Maybe<Scalars["String"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  formFieldName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  locked?: Maybe<Scalars["Boolean"]["output"]>;
  mapUrl?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  promoted?: Maybe<Scalars["Boolean"]["output"]>;
  requiresFollowUp?: Maybe<Scalars["Boolean"]["output"]>;
  status?: Maybe<ActionCardStatus>;
  taskId?: Maybe<Scalars["String"]["output"]>;
  taskName?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<ActionCardType>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type ActionCardMinOrderByAggregateInput = {
  appointmentLocation?: InputMaybe<SortOrder>;
  appointmentTime?: InputMaybe<SortOrder>;
  assignee?: InputMaybe<SortOrder>;
  calendarIcs?: InputMaybe<SortOrder>;
  calendarLink?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrder>;
  completedBy?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  documentDownloadUrl?: InputMaybe<SortOrder>;
  documentSignUrl?: InputMaybe<SortOrder>;
  documentUploadUrl?: InputMaybe<SortOrder>;
  encryptedReply?: InputMaybe<SortOrder>;
  estateDetailId?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  formFieldName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  locked?: InputMaybe<SortOrder>;
  mapUrl?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  promoted?: InputMaybe<SortOrder>;
  requiresFollowUp?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  taskId?: InputMaybe<SortOrder>;
  taskName?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ActionCardNullableRelationFilter = {
  is?: InputMaybe<ActionCardWhereInput>;
  isNot?: InputMaybe<ActionCardWhereInput>;
};

export type ActionCardOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ActionCardOrderByWithAggregationInput = {
  _count?: InputMaybe<ActionCardCountOrderByAggregateInput>;
  _max?: InputMaybe<ActionCardMaxOrderByAggregateInput>;
  _min?: InputMaybe<ActionCardMinOrderByAggregateInput>;
  appointmentLocation?: InputMaybe<SortOrderInput>;
  appointmentTime?: InputMaybe<SortOrderInput>;
  assignee?: InputMaybe<SortOrderInput>;
  calendarIcs?: InputMaybe<SortOrderInput>;
  calendarLink?: InputMaybe<SortOrderInput>;
  completedAt?: InputMaybe<SortOrderInput>;
  completedBy?: InputMaybe<SortOrderInput>;
  componentId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  documentDownloadUrl?: InputMaybe<SortOrderInput>;
  documentSignUrl?: InputMaybe<SortOrderInput>;
  documentUploadUrl?: InputMaybe<SortOrderInput>;
  encryptedReply?: InputMaybe<SortOrder>;
  estateDetailId?: InputMaybe<SortOrderInput>;
  estateId?: InputMaybe<SortOrder>;
  followUpActionCard?: InputMaybe<SortOrderInput>;
  formFieldName?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  locked?: InputMaybe<SortOrder>;
  mapUrl?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  promoted?: InputMaybe<SortOrder>;
  requiresFollowUp?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  taskId?: InputMaybe<SortOrderInput>;
  taskName?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ActionCardOrderByWithRelationInput = {
  appointmentLocation?: InputMaybe<SortOrderInput>;
  appointmentTime?: InputMaybe<SortOrderInput>;
  assignee?: InputMaybe<SortOrderInput>;
  calendarIcs?: InputMaybe<SortOrderInput>;
  calendarLink?: InputMaybe<SortOrderInput>;
  completedAt?: InputMaybe<SortOrderInput>;
  completedBy?: InputMaybe<SortOrderInput>;
  component?: InputMaybe<ComponentOrderByWithRelationInput>;
  componentId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  documentDownloadUrl?: InputMaybe<SortOrderInput>;
  documentSignUrl?: InputMaybe<SortOrderInput>;
  documentUploadUrl?: InputMaybe<SortOrderInput>;
  encryptedReply?: InputMaybe<SortOrder>;
  estate?: InputMaybe<EstateOrderByWithRelationInput>;
  estateDetail?: InputMaybe<EstateDetailOrderByWithRelationInput>;
  estateDetailId?: InputMaybe<SortOrderInput>;
  estateId?: InputMaybe<SortOrder>;
  followUpActionCard?: InputMaybe<SortOrderInput>;
  formFieldName?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  locked?: InputMaybe<SortOrder>;
  mapUrl?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  notifications?: InputMaybe<EstateNotificationOrderByRelationAggregateInput>;
  promoted?: InputMaybe<SortOrder>;
  requiresFollowUp?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  task?: InputMaybe<TaskOrderByWithRelationInput>;
  taskId?: InputMaybe<SortOrderInput>;
  taskName?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ActionCardOrderByWithRelationInput2 = {
  assignee?: InputMaybe<SortOrderInput>;
  completedAt?: InputMaybe<SortOrderInput>;
  completedBy?: InputMaybe<SortOrderInput>;
  componentId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  estateDetailId?: InputMaybe<SortOrderInput>;
  estateId?: InputMaybe<SortOrder>;
  followUpActionCard?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  locked?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  requiresFollowUp?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ActionCardScalarFieldEnum {
  AppointmentLocation = "appointmentLocation",
  AppointmentTime = "appointmentTime",
  Assignee = "assignee",
  CalendarIcs = "calendarIcs",
  CalendarLink = "calendarLink",
  CompletedAt = "completedAt",
  CompletedBy = "completedBy",
  ComponentId = "componentId",
  CreatedAt = "createdAt",
  Description = "description",
  DocumentDownloadUrl = "documentDownloadUrl",
  DocumentSignUrl = "documentSignUrl",
  DocumentUploadUrl = "documentUploadUrl",
  EncryptedReply = "encryptedReply",
  EstateDetailId = "estateDetailId",
  EstateId = "estateId",
  FollowUpActionCard = "followUpActionCard",
  FormFieldName = "formFieldName",
  Id = "id",
  Locked = "locked",
  MapUrl = "mapUrl",
  Name = "name",
  Promoted = "promoted",
  RequiresFollowUp = "requiresFollowUp",
  Status = "status",
  TaskId = "taskId",
  TaskName = "taskName",
  Type = "type",
  UpdatedAt = "updatedAt",
}

export type ActionCardScalarWhereInput = {
  AND?: InputMaybe<Array<ActionCardScalarWhereInput>>;
  NOT?: InputMaybe<Array<ActionCardScalarWhereInput>>;
  OR?: InputMaybe<Array<ActionCardScalarWhereInput>>;
  appointmentLocation?: InputMaybe<StringNullableFilter>;
  appointmentTime?: InputMaybe<DateTimeNullableFilter>;
  assignee?: InputMaybe<EnumAssigneeTypeNullableFilter>;
  calendarIcs?: InputMaybe<StringNullableFilter>;
  calendarLink?: InputMaybe<StringNullableFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  completedBy?: InputMaybe<StringNullableFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  documentDownloadUrl?: InputMaybe<StringNullableFilter>;
  documentSignUrl?: InputMaybe<StringNullableFilter>;
  documentUploadUrl?: InputMaybe<StringNullableFilter>;
  encryptedReply?: InputMaybe<BoolFilter>;
  estateDetailId?: InputMaybe<StringNullableFilter>;
  estateId?: InputMaybe<StringFilter>;
  followUpActionCard?: InputMaybe<JsonNullableFilter>;
  formFieldName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  locked?: InputMaybe<BoolFilter>;
  mapUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  promoted?: InputMaybe<BoolFilter>;
  requiresFollowUp?: InputMaybe<BoolFilter>;
  status?: InputMaybe<EnumActionCardStatusFilter>;
  taskId?: InputMaybe<StringNullableFilter>;
  taskName?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumActionCardTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ActionCardScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ActionCardScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ActionCardScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ActionCardScalarWhereWithAggregatesInput>>;
  appointmentLocation?: InputMaybe<StringNullableWithAggregatesFilter>;
  appointmentTime?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  assignee?: InputMaybe<EnumAssigneeTypeNullableWithAggregatesFilter>;
  calendarIcs?: InputMaybe<StringNullableWithAggregatesFilter>;
  calendarLink?: InputMaybe<StringNullableWithAggregatesFilter>;
  completedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  completedBy?: InputMaybe<StringNullableWithAggregatesFilter>;
  componentId?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  documentDownloadUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  documentSignUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  documentUploadUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  encryptedReply?: InputMaybe<BoolWithAggregatesFilter>;
  estateDetailId?: InputMaybe<StringNullableWithAggregatesFilter>;
  estateId?: InputMaybe<StringWithAggregatesFilter>;
  followUpActionCard?: InputMaybe<JsonNullableWithAggregatesFilter>;
  formFieldName?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  locked?: InputMaybe<BoolWithAggregatesFilter>;
  mapUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  promoted?: InputMaybe<BoolWithAggregatesFilter>;
  requiresFollowUp?: InputMaybe<BoolWithAggregatesFilter>;
  status?: InputMaybe<EnumActionCardStatusWithAggregatesFilter>;
  taskId?: InputMaybe<StringNullableWithAggregatesFilter>;
  taskName?: InputMaybe<StringNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumActionCardTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export enum ActionCardStatus {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  Draft = "DRAFT",
  InProgress = "IN_PROGRESS",
  New = "NEW",
}

export enum ActionCardType {
  ConnectBankAccount = "ConnectBankAccount",
  DocumentDownload = "DocumentDownload",
  DocumentSign = "DocumentSign",
  DocumentUpload = "DocumentUpload",
  FormFields = "FormFields",
  Manual = "Manual",
  ScheduleAppointment = "ScheduleAppointment",
}

export type ActionCardUpdateInput = {
  appointmentLocation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appointmentTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assignee?: InputMaybe<NullableEnumAssigneeTypeFieldUpdateOperationsInput>;
  calendarIcs?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutActionCardsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentDownloadUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentSignUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentUploadUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  encryptedReply?: InputMaybe<BoolFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutActionCardsNestedInput>;
  estateDetail?: InputMaybe<EstateDetailUpdateOneWithoutActionCardNestedInput>;
  followUpActionCard?: InputMaybe<Scalars["JSON"]["input"]>;
  formFieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  mapUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutActionCardNestedInput>;
  promoted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiresFollowUp?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumActionCardStatusFieldUpdateOperationsInput>;
  task?: InputMaybe<TaskUpdateOneWithoutActionCardsNestedInput>;
  taskName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumActionCardTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActionCardUpdateInput2 = {
  appointmentLocation?: InputMaybe<Scalars["String"]["input"]>;
  appointmentTime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  assignee?: InputMaybe<AssigneeType>;
  calendarIcs?: InputMaybe<Scalars["String"]["input"]>;
  calendarLink?: InputMaybe<Scalars["String"]["input"]>;
  component?: InputMaybe<ComponentUpdateOneWithoutActionCardsNestedInput2>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentDownloadUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentSignUrl?: InputMaybe<Scalars["String"]["input"]>;
  documentUploadUrl?: InputMaybe<Scalars["String"]["input"]>;
  encryptedReply?: InputMaybe<Scalars["Boolean"]["input"]>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutActionCardsNestedInput2>;
  formFieldName?: InputMaybe<Scalars["String"]["input"]>;
  locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ActionCardStatus>;
  type?: InputMaybe<ActionCardType>;
};

export type ActionCardUpdateManyMutationInput = {
  appointmentLocation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appointmentTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assignee?: InputMaybe<NullableEnumAssigneeTypeFieldUpdateOperationsInput>;
  calendarIcs?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentDownloadUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentSignUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentUploadUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  encryptedReply?: InputMaybe<BoolFieldUpdateOperationsInput>;
  followUpActionCard?: InputMaybe<Scalars["JSON"]["input"]>;
  formFieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  mapUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  promoted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiresFollowUp?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumActionCardStatusFieldUpdateOperationsInput>;
  taskName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumActionCardTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActionCardUpdateManyWithWhereWithoutComponentInput = {
  data: ActionCardUpdateManyMutationInput;
  where: ActionCardScalarWhereInput;
};

export type ActionCardUpdateManyWithWhereWithoutEstateInput = {
  data: ActionCardUpdateManyMutationInput;
  where: ActionCardScalarWhereInput;
};

export type ActionCardUpdateManyWithWhereWithoutTaskInput = {
  data: ActionCardUpdateManyMutationInput;
  where: ActionCardScalarWhereInput;
};

export type ActionCardUpdateManyWithoutComponentNestedInput = {
  connect?: InputMaybe<Array<ActionCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ActionCardCreateOrConnectWithoutComponentInput>
  >;
  create?: InputMaybe<Array<ActionCardCreateWithoutComponentInput>>;
  createMany?: InputMaybe<ActionCardCreateManyComponentInputEnvelope>;
  delete?: InputMaybe<Array<ActionCardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActionCardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActionCardWhereUniqueInput>>;
  set?: InputMaybe<Array<ActionCardWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ActionCardUpdateWithWhereUniqueWithoutComponentInput>
  >;
  updateMany?: InputMaybe<
    Array<ActionCardUpdateManyWithWhereWithoutComponentInput>
  >;
  upsert?: InputMaybe<
    Array<ActionCardUpsertWithWhereUniqueWithoutComponentInput>
  >;
};

export type ActionCardUpdateManyWithoutEstateNestedInput = {
  connect?: InputMaybe<Array<ActionCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ActionCardCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<ActionCardCreateWithoutEstateInput>>;
  createMany?: InputMaybe<ActionCardCreateManyEstateInputEnvelope>;
  delete?: InputMaybe<Array<ActionCardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActionCardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActionCardWhereUniqueInput>>;
  set?: InputMaybe<Array<ActionCardWhereUniqueInput>>;
  update?: InputMaybe<Array<ActionCardUpdateWithWhereUniqueWithoutEstateInput>>;
  updateMany?: InputMaybe<
    Array<ActionCardUpdateManyWithWhereWithoutEstateInput>
  >;
  upsert?: InputMaybe<Array<ActionCardUpsertWithWhereUniqueWithoutEstateInput>>;
};

export type ActionCardUpdateManyWithoutTaskNestedInput = {
  connect?: InputMaybe<Array<ActionCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ActionCardCreateOrConnectWithoutTaskInput>
  >;
  create?: InputMaybe<Array<ActionCardCreateWithoutTaskInput>>;
  createMany?: InputMaybe<ActionCardCreateManyTaskInputEnvelope>;
  delete?: InputMaybe<Array<ActionCardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActionCardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActionCardWhereUniqueInput>>;
  set?: InputMaybe<Array<ActionCardWhereUniqueInput>>;
  update?: InputMaybe<Array<ActionCardUpdateWithWhereUniqueWithoutTaskInput>>;
  updateMany?: InputMaybe<Array<ActionCardUpdateManyWithWhereWithoutTaskInput>>;
  upsert?: InputMaybe<Array<ActionCardUpsertWithWhereUniqueWithoutTaskInput>>;
};

export type ActionCardUpdateOneWithoutEstateDetailNestedInput = {
  connect?: InputMaybe<ActionCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActionCardCreateOrConnectWithoutEstateDetailInput>;
  create?: InputMaybe<ActionCardCreateWithoutEstateDetailInput>;
  delete?: InputMaybe<ActionCardWhereInput>;
  disconnect?: InputMaybe<ActionCardWhereInput>;
  update?: InputMaybe<ActionCardUpdateToOneWithWhereWithoutEstateDetailInput>;
  upsert?: InputMaybe<ActionCardUpsertWithoutEstateDetailInput>;
};

export type ActionCardUpdateOneWithoutNotificationsNestedInput = {
  connect?: InputMaybe<ActionCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActionCardCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<ActionCardCreateWithoutNotificationsInput>;
  delete?: InputMaybe<ActionCardWhereInput>;
  disconnect?: InputMaybe<ActionCardWhereInput>;
  update?: InputMaybe<ActionCardUpdateToOneWithWhereWithoutNotificationsInput>;
  upsert?: InputMaybe<ActionCardUpsertWithoutNotificationsInput>;
};

export type ActionCardUpdateToOneWithWhereWithoutEstateDetailInput = {
  data: ActionCardUpdateWithoutEstateDetailInput;
  where?: InputMaybe<ActionCardWhereInput>;
};

export type ActionCardUpdateToOneWithWhereWithoutNotificationsInput = {
  data: ActionCardUpdateWithoutNotificationsInput;
  where?: InputMaybe<ActionCardWhereInput>;
};

export type ActionCardUpdateWithWhereUniqueWithoutComponentInput = {
  data: ActionCardUpdateWithoutComponentInput;
  where: ActionCardWhereUniqueInput;
};

export type ActionCardUpdateWithWhereUniqueWithoutEstateInput = {
  data: ActionCardUpdateWithoutEstateInput;
  where: ActionCardWhereUniqueInput;
};

export type ActionCardUpdateWithWhereUniqueWithoutTaskInput = {
  data: ActionCardUpdateWithoutTaskInput;
  where: ActionCardWhereUniqueInput;
};

export type ActionCardUpdateWithoutComponentInput = {
  appointmentLocation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appointmentTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assignee?: InputMaybe<NullableEnumAssigneeTypeFieldUpdateOperationsInput>;
  calendarIcs?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentDownloadUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentSignUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentUploadUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  encryptedReply?: InputMaybe<BoolFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutActionCardsNestedInput>;
  estateDetail?: InputMaybe<EstateDetailUpdateOneWithoutActionCardNestedInput>;
  followUpActionCard?: InputMaybe<Scalars["JSON"]["input"]>;
  formFieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  mapUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutActionCardNestedInput>;
  promoted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiresFollowUp?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumActionCardStatusFieldUpdateOperationsInput>;
  task?: InputMaybe<TaskUpdateOneWithoutActionCardsNestedInput>;
  taskName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumActionCardTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActionCardUpdateWithoutEstateDetailInput = {
  appointmentLocation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appointmentTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assignee?: InputMaybe<NullableEnumAssigneeTypeFieldUpdateOperationsInput>;
  calendarIcs?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutActionCardsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentDownloadUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentSignUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentUploadUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  encryptedReply?: InputMaybe<BoolFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutActionCardsNestedInput>;
  followUpActionCard?: InputMaybe<Scalars["JSON"]["input"]>;
  formFieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  mapUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutActionCardNestedInput>;
  promoted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiresFollowUp?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumActionCardStatusFieldUpdateOperationsInput>;
  task?: InputMaybe<TaskUpdateOneWithoutActionCardsNestedInput>;
  taskName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumActionCardTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActionCardUpdateWithoutEstateInput = {
  appointmentLocation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appointmentTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assignee?: InputMaybe<NullableEnumAssigneeTypeFieldUpdateOperationsInput>;
  calendarIcs?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutActionCardsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentDownloadUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentSignUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentUploadUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  encryptedReply?: InputMaybe<BoolFieldUpdateOperationsInput>;
  estateDetail?: InputMaybe<EstateDetailUpdateOneWithoutActionCardNestedInput>;
  followUpActionCard?: InputMaybe<Scalars["JSON"]["input"]>;
  formFieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  mapUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutActionCardNestedInput>;
  promoted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiresFollowUp?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumActionCardStatusFieldUpdateOperationsInput>;
  task?: InputMaybe<TaskUpdateOneWithoutActionCardsNestedInput>;
  taskName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumActionCardTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActionCardUpdateWithoutNotificationsInput = {
  appointmentLocation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appointmentTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assignee?: InputMaybe<NullableEnumAssigneeTypeFieldUpdateOperationsInput>;
  calendarIcs?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutActionCardsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentDownloadUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentSignUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentUploadUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  encryptedReply?: InputMaybe<BoolFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutActionCardsNestedInput>;
  estateDetail?: InputMaybe<EstateDetailUpdateOneWithoutActionCardNestedInput>;
  followUpActionCard?: InputMaybe<Scalars["JSON"]["input"]>;
  formFieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  mapUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  promoted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiresFollowUp?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumActionCardStatusFieldUpdateOperationsInput>;
  task?: InputMaybe<TaskUpdateOneWithoutActionCardsNestedInput>;
  taskName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumActionCardTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActionCardUpdateWithoutTaskInput = {
  appointmentLocation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appointmentTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  assignee?: InputMaybe<NullableEnumAssigneeTypeFieldUpdateOperationsInput>;
  calendarIcs?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutActionCardsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentDownloadUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentSignUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentUploadUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  encryptedReply?: InputMaybe<BoolFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutActionCardsNestedInput>;
  estateDetail?: InputMaybe<EstateDetailUpdateOneWithoutActionCardNestedInput>;
  followUpActionCard?: InputMaybe<Scalars["JSON"]["input"]>;
  formFieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  mapUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutActionCardNestedInput>;
  promoted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiresFollowUp?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumActionCardStatusFieldUpdateOperationsInput>;
  taskName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumActionCardTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActionCardUpsertWithWhereUniqueWithoutComponentInput = {
  create: ActionCardCreateWithoutComponentInput;
  update: ActionCardUpdateWithoutComponentInput;
  where: ActionCardWhereUniqueInput;
};

export type ActionCardUpsertWithWhereUniqueWithoutEstateInput = {
  create: ActionCardCreateWithoutEstateInput;
  update: ActionCardUpdateWithoutEstateInput;
  where: ActionCardWhereUniqueInput;
};

export type ActionCardUpsertWithWhereUniqueWithoutTaskInput = {
  create: ActionCardCreateWithoutTaskInput;
  update: ActionCardUpdateWithoutTaskInput;
  where: ActionCardWhereUniqueInput;
};

export type ActionCardUpsertWithoutEstateDetailInput = {
  create: ActionCardCreateWithoutEstateDetailInput;
  update: ActionCardUpdateWithoutEstateDetailInput;
  where?: InputMaybe<ActionCardWhereInput>;
};

export type ActionCardUpsertWithoutNotificationsInput = {
  create: ActionCardCreateWithoutNotificationsInput;
  update: ActionCardUpdateWithoutNotificationsInput;
  where?: InputMaybe<ActionCardWhereInput>;
};

export type ActionCardWhereInput = {
  AND?: InputMaybe<Array<ActionCardWhereInput>>;
  NOT?: InputMaybe<Array<ActionCardWhereInput>>;
  OR?: InputMaybe<Array<ActionCardWhereInput>>;
  appointmentLocation?: InputMaybe<StringNullableFilter>;
  appointmentTime?: InputMaybe<DateTimeNullableFilter>;
  assignee?: InputMaybe<EnumAssigneeTypeNullableFilter>;
  calendarIcs?: InputMaybe<StringNullableFilter>;
  calendarLink?: InputMaybe<StringNullableFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  completedBy?: InputMaybe<StringNullableFilter>;
  component?: InputMaybe<ComponentNullableRelationFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  documentDownloadUrl?: InputMaybe<StringNullableFilter>;
  documentSignUrl?: InputMaybe<StringNullableFilter>;
  documentUploadUrl?: InputMaybe<StringNullableFilter>;
  encryptedReply?: InputMaybe<BoolFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateDetail?: InputMaybe<EstateDetailNullableRelationFilter>;
  estateDetailId?: InputMaybe<StringNullableFilter>;
  estateId?: InputMaybe<StringFilter>;
  followUpActionCard?: InputMaybe<JsonNullableFilter>;
  formFieldName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  locked?: InputMaybe<BoolFilter>;
  mapUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  notifications?: InputMaybe<EstateNotificationListRelationFilter>;
  promoted?: InputMaybe<BoolFilter>;
  requiresFollowUp?: InputMaybe<BoolFilter>;
  status?: InputMaybe<EnumActionCardStatusFilter>;
  task?: InputMaybe<TaskNullableRelationFilter>;
  taskId?: InputMaybe<StringNullableFilter>;
  taskName?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumActionCardTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ActionCardWhereInput2 = {
  AND?: InputMaybe<Array<ActionCardWhereInput2>>;
  NOT?: InputMaybe<Array<ActionCardWhereInput2>>;
  OR?: InputMaybe<Array<ActionCardWhereInput2>>;
  assignee?: InputMaybe<EnumAssigneeTypeNullableFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  completedBy?: InputMaybe<StringNullableFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  estateDetailId?: InputMaybe<StringNullableFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  locked?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumActionCardStatusFilter>;
  type?: InputMaybe<EnumActionCardTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ActionCardWhereUniqueInput = {
  AND?: InputMaybe<Array<ActionCardWhereInput>>;
  NOT?: InputMaybe<Array<ActionCardWhereInput>>;
  OR?: InputMaybe<Array<ActionCardWhereInput>>;
  appointmentLocation?: InputMaybe<StringNullableFilter>;
  appointmentTime?: InputMaybe<DateTimeNullableFilter>;
  assignee?: InputMaybe<EnumAssigneeTypeNullableFilter>;
  calendarIcs?: InputMaybe<StringNullableFilter>;
  calendarLink?: InputMaybe<StringNullableFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  completedBy?: InputMaybe<StringNullableFilter>;
  component?: InputMaybe<ComponentNullableRelationFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  documentDownloadUrl?: InputMaybe<StringNullableFilter>;
  documentSignUrl?: InputMaybe<StringNullableFilter>;
  documentUploadUrl?: InputMaybe<StringNullableFilter>;
  encryptedReply?: InputMaybe<BoolFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateDetail?: InputMaybe<EstateDetailNullableRelationFilter>;
  estateDetailId?: InputMaybe<Scalars["String"]["input"]>;
  estateId?: InputMaybe<StringFilter>;
  followUpActionCard?: InputMaybe<JsonNullableFilter>;
  formFieldName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  locked?: InputMaybe<BoolFilter>;
  mapUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  notifications?: InputMaybe<EstateNotificationListRelationFilter>;
  promoted?: InputMaybe<BoolFilter>;
  requiresFollowUp?: InputMaybe<BoolFilter>;
  status?: InputMaybe<EnumActionCardStatusFilter>;
  task?: InputMaybe<TaskNullableRelationFilter>;
  taskId?: InputMaybe<StringNullableFilter>;
  taskName?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumActionCardTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ActionCardWhereUniqueInput2 = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Address = {
  __typename?: "Address";
  Contact: Array<Contact>;
  Deceased: Array<Deceased>;
  PodDeceased: Array<Deceased>;
  User: Array<User>;
  _count?: Maybe<AddressCount>;
  address?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  state?: Maybe<Scalars["String"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type AddressContactArgs = {
  cursor?: InputMaybe<ContactWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContactScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContactOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContactWhereInput>;
};

export type AddressDeceasedArgs = {
  cursor?: InputMaybe<DeceasedWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeceasedScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeceasedOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeceasedWhereInput>;
};

export type AddressPodDeceasedArgs = {
  cursor?: InputMaybe<DeceasedWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeceasedScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeceasedOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeceasedWhereInput>;
};

export type AddressUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserWhereInput>;
};

export type Address2 = {
  __typename?: "Address2";
  address: Scalars["String"]["output"];
  city: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  state: Scalars["String"]["output"];
  zip: Scalars["String"]["output"];
};

export type AddressCount = {
  __typename?: "AddressCount";
  Contact: Scalars["Int"]["output"];
  Deceased: Scalars["Int"]["output"];
  PodDeceased: Scalars["Int"]["output"];
  User: Scalars["Int"]["output"];
};

export type AddressCountContactArgs = {
  where?: InputMaybe<ContactWhereInput>;
};

export type AddressCountDeceasedArgs = {
  where?: InputMaybe<DeceasedWhereInput>;
};

export type AddressCountPodDeceasedArgs = {
  where?: InputMaybe<DeceasedWhereInput>;
};

export type AddressCountUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type AddressCountAggregate = {
  __typename?: "AddressCountAggregate";
  _all: Scalars["Int"]["output"];
  address: Scalars["Int"]["output"];
  city: Scalars["Int"]["output"];
  country: Scalars["Int"]["output"];
  county: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  state: Scalars["Int"]["output"];
  zip: Scalars["Int"]["output"];
};

export type AddressCountOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  county?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type AddressCreateInput = {
  Contact?: InputMaybe<ContactCreateNestedManyWithoutAddressInput>;
  Deceased?: InputMaybe<DeceasedCreateNestedManyWithoutLastKnownAddressInput>;
  PodDeceased?: InputMaybe<DeceasedCreateNestedManyWithoutPlaceOfDeathAddressInput>;
  User?: InputMaybe<UserCreateNestedManyWithoutCurrentAddressInput>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressCreateManyInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressCreateNestedOneWithoutContactInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutContactInput>;
  create?: InputMaybe<AddressCreateWithoutContactInput>;
};

export type AddressCreateNestedOneWithoutDeceasedInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutDeceasedInput>;
  create?: InputMaybe<AddressCreateWithoutDeceasedInput>;
};

export type AddressCreateNestedOneWithoutPodDeceasedInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutPodDeceasedInput>;
  create?: InputMaybe<AddressCreateWithoutPodDeceasedInput>;
};

export type AddressCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<AddressCreateWithoutUserInput>;
};

export type AddressCreateOrConnectWithoutContactInput = {
  create: AddressCreateWithoutContactInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutDeceasedInput = {
  create: AddressCreateWithoutDeceasedInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutPodDeceasedInput = {
  create: AddressCreateWithoutPodDeceasedInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutUserInput = {
  create: AddressCreateWithoutUserInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateWithoutContactInput = {
  Deceased?: InputMaybe<DeceasedCreateNestedManyWithoutLastKnownAddressInput>;
  PodDeceased?: InputMaybe<DeceasedCreateNestedManyWithoutPlaceOfDeathAddressInput>;
  User?: InputMaybe<UserCreateNestedManyWithoutCurrentAddressInput>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressCreateWithoutDeceasedInput = {
  Contact?: InputMaybe<ContactCreateNestedManyWithoutAddressInput>;
  PodDeceased?: InputMaybe<DeceasedCreateNestedManyWithoutPlaceOfDeathAddressInput>;
  User?: InputMaybe<UserCreateNestedManyWithoutCurrentAddressInput>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressCreateWithoutPodDeceasedInput = {
  Contact?: InputMaybe<ContactCreateNestedManyWithoutAddressInput>;
  Deceased?: InputMaybe<DeceasedCreateNestedManyWithoutLastKnownAddressInput>;
  User?: InputMaybe<UserCreateNestedManyWithoutCurrentAddressInput>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressCreateWithoutUserInput = {
  Contact?: InputMaybe<ContactCreateNestedManyWithoutAddressInput>;
  Deceased?: InputMaybe<DeceasedCreateNestedManyWithoutLastKnownAddressInput>;
  PodDeceased?: InputMaybe<DeceasedCreateNestedManyWithoutPlaceOfDeathAddressInput>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressGroupBy = {
  __typename?: "AddressGroupBy";
  _count?: Maybe<AddressCountAggregate>;
  _max?: Maybe<AddressMaxAggregate>;
  _min?: Maybe<AddressMinAggregate>;
  address?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  state?: Maybe<Scalars["String"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type AddressInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressMaxAggregate = {
  __typename?: "AddressMaxAggregate";
  address?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type AddressMaxOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  county?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type AddressMinAggregate = {
  __typename?: "AddressMinAggregate";
  address?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type AddressMinOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  county?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type AddressNullableRelationFilter = {
  is?: InputMaybe<AddressWhereInput>;
  isNot?: InputMaybe<AddressWhereInput>;
};

export type AddressOrderByWithAggregationInput = {
  _count?: InputMaybe<AddressCountOrderByAggregateInput>;
  _max?: InputMaybe<AddressMaxOrderByAggregateInput>;
  _min?: InputMaybe<AddressMinOrderByAggregateInput>;
  address?: InputMaybe<SortOrderInput>;
  city?: InputMaybe<SortOrderInput>;
  country?: InputMaybe<SortOrderInput>;
  county?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrderInput>;
  zip?: InputMaybe<SortOrderInput>;
};

export type AddressOrderByWithRelationInput = {
  Contact?: InputMaybe<ContactOrderByRelationAggregateInput>;
  Deceased?: InputMaybe<DeceasedOrderByRelationAggregateInput>;
  PodDeceased?: InputMaybe<DeceasedOrderByRelationAggregateInput>;
  User?: InputMaybe<UserOrderByRelationAggregateInput>;
  address?: InputMaybe<SortOrderInput>;
  city?: InputMaybe<SortOrderInput>;
  country?: InputMaybe<SortOrderInput>;
  county?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrderInput>;
  zip?: InputMaybe<SortOrderInput>;
};

export enum AddressScalarFieldEnum {
  Address = "address",
  City = "city",
  Country = "country",
  County = "county",
  Id = "id",
  State = "state",
  Zip = "zip",
}

export type AddressScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<AddressScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<AddressScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<AddressScalarWhereWithAggregatesInput>>;
  address?: InputMaybe<StringNullableWithAggregatesFilter>;
  city?: InputMaybe<StringNullableWithAggregatesFilter>;
  country?: InputMaybe<StringNullableWithAggregatesFilter>;
  county?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  state?: InputMaybe<StringNullableWithAggregatesFilter>;
  zip?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type AddressUpdateInput = {
  Contact?: InputMaybe<ContactUpdateManyWithoutAddressNestedInput>;
  Deceased?: InputMaybe<DeceasedUpdateManyWithoutLastKnownAddressNestedInput>;
  PodDeceased?: InputMaybe<DeceasedUpdateManyWithoutPlaceOfDeathAddressNestedInput>;
  User?: InputMaybe<UserUpdateManyWithoutCurrentAddressNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type AddressUpdateManyMutationInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type AddressUpdateOneWithoutContactNestedInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutContactInput>;
  create?: InputMaybe<AddressCreateWithoutContactInput>;
  delete?: InputMaybe<AddressWhereInput>;
  disconnect?: InputMaybe<AddressWhereInput>;
  update?: InputMaybe<AddressUpdateToOneWithWhereWithoutContactInput>;
  upsert?: InputMaybe<AddressUpsertWithoutContactInput>;
};

export type AddressUpdateOneWithoutDeceasedNestedInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutDeceasedInput>;
  create?: InputMaybe<AddressCreateWithoutDeceasedInput>;
  delete?: InputMaybe<AddressWhereInput>;
  disconnect?: InputMaybe<AddressWhereInput>;
  update?: InputMaybe<AddressUpdateToOneWithWhereWithoutDeceasedInput>;
  upsert?: InputMaybe<AddressUpsertWithoutDeceasedInput>;
};

export type AddressUpdateOneWithoutPodDeceasedNestedInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutPodDeceasedInput>;
  create?: InputMaybe<AddressCreateWithoutPodDeceasedInput>;
  delete?: InputMaybe<AddressWhereInput>;
  disconnect?: InputMaybe<AddressWhereInput>;
  update?: InputMaybe<AddressUpdateToOneWithWhereWithoutPodDeceasedInput>;
  upsert?: InputMaybe<AddressUpsertWithoutPodDeceasedInput>;
};

export type AddressUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<AddressCreateWithoutUserInput>;
  delete?: InputMaybe<AddressWhereInput>;
  disconnect?: InputMaybe<AddressWhereInput>;
  update?: InputMaybe<AddressUpdateToOneWithWhereWithoutUserInput>;
  upsert?: InputMaybe<AddressUpsertWithoutUserInput>;
};

export type AddressUpdateToOneWithWhereWithoutContactInput = {
  data: AddressUpdateWithoutContactInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type AddressUpdateToOneWithWhereWithoutDeceasedInput = {
  data: AddressUpdateWithoutDeceasedInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type AddressUpdateToOneWithWhereWithoutPodDeceasedInput = {
  data: AddressUpdateWithoutPodDeceasedInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type AddressUpdateToOneWithWhereWithoutUserInput = {
  data: AddressUpdateWithoutUserInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type AddressUpdateWithoutContactInput = {
  Deceased?: InputMaybe<DeceasedUpdateManyWithoutLastKnownAddressNestedInput>;
  PodDeceased?: InputMaybe<DeceasedUpdateManyWithoutPlaceOfDeathAddressNestedInput>;
  User?: InputMaybe<UserUpdateManyWithoutCurrentAddressNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type AddressUpdateWithoutDeceasedInput = {
  Contact?: InputMaybe<ContactUpdateManyWithoutAddressNestedInput>;
  PodDeceased?: InputMaybe<DeceasedUpdateManyWithoutPlaceOfDeathAddressNestedInput>;
  User?: InputMaybe<UserUpdateManyWithoutCurrentAddressNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type AddressUpdateWithoutPodDeceasedInput = {
  Contact?: InputMaybe<ContactUpdateManyWithoutAddressNestedInput>;
  Deceased?: InputMaybe<DeceasedUpdateManyWithoutLastKnownAddressNestedInput>;
  User?: InputMaybe<UserUpdateManyWithoutCurrentAddressNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type AddressUpdateWithoutUserInput = {
  Contact?: InputMaybe<ContactUpdateManyWithoutAddressNestedInput>;
  Deceased?: InputMaybe<DeceasedUpdateManyWithoutLastKnownAddressNestedInput>;
  PodDeceased?: InputMaybe<DeceasedUpdateManyWithoutPlaceOfDeathAddressNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type AddressUpsertWithoutContactInput = {
  create: AddressCreateWithoutContactInput;
  update: AddressUpdateWithoutContactInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type AddressUpsertWithoutDeceasedInput = {
  create: AddressCreateWithoutDeceasedInput;
  update: AddressUpdateWithoutDeceasedInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type AddressUpsertWithoutPodDeceasedInput = {
  create: AddressCreateWithoutPodDeceasedInput;
  update: AddressUpdateWithoutPodDeceasedInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type AddressUpsertWithoutUserInput = {
  create: AddressCreateWithoutUserInput;
  update: AddressUpdateWithoutUserInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type AddressWhereInput = {
  AND?: InputMaybe<Array<AddressWhereInput>>;
  Contact?: InputMaybe<ContactListRelationFilter>;
  Deceased?: InputMaybe<DeceasedListRelationFilter>;
  NOT?: InputMaybe<Array<AddressWhereInput>>;
  OR?: InputMaybe<Array<AddressWhereInput>>;
  PodDeceased?: InputMaybe<DeceasedListRelationFilter>;
  User?: InputMaybe<UserListRelationFilter>;
  address?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  county?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringNullableFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type AddressWhereUniqueInput = {
  AND?: InputMaybe<Array<AddressWhereInput>>;
  Contact?: InputMaybe<ContactListRelationFilter>;
  Deceased?: InputMaybe<DeceasedListRelationFilter>;
  NOT?: InputMaybe<Array<AddressWhereInput>>;
  OR?: InputMaybe<Array<AddressWhereInput>>;
  PodDeceased?: InputMaybe<DeceasedListRelationFilter>;
  User?: InputMaybe<UserListRelationFilter>;
  address?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  county?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<StringNullableFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type AdminCreateUserWithEstateInput = {
  email: Scalars["String"]["input"];
  estates?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutUserInput2>;
  externalId: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<UserType>;
  userRole?: InputMaybe<UserEstateRole>;
};

export type AffectedRowsOutput = {
  __typename?: "AffectedRowsOutput";
  count: Scalars["Int"]["output"];
};

export type AggregateActionCard = {
  __typename?: "AggregateActionCard";
  _count?: Maybe<ActionCardCountAggregate>;
  _max?: Maybe<ActionCardMaxAggregate>;
  _min?: Maybe<ActionCardMinAggregate>;
};

export type AggregateAddress = {
  __typename?: "AggregateAddress";
  _count?: Maybe<AddressCountAggregate>;
  _max?: Maybe<AddressMaxAggregate>;
  _min?: Maybe<AddressMinAggregate>;
};

export type AggregateAlixFee = {
  __typename?: "AggregateAlixFee";
  _avg?: Maybe<AlixFeeAvgAggregate>;
  _count?: Maybe<AlixFeeCountAggregate>;
  _max?: Maybe<AlixFeeMaxAggregate>;
  _min?: Maybe<AlixFeeMinAggregate>;
  _sum?: Maybe<AlixFeeSumAggregate>;
};

export type AggregateAsset = {
  __typename?: "AggregateAsset";
  _avg?: Maybe<AssetAvgAggregate>;
  _count?: Maybe<AssetCountAggregate>;
  _max?: Maybe<AssetMaxAggregate>;
  _min?: Maybe<AssetMinAggregate>;
  _sum?: Maybe<AssetSumAggregate>;
};

export type AggregateAssetAccount = {
  __typename?: "AggregateAssetAccount";
  _avg?: Maybe<AssetAccountAvgAggregate>;
  _count?: Maybe<AssetAccountCountAggregate>;
  _max?: Maybe<AssetAccountMaxAggregate>;
  _min?: Maybe<AssetAccountMinAggregate>;
  _sum?: Maybe<AssetAccountSumAggregate>;
};

export type AggregateBeneficiary = {
  __typename?: "AggregateBeneficiary";
  _count?: Maybe<BeneficiaryCountAggregate>;
  _max?: Maybe<BeneficiaryMaxAggregate>;
  _min?: Maybe<BeneficiaryMinAggregate>;
};

export type AggregateBeneficiaryDistribution = {
  __typename?: "AggregateBeneficiaryDistribution";
  _avg?: Maybe<BeneficiaryDistributionAvgAggregate>;
  _count?: Maybe<BeneficiaryDistributionCountAggregate>;
  _max?: Maybe<BeneficiaryDistributionMaxAggregate>;
  _min?: Maybe<BeneficiaryDistributionMinAggregate>;
  _sum?: Maybe<BeneficiaryDistributionSumAggregate>;
};

export type AggregateCareTeamNote = {
  __typename?: "AggregateCareTeamNote";
  _count?: Maybe<CareTeamNoteCountAggregate>;
  _max?: Maybe<CareTeamNoteMaxAggregate>;
  _min?: Maybe<CareTeamNoteMinAggregate>;
};

export type AggregateComponent = {
  __typename?: "AggregateComponent";
  _avg?: Maybe<ComponentAvgAggregate>;
  _count?: Maybe<ComponentCountAggregate>;
  _max?: Maybe<ComponentMaxAggregate>;
  _min?: Maybe<ComponentMinAggregate>;
  _sum?: Maybe<ComponentSumAggregate>;
};

export type AggregateContact = {
  __typename?: "AggregateContact";
  _count?: Maybe<ContactCountAggregate>;
  _max?: Maybe<ContactMaxAggregate>;
  _min?: Maybe<ContactMinAggregate>;
};

export type AggregateContactsOnEstates = {
  __typename?: "AggregateContactsOnEstates";
  _count?: Maybe<ContactsOnEstatesCountAggregate>;
  _max?: Maybe<ContactsOnEstatesMaxAggregate>;
  _min?: Maybe<ContactsOnEstatesMinAggregate>;
};

export type AggregateContent = {
  __typename?: "AggregateContent";
  _avg?: Maybe<ContentAvgAggregate>;
  _count?: Maybe<ContentCountAggregate>;
  _max?: Maybe<ContentMaxAggregate>;
  _min?: Maybe<ContentMinAggregate>;
  _sum?: Maybe<ContentSumAggregate>;
};

export type AggregateDeceased = {
  __typename?: "AggregateDeceased";
  _count?: Maybe<DeceasedCountAggregate>;
  _max?: Maybe<DeceasedMaxAggregate>;
  _min?: Maybe<DeceasedMinAggregate>;
};

export type AggregateDeceasedContacts = {
  __typename?: "AggregateDeceasedContacts";
  _count?: Maybe<DeceasedContactsCountAggregate>;
  _max?: Maybe<DeceasedContactsMaxAggregate>;
  _min?: Maybe<DeceasedContactsMinAggregate>;
};

export type AggregateDeviceRegistration = {
  __typename?: "AggregateDeviceRegistration";
  _count?: Maybe<DeviceRegistrationCountAggregate>;
  _max?: Maybe<DeviceRegistrationMaxAggregate>;
  _min?: Maybe<DeviceRegistrationMinAggregate>;
};

export type AggregateDocumentsProcessed = {
  __typename?: "AggregateDocumentsProcessed";
  _avg?: Maybe<DocumentsProcessedAvgAggregate>;
  _count?: Maybe<DocumentsProcessedCountAggregate>;
  _max?: Maybe<DocumentsProcessedMaxAggregate>;
  _min?: Maybe<DocumentsProcessedMinAggregate>;
  _sum?: Maybe<DocumentsProcessedSumAggregate>;
};

export type AggregateEula = {
  __typename?: "AggregateEULA";
  _count?: Maybe<EulaCountAggregate>;
  _max?: Maybe<EulaMaxAggregate>;
  _min?: Maybe<EulaMinAggregate>;
};

export type AggregateEulaAcceptanceHistory = {
  __typename?: "AggregateEULAAcceptanceHistory";
  _count?: Maybe<EulaAcceptanceHistoryCountAggregate>;
  _max?: Maybe<EulaAcceptanceHistoryMaxAggregate>;
  _min?: Maybe<EulaAcceptanceHistoryMinAggregate>;
};

export type AggregateEstate = {
  __typename?: "AggregateEstate";
  _avg?: Maybe<EstateAvgAggregate>;
  _count?: Maybe<EstateCountAggregate>;
  _max?: Maybe<EstateMaxAggregate>;
  _min?: Maybe<EstateMinAggregate>;
  _sum?: Maybe<EstateSumAggregate>;
};

export type AggregateEstateDetail = {
  __typename?: "AggregateEstateDetail";
  _count?: Maybe<EstateDetailCountAggregate>;
  _max?: Maybe<EstateDetailMaxAggregate>;
  _min?: Maybe<EstateDetailMinAggregate>;
};

export type AggregateEstateNotification = {
  __typename?: "AggregateEstateNotification";
  _count?: Maybe<EstateNotificationCountAggregate>;
  _max?: Maybe<EstateNotificationMaxAggregate>;
  _min?: Maybe<EstateNotificationMinAggregate>;
};

export type AggregateExpense = {
  __typename?: "AggregateExpense";
  _avg?: Maybe<ExpenseAvgAggregate>;
  _count?: Maybe<ExpenseCountAggregate>;
  _max?: Maybe<ExpenseMaxAggregate>;
  _min?: Maybe<ExpenseMinAggregate>;
  _sum?: Maybe<ExpenseSumAggregate>;
};

export type AggregateLinkedAccount = {
  __typename?: "AggregateLinkedAccount";
  _avg?: Maybe<LinkedAccountAvgAggregate>;
  _count?: Maybe<LinkedAccountCountAggregate>;
  _max?: Maybe<LinkedAccountMaxAggregate>;
  _min?: Maybe<LinkedAccountMinAggregate>;
  _sum?: Maybe<LinkedAccountSumAggregate>;
};

export type AggregateMilestone = {
  __typename?: "AggregateMilestone";
  _count?: Maybe<MilestoneCountAggregate>;
  _max?: Maybe<MilestoneMaxAggregate>;
  _min?: Maybe<MilestoneMinAggregate>;
};

export type AggregateTask = {
  __typename?: "AggregateTask";
  _avg?: Maybe<TaskAvgAggregate>;
  _count?: Maybe<TaskCountAggregate>;
  _max?: Maybe<TaskMaxAggregate>;
  _min?: Maybe<TaskMinAggregate>;
  _sum?: Maybe<TaskSumAggregate>;
};

export type AggregateTransaction = {
  __typename?: "AggregateTransaction";
  _avg?: Maybe<TransactionAvgAggregate>;
  _count?: Maybe<TransactionCountAggregate>;
  _max?: Maybe<TransactionMaxAggregate>;
  _min?: Maybe<TransactionMinAggregate>;
  _sum?: Maybe<TransactionSumAggregate>;
};

export type AggregateUser = {
  __typename?: "AggregateUser";
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
};

export type AggregateUserLogin = {
  __typename?: "AggregateUserLogin";
  _count?: Maybe<UserLoginCountAggregate>;
  _max?: Maybe<UserLoginMaxAggregate>;
  _min?: Maybe<UserLoginMinAggregate>;
};

export type AggregateUserNotification = {
  __typename?: "AggregateUserNotification";
  _count?: Maybe<UserNotificationCountAggregate>;
  _max?: Maybe<UserNotificationMaxAggregate>;
  _min?: Maybe<UserNotificationMinAggregate>;
};

export type AggregateUsersOnEstates = {
  __typename?: "AggregateUsersOnEstates";
  _count?: Maybe<UsersOnEstatesCountAggregate>;
  _max?: Maybe<UsersOnEstatesMaxAggregate>;
  _min?: Maybe<UsersOnEstatesMinAggregate>;
};

export type AlixFee = {
  __typename?: "AlixFee";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  estate: Estate;
  estateId: Scalars["String"]["output"];
  floor?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["String"]["output"];
  invoicedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  type: FeeType;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type AlixFee2 = {
  __typename?: "AlixFee2";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  estateId: Scalars["String"]["output"];
  floor?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["String"]["output"];
  invoicedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  type: AlixFeeType2;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type AlixFeeAvgAggregate = {
  __typename?: "AlixFeeAvgAggregate";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  floor?: Maybe<Scalars["Decimal"]["output"]>;
};

export type AlixFeeAvgOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  floor?: InputMaybe<SortOrder>;
};

export type AlixFeeCountAggregate = {
  __typename?: "AlixFeeCountAggregate";
  _all: Scalars["Int"]["output"];
  amount: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  estateId: Scalars["Int"]["output"];
  floor: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  invoicedAt: Scalars["Int"]["output"];
  type: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type AlixFeeCountOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  floor?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoicedAt?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AlixFeeCreateInput = {
  amount?: InputMaybe<Scalars["Decimal"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estate: EstateCreateNestedOneWithoutAlixFeeInput;
  floor?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  invoicedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  type?: InputMaybe<FeeType>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type AlixFeeCreateManyInput = {
  amount?: InputMaybe<Scalars["Decimal"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estateId: Scalars["String"]["input"];
  floor?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  invoicedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  type?: InputMaybe<FeeType>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type AlixFeeCreateNestedOneWithoutEstateInput = {
  connect?: InputMaybe<AlixFeeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlixFeeCreateOrConnectWithoutEstateInput>;
  create?: InputMaybe<AlixFeeCreateWithoutEstateInput>;
};

export type AlixFeeCreateOrConnectWithoutEstateInput = {
  create: AlixFeeCreateWithoutEstateInput;
  where: AlixFeeWhereUniqueInput;
};

export type AlixFeeCreateWithoutEstateInput = {
  amount?: InputMaybe<Scalars["Decimal"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  floor?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  invoicedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  type?: InputMaybe<FeeType>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type AlixFeeDisplay = {
  __typename?: "AlixFeeDisplay";
  amount: Scalars["Decimal"]["output"];
  status: Scalars["String"]["output"];
  type: AlixFeeType2;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type AlixFeeGroupBy = {
  __typename?: "AlixFeeGroupBy";
  _avg?: Maybe<AlixFeeAvgAggregate>;
  _count?: Maybe<AlixFeeCountAggregate>;
  _max?: Maybe<AlixFeeMaxAggregate>;
  _min?: Maybe<AlixFeeMinAggregate>;
  _sum?: Maybe<AlixFeeSumAggregate>;
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  estateId: Scalars["String"]["output"];
  floor?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["String"]["output"];
  invoicedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  type: FeeType;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type AlixFeeMaxAggregate = {
  __typename?: "AlixFeeMaxAggregate";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  floor?: Maybe<Scalars["Decimal"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  invoicedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  type?: Maybe<FeeType>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type AlixFeeMaxOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  floor?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoicedAt?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AlixFeeMinAggregate = {
  __typename?: "AlixFeeMinAggregate";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  floor?: Maybe<Scalars["Decimal"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  invoicedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  type?: Maybe<FeeType>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type AlixFeeMinOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  floor?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoicedAt?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AlixFeeNullableRelationFilter = {
  is?: InputMaybe<AlixFeeWhereInput>;
  isNot?: InputMaybe<AlixFeeWhereInput>;
};

export type AlixFeeOrderByWithAggregationInput = {
  _avg?: InputMaybe<AlixFeeAvgOrderByAggregateInput>;
  _count?: InputMaybe<AlixFeeCountOrderByAggregateInput>;
  _max?: InputMaybe<AlixFeeMaxOrderByAggregateInput>;
  _min?: InputMaybe<AlixFeeMinOrderByAggregateInput>;
  _sum?: InputMaybe<AlixFeeSumOrderByAggregateInput>;
  amount?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  floor?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  invoicedAt?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AlixFeeOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  estate?: InputMaybe<EstateOrderByWithRelationInput>;
  estateId?: InputMaybe<SortOrder>;
  floor?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  invoicedAt?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum AlixFeeScalarFieldEnum {
  Amount = "amount",
  CreatedAt = "createdAt",
  EstateId = "estateId",
  Floor = "floor",
  Id = "id",
  InvoicedAt = "invoicedAt",
  Type = "type",
  UpdatedAt = "updatedAt",
}

export type AlixFeeScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<AlixFeeScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<AlixFeeScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<AlixFeeScalarWhereWithAggregatesInput>>;
  amount?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  estateId?: InputMaybe<StringWithAggregatesFilter>;
  floor?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  invoicedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumFeeTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type AlixFeeSumAggregate = {
  __typename?: "AlixFeeSumAggregate";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  floor?: Maybe<Scalars["Decimal"]["output"]>;
};

export type AlixFeeSumOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  floor?: InputMaybe<SortOrder>;
};

/** This is used to determine whether the fee will be calculated as a flat amount or a percentage of the total estate value */
export enum AlixFeeType2 {
  Flat = "FLAT",
  Percentage = "PERCENTAGE",
}

export type AlixFeeUpdateInput = {
  amount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutAlixFeeNestedInput>;
  floor?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoicedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumFeeTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AlixFeeUpdateManyMutationInput = {
  amount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  floor?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoicedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumFeeTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AlixFeeUpdateOneWithoutEstateNestedInput = {
  connect?: InputMaybe<AlixFeeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlixFeeCreateOrConnectWithoutEstateInput>;
  create?: InputMaybe<AlixFeeCreateWithoutEstateInput>;
  delete?: InputMaybe<AlixFeeWhereInput>;
  disconnect?: InputMaybe<AlixFeeWhereInput>;
  update?: InputMaybe<AlixFeeUpdateToOneWithWhereWithoutEstateInput>;
  upsert?: InputMaybe<AlixFeeUpsertWithoutEstateInput>;
};

export type AlixFeeUpdateToOneWithWhereWithoutEstateInput = {
  data: AlixFeeUpdateWithoutEstateInput;
  where?: InputMaybe<AlixFeeWhereInput>;
};

export type AlixFeeUpdateWithoutEstateInput = {
  amount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  floor?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoicedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumFeeTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AlixFeeUpsertWithoutEstateInput = {
  create: AlixFeeCreateWithoutEstateInput;
  update: AlixFeeUpdateWithoutEstateInput;
  where?: InputMaybe<AlixFeeWhereInput>;
};

export type AlixFeeWhereInput = {
  AND?: InputMaybe<Array<AlixFeeWhereInput>>;
  NOT?: InputMaybe<Array<AlixFeeWhereInput>>;
  OR?: InputMaybe<Array<AlixFeeWhereInput>>;
  amount?: InputMaybe<DecimalNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  floor?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  invoicedAt?: InputMaybe<DateTimeNullableFilter>;
  type?: InputMaybe<EnumFeeTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AlixFeeWhereUniqueInput = {
  AND?: InputMaybe<Array<AlixFeeWhereInput>>;
  NOT?: InputMaybe<Array<AlixFeeWhereInput>>;
  OR?: InputMaybe<Array<AlixFeeWhereInput>>;
  amount?: InputMaybe<DecimalNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  invoicedAt?: InputMaybe<DateTimeNullableFilter>;
  type?: InputMaybe<EnumFeeTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Asset = {
  __typename?: "Asset";
  assetAccount?: Maybe<AssetAccount>;
  assetAccountId?: Maybe<Scalars["String"]["output"]>;
  assetCategory: AssetCategory;
  description?: Maybe<Scalars["String"]["output"]>;
  dodValue?: Maybe<Scalars["Decimal"]["output"]>;
  estate: Estate;
  estateId: Scalars["String"]["output"];
  estimatedValue?: Maybe<Scalars["Decimal"]["output"]>;
  finalValue?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["String"]["output"];
  inTrust: Scalars["Boolean"]["output"];
  probateEstate?: Maybe<Scalars["String"]["output"]>;
  probateJurisdiction?: Maybe<Scalars["String"]["output"]>;
  status: AssetStatus;
};

export type AssetAssetAccountArgs = {
  where?: InputMaybe<AssetAccountWhereInput>;
};

export type Asset2 = {
  __typename?: "Asset2";
  assetAccount?: Maybe<AssetAccount2>;
  assetCategory: AssetCategory;
  description?: Maybe<Scalars["String"]["output"]>;
  dodValue?: Maybe<Scalars["Decimal"]["output"]>;
  estateId: Scalars["String"]["output"];
  estimatedValue?: Maybe<Scalars["Decimal"]["output"]>;
  finalValue?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["ID"]["output"];
  inTrust: Scalars["Boolean"]["output"];
  probateEstate?: Maybe<Scalars["String"]["output"]>;
  probateJurisdiction?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<AssetStatus>;
};

export type AssetAccount = {
  __typename?: "AssetAccount";
  accountNumber?: Maybe<Scalars["Decimal"]["output"]>;
  asset: Asset;
  assetId: Scalars["String"]["output"];
  assetSubcategory: AssetSubCategory;
  id: Scalars["String"]["output"];
  institution?: Maybe<Scalars["String"]["output"]>;
};

export type AssetAccount2 = {
  __typename?: "AssetAccount2";
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  assetSubcategory?: Maybe<AssetSubCategory>;
  id: Scalars["ID"]["output"];
  institution?: Maybe<Scalars["String"]["output"]>;
};

export type AssetAccountAvgAggregate = {
  __typename?: "AssetAccountAvgAggregate";
  accountNumber?: Maybe<Scalars["Decimal"]["output"]>;
};

export type AssetAccountAvgOrderByAggregateInput = {
  accountNumber?: InputMaybe<SortOrder>;
};

export type AssetAccountCountAggregate = {
  __typename?: "AssetAccountCountAggregate";
  _all: Scalars["Int"]["output"];
  accountNumber: Scalars["Int"]["output"];
  assetId: Scalars["Int"]["output"];
  assetSubcategory: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  institution: Scalars["Int"]["output"];
};

export type AssetAccountCountOrderByAggregateInput = {
  accountNumber?: InputMaybe<SortOrder>;
  assetId?: InputMaybe<SortOrder>;
  assetSubcategory?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  institution?: InputMaybe<SortOrder>;
};

export type AssetAccountCreateInput = {
  accountNumber?: InputMaybe<Scalars["Decimal"]["input"]>;
  asset: AssetCreateNestedOneWithoutAssetAccountInput;
  assetSubcategory?: InputMaybe<AssetSubCategory>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  institution?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssetAccountCreateManyInput = {
  accountNumber?: InputMaybe<Scalars["Decimal"]["input"]>;
  assetId: Scalars["String"]["input"];
  assetSubcategory?: InputMaybe<AssetSubCategory>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  institution?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssetAccountCreateNestedOneWithoutAssetInput = {
  connect?: InputMaybe<AssetAccountWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AssetAccountCreateOrConnectWithoutAssetInput>;
  create?: InputMaybe<AssetAccountCreateWithoutAssetInput>;
};

export type AssetAccountCreateOrConnectWithoutAssetInput = {
  create: AssetAccountCreateWithoutAssetInput;
  where: AssetAccountWhereUniqueInput;
};

export type AssetAccountCreateWithoutAssetInput = {
  accountNumber?: InputMaybe<Scalars["Decimal"]["input"]>;
  assetSubcategory?: InputMaybe<AssetSubCategory>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  institution?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssetAccountGroupBy = {
  __typename?: "AssetAccountGroupBy";
  _avg?: Maybe<AssetAccountAvgAggregate>;
  _count?: Maybe<AssetAccountCountAggregate>;
  _max?: Maybe<AssetAccountMaxAggregate>;
  _min?: Maybe<AssetAccountMinAggregate>;
  _sum?: Maybe<AssetAccountSumAggregate>;
  accountNumber?: Maybe<Scalars["Decimal"]["output"]>;
  assetId: Scalars["String"]["output"];
  assetSubcategory: AssetSubCategory;
  id: Scalars["String"]["output"];
  institution?: Maybe<Scalars["String"]["output"]>;
};

export type AssetAccountInput = {
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  assetSubCategory?: InputMaybe<AssetSubCategory>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  institution?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssetAccountMaxAggregate = {
  __typename?: "AssetAccountMaxAggregate";
  accountNumber?: Maybe<Scalars["Decimal"]["output"]>;
  assetId?: Maybe<Scalars["String"]["output"]>;
  assetSubcategory?: Maybe<AssetSubCategory>;
  id?: Maybe<Scalars["String"]["output"]>;
  institution?: Maybe<Scalars["String"]["output"]>;
};

export type AssetAccountMaxOrderByAggregateInput = {
  accountNumber?: InputMaybe<SortOrder>;
  assetId?: InputMaybe<SortOrder>;
  assetSubcategory?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  institution?: InputMaybe<SortOrder>;
};

export type AssetAccountMinAggregate = {
  __typename?: "AssetAccountMinAggregate";
  accountNumber?: Maybe<Scalars["Decimal"]["output"]>;
  assetId?: Maybe<Scalars["String"]["output"]>;
  assetSubcategory?: Maybe<AssetSubCategory>;
  id?: Maybe<Scalars["String"]["output"]>;
  institution?: Maybe<Scalars["String"]["output"]>;
};

export type AssetAccountMinOrderByAggregateInput = {
  accountNumber?: InputMaybe<SortOrder>;
  assetId?: InputMaybe<SortOrder>;
  assetSubcategory?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  institution?: InputMaybe<SortOrder>;
};

export type AssetAccountNullableRelationFilter = {
  is?: InputMaybe<AssetAccountWhereInput>;
  isNot?: InputMaybe<AssetAccountWhereInput>;
};

export type AssetAccountOrderByWithAggregationInput = {
  _avg?: InputMaybe<AssetAccountAvgOrderByAggregateInput>;
  _count?: InputMaybe<AssetAccountCountOrderByAggregateInput>;
  _max?: InputMaybe<AssetAccountMaxOrderByAggregateInput>;
  _min?: InputMaybe<AssetAccountMinOrderByAggregateInput>;
  _sum?: InputMaybe<AssetAccountSumOrderByAggregateInput>;
  accountNumber?: InputMaybe<SortOrderInput>;
  assetId?: InputMaybe<SortOrder>;
  assetSubcategory?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  institution?: InputMaybe<SortOrderInput>;
};

export type AssetAccountOrderByWithRelationInput = {
  accountNumber?: InputMaybe<SortOrderInput>;
  asset?: InputMaybe<AssetOrderByWithRelationInput>;
  assetId?: InputMaybe<SortOrder>;
  assetSubcategory?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  institution?: InputMaybe<SortOrderInput>;
};

export enum AssetAccountScalarFieldEnum {
  AccountNumber = "accountNumber",
  AssetId = "assetId",
  AssetSubcategory = "assetSubcategory",
  Id = "id",
  Institution = "institution",
}

export type AssetAccountScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<AssetAccountScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<AssetAccountScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<AssetAccountScalarWhereWithAggregatesInput>>;
  accountNumber?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  assetId?: InputMaybe<StringWithAggregatesFilter>;
  assetSubcategory?: InputMaybe<EnumAssetSubCategoryWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  institution?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type AssetAccountSumAggregate = {
  __typename?: "AssetAccountSumAggregate";
  accountNumber?: Maybe<Scalars["Decimal"]["output"]>;
};

export type AssetAccountSumOrderByAggregateInput = {
  accountNumber?: InputMaybe<SortOrder>;
};

export type AssetAccountUpdateInput = {
  accountNumber?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  asset?: InputMaybe<AssetUpdateOneRequiredWithoutAssetAccountNestedInput>;
  assetSubcategory?: InputMaybe<EnumAssetSubCategoryFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  institution?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type AssetAccountUpdateManyMutationInput = {
  accountNumber?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  assetSubcategory?: InputMaybe<EnumAssetSubCategoryFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  institution?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type AssetAccountUpdateOneWithoutAssetNestedInput = {
  connect?: InputMaybe<AssetAccountWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AssetAccountCreateOrConnectWithoutAssetInput>;
  create?: InputMaybe<AssetAccountCreateWithoutAssetInput>;
  delete?: InputMaybe<AssetAccountWhereInput>;
  disconnect?: InputMaybe<AssetAccountWhereInput>;
  update?: InputMaybe<AssetAccountUpdateToOneWithWhereWithoutAssetInput>;
  upsert?: InputMaybe<AssetAccountUpsertWithoutAssetInput>;
};

export type AssetAccountUpdateToOneWithWhereWithoutAssetInput = {
  data: AssetAccountUpdateWithoutAssetInput;
  where?: InputMaybe<AssetAccountWhereInput>;
};

export type AssetAccountUpdateWithoutAssetInput = {
  accountNumber?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  assetSubcategory?: InputMaybe<EnumAssetSubCategoryFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  institution?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type AssetAccountUpsertWithoutAssetInput = {
  create: AssetAccountCreateWithoutAssetInput;
  update: AssetAccountUpdateWithoutAssetInput;
  where?: InputMaybe<AssetAccountWhereInput>;
};

export type AssetAccountWhereInput = {
  AND?: InputMaybe<Array<AssetAccountWhereInput>>;
  NOT?: InputMaybe<Array<AssetAccountWhereInput>>;
  OR?: InputMaybe<Array<AssetAccountWhereInput>>;
  accountNumber?: InputMaybe<DecimalNullableFilter>;
  asset?: InputMaybe<AssetRelationFilter>;
  assetId?: InputMaybe<StringFilter>;
  assetSubcategory?: InputMaybe<EnumAssetSubCategoryFilter>;
  id?: InputMaybe<StringFilter>;
  institution?: InputMaybe<StringNullableFilter>;
};

export type AssetAccountWhereUniqueInput = {
  AND?: InputMaybe<Array<AssetAccountWhereInput>>;
  NOT?: InputMaybe<Array<AssetAccountWhereInput>>;
  OR?: InputMaybe<Array<AssetAccountWhereInput>>;
  accountNumber?: InputMaybe<DecimalNullableFilter>;
  asset?: InputMaybe<AssetRelationFilter>;
  assetId?: InputMaybe<Scalars["String"]["input"]>;
  assetSubcategory?: InputMaybe<EnumAssetSubCategoryFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  institution?: InputMaybe<StringNullableFilter>;
};

export type AssetAvgAggregate = {
  __typename?: "AssetAvgAggregate";
  dodValue?: Maybe<Scalars["Decimal"]["output"]>;
  estimatedValue?: Maybe<Scalars["Decimal"]["output"]>;
  finalValue?: Maybe<Scalars["Decimal"]["output"]>;
};

export type AssetAvgOrderByAggregateInput = {
  dodValue?: InputMaybe<SortOrder>;
  estimatedValue?: InputMaybe<SortOrder>;
  finalValue?: InputMaybe<SortOrder>;
};

export enum AssetCategory {
  BankAccount = "BankAccount",
  Investment = "Investment",
  LifeInsurance = "LifeInsurance",
  PersonalProperty = "PersonalProperty",
  RealEstate = "RealEstate",
  Retirement = "Retirement",
  UnclaimedProperty = "UnclaimedProperty",
  Vehicle = "Vehicle",
}

export type AssetCountAggregate = {
  __typename?: "AssetCountAggregate";
  _all: Scalars["Int"]["output"];
  assetAccountId: Scalars["Int"]["output"];
  assetCategory: Scalars["Int"]["output"];
  description: Scalars["Int"]["output"];
  dodValue: Scalars["Int"]["output"];
  estateId: Scalars["Int"]["output"];
  estimatedValue: Scalars["Int"]["output"];
  finalValue: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  inTrust: Scalars["Int"]["output"];
  probateEstate: Scalars["Int"]["output"];
  probateJurisdiction: Scalars["Int"]["output"];
  status: Scalars["Int"]["output"];
};

export type AssetCountOrderByAggregateInput = {
  assetAccountId?: InputMaybe<SortOrder>;
  assetCategory?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  dodValue?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  estimatedValue?: InputMaybe<SortOrder>;
  finalValue?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inTrust?: InputMaybe<SortOrder>;
  probateEstate?: InputMaybe<SortOrder>;
  probateJurisdiction?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type AssetCreateInput = {
  assetAccount?: InputMaybe<AssetAccountCreateNestedOneWithoutAssetInput>;
  assetAccountId?: InputMaybe<Scalars["String"]["input"]>;
  assetCategory?: InputMaybe<AssetCategory>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dodValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  estate: EstateCreateNestedOneWithoutAssetInput;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  probateEstate?: InputMaybe<Scalars["String"]["input"]>;
  probateJurisdiction?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<AssetStatus>;
};

export type AssetCreateManyEstateInput = {
  assetAccountId?: InputMaybe<Scalars["String"]["input"]>;
  assetCategory?: InputMaybe<AssetCategory>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dodValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  probateEstate?: InputMaybe<Scalars["String"]["input"]>;
  probateJurisdiction?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<AssetStatus>;
};

export type AssetCreateManyEstateInputEnvelope = {
  data: Array<AssetCreateManyEstateInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssetCreateManyInput = {
  assetAccountId?: InputMaybe<Scalars["String"]["input"]>;
  assetCategory?: InputMaybe<AssetCategory>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dodValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  estateId: Scalars["String"]["input"];
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  probateEstate?: InputMaybe<Scalars["String"]["input"]>;
  probateJurisdiction?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<AssetStatus>;
};

export type AssetCreateNestedManyWithoutEstateInput = {
  connect?: InputMaybe<Array<AssetWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssetCreateOrConnectWithoutEstateInput>>;
  create?: InputMaybe<Array<AssetCreateWithoutEstateInput>>;
  createMany?: InputMaybe<AssetCreateManyEstateInputEnvelope>;
};

export type AssetCreateNestedOneWithoutAssetAccountInput = {
  connect?: InputMaybe<AssetWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AssetCreateOrConnectWithoutAssetAccountInput>;
  create?: InputMaybe<AssetCreateWithoutAssetAccountInput>;
};

export type AssetCreateOrConnectWithoutAssetAccountInput = {
  create: AssetCreateWithoutAssetAccountInput;
  where: AssetWhereUniqueInput;
};

export type AssetCreateOrConnectWithoutEstateInput = {
  create: AssetCreateWithoutEstateInput;
  where: AssetWhereUniqueInput;
};

export type AssetCreateWithoutAssetAccountInput = {
  assetAccountId?: InputMaybe<Scalars["String"]["input"]>;
  assetCategory?: InputMaybe<AssetCategory>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dodValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  estate: EstateCreateNestedOneWithoutAssetInput;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  probateEstate?: InputMaybe<Scalars["String"]["input"]>;
  probateJurisdiction?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<AssetStatus>;
};

export type AssetCreateWithoutEstateInput = {
  assetAccount?: InputMaybe<AssetAccountCreateNestedOneWithoutAssetInput>;
  assetAccountId?: InputMaybe<Scalars["String"]["input"]>;
  assetCategory?: InputMaybe<AssetCategory>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dodValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  probateEstate?: InputMaybe<Scalars["String"]["input"]>;
  probateJurisdiction?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<AssetStatus>;
};

export type AssetGroupBy = {
  __typename?: "AssetGroupBy";
  _avg?: Maybe<AssetAvgAggregate>;
  _count?: Maybe<AssetCountAggregate>;
  _max?: Maybe<AssetMaxAggregate>;
  _min?: Maybe<AssetMinAggregate>;
  _sum?: Maybe<AssetSumAggregate>;
  assetAccountId?: Maybe<Scalars["String"]["output"]>;
  assetCategory: AssetCategory;
  description?: Maybe<Scalars["String"]["output"]>;
  dodValue?: Maybe<Scalars["Decimal"]["output"]>;
  estateId: Scalars["String"]["output"];
  estimatedValue?: Maybe<Scalars["Decimal"]["output"]>;
  finalValue?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["String"]["output"];
  inTrust: Scalars["Boolean"]["output"];
  probateEstate?: Maybe<Scalars["String"]["output"]>;
  probateJurisdiction?: Maybe<Scalars["String"]["output"]>;
  status: AssetStatus;
};

export type AssetInput = {
  assetAccount?: InputMaybe<AssetAccountInput>;
  assetCategory?: AssetCategory;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dodValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  estateId: Scalars["String"]["input"];
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  inTrust?: Scalars["Boolean"]["input"];
  probateEstate?: InputMaybe<Scalars["String"]["input"]>;
  probateJurisdiction?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<AssetStatus>;
};

export type AssetListRelationFilter = {
  every?: InputMaybe<AssetWhereInput>;
  none?: InputMaybe<AssetWhereInput>;
  some?: InputMaybe<AssetWhereInput>;
};

export type AssetMaxAggregate = {
  __typename?: "AssetMaxAggregate";
  assetAccountId?: Maybe<Scalars["String"]["output"]>;
  assetCategory?: Maybe<AssetCategory>;
  description?: Maybe<Scalars["String"]["output"]>;
  dodValue?: Maybe<Scalars["Decimal"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  estimatedValue?: Maybe<Scalars["Decimal"]["output"]>;
  finalValue?: Maybe<Scalars["Decimal"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  inTrust?: Maybe<Scalars["Boolean"]["output"]>;
  probateEstate?: Maybe<Scalars["String"]["output"]>;
  probateJurisdiction?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<AssetStatus>;
};

export type AssetMaxOrderByAggregateInput = {
  assetAccountId?: InputMaybe<SortOrder>;
  assetCategory?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  dodValue?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  estimatedValue?: InputMaybe<SortOrder>;
  finalValue?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inTrust?: InputMaybe<SortOrder>;
  probateEstate?: InputMaybe<SortOrder>;
  probateJurisdiction?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type AssetMinAggregate = {
  __typename?: "AssetMinAggregate";
  assetAccountId?: Maybe<Scalars["String"]["output"]>;
  assetCategory?: Maybe<AssetCategory>;
  description?: Maybe<Scalars["String"]["output"]>;
  dodValue?: Maybe<Scalars["Decimal"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  estimatedValue?: Maybe<Scalars["Decimal"]["output"]>;
  finalValue?: Maybe<Scalars["Decimal"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  inTrust?: Maybe<Scalars["Boolean"]["output"]>;
  probateEstate?: Maybe<Scalars["String"]["output"]>;
  probateJurisdiction?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<AssetStatus>;
};

export type AssetMinOrderByAggregateInput = {
  assetAccountId?: InputMaybe<SortOrder>;
  assetCategory?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  dodValue?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  estimatedValue?: InputMaybe<SortOrder>;
  finalValue?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inTrust?: InputMaybe<SortOrder>;
  probateEstate?: InputMaybe<SortOrder>;
  probateJurisdiction?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type AssetOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AssetOrderByWithAggregationInput = {
  _avg?: InputMaybe<AssetAvgOrderByAggregateInput>;
  _count?: InputMaybe<AssetCountOrderByAggregateInput>;
  _max?: InputMaybe<AssetMaxOrderByAggregateInput>;
  _min?: InputMaybe<AssetMinOrderByAggregateInput>;
  _sum?: InputMaybe<AssetSumOrderByAggregateInput>;
  assetAccountId?: InputMaybe<SortOrderInput>;
  assetCategory?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  dodValue?: InputMaybe<SortOrderInput>;
  estateId?: InputMaybe<SortOrder>;
  estimatedValue?: InputMaybe<SortOrderInput>;
  finalValue?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  inTrust?: InputMaybe<SortOrder>;
  probateEstate?: InputMaybe<SortOrderInput>;
  probateJurisdiction?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
};

export type AssetOrderByWithRelationInput = {
  assetAccount?: InputMaybe<AssetAccountOrderByWithRelationInput>;
  assetAccountId?: InputMaybe<SortOrderInput>;
  assetCategory?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  dodValue?: InputMaybe<SortOrderInput>;
  estate?: InputMaybe<EstateOrderByWithRelationInput>;
  estateId?: InputMaybe<SortOrder>;
  estimatedValue?: InputMaybe<SortOrderInput>;
  finalValue?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  inTrust?: InputMaybe<SortOrder>;
  probateEstate?: InputMaybe<SortOrderInput>;
  probateJurisdiction?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
};

export type AssetRelationFilter = {
  is?: InputMaybe<AssetWhereInput>;
  isNot?: InputMaybe<AssetWhereInput>;
};

export enum AssetScalarFieldEnum {
  AssetAccountId = "assetAccountId",
  AssetCategory = "assetCategory",
  Description = "description",
  DodValue = "dodValue",
  EstateId = "estateId",
  EstimatedValue = "estimatedValue",
  FinalValue = "finalValue",
  Id = "id",
  InTrust = "inTrust",
  ProbateEstate = "probateEstate",
  ProbateJurisdiction = "probateJurisdiction",
  Status = "status",
}

export type AssetScalarWhereInput = {
  AND?: InputMaybe<Array<AssetScalarWhereInput>>;
  NOT?: InputMaybe<Array<AssetScalarWhereInput>>;
  OR?: InputMaybe<Array<AssetScalarWhereInput>>;
  assetAccountId?: InputMaybe<StringNullableFilter>;
  assetCategory?: InputMaybe<EnumAssetCategoryFilter>;
  description?: InputMaybe<StringNullableFilter>;
  dodValue?: InputMaybe<DecimalNullableFilter>;
  estateId?: InputMaybe<StringFilter>;
  estimatedValue?: InputMaybe<DecimalNullableFilter>;
  finalValue?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inTrust?: InputMaybe<BoolFilter>;
  probateEstate?: InputMaybe<StringNullableFilter>;
  probateJurisdiction?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumAssetStatusFilter>;
};

export type AssetScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<AssetScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<AssetScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<AssetScalarWhereWithAggregatesInput>>;
  assetAccountId?: InputMaybe<StringNullableWithAggregatesFilter>;
  assetCategory?: InputMaybe<EnumAssetCategoryWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  dodValue?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  estateId?: InputMaybe<StringWithAggregatesFilter>;
  estimatedValue?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  finalValue?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  inTrust?: InputMaybe<BoolWithAggregatesFilter>;
  probateEstate?: InputMaybe<StringNullableWithAggregatesFilter>;
  probateJurisdiction?: InputMaybe<StringNullableWithAggregatesFilter>;
  status?: InputMaybe<EnumAssetStatusWithAggregatesFilter>;
};

export enum AssetStatus {
  Blocked = "Blocked",
  ForSale = "ForSale",
  InProcess = "InProcess",
  NotManagedByAlix = "NotManagedByAlix",
  Pending = "Pending",
  PrepareForSale = "PrepareForSale",
  PreparingForSale = "PreparingForSale",
  Secured = "Secured",
  Sold = "Sold",
  Transferred = "Transferred",
}

export enum AssetSubCategory {
  Annuity = "Annuity",
  Car = "Car",
  Checking = "Checking",
  CommercialProperty = "CommercialProperty",
  Hsa = "HSA",
  Ira = "IRA",
  K401 = "K401",
  MoneyMarket = "MoneyMarket",
  MotorCycle = "MotorCycle",
  PrimaryResidence = "PrimaryResidence",
  Rv = "RV",
  ResidentialIncomeProperty = "ResidentialIncomeProperty",
  SafetyDepositBox = "SafetyDepositBox",
  Savings = "Savings",
  Stocks = "Stocks",
  Truck = "Truck",
  Trust = "Trust",
}

export type AssetSumAggregate = {
  __typename?: "AssetSumAggregate";
  dodValue?: Maybe<Scalars["Decimal"]["output"]>;
  estimatedValue?: Maybe<Scalars["Decimal"]["output"]>;
  finalValue?: Maybe<Scalars["Decimal"]["output"]>;
};

export type AssetSumOrderByAggregateInput = {
  dodValue?: InputMaybe<SortOrder>;
  estimatedValue?: InputMaybe<SortOrder>;
  finalValue?: InputMaybe<SortOrder>;
};

export type AssetUpdateInput = {
  assetAccount?: InputMaybe<AssetAccountUpdateOneWithoutAssetNestedInput>;
  assetAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  assetCategory?: InputMaybe<EnumAssetCategoryFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dodValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutAssetNestedInput>;
  estimatedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  finalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inTrust?: InputMaybe<BoolFieldUpdateOperationsInput>;
  probateEstate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  probateJurisdiction?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumAssetStatusFieldUpdateOperationsInput>;
};

export type AssetUpdateManyMutationInput = {
  assetAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  assetCategory?: InputMaybe<EnumAssetCategoryFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dodValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  estimatedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  finalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inTrust?: InputMaybe<BoolFieldUpdateOperationsInput>;
  probateEstate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  probateJurisdiction?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumAssetStatusFieldUpdateOperationsInput>;
};

export type AssetUpdateManyWithWhereWithoutEstateInput = {
  data: AssetUpdateManyMutationInput;
  where: AssetScalarWhereInput;
};

export type AssetUpdateManyWithoutEstateNestedInput = {
  connect?: InputMaybe<Array<AssetWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssetCreateOrConnectWithoutEstateInput>>;
  create?: InputMaybe<Array<AssetCreateWithoutEstateInput>>;
  createMany?: InputMaybe<AssetCreateManyEstateInputEnvelope>;
  delete?: InputMaybe<Array<AssetWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AssetScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AssetWhereUniqueInput>>;
  set?: InputMaybe<Array<AssetWhereUniqueInput>>;
  update?: InputMaybe<Array<AssetUpdateWithWhereUniqueWithoutEstateInput>>;
  updateMany?: InputMaybe<Array<AssetUpdateManyWithWhereWithoutEstateInput>>;
  upsert?: InputMaybe<Array<AssetUpsertWithWhereUniqueWithoutEstateInput>>;
};

export type AssetUpdateOneRequiredWithoutAssetAccountNestedInput = {
  connect?: InputMaybe<AssetWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AssetCreateOrConnectWithoutAssetAccountInput>;
  create?: InputMaybe<AssetCreateWithoutAssetAccountInput>;
  update?: InputMaybe<AssetUpdateToOneWithWhereWithoutAssetAccountInput>;
  upsert?: InputMaybe<AssetUpsertWithoutAssetAccountInput>;
};

export type AssetUpdateToOneWithWhereWithoutAssetAccountInput = {
  data: AssetUpdateWithoutAssetAccountInput;
  where?: InputMaybe<AssetWhereInput>;
};

export type AssetUpdateWithWhereUniqueWithoutEstateInput = {
  data: AssetUpdateWithoutEstateInput;
  where: AssetWhereUniqueInput;
};

export type AssetUpdateWithoutAssetAccountInput = {
  assetAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  assetCategory?: InputMaybe<EnumAssetCategoryFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dodValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutAssetNestedInput>;
  estimatedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  finalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inTrust?: InputMaybe<BoolFieldUpdateOperationsInput>;
  probateEstate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  probateJurisdiction?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumAssetStatusFieldUpdateOperationsInput>;
};

export type AssetUpdateWithoutEstateInput = {
  assetAccount?: InputMaybe<AssetAccountUpdateOneWithoutAssetNestedInput>;
  assetAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  assetCategory?: InputMaybe<EnumAssetCategoryFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dodValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  estimatedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  finalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inTrust?: InputMaybe<BoolFieldUpdateOperationsInput>;
  probateEstate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  probateJurisdiction?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumAssetStatusFieldUpdateOperationsInput>;
};

export type AssetUpsertWithWhereUniqueWithoutEstateInput = {
  create: AssetCreateWithoutEstateInput;
  update: AssetUpdateWithoutEstateInput;
  where: AssetWhereUniqueInput;
};

export type AssetUpsertWithoutAssetAccountInput = {
  create: AssetCreateWithoutAssetAccountInput;
  update: AssetUpdateWithoutAssetAccountInput;
  where?: InputMaybe<AssetWhereInput>;
};

export type AssetWhereInput = {
  AND?: InputMaybe<Array<AssetWhereInput>>;
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  OR?: InputMaybe<Array<AssetWhereInput>>;
  assetAccount?: InputMaybe<AssetAccountNullableRelationFilter>;
  assetAccountId?: InputMaybe<StringNullableFilter>;
  assetCategory?: InputMaybe<EnumAssetCategoryFilter>;
  description?: InputMaybe<StringNullableFilter>;
  dodValue?: InputMaybe<DecimalNullableFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  estimatedValue?: InputMaybe<DecimalNullableFilter>;
  finalValue?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inTrust?: InputMaybe<BoolFilter>;
  probateEstate?: InputMaybe<StringNullableFilter>;
  probateJurisdiction?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumAssetStatusFilter>;
};

export type AssetWhereUniqueInput = {
  AND?: InputMaybe<Array<AssetWhereInput>>;
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  OR?: InputMaybe<Array<AssetWhereInput>>;
  assetAccount?: InputMaybe<AssetAccountNullableRelationFilter>;
  assetAccountId?: InputMaybe<Scalars["String"]["input"]>;
  assetCategory?: InputMaybe<EnumAssetCategoryFilter>;
  description?: InputMaybe<StringNullableFilter>;
  dodValue?: InputMaybe<DecimalNullableFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  estimatedValue?: InputMaybe<DecimalNullableFilter>;
  finalValue?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<BoolFilter>;
  probateEstate?: InputMaybe<StringNullableFilter>;
  probateJurisdiction?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumAssetStatusFilter>;
};

export enum AssigneeType {
  CareTeam = "CareTeam",
  Customer = "Customer",
  OpsTeam = "OpsTeam",
  ServiceAccount = "ServiceAccount",
}

export type AuthenticateBoxUserResponse = {
  __typename?: "AuthenticateBoxUserResponse";
  accessToken: Scalars["String"]["output"];
  expiresAt: Scalars["DateTimeISO"]["output"];
  rootFolderId: Scalars["String"]["output"];
};

export type Beneficiary = {
  __typename?: "Beneficiary";
  _count?: Maybe<BeneficiaryCount>;
  beneficiaryDistributions: Array<BeneficiaryDistribution>;
  component?: Maybe<Component>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  estate: Estate;
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  relationship?: Maybe<Scalars["String"]["output"]>;
  taxId?: Maybe<Scalars["String"]["output"]>;
  type: BeneficiaryType;
  updatedAt: Scalars["DateTimeISO"]["output"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type BeneficiaryBeneficiaryDistributionsArgs = {
  cursor?: InputMaybe<BeneficiaryDistributionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryDistributionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryDistributionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<BeneficiaryDistributionWhereInput>;
};

export type BeneficiaryComponentArgs = {
  where?: InputMaybe<ComponentWhereInput>;
};

export type BeneficiaryUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type Beneficiary2 = {
  __typename?: "Beneficiary2";
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  relationship?: Maybe<Scalars["String"]["output"]>;
  taxId?: Maybe<Scalars["String"]["output"]>;
  type: BeneficiaryType;
  updatedAt: Scalars["DateTimeISO"]["output"];
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type BeneficiaryCount = {
  __typename?: "BeneficiaryCount";
  beneficiaryDistributions: Scalars["Int"]["output"];
};

export type BeneficiaryCountBeneficiaryDistributionsArgs = {
  where?: InputMaybe<BeneficiaryDistributionWhereInput>;
};

export type BeneficiaryCountAggregate = {
  __typename?: "BeneficiaryCountAggregate";
  _all: Scalars["Int"]["output"];
  componentId: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  estateId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["Int"]["output"];
  relationship: Scalars["Int"]["output"];
  taxId: Scalars["Int"]["output"];
  type: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  userId: Scalars["Int"]["output"];
};

export type BeneficiaryCountOrderByAggregateInput = {
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  relationship?: InputMaybe<SortOrder>;
  taxId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type BeneficiaryCreateInput = {
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionCreateNestedManyWithoutBeneficiaryInput>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutBeneficiariesInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estate: EstateCreateNestedOneWithoutBeneficiariesInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  relationship?: InputMaybe<Scalars["String"]["input"]>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  type: BeneficiaryType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutBeneficiariesInput>;
};

export type BeneficiaryCreateManyComponentInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estateId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  relationship?: InputMaybe<Scalars["String"]["input"]>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  type: BeneficiaryType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type BeneficiaryCreateManyComponentInputEnvelope = {
  data: Array<BeneficiaryCreateManyComponentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BeneficiaryCreateManyEstateInput = {
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  relationship?: InputMaybe<Scalars["String"]["input"]>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  type: BeneficiaryType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type BeneficiaryCreateManyEstateInputEnvelope = {
  data: Array<BeneficiaryCreateManyEstateInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BeneficiaryCreateManyInput = {
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estateId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  relationship?: InputMaybe<Scalars["String"]["input"]>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  type: BeneficiaryType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type BeneficiaryCreateManyUserInput = {
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estateId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  relationship?: InputMaybe<Scalars["String"]["input"]>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  type: BeneficiaryType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type BeneficiaryCreateManyUserInputEnvelope = {
  data: Array<BeneficiaryCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BeneficiaryCreateNestedManyWithoutComponentInput = {
  connect?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BeneficiaryCreateOrConnectWithoutComponentInput>
  >;
  create?: InputMaybe<Array<BeneficiaryCreateWithoutComponentInput>>;
  createMany?: InputMaybe<BeneficiaryCreateManyComponentInputEnvelope>;
};

export type BeneficiaryCreateNestedManyWithoutEstateInput = {
  connect?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BeneficiaryCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<BeneficiaryCreateWithoutEstateInput>>;
  createMany?: InputMaybe<BeneficiaryCreateManyEstateInputEnvelope>;
};

export type BeneficiaryCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BeneficiaryCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<BeneficiaryCreateWithoutUserInput>>;
  createMany?: InputMaybe<BeneficiaryCreateManyUserInputEnvelope>;
};

export type BeneficiaryCreateNestedOneWithoutBeneficiaryDistributionsInput = {
  connect?: InputMaybe<BeneficiaryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeneficiaryCreateOrConnectWithoutBeneficiaryDistributionsInput>;
  create?: InputMaybe<BeneficiaryCreateWithoutBeneficiaryDistributionsInput>;
};

export type BeneficiaryCreateOrConnectWithoutBeneficiaryDistributionsInput = {
  create: BeneficiaryCreateWithoutBeneficiaryDistributionsInput;
  where: BeneficiaryWhereUniqueInput;
};

export type BeneficiaryCreateOrConnectWithoutComponentInput = {
  create: BeneficiaryCreateWithoutComponentInput;
  where: BeneficiaryWhereUniqueInput;
};

export type BeneficiaryCreateOrConnectWithoutEstateInput = {
  create: BeneficiaryCreateWithoutEstateInput;
  where: BeneficiaryWhereUniqueInput;
};

export type BeneficiaryCreateOrConnectWithoutUserInput = {
  create: BeneficiaryCreateWithoutUserInput;
  where: BeneficiaryWhereUniqueInput;
};

export type BeneficiaryCreateWithoutBeneficiaryDistributionsInput = {
  component?: InputMaybe<ComponentCreateNestedOneWithoutBeneficiariesInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estate: EstateCreateNestedOneWithoutBeneficiariesInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  relationship?: InputMaybe<Scalars["String"]["input"]>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  type: BeneficiaryType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutBeneficiariesInput>;
};

export type BeneficiaryCreateWithoutComponentInput = {
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionCreateNestedManyWithoutBeneficiaryInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estate: EstateCreateNestedOneWithoutBeneficiariesInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  relationship?: InputMaybe<Scalars["String"]["input"]>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  type: BeneficiaryType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutBeneficiariesInput>;
};

export type BeneficiaryCreateWithoutEstateInput = {
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionCreateNestedManyWithoutBeneficiaryInput>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutBeneficiariesInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  relationship?: InputMaybe<Scalars["String"]["input"]>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  type: BeneficiaryType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutBeneficiariesInput>;
};

export type BeneficiaryCreateWithoutUserInput = {
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionCreateNestedManyWithoutBeneficiaryInput>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutBeneficiariesInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estate: EstateCreateNestedOneWithoutBeneficiariesInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  relationship?: InputMaybe<Scalars["String"]["input"]>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  type: BeneficiaryType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type BeneficiaryDistribution = {
  __typename?: "BeneficiaryDistribution";
  beneficiary: Beneficiary;
  beneficiaryId: Scalars["String"]["output"];
  component?: Maybe<Component>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  date?: Maybe<Scalars["DateTimeISO"]["output"]>;
  id: Scalars["String"]["output"];
  percentage: Scalars["Decimal"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type BeneficiaryDistributionComponentArgs = {
  where?: InputMaybe<ComponentWhereInput>;
};

export type BeneficiaryDistributionAvgAggregate = {
  __typename?: "BeneficiaryDistributionAvgAggregate";
  percentage?: Maybe<Scalars["Decimal"]["output"]>;
};

export type BeneficiaryDistributionAvgOrderByAggregateInput = {
  percentage?: InputMaybe<SortOrder>;
};

export type BeneficiaryDistributionCountAggregate = {
  __typename?: "BeneficiaryDistributionCountAggregate";
  _all: Scalars["Int"]["output"];
  beneficiaryId: Scalars["Int"]["output"];
  componentId: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  date: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  percentage: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type BeneficiaryDistributionCountOrderByAggregateInput = {
  beneficiaryId?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  percentage?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BeneficiaryDistributionCreateInput = {
  beneficiary: BeneficiaryCreateNestedOneWithoutBeneficiaryDistributionsInput;
  component?: InputMaybe<ComponentCreateNestedOneWithoutBeneficiaryDistributionsInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  percentage: Scalars["Decimal"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type BeneficiaryDistributionCreateManyBeneficiaryInput = {
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  percentage: Scalars["Decimal"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type BeneficiaryDistributionCreateManyBeneficiaryInputEnvelope = {
  data: Array<BeneficiaryDistributionCreateManyBeneficiaryInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BeneficiaryDistributionCreateManyComponentInput = {
  beneficiaryId: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  percentage: Scalars["Decimal"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type BeneficiaryDistributionCreateManyComponentInputEnvelope = {
  data: Array<BeneficiaryDistributionCreateManyComponentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BeneficiaryDistributionCreateManyInput = {
  beneficiaryId: Scalars["String"]["input"];
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  percentage: Scalars["Decimal"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type BeneficiaryDistributionCreateNestedManyWithoutBeneficiaryInput = {
  connect?: InputMaybe<Array<BeneficiaryDistributionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BeneficiaryDistributionCreateOrConnectWithoutBeneficiaryInput>
  >;
  create?: InputMaybe<
    Array<BeneficiaryDistributionCreateWithoutBeneficiaryInput>
  >;
  createMany?: InputMaybe<BeneficiaryDistributionCreateManyBeneficiaryInputEnvelope>;
};

export type BeneficiaryDistributionCreateNestedManyWithoutComponentInput = {
  connect?: InputMaybe<Array<BeneficiaryDistributionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BeneficiaryDistributionCreateOrConnectWithoutComponentInput>
  >;
  create?: InputMaybe<
    Array<BeneficiaryDistributionCreateWithoutComponentInput>
  >;
  createMany?: InputMaybe<BeneficiaryDistributionCreateManyComponentInputEnvelope>;
};

export type BeneficiaryDistributionCreateOrConnectWithoutBeneficiaryInput = {
  create: BeneficiaryDistributionCreateWithoutBeneficiaryInput;
  where: BeneficiaryDistributionWhereUniqueInput;
};

export type BeneficiaryDistributionCreateOrConnectWithoutComponentInput = {
  create: BeneficiaryDistributionCreateWithoutComponentInput;
  where: BeneficiaryDistributionWhereUniqueInput;
};

export type BeneficiaryDistributionCreateWithoutBeneficiaryInput = {
  component?: InputMaybe<ComponentCreateNestedOneWithoutBeneficiaryDistributionsInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  percentage: Scalars["Decimal"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type BeneficiaryDistributionCreateWithoutComponentInput = {
  beneficiary: BeneficiaryCreateNestedOneWithoutBeneficiaryDistributionsInput;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  percentage: Scalars["Decimal"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type BeneficiaryDistributionGroupBy = {
  __typename?: "BeneficiaryDistributionGroupBy";
  _avg?: Maybe<BeneficiaryDistributionAvgAggregate>;
  _count?: Maybe<BeneficiaryDistributionCountAggregate>;
  _max?: Maybe<BeneficiaryDistributionMaxAggregate>;
  _min?: Maybe<BeneficiaryDistributionMinAggregate>;
  _sum?: Maybe<BeneficiaryDistributionSumAggregate>;
  beneficiaryId: Scalars["String"]["output"];
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  date?: Maybe<Scalars["DateTimeISO"]["output"]>;
  id: Scalars["String"]["output"];
  percentage: Scalars["Decimal"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type BeneficiaryDistributionInput = {
  beneficiaryId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  percentage: Scalars["Decimal"]["input"];
};

export type BeneficiaryDistributionListRelationFilter = {
  every?: InputMaybe<BeneficiaryDistributionWhereInput>;
  none?: InputMaybe<BeneficiaryDistributionWhereInput>;
  some?: InputMaybe<BeneficiaryDistributionWhereInput>;
};

export type BeneficiaryDistributionMaxAggregate = {
  __typename?: "BeneficiaryDistributionMaxAggregate";
  beneficiaryId?: Maybe<Scalars["String"]["output"]>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  date?: Maybe<Scalars["DateTimeISO"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  percentage?: Maybe<Scalars["Decimal"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type BeneficiaryDistributionMaxOrderByAggregateInput = {
  beneficiaryId?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  percentage?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BeneficiaryDistributionMinAggregate = {
  __typename?: "BeneficiaryDistributionMinAggregate";
  beneficiaryId?: Maybe<Scalars["String"]["output"]>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  date?: Maybe<Scalars["DateTimeISO"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  percentage?: Maybe<Scalars["Decimal"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type BeneficiaryDistributionMinOrderByAggregateInput = {
  beneficiaryId?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  percentage?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BeneficiaryDistributionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BeneficiaryDistributionOrderByWithAggregationInput = {
  _avg?: InputMaybe<BeneficiaryDistributionAvgOrderByAggregateInput>;
  _count?: InputMaybe<BeneficiaryDistributionCountOrderByAggregateInput>;
  _max?: InputMaybe<BeneficiaryDistributionMaxOrderByAggregateInput>;
  _min?: InputMaybe<BeneficiaryDistributionMinOrderByAggregateInput>;
  _sum?: InputMaybe<BeneficiaryDistributionSumOrderByAggregateInput>;
  beneficiaryId?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  percentage?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BeneficiaryDistributionOrderByWithRelationInput = {
  beneficiary?: InputMaybe<BeneficiaryOrderByWithRelationInput>;
  beneficiaryId?: InputMaybe<SortOrder>;
  component?: InputMaybe<ComponentOrderByWithRelationInput>;
  componentId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  percentage?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum BeneficiaryDistributionScalarFieldEnum {
  BeneficiaryId = "beneficiaryId",
  ComponentId = "componentId",
  CreatedAt = "createdAt",
  Date = "date",
  Id = "id",
  Percentage = "percentage",
  UpdatedAt = "updatedAt",
}

export type BeneficiaryDistributionScalarWhereInput = {
  AND?: InputMaybe<Array<BeneficiaryDistributionScalarWhereInput>>;
  NOT?: InputMaybe<Array<BeneficiaryDistributionScalarWhereInput>>;
  OR?: InputMaybe<Array<BeneficiaryDistributionScalarWhereInput>>;
  beneficiaryId?: InputMaybe<StringFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  percentage?: InputMaybe<DecimalFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BeneficiaryDistributionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<
    Array<BeneficiaryDistributionScalarWhereWithAggregatesInput>
  >;
  NOT?: InputMaybe<
    Array<BeneficiaryDistributionScalarWhereWithAggregatesInput>
  >;
  OR?: InputMaybe<Array<BeneficiaryDistributionScalarWhereWithAggregatesInput>>;
  beneficiaryId?: InputMaybe<StringWithAggregatesFilter>;
  componentId?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  date?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  percentage?: InputMaybe<DecimalWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type BeneficiaryDistributionSumAggregate = {
  __typename?: "BeneficiaryDistributionSumAggregate";
  percentage?: Maybe<Scalars["Decimal"]["output"]>;
};

export type BeneficiaryDistributionSumOrderByAggregateInput = {
  percentage?: InputMaybe<SortOrder>;
};

export type BeneficiaryDistributionUpdateInput = {
  beneficiary?: InputMaybe<BeneficiaryUpdateOneRequiredWithoutBeneficiaryDistributionsNestedInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutBeneficiaryDistributionsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  percentage?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BeneficiaryDistributionUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  percentage?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BeneficiaryDistributionUpdateManyWithWhereWithoutBeneficiaryInput =
  {
    data: BeneficiaryDistributionUpdateManyMutationInput;
    where: BeneficiaryDistributionScalarWhereInput;
  };

export type BeneficiaryDistributionUpdateManyWithWhereWithoutComponentInput = {
  data: BeneficiaryDistributionUpdateManyMutationInput;
  where: BeneficiaryDistributionScalarWhereInput;
};

export type BeneficiaryDistributionUpdateManyWithoutBeneficiaryNestedInput = {
  connect?: InputMaybe<Array<BeneficiaryDistributionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BeneficiaryDistributionCreateOrConnectWithoutBeneficiaryInput>
  >;
  create?: InputMaybe<
    Array<BeneficiaryDistributionCreateWithoutBeneficiaryInput>
  >;
  createMany?: InputMaybe<BeneficiaryDistributionCreateManyBeneficiaryInputEnvelope>;
  delete?: InputMaybe<Array<BeneficiaryDistributionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeneficiaryDistributionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeneficiaryDistributionWhereUniqueInput>>;
  set?: InputMaybe<Array<BeneficiaryDistributionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<BeneficiaryDistributionUpdateWithWhereUniqueWithoutBeneficiaryInput>
  >;
  updateMany?: InputMaybe<
    Array<BeneficiaryDistributionUpdateManyWithWhereWithoutBeneficiaryInput>
  >;
  upsert?: InputMaybe<
    Array<BeneficiaryDistributionUpsertWithWhereUniqueWithoutBeneficiaryInput>
  >;
};

export type BeneficiaryDistributionUpdateManyWithoutComponentNestedInput = {
  connect?: InputMaybe<Array<BeneficiaryDistributionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BeneficiaryDistributionCreateOrConnectWithoutComponentInput>
  >;
  create?: InputMaybe<
    Array<BeneficiaryDistributionCreateWithoutComponentInput>
  >;
  createMany?: InputMaybe<BeneficiaryDistributionCreateManyComponentInputEnvelope>;
  delete?: InputMaybe<Array<BeneficiaryDistributionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeneficiaryDistributionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeneficiaryDistributionWhereUniqueInput>>;
  set?: InputMaybe<Array<BeneficiaryDistributionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<BeneficiaryDistributionUpdateWithWhereUniqueWithoutComponentInput>
  >;
  updateMany?: InputMaybe<
    Array<BeneficiaryDistributionUpdateManyWithWhereWithoutComponentInput>
  >;
  upsert?: InputMaybe<
    Array<BeneficiaryDistributionUpsertWithWhereUniqueWithoutComponentInput>
  >;
};

export type BeneficiaryDistributionUpdateWithWhereUniqueWithoutBeneficiaryInput =
  {
    data: BeneficiaryDistributionUpdateWithoutBeneficiaryInput;
    where: BeneficiaryDistributionWhereUniqueInput;
  };

export type BeneficiaryDistributionUpdateWithWhereUniqueWithoutComponentInput =
  {
    data: BeneficiaryDistributionUpdateWithoutComponentInput;
    where: BeneficiaryDistributionWhereUniqueInput;
  };

export type BeneficiaryDistributionUpdateWithoutBeneficiaryInput = {
  component?: InputMaybe<ComponentUpdateOneWithoutBeneficiaryDistributionsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  percentage?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BeneficiaryDistributionUpdateWithoutComponentInput = {
  beneficiary?: InputMaybe<BeneficiaryUpdateOneRequiredWithoutBeneficiaryDistributionsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  percentage?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BeneficiaryDistributionUpsertWithWhereUniqueWithoutBeneficiaryInput =
  {
    create: BeneficiaryDistributionCreateWithoutBeneficiaryInput;
    update: BeneficiaryDistributionUpdateWithoutBeneficiaryInput;
    where: BeneficiaryDistributionWhereUniqueInput;
  };

export type BeneficiaryDistributionUpsertWithWhereUniqueWithoutComponentInput =
  {
    create: BeneficiaryDistributionCreateWithoutComponentInput;
    update: BeneficiaryDistributionUpdateWithoutComponentInput;
    where: BeneficiaryDistributionWhereUniqueInput;
  };

export type BeneficiaryDistributionWhereInput = {
  AND?: InputMaybe<Array<BeneficiaryDistributionWhereInput>>;
  NOT?: InputMaybe<Array<BeneficiaryDistributionWhereInput>>;
  OR?: InputMaybe<Array<BeneficiaryDistributionWhereInput>>;
  beneficiary?: InputMaybe<BeneficiaryRelationFilter>;
  beneficiaryId?: InputMaybe<StringFilter>;
  component?: InputMaybe<ComponentNullableRelationFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  percentage?: InputMaybe<DecimalFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BeneficiaryDistributionWhereUniqueInput = {
  AND?: InputMaybe<Array<BeneficiaryDistributionWhereInput>>;
  NOT?: InputMaybe<Array<BeneficiaryDistributionWhereInput>>;
  OR?: InputMaybe<Array<BeneficiaryDistributionWhereInput>>;
  beneficiary?: InputMaybe<BeneficiaryRelationFilter>;
  beneficiaryId?: InputMaybe<StringFilter>;
  component?: InputMaybe<ComponentNullableRelationFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  percentage?: InputMaybe<DecimalFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BeneficiaryGroupBy = {
  __typename?: "BeneficiaryGroupBy";
  _count?: Maybe<BeneficiaryCountAggregate>;
  _max?: Maybe<BeneficiaryMaxAggregate>;
  _min?: Maybe<BeneficiaryMinAggregate>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  relationship?: Maybe<Scalars["String"]["output"]>;
  taxId?: Maybe<Scalars["String"]["output"]>;
  type: BeneficiaryType;
  updatedAt: Scalars["DateTimeISO"]["output"];
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type BeneficiaryListRelationFilter = {
  every?: InputMaybe<BeneficiaryWhereInput>;
  none?: InputMaybe<BeneficiaryWhereInput>;
  some?: InputMaybe<BeneficiaryWhereInput>;
};

export type BeneficiaryMaxAggregate = {
  __typename?: "BeneficiaryMaxAggregate";
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  relationship?: Maybe<Scalars["String"]["output"]>;
  taxId?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<BeneficiaryType>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type BeneficiaryMaxOrderByAggregateInput = {
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  relationship?: InputMaybe<SortOrder>;
  taxId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type BeneficiaryMinAggregate = {
  __typename?: "BeneficiaryMinAggregate";
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  relationship?: Maybe<Scalars["String"]["output"]>;
  taxId?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<BeneficiaryType>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type BeneficiaryMinOrderByAggregateInput = {
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  relationship?: InputMaybe<SortOrder>;
  taxId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type BeneficiaryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BeneficiaryOrderByWithAggregationInput = {
  _count?: InputMaybe<BeneficiaryCountOrderByAggregateInput>;
  _max?: InputMaybe<BeneficiaryMaxOrderByAggregateInput>;
  _min?: InputMaybe<BeneficiaryMinOrderByAggregateInput>;
  componentId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  relationship?: InputMaybe<SortOrderInput>;
  taxId?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrderInput>;
};

export type BeneficiaryOrderByWithRelationInput = {
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionOrderByRelationAggregateInput>;
  component?: InputMaybe<ComponentOrderByWithRelationInput>;
  componentId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  estate?: InputMaybe<EstateOrderByWithRelationInput>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  relationship?: InputMaybe<SortOrderInput>;
  taxId?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
};

export type BeneficiaryRelationFilter = {
  is?: InputMaybe<BeneficiaryWhereInput>;
  isNot?: InputMaybe<BeneficiaryWhereInput>;
};

export enum BeneficiaryScalarFieldEnum {
  ComponentId = "componentId",
  CreatedAt = "createdAt",
  EstateId = "estateId",
  Id = "id",
  Name = "name",
  Relationship = "relationship",
  TaxId = "taxId",
  Type = "type",
  UpdatedAt = "updatedAt",
  UserId = "userId",
}

export type BeneficiaryScalarWhereInput = {
  AND?: InputMaybe<Array<BeneficiaryScalarWhereInput>>;
  NOT?: InputMaybe<Array<BeneficiaryScalarWhereInput>>;
  OR?: InputMaybe<Array<BeneficiaryScalarWhereInput>>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  relationship?: InputMaybe<StringNullableFilter>;
  taxId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumBeneficiaryTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type BeneficiaryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<BeneficiaryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<BeneficiaryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<BeneficiaryScalarWhereWithAggregatesInput>>;
  componentId?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  estateId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  relationship?: InputMaybe<StringNullableWithAggregatesFilter>;
  taxId?: InputMaybe<StringNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumBeneficiaryTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export enum BeneficiaryType {
  AdultChild = "AdultChild",
  Charity = "Charity",
  Estate = "Estate",
  Individual = "Individual",
  MinorChild = "MinorChild",
  OtherDependent = "OtherDependent",
  SurvivingSpouse = "SurvivingSpouse",
  Trust = "Trust",
}

export type BeneficiaryUpdateInput = {
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionUpdateManyWithoutBeneficiaryNestedInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutBeneficiariesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutBeneficiariesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  relationship?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBeneficiaryTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutBeneficiariesNestedInput>;
};

export type BeneficiaryUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  relationship?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBeneficiaryTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BeneficiaryUpdateManyWithWhereWithoutComponentInput = {
  data: BeneficiaryUpdateManyMutationInput;
  where: BeneficiaryScalarWhereInput;
};

export type BeneficiaryUpdateManyWithWhereWithoutEstateInput = {
  data: BeneficiaryUpdateManyMutationInput;
  where: BeneficiaryScalarWhereInput;
};

export type BeneficiaryUpdateManyWithWhereWithoutUserInput = {
  data: BeneficiaryUpdateManyMutationInput;
  where: BeneficiaryScalarWhereInput;
};

export type BeneficiaryUpdateManyWithoutComponentNestedInput = {
  connect?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BeneficiaryCreateOrConnectWithoutComponentInput>
  >;
  create?: InputMaybe<Array<BeneficiaryCreateWithoutComponentInput>>;
  createMany?: InputMaybe<BeneficiaryCreateManyComponentInputEnvelope>;
  delete?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeneficiaryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  set?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  update?: InputMaybe<
    Array<BeneficiaryUpdateWithWhereUniqueWithoutComponentInput>
  >;
  updateMany?: InputMaybe<
    Array<BeneficiaryUpdateManyWithWhereWithoutComponentInput>
  >;
  upsert?: InputMaybe<
    Array<BeneficiaryUpsertWithWhereUniqueWithoutComponentInput>
  >;
};

export type BeneficiaryUpdateManyWithoutEstateNestedInput = {
  connect?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BeneficiaryCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<BeneficiaryCreateWithoutEstateInput>>;
  createMany?: InputMaybe<BeneficiaryCreateManyEstateInputEnvelope>;
  delete?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeneficiaryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  set?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  update?: InputMaybe<
    Array<BeneficiaryUpdateWithWhereUniqueWithoutEstateInput>
  >;
  updateMany?: InputMaybe<
    Array<BeneficiaryUpdateManyWithWhereWithoutEstateInput>
  >;
  upsert?: InputMaybe<
    Array<BeneficiaryUpsertWithWhereUniqueWithoutEstateInput>
  >;
};

export type BeneficiaryUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BeneficiaryCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<BeneficiaryCreateWithoutUserInput>>;
  createMany?: InputMaybe<BeneficiaryCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeneficiaryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  set?: InputMaybe<Array<BeneficiaryWhereUniqueInput>>;
  update?: InputMaybe<Array<BeneficiaryUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<
    Array<BeneficiaryUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<Array<BeneficiaryUpsertWithWhereUniqueWithoutUserInput>>;
};

export type BeneficiaryUpdateOneRequiredWithoutBeneficiaryDistributionsNestedInput =
  {
    connect?: InputMaybe<BeneficiaryWhereUniqueInput>;
    connectOrCreate?: InputMaybe<BeneficiaryCreateOrConnectWithoutBeneficiaryDistributionsInput>;
    create?: InputMaybe<BeneficiaryCreateWithoutBeneficiaryDistributionsInput>;
    update?: InputMaybe<BeneficiaryUpdateToOneWithWhereWithoutBeneficiaryDistributionsInput>;
    upsert?: InputMaybe<BeneficiaryUpsertWithoutBeneficiaryDistributionsInput>;
  };

export type BeneficiaryUpdateToOneWithWhereWithoutBeneficiaryDistributionsInput =
  {
    data: BeneficiaryUpdateWithoutBeneficiaryDistributionsInput;
    where?: InputMaybe<BeneficiaryWhereInput>;
  };

export type BeneficiaryUpdateWithWhereUniqueWithoutComponentInput = {
  data: BeneficiaryUpdateWithoutComponentInput;
  where: BeneficiaryWhereUniqueInput;
};

export type BeneficiaryUpdateWithWhereUniqueWithoutEstateInput = {
  data: BeneficiaryUpdateWithoutEstateInput;
  where: BeneficiaryWhereUniqueInput;
};

export type BeneficiaryUpdateWithWhereUniqueWithoutUserInput = {
  data: BeneficiaryUpdateWithoutUserInput;
  where: BeneficiaryWhereUniqueInput;
};

export type BeneficiaryUpdateWithoutBeneficiaryDistributionsInput = {
  component?: InputMaybe<ComponentUpdateOneWithoutBeneficiariesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutBeneficiariesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  relationship?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBeneficiaryTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutBeneficiariesNestedInput>;
};

export type BeneficiaryUpdateWithoutComponentInput = {
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionUpdateManyWithoutBeneficiaryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutBeneficiariesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  relationship?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBeneficiaryTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutBeneficiariesNestedInput>;
};

export type BeneficiaryUpdateWithoutEstateInput = {
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionUpdateManyWithoutBeneficiaryNestedInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutBeneficiariesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  relationship?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBeneficiaryTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutBeneficiariesNestedInput>;
};

export type BeneficiaryUpdateWithoutUserInput = {
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionUpdateManyWithoutBeneficiaryNestedInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutBeneficiariesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutBeneficiariesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  relationship?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumBeneficiaryTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BeneficiaryUpsertWithWhereUniqueWithoutComponentInput = {
  create: BeneficiaryCreateWithoutComponentInput;
  update: BeneficiaryUpdateWithoutComponentInput;
  where: BeneficiaryWhereUniqueInput;
};

export type BeneficiaryUpsertWithWhereUniqueWithoutEstateInput = {
  create: BeneficiaryCreateWithoutEstateInput;
  update: BeneficiaryUpdateWithoutEstateInput;
  where: BeneficiaryWhereUniqueInput;
};

export type BeneficiaryUpsertWithWhereUniqueWithoutUserInput = {
  create: BeneficiaryCreateWithoutUserInput;
  update: BeneficiaryUpdateWithoutUserInput;
  where: BeneficiaryWhereUniqueInput;
};

export type BeneficiaryUpsertWithoutBeneficiaryDistributionsInput = {
  create: BeneficiaryCreateWithoutBeneficiaryDistributionsInput;
  update: BeneficiaryUpdateWithoutBeneficiaryDistributionsInput;
  where?: InputMaybe<BeneficiaryWhereInput>;
};

export type BeneficiaryWhereInput = {
  AND?: InputMaybe<Array<BeneficiaryWhereInput>>;
  NOT?: InputMaybe<Array<BeneficiaryWhereInput>>;
  OR?: InputMaybe<Array<BeneficiaryWhereInput>>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionListRelationFilter>;
  component?: InputMaybe<ComponentNullableRelationFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  relationship?: InputMaybe<StringNullableFilter>;
  taxId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumBeneficiaryTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type BeneficiaryWhereUniqueInput = {
  AND?: InputMaybe<Array<BeneficiaryWhereInput>>;
  NOT?: InputMaybe<Array<BeneficiaryWhereInput>>;
  OR?: InputMaybe<Array<BeneficiaryWhereInput>>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionListRelationFilter>;
  component?: InputMaybe<ComponentNullableRelationFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  relationship?: InputMaybe<StringNullableFilter>;
  taxId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumBeneficiaryTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars["Boolean"]["input"]>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars["Boolean"]["input"]>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type BoolNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars["Boolean"]["input"]>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars["Boolean"]["input"]>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type CareTeamNote = {
  __typename?: "CareTeamNote";
  createdAt: Scalars["DateTimeISO"]["output"];
  encrypted: Scalars["Boolean"]["output"];
  estate: Estate;
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type CareTeamNote2 = {
  __typename?: "CareTeamNote2";
  createdAt: Scalars["DateTimeISO"]["output"];
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  message: Scalars["JSON"]["output"];
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type CareTeamNoteCountAggregate = {
  __typename?: "CareTeamNoteCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  encrypted: Scalars["Int"]["output"];
  estateId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  message: Scalars["Int"]["output"];
  title: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type CareTeamNoteCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  encrypted?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CareTeamNoteCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  encrypted?: InputMaybe<Scalars["Boolean"]["input"]>;
  estate: EstateCreateNestedOneWithoutCareTeamNotesInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type CareTeamNoteCreateManyEstateInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  encrypted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type CareTeamNoteCreateManyEstateInputEnvelope = {
  data: Array<CareTeamNoteCreateManyEstateInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CareTeamNoteCreateManyInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  encrypted?: InputMaybe<Scalars["Boolean"]["input"]>;
  estateId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type CareTeamNoteCreateNestedManyWithoutEstateInput = {
  connect?: InputMaybe<Array<CareTeamNoteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CareTeamNoteCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<CareTeamNoteCreateWithoutEstateInput>>;
  createMany?: InputMaybe<CareTeamNoteCreateManyEstateInputEnvelope>;
};

export type CareTeamNoteCreateOrConnectWithoutEstateInput = {
  create: CareTeamNoteCreateWithoutEstateInput;
  where: CareTeamNoteWhereUniqueInput;
};

export type CareTeamNoteCreateWithoutEstateInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  encrypted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type CareTeamNoteGroupBy = {
  __typename?: "CareTeamNoteGroupBy";
  _count?: Maybe<CareTeamNoteCountAggregate>;
  _max?: Maybe<CareTeamNoteMaxAggregate>;
  _min?: Maybe<CareTeamNoteMinAggregate>;
  createdAt: Scalars["DateTimeISO"]["output"];
  encrypted: Scalars["Boolean"]["output"];
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type CareTeamNoteListRelationFilter = {
  every?: InputMaybe<CareTeamNoteWhereInput>;
  none?: InputMaybe<CareTeamNoteWhereInput>;
  some?: InputMaybe<CareTeamNoteWhereInput>;
};

export type CareTeamNoteMaxAggregate = {
  __typename?: "CareTeamNoteMaxAggregate";
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  encrypted?: Maybe<Scalars["Boolean"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type CareTeamNoteMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  encrypted?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CareTeamNoteMinAggregate = {
  __typename?: "CareTeamNoteMinAggregate";
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  encrypted?: Maybe<Scalars["Boolean"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type CareTeamNoteMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  encrypted?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CareTeamNoteOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CareTeamNoteOrderByWithAggregationInput = {
  _count?: InputMaybe<CareTeamNoteCountOrderByAggregateInput>;
  _max?: InputMaybe<CareTeamNoteMaxOrderByAggregateInput>;
  _min?: InputMaybe<CareTeamNoteMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  encrypted?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CareTeamNoteOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  encrypted?: InputMaybe<SortOrder>;
  estate?: InputMaybe<EstateOrderByWithRelationInput>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum CareTeamNoteScalarFieldEnum {
  CreatedAt = "createdAt",
  Encrypted = "encrypted",
  EstateId = "estateId",
  Id = "id",
  Message = "message",
  Title = "title",
  UpdatedAt = "updatedAt",
}

export type CareTeamNoteScalarWhereInput = {
  AND?: InputMaybe<Array<CareTeamNoteScalarWhereInput>>;
  NOT?: InputMaybe<Array<CareTeamNoteScalarWhereInput>>;
  OR?: InputMaybe<Array<CareTeamNoteScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  encrypted?: InputMaybe<BoolFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CareTeamNoteScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CareTeamNoteScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CareTeamNoteScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CareTeamNoteScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  encrypted?: InputMaybe<BoolWithAggregatesFilter>;
  estateId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  message?: InputMaybe<StringWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type CareTeamNoteUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  encrypted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutCareTeamNotesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CareTeamNoteUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  encrypted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CareTeamNoteUpdateManyWithWhereWithoutEstateInput = {
  data: CareTeamNoteUpdateManyMutationInput;
  where: CareTeamNoteScalarWhereInput;
};

export type CareTeamNoteUpdateManyWithoutEstateNestedInput = {
  connect?: InputMaybe<Array<CareTeamNoteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CareTeamNoteCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<CareTeamNoteCreateWithoutEstateInput>>;
  createMany?: InputMaybe<CareTeamNoteCreateManyEstateInputEnvelope>;
  delete?: InputMaybe<Array<CareTeamNoteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CareTeamNoteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CareTeamNoteWhereUniqueInput>>;
  set?: InputMaybe<Array<CareTeamNoteWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CareTeamNoteUpdateWithWhereUniqueWithoutEstateInput>
  >;
  updateMany?: InputMaybe<
    Array<CareTeamNoteUpdateManyWithWhereWithoutEstateInput>
  >;
  upsert?: InputMaybe<
    Array<CareTeamNoteUpsertWithWhereUniqueWithoutEstateInput>
  >;
};

export type CareTeamNoteUpdateWithWhereUniqueWithoutEstateInput = {
  data: CareTeamNoteUpdateWithoutEstateInput;
  where: CareTeamNoteWhereUniqueInput;
};

export type CareTeamNoteUpdateWithoutEstateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  encrypted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CareTeamNoteUpsertWithWhereUniqueWithoutEstateInput = {
  create: CareTeamNoteCreateWithoutEstateInput;
  update: CareTeamNoteUpdateWithoutEstateInput;
  where: CareTeamNoteWhereUniqueInput;
};

export type CareTeamNoteWhereInput = {
  AND?: InputMaybe<Array<CareTeamNoteWhereInput>>;
  NOT?: InputMaybe<Array<CareTeamNoteWhereInput>>;
  OR?: InputMaybe<Array<CareTeamNoteWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  encrypted?: InputMaybe<BoolFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CareTeamNoteWhereUniqueInput = {
  AND?: InputMaybe<Array<CareTeamNoteWhereInput>>;
  NOT?: InputMaybe<Array<CareTeamNoteWhereInput>>;
  OR?: InputMaybe<Array<CareTeamNoteWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  encrypted?: InputMaybe<BoolFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Component = {
  __typename?: "Component";
  _count?: Maybe<ComponentCount>;
  accountHolder?: Maybe<Scalars["String"]["output"]>;
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  actionCards: Array<ActionCard>;
  address?: Maybe<Scalars["String"]["output"]>;
  appraisedValue?: Maybe<Scalars["Decimal"]["output"]>;
  balance?: Maybe<Scalars["Decimal"]["output"]>;
  beneficiaries: Array<Beneficiary>;
  beneficiariesText?: Maybe<Scalars["String"]["output"]>;
  beneficiaryDesignated?: Maybe<Scalars["Boolean"]["output"]>;
  beneficiaryDistributions: Array<BeneficiaryDistribution>;
  billingPeriod?: Maybe<Scalars["String"]["output"]>;
  charge?: Maybe<Scalars["Decimal"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  contactName?: Maybe<Scalars["String"]["output"]>;
  contactType?: Maybe<Scalars["String"]["output"]>;
  contents: Array<Content>;
  costBasis?: Maybe<Scalars["Decimal"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  createdById?: Maybe<Scalars["String"]["output"]>;
  createdByUser?: Maybe<User>;
  descriptor: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  estate: Estate;
  estateId: Scalars["String"]["output"];
  estimatedValue?: Maybe<Scalars["Decimal"]["output"]>;
  expenses: Array<Expense>;
  finalValue?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["String"]["output"];
  inTrust?: Maybe<Scalars["Boolean"]["output"]>;
  lastCheckDate?: Maybe<Scalars["DateTimeISO"]["output"]>;
  lastPaymentAmount?: Maybe<Scalars["Decimal"]["output"]>;
  lastPaymentDate?: Maybe<Scalars["DateTimeISO"]["output"]>;
  linkedAccounts: Array<LinkedAccount>;
  loanAmount?: Maybe<Scalars["Decimal"]["output"]>;
  mortgageBank?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  namedBeneficiaries: Array<Scalars["String"]["output"]>;
  notifications: Array<EstateNotification>;
  paidOff?: Maybe<Scalars["Boolean"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  registrationExpiresAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  seenByCustomer?: Maybe<Scalars["Boolean"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  status: ComponentStatus;
  subType: ComponentSubType;
  type: ComponentType;
  updatedAt: Scalars["DateTimeISO"]["output"];
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type ComponentActionCardsArgs = {
  cursor?: InputMaybe<ActionCardWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActionCardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActionCardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActionCardWhereInput>;
};

export type ComponentBeneficiariesArgs = {
  cursor?: InputMaybe<BeneficiaryWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<BeneficiaryWhereInput>;
};

export type ComponentBeneficiaryDistributionsArgs = {
  cursor?: InputMaybe<BeneficiaryDistributionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryDistributionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryDistributionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<BeneficiaryDistributionWhereInput>;
};

export type ComponentContentsArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContentWhereInput>;
};

export type ComponentCreatedByUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type ComponentExpensesArgs = {
  cursor?: InputMaybe<ExpenseWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExpenseScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExpenseOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ExpenseWhereInput>;
};

export type ComponentLinkedAccountsArgs = {
  cursor?: InputMaybe<LinkedAccountWhereUniqueInput>;
  distinct?: InputMaybe<Array<LinkedAccountScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LinkedAccountOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LinkedAccountWhereInput>;
};

export type ComponentNotificationsArgs = {
  cursor?: InputMaybe<EstateNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<EstateNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EstateNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateNotificationWhereInput>;
};

export type Component2 = {
  __typename?: "Component2";
  accountHolder?: Maybe<Scalars["String"]["output"]>;
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  actionCards: Array<ActionCard2>;
  address?: Maybe<Scalars["String"]["output"]>;
  appraisedValue?: Maybe<Scalars["Decimal"]["output"]>;
  balance?: Maybe<Scalars["Decimal"]["output"]>;
  beneficiariesText?: Maybe<Scalars["String"]["output"]>;
  beneficiaryDesignated?: Maybe<Scalars["Boolean"]["output"]>;
  billingPeriod?: Maybe<Scalars["String"]["output"]>;
  charge?: Maybe<Scalars["Decimal"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  contactName?: Maybe<Scalars["String"]["output"]>;
  contactType?: Maybe<Scalars["String"]["output"]>;
  contents: Array<Content2>;
  costBasis?: Maybe<Scalars["Decimal"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  createdById?: Maybe<Scalars["String"]["output"]>;
  createdByUser?: Maybe<User2>;
  descriptor: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  estate: Estate2;
  estateId: Scalars["String"]["output"];
  estimatedValue?: Maybe<Scalars["Decimal"]["output"]>;
  finalValue?: Maybe<Scalars["Decimal"]["output"]>;
  icon: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  inTrust?: Maybe<Scalars["Boolean"]["output"]>;
  lastCheckDate?: Maybe<Scalars["DateTimeISO"]["output"]>;
  lastPaymentAmount?: Maybe<Scalars["Decimal"]["output"]>;
  lastPaymentDate?: Maybe<Scalars["DateTimeISO"]["output"]>;
  loanAmount?: Maybe<Scalars["Decimal"]["output"]>;
  mortgageBank?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  namedBeneficiaries: Array<Scalars["String"]["output"]>;
  notifications: Array<EstateNotification2>;
  paidOff?: Maybe<Scalars["Boolean"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  registrationExpiresAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  seenByCustomer?: Maybe<Scalars["Boolean"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  status: ComponentStatus;
  subType: ComponentSubType;
  subTypeDisplayName: Scalars["String"]["output"];
  type: ComponentType;
  typeDisplayName: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type Component2ContentsArgs = {
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput2>>;
  where?: InputMaybe<ContentWhereInput2>;
};

export type ComponentAvgAggregate = {
  __typename?: "ComponentAvgAggregate";
  appraisedValue?: Maybe<Scalars["Decimal"]["output"]>;
  balance?: Maybe<Scalars["Decimal"]["output"]>;
  charge?: Maybe<Scalars["Decimal"]["output"]>;
  costBasis?: Maybe<Scalars["Decimal"]["output"]>;
  estimatedValue?: Maybe<Scalars["Decimal"]["output"]>;
  finalValue?: Maybe<Scalars["Decimal"]["output"]>;
  lastPaymentAmount?: Maybe<Scalars["Decimal"]["output"]>;
  loanAmount?: Maybe<Scalars["Decimal"]["output"]>;
};

export type ComponentAvgOrderByAggregateInput = {
  appraisedValue?: InputMaybe<SortOrder>;
  balance?: InputMaybe<SortOrder>;
  charge?: InputMaybe<SortOrder>;
  costBasis?: InputMaybe<SortOrder>;
  estimatedValue?: InputMaybe<SortOrder>;
  finalValue?: InputMaybe<SortOrder>;
  lastPaymentAmount?: InputMaybe<SortOrder>;
  loanAmount?: InputMaybe<SortOrder>;
};

export type ComponentBreakDown = {
  __typename?: "ComponentBreakDown";
  count: Scalars["BigInt"]["output"];
  percentage: Scalars["Decimal"]["output"];
  subTotalByType: Scalars["Decimal"]["output"];
  subType: Scalars["String"]["output"];
  sum: Scalars["Decimal"]["output"];
  total: Scalars["Decimal"]["output"];
  type: Scalars["String"]["output"];
};

export type ComponentCount = {
  __typename?: "ComponentCount";
  actionCards: Scalars["Int"]["output"];
  beneficiaries: Scalars["Int"]["output"];
  beneficiaryDistributions: Scalars["Int"]["output"];
  contents: Scalars["Int"]["output"];
  expenses: Scalars["Int"]["output"];
  linkedAccounts: Scalars["Int"]["output"];
  notifications: Scalars["Int"]["output"];
};

export type ComponentCountActionCardsArgs = {
  where?: InputMaybe<ActionCardWhereInput>;
};

export type ComponentCountBeneficiariesArgs = {
  where?: InputMaybe<BeneficiaryWhereInput>;
};

export type ComponentCountBeneficiaryDistributionsArgs = {
  where?: InputMaybe<BeneficiaryDistributionWhereInput>;
};

export type ComponentCountContentsArgs = {
  where?: InputMaybe<ContentWhereInput>;
};

export type ComponentCountExpensesArgs = {
  where?: InputMaybe<ExpenseWhereInput>;
};

export type ComponentCountLinkedAccountsArgs = {
  where?: InputMaybe<LinkedAccountWhereInput>;
};

export type ComponentCountNotificationsArgs = {
  where?: InputMaybe<EstateNotificationWhereInput>;
};

export type ComponentCountAggregate = {
  __typename?: "ComponentCountAggregate";
  _all: Scalars["Int"]["output"];
  accountHolder: Scalars["Int"]["output"];
  accountNumber: Scalars["Int"]["output"];
  address: Scalars["Int"]["output"];
  appraisedValue: Scalars["Int"]["output"];
  balance: Scalars["Int"]["output"];
  beneficiariesText: Scalars["Int"]["output"];
  beneficiaryDesignated: Scalars["Int"]["output"];
  billingPeriod: Scalars["Int"]["output"];
  charge: Scalars["Int"]["output"];
  city: Scalars["Int"]["output"];
  completedAt: Scalars["Int"]["output"];
  contactName: Scalars["Int"]["output"];
  contactType: Scalars["Int"]["output"];
  costBasis: Scalars["Int"]["output"];
  country: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  createdById: Scalars["Int"]["output"];
  descriptor: Scalars["Int"]["output"];
  email: Scalars["Int"]["output"];
  estateId: Scalars["Int"]["output"];
  estimatedValue: Scalars["Int"]["output"];
  finalValue: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  inTrust: Scalars["Int"]["output"];
  lastCheckDate: Scalars["Int"]["output"];
  lastPaymentAmount: Scalars["Int"]["output"];
  lastPaymentDate: Scalars["Int"]["output"];
  linkAccessToken: Scalars["Int"]["output"];
  loanAmount: Scalars["Int"]["output"];
  mortgageBank: Scalars["Int"]["output"];
  name: Scalars["Int"]["output"];
  namedBeneficiaries: Scalars["Int"]["output"];
  paidOff: Scalars["Int"]["output"];
  phone: Scalars["Int"]["output"];
  registrationExpiresAt: Scalars["Int"]["output"];
  seenByCustomer: Scalars["Int"]["output"];
  state: Scalars["Int"]["output"];
  status: Scalars["Int"]["output"];
  subType: Scalars["Int"]["output"];
  type: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  zip: Scalars["Int"]["output"];
};

export type ComponentCountOrderByAggregateInput = {
  accountHolder?: InputMaybe<SortOrder>;
  accountNumber?: InputMaybe<SortOrder>;
  address?: InputMaybe<SortOrder>;
  appraisedValue?: InputMaybe<SortOrder>;
  balance?: InputMaybe<SortOrder>;
  beneficiariesText?: InputMaybe<SortOrder>;
  beneficiaryDesignated?: InputMaybe<SortOrder>;
  billingPeriod?: InputMaybe<SortOrder>;
  charge?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrder>;
  contactName?: InputMaybe<SortOrder>;
  contactType?: InputMaybe<SortOrder>;
  costBasis?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdById?: InputMaybe<SortOrder>;
  descriptor?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  estimatedValue?: InputMaybe<SortOrder>;
  finalValue?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inTrust?: InputMaybe<SortOrder>;
  lastCheckDate?: InputMaybe<SortOrder>;
  lastPaymentAmount?: InputMaybe<SortOrder>;
  lastPaymentDate?: InputMaybe<SortOrder>;
  loanAmount?: InputMaybe<SortOrder>;
  mortgageBank?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  namedBeneficiaries?: InputMaybe<SortOrder>;
  paidOff?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  registrationExpiresAt?: InputMaybe<SortOrder>;
  seenByCustomer?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subType?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type ComponentCreateInput = {
  accountHolder?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutComponentInput>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  appraisedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  balance?: InputMaybe<Scalars["Decimal"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutComponentInput>;
  beneficiariesText?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaryDesignated?: InputMaybe<Scalars["Boolean"]["input"]>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionCreateNestedManyWithoutComponentInput>;
  billingPeriod?: InputMaybe<Scalars["String"]["input"]>;
  charge?: InputMaybe<Scalars["Decimal"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  contactType?: InputMaybe<Scalars["String"]["input"]>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutComponentInput>;
  costBasis?: InputMaybe<Scalars["Decimal"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutComponentsInput>;
  descriptor: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  estate: EstateCreateNestedOneWithoutComponentsInput;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutComponentInput>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastCheckDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lastPaymentAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  lastPaymentDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  linkedAccounts?: InputMaybe<LinkedAccountCreateNestedManyWithoutComponentInput>;
  loanAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  mortgageBank?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  namedBeneficiaries?: InputMaybe<ComponentCreatenamedBeneficiariesInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutComponentInput>;
  paidOff?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationExpiresAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  seenByCustomer?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComponentCreateInput2 = {
  accountHolder?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  appraisedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  balance?: InputMaybe<Scalars["Decimal"]["input"]>;
  billingPeriod?: InputMaybe<Scalars["String"]["input"]>;
  charge?: InputMaybe<Scalars["Decimal"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  contactType?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  descriptor: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  estate: EstateCreateNestedOneWithoutComponentsInput2;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastCheckDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lastPaymentAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  lastPaymentDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  loanAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  mortgageBank?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  paidOff?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationExpiresAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComponentCreateManyCreatedByUserInput = {
  accountHolder?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  appraisedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  balance?: InputMaybe<Scalars["Decimal"]["input"]>;
  beneficiariesText?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaryDesignated?: InputMaybe<Scalars["Boolean"]["input"]>;
  billingPeriod?: InputMaybe<Scalars["String"]["input"]>;
  charge?: InputMaybe<Scalars["Decimal"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  contactType?: InputMaybe<Scalars["String"]["input"]>;
  costBasis?: InputMaybe<Scalars["Decimal"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  descriptor: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  estateId: Scalars["String"]["input"];
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastCheckDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lastPaymentAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  lastPaymentDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  loanAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  mortgageBank?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  namedBeneficiaries?: InputMaybe<ComponentCreatenamedBeneficiariesInput>;
  paidOff?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationExpiresAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  seenByCustomer?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComponentCreateManyCreatedByUserInputEnvelope = {
  data: Array<ComponentCreateManyCreatedByUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ComponentCreateManyEstateInput = {
  accountHolder?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  appraisedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  balance?: InputMaybe<Scalars["Decimal"]["input"]>;
  beneficiariesText?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaryDesignated?: InputMaybe<Scalars["Boolean"]["input"]>;
  billingPeriod?: InputMaybe<Scalars["String"]["input"]>;
  charge?: InputMaybe<Scalars["Decimal"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  contactType?: InputMaybe<Scalars["String"]["input"]>;
  costBasis?: InputMaybe<Scalars["Decimal"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdById?: InputMaybe<Scalars["String"]["input"]>;
  descriptor: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastCheckDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lastPaymentAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  lastPaymentDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  loanAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  mortgageBank?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  namedBeneficiaries?: InputMaybe<ComponentCreatenamedBeneficiariesInput>;
  paidOff?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationExpiresAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  seenByCustomer?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComponentCreateManyEstateInputEnvelope = {
  data: Array<ComponentCreateManyEstateInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ComponentCreateManyInput = {
  accountHolder?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  appraisedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  balance?: InputMaybe<Scalars["Decimal"]["input"]>;
  beneficiariesText?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaryDesignated?: InputMaybe<Scalars["Boolean"]["input"]>;
  billingPeriod?: InputMaybe<Scalars["String"]["input"]>;
  charge?: InputMaybe<Scalars["Decimal"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  contactType?: InputMaybe<Scalars["String"]["input"]>;
  costBasis?: InputMaybe<Scalars["Decimal"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdById?: InputMaybe<Scalars["String"]["input"]>;
  descriptor: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  estateId: Scalars["String"]["input"];
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastCheckDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lastPaymentAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  lastPaymentDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  loanAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  mortgageBank?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  namedBeneficiaries?: InputMaybe<ComponentCreatenamedBeneficiariesInput>;
  paidOff?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationExpiresAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  seenByCustomer?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComponentCreateNestedManyWithoutCreatedByUserInput = {
  connect?: InputMaybe<Array<ComponentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ComponentCreateOrConnectWithoutCreatedByUserInput>
  >;
  create?: InputMaybe<Array<ComponentCreateWithoutCreatedByUserInput>>;
  createMany?: InputMaybe<ComponentCreateManyCreatedByUserInputEnvelope>;
};

export type ComponentCreateNestedManyWithoutEstateInput = {
  connect?: InputMaybe<Array<ComponentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ComponentCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<ComponentCreateWithoutEstateInput>>;
  createMany?: InputMaybe<ComponentCreateManyEstateInputEnvelope>;
};

export type ComponentCreateNestedOneWithoutActionCardsInput = {
  connect?: InputMaybe<ComponentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ComponentCreateOrConnectWithoutActionCardsInput>;
  create?: InputMaybe<ComponentCreateWithoutActionCardsInput>;
};

export type ComponentCreateNestedOneWithoutActionCardsInput2 = {
  connect?: InputMaybe<ComponentWhereUniqueInput2>;
};

export type ComponentCreateNestedOneWithoutBeneficiariesInput = {
  connect?: InputMaybe<ComponentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ComponentCreateOrConnectWithoutBeneficiariesInput>;
  create?: InputMaybe<ComponentCreateWithoutBeneficiariesInput>;
};

export type ComponentCreateNestedOneWithoutBeneficiaryDistributionsInput = {
  connect?: InputMaybe<ComponentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ComponentCreateOrConnectWithoutBeneficiaryDistributionsInput>;
  create?: InputMaybe<ComponentCreateWithoutBeneficiaryDistributionsInput>;
};

export type ComponentCreateNestedOneWithoutContentsInput = {
  connect?: InputMaybe<ComponentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ComponentCreateOrConnectWithoutContentsInput>;
  create?: InputMaybe<ComponentCreateWithoutContentsInput>;
};

export type ComponentCreateNestedOneWithoutContentsInput2 = {
  connect?: InputMaybe<ComponentWhereUniqueInput2>;
};

export type ComponentCreateNestedOneWithoutExpensesInput = {
  connect?: InputMaybe<ComponentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ComponentCreateOrConnectWithoutExpensesInput>;
  create?: InputMaybe<ComponentCreateWithoutExpensesInput>;
};

export type ComponentCreateNestedOneWithoutLinkedAccountsInput = {
  connect?: InputMaybe<ComponentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ComponentCreateOrConnectWithoutLinkedAccountsInput>;
  create?: InputMaybe<ComponentCreateWithoutLinkedAccountsInput>;
};

export type ComponentCreateNestedOneWithoutNotificationsInput = {
  connect?: InputMaybe<ComponentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ComponentCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<ComponentCreateWithoutNotificationsInput>;
};

export type ComponentCreateOrConnectWithoutActionCardsInput = {
  create: ComponentCreateWithoutActionCardsInput;
  where: ComponentWhereUniqueInput;
};

export type ComponentCreateOrConnectWithoutBeneficiariesInput = {
  create: ComponentCreateWithoutBeneficiariesInput;
  where: ComponentWhereUniqueInput;
};

export type ComponentCreateOrConnectWithoutBeneficiaryDistributionsInput = {
  create: ComponentCreateWithoutBeneficiaryDistributionsInput;
  where: ComponentWhereUniqueInput;
};

export type ComponentCreateOrConnectWithoutContentsInput = {
  create: ComponentCreateWithoutContentsInput;
  where: ComponentWhereUniqueInput;
};

export type ComponentCreateOrConnectWithoutCreatedByUserInput = {
  create: ComponentCreateWithoutCreatedByUserInput;
  where: ComponentWhereUniqueInput;
};

export type ComponentCreateOrConnectWithoutEstateInput = {
  create: ComponentCreateWithoutEstateInput;
  where: ComponentWhereUniqueInput;
};

export type ComponentCreateOrConnectWithoutExpensesInput = {
  create: ComponentCreateWithoutExpensesInput;
  where: ComponentWhereUniqueInput;
};

export type ComponentCreateOrConnectWithoutLinkedAccountsInput = {
  create: ComponentCreateWithoutLinkedAccountsInput;
  where: ComponentWhereUniqueInput;
};

export type ComponentCreateOrConnectWithoutNotificationsInput = {
  create: ComponentCreateWithoutNotificationsInput;
  where: ComponentWhereUniqueInput;
};

export type ComponentCreateWithoutActionCardsInput = {
  accountHolder?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  appraisedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  balance?: InputMaybe<Scalars["Decimal"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutComponentInput>;
  beneficiariesText?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaryDesignated?: InputMaybe<Scalars["Boolean"]["input"]>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionCreateNestedManyWithoutComponentInput>;
  billingPeriod?: InputMaybe<Scalars["String"]["input"]>;
  charge?: InputMaybe<Scalars["Decimal"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  contactType?: InputMaybe<Scalars["String"]["input"]>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutComponentInput>;
  costBasis?: InputMaybe<Scalars["Decimal"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutComponentsInput>;
  descriptor: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  estate: EstateCreateNestedOneWithoutComponentsInput;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutComponentInput>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastCheckDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lastPaymentAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  lastPaymentDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  linkedAccounts?: InputMaybe<LinkedAccountCreateNestedManyWithoutComponentInput>;
  loanAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  mortgageBank?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  namedBeneficiaries?: InputMaybe<ComponentCreatenamedBeneficiariesInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutComponentInput>;
  paidOff?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationExpiresAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  seenByCustomer?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComponentCreateWithoutBeneficiariesInput = {
  accountHolder?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutComponentInput>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  appraisedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  balance?: InputMaybe<Scalars["Decimal"]["input"]>;
  beneficiariesText?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaryDesignated?: InputMaybe<Scalars["Boolean"]["input"]>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionCreateNestedManyWithoutComponentInput>;
  billingPeriod?: InputMaybe<Scalars["String"]["input"]>;
  charge?: InputMaybe<Scalars["Decimal"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  contactType?: InputMaybe<Scalars["String"]["input"]>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutComponentInput>;
  costBasis?: InputMaybe<Scalars["Decimal"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutComponentsInput>;
  descriptor: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  estate: EstateCreateNestedOneWithoutComponentsInput;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutComponentInput>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastCheckDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lastPaymentAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  lastPaymentDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  linkedAccounts?: InputMaybe<LinkedAccountCreateNestedManyWithoutComponentInput>;
  loanAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  mortgageBank?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  namedBeneficiaries?: InputMaybe<ComponentCreatenamedBeneficiariesInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutComponentInput>;
  paidOff?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationExpiresAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  seenByCustomer?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComponentCreateWithoutBeneficiaryDistributionsInput = {
  accountHolder?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutComponentInput>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  appraisedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  balance?: InputMaybe<Scalars["Decimal"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutComponentInput>;
  beneficiariesText?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaryDesignated?: InputMaybe<Scalars["Boolean"]["input"]>;
  billingPeriod?: InputMaybe<Scalars["String"]["input"]>;
  charge?: InputMaybe<Scalars["Decimal"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  contactType?: InputMaybe<Scalars["String"]["input"]>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutComponentInput>;
  costBasis?: InputMaybe<Scalars["Decimal"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutComponentsInput>;
  descriptor: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  estate: EstateCreateNestedOneWithoutComponentsInput;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutComponentInput>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastCheckDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lastPaymentAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  lastPaymentDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  linkedAccounts?: InputMaybe<LinkedAccountCreateNestedManyWithoutComponentInput>;
  loanAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  mortgageBank?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  namedBeneficiaries?: InputMaybe<ComponentCreatenamedBeneficiariesInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutComponentInput>;
  paidOff?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationExpiresAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  seenByCustomer?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComponentCreateWithoutContentsInput = {
  accountHolder?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutComponentInput>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  appraisedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  balance?: InputMaybe<Scalars["Decimal"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutComponentInput>;
  beneficiariesText?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaryDesignated?: InputMaybe<Scalars["Boolean"]["input"]>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionCreateNestedManyWithoutComponentInput>;
  billingPeriod?: InputMaybe<Scalars["String"]["input"]>;
  charge?: InputMaybe<Scalars["Decimal"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  contactType?: InputMaybe<Scalars["String"]["input"]>;
  costBasis?: InputMaybe<Scalars["Decimal"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutComponentsInput>;
  descriptor: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  estate: EstateCreateNestedOneWithoutComponentsInput;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutComponentInput>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastCheckDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lastPaymentAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  lastPaymentDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  linkedAccounts?: InputMaybe<LinkedAccountCreateNestedManyWithoutComponentInput>;
  loanAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  mortgageBank?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  namedBeneficiaries?: InputMaybe<ComponentCreatenamedBeneficiariesInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutComponentInput>;
  paidOff?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationExpiresAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  seenByCustomer?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComponentCreateWithoutCreatedByUserInput = {
  accountHolder?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutComponentInput>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  appraisedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  balance?: InputMaybe<Scalars["Decimal"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutComponentInput>;
  beneficiariesText?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaryDesignated?: InputMaybe<Scalars["Boolean"]["input"]>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionCreateNestedManyWithoutComponentInput>;
  billingPeriod?: InputMaybe<Scalars["String"]["input"]>;
  charge?: InputMaybe<Scalars["Decimal"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  contactType?: InputMaybe<Scalars["String"]["input"]>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutComponentInput>;
  costBasis?: InputMaybe<Scalars["Decimal"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  descriptor: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  estate: EstateCreateNestedOneWithoutComponentsInput;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutComponentInput>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastCheckDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lastPaymentAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  lastPaymentDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  linkedAccounts?: InputMaybe<LinkedAccountCreateNestedManyWithoutComponentInput>;
  loanAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  mortgageBank?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  namedBeneficiaries?: InputMaybe<ComponentCreatenamedBeneficiariesInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutComponentInput>;
  paidOff?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationExpiresAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  seenByCustomer?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComponentCreateWithoutEstateInput = {
  accountHolder?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutComponentInput>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  appraisedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  balance?: InputMaybe<Scalars["Decimal"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutComponentInput>;
  beneficiariesText?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaryDesignated?: InputMaybe<Scalars["Boolean"]["input"]>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionCreateNestedManyWithoutComponentInput>;
  billingPeriod?: InputMaybe<Scalars["String"]["input"]>;
  charge?: InputMaybe<Scalars["Decimal"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  contactType?: InputMaybe<Scalars["String"]["input"]>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutComponentInput>;
  costBasis?: InputMaybe<Scalars["Decimal"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutComponentsInput>;
  descriptor: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutComponentInput>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastCheckDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lastPaymentAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  lastPaymentDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  linkedAccounts?: InputMaybe<LinkedAccountCreateNestedManyWithoutComponentInput>;
  loanAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  mortgageBank?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  namedBeneficiaries?: InputMaybe<ComponentCreatenamedBeneficiariesInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutComponentInput>;
  paidOff?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationExpiresAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  seenByCustomer?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComponentCreateWithoutEstateInput2 = {
  accountHolder?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  appraisedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  balance?: InputMaybe<Scalars["Decimal"]["input"]>;
  beneficiariesText?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaryDesignated?: InputMaybe<Scalars["Boolean"]["input"]>;
  billingPeriod?: InputMaybe<Scalars["String"]["input"]>;
  charge?: InputMaybe<Scalars["Decimal"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  contactType?: InputMaybe<Scalars["String"]["input"]>;
  costBasis?: InputMaybe<Scalars["Decimal"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutComponentsInput2>;
  descriptor: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastCheckDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lastPaymentAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  lastPaymentDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  loanAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  mortgageBank?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  namedBeneficiaries?: InputMaybe<Array<Scalars["String"]["input"]>>;
  paidOff?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationExpiresAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComponentCreateWithoutExpensesInput = {
  accountHolder?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutComponentInput>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  appraisedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  balance?: InputMaybe<Scalars["Decimal"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutComponentInput>;
  beneficiariesText?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaryDesignated?: InputMaybe<Scalars["Boolean"]["input"]>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionCreateNestedManyWithoutComponentInput>;
  billingPeriod?: InputMaybe<Scalars["String"]["input"]>;
  charge?: InputMaybe<Scalars["Decimal"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  contactType?: InputMaybe<Scalars["String"]["input"]>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutComponentInput>;
  costBasis?: InputMaybe<Scalars["Decimal"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutComponentsInput>;
  descriptor: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  estate: EstateCreateNestedOneWithoutComponentsInput;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastCheckDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lastPaymentAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  lastPaymentDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  linkedAccounts?: InputMaybe<LinkedAccountCreateNestedManyWithoutComponentInput>;
  loanAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  mortgageBank?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  namedBeneficiaries?: InputMaybe<ComponentCreatenamedBeneficiariesInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutComponentInput>;
  paidOff?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationExpiresAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  seenByCustomer?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComponentCreateWithoutLinkedAccountsInput = {
  accountHolder?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutComponentInput>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  appraisedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  balance?: InputMaybe<Scalars["Decimal"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutComponentInput>;
  beneficiariesText?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaryDesignated?: InputMaybe<Scalars["Boolean"]["input"]>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionCreateNestedManyWithoutComponentInput>;
  billingPeriod?: InputMaybe<Scalars["String"]["input"]>;
  charge?: InputMaybe<Scalars["Decimal"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  contactType?: InputMaybe<Scalars["String"]["input"]>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutComponentInput>;
  costBasis?: InputMaybe<Scalars["Decimal"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutComponentsInput>;
  descriptor: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  estate: EstateCreateNestedOneWithoutComponentsInput;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutComponentInput>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastCheckDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lastPaymentAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  lastPaymentDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  loanAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  mortgageBank?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  namedBeneficiaries?: InputMaybe<ComponentCreatenamedBeneficiariesInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutComponentInput>;
  paidOff?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationExpiresAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  seenByCustomer?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComponentCreateWithoutNotificationsInput = {
  accountHolder?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutComponentInput>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  appraisedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  balance?: InputMaybe<Scalars["Decimal"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutComponentInput>;
  beneficiariesText?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaryDesignated?: InputMaybe<Scalars["Boolean"]["input"]>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionCreateNestedManyWithoutComponentInput>;
  billingPeriod?: InputMaybe<Scalars["String"]["input"]>;
  charge?: InputMaybe<Scalars["Decimal"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  contactType?: InputMaybe<Scalars["String"]["input"]>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutComponentInput>;
  costBasis?: InputMaybe<Scalars["Decimal"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutComponentsInput>;
  descriptor: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  estate: EstateCreateNestedOneWithoutComponentsInput;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutComponentInput>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastCheckDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lastPaymentAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  lastPaymentDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  linkedAccounts?: InputMaybe<LinkedAccountCreateNestedManyWithoutComponentInput>;
  loanAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  mortgageBank?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  namedBeneficiaries?: InputMaybe<ComponentCreatenamedBeneficiariesInput>;
  paidOff?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationExpiresAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  seenByCustomer?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComponentCreatenamedBeneficiariesInput = {
  set: Array<Scalars["String"]["input"]>;
};

export type ComponentGroupBy = {
  __typename?: "ComponentGroupBy";
  _avg?: Maybe<ComponentAvgAggregate>;
  _count?: Maybe<ComponentCountAggregate>;
  _max?: Maybe<ComponentMaxAggregate>;
  _min?: Maybe<ComponentMinAggregate>;
  _sum?: Maybe<ComponentSumAggregate>;
  accountHolder?: Maybe<Scalars["String"]["output"]>;
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  address?: Maybe<Scalars["String"]["output"]>;
  appraisedValue?: Maybe<Scalars["Decimal"]["output"]>;
  balance?: Maybe<Scalars["Decimal"]["output"]>;
  beneficiariesText?: Maybe<Scalars["String"]["output"]>;
  beneficiaryDesignated?: Maybe<Scalars["Boolean"]["output"]>;
  billingPeriod?: Maybe<Scalars["String"]["output"]>;
  charge?: Maybe<Scalars["Decimal"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  contactName?: Maybe<Scalars["String"]["output"]>;
  contactType?: Maybe<Scalars["String"]["output"]>;
  costBasis?: Maybe<Scalars["Decimal"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  createdById?: Maybe<Scalars["String"]["output"]>;
  descriptor: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  estateId: Scalars["String"]["output"];
  estimatedValue?: Maybe<Scalars["Decimal"]["output"]>;
  finalValue?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["String"]["output"];
  inTrust?: Maybe<Scalars["Boolean"]["output"]>;
  lastCheckDate?: Maybe<Scalars["DateTimeISO"]["output"]>;
  lastPaymentAmount?: Maybe<Scalars["Decimal"]["output"]>;
  lastPaymentDate?: Maybe<Scalars["DateTimeISO"]["output"]>;
  linkAccessToken?: Maybe<Scalars["String"]["output"]>;
  loanAmount?: Maybe<Scalars["Decimal"]["output"]>;
  mortgageBank?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  namedBeneficiaries?: Maybe<Array<Scalars["String"]["output"]>>;
  paidOff?: Maybe<Scalars["Boolean"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  registrationExpiresAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  seenByCustomer?: Maybe<Scalars["Boolean"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  status: ComponentStatus;
  subType: ComponentSubType;
  type: ComponentType;
  updatedAt: Scalars["DateTimeISO"]["output"];
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type ComponentListRelationFilter = {
  every?: InputMaybe<ComponentWhereInput>;
  none?: InputMaybe<ComponentWhereInput>;
  some?: InputMaybe<ComponentWhereInput>;
};

export type ComponentMaxAggregate = {
  __typename?: "ComponentMaxAggregate";
  accountHolder?: Maybe<Scalars["String"]["output"]>;
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  address?: Maybe<Scalars["String"]["output"]>;
  appraisedValue?: Maybe<Scalars["Decimal"]["output"]>;
  balance?: Maybe<Scalars["Decimal"]["output"]>;
  beneficiariesText?: Maybe<Scalars["String"]["output"]>;
  beneficiaryDesignated?: Maybe<Scalars["Boolean"]["output"]>;
  billingPeriod?: Maybe<Scalars["String"]["output"]>;
  charge?: Maybe<Scalars["Decimal"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  contactName?: Maybe<Scalars["String"]["output"]>;
  contactType?: Maybe<Scalars["String"]["output"]>;
  costBasis?: Maybe<Scalars["Decimal"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  createdById?: Maybe<Scalars["String"]["output"]>;
  descriptor?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  estimatedValue?: Maybe<Scalars["Decimal"]["output"]>;
  finalValue?: Maybe<Scalars["Decimal"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  inTrust?: Maybe<Scalars["Boolean"]["output"]>;
  lastCheckDate?: Maybe<Scalars["DateTimeISO"]["output"]>;
  lastPaymentAmount?: Maybe<Scalars["Decimal"]["output"]>;
  lastPaymentDate?: Maybe<Scalars["DateTimeISO"]["output"]>;
  linkAccessToken?: Maybe<Scalars["String"]["output"]>;
  loanAmount?: Maybe<Scalars["Decimal"]["output"]>;
  mortgageBank?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  paidOff?: Maybe<Scalars["Boolean"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  registrationExpiresAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  seenByCustomer?: Maybe<Scalars["Boolean"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<ComponentStatus>;
  subType?: Maybe<ComponentSubType>;
  type?: Maybe<ComponentType>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type ComponentMaxOrderByAggregateInput = {
  accountHolder?: InputMaybe<SortOrder>;
  accountNumber?: InputMaybe<SortOrder>;
  address?: InputMaybe<SortOrder>;
  appraisedValue?: InputMaybe<SortOrder>;
  balance?: InputMaybe<SortOrder>;
  beneficiariesText?: InputMaybe<SortOrder>;
  beneficiaryDesignated?: InputMaybe<SortOrder>;
  billingPeriod?: InputMaybe<SortOrder>;
  charge?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrder>;
  contactName?: InputMaybe<SortOrder>;
  contactType?: InputMaybe<SortOrder>;
  costBasis?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdById?: InputMaybe<SortOrder>;
  descriptor?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  estimatedValue?: InputMaybe<SortOrder>;
  finalValue?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inTrust?: InputMaybe<SortOrder>;
  lastCheckDate?: InputMaybe<SortOrder>;
  lastPaymentAmount?: InputMaybe<SortOrder>;
  lastPaymentDate?: InputMaybe<SortOrder>;
  loanAmount?: InputMaybe<SortOrder>;
  mortgageBank?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  paidOff?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  registrationExpiresAt?: InputMaybe<SortOrder>;
  seenByCustomer?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subType?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type ComponentMinAggregate = {
  __typename?: "ComponentMinAggregate";
  accountHolder?: Maybe<Scalars["String"]["output"]>;
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  address?: Maybe<Scalars["String"]["output"]>;
  appraisedValue?: Maybe<Scalars["Decimal"]["output"]>;
  balance?: Maybe<Scalars["Decimal"]["output"]>;
  beneficiariesText?: Maybe<Scalars["String"]["output"]>;
  beneficiaryDesignated?: Maybe<Scalars["Boolean"]["output"]>;
  billingPeriod?: Maybe<Scalars["String"]["output"]>;
  charge?: Maybe<Scalars["Decimal"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  contactName?: Maybe<Scalars["String"]["output"]>;
  contactType?: Maybe<Scalars["String"]["output"]>;
  costBasis?: Maybe<Scalars["Decimal"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  createdById?: Maybe<Scalars["String"]["output"]>;
  descriptor?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  estimatedValue?: Maybe<Scalars["Decimal"]["output"]>;
  finalValue?: Maybe<Scalars["Decimal"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  inTrust?: Maybe<Scalars["Boolean"]["output"]>;
  lastCheckDate?: Maybe<Scalars["DateTimeISO"]["output"]>;
  lastPaymentAmount?: Maybe<Scalars["Decimal"]["output"]>;
  lastPaymentDate?: Maybe<Scalars["DateTimeISO"]["output"]>;
  linkAccessToken?: Maybe<Scalars["String"]["output"]>;
  loanAmount?: Maybe<Scalars["Decimal"]["output"]>;
  mortgageBank?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  paidOff?: Maybe<Scalars["Boolean"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  registrationExpiresAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  seenByCustomer?: Maybe<Scalars["Boolean"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<ComponentStatus>;
  subType?: Maybe<ComponentSubType>;
  type?: Maybe<ComponentType>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type ComponentMinOrderByAggregateInput = {
  accountHolder?: InputMaybe<SortOrder>;
  accountNumber?: InputMaybe<SortOrder>;
  address?: InputMaybe<SortOrder>;
  appraisedValue?: InputMaybe<SortOrder>;
  balance?: InputMaybe<SortOrder>;
  beneficiariesText?: InputMaybe<SortOrder>;
  beneficiaryDesignated?: InputMaybe<SortOrder>;
  billingPeriod?: InputMaybe<SortOrder>;
  charge?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrder>;
  contactName?: InputMaybe<SortOrder>;
  contactType?: InputMaybe<SortOrder>;
  costBasis?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdById?: InputMaybe<SortOrder>;
  descriptor?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  estimatedValue?: InputMaybe<SortOrder>;
  finalValue?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inTrust?: InputMaybe<SortOrder>;
  lastCheckDate?: InputMaybe<SortOrder>;
  lastPaymentAmount?: InputMaybe<SortOrder>;
  lastPaymentDate?: InputMaybe<SortOrder>;
  loanAmount?: InputMaybe<SortOrder>;
  mortgageBank?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  paidOff?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  registrationExpiresAt?: InputMaybe<SortOrder>;
  seenByCustomer?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subType?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type ComponentNullableRelationFilter = {
  is?: InputMaybe<ComponentWhereInput>;
  isNot?: InputMaybe<ComponentWhereInput>;
};

export type ComponentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ComponentOrderByWithAggregationInput = {
  _avg?: InputMaybe<ComponentAvgOrderByAggregateInput>;
  _count?: InputMaybe<ComponentCountOrderByAggregateInput>;
  _max?: InputMaybe<ComponentMaxOrderByAggregateInput>;
  _min?: InputMaybe<ComponentMinOrderByAggregateInput>;
  _sum?: InputMaybe<ComponentSumOrderByAggregateInput>;
  accountHolder?: InputMaybe<SortOrderInput>;
  accountNumber?: InputMaybe<SortOrderInput>;
  address?: InputMaybe<SortOrderInput>;
  appraisedValue?: InputMaybe<SortOrderInput>;
  balance?: InputMaybe<SortOrderInput>;
  beneficiariesText?: InputMaybe<SortOrderInput>;
  beneficiaryDesignated?: InputMaybe<SortOrderInput>;
  billingPeriod?: InputMaybe<SortOrderInput>;
  charge?: InputMaybe<SortOrderInput>;
  city?: InputMaybe<SortOrderInput>;
  completedAt?: InputMaybe<SortOrderInput>;
  contactName?: InputMaybe<SortOrderInput>;
  contactType?: InputMaybe<SortOrderInput>;
  costBasis?: InputMaybe<SortOrderInput>;
  country?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdById?: InputMaybe<SortOrderInput>;
  descriptor?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrderInput>;
  estateId?: InputMaybe<SortOrder>;
  estimatedValue?: InputMaybe<SortOrderInput>;
  finalValue?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  inTrust?: InputMaybe<SortOrderInput>;
  lastCheckDate?: InputMaybe<SortOrderInput>;
  lastPaymentAmount?: InputMaybe<SortOrderInput>;
  lastPaymentDate?: InputMaybe<SortOrderInput>;
  loanAmount?: InputMaybe<SortOrderInput>;
  mortgageBank?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  namedBeneficiaries?: InputMaybe<SortOrder>;
  paidOff?: InputMaybe<SortOrderInput>;
  phone?: InputMaybe<SortOrderInput>;
  registrationExpiresAt?: InputMaybe<SortOrderInput>;
  seenByCustomer?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  subType?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrderInput>;
};

export type ComponentOrderByWithRelationInput = {
  accountHolder?: InputMaybe<SortOrderInput>;
  accountNumber?: InputMaybe<SortOrderInput>;
  actionCards?: InputMaybe<ActionCardOrderByRelationAggregateInput>;
  address?: InputMaybe<SortOrderInput>;
  appraisedValue?: InputMaybe<SortOrderInput>;
  balance?: InputMaybe<SortOrderInput>;
  beneficiaries?: InputMaybe<BeneficiaryOrderByRelationAggregateInput>;
  beneficiariesText?: InputMaybe<SortOrderInput>;
  beneficiaryDesignated?: InputMaybe<SortOrderInput>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionOrderByRelationAggregateInput>;
  billingPeriod?: InputMaybe<SortOrderInput>;
  charge?: InputMaybe<SortOrderInput>;
  city?: InputMaybe<SortOrderInput>;
  completedAt?: InputMaybe<SortOrderInput>;
  contactName?: InputMaybe<SortOrderInput>;
  contactType?: InputMaybe<SortOrderInput>;
  contents?: InputMaybe<ContentOrderByRelationAggregateInput>;
  costBasis?: InputMaybe<SortOrderInput>;
  country?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdById?: InputMaybe<SortOrderInput>;
  createdByUser?: InputMaybe<UserOrderByWithRelationInput>;
  descriptor?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrderInput>;
  estate?: InputMaybe<EstateOrderByWithRelationInput>;
  estateId?: InputMaybe<SortOrder>;
  estimatedValue?: InputMaybe<SortOrderInput>;
  expenses?: InputMaybe<ExpenseOrderByRelationAggregateInput>;
  finalValue?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  inTrust?: InputMaybe<SortOrderInput>;
  lastCheckDate?: InputMaybe<SortOrderInput>;
  lastPaymentAmount?: InputMaybe<SortOrderInput>;
  lastPaymentDate?: InputMaybe<SortOrderInput>;
  linkedAccounts?: InputMaybe<LinkedAccountOrderByRelationAggregateInput>;
  loanAmount?: InputMaybe<SortOrderInput>;
  mortgageBank?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  namedBeneficiaries?: InputMaybe<SortOrder>;
  notifications?: InputMaybe<EstateNotificationOrderByRelationAggregateInput>;
  paidOff?: InputMaybe<SortOrderInput>;
  phone?: InputMaybe<SortOrderInput>;
  registrationExpiresAt?: InputMaybe<SortOrderInput>;
  seenByCustomer?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  subType?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrderInput>;
};

export type ComponentRelationFilter = {
  is?: InputMaybe<ComponentWhereInput>;
  isNot?: InputMaybe<ComponentWhereInput>;
};

export enum ComponentScalarFieldEnum {
  AccountHolder = "accountHolder",
  AccountNumber = "accountNumber",
  Address = "address",
  AppraisedValue = "appraisedValue",
  Balance = "balance",
  BeneficiariesText = "beneficiariesText",
  BeneficiaryDesignated = "beneficiaryDesignated",
  BillingPeriod = "billingPeriod",
  Charge = "charge",
  City = "city",
  CompletedAt = "completedAt",
  ContactName = "contactName",
  ContactType = "contactType",
  CostBasis = "costBasis",
  Country = "country",
  CreatedAt = "createdAt",
  CreatedById = "createdById",
  Descriptor = "descriptor",
  Email = "email",
  EstateId = "estateId",
  EstimatedValue = "estimatedValue",
  FinalValue = "finalValue",
  Id = "id",
  InTrust = "inTrust",
  LastCheckDate = "lastCheckDate",
  LastPaymentAmount = "lastPaymentAmount",
  LastPaymentDate = "lastPaymentDate",
  LinkAccessToken = "linkAccessToken",
  LoanAmount = "loanAmount",
  MortgageBank = "mortgageBank",
  Name = "name",
  NamedBeneficiaries = "namedBeneficiaries",
  PaidOff = "paidOff",
  Phone = "phone",
  RegistrationExpiresAt = "registrationExpiresAt",
  SeenByCustomer = "seenByCustomer",
  State = "state",
  Status = "status",
  SubType = "subType",
  Type = "type",
  UpdatedAt = "updatedAt",
  Zip = "zip",
}

export type ComponentScalarWhereInput = {
  AND?: InputMaybe<Array<ComponentScalarWhereInput>>;
  NOT?: InputMaybe<Array<ComponentScalarWhereInput>>;
  OR?: InputMaybe<Array<ComponentScalarWhereInput>>;
  accountHolder?: InputMaybe<StringNullableFilter>;
  accountNumber?: InputMaybe<StringNullableFilter>;
  address?: InputMaybe<StringNullableFilter>;
  appraisedValue?: InputMaybe<DecimalNullableFilter>;
  balance?: InputMaybe<DecimalNullableFilter>;
  beneficiariesText?: InputMaybe<StringNullableFilter>;
  beneficiaryDesignated?: InputMaybe<BoolNullableFilter>;
  billingPeriod?: InputMaybe<StringNullableFilter>;
  charge?: InputMaybe<DecimalNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  contactName?: InputMaybe<StringNullableFilter>;
  contactType?: InputMaybe<StringNullableFilter>;
  costBasis?: InputMaybe<DecimalNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<StringNullableFilter>;
  descriptor?: InputMaybe<StringFilter>;
  email?: InputMaybe<StringNullableFilter>;
  estateId?: InputMaybe<StringFilter>;
  estimatedValue?: InputMaybe<DecimalNullableFilter>;
  finalValue?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inTrust?: InputMaybe<BoolNullableFilter>;
  lastCheckDate?: InputMaybe<DateTimeNullableFilter>;
  lastPaymentAmount?: InputMaybe<DecimalNullableFilter>;
  lastPaymentDate?: InputMaybe<DateTimeNullableFilter>;
  loanAmount?: InputMaybe<DecimalNullableFilter>;
  mortgageBank?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  namedBeneficiaries?: InputMaybe<StringNullableListFilter>;
  paidOff?: InputMaybe<BoolNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  registrationExpiresAt?: InputMaybe<DateTimeNullableFilter>;
  seenByCustomer?: InputMaybe<BoolNullableFilter>;
  state?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumComponentStatusFilter>;
  subType?: InputMaybe<EnumComponentSubTypeFilter>;
  type?: InputMaybe<EnumComponentTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type ComponentScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ComponentScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ComponentScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ComponentScalarWhereWithAggregatesInput>>;
  accountHolder?: InputMaybe<StringNullableWithAggregatesFilter>;
  accountNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  address?: InputMaybe<StringNullableWithAggregatesFilter>;
  appraisedValue?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  balance?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  beneficiariesText?: InputMaybe<StringNullableWithAggregatesFilter>;
  beneficiaryDesignated?: InputMaybe<BoolNullableWithAggregatesFilter>;
  billingPeriod?: InputMaybe<StringNullableWithAggregatesFilter>;
  charge?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  city?: InputMaybe<StringNullableWithAggregatesFilter>;
  completedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  contactName?: InputMaybe<StringNullableWithAggregatesFilter>;
  contactType?: InputMaybe<StringNullableWithAggregatesFilter>;
  costBasis?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  country?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  createdById?: InputMaybe<StringNullableWithAggregatesFilter>;
  descriptor?: InputMaybe<StringWithAggregatesFilter>;
  email?: InputMaybe<StringNullableWithAggregatesFilter>;
  estateId?: InputMaybe<StringWithAggregatesFilter>;
  estimatedValue?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  finalValue?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  inTrust?: InputMaybe<BoolNullableWithAggregatesFilter>;
  lastCheckDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  lastPaymentAmount?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  lastPaymentDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  loanAmount?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  mortgageBank?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  namedBeneficiaries?: InputMaybe<StringNullableListFilter>;
  paidOff?: InputMaybe<BoolNullableWithAggregatesFilter>;
  phone?: InputMaybe<StringNullableWithAggregatesFilter>;
  registrationExpiresAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  seenByCustomer?: InputMaybe<BoolNullableWithAggregatesFilter>;
  state?: InputMaybe<StringNullableWithAggregatesFilter>;
  status?: InputMaybe<EnumComponentStatusWithAggregatesFilter>;
  subType?: InputMaybe<EnumComponentSubTypeWithAggregatesFilter>;
  type?: InputMaybe<EnumComponentTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  zip?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export enum ComponentStatus {
  Blocked = "Blocked",
  Completed = "Completed",
  InProgress = "InProgress",
  Pending = "Pending",
}

export enum ComponentSubType {
  BankAccount = "BankAccount",
  BillToCancel = "BillToCancel",
  Debt = "Debt",
  Discovery = "Discovery",
  Distribution = "Distribution",
  Investment = "Investment",
  LifeInsurance = "LifeInsurance",
  PersonalProperty = "PersonalProperty",
  Probate = "Probate",
  RealEstate = "RealEstate",
  Retirement = "Retirement",
  Tax = "Tax",
  UnclaimedProperty = "UnclaimedProperty",
  Vehicle = "Vehicle",
}

export type ComponentSubTypeItem = {
  __typename?: "ComponentSubTypeItem";
  displayName: Scalars["String"]["output"];
  icon?: Maybe<Scalars["String"]["output"]>;
  type: ComponentSubType;
};

export type ComponentSumAggregate = {
  __typename?: "ComponentSumAggregate";
  appraisedValue?: Maybe<Scalars["Decimal"]["output"]>;
  balance?: Maybe<Scalars["Decimal"]["output"]>;
  charge?: Maybe<Scalars["Decimal"]["output"]>;
  costBasis?: Maybe<Scalars["Decimal"]["output"]>;
  estimatedValue?: Maybe<Scalars["Decimal"]["output"]>;
  finalValue?: Maybe<Scalars["Decimal"]["output"]>;
  lastPaymentAmount?: Maybe<Scalars["Decimal"]["output"]>;
  loanAmount?: Maybe<Scalars["Decimal"]["output"]>;
};

export type ComponentSumOrderByAggregateInput = {
  appraisedValue?: InputMaybe<SortOrder>;
  balance?: InputMaybe<SortOrder>;
  charge?: InputMaybe<SortOrder>;
  costBasis?: InputMaybe<SortOrder>;
  estimatedValue?: InputMaybe<SortOrder>;
  finalValue?: InputMaybe<SortOrder>;
  lastPaymentAmount?: InputMaybe<SortOrder>;
  loanAmount?: InputMaybe<SortOrder>;
};

export enum ComponentType {
  Asset = "Asset",
  Debt = "Debt",
  Obligation = "Obligation",
  Process = "Process",
}

export type ComponentTypeItem = {
  __typename?: "ComponentTypeItem";
  componentSubTypes: Array<ComponentSubTypeItem>;
  displayName: Scalars["String"]["output"];
  icon?: Maybe<Scalars["String"]["output"]>;
  type: ComponentType;
};

export type ComponentUpdateInput = {
  accountHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutComponentNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appraisedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  balance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutComponentNestedInput>;
  beneficiariesText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaryDesignated?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionUpdateManyWithoutComponentNestedInput>;
  billingPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  charge?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutComponentNestedInput>;
  costBasis?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutComponentsNestedInput>;
  descriptor?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutComponentsNestedInput>;
  estimatedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutComponentNestedInput>;
  finalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lastCheckDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastPaymentAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  lastPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  linkedAccounts?: InputMaybe<LinkedAccountUpdateManyWithoutComponentNestedInput>;
  loanAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  mortgageBank?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  namedBeneficiaries?: InputMaybe<ComponentUpdatenamedBeneficiariesInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutComponentNestedInput>;
  paidOff?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registrationExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  seenByCustomer?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumComponentStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<EnumComponentSubTypeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumComponentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ComponentUpdateInput2 = {
  accountHolder?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  appraisedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  balance?: InputMaybe<Scalars["Decimal"]["input"]>;
  billingPeriod?: InputMaybe<Scalars["String"]["input"]>;
  charge?: InputMaybe<Scalars["Decimal"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  contactType?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  descriptor: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastCheckDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lastPaymentAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  lastPaymentDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  loanAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  mortgageBank?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  paidOff?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationExpiresAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComponentUpdateManyMutationInput = {
  accountHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appraisedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  balance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  beneficiariesText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaryDesignated?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  billingPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  charge?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  costBasis?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptor?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  finalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lastCheckDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastPaymentAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  lastPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  loanAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  mortgageBank?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  namedBeneficiaries?: InputMaybe<ComponentUpdatenamedBeneficiariesInput>;
  paidOff?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registrationExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  seenByCustomer?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumComponentStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<EnumComponentSubTypeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumComponentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ComponentUpdateManyWithWhereWithoutCreatedByUserInput = {
  data: ComponentUpdateManyMutationInput;
  where: ComponentScalarWhereInput;
};

export type ComponentUpdateManyWithWhereWithoutEstateInput = {
  data: ComponentUpdateManyMutationInput;
  where: ComponentScalarWhereInput;
};

export type ComponentUpdateManyWithoutCreatedByUserNestedInput = {
  connect?: InputMaybe<Array<ComponentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ComponentCreateOrConnectWithoutCreatedByUserInput>
  >;
  create?: InputMaybe<Array<ComponentCreateWithoutCreatedByUserInput>>;
  createMany?: InputMaybe<ComponentCreateManyCreatedByUserInputEnvelope>;
  delete?: InputMaybe<Array<ComponentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ComponentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ComponentWhereUniqueInput>>;
  set?: InputMaybe<Array<ComponentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ComponentUpdateWithWhereUniqueWithoutCreatedByUserInput>
  >;
  updateMany?: InputMaybe<
    Array<ComponentUpdateManyWithWhereWithoutCreatedByUserInput>
  >;
  upsert?: InputMaybe<
    Array<ComponentUpsertWithWhereUniqueWithoutCreatedByUserInput>
  >;
};

export type ComponentUpdateManyWithoutEstateNestedInput = {
  connect?: InputMaybe<Array<ComponentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ComponentCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<ComponentCreateWithoutEstateInput>>;
  createMany?: InputMaybe<ComponentCreateManyEstateInputEnvelope>;
  delete?: InputMaybe<Array<ComponentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ComponentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ComponentWhereUniqueInput>>;
  set?: InputMaybe<Array<ComponentWhereUniqueInput>>;
  update?: InputMaybe<Array<ComponentUpdateWithWhereUniqueWithoutEstateInput>>;
  updateMany?: InputMaybe<
    Array<ComponentUpdateManyWithWhereWithoutEstateInput>
  >;
  upsert?: InputMaybe<Array<ComponentUpsertWithWhereUniqueWithoutEstateInput>>;
};

export type ComponentUpdateManyWithoutEstateNestedInput2 = {
  create?: InputMaybe<Array<ComponentCreateWithoutEstateInput2>>;
  update?: InputMaybe<Array<ComponentUpdateWithWhereUniqueWithoutEstateInput2>>;
};

export type ComponentUpdateOneRequiredWithoutLinkedAccountsNestedInput = {
  connect?: InputMaybe<ComponentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ComponentCreateOrConnectWithoutLinkedAccountsInput>;
  create?: InputMaybe<ComponentCreateWithoutLinkedAccountsInput>;
  update?: InputMaybe<ComponentUpdateToOneWithWhereWithoutLinkedAccountsInput>;
  upsert?: InputMaybe<ComponentUpsertWithoutLinkedAccountsInput>;
};

export type ComponentUpdateOneWithoutActionCardsNestedInput = {
  connect?: InputMaybe<ComponentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ComponentCreateOrConnectWithoutActionCardsInput>;
  create?: InputMaybe<ComponentCreateWithoutActionCardsInput>;
  delete?: InputMaybe<ComponentWhereInput>;
  disconnect?: InputMaybe<ComponentWhereInput>;
  update?: InputMaybe<ComponentUpdateToOneWithWhereWithoutActionCardsInput>;
  upsert?: InputMaybe<ComponentUpsertWithoutActionCardsInput>;
};

export type ComponentUpdateOneWithoutActionCardsNestedInput2 = {
  connect?: InputMaybe<ComponentWhereUniqueInput2>;
};

export type ComponentUpdateOneWithoutBeneficiariesNestedInput = {
  connect?: InputMaybe<ComponentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ComponentCreateOrConnectWithoutBeneficiariesInput>;
  create?: InputMaybe<ComponentCreateWithoutBeneficiariesInput>;
  delete?: InputMaybe<ComponentWhereInput>;
  disconnect?: InputMaybe<ComponentWhereInput>;
  update?: InputMaybe<ComponentUpdateToOneWithWhereWithoutBeneficiariesInput>;
  upsert?: InputMaybe<ComponentUpsertWithoutBeneficiariesInput>;
};

export type ComponentUpdateOneWithoutBeneficiaryDistributionsNestedInput = {
  connect?: InputMaybe<ComponentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ComponentCreateOrConnectWithoutBeneficiaryDistributionsInput>;
  create?: InputMaybe<ComponentCreateWithoutBeneficiaryDistributionsInput>;
  delete?: InputMaybe<ComponentWhereInput>;
  disconnect?: InputMaybe<ComponentWhereInput>;
  update?: InputMaybe<ComponentUpdateToOneWithWhereWithoutBeneficiaryDistributionsInput>;
  upsert?: InputMaybe<ComponentUpsertWithoutBeneficiaryDistributionsInput>;
};

export type ComponentUpdateOneWithoutContentsNestedInput = {
  connect?: InputMaybe<ComponentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ComponentCreateOrConnectWithoutContentsInput>;
  create?: InputMaybe<ComponentCreateWithoutContentsInput>;
  delete?: InputMaybe<ComponentWhereInput>;
  disconnect?: InputMaybe<ComponentWhereInput>;
  update?: InputMaybe<ComponentUpdateToOneWithWhereWithoutContentsInput>;
  upsert?: InputMaybe<ComponentUpsertWithoutContentsInput>;
};

export type ComponentUpdateOneWithoutContentsNestedInput2 = {
  connect?: InputMaybe<ComponentWhereUniqueInput2>;
};

export type ComponentUpdateOneWithoutExpensesNestedInput = {
  connect?: InputMaybe<ComponentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ComponentCreateOrConnectWithoutExpensesInput>;
  create?: InputMaybe<ComponentCreateWithoutExpensesInput>;
  delete?: InputMaybe<ComponentWhereInput>;
  disconnect?: InputMaybe<ComponentWhereInput>;
  update?: InputMaybe<ComponentUpdateToOneWithWhereWithoutExpensesInput>;
  upsert?: InputMaybe<ComponentUpsertWithoutExpensesInput>;
};

export type ComponentUpdateOneWithoutNotificationsNestedInput = {
  connect?: InputMaybe<ComponentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ComponentCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<ComponentCreateWithoutNotificationsInput>;
  delete?: InputMaybe<ComponentWhereInput>;
  disconnect?: InputMaybe<ComponentWhereInput>;
  update?: InputMaybe<ComponentUpdateToOneWithWhereWithoutNotificationsInput>;
  upsert?: InputMaybe<ComponentUpsertWithoutNotificationsInput>;
};

export type ComponentUpdateToOneWithWhereWithoutActionCardsInput = {
  data: ComponentUpdateWithoutActionCardsInput;
  where?: InputMaybe<ComponentWhereInput>;
};

export type ComponentUpdateToOneWithWhereWithoutBeneficiariesInput = {
  data: ComponentUpdateWithoutBeneficiariesInput;
  where?: InputMaybe<ComponentWhereInput>;
};

export type ComponentUpdateToOneWithWhereWithoutBeneficiaryDistributionsInput =
  {
    data: ComponentUpdateWithoutBeneficiaryDistributionsInput;
    where?: InputMaybe<ComponentWhereInput>;
  };

export type ComponentUpdateToOneWithWhereWithoutContentsInput = {
  data: ComponentUpdateWithoutContentsInput;
  where?: InputMaybe<ComponentWhereInput>;
};

export type ComponentUpdateToOneWithWhereWithoutExpensesInput = {
  data: ComponentUpdateWithoutExpensesInput;
  where?: InputMaybe<ComponentWhereInput>;
};

export type ComponentUpdateToOneWithWhereWithoutLinkedAccountsInput = {
  data: ComponentUpdateWithoutLinkedAccountsInput;
  where?: InputMaybe<ComponentWhereInput>;
};

export type ComponentUpdateToOneWithWhereWithoutNotificationsInput = {
  data: ComponentUpdateWithoutNotificationsInput;
  where?: InputMaybe<ComponentWhereInput>;
};

export type ComponentUpdateWithWhereUniqueWithoutCreatedByUserInput = {
  data: ComponentUpdateWithoutCreatedByUserInput;
  where: ComponentWhereUniqueInput;
};

export type ComponentUpdateWithWhereUniqueWithoutEstateInput = {
  data: ComponentUpdateWithoutEstateInput;
  where: ComponentWhereUniqueInput;
};

export type ComponentUpdateWithWhereUniqueWithoutEstateInput2 = {
  data: ComponentUpdateWithoutEstateInput2;
  where: ComponentWhereUniqueInput2;
};

export type ComponentUpdateWithoutActionCardsInput = {
  accountHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appraisedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  balance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutComponentNestedInput>;
  beneficiariesText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaryDesignated?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionUpdateManyWithoutComponentNestedInput>;
  billingPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  charge?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutComponentNestedInput>;
  costBasis?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutComponentsNestedInput>;
  descriptor?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutComponentsNestedInput>;
  estimatedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutComponentNestedInput>;
  finalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lastCheckDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastPaymentAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  lastPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  linkedAccounts?: InputMaybe<LinkedAccountUpdateManyWithoutComponentNestedInput>;
  loanAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  mortgageBank?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  namedBeneficiaries?: InputMaybe<ComponentUpdatenamedBeneficiariesInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutComponentNestedInput>;
  paidOff?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registrationExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  seenByCustomer?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumComponentStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<EnumComponentSubTypeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumComponentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ComponentUpdateWithoutBeneficiariesInput = {
  accountHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutComponentNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appraisedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  balance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  beneficiariesText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaryDesignated?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionUpdateManyWithoutComponentNestedInput>;
  billingPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  charge?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutComponentNestedInput>;
  costBasis?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutComponentsNestedInput>;
  descriptor?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutComponentsNestedInput>;
  estimatedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutComponentNestedInput>;
  finalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lastCheckDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastPaymentAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  lastPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  linkedAccounts?: InputMaybe<LinkedAccountUpdateManyWithoutComponentNestedInput>;
  loanAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  mortgageBank?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  namedBeneficiaries?: InputMaybe<ComponentUpdatenamedBeneficiariesInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutComponentNestedInput>;
  paidOff?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registrationExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  seenByCustomer?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumComponentStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<EnumComponentSubTypeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumComponentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ComponentUpdateWithoutBeneficiaryDistributionsInput = {
  accountHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutComponentNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appraisedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  balance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutComponentNestedInput>;
  beneficiariesText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaryDesignated?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  billingPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  charge?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutComponentNestedInput>;
  costBasis?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutComponentsNestedInput>;
  descriptor?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutComponentsNestedInput>;
  estimatedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutComponentNestedInput>;
  finalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lastCheckDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastPaymentAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  lastPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  linkedAccounts?: InputMaybe<LinkedAccountUpdateManyWithoutComponentNestedInput>;
  loanAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  mortgageBank?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  namedBeneficiaries?: InputMaybe<ComponentUpdatenamedBeneficiariesInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutComponentNestedInput>;
  paidOff?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registrationExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  seenByCustomer?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumComponentStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<EnumComponentSubTypeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumComponentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ComponentUpdateWithoutContentsInput = {
  accountHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutComponentNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appraisedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  balance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutComponentNestedInput>;
  beneficiariesText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaryDesignated?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionUpdateManyWithoutComponentNestedInput>;
  billingPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  charge?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  costBasis?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutComponentsNestedInput>;
  descriptor?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutComponentsNestedInput>;
  estimatedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutComponentNestedInput>;
  finalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lastCheckDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastPaymentAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  lastPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  linkedAccounts?: InputMaybe<LinkedAccountUpdateManyWithoutComponentNestedInput>;
  loanAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  mortgageBank?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  namedBeneficiaries?: InputMaybe<ComponentUpdatenamedBeneficiariesInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutComponentNestedInput>;
  paidOff?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registrationExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  seenByCustomer?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumComponentStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<EnumComponentSubTypeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumComponentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ComponentUpdateWithoutCreatedByUserInput = {
  accountHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutComponentNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appraisedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  balance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutComponentNestedInput>;
  beneficiariesText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaryDesignated?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionUpdateManyWithoutComponentNestedInput>;
  billingPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  charge?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutComponentNestedInput>;
  costBasis?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptor?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutComponentsNestedInput>;
  estimatedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutComponentNestedInput>;
  finalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lastCheckDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastPaymentAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  lastPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  linkAccessToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkedAccounts?: InputMaybe<LinkedAccountUpdateManyWithoutComponentNestedInput>;
  loanAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  mortgageBank?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  namedBeneficiaries?: InputMaybe<ComponentUpdatenamedBeneficiariesInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutComponentNestedInput>;
  paidOff?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registrationExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  seenByCustomer?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumComponentStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<EnumComponentSubTypeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumComponentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ComponentUpdateWithoutEstateInput = {
  accountHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutComponentNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appraisedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  balance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutComponentNestedInput>;
  beneficiariesText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaryDesignated?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionUpdateManyWithoutComponentNestedInput>;
  billingPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  charge?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutComponentNestedInput>;
  costBasis?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutComponentsNestedInput>;
  descriptor?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutComponentNestedInput>;
  finalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lastCheckDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastPaymentAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  lastPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  linkedAccounts?: InputMaybe<LinkedAccountUpdateManyWithoutComponentNestedInput>;
  loanAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  mortgageBank?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  namedBeneficiaries?: InputMaybe<ComponentUpdatenamedBeneficiariesInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutComponentNestedInput>;
  paidOff?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registrationExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  seenByCustomer?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumComponentStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<EnumComponentSubTypeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumComponentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ComponentUpdateWithoutEstateInput2 = {
  accountHolder?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  appraisedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  balance?: InputMaybe<Scalars["Decimal"]["input"]>;
  beneficiariesText?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaryDesignated?: InputMaybe<Scalars["Boolean"]["input"]>;
  billingPeriod?: InputMaybe<Scalars["String"]["input"]>;
  charge?: InputMaybe<Scalars["Decimal"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  contactType?: InputMaybe<Scalars["String"]["input"]>;
  costBasis?: InputMaybe<Scalars["Decimal"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  descriptor?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastCheckDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lastPaymentAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  lastPaymentDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  loanAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
  mortgageBank?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  namedBeneficiaries?: InputMaybe<Array<Scalars["String"]["input"]>>;
  paidOff?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationExpiresAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ComponentStatus>;
  subType?: InputMaybe<ComponentSubType>;
  type?: InputMaybe<ComponentType>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComponentUpdateWithoutExpensesInput = {
  accountHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutComponentNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appraisedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  balance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutComponentNestedInput>;
  beneficiariesText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaryDesignated?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionUpdateManyWithoutComponentNestedInput>;
  billingPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  charge?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutComponentNestedInput>;
  costBasis?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutComponentsNestedInput>;
  descriptor?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutComponentsNestedInput>;
  estimatedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  finalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lastCheckDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastPaymentAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  lastPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  linkedAccounts?: InputMaybe<LinkedAccountUpdateManyWithoutComponentNestedInput>;
  loanAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  mortgageBank?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  namedBeneficiaries?: InputMaybe<ComponentUpdatenamedBeneficiariesInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutComponentNestedInput>;
  paidOff?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registrationExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  seenByCustomer?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumComponentStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<EnumComponentSubTypeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumComponentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ComponentUpdateWithoutLinkedAccountsInput = {
  accountHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutComponentNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appraisedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  balance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutComponentNestedInput>;
  beneficiariesText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaryDesignated?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionUpdateManyWithoutComponentNestedInput>;
  billingPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  charge?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutComponentNestedInput>;
  costBasis?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutComponentsNestedInput>;
  descriptor?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutComponentsNestedInput>;
  estimatedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutComponentNestedInput>;
  finalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lastCheckDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastPaymentAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  lastPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  loanAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  mortgageBank?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  namedBeneficiaries?: InputMaybe<ComponentUpdatenamedBeneficiariesInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutComponentNestedInput>;
  paidOff?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registrationExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  seenByCustomer?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumComponentStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<EnumComponentSubTypeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumComponentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ComponentUpdateWithoutNotificationsInput = {
  accountHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutComponentNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appraisedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  balance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutComponentNestedInput>;
  beneficiariesText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaryDesignated?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionUpdateManyWithoutComponentNestedInput>;
  billingPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  charge?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutComponentNestedInput>;
  costBasis?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdByUser?: InputMaybe<UserUpdateOneWithoutComponentsNestedInput>;
  descriptor?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutComponentsNestedInput>;
  estimatedValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutComponentNestedInput>;
  finalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lastCheckDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastPaymentAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  lastPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  linkedAccounts?: InputMaybe<LinkedAccountUpdateManyWithoutComponentNestedInput>;
  loanAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  mortgageBank?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  namedBeneficiaries?: InputMaybe<ComponentUpdatenamedBeneficiariesInput>;
  paidOff?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registrationExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  seenByCustomer?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumComponentStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<EnumComponentSubTypeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumComponentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ComponentUpdatenamedBeneficiariesInput = {
  push?: InputMaybe<Array<Scalars["String"]["input"]>>;
  set?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type ComponentUpsertWithWhereUniqueWithoutCreatedByUserInput = {
  create: ComponentCreateWithoutCreatedByUserInput;
  update: ComponentUpdateWithoutCreatedByUserInput;
  where: ComponentWhereUniqueInput;
};

export type ComponentUpsertWithWhereUniqueWithoutEstateInput = {
  create: ComponentCreateWithoutEstateInput;
  update: ComponentUpdateWithoutEstateInput;
  where: ComponentWhereUniqueInput;
};

export type ComponentUpsertWithoutActionCardsInput = {
  create: ComponentCreateWithoutActionCardsInput;
  update: ComponentUpdateWithoutActionCardsInput;
  where?: InputMaybe<ComponentWhereInput>;
};

export type ComponentUpsertWithoutBeneficiariesInput = {
  create: ComponentCreateWithoutBeneficiariesInput;
  update: ComponentUpdateWithoutBeneficiariesInput;
  where?: InputMaybe<ComponentWhereInput>;
};

export type ComponentUpsertWithoutBeneficiaryDistributionsInput = {
  create: ComponentCreateWithoutBeneficiaryDistributionsInput;
  update: ComponentUpdateWithoutBeneficiaryDistributionsInput;
  where?: InputMaybe<ComponentWhereInput>;
};

export type ComponentUpsertWithoutContentsInput = {
  create: ComponentCreateWithoutContentsInput;
  update: ComponentUpdateWithoutContentsInput;
  where?: InputMaybe<ComponentWhereInput>;
};

export type ComponentUpsertWithoutExpensesInput = {
  create: ComponentCreateWithoutExpensesInput;
  update: ComponentUpdateWithoutExpensesInput;
  where?: InputMaybe<ComponentWhereInput>;
};

export type ComponentUpsertWithoutLinkedAccountsInput = {
  create: ComponentCreateWithoutLinkedAccountsInput;
  update: ComponentUpdateWithoutLinkedAccountsInput;
  where?: InputMaybe<ComponentWhereInput>;
};

export type ComponentUpsertWithoutNotificationsInput = {
  create: ComponentCreateWithoutNotificationsInput;
  update: ComponentUpdateWithoutNotificationsInput;
  where?: InputMaybe<ComponentWhereInput>;
};

export type ComponentWhereInput = {
  AND?: InputMaybe<Array<ComponentWhereInput>>;
  NOT?: InputMaybe<Array<ComponentWhereInput>>;
  OR?: InputMaybe<Array<ComponentWhereInput>>;
  accountHolder?: InputMaybe<StringNullableFilter>;
  accountNumber?: InputMaybe<StringNullableFilter>;
  actionCards?: InputMaybe<ActionCardListRelationFilter>;
  address?: InputMaybe<StringNullableFilter>;
  appraisedValue?: InputMaybe<DecimalNullableFilter>;
  balance?: InputMaybe<DecimalNullableFilter>;
  beneficiaries?: InputMaybe<BeneficiaryListRelationFilter>;
  beneficiariesText?: InputMaybe<StringNullableFilter>;
  beneficiaryDesignated?: InputMaybe<BoolNullableFilter>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionListRelationFilter>;
  billingPeriod?: InputMaybe<StringNullableFilter>;
  charge?: InputMaybe<DecimalNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  contactName?: InputMaybe<StringNullableFilter>;
  contactType?: InputMaybe<StringNullableFilter>;
  contents?: InputMaybe<ContentListRelationFilter>;
  costBasis?: InputMaybe<DecimalNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<StringNullableFilter>;
  createdByUser?: InputMaybe<UserNullableRelationFilter>;
  descriptor?: InputMaybe<StringFilter>;
  email?: InputMaybe<StringNullableFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  estimatedValue?: InputMaybe<DecimalNullableFilter>;
  expenses?: InputMaybe<ExpenseListRelationFilter>;
  finalValue?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inTrust?: InputMaybe<BoolNullableFilter>;
  lastCheckDate?: InputMaybe<DateTimeNullableFilter>;
  lastPaymentAmount?: InputMaybe<DecimalNullableFilter>;
  lastPaymentDate?: InputMaybe<DateTimeNullableFilter>;
  linkedAccounts?: InputMaybe<LinkedAccountListRelationFilter>;
  loanAmount?: InputMaybe<DecimalNullableFilter>;
  mortgageBank?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  namedBeneficiaries?: InputMaybe<StringNullableListFilter>;
  notifications?: InputMaybe<EstateNotificationListRelationFilter>;
  paidOff?: InputMaybe<BoolNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  registrationExpiresAt?: InputMaybe<DateTimeNullableFilter>;
  seenByCustomer?: InputMaybe<BoolNullableFilter>;
  state?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumComponentStatusFilter>;
  subType?: InputMaybe<EnumComponentSubTypeFilter>;
  type?: InputMaybe<EnumComponentTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type ComponentWhereUniqueInput = {
  AND?: InputMaybe<Array<ComponentWhereInput>>;
  NOT?: InputMaybe<Array<ComponentWhereInput>>;
  OR?: InputMaybe<Array<ComponentWhereInput>>;
  accountHolder?: InputMaybe<StringNullableFilter>;
  accountNumber?: InputMaybe<StringNullableFilter>;
  actionCards?: InputMaybe<ActionCardListRelationFilter>;
  address?: InputMaybe<StringNullableFilter>;
  appraisedValue?: InputMaybe<DecimalNullableFilter>;
  balance?: InputMaybe<DecimalNullableFilter>;
  beneficiaries?: InputMaybe<BeneficiaryListRelationFilter>;
  beneficiariesText?: InputMaybe<StringNullableFilter>;
  beneficiaryDesignated?: InputMaybe<BoolNullableFilter>;
  beneficiaryDistributions?: InputMaybe<BeneficiaryDistributionListRelationFilter>;
  billingPeriod?: InputMaybe<StringNullableFilter>;
  charge?: InputMaybe<DecimalNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  contactName?: InputMaybe<StringNullableFilter>;
  contactType?: InputMaybe<StringNullableFilter>;
  contents?: InputMaybe<ContentListRelationFilter>;
  costBasis?: InputMaybe<DecimalNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<StringNullableFilter>;
  createdByUser?: InputMaybe<UserNullableRelationFilter>;
  descriptor?: InputMaybe<StringFilter>;
  email?: InputMaybe<StringNullableFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  estimatedValue?: InputMaybe<DecimalNullableFilter>;
  expenses?: InputMaybe<ExpenseListRelationFilter>;
  finalValue?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inTrust?: InputMaybe<BoolNullableFilter>;
  lastCheckDate?: InputMaybe<DateTimeNullableFilter>;
  lastPaymentAmount?: InputMaybe<DecimalNullableFilter>;
  lastPaymentDate?: InputMaybe<DateTimeNullableFilter>;
  linkedAccounts?: InputMaybe<LinkedAccountListRelationFilter>;
  loanAmount?: InputMaybe<DecimalNullableFilter>;
  mortgageBank?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  namedBeneficiaries?: InputMaybe<StringNullableListFilter>;
  notifications?: InputMaybe<EstateNotificationListRelationFilter>;
  paidOff?: InputMaybe<BoolNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  registrationExpiresAt?: InputMaybe<DateTimeNullableFilter>;
  seenByCustomer?: InputMaybe<BoolNullableFilter>;
  state?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumComponentStatusFilter>;
  subType?: InputMaybe<EnumComponentSubTypeFilter>;
  type?: InputMaybe<EnumComponentTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type ComponentWhereUniqueInput2 = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type ConfigureAlixFeeAsFlatAmountInput = {
  /** This is the total amount that will be charged to the estate */
  amount: Scalars["Decimal"]["input"];
  estateId: Scalars["String"]["input"];
};

export type ConfigureAlixFeeAsPercentageInput = {
  estateId: Scalars["String"]["input"];
  /** Sets the minimum amount that will be charged to the estate. If not provided, will default to $9000. */
  floor?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export type Contact = {
  __typename?: "Contact";
  ContactDeceased: Array<DeceasedContacts>;
  ContactsOnEstates: Array<ContactsOnEstates>;
  _count?: Maybe<ContactCount>;
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars["String"]["output"]>;
  avatar?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  dateOfBirth?: Maybe<Scalars["DateTimeISO"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  middleName?: Maybe<Scalars["String"]["output"]>;
  partnerId?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  sex?: Maybe<Sex>;
  sfx?: Maybe<Sfx>;
  ssn?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type ContactContactDeceasedArgs = {
  cursor?: InputMaybe<DeceasedContactsWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeceasedContactsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeceasedContactsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeceasedContactsWhereInput>;
};

export type ContactContactsOnEstatesArgs = {
  cursor?: InputMaybe<ContactsOnEstatesWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContactsOnEstatesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContactsOnEstatesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContactsOnEstatesWhereInput>;
};

export type ContactAddressArgs = {
  where?: InputMaybe<AddressWhereInput>;
};

export type ContactUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type Contact2 = {
  __typename?: "Contact2";
  ContactsOnEstates?: Maybe<Array<ContactEstate>>;
  address?: Maybe<Address2>;
  contactRole?: Maybe<ContactEstateRoleType2>;
  dateOfBirth?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  partnerId?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  sex?: Maybe<SexType2>;
  sfx?: Maybe<SfxType2>;
  user?: Maybe<ContactUser>;
};

export type ContactCount = {
  __typename?: "ContactCount";
  ContactDeceased: Scalars["Int"]["output"];
  ContactsOnEstates: Scalars["Int"]["output"];
};

export type ContactCountContactDeceasedArgs = {
  where?: InputMaybe<DeceasedContactsWhereInput>;
};

export type ContactCountContactsOnEstatesArgs = {
  where?: InputMaybe<ContactsOnEstatesWhereInput>;
};

export type ContactCountAggregate = {
  __typename?: "ContactCountAggregate";
  _all: Scalars["Int"]["output"];
  addressId: Scalars["Int"]["output"];
  avatar: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  dateOfBirth: Scalars["Int"]["output"];
  email: Scalars["Int"]["output"];
  firstName: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  lastName: Scalars["Int"]["output"];
  middleName: Scalars["Int"]["output"];
  partnerId: Scalars["Int"]["output"];
  phone: Scalars["Int"]["output"];
  sex: Scalars["Int"]["output"];
  sfx: Scalars["Int"]["output"];
  ssn: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  userId: Scalars["Int"]["output"];
};

export type ContactCountOrderByAggregateInput = {
  addressId?: InputMaybe<SortOrder>;
  avatar?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  middleName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  sex?: InputMaybe<SortOrder>;
  sfx?: InputMaybe<SortOrder>;
  ssn?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ContactCreateInput = {
  ContactDeceased?: InputMaybe<DeceasedContactsCreateNestedManyWithoutContactInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutContactInput>;
  address?: InputMaybe<AddressCreateNestedOneWithoutContactInput>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutContactInput>;
};

export type ContactCreateInput2 = {
  ContactsOnEstates: ContactsOnEstatesInput;
  address?: InputMaybe<AddressInput>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<SexType2>;
  sfx?: InputMaybe<SfxType2>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type ContactCreateManyAddressInput = {
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ContactCreateManyAddressInputEnvelope = {
  data: Array<ContactCreateManyAddressInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ContactCreateManyInput = {
  addressId?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ContactCreateManyUserInput = {
  addressId?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type ContactCreateManyUserInputEnvelope = {
  data: Array<ContactCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ContactCreateNestedManyWithoutAddressInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ContactCreateOrConnectWithoutAddressInput>
  >;
  create?: InputMaybe<Array<ContactCreateWithoutAddressInput>>;
  createMany?: InputMaybe<ContactCreateManyAddressInputEnvelope>;
};

export type ContactCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ContactCreateWithoutUserInput>>;
  createMany?: InputMaybe<ContactCreateManyUserInputEnvelope>;
};

export type ContactCreateNestedOneWithoutContactDeceasedInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutContactDeceasedInput>;
  create?: InputMaybe<ContactCreateWithoutContactDeceasedInput>;
};

export type ContactCreateNestedOneWithoutContactsOnEstatesInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutContactsOnEstatesInput>;
  create?: InputMaybe<ContactCreateWithoutContactsOnEstatesInput>;
};

export type ContactCreateOrConnectWithoutAddressInput = {
  create: ContactCreateWithoutAddressInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateOrConnectWithoutContactDeceasedInput = {
  create: ContactCreateWithoutContactDeceasedInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateOrConnectWithoutContactsOnEstatesInput = {
  create: ContactCreateWithoutContactsOnEstatesInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateOrConnectWithoutUserInput = {
  create: ContactCreateWithoutUserInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateWithoutAddressInput = {
  ContactDeceased?: InputMaybe<DeceasedContactsCreateNestedManyWithoutContactInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutContactInput>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutContactInput>;
};

export type ContactCreateWithoutContactDeceasedInput = {
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutContactInput>;
  address?: InputMaybe<AddressCreateNestedOneWithoutContactInput>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutContactInput>;
};

export type ContactCreateWithoutContactsOnEstatesInput = {
  ContactDeceased?: InputMaybe<DeceasedContactsCreateNestedManyWithoutContactInput>;
  address?: InputMaybe<AddressCreateNestedOneWithoutContactInput>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutContactInput>;
};

export type ContactCreateWithoutUserInput = {
  ContactDeceased?: InputMaybe<DeceasedContactsCreateNestedManyWithoutContactInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutContactInput>;
  address?: InputMaybe<AddressCreateNestedOneWithoutContactInput>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type ContactEstate = {
  __typename?: "ContactEstate";
  estateId?: Maybe<Scalars["String"]["output"]>;
};

export enum ContactEstateRole {
  Beneficiary = "BENEFICIARY",
  Executor = "EXECUTOR",
}

export enum ContactEstateRoleType2 {
  Beneficiary = "BENEFICIARY",
  Executor = "EXECUTOR",
}

export type ContactGroupBy = {
  __typename?: "ContactGroupBy";
  _count?: Maybe<ContactCountAggregate>;
  _max?: Maybe<ContactMaxAggregate>;
  _min?: Maybe<ContactMinAggregate>;
  addressId?: Maybe<Scalars["String"]["output"]>;
  avatar?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  dateOfBirth?: Maybe<Scalars["DateTimeISO"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  middleName?: Maybe<Scalars["String"]["output"]>;
  partnerId?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  sex?: Maybe<Sex>;
  sfx?: Maybe<Sfx>;
  ssn?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type ContactListRelationFilter = {
  every?: InputMaybe<ContactWhereInput>;
  none?: InputMaybe<ContactWhereInput>;
  some?: InputMaybe<ContactWhereInput>;
};

export type ContactMaxAggregate = {
  __typename?: "ContactMaxAggregate";
  addressId?: Maybe<Scalars["String"]["output"]>;
  avatar?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  dateOfBirth?: Maybe<Scalars["DateTimeISO"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  partnerId?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  sex?: Maybe<Sex>;
  sfx?: Maybe<Sfx>;
  ssn?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type ContactMaxOrderByAggregateInput = {
  addressId?: InputMaybe<SortOrder>;
  avatar?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  middleName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  sex?: InputMaybe<SortOrder>;
  sfx?: InputMaybe<SortOrder>;
  ssn?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ContactMinAggregate = {
  __typename?: "ContactMinAggregate";
  addressId?: Maybe<Scalars["String"]["output"]>;
  avatar?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  dateOfBirth?: Maybe<Scalars["DateTimeISO"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  partnerId?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  sex?: Maybe<Sex>;
  sfx?: Maybe<Sfx>;
  ssn?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type ContactMinOrderByAggregateInput = {
  addressId?: InputMaybe<SortOrder>;
  avatar?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  middleName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  sex?: InputMaybe<SortOrder>;
  sfx?: InputMaybe<SortOrder>;
  ssn?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ContactOrderByInput2 = {
  dateOfBirth?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  middleName?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type ContactOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ContactOrderByWithAggregationInput = {
  _count?: InputMaybe<ContactCountOrderByAggregateInput>;
  _max?: InputMaybe<ContactMaxOrderByAggregateInput>;
  _min?: InputMaybe<ContactMinOrderByAggregateInput>;
  addressId?: InputMaybe<SortOrderInput>;
  avatar?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrderInput>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  middleName?: InputMaybe<SortOrderInput>;
  partnerId?: InputMaybe<SortOrderInput>;
  phone?: InputMaybe<SortOrderInput>;
  sex?: InputMaybe<SortOrderInput>;
  sfx?: InputMaybe<SortOrderInput>;
  ssn?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrderInput>;
};

export type ContactOrderByWithRelationInput = {
  ContactDeceased?: InputMaybe<DeceasedContactsOrderByRelationAggregateInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesOrderByRelationAggregateInput>;
  address?: InputMaybe<AddressOrderByWithRelationInput>;
  addressId?: InputMaybe<SortOrderInput>;
  avatar?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrderInput>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  middleName?: InputMaybe<SortOrderInput>;
  partnerId?: InputMaybe<SortOrderInput>;
  phone?: InputMaybe<SortOrderInput>;
  sex?: InputMaybe<SortOrderInput>;
  sfx?: InputMaybe<SortOrderInput>;
  ssn?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
};

export type ContactRelationFilter = {
  is?: InputMaybe<ContactWhereInput>;
  isNot?: InputMaybe<ContactWhereInput>;
};

export enum ContactScalarFieldEnum {
  AddressId = "addressId",
  Avatar = "avatar",
  CreatedAt = "createdAt",
  DateOfBirth = "dateOfBirth",
  Email = "email",
  FirstName = "firstName",
  Id = "id",
  LastName = "lastName",
  MiddleName = "middleName",
  PartnerId = "partnerId",
  Phone = "phone",
  Sex = "sex",
  Sfx = "sfx",
  Ssn = "ssn",
  UpdatedAt = "updatedAt",
  UserId = "userId",
}

export type ContactScalarWhereInput = {
  AND?: InputMaybe<Array<ContactScalarWhereInput>>;
  NOT?: InputMaybe<Array<ContactScalarWhereInput>>;
  OR?: InputMaybe<Array<ContactScalarWhereInput>>;
  addressId?: InputMaybe<StringNullableFilter>;
  avatar?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringFilter>;
  middleName?: InputMaybe<StringNullableFilter>;
  partnerId?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  sex?: InputMaybe<EnumSexNullableFilter>;
  sfx?: InputMaybe<EnumSfxNullableFilter>;
  ssn?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type ContactScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ContactScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ContactScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ContactScalarWhereWithAggregatesInput>>;
  addressId?: InputMaybe<StringNullableWithAggregatesFilter>;
  avatar?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  email?: InputMaybe<StringNullableWithAggregatesFilter>;
  firstName?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  lastName?: InputMaybe<StringWithAggregatesFilter>;
  middleName?: InputMaybe<StringNullableWithAggregatesFilter>;
  partnerId?: InputMaybe<StringNullableWithAggregatesFilter>;
  phone?: InputMaybe<StringNullableWithAggregatesFilter>;
  sex?: InputMaybe<EnumSexNullableWithAggregatesFilter>;
  sfx?: InputMaybe<EnumSfxNullableWithAggregatesFilter>;
  ssn?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type ContactUpdateInput = {
  ContactDeceased?: InputMaybe<DeceasedContactsUpdateManyWithoutContactNestedInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutContactNestedInput>;
  address?: InputMaybe<AddressUpdateOneWithoutContactNestedInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutContactNestedInput>;
};

export type ContactUpdateInput2 = {
  ContactsOnEstates: ContactsOnEstatesInput;
  address?: InputMaybe<AddressInput>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<SexType2>;
  sfx?: InputMaybe<SfxType2>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type ContactUpdateManyMutationInput = {
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ContactUpdateManyWithWhereWithoutAddressInput = {
  data: ContactUpdateManyMutationInput;
  where: ContactScalarWhereInput;
};

export type ContactUpdateManyWithWhereWithoutUserInput = {
  data: ContactUpdateManyMutationInput;
  where: ContactScalarWhereInput;
};

export type ContactUpdateManyWithoutAddressNestedInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ContactCreateOrConnectWithoutAddressInput>
  >;
  create?: InputMaybe<Array<ContactCreateWithoutAddressInput>>;
  createMany?: InputMaybe<ContactCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<ContactWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContactScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactWhereUniqueInput>>;
  update?: InputMaybe<Array<ContactUpdateWithWhereUniqueWithoutAddressInput>>;
  updateMany?: InputMaybe<Array<ContactUpdateManyWithWhereWithoutAddressInput>>;
  upsert?: InputMaybe<Array<ContactUpsertWithWhereUniqueWithoutAddressInput>>;
};

export type ContactUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ContactCreateWithoutUserInput>>;
  createMany?: InputMaybe<ContactCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ContactWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContactScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactWhereUniqueInput>>;
  update?: InputMaybe<Array<ContactUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<ContactUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<ContactUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ContactUpdateOneRequiredWithoutContactDeceasedNestedInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutContactDeceasedInput>;
  create?: InputMaybe<ContactCreateWithoutContactDeceasedInput>;
  update?: InputMaybe<ContactUpdateToOneWithWhereWithoutContactDeceasedInput>;
  upsert?: InputMaybe<ContactUpsertWithoutContactDeceasedInput>;
};

export type ContactUpdateOneRequiredWithoutContactsOnEstatesNestedInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutContactsOnEstatesInput>;
  create?: InputMaybe<ContactCreateWithoutContactsOnEstatesInput>;
  update?: InputMaybe<ContactUpdateToOneWithWhereWithoutContactsOnEstatesInput>;
  upsert?: InputMaybe<ContactUpsertWithoutContactsOnEstatesInput>;
};

export type ContactUpdateToOneWithWhereWithoutContactDeceasedInput = {
  data: ContactUpdateWithoutContactDeceasedInput;
  where?: InputMaybe<ContactWhereInput>;
};

export type ContactUpdateToOneWithWhereWithoutContactsOnEstatesInput = {
  data: ContactUpdateWithoutContactsOnEstatesInput;
  where?: InputMaybe<ContactWhereInput>;
};

export type ContactUpdateWithWhereUniqueWithoutAddressInput = {
  data: ContactUpdateWithoutAddressInput;
  where: ContactWhereUniqueInput;
};

export type ContactUpdateWithWhereUniqueWithoutUserInput = {
  data: ContactUpdateWithoutUserInput;
  where: ContactWhereUniqueInput;
};

export type ContactUpdateWithoutAddressInput = {
  ContactDeceased?: InputMaybe<DeceasedContactsUpdateManyWithoutContactNestedInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutContactNestedInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutContactNestedInput>;
};

export type ContactUpdateWithoutContactDeceasedInput = {
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutContactNestedInput>;
  address?: InputMaybe<AddressUpdateOneWithoutContactNestedInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutContactNestedInput>;
};

export type ContactUpdateWithoutContactsOnEstatesInput = {
  ContactDeceased?: InputMaybe<DeceasedContactsUpdateManyWithoutContactNestedInput>;
  address?: InputMaybe<AddressUpdateOneWithoutContactNestedInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutContactNestedInput>;
};

export type ContactUpdateWithoutUserInput = {
  ContactDeceased?: InputMaybe<DeceasedContactsUpdateManyWithoutContactNestedInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutContactNestedInput>;
  address?: InputMaybe<AddressUpdateOneWithoutContactNestedInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ContactUpsertWithWhereUniqueWithoutAddressInput = {
  create: ContactCreateWithoutAddressInput;
  update: ContactUpdateWithoutAddressInput;
  where: ContactWhereUniqueInput;
};

export type ContactUpsertWithWhereUniqueWithoutUserInput = {
  create: ContactCreateWithoutUserInput;
  update: ContactUpdateWithoutUserInput;
  where: ContactWhereUniqueInput;
};

export type ContactUpsertWithoutContactDeceasedInput = {
  create: ContactCreateWithoutContactDeceasedInput;
  update: ContactUpdateWithoutContactDeceasedInput;
  where?: InputMaybe<ContactWhereInput>;
};

export type ContactUpsertWithoutContactsOnEstatesInput = {
  create: ContactCreateWithoutContactsOnEstatesInput;
  update: ContactUpdateWithoutContactsOnEstatesInput;
  where?: InputMaybe<ContactWhereInput>;
};

export type ContactUser = {
  __typename?: "ContactUser";
  id?: Maybe<Scalars["String"]["output"]>;
};

export type ContactWhereInput = {
  AND?: InputMaybe<Array<ContactWhereInput>>;
  ContactDeceased?: InputMaybe<DeceasedContactsListRelationFilter>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesListRelationFilter>;
  NOT?: InputMaybe<Array<ContactWhereInput>>;
  OR?: InputMaybe<Array<ContactWhereInput>>;
  address?: InputMaybe<AddressNullableRelationFilter>;
  addressId?: InputMaybe<StringNullableFilter>;
  avatar?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringFilter>;
  middleName?: InputMaybe<StringNullableFilter>;
  partnerId?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  sex?: InputMaybe<EnumSexNullableFilter>;
  sfx?: InputMaybe<EnumSfxNullableFilter>;
  ssn?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type ContactWhereInput2 = {
  contactRole?: InputMaybe<ContactEstateRoleType2>;
  estateId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ContactWhereUniqueInput = {
  AND?: InputMaybe<Array<ContactWhereInput>>;
  ContactDeceased?: InputMaybe<DeceasedContactsListRelationFilter>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesListRelationFilter>;
  NOT?: InputMaybe<Array<ContactWhereInput>>;
  OR?: InputMaybe<Array<ContactWhereInput>>;
  address?: InputMaybe<AddressNullableRelationFilter>;
  addressId?: InputMaybe<StringNullableFilter>;
  avatar?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<StringFilter>;
  middleName?: InputMaybe<StringNullableFilter>;
  partnerId?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<EnumSexNullableFilter>;
  sfx?: InputMaybe<EnumSfxNullableFilter>;
  ssn?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type ContactsOnEstates = {
  __typename?: "ContactsOnEstates";
  boxUserRootFolderId?: Maybe<Scalars["String"]["output"]>;
  contact: Contact;
  contactId: Scalars["String"]["output"];
  contactRole?: Maybe<ContactEstateRole>;
  estate: Estate;
  estateId: Scalars["String"]["output"];
};

export type ContactsOnEstatesContactIdEstateIdCompoundUniqueInput = {
  contactId: Scalars["String"]["input"];
  estateId: Scalars["String"]["input"];
};

export type ContactsOnEstatesCountAggregate = {
  __typename?: "ContactsOnEstatesCountAggregate";
  _all: Scalars["Int"]["output"];
  boxUserRootFolderId: Scalars["Int"]["output"];
  contactId: Scalars["Int"]["output"];
  contactRole: Scalars["Int"]["output"];
  estateId: Scalars["Int"]["output"];
};

export type ContactsOnEstatesCountOrderByAggregateInput = {
  boxUserRootFolderId?: InputMaybe<SortOrder>;
  contactId?: InputMaybe<SortOrder>;
  contactRole?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
};

export type ContactsOnEstatesCreateInput = {
  boxUserRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  contact: ContactCreateNestedOneWithoutContactsOnEstatesInput;
  contactRole?: InputMaybe<ContactEstateRole>;
  estate: EstateCreateNestedOneWithoutContactsOnEstatesInput;
};

export type ContactsOnEstatesCreateManyContactInput = {
  boxUserRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  contactRole?: InputMaybe<ContactEstateRole>;
  estateId: Scalars["String"]["input"];
};

export type ContactsOnEstatesCreateManyContactInputEnvelope = {
  data: Array<ContactsOnEstatesCreateManyContactInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ContactsOnEstatesCreateManyEstateInput = {
  boxUserRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  contactId: Scalars["String"]["input"];
  contactRole?: InputMaybe<ContactEstateRole>;
};

export type ContactsOnEstatesCreateManyEstateInputEnvelope = {
  data: Array<ContactsOnEstatesCreateManyEstateInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ContactsOnEstatesCreateManyInput = {
  boxUserRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  contactId: Scalars["String"]["input"];
  contactRole?: InputMaybe<ContactEstateRole>;
  estateId: Scalars["String"]["input"];
};

export type ContactsOnEstatesCreateNestedManyWithoutContactInput = {
  connect?: InputMaybe<Array<ContactsOnEstatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ContactsOnEstatesCreateOrConnectWithoutContactInput>
  >;
  create?: InputMaybe<Array<ContactsOnEstatesCreateWithoutContactInput>>;
  createMany?: InputMaybe<ContactsOnEstatesCreateManyContactInputEnvelope>;
};

export type ContactsOnEstatesCreateNestedManyWithoutEstateInput = {
  connect?: InputMaybe<Array<ContactsOnEstatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ContactsOnEstatesCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<ContactsOnEstatesCreateWithoutEstateInput>>;
  createMany?: InputMaybe<ContactsOnEstatesCreateManyEstateInputEnvelope>;
};

export type ContactsOnEstatesCreateOrConnectWithoutContactInput = {
  create: ContactsOnEstatesCreateWithoutContactInput;
  where: ContactsOnEstatesWhereUniqueInput;
};

export type ContactsOnEstatesCreateOrConnectWithoutEstateInput = {
  create: ContactsOnEstatesCreateWithoutEstateInput;
  where: ContactsOnEstatesWhereUniqueInput;
};

export type ContactsOnEstatesCreateWithoutContactInput = {
  boxUserRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  contactRole?: InputMaybe<ContactEstateRole>;
  estate: EstateCreateNestedOneWithoutContactsOnEstatesInput;
};

export type ContactsOnEstatesCreateWithoutEstateInput = {
  boxUserRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  contact: ContactCreateNestedOneWithoutContactsOnEstatesInput;
  contactRole?: InputMaybe<ContactEstateRole>;
};

export type ContactsOnEstatesGroupBy = {
  __typename?: "ContactsOnEstatesGroupBy";
  _count?: Maybe<ContactsOnEstatesCountAggregate>;
  _max?: Maybe<ContactsOnEstatesMaxAggregate>;
  _min?: Maybe<ContactsOnEstatesMinAggregate>;
  boxUserRootFolderId?: Maybe<Scalars["String"]["output"]>;
  contactId: Scalars["String"]["output"];
  contactRole?: Maybe<ContactEstateRole>;
  estateId: Scalars["String"]["output"];
};

export type ContactsOnEstatesInput = {
  boxUserRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  contactId?: InputMaybe<Scalars["String"]["input"]>;
  contactRole: ContactEstateRoleType2;
  estateId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ContactsOnEstatesListRelationFilter = {
  every?: InputMaybe<ContactsOnEstatesWhereInput>;
  none?: InputMaybe<ContactsOnEstatesWhereInput>;
  some?: InputMaybe<ContactsOnEstatesWhereInput>;
};

export type ContactsOnEstatesMaxAggregate = {
  __typename?: "ContactsOnEstatesMaxAggregate";
  boxUserRootFolderId?: Maybe<Scalars["String"]["output"]>;
  contactId?: Maybe<Scalars["String"]["output"]>;
  contactRole?: Maybe<ContactEstateRole>;
  estateId?: Maybe<Scalars["String"]["output"]>;
};

export type ContactsOnEstatesMaxOrderByAggregateInput = {
  boxUserRootFolderId?: InputMaybe<SortOrder>;
  contactId?: InputMaybe<SortOrder>;
  contactRole?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
};

export type ContactsOnEstatesMinAggregate = {
  __typename?: "ContactsOnEstatesMinAggregate";
  boxUserRootFolderId?: Maybe<Scalars["String"]["output"]>;
  contactId?: Maybe<Scalars["String"]["output"]>;
  contactRole?: Maybe<ContactEstateRole>;
  estateId?: Maybe<Scalars["String"]["output"]>;
};

export type ContactsOnEstatesMinOrderByAggregateInput = {
  boxUserRootFolderId?: InputMaybe<SortOrder>;
  contactId?: InputMaybe<SortOrder>;
  contactRole?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
};

export type ContactsOnEstatesOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ContactsOnEstatesOrderByWithAggregationInput = {
  _count?: InputMaybe<ContactsOnEstatesCountOrderByAggregateInput>;
  _max?: InputMaybe<ContactsOnEstatesMaxOrderByAggregateInput>;
  _min?: InputMaybe<ContactsOnEstatesMinOrderByAggregateInput>;
  boxUserRootFolderId?: InputMaybe<SortOrderInput>;
  contactId?: InputMaybe<SortOrder>;
  contactRole?: InputMaybe<SortOrderInput>;
  estateId?: InputMaybe<SortOrder>;
};

export type ContactsOnEstatesOrderByWithRelationInput = {
  boxUserRootFolderId?: InputMaybe<SortOrderInput>;
  contact?: InputMaybe<ContactOrderByWithRelationInput>;
  contactId?: InputMaybe<SortOrder>;
  contactRole?: InputMaybe<SortOrderInput>;
  estate?: InputMaybe<EstateOrderByWithRelationInput>;
  estateId?: InputMaybe<SortOrder>;
};

export enum ContactsOnEstatesScalarFieldEnum {
  BoxUserRootFolderId = "boxUserRootFolderId",
  ContactId = "contactId",
  ContactRole = "contactRole",
  EstateId = "estateId",
}

export type ContactsOnEstatesScalarWhereInput = {
  AND?: InputMaybe<Array<ContactsOnEstatesScalarWhereInput>>;
  NOT?: InputMaybe<Array<ContactsOnEstatesScalarWhereInput>>;
  OR?: InputMaybe<Array<ContactsOnEstatesScalarWhereInput>>;
  boxUserRootFolderId?: InputMaybe<StringNullableFilter>;
  contactId?: InputMaybe<StringFilter>;
  contactRole?: InputMaybe<EnumContactEstateRoleNullableFilter>;
  estateId?: InputMaybe<StringFilter>;
};

export type ContactsOnEstatesScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ContactsOnEstatesScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ContactsOnEstatesScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ContactsOnEstatesScalarWhereWithAggregatesInput>>;
  boxUserRootFolderId?: InputMaybe<StringNullableWithAggregatesFilter>;
  contactId?: InputMaybe<StringWithAggregatesFilter>;
  contactRole?: InputMaybe<EnumContactEstateRoleNullableWithAggregatesFilter>;
  estateId?: InputMaybe<StringWithAggregatesFilter>;
};

export type ContactsOnEstatesUpdateInput = {
  boxUserRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact?: InputMaybe<ContactUpdateOneRequiredWithoutContactsOnEstatesNestedInput>;
  contactRole?: InputMaybe<NullableEnumContactEstateRoleFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutContactsOnEstatesNestedInput>;
};

export type ContactsOnEstatesUpdateManyMutationInput = {
  boxUserRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactRole?: InputMaybe<NullableEnumContactEstateRoleFieldUpdateOperationsInput>;
};

export type ContactsOnEstatesUpdateManyWithWhereWithoutContactInput = {
  data: ContactsOnEstatesUpdateManyMutationInput;
  where: ContactsOnEstatesScalarWhereInput;
};

export type ContactsOnEstatesUpdateManyWithWhereWithoutEstateInput = {
  data: ContactsOnEstatesUpdateManyMutationInput;
  where: ContactsOnEstatesScalarWhereInput;
};

export type ContactsOnEstatesUpdateManyWithoutContactNestedInput = {
  connect?: InputMaybe<Array<ContactsOnEstatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ContactsOnEstatesCreateOrConnectWithoutContactInput>
  >;
  create?: InputMaybe<Array<ContactsOnEstatesCreateWithoutContactInput>>;
  createMany?: InputMaybe<ContactsOnEstatesCreateManyContactInputEnvelope>;
  delete?: InputMaybe<Array<ContactsOnEstatesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContactsOnEstatesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContactsOnEstatesWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactsOnEstatesWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ContactsOnEstatesUpdateWithWhereUniqueWithoutContactInput>
  >;
  updateMany?: InputMaybe<
    Array<ContactsOnEstatesUpdateManyWithWhereWithoutContactInput>
  >;
  upsert?: InputMaybe<
    Array<ContactsOnEstatesUpsertWithWhereUniqueWithoutContactInput>
  >;
};

export type ContactsOnEstatesUpdateManyWithoutEstateNestedInput = {
  connect?: InputMaybe<Array<ContactsOnEstatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ContactsOnEstatesCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<ContactsOnEstatesCreateWithoutEstateInput>>;
  createMany?: InputMaybe<ContactsOnEstatesCreateManyEstateInputEnvelope>;
  delete?: InputMaybe<Array<ContactsOnEstatesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContactsOnEstatesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContactsOnEstatesWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactsOnEstatesWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ContactsOnEstatesUpdateWithWhereUniqueWithoutEstateInput>
  >;
  updateMany?: InputMaybe<
    Array<ContactsOnEstatesUpdateManyWithWhereWithoutEstateInput>
  >;
  upsert?: InputMaybe<
    Array<ContactsOnEstatesUpsertWithWhereUniqueWithoutEstateInput>
  >;
};

export type ContactsOnEstatesUpdateWithWhereUniqueWithoutContactInput = {
  data: ContactsOnEstatesUpdateWithoutContactInput;
  where: ContactsOnEstatesWhereUniqueInput;
};

export type ContactsOnEstatesUpdateWithWhereUniqueWithoutEstateInput = {
  data: ContactsOnEstatesUpdateWithoutEstateInput;
  where: ContactsOnEstatesWhereUniqueInput;
};

export type ContactsOnEstatesUpdateWithoutContactInput = {
  boxUserRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactRole?: InputMaybe<NullableEnumContactEstateRoleFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutContactsOnEstatesNestedInput>;
};

export type ContactsOnEstatesUpdateWithoutEstateInput = {
  boxUserRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact?: InputMaybe<ContactUpdateOneRequiredWithoutContactsOnEstatesNestedInput>;
  contactRole?: InputMaybe<NullableEnumContactEstateRoleFieldUpdateOperationsInput>;
};

export type ContactsOnEstatesUpsertWithWhereUniqueWithoutContactInput = {
  create: ContactsOnEstatesCreateWithoutContactInput;
  update: ContactsOnEstatesUpdateWithoutContactInput;
  where: ContactsOnEstatesWhereUniqueInput;
};

export type ContactsOnEstatesUpsertWithWhereUniqueWithoutEstateInput = {
  create: ContactsOnEstatesCreateWithoutEstateInput;
  update: ContactsOnEstatesUpdateWithoutEstateInput;
  where: ContactsOnEstatesWhereUniqueInput;
};

export type ContactsOnEstatesWhereInput = {
  AND?: InputMaybe<Array<ContactsOnEstatesWhereInput>>;
  NOT?: InputMaybe<Array<ContactsOnEstatesWhereInput>>;
  OR?: InputMaybe<Array<ContactsOnEstatesWhereInput>>;
  boxUserRootFolderId?: InputMaybe<StringNullableFilter>;
  contact?: InputMaybe<ContactRelationFilter>;
  contactId?: InputMaybe<StringFilter>;
  contactRole?: InputMaybe<EnumContactEstateRoleNullableFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
};

export type ContactsOnEstatesWhereUniqueInput = {
  AND?: InputMaybe<Array<ContactsOnEstatesWhereInput>>;
  NOT?: InputMaybe<Array<ContactsOnEstatesWhereInput>>;
  OR?: InputMaybe<Array<ContactsOnEstatesWhereInput>>;
  boxUserRootFolderId?: InputMaybe<StringNullableFilter>;
  contact?: InputMaybe<ContactRelationFilter>;
  contactId?: InputMaybe<StringFilter>;
  contactId_estateId?: InputMaybe<ContactsOnEstatesContactIdEstateIdCompoundUniqueInput>;
  contactRole?: InputMaybe<EnumContactEstateRoleNullableFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
};

export type Content = {
  __typename?: "Content";
  _count?: Maybe<ContentCount>;
  alixGotIt: Scalars["Boolean"]["output"];
  body: Scalars["String"]["output"];
  completeBy: Scalars["DateTimeISO"]["output"];
  completeByType: ContentCompleteByType;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completedBy?: Maybe<Scalars["String"]["output"]>;
  component?: Maybe<Component>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  header: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  notifications: Array<EstateNotification>;
  order: Scalars["Int"]["output"];
  status: ContentStatus;
  type?: Maybe<ContentType>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type ContentComponentArgs = {
  where?: InputMaybe<ComponentWhereInput>;
};

export type ContentNotificationsArgs = {
  cursor?: InputMaybe<EstateNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<EstateNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EstateNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateNotificationWhereInput>;
};

export type ContentUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type Content2 = {
  __typename?: "Content2";
  alixGotIt: Scalars["Boolean"]["output"];
  body: Scalars["String"]["output"];
  completeBy: Scalars["DateTimeISO"]["output"];
  completeByType: ContentCompleteByType;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completedBy?: Maybe<Scalars["String"]["output"]>;
  component?: Maybe<Component2>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  displayDate: Scalars["String"]["output"];
  header: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  notifications: Array<EstateNotification2>;
  order: Scalars["Int"]["output"];
  status: ContentStatus;
  type?: Maybe<ContentType>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  user?: Maybe<User2>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type ContentAvgAggregate = {
  __typename?: "ContentAvgAggregate";
  order?: Maybe<Scalars["Float"]["output"]>;
};

export type ContentAvgOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export enum ContentCompleteByType {
  Exact = "EXACT",
  Fuzzy = "FUZZY",
  Month = "MONTH",
}

export type ContentCount = {
  __typename?: "ContentCount";
  notifications: Scalars["Int"]["output"];
};

export type ContentCountNotificationsArgs = {
  where?: InputMaybe<EstateNotificationWhereInput>;
};

export type ContentCountAggregate = {
  __typename?: "ContentCountAggregate";
  _all: Scalars["Int"]["output"];
  alixGotIt: Scalars["Int"]["output"];
  body: Scalars["Int"]["output"];
  completeBy: Scalars["Int"]["output"];
  completeByType: Scalars["Int"]["output"];
  completedAt: Scalars["Int"]["output"];
  completedBy: Scalars["Int"]["output"];
  componentId: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  header: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  order: Scalars["Int"]["output"];
  status: Scalars["Int"]["output"];
  type: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  userId: Scalars["Int"]["output"];
};

export type ContentCountOrderByAggregateInput = {
  alixGotIt?: InputMaybe<SortOrder>;
  body?: InputMaybe<SortOrder>;
  completeBy?: InputMaybe<SortOrder>;
  completeByType?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrder>;
  completedBy?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  header?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ContentCreateInput = {
  alixGotIt?: InputMaybe<Scalars["Boolean"]["input"]>;
  body: Scalars["String"]["input"];
  completeBy: Scalars["DateTimeISO"]["input"];
  completeByType?: InputMaybe<ContentCompleteByType>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutContentsInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  header: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutContentInput>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<ContentStatus>;
  type?: InputMaybe<ContentType>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutContentsInput>;
};

export type ContentCreateInput2 = {
  body: Scalars["String"]["input"];
  completeBy: Scalars["DateTimeISO"]["input"];
  completeByType?: InputMaybe<ContentCompleteByType>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutContentsInput2>;
  header: Scalars["String"]["input"];
  status?: InputMaybe<ContentStatus>;
  type?: InputMaybe<ContentType>;
  user?: InputMaybe<UserCreateNestedOneWithoutContentsInput2>;
};

export type ContentCreateManyComponentInput = {
  alixGotIt?: InputMaybe<Scalars["Boolean"]["input"]>;
  body: Scalars["String"]["input"];
  completeBy: Scalars["DateTimeISO"]["input"];
  completeByType?: InputMaybe<ContentCompleteByType>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  header: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<ContentStatus>;
  type?: InputMaybe<ContentType>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ContentCreateManyComponentInputEnvelope = {
  data: Array<ContentCreateManyComponentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ContentCreateManyInput = {
  alixGotIt?: InputMaybe<Scalars["Boolean"]["input"]>;
  body: Scalars["String"]["input"];
  completeBy: Scalars["DateTimeISO"]["input"];
  completeByType?: InputMaybe<ContentCompleteByType>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  header: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<ContentStatus>;
  type?: InputMaybe<ContentType>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ContentCreateManyUserInput = {
  alixGotIt?: InputMaybe<Scalars["Boolean"]["input"]>;
  body: Scalars["String"]["input"];
  completeBy: Scalars["DateTimeISO"]["input"];
  completeByType?: InputMaybe<ContentCompleteByType>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  header: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<ContentStatus>;
  type?: InputMaybe<ContentType>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type ContentCreateManyUserInputEnvelope = {
  data: Array<ContentCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ContentCreateNestedManyWithoutComponentInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ContentCreateOrConnectWithoutComponentInput>
  >;
  create?: InputMaybe<Array<ContentCreateWithoutComponentInput>>;
  createMany?: InputMaybe<ContentCreateManyComponentInputEnvelope>;
};

export type ContentCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ContentCreateWithoutUserInput>>;
  createMany?: InputMaybe<ContentCreateManyUserInputEnvelope>;
};

export type ContentCreateNestedOneWithoutNotificationsInput = {
  connect?: InputMaybe<ContentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContentCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<ContentCreateWithoutNotificationsInput>;
};

export type ContentCreateOrConnectWithoutComponentInput = {
  create: ContentCreateWithoutComponentInput;
  where: ContentWhereUniqueInput;
};

export type ContentCreateOrConnectWithoutNotificationsInput = {
  create: ContentCreateWithoutNotificationsInput;
  where: ContentWhereUniqueInput;
};

export type ContentCreateOrConnectWithoutUserInput = {
  create: ContentCreateWithoutUserInput;
  where: ContentWhereUniqueInput;
};

export type ContentCreateWithoutComponentInput = {
  alixGotIt?: InputMaybe<Scalars["Boolean"]["input"]>;
  body: Scalars["String"]["input"];
  completeBy: Scalars["DateTimeISO"]["input"];
  completeByType?: InputMaybe<ContentCompleteByType>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  header: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutContentInput>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<ContentStatus>;
  type?: InputMaybe<ContentType>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutContentsInput>;
};

export type ContentCreateWithoutNotificationsInput = {
  alixGotIt?: InputMaybe<Scalars["Boolean"]["input"]>;
  body: Scalars["String"]["input"];
  completeBy: Scalars["DateTimeISO"]["input"];
  completeByType?: InputMaybe<ContentCompleteByType>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutContentsInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  header: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<ContentStatus>;
  type?: InputMaybe<ContentType>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutContentsInput>;
};

export type ContentCreateWithoutUserInput = {
  alixGotIt?: InputMaybe<Scalars["Boolean"]["input"]>;
  body: Scalars["String"]["input"];
  completeBy: Scalars["DateTimeISO"]["input"];
  completeByType?: InputMaybe<ContentCompleteByType>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutContentsInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  header: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutContentInput>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<ContentStatus>;
  type?: InputMaybe<ContentType>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type ContentGroupBy = {
  __typename?: "ContentGroupBy";
  _avg?: Maybe<ContentAvgAggregate>;
  _count?: Maybe<ContentCountAggregate>;
  _max?: Maybe<ContentMaxAggregate>;
  _min?: Maybe<ContentMinAggregate>;
  _sum?: Maybe<ContentSumAggregate>;
  alixGotIt: Scalars["Boolean"]["output"];
  body: Scalars["String"]["output"];
  completeBy: Scalars["DateTimeISO"]["output"];
  completeByType: ContentCompleteByType;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completedBy?: Maybe<Scalars["String"]["output"]>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  header: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  status: ContentStatus;
  type?: Maybe<ContentType>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type ContentGroupedByEstateItem = {
  __typename?: "ContentGroupedByEstateItem";
  contents: Array<Content2>;
  estateId: Scalars["String"]["output"];
};

export type ContentListRelationFilter = {
  every?: InputMaybe<ContentWhereInput>;
  none?: InputMaybe<ContentWhereInput>;
  some?: InputMaybe<ContentWhereInput>;
};

export type ContentMaxAggregate = {
  __typename?: "ContentMaxAggregate";
  alixGotIt?: Maybe<Scalars["Boolean"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  completeBy?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completeByType?: Maybe<ContentCompleteByType>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completedBy?: Maybe<Scalars["String"]["output"]>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  header?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  order?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<ContentStatus>;
  type?: Maybe<ContentType>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type ContentMaxOrderByAggregateInput = {
  alixGotIt?: InputMaybe<SortOrder>;
  body?: InputMaybe<SortOrder>;
  completeBy?: InputMaybe<SortOrder>;
  completeByType?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrder>;
  completedBy?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  header?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ContentMinAggregate = {
  __typename?: "ContentMinAggregate";
  alixGotIt?: Maybe<Scalars["Boolean"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  completeBy?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completeByType?: Maybe<ContentCompleteByType>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completedBy?: Maybe<Scalars["String"]["output"]>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  header?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  order?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<ContentStatus>;
  type?: Maybe<ContentType>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type ContentMinOrderByAggregateInput = {
  alixGotIt?: InputMaybe<SortOrder>;
  body?: InputMaybe<SortOrder>;
  completeBy?: InputMaybe<SortOrder>;
  completeByType?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrder>;
  completedBy?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  header?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ContentNullableRelationFilter = {
  is?: InputMaybe<ContentWhereInput>;
  isNot?: InputMaybe<ContentWhereInput>;
};

export type ContentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ContentOrderByWithAggregationInput = {
  _avg?: InputMaybe<ContentAvgOrderByAggregateInput>;
  _count?: InputMaybe<ContentCountOrderByAggregateInput>;
  _max?: InputMaybe<ContentMaxOrderByAggregateInput>;
  _min?: InputMaybe<ContentMinOrderByAggregateInput>;
  _sum?: InputMaybe<ContentSumOrderByAggregateInput>;
  alixGotIt?: InputMaybe<SortOrder>;
  body?: InputMaybe<SortOrder>;
  completeBy?: InputMaybe<SortOrder>;
  completeByType?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrderInput>;
  completedBy?: InputMaybe<SortOrderInput>;
  componentId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  header?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrderInput>;
};

export type ContentOrderByWithRelationInput = {
  alixGotIt?: InputMaybe<SortOrder>;
  body?: InputMaybe<SortOrder>;
  completeBy?: InputMaybe<SortOrder>;
  completeByType?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrderInput>;
  completedBy?: InputMaybe<SortOrderInput>;
  component?: InputMaybe<ComponentOrderByWithRelationInput>;
  componentId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  header?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  notifications?: InputMaybe<EstateNotificationOrderByRelationAggregateInput>;
  order?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
};

export type ContentOrderByWithRelationInput2 = {
  completeBy?: InputMaybe<SortOrder>;
  completeByType?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrderInput>;
  completedBy?: InputMaybe<SortOrderInput>;
  componentId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrderInput>;
};

export enum ContentScalarFieldEnum {
  AlixGotIt = "alixGotIt",
  Body = "body",
  CompleteBy = "completeBy",
  CompleteByType = "completeByType",
  CompletedAt = "completedAt",
  CompletedBy = "completedBy",
  ComponentId = "componentId",
  CreatedAt = "createdAt",
  Header = "header",
  Id = "id",
  Order = "order",
  Status = "status",
  Type = "type",
  UpdatedAt = "updatedAt",
  UserId = "userId",
}

export type ContentScalarWhereInput = {
  AND?: InputMaybe<Array<ContentScalarWhereInput>>;
  NOT?: InputMaybe<Array<ContentScalarWhereInput>>;
  OR?: InputMaybe<Array<ContentScalarWhereInput>>;
  alixGotIt?: InputMaybe<BoolFilter>;
  body?: InputMaybe<StringFilter>;
  completeBy?: InputMaybe<DateTimeFilter>;
  completeByType?: InputMaybe<EnumContentCompleteByTypeFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  completedBy?: InputMaybe<StringNullableFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  header?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumContentStatusFilter>;
  type?: InputMaybe<EnumContentTypeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type ContentScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ContentScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ContentScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ContentScalarWhereWithAggregatesInput>>;
  alixGotIt?: InputMaybe<BoolWithAggregatesFilter>;
  body?: InputMaybe<StringWithAggregatesFilter>;
  completeBy?: InputMaybe<DateTimeWithAggregatesFilter>;
  completeByType?: InputMaybe<EnumContentCompleteByTypeWithAggregatesFilter>;
  completedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  completedBy?: InputMaybe<StringNullableWithAggregatesFilter>;
  componentId?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  header?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  status?: InputMaybe<EnumContentStatusWithAggregatesFilter>;
  type?: InputMaybe<EnumContentTypeNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export enum ContentStatus {
  Completed = "COMPLETED",
  InProcess = "IN_PROCESS",
}

export type ContentSumAggregate = {
  __typename?: "ContentSumAggregate";
  order?: Maybe<Scalars["Int"]["output"]>;
};

export type ContentSumOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export enum ContentType {
  KeyDate = "KEY_DATE",
  RecentActivity = "RECENT_ACTIVITY",
}

export type ContentUpdateInput = {
  alixGotIt?: InputMaybe<BoolFieldUpdateOperationsInput>;
  body?: InputMaybe<StringFieldUpdateOperationsInput>;
  completeBy?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  completeByType?: InputMaybe<EnumContentCompleteByTypeFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutContentsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  header?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutContentNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumContentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutContentsNestedInput>;
};

export type ContentUpdateInput2 = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  completeBy?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completeByType?: InputMaybe<ContentCompleteByType>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completedBy?: InputMaybe<Scalars["String"]["input"]>;
  component?: InputMaybe<ComponentUpdateOneWithoutContentsNestedInput2>;
  header?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ContentStatus>;
  type?: InputMaybe<ContentType>;
  user?: InputMaybe<UserUpdateOneWithoutContentsNestedInput2>;
};

export type ContentUpdateManyMutationInput = {
  alixGotIt?: InputMaybe<BoolFieldUpdateOperationsInput>;
  body?: InputMaybe<StringFieldUpdateOperationsInput>;
  completeBy?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  completeByType?: InputMaybe<EnumContentCompleteByTypeFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  header?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumContentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ContentUpdateManyWithWhereWithoutComponentInput = {
  data: ContentUpdateManyMutationInput;
  where: ContentScalarWhereInput;
};

export type ContentUpdateManyWithWhereWithoutUserInput = {
  data: ContentUpdateManyMutationInput;
  where: ContentScalarWhereInput;
};

export type ContentUpdateManyWithoutComponentNestedInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ContentCreateOrConnectWithoutComponentInput>
  >;
  create?: InputMaybe<Array<ContentCreateWithoutComponentInput>>;
  createMany?: InputMaybe<ContentCreateManyComponentInputEnvelope>;
  delete?: InputMaybe<Array<ContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  set?: InputMaybe<Array<ContentWhereUniqueInput>>;
  update?: InputMaybe<Array<ContentUpdateWithWhereUniqueWithoutComponentInput>>;
  updateMany?: InputMaybe<
    Array<ContentUpdateManyWithWhereWithoutComponentInput>
  >;
  upsert?: InputMaybe<Array<ContentUpsertWithWhereUniqueWithoutComponentInput>>;
};

export type ContentUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ContentCreateWithoutUserInput>>;
  createMany?: InputMaybe<ContentCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  set?: InputMaybe<Array<ContentWhereUniqueInput>>;
  update?: InputMaybe<Array<ContentUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<ContentUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<ContentUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ContentUpdateOneWithoutNotificationsNestedInput = {
  connect?: InputMaybe<ContentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContentCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<ContentCreateWithoutNotificationsInput>;
  delete?: InputMaybe<ContentWhereInput>;
  disconnect?: InputMaybe<ContentWhereInput>;
  update?: InputMaybe<ContentUpdateToOneWithWhereWithoutNotificationsInput>;
  upsert?: InputMaybe<ContentUpsertWithoutNotificationsInput>;
};

export type ContentUpdateToOneWithWhereWithoutNotificationsInput = {
  data: ContentUpdateWithoutNotificationsInput;
  where?: InputMaybe<ContentWhereInput>;
};

export type ContentUpdateWithWhereUniqueWithoutComponentInput = {
  data: ContentUpdateWithoutComponentInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpdateWithWhereUniqueWithoutUserInput = {
  data: ContentUpdateWithoutUserInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpdateWithoutComponentInput = {
  alixGotIt?: InputMaybe<BoolFieldUpdateOperationsInput>;
  body?: InputMaybe<StringFieldUpdateOperationsInput>;
  completeBy?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  completeByType?: InputMaybe<EnumContentCompleteByTypeFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  header?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutContentNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumContentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutContentsNestedInput>;
};

export type ContentUpdateWithoutNotificationsInput = {
  alixGotIt?: InputMaybe<BoolFieldUpdateOperationsInput>;
  body?: InputMaybe<StringFieldUpdateOperationsInput>;
  completeBy?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  completeByType?: InputMaybe<EnumContentCompleteByTypeFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutContentsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  header?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumContentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutContentsNestedInput>;
};

export type ContentUpdateWithoutUserInput = {
  alixGotIt?: InputMaybe<BoolFieldUpdateOperationsInput>;
  body?: InputMaybe<StringFieldUpdateOperationsInput>;
  completeBy?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  completeByType?: InputMaybe<EnumContentCompleteByTypeFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutContentsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  header?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutContentNestedInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumContentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ContentUpsertWithWhereUniqueWithoutComponentInput = {
  create: ContentCreateWithoutComponentInput;
  update: ContentUpdateWithoutComponentInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpsertWithWhereUniqueWithoutUserInput = {
  create: ContentCreateWithoutUserInput;
  update: ContentUpdateWithoutUserInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpsertWithoutNotificationsInput = {
  create: ContentCreateWithoutNotificationsInput;
  update: ContentUpdateWithoutNotificationsInput;
  where?: InputMaybe<ContentWhereInput>;
};

export type ContentWhereInput = {
  AND?: InputMaybe<Array<ContentWhereInput>>;
  NOT?: InputMaybe<Array<ContentWhereInput>>;
  OR?: InputMaybe<Array<ContentWhereInput>>;
  alixGotIt?: InputMaybe<BoolFilter>;
  body?: InputMaybe<StringFilter>;
  completeBy?: InputMaybe<DateTimeFilter>;
  completeByType?: InputMaybe<EnumContentCompleteByTypeFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  completedBy?: InputMaybe<StringNullableFilter>;
  component?: InputMaybe<ComponentNullableRelationFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  header?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  notifications?: InputMaybe<EstateNotificationListRelationFilter>;
  order?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumContentStatusFilter>;
  type?: InputMaybe<EnumContentTypeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type ContentWhereInput2 = {
  AND?: InputMaybe<Array<ContentWhereInput2>>;
  NOT?: InputMaybe<Array<ContentWhereInput2>>;
  OR?: InputMaybe<Array<ContentWhereInput2>>;
  alixGotIt?: InputMaybe<BoolFilter>;
  body?: InputMaybe<StringFilter>;
  completeBy?: InputMaybe<DateTimeFilter>;
  completeByType?: InputMaybe<EnumContentCompleteByTypeFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  completedBy?: InputMaybe<StringNullableFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  header?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumContentStatusFilter>;
  type?: InputMaybe<EnumContentTypeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type ContentWhereUniqueInput = {
  AND?: InputMaybe<Array<ContentWhereInput>>;
  NOT?: InputMaybe<Array<ContentWhereInput>>;
  OR?: InputMaybe<Array<ContentWhereInput>>;
  alixGotIt?: InputMaybe<BoolFilter>;
  body?: InputMaybe<StringFilter>;
  completeBy?: InputMaybe<DateTimeFilter>;
  completeByType?: InputMaybe<EnumContentCompleteByTypeFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  completedBy?: InputMaybe<StringNullableFilter>;
  component?: InputMaybe<ComponentNullableRelationFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  header?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notifications?: InputMaybe<EstateNotificationListRelationFilter>;
  order?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumContentStatusFilter>;
  type?: InputMaybe<EnumContentTypeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type ContentWhereUniqueInput2 = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type CoreEstateInformationListItem = {
  __typename?: "CoreEstateInformationListItem";
  deceased?: Maybe<Deceased2>;
  estateId: Scalars["String"]["output"];
  executors?: Maybe<Array<User2>>;
};

export type CreateCareTeamNoteInput = {
  estateId: Scalars["String"]["input"];
  message: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

export type CreateExpenseInput = {
  amount: Scalars["Decimal"]["input"];
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  date: Scalars["DateTimeISO"]["input"];
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateFolderInput = {
  estateId: Scalars["String"]["input"];
  folderName: Scalars["String"]["input"];
};

export type CreateManyAndReturnActionCard = {
  __typename?: "CreateManyAndReturnActionCard";
  appointmentLocation?: Maybe<Scalars["String"]["output"]>;
  appointmentTime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  assignee?: Maybe<AssigneeType>;
  calendarIcs?: Maybe<Scalars["String"]["output"]>;
  calendarLink?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completedBy?: Maybe<Scalars["String"]["output"]>;
  component?: Maybe<Component>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  documentDownloadUrl?: Maybe<Scalars["String"]["output"]>;
  documentSignUrl?: Maybe<Scalars["String"]["output"]>;
  documentUploadUrl?: Maybe<Scalars["String"]["output"]>;
  encryptedReply: Scalars["Boolean"]["output"];
  estate: Estate;
  estateDetail?: Maybe<EstateDetail>;
  estateDetailId?: Maybe<Scalars["String"]["output"]>;
  estateId: Scalars["String"]["output"];
  followUpActionCard?: Maybe<Scalars["JSON"]["output"]>;
  formFieldName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  locked: Scalars["Boolean"]["output"];
  mapUrl?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  promoted: Scalars["Boolean"]["output"];
  requiresFollowUp: Scalars["Boolean"]["output"];
  status: ActionCardStatus;
  task?: Maybe<Task>;
  taskId?: Maybe<Scalars["String"]["output"]>;
  taskName?: Maybe<Scalars["String"]["output"]>;
  type: ActionCardType;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type CreateManyAndReturnActionCardComponentArgs = {
  where?: InputMaybe<ComponentWhereInput>;
};

export type CreateManyAndReturnActionCardEstateDetailArgs = {
  where?: InputMaybe<EstateDetailWhereInput>;
};

export type CreateManyAndReturnActionCardTaskArgs = {
  where?: InputMaybe<TaskWhereInput>;
};

export type CreateManyAndReturnAddress = {
  __typename?: "CreateManyAndReturnAddress";
  address?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  state?: Maybe<Scalars["String"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type CreateManyAndReturnAlixFee = {
  __typename?: "CreateManyAndReturnAlixFee";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  estate: Estate;
  estateId: Scalars["String"]["output"];
  floor?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["String"]["output"];
  invoicedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  type: FeeType;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type CreateManyAndReturnAsset = {
  __typename?: "CreateManyAndReturnAsset";
  assetAccountId?: Maybe<Scalars["String"]["output"]>;
  assetCategory: AssetCategory;
  description?: Maybe<Scalars["String"]["output"]>;
  dodValue?: Maybe<Scalars["Decimal"]["output"]>;
  estate: Estate;
  estateId: Scalars["String"]["output"];
  estimatedValue?: Maybe<Scalars["Decimal"]["output"]>;
  finalValue?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["String"]["output"];
  inTrust: Scalars["Boolean"]["output"];
  probateEstate?: Maybe<Scalars["String"]["output"]>;
  probateJurisdiction?: Maybe<Scalars["String"]["output"]>;
  status: AssetStatus;
};

export type CreateManyAndReturnAssetAccount = {
  __typename?: "CreateManyAndReturnAssetAccount";
  accountNumber?: Maybe<Scalars["Decimal"]["output"]>;
  asset: Asset;
  assetId: Scalars["String"]["output"];
  assetSubcategory: AssetSubCategory;
  id: Scalars["String"]["output"];
  institution?: Maybe<Scalars["String"]["output"]>;
};

export type CreateManyAndReturnBeneficiary = {
  __typename?: "CreateManyAndReturnBeneficiary";
  component?: Maybe<Component>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  estate: Estate;
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  relationship?: Maybe<Scalars["String"]["output"]>;
  taxId?: Maybe<Scalars["String"]["output"]>;
  type: BeneficiaryType;
  updatedAt: Scalars["DateTimeISO"]["output"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type CreateManyAndReturnBeneficiaryComponentArgs = {
  where?: InputMaybe<ComponentWhereInput>;
};

export type CreateManyAndReturnBeneficiaryUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type CreateManyAndReturnBeneficiaryDistribution = {
  __typename?: "CreateManyAndReturnBeneficiaryDistribution";
  beneficiary: Beneficiary;
  beneficiaryId: Scalars["String"]["output"];
  component?: Maybe<Component>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  date?: Maybe<Scalars["DateTimeISO"]["output"]>;
  id: Scalars["String"]["output"];
  percentage: Scalars["Decimal"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type CreateManyAndReturnBeneficiaryDistributionComponentArgs = {
  where?: InputMaybe<ComponentWhereInput>;
};

export type CreateManyAndReturnCareTeamNote = {
  __typename?: "CreateManyAndReturnCareTeamNote";
  createdAt: Scalars["DateTimeISO"]["output"];
  encrypted: Scalars["Boolean"]["output"];
  estate: Estate;
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type CreateManyAndReturnComponent = {
  __typename?: "CreateManyAndReturnComponent";
  accountHolder?: Maybe<Scalars["String"]["output"]>;
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  address?: Maybe<Scalars["String"]["output"]>;
  appraisedValue?: Maybe<Scalars["Decimal"]["output"]>;
  balance?: Maybe<Scalars["Decimal"]["output"]>;
  beneficiariesText?: Maybe<Scalars["String"]["output"]>;
  beneficiaryDesignated?: Maybe<Scalars["Boolean"]["output"]>;
  billingPeriod?: Maybe<Scalars["String"]["output"]>;
  charge?: Maybe<Scalars["Decimal"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  contactName?: Maybe<Scalars["String"]["output"]>;
  contactType?: Maybe<Scalars["String"]["output"]>;
  costBasis?: Maybe<Scalars["Decimal"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  createdById?: Maybe<Scalars["String"]["output"]>;
  createdByUser?: Maybe<User>;
  descriptor: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  estate: Estate;
  estateId: Scalars["String"]["output"];
  estimatedValue?: Maybe<Scalars["Decimal"]["output"]>;
  finalValue?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["String"]["output"];
  inTrust?: Maybe<Scalars["Boolean"]["output"]>;
  lastCheckDate?: Maybe<Scalars["DateTimeISO"]["output"]>;
  lastPaymentAmount?: Maybe<Scalars["Decimal"]["output"]>;
  lastPaymentDate?: Maybe<Scalars["DateTimeISO"]["output"]>;
  linkAccessToken?: Maybe<Scalars["String"]["output"]>;
  loanAmount?: Maybe<Scalars["Decimal"]["output"]>;
  mortgageBank?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  namedBeneficiaries?: Maybe<Array<Scalars["String"]["output"]>>;
  paidOff?: Maybe<Scalars["Boolean"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  registrationExpiresAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  seenByCustomer?: Maybe<Scalars["Boolean"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  status: ComponentStatus;
  subType: ComponentSubType;
  type: ComponentType;
  updatedAt: Scalars["DateTimeISO"]["output"];
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type CreateManyAndReturnComponentCreatedByUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type CreateManyAndReturnContact = {
  __typename?: "CreateManyAndReturnContact";
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars["String"]["output"]>;
  avatar?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  dateOfBirth?: Maybe<Scalars["DateTimeISO"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  middleName?: Maybe<Scalars["String"]["output"]>;
  partnerId?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  sex?: Maybe<Sex>;
  sfx?: Maybe<Sfx>;
  ssn?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type CreateManyAndReturnContactAddressArgs = {
  where?: InputMaybe<AddressWhereInput>;
};

export type CreateManyAndReturnContactUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type CreateManyAndReturnContactsOnEstates = {
  __typename?: "CreateManyAndReturnContactsOnEstates";
  boxUserRootFolderId?: Maybe<Scalars["String"]["output"]>;
  contact: Contact;
  contactId: Scalars["String"]["output"];
  contactRole?: Maybe<ContactEstateRole>;
  estate: Estate;
  estateId: Scalars["String"]["output"];
};

export type CreateManyAndReturnContent = {
  __typename?: "CreateManyAndReturnContent";
  alixGotIt: Scalars["Boolean"]["output"];
  body: Scalars["String"]["output"];
  completeBy: Scalars["DateTimeISO"]["output"];
  completeByType: ContentCompleteByType;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completedBy?: Maybe<Scalars["String"]["output"]>;
  component?: Maybe<Component>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  header: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  status: ContentStatus;
  type?: Maybe<ContentType>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type CreateManyAndReturnContentComponentArgs = {
  where?: InputMaybe<ComponentWhereInput>;
};

export type CreateManyAndReturnContentUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type CreateManyAndReturnDeceased = {
  __typename?: "CreateManyAndReturnDeceased";
  address?: Maybe<Scalars["String"]["output"]>;
  citizenship?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  dateOfBirth?: Maybe<Scalars["DateTimeISO"]["output"]>;
  dateOfDeath?: Maybe<Scalars["DateTimeISO"]["output"]>;
  estate: Estate;
  estateId: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  lastKnowAddressId?: Maybe<Scalars["String"]["output"]>;
  lastKnownAddress?: Maybe<Address>;
  lastName: Scalars["String"]["output"];
  maritalStatus?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  nameOfHospital?: Maybe<Scalars["String"]["output"]>;
  placeOfDeathAddress?: Maybe<Address>;
  podAddress?: Maybe<Scalars["String"]["output"]>;
  podAddressId?: Maybe<Scalars["String"]["output"]>;
  podCity?: Maybe<Scalars["String"]["output"]>;
  podCountry?: Maybe<Scalars["String"]["output"]>;
  podCounty?: Maybe<Scalars["String"]["output"]>;
  podState?: Maybe<Scalars["String"]["output"]>;
  podZip?: Maybe<Scalars["String"]["output"]>;
  referringPartner?: Maybe<Scalars["String"]["output"]>;
  sex?: Maybe<Sex>;
  sfx?: Maybe<Sfx>;
  ssn?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  veteranStatus?: Maybe<Scalars["Boolean"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type CreateManyAndReturnDeceasedLastKnownAddressArgs = {
  where?: InputMaybe<AddressWhereInput>;
};

export type CreateManyAndReturnDeceasedPlaceOfDeathAddressArgs = {
  where?: InputMaybe<AddressWhereInput>;
};

export type CreateManyAndReturnDeceasedContacts = {
  __typename?: "CreateManyAndReturnDeceasedContacts";
  contact: Contact;
  contactId: Scalars["String"]["output"];
  deceased: Deceased;
  deceasedContactType?: Maybe<DeceasedContactType>;
  deceasedId: Scalars["String"]["output"];
};

export type CreateManyAndReturnDeviceRegistration = {
  __typename?: "CreateManyAndReturnDeviceRegistration";
  createdAt: Scalars["DateTimeISO"]["output"];
  deviceToken: Scalars["String"]["output"];
  endpoint?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
};

export type CreateManyAndReturnDocumentsProcessed = {
  __typename?: "CreateManyAndReturnDocumentsProcessed";
  batchId: Scalars["Int"]["output"];
  createdAt: Scalars["DateTimeISO"]["output"];
  estate: Estate;
  estateId: Scalars["String"]["output"];
  filename: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  sha1: Scalars["String"]["output"];
  status: ProcessingStatus;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type CreateManyAndReturnEula = {
  __typename?: "CreateManyAndReturnEULA";
  content?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  id: Scalars["String"]["output"];
  pdfLink?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  version: Scalars["String"]["output"];
};

export type CreateManyAndReturnEulaAcceptanceHistory = {
  __typename?: "CreateManyAndReturnEULAAcceptanceHistory";
  EULAacceptedAt: Scalars["DateTimeISO"]["output"];
  EULAacceptedIp: Scalars["String"]["output"];
  acceptedEULAVersion: Scalars["String"]["output"];
  createdAt: Scalars["DateTimeISO"]["output"];
  eula: Eula;
  eulaId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
};

export type CreateManyAndReturnEstate = {
  __typename?: "CreateManyAndReturnEstate";
  boxBeneficiaryFolderId?: Maybe<Scalars["String"]["output"]>;
  boxEstateRootFolderId?: Maybe<Scalars["String"]["output"]>;
  boxExecutorFolderId?: Maybe<Scalars["String"]["output"]>;
  channelPartner?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  currentWorkFLowStep: Scalars["Int"]["output"];
  data?: Maybe<Scalars["JSON"]["output"]>;
  deleted: Scalars["Boolean"]["output"];
  estateReady: Scalars["Boolean"]["output"];
  hasProbate?: Maybe<Scalars["Boolean"]["output"]>;
  hasTrust?: Maybe<Scalars["Boolean"]["output"]>;
  hasWill?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["String"]["output"];
  planReady: Scalars["Boolean"]["output"];
  processInstanceId?: Maybe<Scalars["String"]["output"]>;
  survivingSpouse?: Maybe<Scalars["Boolean"]["output"]>;
  taxId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type CreateManyAndReturnEstateDetail = {
  __typename?: "CreateManyAndReturnEstateDetail";
  createdAt: Scalars["DateTimeISO"]["output"];
  encrypted: Scalars["Boolean"]["output"];
  estate: Estate;
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  value: Scalars["JSON"]["output"];
};

export type CreateManyAndReturnEstateNotification = {
  __typename?: "CreateManyAndReturnEstateNotification";
  actionCard?: Maybe<ActionCard>;
  actionCardId?: Maybe<Scalars["String"]["output"]>;
  component?: Maybe<Component>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  content?: Maybe<Content>;
  contentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  estate?: Maybe<Estate>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  relatedElementType?: Maybe<RelatedElementType>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type CreateManyAndReturnEstateNotificationActionCardArgs = {
  where?: InputMaybe<ActionCardWhereInput>;
};

export type CreateManyAndReturnEstateNotificationComponentArgs = {
  where?: InputMaybe<ComponentWhereInput>;
};

export type CreateManyAndReturnEstateNotificationContentArgs = {
  where?: InputMaybe<ContentWhereInput>;
};

export type CreateManyAndReturnEstateNotificationEstateArgs = {
  where?: InputMaybe<EstateWhereInput>;
};

export type CreateManyAndReturnExpense = {
  __typename?: "CreateManyAndReturnExpense";
  amount: Scalars["Decimal"]["output"];
  component?: Maybe<Component>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  date: Scalars["DateTimeISO"]["output"];
  estate: Estate;
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  paid: Scalars["Boolean"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type CreateManyAndReturnExpenseComponentArgs = {
  where?: InputMaybe<ComponentWhereInput>;
};

export type CreateManyAndReturnExpenseUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type CreateManyAndReturnLinkedAccount = {
  __typename?: "CreateManyAndReturnLinkedAccount";
  accountId: Scalars["String"]["output"];
  availableBalance?: Maybe<Scalars["Decimal"]["output"]>;
  balanceLimit?: Maybe<Scalars["Decimal"]["output"]>;
  component: Component;
  componentId: Scalars["String"]["output"];
  createdAt: Scalars["DateTimeISO"]["output"];
  currentBalance?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["String"]["output"];
  isoCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  lastUpdatedDatetime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  mask?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  officialName?: Maybe<Scalars["String"]["output"]>;
  persistentAccountId?: Maybe<Scalars["String"]["output"]>;
  subtype?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
  unofficialCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  verificationStatus?: Maybe<Scalars["String"]["output"]>;
};

export type CreateManyAndReturnMilestone = {
  __typename?: "CreateManyAndReturnMilestone";
  createdAt: Scalars["DateTimeISO"]["output"];
  date: Scalars["DateTimeISO"]["output"];
  dateType: MilestoneDateType;
  estate: Estate;
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type CreateManyAndReturnTask = {
  __typename?: "CreateManyAndReturnTask";
  completeByDateEnd?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completeByDateStart?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completeByName?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  estate: Estate;
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  important: Scalars["Boolean"]["output"];
  level: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  onCompleteMessage?: Maybe<Scalars["String"]["output"]>;
  order: Scalars["Int"]["output"];
  parentTask?: Maybe<Task>;
  parentTaskId?: Maybe<Scalars["String"]["output"]>;
  shortName?: Maybe<Scalars["String"]["output"]>;
  status: TaskStatus;
  taskId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  workflowTaskId?: Maybe<Scalars["String"]["output"]>;
};

export type CreateManyAndReturnTaskParentTaskArgs = {
  where?: InputMaybe<TaskWhereInput>;
};

export type CreateManyAndReturnTransaction = {
  __typename?: "CreateManyAndReturnTransaction";
  accountId: Scalars["String"]["output"];
  accountOwner?: Maybe<Scalars["String"]["output"]>;
  amount: Scalars["Decimal"]["output"];
  authorizedDate?: Maybe<Scalars["DateTimeISO"]["output"]>;
  authorizedDatetime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  category?: Maybe<Array<Scalars["String"]["output"]>>;
  categoryId?: Maybe<Scalars["String"]["output"]>;
  checkNumber?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  date: Scalars["DateTimeISO"]["output"];
  dateTime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  id: Scalars["String"]["output"];
  isoCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  linkedAccount: LinkedAccount;
  linkedAccountId: Scalars["String"]["output"];
  location?: Maybe<Scalars["JSON"]["output"]>;
  merchantName?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  originalDescription?: Maybe<Scalars["String"]["output"]>;
  paymentChannel?: Maybe<Scalars["String"]["output"]>;
  paymentMeta?: Maybe<Scalars["JSON"]["output"]>;
  pending: Scalars["Boolean"]["output"];
  pendingTransactionId?: Maybe<Scalars["String"]["output"]>;
  personalFinanceCategory?: Maybe<Scalars["JSON"]["output"]>;
  transactionCode?: Maybe<Scalars["String"]["output"]>;
  transactionId: Scalars["String"]["output"];
  transactionType?: Maybe<Scalars["String"]["output"]>;
  unofficialCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type CreateManyAndReturnUser = {
  __typename?: "CreateManyAndReturnUser";
  active: Scalars["Boolean"]["output"];
  address?: Maybe<Scalars["String"]["output"]>;
  avatar?: Maybe<Scalars["String"]["output"]>;
  boxUserId?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  currentAddress?: Maybe<Address>;
  currentAddressId?: Maybe<Scalars["String"]["output"]>;
  dateOfBirth?: Maybe<Scalars["DateTimeISO"]["output"]>;
  email: Scalars["String"]["output"];
  externalId: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  forcePasswordReset: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  middleName?: Maybe<Scalars["String"]["output"]>;
  partnerId?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  sex?: Maybe<Sex>;
  sfx?: Maybe<Sfx>;
  ssn?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  tempPassword?: Maybe<Scalars["String"]["output"]>;
  type: UserType;
  updatedAt: Scalars["DateTimeISO"]["output"];
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type CreateManyAndReturnUserCurrentAddressArgs = {
  where?: InputMaybe<AddressWhereInput>;
};

export type CreateManyAndReturnUserLogin = {
  __typename?: "CreateManyAndReturnUserLogin";
  createdAt: Scalars["DateTimeISO"]["output"];
  id: Scalars["String"]["output"];
  loginAt: Scalars["DateTimeISO"]["output"];
  loginIp: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
};

export type CreateManyAndReturnUserNotification = {
  __typename?: "CreateManyAndReturnUserNotification";
  createdAt: Scalars["DateTimeISO"]["output"];
  notification: EstateNotification;
  notificationId: Scalars["String"]["output"];
  seenAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  user: User;
  userId: Scalars["String"]["output"];
};

export type CreateManyAndReturnUsersOnEstates = {
  __typename?: "CreateManyAndReturnUsersOnEstates";
  boxUserRootFolderId?: Maybe<Scalars["String"]["output"]>;
  estate: Estate;
  estateId: Scalars["String"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
  userRole?: Maybe<UserEstateRole>;
};

export type CreateNotificationByGroupInput = {
  enablePush?: InputMaybe<Scalars["Boolean"]["input"]>;
  estateId: Scalars["String"]["input"];
  group: NotificationGroup;
  message: Scalars["String"]["input"];
  relatedElementType?: InputMaybe<RelatedElementType>;
  routeElements?: InputMaybe<Array<RouteElementInput>>;
  title: Scalars["String"]["input"];
};

export type CreateUserInput = {
  email: Scalars["String"]["input"];
  estates?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutUserInput2>;
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  messageAction?: InputMaybe<MessageAction>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  phone: Scalars["String"]["input"];
  type?: InputMaybe<UserType>;
  userRole?: InputMaybe<UserEstateRole>;
};

export type DateRangeInput = {
  endDate: Scalars["DateTimeISO"]["input"];
  startDate: Scalars["DateTimeISO"]["input"];
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  gt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  gte?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  in?: InputMaybe<Array<Scalars["DateTimeISO"]["input"]>>;
  lt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lte?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTimeISO"]["input"]>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  gt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  gte?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  in?: InputMaybe<Array<Scalars["DateTimeISO"]["input"]>>;
  lt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lte?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTimeISO"]["input"]>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  gt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  gte?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  in?: InputMaybe<Array<Scalars["DateTimeISO"]["input"]>>;
  lt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lte?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTimeISO"]["input"]>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  gt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  gte?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  in?: InputMaybe<Array<Scalars["DateTimeISO"]["input"]>>;
  lt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lte?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTimeISO"]["input"]>>;
};

export type Deceased = {
  __typename?: "Deceased";
  ContactDeceased: Array<DeceasedContacts>;
  _count?: Maybe<DeceasedCount>;
  address?: Maybe<Scalars["String"]["output"]>;
  citizenship?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  dateOfBirth?: Maybe<Scalars["DateTimeISO"]["output"]>;
  dateOfDeath?: Maybe<Scalars["DateTimeISO"]["output"]>;
  estate: Estate;
  estateId: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  lastKnowAddressId?: Maybe<Scalars["String"]["output"]>;
  lastKnownAddress?: Maybe<Address>;
  lastName: Scalars["String"]["output"];
  maritalStatus?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  nameOfHospital?: Maybe<Scalars["String"]["output"]>;
  placeOfDeathAddress?: Maybe<Address>;
  podAddress?: Maybe<Scalars["String"]["output"]>;
  podAddressId?: Maybe<Scalars["String"]["output"]>;
  podCity?: Maybe<Scalars["String"]["output"]>;
  podCountry?: Maybe<Scalars["String"]["output"]>;
  podCounty?: Maybe<Scalars["String"]["output"]>;
  podState?: Maybe<Scalars["String"]["output"]>;
  podZip?: Maybe<Scalars["String"]["output"]>;
  referringPartner?: Maybe<Scalars["String"]["output"]>;
  sex?: Maybe<Sex>;
  sfx?: Maybe<Sfx>;
  state?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  veteranStatus?: Maybe<Scalars["Boolean"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type DeceasedContactDeceasedArgs = {
  cursor?: InputMaybe<DeceasedContactsWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeceasedContactsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeceasedContactsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeceasedContactsWhereInput>;
};

export type DeceasedLastKnownAddressArgs = {
  where?: InputMaybe<AddressWhereInput>;
};

export type DeceasedPlaceOfDeathAddressArgs = {
  where?: InputMaybe<AddressWhereInput>;
};

export type Deceased2 = {
  __typename?: "Deceased2";
  address?: Maybe<Scalars["String"]["output"]>;
  citizenship?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  dateOfBirth?: Maybe<Scalars["DateTimeISO"]["output"]>;
  dateOfDeath?: Maybe<Scalars["DateTimeISO"]["output"]>;
  estate?: Maybe<Estate2>;
  estateId: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  lastKnownAddress?: Maybe<Address2>;
  lastName: Scalars["String"]["output"];
  maritalStatus?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  nameOfHospital?: Maybe<Scalars["String"]["output"]>;
  podAddress?: Maybe<Scalars["String"]["output"]>;
  podCity?: Maybe<Scalars["String"]["output"]>;
  podCountry?: Maybe<Scalars["String"]["output"]>;
  podCounty?: Maybe<Scalars["String"]["output"]>;
  podState?: Maybe<Scalars["String"]["output"]>;
  podZip?: Maybe<Scalars["String"]["output"]>;
  referringPartner?: Maybe<Scalars["String"]["output"]>;
  sex?: Maybe<SexType2>;
  sfx?: Maybe<SfxType2>;
  ssn?: Maybe<Scalars["String"]["output"]>;
  ssnLast4?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  veteranStatus?: Maybe<Scalars["Boolean"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

export enum DeceasedContactType {
  Attorney = "ATTORNEY",
  Aunt = "AUNT",
  Brother = "BROTHER",
  Child = "CHILD",
  Cousin = "COUSIN",
  Daughter = "DAUGHTER",
  FamilyMember = "FAMILY_MEMBER",
  Father = "FATHER",
  Fiduciary = "FIDUCIARY",
  Friend = "FRIEND",
  Mother = "MOTHER",
  Nephew = "NEPHEW",
  Niece = "NIECE",
  Parent = "PARENT",
  Sibling = "SIBLING",
  Sister = "SISTER",
  Son = "SON",
  Uncle = "UNCLE",
}

export type DeceasedContacts = {
  __typename?: "DeceasedContacts";
  contact: Contact;
  contactId: Scalars["String"]["output"];
  deceased: Deceased;
  deceasedContactType?: Maybe<DeceasedContactType>;
  deceasedId: Scalars["String"]["output"];
};

export type DeceasedContactsContactIdDeceasedIdCompoundUniqueInput = {
  contactId: Scalars["String"]["input"];
  deceasedId: Scalars["String"]["input"];
};

export type DeceasedContactsCountAggregate = {
  __typename?: "DeceasedContactsCountAggregate";
  _all: Scalars["Int"]["output"];
  contactId: Scalars["Int"]["output"];
  deceasedContactType: Scalars["Int"]["output"];
  deceasedId: Scalars["Int"]["output"];
};

export type DeceasedContactsCountOrderByAggregateInput = {
  contactId?: InputMaybe<SortOrder>;
  deceasedContactType?: InputMaybe<SortOrder>;
  deceasedId?: InputMaybe<SortOrder>;
};

export type DeceasedContactsCreateInput = {
  contact: ContactCreateNestedOneWithoutContactDeceasedInput;
  deceased: DeceasedCreateNestedOneWithoutContactDeceasedInput;
  deceasedContactType?: InputMaybe<DeceasedContactType>;
};

export type DeceasedContactsCreateManyContactInput = {
  deceasedContactType?: InputMaybe<DeceasedContactType>;
  deceasedId: Scalars["String"]["input"];
};

export type DeceasedContactsCreateManyContactInputEnvelope = {
  data: Array<DeceasedContactsCreateManyContactInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DeceasedContactsCreateManyDeceasedInput = {
  contactId: Scalars["String"]["input"];
  deceasedContactType?: InputMaybe<DeceasedContactType>;
};

export type DeceasedContactsCreateManyDeceasedInputEnvelope = {
  data: Array<DeceasedContactsCreateManyDeceasedInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DeceasedContactsCreateManyInput = {
  contactId: Scalars["String"]["input"];
  deceasedContactType?: InputMaybe<DeceasedContactType>;
  deceasedId: Scalars["String"]["input"];
};

export type DeceasedContactsCreateNestedManyWithoutContactInput = {
  connect?: InputMaybe<Array<DeceasedContactsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeceasedContactsCreateOrConnectWithoutContactInput>
  >;
  create?: InputMaybe<Array<DeceasedContactsCreateWithoutContactInput>>;
  createMany?: InputMaybe<DeceasedContactsCreateManyContactInputEnvelope>;
};

export type DeceasedContactsCreateNestedManyWithoutDeceasedInput = {
  connect?: InputMaybe<Array<DeceasedContactsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeceasedContactsCreateOrConnectWithoutDeceasedInput>
  >;
  create?: InputMaybe<Array<DeceasedContactsCreateWithoutDeceasedInput>>;
  createMany?: InputMaybe<DeceasedContactsCreateManyDeceasedInputEnvelope>;
};

export type DeceasedContactsCreateOrConnectWithoutContactInput = {
  create: DeceasedContactsCreateWithoutContactInput;
  where: DeceasedContactsWhereUniqueInput;
};

export type DeceasedContactsCreateOrConnectWithoutDeceasedInput = {
  create: DeceasedContactsCreateWithoutDeceasedInput;
  where: DeceasedContactsWhereUniqueInput;
};

export type DeceasedContactsCreateWithoutContactInput = {
  deceased: DeceasedCreateNestedOneWithoutContactDeceasedInput;
  deceasedContactType?: InputMaybe<DeceasedContactType>;
};

export type DeceasedContactsCreateWithoutDeceasedInput = {
  contact: ContactCreateNestedOneWithoutContactDeceasedInput;
  deceasedContactType?: InputMaybe<DeceasedContactType>;
};

export type DeceasedContactsGroupBy = {
  __typename?: "DeceasedContactsGroupBy";
  _count?: Maybe<DeceasedContactsCountAggregate>;
  _max?: Maybe<DeceasedContactsMaxAggregate>;
  _min?: Maybe<DeceasedContactsMinAggregate>;
  contactId: Scalars["String"]["output"];
  deceasedContactType?: Maybe<DeceasedContactType>;
  deceasedId: Scalars["String"]["output"];
};

export type DeceasedContactsListRelationFilter = {
  every?: InputMaybe<DeceasedContactsWhereInput>;
  none?: InputMaybe<DeceasedContactsWhereInput>;
  some?: InputMaybe<DeceasedContactsWhereInput>;
};

export type DeceasedContactsMaxAggregate = {
  __typename?: "DeceasedContactsMaxAggregate";
  contactId?: Maybe<Scalars["String"]["output"]>;
  deceasedContactType?: Maybe<DeceasedContactType>;
  deceasedId?: Maybe<Scalars["String"]["output"]>;
};

export type DeceasedContactsMaxOrderByAggregateInput = {
  contactId?: InputMaybe<SortOrder>;
  deceasedContactType?: InputMaybe<SortOrder>;
  deceasedId?: InputMaybe<SortOrder>;
};

export type DeceasedContactsMinAggregate = {
  __typename?: "DeceasedContactsMinAggregate";
  contactId?: Maybe<Scalars["String"]["output"]>;
  deceasedContactType?: Maybe<DeceasedContactType>;
  deceasedId?: Maybe<Scalars["String"]["output"]>;
};

export type DeceasedContactsMinOrderByAggregateInput = {
  contactId?: InputMaybe<SortOrder>;
  deceasedContactType?: InputMaybe<SortOrder>;
  deceasedId?: InputMaybe<SortOrder>;
};

export type DeceasedContactsOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DeceasedContactsOrderByWithAggregationInput = {
  _count?: InputMaybe<DeceasedContactsCountOrderByAggregateInput>;
  _max?: InputMaybe<DeceasedContactsMaxOrderByAggregateInput>;
  _min?: InputMaybe<DeceasedContactsMinOrderByAggregateInput>;
  contactId?: InputMaybe<SortOrder>;
  deceasedContactType?: InputMaybe<SortOrderInput>;
  deceasedId?: InputMaybe<SortOrder>;
};

export type DeceasedContactsOrderByWithRelationInput = {
  contact?: InputMaybe<ContactOrderByWithRelationInput>;
  contactId?: InputMaybe<SortOrder>;
  deceased?: InputMaybe<DeceasedOrderByWithRelationInput>;
  deceasedContactType?: InputMaybe<SortOrderInput>;
  deceasedId?: InputMaybe<SortOrder>;
};

export enum DeceasedContactsScalarFieldEnum {
  ContactId = "contactId",
  DeceasedContactType = "deceasedContactType",
  DeceasedId = "deceasedId",
}

export type DeceasedContactsScalarWhereInput = {
  AND?: InputMaybe<Array<DeceasedContactsScalarWhereInput>>;
  NOT?: InputMaybe<Array<DeceasedContactsScalarWhereInput>>;
  OR?: InputMaybe<Array<DeceasedContactsScalarWhereInput>>;
  contactId?: InputMaybe<StringFilter>;
  deceasedContactType?: InputMaybe<EnumDeceasedContactTypeNullableFilter>;
  deceasedId?: InputMaybe<StringFilter>;
};

export type DeceasedContactsScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<DeceasedContactsScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<DeceasedContactsScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<DeceasedContactsScalarWhereWithAggregatesInput>>;
  contactId?: InputMaybe<StringWithAggregatesFilter>;
  deceasedContactType?: InputMaybe<EnumDeceasedContactTypeNullableWithAggregatesFilter>;
  deceasedId?: InputMaybe<StringWithAggregatesFilter>;
};

export type DeceasedContactsUpdateInput = {
  contact?: InputMaybe<ContactUpdateOneRequiredWithoutContactDeceasedNestedInput>;
  deceased?: InputMaybe<DeceasedUpdateOneRequiredWithoutContactDeceasedNestedInput>;
  deceasedContactType?: InputMaybe<NullableEnumDeceasedContactTypeFieldUpdateOperationsInput>;
};

export type DeceasedContactsUpdateManyMutationInput = {
  deceasedContactType?: InputMaybe<NullableEnumDeceasedContactTypeFieldUpdateOperationsInput>;
};

export type DeceasedContactsUpdateManyWithWhereWithoutContactInput = {
  data: DeceasedContactsUpdateManyMutationInput;
  where: DeceasedContactsScalarWhereInput;
};

export type DeceasedContactsUpdateManyWithWhereWithoutDeceasedInput = {
  data: DeceasedContactsUpdateManyMutationInput;
  where: DeceasedContactsScalarWhereInput;
};

export type DeceasedContactsUpdateManyWithoutContactNestedInput = {
  connect?: InputMaybe<Array<DeceasedContactsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeceasedContactsCreateOrConnectWithoutContactInput>
  >;
  create?: InputMaybe<Array<DeceasedContactsCreateWithoutContactInput>>;
  createMany?: InputMaybe<DeceasedContactsCreateManyContactInputEnvelope>;
  delete?: InputMaybe<Array<DeceasedContactsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeceasedContactsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeceasedContactsWhereUniqueInput>>;
  set?: InputMaybe<Array<DeceasedContactsWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DeceasedContactsUpdateWithWhereUniqueWithoutContactInput>
  >;
  updateMany?: InputMaybe<
    Array<DeceasedContactsUpdateManyWithWhereWithoutContactInput>
  >;
  upsert?: InputMaybe<
    Array<DeceasedContactsUpsertWithWhereUniqueWithoutContactInput>
  >;
};

export type DeceasedContactsUpdateManyWithoutDeceasedNestedInput = {
  connect?: InputMaybe<Array<DeceasedContactsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeceasedContactsCreateOrConnectWithoutDeceasedInput>
  >;
  create?: InputMaybe<Array<DeceasedContactsCreateWithoutDeceasedInput>>;
  createMany?: InputMaybe<DeceasedContactsCreateManyDeceasedInputEnvelope>;
  delete?: InputMaybe<Array<DeceasedContactsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeceasedContactsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeceasedContactsWhereUniqueInput>>;
  set?: InputMaybe<Array<DeceasedContactsWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DeceasedContactsUpdateWithWhereUniqueWithoutDeceasedInput>
  >;
  updateMany?: InputMaybe<
    Array<DeceasedContactsUpdateManyWithWhereWithoutDeceasedInput>
  >;
  upsert?: InputMaybe<
    Array<DeceasedContactsUpsertWithWhereUniqueWithoutDeceasedInput>
  >;
};

export type DeceasedContactsUpdateWithWhereUniqueWithoutContactInput = {
  data: DeceasedContactsUpdateWithoutContactInput;
  where: DeceasedContactsWhereUniqueInput;
};

export type DeceasedContactsUpdateWithWhereUniqueWithoutDeceasedInput = {
  data: DeceasedContactsUpdateWithoutDeceasedInput;
  where: DeceasedContactsWhereUniqueInput;
};

export type DeceasedContactsUpdateWithoutContactInput = {
  deceased?: InputMaybe<DeceasedUpdateOneRequiredWithoutContactDeceasedNestedInput>;
  deceasedContactType?: InputMaybe<NullableEnumDeceasedContactTypeFieldUpdateOperationsInput>;
};

export type DeceasedContactsUpdateWithoutDeceasedInput = {
  contact?: InputMaybe<ContactUpdateOneRequiredWithoutContactDeceasedNestedInput>;
  deceasedContactType?: InputMaybe<NullableEnumDeceasedContactTypeFieldUpdateOperationsInput>;
};

export type DeceasedContactsUpsertWithWhereUniqueWithoutContactInput = {
  create: DeceasedContactsCreateWithoutContactInput;
  update: DeceasedContactsUpdateWithoutContactInput;
  where: DeceasedContactsWhereUniqueInput;
};

export type DeceasedContactsUpsertWithWhereUniqueWithoutDeceasedInput = {
  create: DeceasedContactsCreateWithoutDeceasedInput;
  update: DeceasedContactsUpdateWithoutDeceasedInput;
  where: DeceasedContactsWhereUniqueInput;
};

export type DeceasedContactsWhereInput = {
  AND?: InputMaybe<Array<DeceasedContactsWhereInput>>;
  NOT?: InputMaybe<Array<DeceasedContactsWhereInput>>;
  OR?: InputMaybe<Array<DeceasedContactsWhereInput>>;
  contact?: InputMaybe<ContactRelationFilter>;
  contactId?: InputMaybe<StringFilter>;
  deceased?: InputMaybe<DeceasedRelationFilter>;
  deceasedContactType?: InputMaybe<EnumDeceasedContactTypeNullableFilter>;
  deceasedId?: InputMaybe<StringFilter>;
};

export type DeceasedContactsWhereUniqueInput = {
  AND?: InputMaybe<Array<DeceasedContactsWhereInput>>;
  NOT?: InputMaybe<Array<DeceasedContactsWhereInput>>;
  OR?: InputMaybe<Array<DeceasedContactsWhereInput>>;
  contact?: InputMaybe<ContactRelationFilter>;
  contactId?: InputMaybe<StringFilter>;
  contactId_deceasedId?: InputMaybe<DeceasedContactsContactIdDeceasedIdCompoundUniqueInput>;
  deceased?: InputMaybe<DeceasedRelationFilter>;
  deceasedContactType?: InputMaybe<EnumDeceasedContactTypeNullableFilter>;
  deceasedId?: InputMaybe<StringFilter>;
};

export type DeceasedCount = {
  __typename?: "DeceasedCount";
  ContactDeceased: Scalars["Int"]["output"];
};

export type DeceasedCountContactDeceasedArgs = {
  where?: InputMaybe<DeceasedContactsWhereInput>;
};

export type DeceasedCountAggregate = {
  __typename?: "DeceasedCountAggregate";
  _all: Scalars["Int"]["output"];
  address: Scalars["Int"]["output"];
  citizenship: Scalars["Int"]["output"];
  city: Scalars["Int"]["output"];
  country: Scalars["Int"]["output"];
  county: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  dateOfBirth: Scalars["Int"]["output"];
  dateOfDeath: Scalars["Int"]["output"];
  estateId: Scalars["Int"]["output"];
  firstName: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  lastKnowAddressId: Scalars["Int"]["output"];
  lastName: Scalars["Int"]["output"];
  maritalStatus: Scalars["Int"]["output"];
  middleName: Scalars["Int"]["output"];
  nameOfHospital: Scalars["Int"]["output"];
  podAddress: Scalars["Int"]["output"];
  podAddressId: Scalars["Int"]["output"];
  podCity: Scalars["Int"]["output"];
  podCountry: Scalars["Int"]["output"];
  podCounty: Scalars["Int"]["output"];
  podState: Scalars["Int"]["output"];
  podZip: Scalars["Int"]["output"];
  referringPartner: Scalars["Int"]["output"];
  sex: Scalars["Int"]["output"];
  sfx: Scalars["Int"]["output"];
  ssn: Scalars["Int"]["output"];
  state: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  veteranStatus: Scalars["Int"]["output"];
  zip: Scalars["Int"]["output"];
};

export type DeceasedCountOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  citizenship?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  county?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrder>;
  dateOfDeath?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastKnowAddressId?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  maritalStatus?: InputMaybe<SortOrder>;
  middleName?: InputMaybe<SortOrder>;
  nameOfHospital?: InputMaybe<SortOrder>;
  podAddress?: InputMaybe<SortOrder>;
  podAddressId?: InputMaybe<SortOrder>;
  podCity?: InputMaybe<SortOrder>;
  podCountry?: InputMaybe<SortOrder>;
  podCounty?: InputMaybe<SortOrder>;
  podState?: InputMaybe<SortOrder>;
  podZip?: InputMaybe<SortOrder>;
  referringPartner?: InputMaybe<SortOrder>;
  sex?: InputMaybe<SortOrder>;
  sfx?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  veteranStatus?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type DeceasedCreateInput = {
  ContactDeceased?: InputMaybe<DeceasedContactsCreateNestedManyWithoutDeceasedInput>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  citizenship?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfDeath?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estate: EstateCreateNestedOneWithoutDeceasedInput;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastKnownAddress?: InputMaybe<AddressCreateNestedOneWithoutDeceasedInput>;
  lastName: Scalars["String"]["input"];
  maritalStatus?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  nameOfHospital?: InputMaybe<Scalars["String"]["input"]>;
  placeOfDeathAddress?: InputMaybe<AddressCreateNestedOneWithoutPodDeceasedInput>;
  podAddress?: InputMaybe<Scalars["String"]["input"]>;
  podCity?: InputMaybe<Scalars["String"]["input"]>;
  podCountry?: InputMaybe<Scalars["String"]["input"]>;
  podCounty?: InputMaybe<Scalars["String"]["input"]>;
  podState?: InputMaybe<Scalars["String"]["input"]>;
  podZip?: InputMaybe<Scalars["String"]["input"]>;
  referringPartner?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  veteranStatus?: InputMaybe<Scalars["Boolean"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeceasedCreateInput2 = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  citizenship?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfDeath?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estate: EstateCreateNestedOneWithoutDeceasedInput2;
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  maritalStatus?: InputMaybe<Scalars["String"]["input"]>;
  nameOfHospital?: InputMaybe<Scalars["String"]["input"]>;
  podAddress?: InputMaybe<Scalars["String"]["input"]>;
  podCity?: InputMaybe<Scalars["String"]["input"]>;
  podCountry?: InputMaybe<Scalars["String"]["input"]>;
  podCounty?: InputMaybe<Scalars["String"]["input"]>;
  podState?: InputMaybe<Scalars["String"]["input"]>;
  podZip?: InputMaybe<Scalars["String"]["input"]>;
  referringPartner?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  veteranStatus?: InputMaybe<Scalars["Boolean"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeceasedCreateManyInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  citizenship?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfDeath?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estateId: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastKnowAddressId?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  maritalStatus?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  nameOfHospital?: InputMaybe<Scalars["String"]["input"]>;
  podAddress?: InputMaybe<Scalars["String"]["input"]>;
  podAddressId?: InputMaybe<Scalars["String"]["input"]>;
  podCity?: InputMaybe<Scalars["String"]["input"]>;
  podCountry?: InputMaybe<Scalars["String"]["input"]>;
  podCounty?: InputMaybe<Scalars["String"]["input"]>;
  podState?: InputMaybe<Scalars["String"]["input"]>;
  podZip?: InputMaybe<Scalars["String"]["input"]>;
  referringPartner?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  veteranStatus?: InputMaybe<Scalars["Boolean"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeceasedCreateManyLastKnownAddressInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  citizenship?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfDeath?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estateId: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  maritalStatus?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  nameOfHospital?: InputMaybe<Scalars["String"]["input"]>;
  podAddress?: InputMaybe<Scalars["String"]["input"]>;
  podAddressId?: InputMaybe<Scalars["String"]["input"]>;
  podCity?: InputMaybe<Scalars["String"]["input"]>;
  podCountry?: InputMaybe<Scalars["String"]["input"]>;
  podCounty?: InputMaybe<Scalars["String"]["input"]>;
  podState?: InputMaybe<Scalars["String"]["input"]>;
  podZip?: InputMaybe<Scalars["String"]["input"]>;
  referringPartner?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  veteranStatus?: InputMaybe<Scalars["Boolean"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeceasedCreateManyLastKnownAddressInputEnvelope = {
  data: Array<DeceasedCreateManyLastKnownAddressInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DeceasedCreateManyPlaceOfDeathAddressInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  citizenship?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfDeath?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estateId: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastKnowAddressId?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  maritalStatus?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  nameOfHospital?: InputMaybe<Scalars["String"]["input"]>;
  podAddress?: InputMaybe<Scalars["String"]["input"]>;
  podCity?: InputMaybe<Scalars["String"]["input"]>;
  podCountry?: InputMaybe<Scalars["String"]["input"]>;
  podCounty?: InputMaybe<Scalars["String"]["input"]>;
  podState?: InputMaybe<Scalars["String"]["input"]>;
  podZip?: InputMaybe<Scalars["String"]["input"]>;
  referringPartner?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  veteranStatus?: InputMaybe<Scalars["Boolean"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeceasedCreateManyPlaceOfDeathAddressInputEnvelope = {
  data: Array<DeceasedCreateManyPlaceOfDeathAddressInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DeceasedCreateNestedManyWithoutLastKnownAddressInput = {
  connect?: InputMaybe<Array<DeceasedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeceasedCreateOrConnectWithoutLastKnownAddressInput>
  >;
  create?: InputMaybe<Array<DeceasedCreateWithoutLastKnownAddressInput>>;
  createMany?: InputMaybe<DeceasedCreateManyLastKnownAddressInputEnvelope>;
};

export type DeceasedCreateNestedManyWithoutPlaceOfDeathAddressInput = {
  connect?: InputMaybe<Array<DeceasedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeceasedCreateOrConnectWithoutPlaceOfDeathAddressInput>
  >;
  create?: InputMaybe<Array<DeceasedCreateWithoutPlaceOfDeathAddressInput>>;
  createMany?: InputMaybe<DeceasedCreateManyPlaceOfDeathAddressInputEnvelope>;
};

export type DeceasedCreateNestedOneWithoutContactDeceasedInput = {
  connect?: InputMaybe<DeceasedWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeceasedCreateOrConnectWithoutContactDeceasedInput>;
  create?: InputMaybe<DeceasedCreateWithoutContactDeceasedInput>;
};

export type DeceasedCreateNestedOneWithoutEstateInput = {
  connect?: InputMaybe<DeceasedWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeceasedCreateOrConnectWithoutEstateInput>;
  create?: InputMaybe<DeceasedCreateWithoutEstateInput>;
};

export type DeceasedCreateNestedOneWithoutEstateInput2 = {
  create?: InputMaybe<DeceasedCreateWithoutEstateInput2>;
};

export type DeceasedCreateOrConnectWithoutContactDeceasedInput = {
  create: DeceasedCreateWithoutContactDeceasedInput;
  where: DeceasedWhereUniqueInput;
};

export type DeceasedCreateOrConnectWithoutEstateInput = {
  create: DeceasedCreateWithoutEstateInput;
  where: DeceasedWhereUniqueInput;
};

export type DeceasedCreateOrConnectWithoutLastKnownAddressInput = {
  create: DeceasedCreateWithoutLastKnownAddressInput;
  where: DeceasedWhereUniqueInput;
};

export type DeceasedCreateOrConnectWithoutPlaceOfDeathAddressInput = {
  create: DeceasedCreateWithoutPlaceOfDeathAddressInput;
  where: DeceasedWhereUniqueInput;
};

export type DeceasedCreateWithoutContactDeceasedInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  citizenship?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfDeath?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estate: EstateCreateNestedOneWithoutDeceasedInput;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastKnownAddress?: InputMaybe<AddressCreateNestedOneWithoutDeceasedInput>;
  lastName: Scalars["String"]["input"];
  maritalStatus?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  nameOfHospital?: InputMaybe<Scalars["String"]["input"]>;
  placeOfDeathAddress?: InputMaybe<AddressCreateNestedOneWithoutPodDeceasedInput>;
  podAddress?: InputMaybe<Scalars["String"]["input"]>;
  podCity?: InputMaybe<Scalars["String"]["input"]>;
  podCountry?: InputMaybe<Scalars["String"]["input"]>;
  podCounty?: InputMaybe<Scalars["String"]["input"]>;
  podState?: InputMaybe<Scalars["String"]["input"]>;
  podZip?: InputMaybe<Scalars["String"]["input"]>;
  referringPartner?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  veteranStatus?: InputMaybe<Scalars["Boolean"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeceasedCreateWithoutEstateInput = {
  ContactDeceased?: InputMaybe<DeceasedContactsCreateNestedManyWithoutDeceasedInput>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  citizenship?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfDeath?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastKnownAddress?: InputMaybe<AddressCreateNestedOneWithoutDeceasedInput>;
  lastName: Scalars["String"]["input"];
  maritalStatus?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  nameOfHospital?: InputMaybe<Scalars["String"]["input"]>;
  placeOfDeathAddress?: InputMaybe<AddressCreateNestedOneWithoutPodDeceasedInput>;
  podAddress?: InputMaybe<Scalars["String"]["input"]>;
  podCity?: InputMaybe<Scalars["String"]["input"]>;
  podCountry?: InputMaybe<Scalars["String"]["input"]>;
  podCounty?: InputMaybe<Scalars["String"]["input"]>;
  podState?: InputMaybe<Scalars["String"]["input"]>;
  podZip?: InputMaybe<Scalars["String"]["input"]>;
  referringPartner?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  veteranStatus?: InputMaybe<Scalars["Boolean"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeceasedCreateWithoutEstateInput2 = {
  address?: InputMaybe<AddressInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfDeath?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<SexType2>;
  sfx?: InputMaybe<SfxType2>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeceasedCreateWithoutLastKnownAddressInput = {
  ContactDeceased?: InputMaybe<DeceasedContactsCreateNestedManyWithoutDeceasedInput>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  citizenship?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfDeath?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estate: EstateCreateNestedOneWithoutDeceasedInput;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  maritalStatus?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  nameOfHospital?: InputMaybe<Scalars["String"]["input"]>;
  placeOfDeathAddress?: InputMaybe<AddressCreateNestedOneWithoutPodDeceasedInput>;
  podAddress?: InputMaybe<Scalars["String"]["input"]>;
  podCity?: InputMaybe<Scalars["String"]["input"]>;
  podCountry?: InputMaybe<Scalars["String"]["input"]>;
  podCounty?: InputMaybe<Scalars["String"]["input"]>;
  podState?: InputMaybe<Scalars["String"]["input"]>;
  podZip?: InputMaybe<Scalars["String"]["input"]>;
  referringPartner?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  veteranStatus?: InputMaybe<Scalars["Boolean"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeceasedCreateWithoutPlaceOfDeathAddressInput = {
  ContactDeceased?: InputMaybe<DeceasedContactsCreateNestedManyWithoutDeceasedInput>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  citizenship?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfDeath?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estate: EstateCreateNestedOneWithoutDeceasedInput;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastKnownAddress?: InputMaybe<AddressCreateNestedOneWithoutDeceasedInput>;
  lastName: Scalars["String"]["input"];
  maritalStatus?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  nameOfHospital?: InputMaybe<Scalars["String"]["input"]>;
  podAddress?: InputMaybe<Scalars["String"]["input"]>;
  podCity?: InputMaybe<Scalars["String"]["input"]>;
  podCountry?: InputMaybe<Scalars["String"]["input"]>;
  podCounty?: InputMaybe<Scalars["String"]["input"]>;
  podState?: InputMaybe<Scalars["String"]["input"]>;
  podZip?: InputMaybe<Scalars["String"]["input"]>;
  referringPartner?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  veteranStatus?: InputMaybe<Scalars["Boolean"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeceasedGroupBy = {
  __typename?: "DeceasedGroupBy";
  _count?: Maybe<DeceasedCountAggregate>;
  _max?: Maybe<DeceasedMaxAggregate>;
  _min?: Maybe<DeceasedMinAggregate>;
  address?: Maybe<Scalars["String"]["output"]>;
  citizenship?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  dateOfBirth?: Maybe<Scalars["DateTimeISO"]["output"]>;
  dateOfDeath?: Maybe<Scalars["DateTimeISO"]["output"]>;
  estateId: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  lastKnowAddressId?: Maybe<Scalars["String"]["output"]>;
  lastName: Scalars["String"]["output"];
  maritalStatus?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  nameOfHospital?: Maybe<Scalars["String"]["output"]>;
  podAddress?: Maybe<Scalars["String"]["output"]>;
  podAddressId?: Maybe<Scalars["String"]["output"]>;
  podCity?: Maybe<Scalars["String"]["output"]>;
  podCountry?: Maybe<Scalars["String"]["output"]>;
  podCounty?: Maybe<Scalars["String"]["output"]>;
  podState?: Maybe<Scalars["String"]["output"]>;
  podZip?: Maybe<Scalars["String"]["output"]>;
  referringPartner?: Maybe<Scalars["String"]["output"]>;
  sex?: Maybe<Sex>;
  sfx?: Maybe<Sfx>;
  ssn?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  veteranStatus?: Maybe<Scalars["Boolean"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type DeceasedInput2 = {
  address?: InputMaybe<AddressInput>;
  citizenship?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfDeath?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  maritalStatus?: InputMaybe<Scalars["String"]["input"]>;
  middleName: Scalars["String"]["input"];
  nameOfHospital?: InputMaybe<Scalars["String"]["input"]>;
  podAddress?: InputMaybe<Scalars["String"]["input"]>;
  podCity?: InputMaybe<Scalars["String"]["input"]>;
  podCountry?: InputMaybe<Scalars["String"]["input"]>;
  podCounty?: InputMaybe<Scalars["String"]["input"]>;
  podState?: InputMaybe<Scalars["String"]["input"]>;
  podZip?: InputMaybe<Scalars["String"]["input"]>;
  referringPartner?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<SexType2>;
  sfx?: InputMaybe<SfxType2>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  veteranStatus?: InputMaybe<Scalars["Boolean"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeceasedListRelationFilter = {
  every?: InputMaybe<DeceasedWhereInput>;
  none?: InputMaybe<DeceasedWhereInput>;
  some?: InputMaybe<DeceasedWhereInput>;
};

export type DeceasedMaxAggregate = {
  __typename?: "DeceasedMaxAggregate";
  address?: Maybe<Scalars["String"]["output"]>;
  citizenship?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  dateOfBirth?: Maybe<Scalars["DateTimeISO"]["output"]>;
  dateOfDeath?: Maybe<Scalars["DateTimeISO"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lastKnowAddressId?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  maritalStatus?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  nameOfHospital?: Maybe<Scalars["String"]["output"]>;
  podAddress?: Maybe<Scalars["String"]["output"]>;
  podAddressId?: Maybe<Scalars["String"]["output"]>;
  podCity?: Maybe<Scalars["String"]["output"]>;
  podCountry?: Maybe<Scalars["String"]["output"]>;
  podCounty?: Maybe<Scalars["String"]["output"]>;
  podState?: Maybe<Scalars["String"]["output"]>;
  podZip?: Maybe<Scalars["String"]["output"]>;
  referringPartner?: Maybe<Scalars["String"]["output"]>;
  sex?: Maybe<Sex>;
  sfx?: Maybe<Sfx>;
  ssn?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  veteranStatus?: Maybe<Scalars["Boolean"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type DeceasedMaxOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  citizenship?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  county?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrder>;
  dateOfDeath?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastKnowAddressId?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  maritalStatus?: InputMaybe<SortOrder>;
  middleName?: InputMaybe<SortOrder>;
  nameOfHospital?: InputMaybe<SortOrder>;
  podAddress?: InputMaybe<SortOrder>;
  podAddressId?: InputMaybe<SortOrder>;
  podCity?: InputMaybe<SortOrder>;
  podCountry?: InputMaybe<SortOrder>;
  podCounty?: InputMaybe<SortOrder>;
  podState?: InputMaybe<SortOrder>;
  podZip?: InputMaybe<SortOrder>;
  referringPartner?: InputMaybe<SortOrder>;
  sex?: InputMaybe<SortOrder>;
  sfx?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  veteranStatus?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type DeceasedMinAggregate = {
  __typename?: "DeceasedMinAggregate";
  address?: Maybe<Scalars["String"]["output"]>;
  citizenship?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  dateOfBirth?: Maybe<Scalars["DateTimeISO"]["output"]>;
  dateOfDeath?: Maybe<Scalars["DateTimeISO"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lastKnowAddressId?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  maritalStatus?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  nameOfHospital?: Maybe<Scalars["String"]["output"]>;
  podAddress?: Maybe<Scalars["String"]["output"]>;
  podAddressId?: Maybe<Scalars["String"]["output"]>;
  podCity?: Maybe<Scalars["String"]["output"]>;
  podCountry?: Maybe<Scalars["String"]["output"]>;
  podCounty?: Maybe<Scalars["String"]["output"]>;
  podState?: Maybe<Scalars["String"]["output"]>;
  podZip?: Maybe<Scalars["String"]["output"]>;
  referringPartner?: Maybe<Scalars["String"]["output"]>;
  sex?: Maybe<Sex>;
  sfx?: Maybe<Sfx>;
  ssn?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  veteranStatus?: Maybe<Scalars["Boolean"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type DeceasedMinOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  citizenship?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  county?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrder>;
  dateOfDeath?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastKnowAddressId?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  maritalStatus?: InputMaybe<SortOrder>;
  middleName?: InputMaybe<SortOrder>;
  nameOfHospital?: InputMaybe<SortOrder>;
  podAddress?: InputMaybe<SortOrder>;
  podAddressId?: InputMaybe<SortOrder>;
  podCity?: InputMaybe<SortOrder>;
  podCountry?: InputMaybe<SortOrder>;
  podCounty?: InputMaybe<SortOrder>;
  podState?: InputMaybe<SortOrder>;
  podZip?: InputMaybe<SortOrder>;
  referringPartner?: InputMaybe<SortOrder>;
  sex?: InputMaybe<SortOrder>;
  sfx?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  veteranStatus?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type DeceasedNullableRelationFilter = {
  is?: InputMaybe<DeceasedWhereInput>;
  isNot?: InputMaybe<DeceasedWhereInput>;
};

export type DeceasedOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DeceasedOrderByWithAggregationInput = {
  _count?: InputMaybe<DeceasedCountOrderByAggregateInput>;
  _max?: InputMaybe<DeceasedMaxOrderByAggregateInput>;
  _min?: InputMaybe<DeceasedMinOrderByAggregateInput>;
  address?: InputMaybe<SortOrderInput>;
  citizenship?: InputMaybe<SortOrderInput>;
  city?: InputMaybe<SortOrderInput>;
  country?: InputMaybe<SortOrderInput>;
  county?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrderInput>;
  dateOfDeath?: InputMaybe<SortOrderInput>;
  estateId?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastKnowAddressId?: InputMaybe<SortOrderInput>;
  lastName?: InputMaybe<SortOrder>;
  maritalStatus?: InputMaybe<SortOrderInput>;
  middleName?: InputMaybe<SortOrderInput>;
  nameOfHospital?: InputMaybe<SortOrderInput>;
  podAddress?: InputMaybe<SortOrderInput>;
  podAddressId?: InputMaybe<SortOrderInput>;
  podCity?: InputMaybe<SortOrderInput>;
  podCountry?: InputMaybe<SortOrderInput>;
  podCounty?: InputMaybe<SortOrderInput>;
  podState?: InputMaybe<SortOrderInput>;
  podZip?: InputMaybe<SortOrderInput>;
  referringPartner?: InputMaybe<SortOrderInput>;
  sex?: InputMaybe<SortOrderInput>;
  sfx?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  veteranStatus?: InputMaybe<SortOrderInput>;
  zip?: InputMaybe<SortOrderInput>;
};

export type DeceasedOrderByWithRelationInput = {
  ContactDeceased?: InputMaybe<DeceasedContactsOrderByRelationAggregateInput>;
  address?: InputMaybe<SortOrderInput>;
  citizenship?: InputMaybe<SortOrderInput>;
  city?: InputMaybe<SortOrderInput>;
  country?: InputMaybe<SortOrderInput>;
  county?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrderInput>;
  dateOfDeath?: InputMaybe<SortOrderInput>;
  estate?: InputMaybe<EstateOrderByWithRelationInput>;
  estateId?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastKnowAddressId?: InputMaybe<SortOrderInput>;
  lastKnownAddress?: InputMaybe<AddressOrderByWithRelationInput>;
  lastName?: InputMaybe<SortOrder>;
  maritalStatus?: InputMaybe<SortOrderInput>;
  middleName?: InputMaybe<SortOrderInput>;
  nameOfHospital?: InputMaybe<SortOrderInput>;
  placeOfDeathAddress?: InputMaybe<AddressOrderByWithRelationInput>;
  podAddress?: InputMaybe<SortOrderInput>;
  podAddressId?: InputMaybe<SortOrderInput>;
  podCity?: InputMaybe<SortOrderInput>;
  podCountry?: InputMaybe<SortOrderInput>;
  podCounty?: InputMaybe<SortOrderInput>;
  podState?: InputMaybe<SortOrderInput>;
  podZip?: InputMaybe<SortOrderInput>;
  referringPartner?: InputMaybe<SortOrderInput>;
  sex?: InputMaybe<SortOrderInput>;
  sfx?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  veteranStatus?: InputMaybe<SortOrderInput>;
  zip?: InputMaybe<SortOrderInput>;
};

export type DeceasedRelationFilter = {
  is?: InputMaybe<DeceasedWhereInput>;
  isNot?: InputMaybe<DeceasedWhereInput>;
};

export enum DeceasedScalarFieldEnum {
  Address = "address",
  Citizenship = "citizenship",
  City = "city",
  Country = "country",
  County = "county",
  CreatedAt = "createdAt",
  DateOfBirth = "dateOfBirth",
  DateOfDeath = "dateOfDeath",
  EstateId = "estateId",
  FirstName = "firstName",
  Id = "id",
  LastKnowAddressId = "lastKnowAddressId",
  LastName = "lastName",
  MaritalStatus = "maritalStatus",
  MiddleName = "middleName",
  NameOfHospital = "nameOfHospital",
  PodAddress = "podAddress",
  PodAddressId = "podAddressId",
  PodCity = "podCity",
  PodCountry = "podCountry",
  PodCounty = "podCounty",
  PodState = "podState",
  PodZip = "podZip",
  ReferringPartner = "referringPartner",
  Sex = "sex",
  Sfx = "sfx",
  Ssn = "ssn",
  State = "state",
  UpdatedAt = "updatedAt",
  VeteranStatus = "veteranStatus",
  Zip = "zip",
}

export type DeceasedScalarWhereInput = {
  AND?: InputMaybe<Array<DeceasedScalarWhereInput>>;
  NOT?: InputMaybe<Array<DeceasedScalarWhereInput>>;
  OR?: InputMaybe<Array<DeceasedScalarWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  citizenship?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  county?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableFilter>;
  dateOfDeath?: InputMaybe<DateTimeNullableFilter>;
  estateId?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastKnowAddressId?: InputMaybe<StringNullableFilter>;
  lastName?: InputMaybe<StringFilter>;
  maritalStatus?: InputMaybe<StringNullableFilter>;
  middleName?: InputMaybe<StringNullableFilter>;
  nameOfHospital?: InputMaybe<StringNullableFilter>;
  podAddress?: InputMaybe<StringNullableFilter>;
  podAddressId?: InputMaybe<StringNullableFilter>;
  podCity?: InputMaybe<StringNullableFilter>;
  podCountry?: InputMaybe<StringNullableFilter>;
  podCounty?: InputMaybe<StringNullableFilter>;
  podState?: InputMaybe<StringNullableFilter>;
  podZip?: InputMaybe<StringNullableFilter>;
  referringPartner?: InputMaybe<StringNullableFilter>;
  sex?: InputMaybe<EnumSexNullableFilter>;
  sfx?: InputMaybe<EnumSfxNullableFilter>;
  state?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  veteranStatus?: InputMaybe<BoolNullableFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type DeceasedScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<DeceasedScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<DeceasedScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<DeceasedScalarWhereWithAggregatesInput>>;
  address?: InputMaybe<StringNullableWithAggregatesFilter>;
  citizenship?: InputMaybe<StringNullableWithAggregatesFilter>;
  city?: InputMaybe<StringNullableWithAggregatesFilter>;
  country?: InputMaybe<StringNullableWithAggregatesFilter>;
  county?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  dateOfDeath?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  estateId?: InputMaybe<StringWithAggregatesFilter>;
  firstName?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  lastKnowAddressId?: InputMaybe<StringNullableWithAggregatesFilter>;
  lastName?: InputMaybe<StringWithAggregatesFilter>;
  maritalStatus?: InputMaybe<StringNullableWithAggregatesFilter>;
  middleName?: InputMaybe<StringNullableWithAggregatesFilter>;
  nameOfHospital?: InputMaybe<StringNullableWithAggregatesFilter>;
  podAddress?: InputMaybe<StringNullableWithAggregatesFilter>;
  podAddressId?: InputMaybe<StringNullableWithAggregatesFilter>;
  podCity?: InputMaybe<StringNullableWithAggregatesFilter>;
  podCountry?: InputMaybe<StringNullableWithAggregatesFilter>;
  podCounty?: InputMaybe<StringNullableWithAggregatesFilter>;
  podState?: InputMaybe<StringNullableWithAggregatesFilter>;
  podZip?: InputMaybe<StringNullableWithAggregatesFilter>;
  referringPartner?: InputMaybe<StringNullableWithAggregatesFilter>;
  sex?: InputMaybe<EnumSexNullableWithAggregatesFilter>;
  sfx?: InputMaybe<EnumSfxNullableWithAggregatesFilter>;
  state?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  veteranStatus?: InputMaybe<BoolNullableWithAggregatesFilter>;
  zip?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type DeceasedUpdateInput = {
  ContactDeceased?: InputMaybe<DeceasedContactsUpdateManyWithoutDeceasedNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  citizenship?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dateOfDeath?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutDeceasedNestedInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastKnownAddress?: InputMaybe<AddressUpdateOneWithoutDeceasedNestedInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  maritalStatus?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nameOfHospital?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  placeOfDeathAddress?: InputMaybe<AddressUpdateOneWithoutPodDeceasedNestedInput>;
  podAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podCounty?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podZip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referringPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  veteranStatus?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type DeceasedUpdateInput2 = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  citizenship?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfDeath?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutDeceasedNestedInput2>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  maritalStatus?: InputMaybe<Scalars["String"]["input"]>;
  nameOfHospital?: InputMaybe<Scalars["String"]["input"]>;
  podAddress?: InputMaybe<Scalars["String"]["input"]>;
  podCity?: InputMaybe<Scalars["String"]["input"]>;
  podCountry?: InputMaybe<Scalars["String"]["input"]>;
  podCounty?: InputMaybe<Scalars["String"]["input"]>;
  podState?: InputMaybe<Scalars["String"]["input"]>;
  podZip?: InputMaybe<Scalars["String"]["input"]>;
  referringPartner?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  veteranStatus?: InputMaybe<Scalars["Boolean"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeceasedUpdateManyMutationInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  citizenship?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dateOfDeath?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  maritalStatus?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nameOfHospital?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podCounty?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podZip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referringPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  veteranStatus?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type DeceasedUpdateManyWithWhereWithoutLastKnownAddressInput = {
  data: DeceasedUpdateManyMutationInput;
  where: DeceasedScalarWhereInput;
};

export type DeceasedUpdateManyWithWhereWithoutPlaceOfDeathAddressInput = {
  data: DeceasedUpdateManyMutationInput;
  where: DeceasedScalarWhereInput;
};

export type DeceasedUpdateManyWithoutLastKnownAddressNestedInput = {
  connect?: InputMaybe<Array<DeceasedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeceasedCreateOrConnectWithoutLastKnownAddressInput>
  >;
  create?: InputMaybe<Array<DeceasedCreateWithoutLastKnownAddressInput>>;
  createMany?: InputMaybe<DeceasedCreateManyLastKnownAddressInputEnvelope>;
  delete?: InputMaybe<Array<DeceasedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeceasedScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeceasedWhereUniqueInput>>;
  set?: InputMaybe<Array<DeceasedWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DeceasedUpdateWithWhereUniqueWithoutLastKnownAddressInput>
  >;
  updateMany?: InputMaybe<
    Array<DeceasedUpdateManyWithWhereWithoutLastKnownAddressInput>
  >;
  upsert?: InputMaybe<
    Array<DeceasedUpsertWithWhereUniqueWithoutLastKnownAddressInput>
  >;
};

export type DeceasedUpdateManyWithoutPlaceOfDeathAddressNestedInput = {
  connect?: InputMaybe<Array<DeceasedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeceasedCreateOrConnectWithoutPlaceOfDeathAddressInput>
  >;
  create?: InputMaybe<Array<DeceasedCreateWithoutPlaceOfDeathAddressInput>>;
  createMany?: InputMaybe<DeceasedCreateManyPlaceOfDeathAddressInputEnvelope>;
  delete?: InputMaybe<Array<DeceasedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeceasedScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeceasedWhereUniqueInput>>;
  set?: InputMaybe<Array<DeceasedWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DeceasedUpdateWithWhereUniqueWithoutPlaceOfDeathAddressInput>
  >;
  updateMany?: InputMaybe<
    Array<DeceasedUpdateManyWithWhereWithoutPlaceOfDeathAddressInput>
  >;
  upsert?: InputMaybe<
    Array<DeceasedUpsertWithWhereUniqueWithoutPlaceOfDeathAddressInput>
  >;
};

export type DeceasedUpdateOneRequiredWithoutContactDeceasedNestedInput = {
  connect?: InputMaybe<DeceasedWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeceasedCreateOrConnectWithoutContactDeceasedInput>;
  create?: InputMaybe<DeceasedCreateWithoutContactDeceasedInput>;
  update?: InputMaybe<DeceasedUpdateToOneWithWhereWithoutContactDeceasedInput>;
  upsert?: InputMaybe<DeceasedUpsertWithoutContactDeceasedInput>;
};

export type DeceasedUpdateOneWithoutEstateNestedInput = {
  connect?: InputMaybe<DeceasedWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeceasedCreateOrConnectWithoutEstateInput>;
  create?: InputMaybe<DeceasedCreateWithoutEstateInput>;
  delete?: InputMaybe<DeceasedWhereInput>;
  disconnect?: InputMaybe<DeceasedWhereInput>;
  update?: InputMaybe<DeceasedUpdateToOneWithWhereWithoutEstateInput>;
  upsert?: InputMaybe<DeceasedUpsertWithoutEstateInput>;
};

export type DeceasedUpdateOneWithoutEstateNestedInput2 = {
  connect?: InputMaybe<DeceasedWhereUniqueInput2>;
  create?: InputMaybe<DeceasedCreateWithoutEstateInput2>;
};

export type DeceasedUpdateToOneWithWhereWithoutContactDeceasedInput = {
  data: DeceasedUpdateWithoutContactDeceasedInput;
  where?: InputMaybe<DeceasedWhereInput>;
};

export type DeceasedUpdateToOneWithWhereWithoutEstateInput = {
  data: DeceasedUpdateWithoutEstateInput;
  where?: InputMaybe<DeceasedWhereInput>;
};

export type DeceasedUpdateWithWhereUniqueWithoutLastKnownAddressInput = {
  data: DeceasedUpdateWithoutLastKnownAddressInput;
  where: DeceasedWhereUniqueInput;
};

export type DeceasedUpdateWithWhereUniqueWithoutPlaceOfDeathAddressInput = {
  data: DeceasedUpdateWithoutPlaceOfDeathAddressInput;
  where: DeceasedWhereUniqueInput;
};

export type DeceasedUpdateWithoutContactDeceasedInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  citizenship?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dateOfDeath?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutDeceasedNestedInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastKnownAddress?: InputMaybe<AddressUpdateOneWithoutDeceasedNestedInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  maritalStatus?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nameOfHospital?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  placeOfDeathAddress?: InputMaybe<AddressUpdateOneWithoutPodDeceasedNestedInput>;
  podAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podCounty?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podZip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referringPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  veteranStatus?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type DeceasedUpdateWithoutEstateInput = {
  ContactDeceased?: InputMaybe<DeceasedContactsUpdateManyWithoutDeceasedNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  citizenship?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dateOfDeath?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastKnownAddress?: InputMaybe<AddressUpdateOneWithoutDeceasedNestedInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  maritalStatus?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nameOfHospital?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  placeOfDeathAddress?: InputMaybe<AddressUpdateOneWithoutPodDeceasedNestedInput>;
  podAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podCounty?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podZip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referringPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  veteranStatus?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type DeceasedUpdateWithoutLastKnownAddressInput = {
  ContactDeceased?: InputMaybe<DeceasedContactsUpdateManyWithoutDeceasedNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  citizenship?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dateOfDeath?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutDeceasedNestedInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  maritalStatus?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nameOfHospital?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  placeOfDeathAddress?: InputMaybe<AddressUpdateOneWithoutPodDeceasedNestedInput>;
  podAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podCounty?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podZip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referringPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  veteranStatus?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type DeceasedUpdateWithoutPlaceOfDeathAddressInput = {
  ContactDeceased?: InputMaybe<DeceasedContactsUpdateManyWithoutDeceasedNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  citizenship?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dateOfDeath?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutDeceasedNestedInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastKnownAddress?: InputMaybe<AddressUpdateOneWithoutDeceasedNestedInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  maritalStatus?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  nameOfHospital?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podCounty?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  podZip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  referringPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  veteranStatus?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type DeceasedUpsertWithWhereUniqueWithoutLastKnownAddressInput = {
  create: DeceasedCreateWithoutLastKnownAddressInput;
  update: DeceasedUpdateWithoutLastKnownAddressInput;
  where: DeceasedWhereUniqueInput;
};

export type DeceasedUpsertWithWhereUniqueWithoutPlaceOfDeathAddressInput = {
  create: DeceasedCreateWithoutPlaceOfDeathAddressInput;
  update: DeceasedUpdateWithoutPlaceOfDeathAddressInput;
  where: DeceasedWhereUniqueInput;
};

export type DeceasedUpsertWithoutContactDeceasedInput = {
  create: DeceasedCreateWithoutContactDeceasedInput;
  update: DeceasedUpdateWithoutContactDeceasedInput;
  where?: InputMaybe<DeceasedWhereInput>;
};

export type DeceasedUpsertWithoutEstateInput = {
  create: DeceasedCreateWithoutEstateInput;
  update: DeceasedUpdateWithoutEstateInput;
  where?: InputMaybe<DeceasedWhereInput>;
};

export type DeceasedWhereInput = {
  AND?: InputMaybe<Array<DeceasedWhereInput>>;
  ContactDeceased?: InputMaybe<DeceasedContactsListRelationFilter>;
  NOT?: InputMaybe<Array<DeceasedWhereInput>>;
  OR?: InputMaybe<Array<DeceasedWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  citizenship?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  county?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableFilter>;
  dateOfDeath?: InputMaybe<DateTimeNullableFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastKnowAddressId?: InputMaybe<StringNullableFilter>;
  lastKnownAddress?: InputMaybe<AddressNullableRelationFilter>;
  lastName?: InputMaybe<StringFilter>;
  maritalStatus?: InputMaybe<StringNullableFilter>;
  middleName?: InputMaybe<StringNullableFilter>;
  nameOfHospital?: InputMaybe<StringNullableFilter>;
  placeOfDeathAddress?: InputMaybe<AddressNullableRelationFilter>;
  podAddress?: InputMaybe<StringNullableFilter>;
  podAddressId?: InputMaybe<StringNullableFilter>;
  podCity?: InputMaybe<StringNullableFilter>;
  podCountry?: InputMaybe<StringNullableFilter>;
  podCounty?: InputMaybe<StringNullableFilter>;
  podState?: InputMaybe<StringNullableFilter>;
  podZip?: InputMaybe<StringNullableFilter>;
  referringPartner?: InputMaybe<StringNullableFilter>;
  sex?: InputMaybe<EnumSexNullableFilter>;
  sfx?: InputMaybe<EnumSfxNullableFilter>;
  state?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  veteranStatus?: InputMaybe<BoolNullableFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type DeceasedWhereUniqueInput = {
  AND?: InputMaybe<Array<DeceasedWhereInput>>;
  ContactDeceased?: InputMaybe<DeceasedContactsListRelationFilter>;
  NOT?: InputMaybe<Array<DeceasedWhereInput>>;
  OR?: InputMaybe<Array<DeceasedWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  citizenship?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  county?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableFilter>;
  dateOfDeath?: InputMaybe<DateTimeNullableFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastKnowAddressId?: InputMaybe<StringNullableFilter>;
  lastKnownAddress?: InputMaybe<AddressNullableRelationFilter>;
  lastName?: InputMaybe<StringFilter>;
  maritalStatus?: InputMaybe<StringNullableFilter>;
  middleName?: InputMaybe<StringNullableFilter>;
  nameOfHospital?: InputMaybe<StringNullableFilter>;
  placeOfDeathAddress?: InputMaybe<AddressNullableRelationFilter>;
  podAddress?: InputMaybe<StringNullableFilter>;
  podAddressId?: InputMaybe<StringNullableFilter>;
  podCity?: InputMaybe<StringNullableFilter>;
  podCountry?: InputMaybe<StringNullableFilter>;
  podCounty?: InputMaybe<StringNullableFilter>;
  podState?: InputMaybe<StringNullableFilter>;
  podZip?: InputMaybe<StringNullableFilter>;
  referringPartner?: InputMaybe<StringNullableFilter>;
  sex?: InputMaybe<EnumSexNullableFilter>;
  sfx?: InputMaybe<EnumSfxNullableFilter>;
  state?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  veteranStatus?: InputMaybe<BoolNullableFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type DeceasedWhereUniqueInput2 = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type DecimalFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Decimal"]["input"]>;
  divide?: InputMaybe<Scalars["Decimal"]["input"]>;
  increment?: InputMaybe<Scalars["Decimal"]["input"]>;
  multiply?: InputMaybe<Scalars["Decimal"]["input"]>;
  set?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export type DecimalFilter = {
  equals?: InputMaybe<Scalars["Decimal"]["input"]>;
  gt?: InputMaybe<Scalars["Decimal"]["input"]>;
  gte?: InputMaybe<Scalars["Decimal"]["input"]>;
  in?: InputMaybe<Array<Scalars["Decimal"]["input"]>>;
  lt?: InputMaybe<Scalars["Decimal"]["input"]>;
  lte?: InputMaybe<Scalars["Decimal"]["input"]>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars["Decimal"]["input"]>>;
};

export type DecimalNullableFilter = {
  equals?: InputMaybe<Scalars["Decimal"]["input"]>;
  gt?: InputMaybe<Scalars["Decimal"]["input"]>;
  gte?: InputMaybe<Scalars["Decimal"]["input"]>;
  in?: InputMaybe<Array<Scalars["Decimal"]["input"]>>;
  lt?: InputMaybe<Scalars["Decimal"]["input"]>;
  lte?: InputMaybe<Scalars["Decimal"]["input"]>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Decimal"]["input"]>>;
};

export type DecimalNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDecimalNullableFilter>;
  _min?: InputMaybe<NestedDecimalNullableFilter>;
  _sum?: InputMaybe<NestedDecimalNullableFilter>;
  equals?: InputMaybe<Scalars["Decimal"]["input"]>;
  gt?: InputMaybe<Scalars["Decimal"]["input"]>;
  gte?: InputMaybe<Scalars["Decimal"]["input"]>;
  in?: InputMaybe<Array<Scalars["Decimal"]["input"]>>;
  lt?: InputMaybe<Scalars["Decimal"]["input"]>;
  lte?: InputMaybe<Scalars["Decimal"]["input"]>;
  not?: InputMaybe<NestedDecimalNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["Decimal"]["input"]>>;
};

export type DecimalWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDecimalFilter>;
  _min?: InputMaybe<NestedDecimalFilter>;
  _sum?: InputMaybe<NestedDecimalFilter>;
  equals?: InputMaybe<Scalars["Decimal"]["input"]>;
  gt?: InputMaybe<Scalars["Decimal"]["input"]>;
  gte?: InputMaybe<Scalars["Decimal"]["input"]>;
  in?: InputMaybe<Array<Scalars["Decimal"]["input"]>>;
  lt?: InputMaybe<Scalars["Decimal"]["input"]>;
  lte?: InputMaybe<Scalars["Decimal"]["input"]>;
  not?: InputMaybe<NestedDecimalWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["Decimal"]["input"]>>;
};

export type DeleteUserNotificationsInput = {
  estateId: Scalars["String"]["input"];
  estateNotificationId: Scalars["String"]["input"];
};

export type DeleteUserNotificationsResult = {
  __typename?: "DeleteUserNotificationsResult";
  estateId: Scalars["String"]["output"];
  estateNotificationId: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type DeviceRegistration = {
  __typename?: "DeviceRegistration";
  createdAt: Scalars["DateTimeISO"]["output"];
  deviceToken: Scalars["String"]["output"];
  endpoint?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
};

export type DeviceRegistration2 = {
  __typename?: "DeviceRegistration2";
  createdAt: Scalars["DateTimeISO"]["output"];
  deviceToken: Scalars["String"]["output"];
  endpoint?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  userId: Scalars["String"]["output"];
};

export type DeviceRegistrationCountAggregate = {
  __typename?: "DeviceRegistrationCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  deviceToken: Scalars["Int"]["output"];
  endpoint: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  userId: Scalars["Int"]["output"];
};

export type DeviceRegistrationCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  deviceToken?: InputMaybe<SortOrder>;
  endpoint?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type DeviceRegistrationCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  deviceToken: Scalars["String"]["input"];
  endpoint?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user: UserCreateNestedOneWithoutDeviceRegistrationInput;
};

export type DeviceRegistrationCreateManyInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  deviceToken: Scalars["String"]["input"];
  endpoint?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type DeviceRegistrationCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<DeviceRegistrationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeviceRegistrationCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<DeviceRegistrationCreateWithoutUserInput>;
};

export type DeviceRegistrationCreateOrConnectWithoutUserInput = {
  create: DeviceRegistrationCreateWithoutUserInput;
  where: DeviceRegistrationWhereUniqueInput;
};

export type DeviceRegistrationCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  deviceToken: Scalars["String"]["input"];
  endpoint?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type DeviceRegistrationGroupBy = {
  __typename?: "DeviceRegistrationGroupBy";
  _count?: Maybe<DeviceRegistrationCountAggregate>;
  _max?: Maybe<DeviceRegistrationMaxAggregate>;
  _min?: Maybe<DeviceRegistrationMinAggregate>;
  createdAt: Scalars["DateTimeISO"]["output"];
  deviceToken: Scalars["String"]["output"];
  endpoint?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  userId: Scalars["String"]["output"];
};

export type DeviceRegistrationMaxAggregate = {
  __typename?: "DeviceRegistrationMaxAggregate";
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  deviceToken?: Maybe<Scalars["String"]["output"]>;
  endpoint?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type DeviceRegistrationMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  deviceToken?: InputMaybe<SortOrder>;
  endpoint?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type DeviceRegistrationMinAggregate = {
  __typename?: "DeviceRegistrationMinAggregate";
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  deviceToken?: Maybe<Scalars["String"]["output"]>;
  endpoint?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type DeviceRegistrationMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  deviceToken?: InputMaybe<SortOrder>;
  endpoint?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type DeviceRegistrationNullableRelationFilter = {
  is?: InputMaybe<DeviceRegistrationWhereInput>;
  isNot?: InputMaybe<DeviceRegistrationWhereInput>;
};

export type DeviceRegistrationOrderByWithAggregationInput = {
  _count?: InputMaybe<DeviceRegistrationCountOrderByAggregateInput>;
  _max?: InputMaybe<DeviceRegistrationMaxOrderByAggregateInput>;
  _min?: InputMaybe<DeviceRegistrationMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  deviceToken?: InputMaybe<SortOrder>;
  endpoint?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type DeviceRegistrationOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  deviceToken?: InputMaybe<SortOrder>;
  endpoint?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum DeviceRegistrationScalarFieldEnum {
  CreatedAt = "createdAt",
  DeviceToken = "deviceToken",
  Endpoint = "endpoint",
  Id = "id",
  UpdatedAt = "updatedAt",
  UserId = "userId",
}

export type DeviceRegistrationScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<DeviceRegistrationScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<DeviceRegistrationScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<DeviceRegistrationScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  deviceToken?: InputMaybe<StringWithAggregatesFilter>;
  endpoint?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type DeviceRegistrationUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deviceToken?: InputMaybe<StringFieldUpdateOperationsInput>;
  endpoint?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDeviceRegistrationNestedInput>;
};

export type DeviceRegistrationUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deviceToken?: InputMaybe<StringFieldUpdateOperationsInput>;
  endpoint?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceRegistrationUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<DeviceRegistrationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeviceRegistrationCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<DeviceRegistrationCreateWithoutUserInput>;
  delete?: InputMaybe<DeviceRegistrationWhereInput>;
  disconnect?: InputMaybe<DeviceRegistrationWhereInput>;
  update?: InputMaybe<DeviceRegistrationUpdateToOneWithWhereWithoutUserInput>;
  upsert?: InputMaybe<DeviceRegistrationUpsertWithoutUserInput>;
};

export type DeviceRegistrationUpdateToOneWithWhereWithoutUserInput = {
  data: DeviceRegistrationUpdateWithoutUserInput;
  where?: InputMaybe<DeviceRegistrationWhereInput>;
};

export type DeviceRegistrationUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deviceToken?: InputMaybe<StringFieldUpdateOperationsInput>;
  endpoint?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceRegistrationUpsertWithoutUserInput = {
  create: DeviceRegistrationCreateWithoutUserInput;
  update: DeviceRegistrationUpdateWithoutUserInput;
  where?: InputMaybe<DeviceRegistrationWhereInput>;
};

export type DeviceRegistrationWhereInput = {
  AND?: InputMaybe<Array<DeviceRegistrationWhereInput>>;
  NOT?: InputMaybe<Array<DeviceRegistrationWhereInput>>;
  OR?: InputMaybe<Array<DeviceRegistrationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deviceToken?: InputMaybe<StringFilter>;
  endpoint?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type DeviceRegistrationWhereUniqueInput = {
  AND?: InputMaybe<Array<DeviceRegistrationWhereInput>>;
  NOT?: InputMaybe<Array<DeviceRegistrationWhereInput>>;
  OR?: InputMaybe<Array<DeviceRegistrationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deviceToken?: InputMaybe<StringFilter>;
  endpoint?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type DocumentProcessed2 = {
  __typename?: "DocumentProcessed2";
  batchId: Scalars["String"]["output"];
  createdAt: Scalars["DateTimeISO"]["output"];
  estateId: Scalars["String"]["output"];
  filename: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  sha1?: Maybe<Scalars["String"]["output"]>;
  status: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type DocumentsProcessed = {
  __typename?: "DocumentsProcessed";
  batchId: Scalars["Int"]["output"];
  createdAt: Scalars["DateTimeISO"]["output"];
  estate: Estate;
  estateId: Scalars["String"]["output"];
  filename: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  sha1: Scalars["String"]["output"];
  status: ProcessingStatus;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type DocumentsProcessedAvgAggregate = {
  __typename?: "DocumentsProcessedAvgAggregate";
  batchId?: Maybe<Scalars["Float"]["output"]>;
};

export type DocumentsProcessedAvgOrderByAggregateInput = {
  batchId?: InputMaybe<SortOrder>;
};

export type DocumentsProcessedCountAggregate = {
  __typename?: "DocumentsProcessedCountAggregate";
  _all: Scalars["Int"]["output"];
  batchId: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  estateId: Scalars["Int"]["output"];
  filename: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  sha1: Scalars["Int"]["output"];
  status: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type DocumentsProcessedCountOrderByAggregateInput = {
  batchId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  filename?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sha1?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DocumentsProcessedCreateInput = {
  batchId?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estate: EstateCreateNestedOneWithoutDocumentsProcessedInput;
  filename?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  sha1?: InputMaybe<Scalars["String"]["input"]>;
  status: ProcessingStatus;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type DocumentsProcessedCreateManyEstateInput = {
  batchId?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  filename?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  sha1?: InputMaybe<Scalars["String"]["input"]>;
  status: ProcessingStatus;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type DocumentsProcessedCreateManyEstateInputEnvelope = {
  data: Array<DocumentsProcessedCreateManyEstateInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DocumentsProcessedCreateManyInput = {
  batchId?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estateId: Scalars["String"]["input"];
  filename?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  sha1?: InputMaybe<Scalars["String"]["input"]>;
  status: ProcessingStatus;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type DocumentsProcessedCreateNestedManyWithoutEstateInput = {
  connect?: InputMaybe<Array<DocumentsProcessedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DocumentsProcessedCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<DocumentsProcessedCreateWithoutEstateInput>>;
  createMany?: InputMaybe<DocumentsProcessedCreateManyEstateInputEnvelope>;
};

export type DocumentsProcessedCreateOrConnectWithoutEstateInput = {
  create: DocumentsProcessedCreateWithoutEstateInput;
  where: DocumentsProcessedWhereUniqueInput;
};

export type DocumentsProcessedCreateWithoutEstateInput = {
  batchId?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  filename?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  sha1?: InputMaybe<Scalars["String"]["input"]>;
  status: ProcessingStatus;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type DocumentsProcessedGroupBy = {
  __typename?: "DocumentsProcessedGroupBy";
  _avg?: Maybe<DocumentsProcessedAvgAggregate>;
  _count?: Maybe<DocumentsProcessedCountAggregate>;
  _max?: Maybe<DocumentsProcessedMaxAggregate>;
  _min?: Maybe<DocumentsProcessedMinAggregate>;
  _sum?: Maybe<DocumentsProcessedSumAggregate>;
  batchId: Scalars["Int"]["output"];
  createdAt: Scalars["DateTimeISO"]["output"];
  estateId: Scalars["String"]["output"];
  filename: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  sha1: Scalars["String"]["output"];
  status: ProcessingStatus;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type DocumentsProcessedListRelationFilter = {
  every?: InputMaybe<DocumentsProcessedWhereInput>;
  none?: InputMaybe<DocumentsProcessedWhereInput>;
  some?: InputMaybe<DocumentsProcessedWhereInput>;
};

export type DocumentsProcessedMaxAggregate = {
  __typename?: "DocumentsProcessedMaxAggregate";
  batchId?: Maybe<Scalars["Int"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  filename?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  sha1?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<ProcessingStatus>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type DocumentsProcessedMaxOrderByAggregateInput = {
  batchId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  filename?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sha1?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DocumentsProcessedMinAggregate = {
  __typename?: "DocumentsProcessedMinAggregate";
  batchId?: Maybe<Scalars["Int"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  filename?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  sha1?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<ProcessingStatus>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type DocumentsProcessedMinOrderByAggregateInput = {
  batchId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  filename?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sha1?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DocumentsProcessedOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DocumentsProcessedOrderByWithAggregationInput = {
  _avg?: InputMaybe<DocumentsProcessedAvgOrderByAggregateInput>;
  _count?: InputMaybe<DocumentsProcessedCountOrderByAggregateInput>;
  _max?: InputMaybe<DocumentsProcessedMaxOrderByAggregateInput>;
  _min?: InputMaybe<DocumentsProcessedMinOrderByAggregateInput>;
  _sum?: InputMaybe<DocumentsProcessedSumOrderByAggregateInput>;
  batchId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  filename?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sha1?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DocumentsProcessedOrderByWithRelationInput = {
  batchId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  estate?: InputMaybe<EstateOrderByWithRelationInput>;
  estateId?: InputMaybe<SortOrder>;
  filename?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sha1?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum DocumentsProcessedScalarFieldEnum {
  BatchId = "batchId",
  CreatedAt = "createdAt",
  EstateId = "estateId",
  Filename = "filename",
  Id = "id",
  Sha1 = "sha1",
  Status = "status",
  UpdatedAt = "updatedAt",
}

export type DocumentsProcessedScalarWhereInput = {
  AND?: InputMaybe<Array<DocumentsProcessedScalarWhereInput>>;
  NOT?: InputMaybe<Array<DocumentsProcessedScalarWhereInput>>;
  OR?: InputMaybe<Array<DocumentsProcessedScalarWhereInput>>;
  batchId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  estateId?: InputMaybe<StringFilter>;
  filename?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  sha1?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumProcessingStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DocumentsProcessedScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<DocumentsProcessedScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<DocumentsProcessedScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<DocumentsProcessedScalarWhereWithAggregatesInput>>;
  batchId?: InputMaybe<IntWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  estateId?: InputMaybe<StringWithAggregatesFilter>;
  filename?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  sha1?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<EnumProcessingStatusWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type DocumentsProcessedSumAggregate = {
  __typename?: "DocumentsProcessedSumAggregate";
  batchId?: Maybe<Scalars["Int"]["output"]>;
};

export type DocumentsProcessedSumOrderByAggregateInput = {
  batchId?: InputMaybe<SortOrder>;
};

export type DocumentsProcessedUpdateInput = {
  batchId?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutDocumentsProcessedNestedInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sha1?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProcessingStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DocumentsProcessedUpdateManyMutationInput = {
  batchId?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sha1?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProcessingStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DocumentsProcessedUpdateManyWithWhereWithoutEstateInput = {
  data: DocumentsProcessedUpdateManyMutationInput;
  where: DocumentsProcessedScalarWhereInput;
};

export type DocumentsProcessedUpdateManyWithoutEstateNestedInput = {
  connect?: InputMaybe<Array<DocumentsProcessedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DocumentsProcessedCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<DocumentsProcessedCreateWithoutEstateInput>>;
  createMany?: InputMaybe<DocumentsProcessedCreateManyEstateInputEnvelope>;
  delete?: InputMaybe<Array<DocumentsProcessedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DocumentsProcessedScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DocumentsProcessedWhereUniqueInput>>;
  set?: InputMaybe<Array<DocumentsProcessedWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DocumentsProcessedUpdateWithWhereUniqueWithoutEstateInput>
  >;
  updateMany?: InputMaybe<
    Array<DocumentsProcessedUpdateManyWithWhereWithoutEstateInput>
  >;
  upsert?: InputMaybe<
    Array<DocumentsProcessedUpsertWithWhereUniqueWithoutEstateInput>
  >;
};

export type DocumentsProcessedUpdateWithWhereUniqueWithoutEstateInput = {
  data: DocumentsProcessedUpdateWithoutEstateInput;
  where: DocumentsProcessedWhereUniqueInput;
};

export type DocumentsProcessedUpdateWithoutEstateInput = {
  batchId?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sha1?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProcessingStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DocumentsProcessedUpsertWithWhereUniqueWithoutEstateInput = {
  create: DocumentsProcessedCreateWithoutEstateInput;
  update: DocumentsProcessedUpdateWithoutEstateInput;
  where: DocumentsProcessedWhereUniqueInput;
};

export type DocumentsProcessedWhereInput = {
  AND?: InputMaybe<Array<DocumentsProcessedWhereInput>>;
  NOT?: InputMaybe<Array<DocumentsProcessedWhereInput>>;
  OR?: InputMaybe<Array<DocumentsProcessedWhereInput>>;
  batchId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  filename?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  sha1?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumProcessingStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DocumentsProcessedWhereUniqueInput = {
  AND?: InputMaybe<Array<DocumentsProcessedWhereInput>>;
  NOT?: InputMaybe<Array<DocumentsProcessedWhereInput>>;
  OR?: InputMaybe<Array<DocumentsProcessedWhereInput>>;
  batchId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  filename?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  sha1?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumProcessingStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Eula = {
  __typename?: "EULA";
  EULAAcceptanceHistories: Array<EulaAcceptanceHistory>;
  _count?: Maybe<EulaCount>;
  content?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  id: Scalars["String"]["output"];
  pdfLink?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  version: Scalars["String"]["output"];
};

export type EulaEulaAcceptanceHistoriesArgs = {
  cursor?: InputMaybe<EulaAcceptanceHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<EulaAcceptanceHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EulaAcceptanceHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EulaAcceptanceHistoryWhereInput>;
};

export type Eula2 = {
  __typename?: "EULA2";
  content?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  id: Scalars["String"]["output"];
  pdfLink?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  version: Scalars["String"]["output"];
};

export type EulaAcceptanceHistory = {
  __typename?: "EULAAcceptanceHistory";
  EULAacceptedAt: Scalars["DateTimeISO"]["output"];
  EULAacceptedIp: Scalars["String"]["output"];
  acceptedEULAVersion: Scalars["String"]["output"];
  createdAt: Scalars["DateTimeISO"]["output"];
  eula: Eula;
  eulaId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
};

export type EulaAcceptanceHistory2 = {
  __typename?: "EULAAcceptanceHistory2";
  EULAacceptedAt: Scalars["DateTimeISO"]["output"];
  EULAacceptedIp: Scalars["String"]["output"];
  acceptedEULAVersion: Scalars["String"]["output"];
  createdAt: Scalars["DateTimeISO"]["output"];
  eulaId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  userId: Scalars["String"]["output"];
};

export type EulaAcceptanceHistoryCountAggregate = {
  __typename?: "EULAAcceptanceHistoryCountAggregate";
  EULAacceptedAt: Scalars["Int"]["output"];
  EULAacceptedIp: Scalars["Int"]["output"];
  _all: Scalars["Int"]["output"];
  acceptedEULAVersion: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  eulaId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  userId: Scalars["Int"]["output"];
};

export type EulaAcceptanceHistoryCountOrderByAggregateInput = {
  EULAacceptedAt?: InputMaybe<SortOrder>;
  EULAacceptedIp?: InputMaybe<SortOrder>;
  acceptedEULAVersion?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  eulaId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type EulaAcceptanceHistoryCreateInput = {
  EULAacceptedAt: Scalars["DateTimeISO"]["input"];
  EULAacceptedIp: Scalars["String"]["input"];
  acceptedEULAVersion: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  eula: EulaCreateNestedOneWithoutEulaAcceptanceHistoriesInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user: UserCreateNestedOneWithoutEulaAcceptanceHistoriesInput;
};

export type EulaAcceptanceHistoryCreateManyEulaInput = {
  EULAacceptedAt: Scalars["DateTimeISO"]["input"];
  EULAacceptedIp: Scalars["String"]["input"];
  acceptedEULAVersion: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type EulaAcceptanceHistoryCreateManyEulaInputEnvelope = {
  data: Array<EulaAcceptanceHistoryCreateManyEulaInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type EulaAcceptanceHistoryCreateManyInput = {
  EULAacceptedAt: Scalars["DateTimeISO"]["input"];
  EULAacceptedIp: Scalars["String"]["input"];
  acceptedEULAVersion: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  eulaId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type EulaAcceptanceHistoryCreateManyUserInput = {
  EULAacceptedAt: Scalars["DateTimeISO"]["input"];
  EULAacceptedIp: Scalars["String"]["input"];
  acceptedEULAVersion: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  eulaId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type EulaAcceptanceHistoryCreateManyUserInputEnvelope = {
  data: Array<EulaAcceptanceHistoryCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type EulaAcceptanceHistoryCreateNestedManyWithoutEulaInput = {
  connect?: InputMaybe<Array<EulaAcceptanceHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EulaAcceptanceHistoryCreateOrConnectWithoutEulaInput>
  >;
  create?: InputMaybe<Array<EulaAcceptanceHistoryCreateWithoutEulaInput>>;
  createMany?: InputMaybe<EulaAcceptanceHistoryCreateManyEulaInputEnvelope>;
};

export type EulaAcceptanceHistoryCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<EulaAcceptanceHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EulaAcceptanceHistoryCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<EulaAcceptanceHistoryCreateWithoutUserInput>>;
  createMany?: InputMaybe<EulaAcceptanceHistoryCreateManyUserInputEnvelope>;
};

export type EulaAcceptanceHistoryCreateOrConnectWithoutEulaInput = {
  create: EulaAcceptanceHistoryCreateWithoutEulaInput;
  where: EulaAcceptanceHistoryWhereUniqueInput;
};

export type EulaAcceptanceHistoryCreateOrConnectWithoutUserInput = {
  create: EulaAcceptanceHistoryCreateWithoutUserInput;
  where: EulaAcceptanceHistoryWhereUniqueInput;
};

export type EulaAcceptanceHistoryCreateWithoutEulaInput = {
  EULAacceptedAt: Scalars["DateTimeISO"]["input"];
  EULAacceptedIp: Scalars["String"]["input"];
  acceptedEULAVersion: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user: UserCreateNestedOneWithoutEulaAcceptanceHistoriesInput;
};

export type EulaAcceptanceHistoryCreateWithoutUserInput = {
  EULAacceptedAt: Scalars["DateTimeISO"]["input"];
  EULAacceptedIp: Scalars["String"]["input"];
  acceptedEULAVersion: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  eula: EulaCreateNestedOneWithoutEulaAcceptanceHistoriesInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type EulaAcceptanceHistoryGroupBy = {
  __typename?: "EULAAcceptanceHistoryGroupBy";
  EULAacceptedAt: Scalars["DateTimeISO"]["output"];
  EULAacceptedIp: Scalars["String"]["output"];
  _count?: Maybe<EulaAcceptanceHistoryCountAggregate>;
  _max?: Maybe<EulaAcceptanceHistoryMaxAggregate>;
  _min?: Maybe<EulaAcceptanceHistoryMinAggregate>;
  acceptedEULAVersion: Scalars["String"]["output"];
  createdAt: Scalars["DateTimeISO"]["output"];
  eulaId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  userId: Scalars["String"]["output"];
};

export type EulaAcceptanceHistoryListRelationFilter = {
  every?: InputMaybe<EulaAcceptanceHistoryWhereInput>;
  none?: InputMaybe<EulaAcceptanceHistoryWhereInput>;
  some?: InputMaybe<EulaAcceptanceHistoryWhereInput>;
};

export type EulaAcceptanceHistoryMaxAggregate = {
  __typename?: "EULAAcceptanceHistoryMaxAggregate";
  EULAacceptedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  EULAacceptedIp?: Maybe<Scalars["String"]["output"]>;
  acceptedEULAVersion?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  eulaId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type EulaAcceptanceHistoryMaxOrderByAggregateInput = {
  EULAacceptedAt?: InputMaybe<SortOrder>;
  EULAacceptedIp?: InputMaybe<SortOrder>;
  acceptedEULAVersion?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  eulaId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type EulaAcceptanceHistoryMinAggregate = {
  __typename?: "EULAAcceptanceHistoryMinAggregate";
  EULAacceptedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  EULAacceptedIp?: Maybe<Scalars["String"]["output"]>;
  acceptedEULAVersion?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  eulaId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type EulaAcceptanceHistoryMinOrderByAggregateInput = {
  EULAacceptedAt?: InputMaybe<SortOrder>;
  EULAacceptedIp?: InputMaybe<SortOrder>;
  acceptedEULAVersion?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  eulaId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type EulaAcceptanceHistoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EulaAcceptanceHistoryOrderByWithAggregationInput = {
  EULAacceptedAt?: InputMaybe<SortOrder>;
  EULAacceptedIp?: InputMaybe<SortOrder>;
  _count?: InputMaybe<EulaAcceptanceHistoryCountOrderByAggregateInput>;
  _max?: InputMaybe<EulaAcceptanceHistoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<EulaAcceptanceHistoryMinOrderByAggregateInput>;
  acceptedEULAVersion?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  eulaId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type EulaAcceptanceHistoryOrderByWithRelationInput = {
  EULAacceptedAt?: InputMaybe<SortOrder>;
  EULAacceptedIp?: InputMaybe<SortOrder>;
  acceptedEULAVersion?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  eula?: InputMaybe<EulaOrderByWithRelationInput>;
  eulaId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum EulaAcceptanceHistoryScalarFieldEnum {
  EulAacceptedAt = "EULAacceptedAt",
  EulAacceptedIp = "EULAacceptedIp",
  AcceptedEulaVersion = "acceptedEULAVersion",
  CreatedAt = "createdAt",
  EulaId = "eulaId",
  Id = "id",
  UpdatedAt = "updatedAt",
  UserId = "userId",
}

export type EulaAcceptanceHistoryScalarWhereInput = {
  AND?: InputMaybe<Array<EulaAcceptanceHistoryScalarWhereInput>>;
  EULAacceptedAt?: InputMaybe<DateTimeFilter>;
  EULAacceptedIp?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<EulaAcceptanceHistoryScalarWhereInput>>;
  OR?: InputMaybe<Array<EulaAcceptanceHistoryScalarWhereInput>>;
  acceptedEULAVersion?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eulaId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type EulaAcceptanceHistoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<EulaAcceptanceHistoryScalarWhereWithAggregatesInput>>;
  EULAacceptedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  EULAacceptedIp?: InputMaybe<StringWithAggregatesFilter>;
  NOT?: InputMaybe<Array<EulaAcceptanceHistoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<EulaAcceptanceHistoryScalarWhereWithAggregatesInput>>;
  acceptedEULAVersion?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  eulaId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type EulaAcceptanceHistoryUpdateInput = {
  EULAacceptedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  EULAacceptedIp?: InputMaybe<StringFieldUpdateOperationsInput>;
  acceptedEULAVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eula?: InputMaybe<EulaUpdateOneRequiredWithoutEulaAcceptanceHistoriesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutEulaAcceptanceHistoriesNestedInput>;
};

export type EulaAcceptanceHistoryUpdateManyMutationInput = {
  EULAacceptedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  EULAacceptedIp?: InputMaybe<StringFieldUpdateOperationsInput>;
  acceptedEULAVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EulaAcceptanceHistoryUpdateManyWithWhereWithoutEulaInput = {
  data: EulaAcceptanceHistoryUpdateManyMutationInput;
  where: EulaAcceptanceHistoryScalarWhereInput;
};

export type EulaAcceptanceHistoryUpdateManyWithWhereWithoutUserInput = {
  data: EulaAcceptanceHistoryUpdateManyMutationInput;
  where: EulaAcceptanceHistoryScalarWhereInput;
};

export type EulaAcceptanceHistoryUpdateManyWithoutEulaNestedInput = {
  connect?: InputMaybe<Array<EulaAcceptanceHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EulaAcceptanceHistoryCreateOrConnectWithoutEulaInput>
  >;
  create?: InputMaybe<Array<EulaAcceptanceHistoryCreateWithoutEulaInput>>;
  createMany?: InputMaybe<EulaAcceptanceHistoryCreateManyEulaInputEnvelope>;
  delete?: InputMaybe<Array<EulaAcceptanceHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EulaAcceptanceHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EulaAcceptanceHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<EulaAcceptanceHistoryWhereUniqueInput>>;
  update?: InputMaybe<
    Array<EulaAcceptanceHistoryUpdateWithWhereUniqueWithoutEulaInput>
  >;
  updateMany?: InputMaybe<
    Array<EulaAcceptanceHistoryUpdateManyWithWhereWithoutEulaInput>
  >;
  upsert?: InputMaybe<
    Array<EulaAcceptanceHistoryUpsertWithWhereUniqueWithoutEulaInput>
  >;
};

export type EulaAcceptanceHistoryUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<EulaAcceptanceHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EulaAcceptanceHistoryCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<EulaAcceptanceHistoryCreateWithoutUserInput>>;
  createMany?: InputMaybe<EulaAcceptanceHistoryCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<EulaAcceptanceHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EulaAcceptanceHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EulaAcceptanceHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<EulaAcceptanceHistoryWhereUniqueInput>>;
  update?: InputMaybe<
    Array<EulaAcceptanceHistoryUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<EulaAcceptanceHistoryUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<EulaAcceptanceHistoryUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type EulaAcceptanceHistoryUpdateWithWhereUniqueWithoutEulaInput = {
  data: EulaAcceptanceHistoryUpdateWithoutEulaInput;
  where: EulaAcceptanceHistoryWhereUniqueInput;
};

export type EulaAcceptanceHistoryUpdateWithWhereUniqueWithoutUserInput = {
  data: EulaAcceptanceHistoryUpdateWithoutUserInput;
  where: EulaAcceptanceHistoryWhereUniqueInput;
};

export type EulaAcceptanceHistoryUpdateWithoutEulaInput = {
  EULAacceptedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  EULAacceptedIp?: InputMaybe<StringFieldUpdateOperationsInput>;
  acceptedEULAVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutEulaAcceptanceHistoriesNestedInput>;
};

export type EulaAcceptanceHistoryUpdateWithoutUserInput = {
  EULAacceptedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  EULAacceptedIp?: InputMaybe<StringFieldUpdateOperationsInput>;
  acceptedEULAVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eula?: InputMaybe<EulaUpdateOneRequiredWithoutEulaAcceptanceHistoriesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EulaAcceptanceHistoryUpsertWithWhereUniqueWithoutEulaInput = {
  create: EulaAcceptanceHistoryCreateWithoutEulaInput;
  update: EulaAcceptanceHistoryUpdateWithoutEulaInput;
  where: EulaAcceptanceHistoryWhereUniqueInput;
};

export type EulaAcceptanceHistoryUpsertWithWhereUniqueWithoutUserInput = {
  create: EulaAcceptanceHistoryCreateWithoutUserInput;
  update: EulaAcceptanceHistoryUpdateWithoutUserInput;
  where: EulaAcceptanceHistoryWhereUniqueInput;
};

export type EulaAcceptanceHistoryWhereInput = {
  AND?: InputMaybe<Array<EulaAcceptanceHistoryWhereInput>>;
  EULAacceptedAt?: InputMaybe<DateTimeFilter>;
  EULAacceptedIp?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<EulaAcceptanceHistoryWhereInput>>;
  OR?: InputMaybe<Array<EulaAcceptanceHistoryWhereInput>>;
  acceptedEULAVersion?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eula?: InputMaybe<EulaRelationFilter>;
  eulaId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type EulaAcceptanceHistoryWhereUniqueInput = {
  AND?: InputMaybe<Array<EulaAcceptanceHistoryWhereInput>>;
  EULAacceptedAt?: InputMaybe<DateTimeFilter>;
  EULAacceptedIp?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<EulaAcceptanceHistoryWhereInput>>;
  OR?: InputMaybe<Array<EulaAcceptanceHistoryWhereInput>>;
  acceptedEULAVersion?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eula?: InputMaybe<EulaRelationFilter>;
  eulaId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type EulaCount = {
  __typename?: "EULACount";
  EULAAcceptanceHistories: Scalars["Int"]["output"];
};

export type EulaCountEulaAcceptanceHistoriesArgs = {
  where?: InputMaybe<EulaAcceptanceHistoryWhereInput>;
};

export type EulaCountAggregate = {
  __typename?: "EULACountAggregate";
  _all: Scalars["Int"]["output"];
  content: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  pdfLink: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  version: Scalars["Int"]["output"];
};

export type EulaCountOrderByAggregateInput = {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pdfLink?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  version?: InputMaybe<SortOrder>;
};

export type EulaCreateInput = {
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryCreateNestedManyWithoutEulaInput>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  pdfLink?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  version: Scalars["String"]["input"];
};

export type EulaCreateManyInput = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  pdfLink?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  version: Scalars["String"]["input"];
};

export type EulaCreateNestedOneWithoutEulaAcceptanceHistoriesInput = {
  connect?: InputMaybe<EulaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EulaCreateOrConnectWithoutEulaAcceptanceHistoriesInput>;
  create?: InputMaybe<EulaCreateWithoutEulaAcceptanceHistoriesInput>;
};

export type EulaCreateOrConnectWithoutEulaAcceptanceHistoriesInput = {
  create: EulaCreateWithoutEulaAcceptanceHistoriesInput;
  where: EulaWhereUniqueInput;
};

export type EulaCreateWithoutEulaAcceptanceHistoriesInput = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  pdfLink?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  version: Scalars["String"]["input"];
};

export type EulaGroupBy = {
  __typename?: "EULAGroupBy";
  _count?: Maybe<EulaCountAggregate>;
  _max?: Maybe<EulaMaxAggregate>;
  _min?: Maybe<EulaMinAggregate>;
  content?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  id: Scalars["String"]["output"];
  pdfLink?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  version: Scalars["String"]["output"];
};

export type EulaMaxAggregate = {
  __typename?: "EULAMaxAggregate";
  content?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  pdfLink?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  version?: Maybe<Scalars["String"]["output"]>;
};

export type EulaMaxOrderByAggregateInput = {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pdfLink?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  version?: InputMaybe<SortOrder>;
};

export type EulaMinAggregate = {
  __typename?: "EULAMinAggregate";
  content?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  pdfLink?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  version?: Maybe<Scalars["String"]["output"]>;
};

export type EulaMinOrderByAggregateInput = {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pdfLink?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  version?: InputMaybe<SortOrder>;
};

export type EulaOrderByWithAggregationInput = {
  _count?: InputMaybe<EulaCountOrderByAggregateInput>;
  _max?: InputMaybe<EulaMaxOrderByAggregateInput>;
  _min?: InputMaybe<EulaMinOrderByAggregateInput>;
  content?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pdfLink?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  version?: InputMaybe<SortOrder>;
};

export type EulaOrderByWithRelationInput = {
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryOrderByRelationAggregateInput>;
  content?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pdfLink?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  version?: InputMaybe<SortOrder>;
};

export type EulaRelationFilter = {
  is?: InputMaybe<EulaWhereInput>;
  isNot?: InputMaybe<EulaWhereInput>;
};

export enum EulaScalarFieldEnum {
  Content = "content",
  CreatedAt = "createdAt",
  Id = "id",
  PdfLink = "pdfLink",
  UpdatedAt = "updatedAt",
  Version = "version",
}

export type EulaScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<EulaScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<EulaScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<EulaScalarWhereWithAggregatesInput>>;
  content?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  pdfLink?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  version?: InputMaybe<StringWithAggregatesFilter>;
};

export type EulaUpdateInput = {
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryUpdateManyWithoutEulaNestedInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  pdfLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  version?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EulaUpdateManyMutationInput = {
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  pdfLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  version?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EulaUpdateOneRequiredWithoutEulaAcceptanceHistoriesNestedInput = {
  connect?: InputMaybe<EulaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EulaCreateOrConnectWithoutEulaAcceptanceHistoriesInput>;
  create?: InputMaybe<EulaCreateWithoutEulaAcceptanceHistoriesInput>;
  update?: InputMaybe<EulaUpdateToOneWithWhereWithoutEulaAcceptanceHistoriesInput>;
  upsert?: InputMaybe<EulaUpsertWithoutEulaAcceptanceHistoriesInput>;
};

export type EulaUpdateToOneWithWhereWithoutEulaAcceptanceHistoriesInput = {
  data: EulaUpdateWithoutEulaAcceptanceHistoriesInput;
  where?: InputMaybe<EulaWhereInput>;
};

export type EulaUpdateWithoutEulaAcceptanceHistoriesInput = {
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  pdfLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  version?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EulaUpsertWithoutEulaAcceptanceHistoriesInput = {
  create: EulaCreateWithoutEulaAcceptanceHistoriesInput;
  update: EulaUpdateWithoutEulaAcceptanceHistoriesInput;
  where?: InputMaybe<EulaWhereInput>;
};

export type EulaWhereInput = {
  AND?: InputMaybe<Array<EulaWhereInput>>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryListRelationFilter>;
  NOT?: InputMaybe<Array<EulaWhereInput>>;
  OR?: InputMaybe<Array<EulaWhereInput>>;
  content?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  pdfLink?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  version?: InputMaybe<StringFilter>;
};

export type EulaWhereUniqueInput = {
  AND?: InputMaybe<Array<EulaWhereInput>>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryListRelationFilter>;
  NOT?: InputMaybe<Array<EulaWhereInput>>;
  OR?: InputMaybe<Array<EulaWhereInput>>;
  content?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  pdfLink?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type EditCareTeamNoteInput = {
  message: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

export type EnumActionCardStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ActionCardStatus>;
};

export type EnumActionCardStatusFilter = {
  equals?: InputMaybe<ActionCardStatus>;
  in?: InputMaybe<Array<ActionCardStatus>>;
  not?: InputMaybe<NestedEnumActionCardStatusFilter>;
  notIn?: InputMaybe<Array<ActionCardStatus>>;
};

export type EnumActionCardStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumActionCardStatusFilter>;
  _min?: InputMaybe<NestedEnumActionCardStatusFilter>;
  equals?: InputMaybe<ActionCardStatus>;
  in?: InputMaybe<Array<ActionCardStatus>>;
  not?: InputMaybe<NestedEnumActionCardStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ActionCardStatus>>;
};

export type EnumActionCardTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ActionCardType>;
};

export type EnumActionCardTypeFilter = {
  equals?: InputMaybe<ActionCardType>;
  in?: InputMaybe<Array<ActionCardType>>;
  not?: InputMaybe<NestedEnumActionCardTypeFilter>;
  notIn?: InputMaybe<Array<ActionCardType>>;
};

export type EnumActionCardTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumActionCardTypeFilter>;
  _min?: InputMaybe<NestedEnumActionCardTypeFilter>;
  equals?: InputMaybe<ActionCardType>;
  in?: InputMaybe<Array<ActionCardType>>;
  not?: InputMaybe<NestedEnumActionCardTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ActionCardType>>;
};

export type EnumAssetCategoryFieldUpdateOperationsInput = {
  set?: InputMaybe<AssetCategory>;
};

export type EnumAssetCategoryFilter = {
  equals?: InputMaybe<AssetCategory>;
  in?: InputMaybe<Array<AssetCategory>>;
  not?: InputMaybe<NestedEnumAssetCategoryFilter>;
  notIn?: InputMaybe<Array<AssetCategory>>;
};

export type EnumAssetCategoryWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumAssetCategoryFilter>;
  _min?: InputMaybe<NestedEnumAssetCategoryFilter>;
  equals?: InputMaybe<AssetCategory>;
  in?: InputMaybe<Array<AssetCategory>>;
  not?: InputMaybe<NestedEnumAssetCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<AssetCategory>>;
};

export type EnumAssetStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<AssetStatus>;
};

export type EnumAssetStatusFilter = {
  equals?: InputMaybe<AssetStatus>;
  in?: InputMaybe<Array<AssetStatus>>;
  not?: InputMaybe<NestedEnumAssetStatusFilter>;
  notIn?: InputMaybe<Array<AssetStatus>>;
};

export type EnumAssetStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumAssetStatusFilter>;
  _min?: InputMaybe<NestedEnumAssetStatusFilter>;
  equals?: InputMaybe<AssetStatus>;
  in?: InputMaybe<Array<AssetStatus>>;
  not?: InputMaybe<NestedEnumAssetStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<AssetStatus>>;
};

export type EnumAssetSubCategoryFieldUpdateOperationsInput = {
  set?: InputMaybe<AssetSubCategory>;
};

export type EnumAssetSubCategoryFilter = {
  equals?: InputMaybe<AssetSubCategory>;
  in?: InputMaybe<Array<AssetSubCategory>>;
  not?: InputMaybe<NestedEnumAssetSubCategoryFilter>;
  notIn?: InputMaybe<Array<AssetSubCategory>>;
};

export type EnumAssetSubCategoryWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumAssetSubCategoryFilter>;
  _min?: InputMaybe<NestedEnumAssetSubCategoryFilter>;
  equals?: InputMaybe<AssetSubCategory>;
  in?: InputMaybe<Array<AssetSubCategory>>;
  not?: InputMaybe<NestedEnumAssetSubCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<AssetSubCategory>>;
};

export type EnumAssigneeTypeNullableFilter = {
  equals?: InputMaybe<AssigneeType>;
  in?: InputMaybe<Array<AssigneeType>>;
  not?: InputMaybe<NestedEnumAssigneeTypeNullableFilter>;
  notIn?: InputMaybe<Array<AssigneeType>>;
};

export type EnumAssigneeTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumAssigneeTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumAssigneeTypeNullableFilter>;
  equals?: InputMaybe<AssigneeType>;
  in?: InputMaybe<Array<AssigneeType>>;
  not?: InputMaybe<NestedEnumAssigneeTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<AssigneeType>>;
};

export type EnumBeneficiaryTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<BeneficiaryType>;
};

export type EnumBeneficiaryTypeFilter = {
  equals?: InputMaybe<BeneficiaryType>;
  in?: InputMaybe<Array<BeneficiaryType>>;
  not?: InputMaybe<NestedEnumBeneficiaryTypeFilter>;
  notIn?: InputMaybe<Array<BeneficiaryType>>;
};

export type EnumBeneficiaryTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumBeneficiaryTypeFilter>;
  _min?: InputMaybe<NestedEnumBeneficiaryTypeFilter>;
  equals?: InputMaybe<BeneficiaryType>;
  in?: InputMaybe<Array<BeneficiaryType>>;
  not?: InputMaybe<NestedEnumBeneficiaryTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<BeneficiaryType>>;
};

export type EnumComponentStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ComponentStatus>;
};

export type EnumComponentStatusFilter = {
  equals?: InputMaybe<ComponentStatus>;
  in?: InputMaybe<Array<ComponentStatus>>;
  not?: InputMaybe<NestedEnumComponentStatusFilter>;
  notIn?: InputMaybe<Array<ComponentStatus>>;
};

export type EnumComponentStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumComponentStatusFilter>;
  _min?: InputMaybe<NestedEnumComponentStatusFilter>;
  equals?: InputMaybe<ComponentStatus>;
  in?: InputMaybe<Array<ComponentStatus>>;
  not?: InputMaybe<NestedEnumComponentStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ComponentStatus>>;
};

export type EnumComponentSubTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ComponentSubType>;
};

export type EnumComponentSubTypeFilter = {
  equals?: InputMaybe<ComponentSubType>;
  in?: InputMaybe<Array<ComponentSubType>>;
  not?: InputMaybe<NestedEnumComponentSubTypeFilter>;
  notIn?: InputMaybe<Array<ComponentSubType>>;
};

export type EnumComponentSubTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumComponentSubTypeFilter>;
  _min?: InputMaybe<NestedEnumComponentSubTypeFilter>;
  equals?: InputMaybe<ComponentSubType>;
  in?: InputMaybe<Array<ComponentSubType>>;
  not?: InputMaybe<NestedEnumComponentSubTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ComponentSubType>>;
};

export type EnumComponentTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ComponentType>;
};

export type EnumComponentTypeFilter = {
  equals?: InputMaybe<ComponentType>;
  in?: InputMaybe<Array<ComponentType>>;
  not?: InputMaybe<NestedEnumComponentTypeFilter>;
  notIn?: InputMaybe<Array<ComponentType>>;
};

export type EnumComponentTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumComponentTypeFilter>;
  _min?: InputMaybe<NestedEnumComponentTypeFilter>;
  equals?: InputMaybe<ComponentType>;
  in?: InputMaybe<Array<ComponentType>>;
  not?: InputMaybe<NestedEnumComponentTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ComponentType>>;
};

export type EnumContactEstateRoleNullableFilter = {
  equals?: InputMaybe<ContactEstateRole>;
  in?: InputMaybe<Array<ContactEstateRole>>;
  not?: InputMaybe<NestedEnumContactEstateRoleNullableFilter>;
  notIn?: InputMaybe<Array<ContactEstateRole>>;
};

export type EnumContactEstateRoleNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumContactEstateRoleNullableFilter>;
  _min?: InputMaybe<NestedEnumContactEstateRoleNullableFilter>;
  equals?: InputMaybe<ContactEstateRole>;
  in?: InputMaybe<Array<ContactEstateRole>>;
  not?: InputMaybe<NestedEnumContactEstateRoleNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ContactEstateRole>>;
};

export type EnumContentCompleteByTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ContentCompleteByType>;
};

export type EnumContentCompleteByTypeFilter = {
  equals?: InputMaybe<ContentCompleteByType>;
  in?: InputMaybe<Array<ContentCompleteByType>>;
  not?: InputMaybe<NestedEnumContentCompleteByTypeFilter>;
  notIn?: InputMaybe<Array<ContentCompleteByType>>;
};

export type EnumContentCompleteByTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumContentCompleteByTypeFilter>;
  _min?: InputMaybe<NestedEnumContentCompleteByTypeFilter>;
  equals?: InputMaybe<ContentCompleteByType>;
  in?: InputMaybe<Array<ContentCompleteByType>>;
  not?: InputMaybe<NestedEnumContentCompleteByTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ContentCompleteByType>>;
};

export type EnumContentStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ContentStatus>;
};

export type EnumContentStatusFilter = {
  equals?: InputMaybe<ContentStatus>;
  in?: InputMaybe<Array<ContentStatus>>;
  not?: InputMaybe<NestedEnumContentStatusFilter>;
  notIn?: InputMaybe<Array<ContentStatus>>;
};

export type EnumContentStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumContentStatusFilter>;
  _min?: InputMaybe<NestedEnumContentStatusFilter>;
  equals?: InputMaybe<ContentStatus>;
  in?: InputMaybe<Array<ContentStatus>>;
  not?: InputMaybe<NestedEnumContentStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ContentStatus>>;
};

export type EnumContentTypeNullableFilter = {
  equals?: InputMaybe<ContentType>;
  in?: InputMaybe<Array<ContentType>>;
  not?: InputMaybe<NestedEnumContentTypeNullableFilter>;
  notIn?: InputMaybe<Array<ContentType>>;
};

export type EnumContentTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumContentTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumContentTypeNullableFilter>;
  equals?: InputMaybe<ContentType>;
  in?: InputMaybe<Array<ContentType>>;
  not?: InputMaybe<NestedEnumContentTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ContentType>>;
};

export type EnumDeceasedContactTypeNullableFilter = {
  equals?: InputMaybe<DeceasedContactType>;
  in?: InputMaybe<Array<DeceasedContactType>>;
  not?: InputMaybe<NestedEnumDeceasedContactTypeNullableFilter>;
  notIn?: InputMaybe<Array<DeceasedContactType>>;
};

export type EnumDeceasedContactTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumDeceasedContactTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumDeceasedContactTypeNullableFilter>;
  equals?: InputMaybe<DeceasedContactType>;
  in?: InputMaybe<Array<DeceasedContactType>>;
  not?: InputMaybe<NestedEnumDeceasedContactTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<DeceasedContactType>>;
};

export type EnumFeeTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<FeeType>;
};

export type EnumFeeTypeFilter = {
  equals?: InputMaybe<FeeType>;
  in?: InputMaybe<Array<FeeType>>;
  not?: InputMaybe<NestedEnumFeeTypeFilter>;
  notIn?: InputMaybe<Array<FeeType>>;
};

export type EnumFeeTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumFeeTypeFilter>;
  _min?: InputMaybe<NestedEnumFeeTypeFilter>;
  equals?: InputMaybe<FeeType>;
  in?: InputMaybe<Array<FeeType>>;
  not?: InputMaybe<NestedEnumFeeTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<FeeType>>;
};

export type EnumMilestoneDateTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<MilestoneDateType>;
};

export type EnumMilestoneDateTypeFilter = {
  equals?: InputMaybe<MilestoneDateType>;
  in?: InputMaybe<Array<MilestoneDateType>>;
  not?: InputMaybe<NestedEnumMilestoneDateTypeFilter>;
  notIn?: InputMaybe<Array<MilestoneDateType>>;
};

export type EnumMilestoneDateTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumMilestoneDateTypeFilter>;
  _min?: InputMaybe<NestedEnumMilestoneDateTypeFilter>;
  equals?: InputMaybe<MilestoneDateType>;
  in?: InputMaybe<Array<MilestoneDateType>>;
  not?: InputMaybe<NestedEnumMilestoneDateTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<MilestoneDateType>>;
};

export type EnumProcessingStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ProcessingStatus>;
};

export type EnumProcessingStatusFilter = {
  equals?: InputMaybe<ProcessingStatus>;
  in?: InputMaybe<Array<ProcessingStatus>>;
  not?: InputMaybe<NestedEnumProcessingStatusFilter>;
  notIn?: InputMaybe<Array<ProcessingStatus>>;
};

export type EnumProcessingStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumProcessingStatusFilter>;
  _min?: InputMaybe<NestedEnumProcessingStatusFilter>;
  equals?: InputMaybe<ProcessingStatus>;
  in?: InputMaybe<Array<ProcessingStatus>>;
  not?: InputMaybe<NestedEnumProcessingStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ProcessingStatus>>;
};

export type EnumRelatedElementTypeNullableFilter = {
  equals?: InputMaybe<RelatedElementType>;
  in?: InputMaybe<Array<RelatedElementType>>;
  not?: InputMaybe<NestedEnumRelatedElementTypeNullableFilter>;
  notIn?: InputMaybe<Array<RelatedElementType>>;
};

export type EnumRelatedElementTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumRelatedElementTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumRelatedElementTypeNullableFilter>;
  equals?: InputMaybe<RelatedElementType>;
  in?: InputMaybe<Array<RelatedElementType>>;
  not?: InputMaybe<NestedEnumRelatedElementTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<RelatedElementType>>;
};

export type EnumSexNullableFilter = {
  equals?: InputMaybe<Sex>;
  in?: InputMaybe<Array<Sex>>;
  not?: InputMaybe<NestedEnumSexNullableFilter>;
  notIn?: InputMaybe<Array<Sex>>;
};

export type EnumSexNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumSexNullableFilter>;
  _min?: InputMaybe<NestedEnumSexNullableFilter>;
  equals?: InputMaybe<Sex>;
  in?: InputMaybe<Array<Sex>>;
  not?: InputMaybe<NestedEnumSexNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Sex>>;
};

export type EnumSfxNullableFilter = {
  equals?: InputMaybe<Sfx>;
  in?: InputMaybe<Array<Sfx>>;
  not?: InputMaybe<NestedEnumSfxNullableFilter>;
  notIn?: InputMaybe<Array<Sfx>>;
};

export type EnumSfxNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumSfxNullableFilter>;
  _min?: InputMaybe<NestedEnumSfxNullableFilter>;
  equals?: InputMaybe<Sfx>;
  in?: InputMaybe<Array<Sfx>>;
  not?: InputMaybe<NestedEnumSfxNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Sfx>>;
};

export type EnumTaskStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<TaskStatus>;
};

export type EnumTaskStatusFilter = {
  equals?: InputMaybe<TaskStatus>;
  in?: InputMaybe<Array<TaskStatus>>;
  not?: InputMaybe<NestedEnumTaskStatusFilter>;
  notIn?: InputMaybe<Array<TaskStatus>>;
};

export type EnumTaskStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumTaskStatusFilter>;
  _min?: InputMaybe<NestedEnumTaskStatusFilter>;
  equals?: InputMaybe<TaskStatus>;
  in?: InputMaybe<Array<TaskStatus>>;
  not?: InputMaybe<NestedEnumTaskStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<TaskStatus>>;
};

export type EnumUserEstateRoleNullableFilter = {
  equals?: InputMaybe<UserEstateRole>;
  in?: InputMaybe<Array<UserEstateRole>>;
  not?: InputMaybe<NestedEnumUserEstateRoleNullableFilter>;
  notIn?: InputMaybe<Array<UserEstateRole>>;
};

export type EnumUserEstateRoleNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumUserEstateRoleNullableFilter>;
  _min?: InputMaybe<NestedEnumUserEstateRoleNullableFilter>;
  equals?: InputMaybe<UserEstateRole>;
  in?: InputMaybe<Array<UserEstateRole>>;
  not?: InputMaybe<NestedEnumUserEstateRoleNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<UserEstateRole>>;
};

export type EnumUserTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<UserType>;
};

export type EnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type EnumUserTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumUserTypeFilter>;
  _min?: InputMaybe<NestedEnumUserTypeFilter>;
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type Estate = {
  __typename?: "Estate";
  Asset: Array<Asset>;
  ContactsOnEstates: Array<ContactsOnEstates>;
  DocumentsProcessed: Array<DocumentsProcessed>;
  _count?: Maybe<EstateCount>;
  actionCards: Array<ActionCard>;
  alixFee?: Maybe<AlixFee>;
  beneficiaries: Array<Beneficiary>;
  boxBeneficiaryFolderId?: Maybe<Scalars["String"]["output"]>;
  boxEstateRootFolderId?: Maybe<Scalars["String"]["output"]>;
  boxExecutorFolderId?: Maybe<Scalars["String"]["output"]>;
  careTeamNotes: Array<CareTeamNote>;
  channelPartner?: Maybe<Scalars["String"]["output"]>;
  components: Array<Component>;
  createdAt: Scalars["DateTimeISO"]["output"];
  currentWorkFLowStep: Scalars["Int"]["output"];
  data?: Maybe<Scalars["JSON"]["output"]>;
  deceased?: Maybe<Deceased>;
  deleted: Scalars["Boolean"]["output"];
  details: Array<EstateDetail>;
  estateReady: Scalars["Boolean"]["output"];
  expenses: Array<Expense>;
  hasProbate?: Maybe<Scalars["Boolean"]["output"]>;
  hasTrust?: Maybe<Scalars["Boolean"]["output"]>;
  hasWill?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["String"]["output"];
  milestones: Array<Milestone>;
  notifications: Array<EstateNotification>;
  planReady: Scalars["Boolean"]["output"];
  processInstanceId?: Maybe<Scalars["String"]["output"]>;
  survivingSpouse?: Maybe<Scalars["Boolean"]["output"]>;
  tasks: Array<Task>;
  taxId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  users: Array<UsersOnEstates>;
};

export type EstateAssetArgs = {
  cursor?: InputMaybe<AssetWhereUniqueInput>;
  distinct?: InputMaybe<Array<AssetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AssetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetWhereInput>;
};

export type EstateContactsOnEstatesArgs = {
  cursor?: InputMaybe<ContactsOnEstatesWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContactsOnEstatesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContactsOnEstatesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContactsOnEstatesWhereInput>;
};

export type EstateDocumentsProcessedArgs = {
  cursor?: InputMaybe<DocumentsProcessedWhereUniqueInput>;
  distinct?: InputMaybe<Array<DocumentsProcessedScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DocumentsProcessedOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DocumentsProcessedWhereInput>;
};

export type EstateActionCardsArgs = {
  cursor?: InputMaybe<ActionCardWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActionCardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActionCardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActionCardWhereInput>;
};

export type EstateAlixFeeArgs = {
  where?: InputMaybe<AlixFeeWhereInput>;
};

export type EstateBeneficiariesArgs = {
  cursor?: InputMaybe<BeneficiaryWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<BeneficiaryWhereInput>;
};

export type EstateCareTeamNotesArgs = {
  cursor?: InputMaybe<CareTeamNoteWhereUniqueInput>;
  distinct?: InputMaybe<Array<CareTeamNoteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CareTeamNoteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CareTeamNoteWhereInput>;
};

export type EstateComponentsArgs = {
  cursor?: InputMaybe<ComponentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ComponentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ComponentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ComponentWhereInput>;
};

export type EstateDeceasedArgs = {
  where?: InputMaybe<DeceasedWhereInput>;
};

export type EstateDetailsArgs = {
  cursor?: InputMaybe<EstateDetailWhereUniqueInput>;
  distinct?: InputMaybe<Array<EstateDetailScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EstateDetailOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateDetailWhereInput>;
};

export type EstateExpensesArgs = {
  cursor?: InputMaybe<ExpenseWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExpenseScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExpenseOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ExpenseWhereInput>;
};

export type EstateMilestonesArgs = {
  cursor?: InputMaybe<MilestoneWhereUniqueInput>;
  distinct?: InputMaybe<Array<MilestoneScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MilestoneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MilestoneWhereInput>;
};

export type EstateNotificationsArgs = {
  cursor?: InputMaybe<EstateNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<EstateNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EstateNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateNotificationWhereInput>;
};

export type EstateTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TaskWhereInput>;
};

export type EstateUsersArgs = {
  cursor?: InputMaybe<UsersOnEstatesWhereUniqueInput>;
  distinct?: InputMaybe<Array<UsersOnEstatesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UsersOnEstatesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UsersOnEstatesWhereInput>;
};

export type Estate2 = {
  __typename?: "Estate2";
  actionCards: Array<ActionCard2>;
  alixFee?: Maybe<AlixFee2>;
  beneficiaries: Array<Beneficiary2>;
  boxBeneficiaryFolderId?: Maybe<Scalars["String"]["output"]>;
  boxEstateRootFolderId?: Maybe<Scalars["String"]["output"]>;
  boxExecutorFolderId?: Maybe<Scalars["String"]["output"]>;
  channelPartner?: Maybe<Scalars["String"]["output"]>;
  /** Get the component breakdown for the estate. Only assets and debts are included in the breakdown. */
  componentBreakDown?: Maybe<Array<ComponentBreakDown>>;
  components: Array<Component2>;
  contacts?: Maybe<Array<Contact2>>;
  contents: Array<Content2>;
  createdAt: Scalars["DateTimeISO"]["output"];
  currentWorkFLowStep: Scalars["Int"]["output"];
  deceased?: Maybe<Deceased2>;
  deleted: Scalars["Boolean"]["output"];
  details: Array<EstateDetail2>;
  estateComponents: Array<Component2>;
  /** @deprecated Defaults to true for backwards compatibility with older client versions. */
  estateReady: Scalars["Boolean"]["output"];
  hasProbate?: Maybe<Scalars["Boolean"]["output"]>;
  hasTrust?: Maybe<Scalars["Boolean"]["output"]>;
  hasWill?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["String"]["output"];
  keyDates: Array<Content2>;
  notifications: Array<EstateNotification2>;
  /** @deprecated Defaults to false. No longer used. */
  planReady: Scalars["Boolean"]["output"];
  processInstanceId?: Maybe<Scalars["String"]["output"]>;
  recentActivity: Array<Content2>;
  survivingSpouse?: Maybe<Scalars["Boolean"]["output"]>;
  taxId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  users: Array<UsersOnEstates2>;
};

export type Estate2ActionCardsArgs = {
  orderBy?: InputMaybe<Array<ActionCardOrderByWithRelationInput2>>;
  where?: InputMaybe<ActionCardWhereInput2>;
};

export type Estate2UsersArgs = {
  orderBy?: InputMaybe<Array<UsersOnEstatesOrderByWithRelationInput2>>;
  where?: InputMaybe<UsersOnEstatesWhereInput2>;
};

export type EstateAvgAggregate = {
  __typename?: "EstateAvgAggregate";
  currentWorkFLowStep?: Maybe<Scalars["Float"]["output"]>;
};

export type EstateAvgOrderByAggregateInput = {
  currentWorkFLowStep?: InputMaybe<SortOrder>;
};

export type EstateContentsFilterInput = {
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  dateRange?: InputMaybe<DateRangeInput>;
};

export type EstateCount = {
  __typename?: "EstateCount";
  Asset: Scalars["Int"]["output"];
  ContactsOnEstates: Scalars["Int"]["output"];
  DocumentsProcessed: Scalars["Int"]["output"];
  actionCards: Scalars["Int"]["output"];
  beneficiaries: Scalars["Int"]["output"];
  careTeamNotes: Scalars["Int"]["output"];
  components: Scalars["Int"]["output"];
  details: Scalars["Int"]["output"];
  expenses: Scalars["Int"]["output"];
  milestones: Scalars["Int"]["output"];
  notifications: Scalars["Int"]["output"];
  tasks: Scalars["Int"]["output"];
  users: Scalars["Int"]["output"];
};

export type EstateCountAssetArgs = {
  where?: InputMaybe<AssetWhereInput>;
};

export type EstateCountContactsOnEstatesArgs = {
  where?: InputMaybe<ContactsOnEstatesWhereInput>;
};

export type EstateCountDocumentsProcessedArgs = {
  where?: InputMaybe<DocumentsProcessedWhereInput>;
};

export type EstateCountActionCardsArgs = {
  where?: InputMaybe<ActionCardWhereInput>;
};

export type EstateCountBeneficiariesArgs = {
  where?: InputMaybe<BeneficiaryWhereInput>;
};

export type EstateCountCareTeamNotesArgs = {
  where?: InputMaybe<CareTeamNoteWhereInput>;
};

export type EstateCountComponentsArgs = {
  where?: InputMaybe<ComponentWhereInput>;
};

export type EstateCountDetailsArgs = {
  where?: InputMaybe<EstateDetailWhereInput>;
};

export type EstateCountExpensesArgs = {
  where?: InputMaybe<ExpenseWhereInput>;
};

export type EstateCountMilestonesArgs = {
  where?: InputMaybe<MilestoneWhereInput>;
};

export type EstateCountNotificationsArgs = {
  where?: InputMaybe<EstateNotificationWhereInput>;
};

export type EstateCountTasksArgs = {
  where?: InputMaybe<TaskWhereInput>;
};

export type EstateCountUsersArgs = {
  where?: InputMaybe<UsersOnEstatesWhereInput>;
};

export type EstateCountAggregate = {
  __typename?: "EstateCountAggregate";
  _all: Scalars["Int"]["output"];
  boxBeneficiaryFolderId: Scalars["Int"]["output"];
  boxEstateRootFolderId: Scalars["Int"]["output"];
  boxExecutorFolderId: Scalars["Int"]["output"];
  channelPartner: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  currentWorkFLowStep: Scalars["Int"]["output"];
  data: Scalars["Int"]["output"];
  deleted: Scalars["Int"]["output"];
  estateReady: Scalars["Int"]["output"];
  hasProbate: Scalars["Int"]["output"];
  hasTrust: Scalars["Int"]["output"];
  hasWill: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  planReady: Scalars["Int"]["output"];
  processInstanceId: Scalars["Int"]["output"];
  survivingSpouse: Scalars["Int"]["output"];
  taxId: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type EstateCountOrderByAggregateInput = {
  boxBeneficiaryFolderId?: InputMaybe<SortOrder>;
  boxEstateRootFolderId?: InputMaybe<SortOrder>;
  boxExecutorFolderId?: InputMaybe<SortOrder>;
  channelPartner?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currentWorkFLowStep?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  estateReady?: InputMaybe<SortOrder>;
  hasProbate?: InputMaybe<SortOrder>;
  hasTrust?: InputMaybe<SortOrder>;
  hasWill?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  planReady?: InputMaybe<SortOrder>;
  processInstanceId?: InputMaybe<SortOrder>;
  survivingSpouse?: InputMaybe<SortOrder>;
  taxId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EstateCreateInput = {
  Asset?: InputMaybe<AssetCreateNestedManyWithoutEstateInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutEstateInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedCreateNestedManyWithoutEstateInput>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutEstateInput>;
  alixFee?: InputMaybe<AlixFeeCreateNestedOneWithoutEstateInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutEstateInput>;
  boxBeneficiaryFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxEstateRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxExecutorFolderId?: InputMaybe<Scalars["String"]["input"]>;
  careTeamNotes?: InputMaybe<CareTeamNoteCreateNestedManyWithoutEstateInput>;
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutEstateInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentWorkFLowStep?: InputMaybe<Scalars["Int"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  details?: InputMaybe<EstateDetailCreateNestedManyWithoutEstateInput>;
  estateReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEstateInput>;
  hasProbate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  milestones?: InputMaybe<MilestoneCreateNestedManyWithoutEstateInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutEstateInput>;
  planReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  survivingSpouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutEstateInput>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  users?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutEstateInput>;
};

export type EstateCreateInput2 = {
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput2>;
  executors?: InputMaybe<Array<ExecutorCreateNestedOneWithoutEstateInput>>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type EstateCreateManyInput = {
  boxBeneficiaryFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxEstateRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxExecutorFolderId?: InputMaybe<Scalars["String"]["input"]>;
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentWorkFLowStep?: InputMaybe<Scalars["Int"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  estateReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasProbate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  planReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  survivingSpouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type EstateCreateNestedOneWithoutActionCardsInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutActionCardsInput>;
  create?: InputMaybe<EstateCreateWithoutActionCardsInput>;
};

export type EstateCreateNestedOneWithoutActionCardsInput2 = {
  connect?: InputMaybe<EstateWhereUniqueInput2>;
};

export type EstateCreateNestedOneWithoutAlixFeeInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutAlixFeeInput>;
  create?: InputMaybe<EstateCreateWithoutAlixFeeInput>;
};

export type EstateCreateNestedOneWithoutAssetInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutAssetInput>;
  create?: InputMaybe<EstateCreateWithoutAssetInput>;
};

export type EstateCreateNestedOneWithoutBeneficiariesInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutBeneficiariesInput>;
  create?: InputMaybe<EstateCreateWithoutBeneficiariesInput>;
};

export type EstateCreateNestedOneWithoutCareTeamNotesInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutCareTeamNotesInput>;
  create?: InputMaybe<EstateCreateWithoutCareTeamNotesInput>;
};

export type EstateCreateNestedOneWithoutComponentsInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutComponentsInput>;
  create?: InputMaybe<EstateCreateWithoutComponentsInput>;
};

export type EstateCreateNestedOneWithoutComponentsInput2 = {
  connect?: InputMaybe<EstateWhereUniqueInput2>;
};

export type EstateCreateNestedOneWithoutContactsOnEstatesInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutContactsOnEstatesInput>;
  create?: InputMaybe<EstateCreateWithoutContactsOnEstatesInput>;
};

export type EstateCreateNestedOneWithoutDeceasedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutDeceasedInput>;
  create?: InputMaybe<EstateCreateWithoutDeceasedInput>;
};

export type EstateCreateNestedOneWithoutDeceasedInput2 = {
  connect?: InputMaybe<EstateWhereUniqueInput2>;
};

export type EstateCreateNestedOneWithoutDetailsInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutDetailsInput>;
  create?: InputMaybe<EstateCreateWithoutDetailsInput>;
};

export type EstateCreateNestedOneWithoutDocumentsProcessedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutDocumentsProcessedInput>;
  create?: InputMaybe<EstateCreateWithoutDocumentsProcessedInput>;
};

export type EstateCreateNestedOneWithoutExpensesInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutExpensesInput>;
  create?: InputMaybe<EstateCreateWithoutExpensesInput>;
};

export type EstateCreateNestedOneWithoutMilestonesInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutMilestonesInput>;
  create?: InputMaybe<EstateCreateWithoutMilestonesInput>;
};

export type EstateCreateNestedOneWithoutNotificationsInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<EstateCreateWithoutNotificationsInput>;
};

export type EstateCreateNestedOneWithoutTasksInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<EstateCreateWithoutTasksInput>;
};

export type EstateCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<EstateCreateWithoutUsersInput>;
};

export type EstateCreateNestedOneWithoutUsersInput2 = {
  connect?: InputMaybe<EstateWhereUniqueInput2>;
  create?: InputMaybe<EstateCreateWithoutUsersInput2>;
};

export type EstateCreateOrConnectWithoutActionCardsInput = {
  create: EstateCreateWithoutActionCardsInput;
  where: EstateWhereUniqueInput;
};

export type EstateCreateOrConnectWithoutAlixFeeInput = {
  create: EstateCreateWithoutAlixFeeInput;
  where: EstateWhereUniqueInput;
};

export type EstateCreateOrConnectWithoutAssetInput = {
  create: EstateCreateWithoutAssetInput;
  where: EstateWhereUniqueInput;
};

export type EstateCreateOrConnectWithoutBeneficiariesInput = {
  create: EstateCreateWithoutBeneficiariesInput;
  where: EstateWhereUniqueInput;
};

export type EstateCreateOrConnectWithoutCareTeamNotesInput = {
  create: EstateCreateWithoutCareTeamNotesInput;
  where: EstateWhereUniqueInput;
};

export type EstateCreateOrConnectWithoutComponentsInput = {
  create: EstateCreateWithoutComponentsInput;
  where: EstateWhereUniqueInput;
};

export type EstateCreateOrConnectWithoutContactsOnEstatesInput = {
  create: EstateCreateWithoutContactsOnEstatesInput;
  where: EstateWhereUniqueInput;
};

export type EstateCreateOrConnectWithoutDeceasedInput = {
  create: EstateCreateWithoutDeceasedInput;
  where: EstateWhereUniqueInput;
};

export type EstateCreateOrConnectWithoutDetailsInput = {
  create: EstateCreateWithoutDetailsInput;
  where: EstateWhereUniqueInput;
};

export type EstateCreateOrConnectWithoutDocumentsProcessedInput = {
  create: EstateCreateWithoutDocumentsProcessedInput;
  where: EstateWhereUniqueInput;
};

export type EstateCreateOrConnectWithoutExpensesInput = {
  create: EstateCreateWithoutExpensesInput;
  where: EstateWhereUniqueInput;
};

export type EstateCreateOrConnectWithoutMilestonesInput = {
  create: EstateCreateWithoutMilestonesInput;
  where: EstateWhereUniqueInput;
};

export type EstateCreateOrConnectWithoutNotificationsInput = {
  create: EstateCreateWithoutNotificationsInput;
  where: EstateWhereUniqueInput;
};

export type EstateCreateOrConnectWithoutTasksInput = {
  create: EstateCreateWithoutTasksInput;
  where: EstateWhereUniqueInput;
};

export type EstateCreateOrConnectWithoutUsersInput = {
  create: EstateCreateWithoutUsersInput;
  where: EstateWhereUniqueInput;
};

export type EstateCreateWithoutActionCardsInput = {
  Asset?: InputMaybe<AssetCreateNestedManyWithoutEstateInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutEstateInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedCreateNestedManyWithoutEstateInput>;
  alixFee?: InputMaybe<AlixFeeCreateNestedOneWithoutEstateInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutEstateInput>;
  boxBeneficiaryFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxEstateRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxExecutorFolderId?: InputMaybe<Scalars["String"]["input"]>;
  careTeamNotes?: InputMaybe<CareTeamNoteCreateNestedManyWithoutEstateInput>;
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutEstateInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentWorkFLowStep?: InputMaybe<Scalars["Int"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  details?: InputMaybe<EstateDetailCreateNestedManyWithoutEstateInput>;
  estateReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEstateInput>;
  hasProbate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  milestones?: InputMaybe<MilestoneCreateNestedManyWithoutEstateInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutEstateInput>;
  planReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  survivingSpouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutEstateInput>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  users?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutEstateInput>;
};

export type EstateCreateWithoutAlixFeeInput = {
  Asset?: InputMaybe<AssetCreateNestedManyWithoutEstateInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutEstateInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedCreateNestedManyWithoutEstateInput>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutEstateInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutEstateInput>;
  boxBeneficiaryFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxEstateRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxExecutorFolderId?: InputMaybe<Scalars["String"]["input"]>;
  careTeamNotes?: InputMaybe<CareTeamNoteCreateNestedManyWithoutEstateInput>;
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutEstateInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentWorkFLowStep?: InputMaybe<Scalars["Int"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  details?: InputMaybe<EstateDetailCreateNestedManyWithoutEstateInput>;
  estateReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEstateInput>;
  hasProbate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  milestones?: InputMaybe<MilestoneCreateNestedManyWithoutEstateInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutEstateInput>;
  planReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  survivingSpouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutEstateInput>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  users?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutEstateInput>;
};

export type EstateCreateWithoutAssetInput = {
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutEstateInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedCreateNestedManyWithoutEstateInput>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutEstateInput>;
  alixFee?: InputMaybe<AlixFeeCreateNestedOneWithoutEstateInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutEstateInput>;
  boxBeneficiaryFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxEstateRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxExecutorFolderId?: InputMaybe<Scalars["String"]["input"]>;
  careTeamNotes?: InputMaybe<CareTeamNoteCreateNestedManyWithoutEstateInput>;
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutEstateInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentWorkFLowStep?: InputMaybe<Scalars["Int"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  details?: InputMaybe<EstateDetailCreateNestedManyWithoutEstateInput>;
  estateReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEstateInput>;
  hasProbate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  milestones?: InputMaybe<MilestoneCreateNestedManyWithoutEstateInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutEstateInput>;
  planReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  survivingSpouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutEstateInput>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  users?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutEstateInput>;
};

export type EstateCreateWithoutBeneficiariesInput = {
  Asset?: InputMaybe<AssetCreateNestedManyWithoutEstateInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutEstateInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedCreateNestedManyWithoutEstateInput>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutEstateInput>;
  alixFee?: InputMaybe<AlixFeeCreateNestedOneWithoutEstateInput>;
  boxBeneficiaryFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxEstateRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxExecutorFolderId?: InputMaybe<Scalars["String"]["input"]>;
  careTeamNotes?: InputMaybe<CareTeamNoteCreateNestedManyWithoutEstateInput>;
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutEstateInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentWorkFLowStep?: InputMaybe<Scalars["Int"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  details?: InputMaybe<EstateDetailCreateNestedManyWithoutEstateInput>;
  estateReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEstateInput>;
  hasProbate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  milestones?: InputMaybe<MilestoneCreateNestedManyWithoutEstateInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutEstateInput>;
  planReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  survivingSpouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutEstateInput>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  users?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutEstateInput>;
};

export type EstateCreateWithoutCareTeamNotesInput = {
  Asset?: InputMaybe<AssetCreateNestedManyWithoutEstateInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutEstateInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedCreateNestedManyWithoutEstateInput>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutEstateInput>;
  alixFee?: InputMaybe<AlixFeeCreateNestedOneWithoutEstateInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutEstateInput>;
  boxBeneficiaryFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxEstateRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxExecutorFolderId?: InputMaybe<Scalars["String"]["input"]>;
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutEstateInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentWorkFLowStep?: InputMaybe<Scalars["Int"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  details?: InputMaybe<EstateDetailCreateNestedManyWithoutEstateInput>;
  estateReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEstateInput>;
  hasProbate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  milestones?: InputMaybe<MilestoneCreateNestedManyWithoutEstateInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutEstateInput>;
  planReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  survivingSpouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutEstateInput>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  users?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutEstateInput>;
};

export type EstateCreateWithoutComponentsInput = {
  Asset?: InputMaybe<AssetCreateNestedManyWithoutEstateInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutEstateInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedCreateNestedManyWithoutEstateInput>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutEstateInput>;
  alixFee?: InputMaybe<AlixFeeCreateNestedOneWithoutEstateInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutEstateInput>;
  boxBeneficiaryFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxEstateRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxExecutorFolderId?: InputMaybe<Scalars["String"]["input"]>;
  careTeamNotes?: InputMaybe<CareTeamNoteCreateNestedManyWithoutEstateInput>;
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentWorkFLowStep?: InputMaybe<Scalars["Int"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  details?: InputMaybe<EstateDetailCreateNestedManyWithoutEstateInput>;
  estateReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEstateInput>;
  hasProbate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  milestones?: InputMaybe<MilestoneCreateNestedManyWithoutEstateInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutEstateInput>;
  planReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  survivingSpouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutEstateInput>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  users?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutEstateInput>;
};

export type EstateCreateWithoutContactsOnEstatesInput = {
  Asset?: InputMaybe<AssetCreateNestedManyWithoutEstateInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedCreateNestedManyWithoutEstateInput>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutEstateInput>;
  alixFee?: InputMaybe<AlixFeeCreateNestedOneWithoutEstateInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutEstateInput>;
  boxBeneficiaryFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxEstateRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxExecutorFolderId?: InputMaybe<Scalars["String"]["input"]>;
  careTeamNotes?: InputMaybe<CareTeamNoteCreateNestedManyWithoutEstateInput>;
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutEstateInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentWorkFLowStep?: InputMaybe<Scalars["Int"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  details?: InputMaybe<EstateDetailCreateNestedManyWithoutEstateInput>;
  estateReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEstateInput>;
  hasProbate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  milestones?: InputMaybe<MilestoneCreateNestedManyWithoutEstateInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutEstateInput>;
  planReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  survivingSpouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutEstateInput>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  users?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutEstateInput>;
};

export type EstateCreateWithoutDeceasedInput = {
  Asset?: InputMaybe<AssetCreateNestedManyWithoutEstateInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutEstateInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedCreateNestedManyWithoutEstateInput>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutEstateInput>;
  alixFee?: InputMaybe<AlixFeeCreateNestedOneWithoutEstateInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutEstateInput>;
  boxBeneficiaryFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxEstateRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxExecutorFolderId?: InputMaybe<Scalars["String"]["input"]>;
  careTeamNotes?: InputMaybe<CareTeamNoteCreateNestedManyWithoutEstateInput>;
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutEstateInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentWorkFLowStep?: InputMaybe<Scalars["Int"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  details?: InputMaybe<EstateDetailCreateNestedManyWithoutEstateInput>;
  estateReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEstateInput>;
  hasProbate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  milestones?: InputMaybe<MilestoneCreateNestedManyWithoutEstateInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutEstateInput>;
  planReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  survivingSpouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutEstateInput>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  users?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutEstateInput>;
};

export type EstateCreateWithoutDetailsInput = {
  Asset?: InputMaybe<AssetCreateNestedManyWithoutEstateInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutEstateInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedCreateNestedManyWithoutEstateInput>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutEstateInput>;
  alixFee?: InputMaybe<AlixFeeCreateNestedOneWithoutEstateInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutEstateInput>;
  boxBeneficiaryFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxEstateRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxExecutorFolderId?: InputMaybe<Scalars["String"]["input"]>;
  careTeamNotes?: InputMaybe<CareTeamNoteCreateNestedManyWithoutEstateInput>;
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutEstateInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentWorkFLowStep?: InputMaybe<Scalars["Int"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  estateReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEstateInput>;
  hasProbate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  milestones?: InputMaybe<MilestoneCreateNestedManyWithoutEstateInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutEstateInput>;
  planReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  survivingSpouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutEstateInput>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  users?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutEstateInput>;
};

export type EstateCreateWithoutDocumentsProcessedInput = {
  Asset?: InputMaybe<AssetCreateNestedManyWithoutEstateInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutEstateInput>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutEstateInput>;
  alixFee?: InputMaybe<AlixFeeCreateNestedOneWithoutEstateInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutEstateInput>;
  boxBeneficiaryFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxEstateRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxExecutorFolderId?: InputMaybe<Scalars["String"]["input"]>;
  careTeamNotes?: InputMaybe<CareTeamNoteCreateNestedManyWithoutEstateInput>;
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutEstateInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentWorkFLowStep?: InputMaybe<Scalars["Int"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  details?: InputMaybe<EstateDetailCreateNestedManyWithoutEstateInput>;
  estateReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEstateInput>;
  hasProbate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  milestones?: InputMaybe<MilestoneCreateNestedManyWithoutEstateInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutEstateInput>;
  planReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  survivingSpouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutEstateInput>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  users?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutEstateInput>;
};

export type EstateCreateWithoutExpensesInput = {
  Asset?: InputMaybe<AssetCreateNestedManyWithoutEstateInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutEstateInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedCreateNestedManyWithoutEstateInput>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutEstateInput>;
  alixFee?: InputMaybe<AlixFeeCreateNestedOneWithoutEstateInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutEstateInput>;
  boxBeneficiaryFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxEstateRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxExecutorFolderId?: InputMaybe<Scalars["String"]["input"]>;
  careTeamNotes?: InputMaybe<CareTeamNoteCreateNestedManyWithoutEstateInput>;
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutEstateInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentWorkFLowStep?: InputMaybe<Scalars["Int"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  details?: InputMaybe<EstateDetailCreateNestedManyWithoutEstateInput>;
  estateReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasProbate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  milestones?: InputMaybe<MilestoneCreateNestedManyWithoutEstateInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutEstateInput>;
  planReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  survivingSpouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutEstateInput>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  users?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutEstateInput>;
};

export type EstateCreateWithoutMilestonesInput = {
  Asset?: InputMaybe<AssetCreateNestedManyWithoutEstateInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutEstateInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedCreateNestedManyWithoutEstateInput>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutEstateInput>;
  alixFee?: InputMaybe<AlixFeeCreateNestedOneWithoutEstateInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutEstateInput>;
  boxBeneficiaryFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxEstateRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxExecutorFolderId?: InputMaybe<Scalars["String"]["input"]>;
  careTeamNotes?: InputMaybe<CareTeamNoteCreateNestedManyWithoutEstateInput>;
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutEstateInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentWorkFLowStep?: InputMaybe<Scalars["Int"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  details?: InputMaybe<EstateDetailCreateNestedManyWithoutEstateInput>;
  estateReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEstateInput>;
  hasProbate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutEstateInput>;
  planReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  survivingSpouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutEstateInput>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  users?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutEstateInput>;
};

export type EstateCreateWithoutNotificationsInput = {
  Asset?: InputMaybe<AssetCreateNestedManyWithoutEstateInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutEstateInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedCreateNestedManyWithoutEstateInput>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutEstateInput>;
  alixFee?: InputMaybe<AlixFeeCreateNestedOneWithoutEstateInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutEstateInput>;
  boxBeneficiaryFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxEstateRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxExecutorFolderId?: InputMaybe<Scalars["String"]["input"]>;
  careTeamNotes?: InputMaybe<CareTeamNoteCreateNestedManyWithoutEstateInput>;
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutEstateInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentWorkFLowStep?: InputMaybe<Scalars["Int"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  details?: InputMaybe<EstateDetailCreateNestedManyWithoutEstateInput>;
  estateReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEstateInput>;
  hasProbate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  milestones?: InputMaybe<MilestoneCreateNestedManyWithoutEstateInput>;
  planReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  survivingSpouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutEstateInput>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  users?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutEstateInput>;
};

export type EstateCreateWithoutTasksInput = {
  Asset?: InputMaybe<AssetCreateNestedManyWithoutEstateInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutEstateInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedCreateNestedManyWithoutEstateInput>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutEstateInput>;
  alixFee?: InputMaybe<AlixFeeCreateNestedOneWithoutEstateInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutEstateInput>;
  boxBeneficiaryFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxEstateRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxExecutorFolderId?: InputMaybe<Scalars["String"]["input"]>;
  careTeamNotes?: InputMaybe<CareTeamNoteCreateNestedManyWithoutEstateInput>;
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutEstateInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentWorkFLowStep?: InputMaybe<Scalars["Int"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  details?: InputMaybe<EstateDetailCreateNestedManyWithoutEstateInput>;
  estateReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEstateInput>;
  hasProbate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  milestones?: InputMaybe<MilestoneCreateNestedManyWithoutEstateInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutEstateInput>;
  planReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  survivingSpouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  users?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutEstateInput>;
};

export type EstateCreateWithoutUsersInput = {
  Asset?: InputMaybe<AssetCreateNestedManyWithoutEstateInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesCreateNestedManyWithoutEstateInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedCreateNestedManyWithoutEstateInput>;
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutEstateInput>;
  alixFee?: InputMaybe<AlixFeeCreateNestedOneWithoutEstateInput>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutEstateInput>;
  boxBeneficiaryFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxEstateRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  boxExecutorFolderId?: InputMaybe<Scalars["String"]["input"]>;
  careTeamNotes?: InputMaybe<CareTeamNoteCreateNestedManyWithoutEstateInput>;
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutEstateInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentWorkFLowStep?: InputMaybe<Scalars["Int"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  details?: InputMaybe<EstateDetailCreateNestedManyWithoutEstateInput>;
  estateReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEstateInput>;
  hasProbate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  milestones?: InputMaybe<MilestoneCreateNestedManyWithoutEstateInput>;
  notifications?: InputMaybe<EstateNotificationCreateNestedManyWithoutEstateInput>;
  planReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  survivingSpouse?: InputMaybe<Scalars["Boolean"]["input"]>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutEstateInput>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type EstateCreateWithoutUsersInput2 = {
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput2>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type EstateDetail = {
  __typename?: "EstateDetail";
  actionCard?: Maybe<ActionCard>;
  createdAt: Scalars["DateTimeISO"]["output"];
  encrypted: Scalars["Boolean"]["output"];
  estate: Estate;
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  value: Scalars["JSON"]["output"];
};

export type EstateDetailActionCardArgs = {
  where?: InputMaybe<ActionCardWhereInput>;
};

export type EstateDetail2 = {
  __typename?: "EstateDetail2";
  createdAt: Scalars["DateTimeISO"]["output"];
  decryptedValue?: Maybe<Scalars["JSON"]["output"]>;
  encrypted: Scalars["Boolean"]["output"];
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  value: Scalars["JSON"]["output"];
};

export type EstateDetailCountAggregate = {
  __typename?: "EstateDetailCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  encrypted: Scalars["Int"]["output"];
  estateId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  value: Scalars["Int"]["output"];
};

export type EstateDetailCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  encrypted?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type EstateDetailCreateInput = {
  actionCard?: InputMaybe<ActionCardCreateNestedOneWithoutEstateDetailInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  encrypted?: InputMaybe<Scalars["Boolean"]["input"]>;
  estate: EstateCreateNestedOneWithoutDetailsInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  value: Scalars["JSON"]["input"];
};

export type EstateDetailCreateManyEstateInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  encrypted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  value: Scalars["JSON"]["input"];
};

export type EstateDetailCreateManyEstateInputEnvelope = {
  data: Array<EstateDetailCreateManyEstateInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type EstateDetailCreateManyInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  encrypted?: InputMaybe<Scalars["Boolean"]["input"]>;
  estateId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  value: Scalars["JSON"]["input"];
};

export type EstateDetailCreateNestedManyWithoutEstateInput = {
  connect?: InputMaybe<Array<EstateDetailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EstateDetailCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<EstateDetailCreateWithoutEstateInput>>;
  createMany?: InputMaybe<EstateDetailCreateManyEstateInputEnvelope>;
};

export type EstateDetailCreateNestedOneWithoutActionCardInput = {
  connect?: InputMaybe<EstateDetailWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateDetailCreateOrConnectWithoutActionCardInput>;
  create?: InputMaybe<EstateDetailCreateWithoutActionCardInput>;
};

export type EstateDetailCreateOrConnectWithoutActionCardInput = {
  create: EstateDetailCreateWithoutActionCardInput;
  where: EstateDetailWhereUniqueInput;
};

export type EstateDetailCreateOrConnectWithoutEstateInput = {
  create: EstateDetailCreateWithoutEstateInput;
  where: EstateDetailWhereUniqueInput;
};

export type EstateDetailCreateWithoutActionCardInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  encrypted?: InputMaybe<Scalars["Boolean"]["input"]>;
  estate: EstateCreateNestedOneWithoutDetailsInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  value: Scalars["JSON"]["input"];
};

export type EstateDetailCreateWithoutEstateInput = {
  actionCard?: InputMaybe<ActionCardCreateNestedOneWithoutEstateDetailInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  encrypted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  value: Scalars["JSON"]["input"];
};

export type EstateDetailGroupBy = {
  __typename?: "EstateDetailGroupBy";
  _count?: Maybe<EstateDetailCountAggregate>;
  _max?: Maybe<EstateDetailMaxAggregate>;
  _min?: Maybe<EstateDetailMinAggregate>;
  createdAt: Scalars["DateTimeISO"]["output"];
  encrypted: Scalars["Boolean"]["output"];
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  value: Scalars["JSON"]["output"];
};

export type EstateDetailListRelationFilter = {
  every?: InputMaybe<EstateDetailWhereInput>;
  none?: InputMaybe<EstateDetailWhereInput>;
  some?: InputMaybe<EstateDetailWhereInput>;
};

export type EstateDetailMaxAggregate = {
  __typename?: "EstateDetailMaxAggregate";
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  encrypted?: Maybe<Scalars["Boolean"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type EstateDetailMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  encrypted?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EstateDetailMinAggregate = {
  __typename?: "EstateDetailMinAggregate";
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  encrypted?: Maybe<Scalars["Boolean"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type EstateDetailMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  encrypted?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EstateDetailNullableRelationFilter = {
  is?: InputMaybe<EstateDetailWhereInput>;
  isNot?: InputMaybe<EstateDetailWhereInput>;
};

export type EstateDetailOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EstateDetailOrderByWithAggregationInput = {
  _count?: InputMaybe<EstateDetailCountOrderByAggregateInput>;
  _max?: InputMaybe<EstateDetailMaxOrderByAggregateInput>;
  _min?: InputMaybe<EstateDetailMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  encrypted?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type EstateDetailOrderByWithRelationInput = {
  actionCard?: InputMaybe<ActionCardOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  encrypted?: InputMaybe<SortOrder>;
  estate?: InputMaybe<EstateOrderByWithRelationInput>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export enum EstateDetailScalarFieldEnum {
  CreatedAt = "createdAt",
  Encrypted = "encrypted",
  EstateId = "estateId",
  Id = "id",
  Name = "name",
  UpdatedAt = "updatedAt",
  Value = "value",
}

export type EstateDetailScalarWhereInput = {
  AND?: InputMaybe<Array<EstateDetailScalarWhereInput>>;
  NOT?: InputMaybe<Array<EstateDetailScalarWhereInput>>;
  OR?: InputMaybe<Array<EstateDetailScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  encrypted?: InputMaybe<BoolFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<JsonFilter>;
};

export type EstateDetailScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<EstateDetailScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<EstateDetailScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<EstateDetailScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  encrypted?: InputMaybe<BoolWithAggregatesFilter>;
  estateId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  value?: InputMaybe<JsonWithAggregatesFilter>;
};

export type EstateDetailUpdateInput = {
  actionCard?: InputMaybe<ActionCardUpdateOneWithoutEstateDetailNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  encrypted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutDetailsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type EstateDetailUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  encrypted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type EstateDetailUpdateManyWithWhereWithoutEstateInput = {
  data: EstateDetailUpdateManyMutationInput;
  where: EstateDetailScalarWhereInput;
};

export type EstateDetailUpdateManyWithoutEstateNestedInput = {
  connect?: InputMaybe<Array<EstateDetailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EstateDetailCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<EstateDetailCreateWithoutEstateInput>>;
  createMany?: InputMaybe<EstateDetailCreateManyEstateInputEnvelope>;
  delete?: InputMaybe<Array<EstateDetailWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EstateDetailScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EstateDetailWhereUniqueInput>>;
  set?: InputMaybe<Array<EstateDetailWhereUniqueInput>>;
  update?: InputMaybe<
    Array<EstateDetailUpdateWithWhereUniqueWithoutEstateInput>
  >;
  updateMany?: InputMaybe<
    Array<EstateDetailUpdateManyWithWhereWithoutEstateInput>
  >;
  upsert?: InputMaybe<
    Array<EstateDetailUpsertWithWhereUniqueWithoutEstateInput>
  >;
};

export type EstateDetailUpdateOneWithoutActionCardNestedInput = {
  connect?: InputMaybe<EstateDetailWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateDetailCreateOrConnectWithoutActionCardInput>;
  create?: InputMaybe<EstateDetailCreateWithoutActionCardInput>;
  delete?: InputMaybe<EstateDetailWhereInput>;
  disconnect?: InputMaybe<EstateDetailWhereInput>;
  update?: InputMaybe<EstateDetailUpdateToOneWithWhereWithoutActionCardInput>;
  upsert?: InputMaybe<EstateDetailUpsertWithoutActionCardInput>;
};

export type EstateDetailUpdateToOneWithWhereWithoutActionCardInput = {
  data: EstateDetailUpdateWithoutActionCardInput;
  where?: InputMaybe<EstateDetailWhereInput>;
};

export type EstateDetailUpdateWithWhereUniqueWithoutEstateInput = {
  data: EstateDetailUpdateWithoutEstateInput;
  where: EstateDetailWhereUniqueInput;
};

export type EstateDetailUpdateWithoutActionCardInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  encrypted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutDetailsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type EstateDetailUpdateWithoutEstateInput = {
  actionCard?: InputMaybe<ActionCardUpdateOneWithoutEstateDetailNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  encrypted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type EstateDetailUpsertWithWhereUniqueWithoutEstateInput = {
  create: EstateDetailCreateWithoutEstateInput;
  update: EstateDetailUpdateWithoutEstateInput;
  where: EstateDetailWhereUniqueInput;
};

export type EstateDetailUpsertWithoutActionCardInput = {
  create: EstateDetailCreateWithoutActionCardInput;
  update: EstateDetailUpdateWithoutActionCardInput;
  where?: InputMaybe<EstateDetailWhereInput>;
};

export type EstateDetailWhereInput = {
  AND?: InputMaybe<Array<EstateDetailWhereInput>>;
  NOT?: InputMaybe<Array<EstateDetailWhereInput>>;
  OR?: InputMaybe<Array<EstateDetailWhereInput>>;
  actionCard?: InputMaybe<ActionCardNullableRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  encrypted?: InputMaybe<BoolFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<JsonFilter>;
};

export type EstateDetailWhereUniqueInput = {
  AND?: InputMaybe<Array<EstateDetailWhereInput>>;
  NOT?: InputMaybe<Array<EstateDetailWhereInput>>;
  OR?: InputMaybe<Array<EstateDetailWhereInput>>;
  actionCard?: InputMaybe<ActionCardNullableRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  encrypted?: InputMaybe<BoolFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<JsonFilter>;
};

export type EstateDistributionBreakdown = {
  __typename?: "EstateDistributionBreakdown";
  alixFee: AlixFeeDisplay;
  debts: Scalars["Decimal"]["output"];
  distributionInfo?: Maybe<Info>;
  estimatedDistribution: Scalars["Decimal"]["output"];
  feeCalculationInfo?: Maybe<Info>;
  totalValue: Scalars["Decimal"]["output"];
};

export type EstateDocument = {
  __typename?: "EstateDocument";
  contentType?: Maybe<Scalars["String"]["output"]>;
  deleteMarkerId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isDir: Scalars["Boolean"]["output"];
  modDate: Scalars["DateTimeISO"]["output"];
  name: Scalars["String"]["output"];
  signedUrl?: Maybe<Scalars["String"]["output"]>;
  size: Scalars["Float"]["output"];
  uploadedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type EstateFilter = {
  userIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type EstateGroupBy = {
  __typename?: "EstateGroupBy";
  _avg?: Maybe<EstateAvgAggregate>;
  _count?: Maybe<EstateCountAggregate>;
  _max?: Maybe<EstateMaxAggregate>;
  _min?: Maybe<EstateMinAggregate>;
  _sum?: Maybe<EstateSumAggregate>;
  boxBeneficiaryFolderId?: Maybe<Scalars["String"]["output"]>;
  boxEstateRootFolderId?: Maybe<Scalars["String"]["output"]>;
  boxExecutorFolderId?: Maybe<Scalars["String"]["output"]>;
  channelPartner?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  currentWorkFLowStep: Scalars["Int"]["output"];
  data?: Maybe<Scalars["JSON"]["output"]>;
  deleted: Scalars["Boolean"]["output"];
  estateReady: Scalars["Boolean"]["output"];
  hasProbate?: Maybe<Scalars["Boolean"]["output"]>;
  hasTrust?: Maybe<Scalars["Boolean"]["output"]>;
  hasWill?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["String"]["output"];
  planReady: Scalars["Boolean"]["output"];
  processInstanceId?: Maybe<Scalars["String"]["output"]>;
  survivingSpouse?: Maybe<Scalars["Boolean"]["output"]>;
  taxId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type EstateMaxAggregate = {
  __typename?: "EstateMaxAggregate";
  boxBeneficiaryFolderId?: Maybe<Scalars["String"]["output"]>;
  boxEstateRootFolderId?: Maybe<Scalars["String"]["output"]>;
  boxExecutorFolderId?: Maybe<Scalars["String"]["output"]>;
  channelPartner?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  currentWorkFLowStep?: Maybe<Scalars["Int"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  estateReady?: Maybe<Scalars["Boolean"]["output"]>;
  hasProbate?: Maybe<Scalars["Boolean"]["output"]>;
  hasTrust?: Maybe<Scalars["Boolean"]["output"]>;
  hasWill?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  planReady?: Maybe<Scalars["Boolean"]["output"]>;
  processInstanceId?: Maybe<Scalars["String"]["output"]>;
  survivingSpouse?: Maybe<Scalars["Boolean"]["output"]>;
  taxId?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type EstateMaxOrderByAggregateInput = {
  boxBeneficiaryFolderId?: InputMaybe<SortOrder>;
  boxEstateRootFolderId?: InputMaybe<SortOrder>;
  boxExecutorFolderId?: InputMaybe<SortOrder>;
  channelPartner?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currentWorkFLowStep?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  estateReady?: InputMaybe<SortOrder>;
  hasProbate?: InputMaybe<SortOrder>;
  hasTrust?: InputMaybe<SortOrder>;
  hasWill?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  planReady?: InputMaybe<SortOrder>;
  processInstanceId?: InputMaybe<SortOrder>;
  survivingSpouse?: InputMaybe<SortOrder>;
  taxId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EstateMinAggregate = {
  __typename?: "EstateMinAggregate";
  boxBeneficiaryFolderId?: Maybe<Scalars["String"]["output"]>;
  boxEstateRootFolderId?: Maybe<Scalars["String"]["output"]>;
  boxExecutorFolderId?: Maybe<Scalars["String"]["output"]>;
  channelPartner?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  currentWorkFLowStep?: Maybe<Scalars["Int"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  estateReady?: Maybe<Scalars["Boolean"]["output"]>;
  hasProbate?: Maybe<Scalars["Boolean"]["output"]>;
  hasTrust?: Maybe<Scalars["Boolean"]["output"]>;
  hasWill?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  planReady?: Maybe<Scalars["Boolean"]["output"]>;
  processInstanceId?: Maybe<Scalars["String"]["output"]>;
  survivingSpouse?: Maybe<Scalars["Boolean"]["output"]>;
  taxId?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type EstateMinOrderByAggregateInput = {
  boxBeneficiaryFolderId?: InputMaybe<SortOrder>;
  boxEstateRootFolderId?: InputMaybe<SortOrder>;
  boxExecutorFolderId?: InputMaybe<SortOrder>;
  channelPartner?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currentWorkFLowStep?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  estateReady?: InputMaybe<SortOrder>;
  hasProbate?: InputMaybe<SortOrder>;
  hasTrust?: InputMaybe<SortOrder>;
  hasWill?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  planReady?: InputMaybe<SortOrder>;
  processInstanceId?: InputMaybe<SortOrder>;
  survivingSpouse?: InputMaybe<SortOrder>;
  taxId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EstateNotification = {
  __typename?: "EstateNotification";
  _count?: Maybe<EstateNotificationCount>;
  actionCard?: Maybe<ActionCard>;
  actionCardId?: Maybe<Scalars["String"]["output"]>;
  component?: Maybe<Component>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  content?: Maybe<Content>;
  contentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  estate?: Maybe<Estate>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  relatedElementType?: Maybe<RelatedElementType>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  userNotifications: Array<UserNotification>;
};

export type EstateNotificationActionCardArgs = {
  where?: InputMaybe<ActionCardWhereInput>;
};

export type EstateNotificationComponentArgs = {
  where?: InputMaybe<ComponentWhereInput>;
};

export type EstateNotificationContentArgs = {
  where?: InputMaybe<ContentWhereInput>;
};

export type EstateNotificationEstateArgs = {
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateNotificationUserNotificationsArgs = {
  cursor?: InputMaybe<UserNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserNotificationWhereInput>;
};

export type EstateNotification2 = {
  __typename?: "EstateNotification2";
  actionCard: ActionCard2;
  actionCardId?: Maybe<Scalars["String"]["output"]>;
  component?: Maybe<Component2>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  contentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  estate?: Maybe<Estate2>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  relatedElementType?: Maybe<RelatedElementType>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  userNotifications: Array<UserNotification2>;
};

export type EstateNotificationCount = {
  __typename?: "EstateNotificationCount";
  userNotifications: Scalars["Int"]["output"];
};

export type EstateNotificationCountUserNotificationsArgs = {
  where?: InputMaybe<UserNotificationWhereInput>;
};

export type EstateNotificationCountAggregate = {
  __typename?: "EstateNotificationCountAggregate";
  _all: Scalars["Int"]["output"];
  actionCardId: Scalars["Int"]["output"];
  componentId: Scalars["Int"]["output"];
  contentId: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  estateId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  message: Scalars["Int"]["output"];
  relatedElementType: Scalars["Int"]["output"];
  title: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type EstateNotificationCountOrderByAggregateInput = {
  actionCardId?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrder>;
  contentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  relatedElementType?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EstateNotificationCreateInput = {
  actionCard?: InputMaybe<ActionCardCreateNestedOneWithoutNotificationsInput>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutNotificationsInput>;
  content?: InputMaybe<ContentCreateNestedOneWithoutNotificationsInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estate?: InputMaybe<EstateCreateNestedOneWithoutNotificationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  relatedElementType?: InputMaybe<RelatedElementType>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userNotifications?: InputMaybe<UserNotificationCreateNestedManyWithoutNotificationInput>;
};

export type EstateNotificationCreateManyActionCardInput = {
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  contentId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  relatedElementType?: InputMaybe<RelatedElementType>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type EstateNotificationCreateManyActionCardInputEnvelope = {
  data: Array<EstateNotificationCreateManyActionCardInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type EstateNotificationCreateManyComponentInput = {
  actionCardId?: InputMaybe<Scalars["String"]["input"]>;
  contentId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  relatedElementType?: InputMaybe<RelatedElementType>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type EstateNotificationCreateManyComponentInputEnvelope = {
  data: Array<EstateNotificationCreateManyComponentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type EstateNotificationCreateManyContentInput = {
  actionCardId?: InputMaybe<Scalars["String"]["input"]>;
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  relatedElementType?: InputMaybe<RelatedElementType>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type EstateNotificationCreateManyContentInputEnvelope = {
  data: Array<EstateNotificationCreateManyContentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type EstateNotificationCreateManyEstateInput = {
  actionCardId?: InputMaybe<Scalars["String"]["input"]>;
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  contentId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  relatedElementType?: InputMaybe<RelatedElementType>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type EstateNotificationCreateManyEstateInputEnvelope = {
  data: Array<EstateNotificationCreateManyEstateInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type EstateNotificationCreateManyInput = {
  actionCardId?: InputMaybe<Scalars["String"]["input"]>;
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  contentId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  relatedElementType?: InputMaybe<RelatedElementType>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type EstateNotificationCreateNestedManyWithoutActionCardInput = {
  connect?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EstateNotificationCreateOrConnectWithoutActionCardInput>
  >;
  create?: InputMaybe<Array<EstateNotificationCreateWithoutActionCardInput>>;
  createMany?: InputMaybe<EstateNotificationCreateManyActionCardInputEnvelope>;
};

export type EstateNotificationCreateNestedManyWithoutComponentInput = {
  connect?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EstateNotificationCreateOrConnectWithoutComponentInput>
  >;
  create?: InputMaybe<Array<EstateNotificationCreateWithoutComponentInput>>;
  createMany?: InputMaybe<EstateNotificationCreateManyComponentInputEnvelope>;
};

export type EstateNotificationCreateNestedManyWithoutContentInput = {
  connect?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EstateNotificationCreateOrConnectWithoutContentInput>
  >;
  create?: InputMaybe<Array<EstateNotificationCreateWithoutContentInput>>;
  createMany?: InputMaybe<EstateNotificationCreateManyContentInputEnvelope>;
};

export type EstateNotificationCreateNestedManyWithoutEstateInput = {
  connect?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EstateNotificationCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<EstateNotificationCreateWithoutEstateInput>>;
  createMany?: InputMaybe<EstateNotificationCreateManyEstateInputEnvelope>;
};

export type EstateNotificationCreateNestedOneWithoutUserNotificationsInput = {
  connect?: InputMaybe<EstateNotificationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateNotificationCreateOrConnectWithoutUserNotificationsInput>;
  create?: InputMaybe<EstateNotificationCreateWithoutUserNotificationsInput>;
};

export type EstateNotificationCreateOrConnectWithoutActionCardInput = {
  create: EstateNotificationCreateWithoutActionCardInput;
  where: EstateNotificationWhereUniqueInput;
};

export type EstateNotificationCreateOrConnectWithoutComponentInput = {
  create: EstateNotificationCreateWithoutComponentInput;
  where: EstateNotificationWhereUniqueInput;
};

export type EstateNotificationCreateOrConnectWithoutContentInput = {
  create: EstateNotificationCreateWithoutContentInput;
  where: EstateNotificationWhereUniqueInput;
};

export type EstateNotificationCreateOrConnectWithoutEstateInput = {
  create: EstateNotificationCreateWithoutEstateInput;
  where: EstateNotificationWhereUniqueInput;
};

export type EstateNotificationCreateOrConnectWithoutUserNotificationsInput = {
  create: EstateNotificationCreateWithoutUserNotificationsInput;
  where: EstateNotificationWhereUniqueInput;
};

export type EstateNotificationCreateWithoutActionCardInput = {
  component?: InputMaybe<ComponentCreateNestedOneWithoutNotificationsInput>;
  content?: InputMaybe<ContentCreateNestedOneWithoutNotificationsInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estate?: InputMaybe<EstateCreateNestedOneWithoutNotificationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  relatedElementType?: InputMaybe<RelatedElementType>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userNotifications?: InputMaybe<UserNotificationCreateNestedManyWithoutNotificationInput>;
};

export type EstateNotificationCreateWithoutComponentInput = {
  actionCard?: InputMaybe<ActionCardCreateNestedOneWithoutNotificationsInput>;
  content?: InputMaybe<ContentCreateNestedOneWithoutNotificationsInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estate?: InputMaybe<EstateCreateNestedOneWithoutNotificationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  relatedElementType?: InputMaybe<RelatedElementType>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userNotifications?: InputMaybe<UserNotificationCreateNestedManyWithoutNotificationInput>;
};

export type EstateNotificationCreateWithoutContentInput = {
  actionCard?: InputMaybe<ActionCardCreateNestedOneWithoutNotificationsInput>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutNotificationsInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estate?: InputMaybe<EstateCreateNestedOneWithoutNotificationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  relatedElementType?: InputMaybe<RelatedElementType>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userNotifications?: InputMaybe<UserNotificationCreateNestedManyWithoutNotificationInput>;
};

export type EstateNotificationCreateWithoutEstateInput = {
  actionCard?: InputMaybe<ActionCardCreateNestedOneWithoutNotificationsInput>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutNotificationsInput>;
  content?: InputMaybe<ContentCreateNestedOneWithoutNotificationsInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  relatedElementType?: InputMaybe<RelatedElementType>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userNotifications?: InputMaybe<UserNotificationCreateNestedManyWithoutNotificationInput>;
};

export type EstateNotificationCreateWithoutUserNotificationsInput = {
  actionCard?: InputMaybe<ActionCardCreateNestedOneWithoutNotificationsInput>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutNotificationsInput>;
  content?: InputMaybe<ContentCreateNestedOneWithoutNotificationsInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  estate?: InputMaybe<EstateCreateNestedOneWithoutNotificationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  relatedElementType?: InputMaybe<RelatedElementType>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type EstateNotificationGroupBy = {
  __typename?: "EstateNotificationGroupBy";
  _count?: Maybe<EstateNotificationCountAggregate>;
  _max?: Maybe<EstateNotificationMaxAggregate>;
  _min?: Maybe<EstateNotificationMinAggregate>;
  actionCardId?: Maybe<Scalars["String"]["output"]>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  contentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  estateId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  relatedElementType?: Maybe<RelatedElementType>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type EstateNotificationListRelationFilter = {
  every?: InputMaybe<EstateNotificationWhereInput>;
  none?: InputMaybe<EstateNotificationWhereInput>;
  some?: InputMaybe<EstateNotificationWhereInput>;
};

export type EstateNotificationMaxAggregate = {
  __typename?: "EstateNotificationMaxAggregate";
  actionCardId?: Maybe<Scalars["String"]["output"]>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  contentId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  relatedElementType?: Maybe<RelatedElementType>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type EstateNotificationMaxOrderByAggregateInput = {
  actionCardId?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrder>;
  contentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  relatedElementType?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EstateNotificationMinAggregate = {
  __typename?: "EstateNotificationMinAggregate";
  actionCardId?: Maybe<Scalars["String"]["output"]>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  contentId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  relatedElementType?: Maybe<RelatedElementType>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type EstateNotificationMinOrderByAggregateInput = {
  actionCardId?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrder>;
  contentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  relatedElementType?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EstateNotificationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EstateNotificationOrderByWithAggregationInput = {
  _count?: InputMaybe<EstateNotificationCountOrderByAggregateInput>;
  _max?: InputMaybe<EstateNotificationMaxOrderByAggregateInput>;
  _min?: InputMaybe<EstateNotificationMinOrderByAggregateInput>;
  actionCardId?: InputMaybe<SortOrderInput>;
  componentId?: InputMaybe<SortOrderInput>;
  contentId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  relatedElementType?: InputMaybe<SortOrderInput>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EstateNotificationOrderByWithRelationInput = {
  actionCard?: InputMaybe<ActionCardOrderByWithRelationInput>;
  actionCardId?: InputMaybe<SortOrderInput>;
  component?: InputMaybe<ComponentOrderByWithRelationInput>;
  componentId?: InputMaybe<SortOrderInput>;
  content?: InputMaybe<ContentOrderByWithRelationInput>;
  contentId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  estate?: InputMaybe<EstateOrderByWithRelationInput>;
  estateId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  relatedElementType?: InputMaybe<SortOrderInput>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userNotifications?: InputMaybe<UserNotificationOrderByRelationAggregateInput>;
};

export type EstateNotificationRelationFilter = {
  is?: InputMaybe<EstateNotificationWhereInput>;
  isNot?: InputMaybe<EstateNotificationWhereInput>;
};

export enum EstateNotificationScalarFieldEnum {
  ActionCardId = "actionCardId",
  ComponentId = "componentId",
  ContentId = "contentId",
  CreatedAt = "createdAt",
  EstateId = "estateId",
  Id = "id",
  Message = "message",
  RelatedElementType = "relatedElementType",
  Title = "title",
  UpdatedAt = "updatedAt",
}

export type EstateNotificationScalarWhereInput = {
  AND?: InputMaybe<Array<EstateNotificationScalarWhereInput>>;
  NOT?: InputMaybe<Array<EstateNotificationScalarWhereInput>>;
  OR?: InputMaybe<Array<EstateNotificationScalarWhereInput>>;
  actionCardId?: InputMaybe<StringNullableFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  contentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  estateId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringFilter>;
  relatedElementType?: InputMaybe<EnumRelatedElementTypeNullableFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EstateNotificationScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<EstateNotificationScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<EstateNotificationScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<EstateNotificationScalarWhereWithAggregatesInput>>;
  actionCardId?: InputMaybe<StringNullableWithAggregatesFilter>;
  componentId?: InputMaybe<StringNullableWithAggregatesFilter>;
  contentId?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  estateId?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  message?: InputMaybe<StringWithAggregatesFilter>;
  relatedElementType?: InputMaybe<EnumRelatedElementTypeNullableWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type EstateNotificationUpdateInput = {
  actionCard?: InputMaybe<ActionCardUpdateOneWithoutNotificationsNestedInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutNotificationsNestedInput>;
  content?: InputMaybe<ContentUpdateOneWithoutNotificationsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneWithoutNotificationsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedElementType?: InputMaybe<NullableEnumRelatedElementTypeFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userNotifications?: InputMaybe<UserNotificationUpdateManyWithoutNotificationNestedInput>;
};

export type EstateNotificationUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedElementType?: InputMaybe<NullableEnumRelatedElementTypeFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EstateNotificationUpdateManyWithWhereWithoutActionCardInput = {
  data: EstateNotificationUpdateManyMutationInput;
  where: EstateNotificationScalarWhereInput;
};

export type EstateNotificationUpdateManyWithWhereWithoutComponentInput = {
  data: EstateNotificationUpdateManyMutationInput;
  where: EstateNotificationScalarWhereInput;
};

export type EstateNotificationUpdateManyWithWhereWithoutContentInput = {
  data: EstateNotificationUpdateManyMutationInput;
  where: EstateNotificationScalarWhereInput;
};

export type EstateNotificationUpdateManyWithWhereWithoutEstateInput = {
  data: EstateNotificationUpdateManyMutationInput;
  where: EstateNotificationScalarWhereInput;
};

export type EstateNotificationUpdateManyWithoutActionCardNestedInput = {
  connect?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EstateNotificationCreateOrConnectWithoutActionCardInput>
  >;
  create?: InputMaybe<Array<EstateNotificationCreateWithoutActionCardInput>>;
  createMany?: InputMaybe<EstateNotificationCreateManyActionCardInputEnvelope>;
  delete?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EstateNotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<EstateNotificationUpdateWithWhereUniqueWithoutActionCardInput>
  >;
  updateMany?: InputMaybe<
    Array<EstateNotificationUpdateManyWithWhereWithoutActionCardInput>
  >;
  upsert?: InputMaybe<
    Array<EstateNotificationUpsertWithWhereUniqueWithoutActionCardInput>
  >;
};

export type EstateNotificationUpdateManyWithoutComponentNestedInput = {
  connect?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EstateNotificationCreateOrConnectWithoutComponentInput>
  >;
  create?: InputMaybe<Array<EstateNotificationCreateWithoutComponentInput>>;
  createMany?: InputMaybe<EstateNotificationCreateManyComponentInputEnvelope>;
  delete?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EstateNotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<EstateNotificationUpdateWithWhereUniqueWithoutComponentInput>
  >;
  updateMany?: InputMaybe<
    Array<EstateNotificationUpdateManyWithWhereWithoutComponentInput>
  >;
  upsert?: InputMaybe<
    Array<EstateNotificationUpsertWithWhereUniqueWithoutComponentInput>
  >;
};

export type EstateNotificationUpdateManyWithoutContentNestedInput = {
  connect?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EstateNotificationCreateOrConnectWithoutContentInput>
  >;
  create?: InputMaybe<Array<EstateNotificationCreateWithoutContentInput>>;
  createMany?: InputMaybe<EstateNotificationCreateManyContentInputEnvelope>;
  delete?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EstateNotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<EstateNotificationUpdateWithWhereUniqueWithoutContentInput>
  >;
  updateMany?: InputMaybe<
    Array<EstateNotificationUpdateManyWithWhereWithoutContentInput>
  >;
  upsert?: InputMaybe<
    Array<EstateNotificationUpsertWithWhereUniqueWithoutContentInput>
  >;
};

export type EstateNotificationUpdateManyWithoutEstateNestedInput = {
  connect?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EstateNotificationCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<EstateNotificationCreateWithoutEstateInput>>;
  createMany?: InputMaybe<EstateNotificationCreateManyEstateInputEnvelope>;
  delete?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EstateNotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<EstateNotificationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<EstateNotificationUpdateWithWhereUniqueWithoutEstateInput>
  >;
  updateMany?: InputMaybe<
    Array<EstateNotificationUpdateManyWithWhereWithoutEstateInput>
  >;
  upsert?: InputMaybe<
    Array<EstateNotificationUpsertWithWhereUniqueWithoutEstateInput>
  >;
};

export type EstateNotificationUpdateOneRequiredWithoutUserNotificationsNestedInput =
  {
    connect?: InputMaybe<EstateNotificationWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EstateNotificationCreateOrConnectWithoutUserNotificationsInput>;
    create?: InputMaybe<EstateNotificationCreateWithoutUserNotificationsInput>;
    update?: InputMaybe<EstateNotificationUpdateToOneWithWhereWithoutUserNotificationsInput>;
    upsert?: InputMaybe<EstateNotificationUpsertWithoutUserNotificationsInput>;
  };

export type EstateNotificationUpdateToOneWithWhereWithoutUserNotificationsInput =
  {
    data: EstateNotificationUpdateWithoutUserNotificationsInput;
    where?: InputMaybe<EstateNotificationWhereInput>;
  };

export type EstateNotificationUpdateWithWhereUniqueWithoutActionCardInput = {
  data: EstateNotificationUpdateWithoutActionCardInput;
  where: EstateNotificationWhereUniqueInput;
};

export type EstateNotificationUpdateWithWhereUniqueWithoutComponentInput = {
  data: EstateNotificationUpdateWithoutComponentInput;
  where: EstateNotificationWhereUniqueInput;
};

export type EstateNotificationUpdateWithWhereUniqueWithoutContentInput = {
  data: EstateNotificationUpdateWithoutContentInput;
  where: EstateNotificationWhereUniqueInput;
};

export type EstateNotificationUpdateWithWhereUniqueWithoutEstateInput = {
  data: EstateNotificationUpdateWithoutEstateInput;
  where: EstateNotificationWhereUniqueInput;
};

export type EstateNotificationUpdateWithoutActionCardInput = {
  component?: InputMaybe<ComponentUpdateOneWithoutNotificationsNestedInput>;
  content?: InputMaybe<ContentUpdateOneWithoutNotificationsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneWithoutNotificationsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedElementType?: InputMaybe<NullableEnumRelatedElementTypeFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userNotifications?: InputMaybe<UserNotificationUpdateManyWithoutNotificationNestedInput>;
};

export type EstateNotificationUpdateWithoutComponentInput = {
  actionCard?: InputMaybe<ActionCardUpdateOneWithoutNotificationsNestedInput>;
  content?: InputMaybe<ContentUpdateOneWithoutNotificationsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneWithoutNotificationsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedElementType?: InputMaybe<NullableEnumRelatedElementTypeFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userNotifications?: InputMaybe<UserNotificationUpdateManyWithoutNotificationNestedInput>;
};

export type EstateNotificationUpdateWithoutContentInput = {
  actionCard?: InputMaybe<ActionCardUpdateOneWithoutNotificationsNestedInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutNotificationsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneWithoutNotificationsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedElementType?: InputMaybe<NullableEnumRelatedElementTypeFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userNotifications?: InputMaybe<UserNotificationUpdateManyWithoutNotificationNestedInput>;
};

export type EstateNotificationUpdateWithoutEstateInput = {
  actionCard?: InputMaybe<ActionCardUpdateOneWithoutNotificationsNestedInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutNotificationsNestedInput>;
  content?: InputMaybe<ContentUpdateOneWithoutNotificationsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedElementType?: InputMaybe<NullableEnumRelatedElementTypeFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userNotifications?: InputMaybe<UserNotificationUpdateManyWithoutNotificationNestedInput>;
};

export type EstateNotificationUpdateWithoutUserNotificationsInput = {
  actionCard?: InputMaybe<ActionCardUpdateOneWithoutNotificationsNestedInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutNotificationsNestedInput>;
  content?: InputMaybe<ContentUpdateOneWithoutNotificationsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneWithoutNotificationsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedElementType?: InputMaybe<NullableEnumRelatedElementTypeFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EstateNotificationUpsertWithWhereUniqueWithoutActionCardInput = {
  create: EstateNotificationCreateWithoutActionCardInput;
  update: EstateNotificationUpdateWithoutActionCardInput;
  where: EstateNotificationWhereUniqueInput;
};

export type EstateNotificationUpsertWithWhereUniqueWithoutComponentInput = {
  create: EstateNotificationCreateWithoutComponentInput;
  update: EstateNotificationUpdateWithoutComponentInput;
  where: EstateNotificationWhereUniqueInput;
};

export type EstateNotificationUpsertWithWhereUniqueWithoutContentInput = {
  create: EstateNotificationCreateWithoutContentInput;
  update: EstateNotificationUpdateWithoutContentInput;
  where: EstateNotificationWhereUniqueInput;
};

export type EstateNotificationUpsertWithWhereUniqueWithoutEstateInput = {
  create: EstateNotificationCreateWithoutEstateInput;
  update: EstateNotificationUpdateWithoutEstateInput;
  where: EstateNotificationWhereUniqueInput;
};

export type EstateNotificationUpsertWithoutUserNotificationsInput = {
  create: EstateNotificationCreateWithoutUserNotificationsInput;
  update: EstateNotificationUpdateWithoutUserNotificationsInput;
  where?: InputMaybe<EstateNotificationWhereInput>;
};

export type EstateNotificationWhereInput = {
  AND?: InputMaybe<Array<EstateNotificationWhereInput>>;
  NOT?: InputMaybe<Array<EstateNotificationWhereInput>>;
  OR?: InputMaybe<Array<EstateNotificationWhereInput>>;
  actionCard?: InputMaybe<ActionCardNullableRelationFilter>;
  actionCardId?: InputMaybe<StringNullableFilter>;
  component?: InputMaybe<ComponentNullableRelationFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  content?: InputMaybe<ContentNullableRelationFilter>;
  contentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  estate?: InputMaybe<EstateNullableRelationFilter>;
  estateId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringFilter>;
  relatedElementType?: InputMaybe<EnumRelatedElementTypeNullableFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userNotifications?: InputMaybe<UserNotificationListRelationFilter>;
};

export type EstateNotificationWhereUniqueInput = {
  AND?: InputMaybe<Array<EstateNotificationWhereInput>>;
  NOT?: InputMaybe<Array<EstateNotificationWhereInput>>;
  OR?: InputMaybe<Array<EstateNotificationWhereInput>>;
  actionCard?: InputMaybe<ActionCardNullableRelationFilter>;
  actionCardId?: InputMaybe<StringNullableFilter>;
  component?: InputMaybe<ComponentNullableRelationFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  content?: InputMaybe<ContentNullableRelationFilter>;
  contentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  estate?: InputMaybe<EstateNullableRelationFilter>;
  estateId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<StringFilter>;
  relatedElementType?: InputMaybe<EnumRelatedElementTypeNullableFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userNotifications?: InputMaybe<UserNotificationListRelationFilter>;
};

export type EstateNullableRelationFilter = {
  is?: InputMaybe<EstateWhereInput>;
  isNot?: InputMaybe<EstateWhereInput>;
};

export type EstateOrderByWithAggregationInput = {
  _avg?: InputMaybe<EstateAvgOrderByAggregateInput>;
  _count?: InputMaybe<EstateCountOrderByAggregateInput>;
  _max?: InputMaybe<EstateMaxOrderByAggregateInput>;
  _min?: InputMaybe<EstateMinOrderByAggregateInput>;
  _sum?: InputMaybe<EstateSumOrderByAggregateInput>;
  boxBeneficiaryFolderId?: InputMaybe<SortOrderInput>;
  boxEstateRootFolderId?: InputMaybe<SortOrderInput>;
  boxExecutorFolderId?: InputMaybe<SortOrderInput>;
  channelPartner?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  currentWorkFLowStep?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrderInput>;
  deleted?: InputMaybe<SortOrder>;
  estateReady?: InputMaybe<SortOrder>;
  hasProbate?: InputMaybe<SortOrderInput>;
  hasTrust?: InputMaybe<SortOrderInput>;
  hasWill?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  planReady?: InputMaybe<SortOrder>;
  processInstanceId?: InputMaybe<SortOrderInput>;
  survivingSpouse?: InputMaybe<SortOrderInput>;
  taxId?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EstateOrderByWithRelationInput = {
  Asset?: InputMaybe<AssetOrderByRelationAggregateInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesOrderByRelationAggregateInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedOrderByRelationAggregateInput>;
  actionCards?: InputMaybe<ActionCardOrderByRelationAggregateInput>;
  alixFee?: InputMaybe<AlixFeeOrderByWithRelationInput>;
  beneficiaries?: InputMaybe<BeneficiaryOrderByRelationAggregateInput>;
  boxBeneficiaryFolderId?: InputMaybe<SortOrderInput>;
  boxEstateRootFolderId?: InputMaybe<SortOrderInput>;
  boxExecutorFolderId?: InputMaybe<SortOrderInput>;
  careTeamNotes?: InputMaybe<CareTeamNoteOrderByRelationAggregateInput>;
  channelPartner?: InputMaybe<SortOrderInput>;
  components?: InputMaybe<ComponentOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  currentWorkFLowStep?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrderInput>;
  deceased?: InputMaybe<DeceasedOrderByWithRelationInput>;
  deleted?: InputMaybe<SortOrder>;
  details?: InputMaybe<EstateDetailOrderByRelationAggregateInput>;
  estateReady?: InputMaybe<SortOrder>;
  expenses?: InputMaybe<ExpenseOrderByRelationAggregateInput>;
  hasProbate?: InputMaybe<SortOrderInput>;
  hasTrust?: InputMaybe<SortOrderInput>;
  hasWill?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  milestones?: InputMaybe<MilestoneOrderByRelationAggregateInput>;
  notifications?: InputMaybe<EstateNotificationOrderByRelationAggregateInput>;
  planReady?: InputMaybe<SortOrder>;
  processInstanceId?: InputMaybe<SortOrderInput>;
  survivingSpouse?: InputMaybe<SortOrderInput>;
  tasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  taxId?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  users?: InputMaybe<UsersOnEstatesOrderByRelationAggregateInput>;
};

export type EstateRelationFilter = {
  is?: InputMaybe<EstateWhereInput>;
  isNot?: InputMaybe<EstateWhereInput>;
};

export enum EstateScalarFieldEnum {
  BoxBeneficiaryFolderId = "boxBeneficiaryFolderId",
  BoxEstateRootFolderId = "boxEstateRootFolderId",
  BoxExecutorFolderId = "boxExecutorFolderId",
  ChannelPartner = "channelPartner",
  CreatedAt = "createdAt",
  CurrentWorkFLowStep = "currentWorkFLowStep",
  Data = "data",
  Deleted = "deleted",
  EstateReady = "estateReady",
  HasProbate = "hasProbate",
  HasTrust = "hasTrust",
  HasWill = "hasWill",
  Id = "id",
  PlanReady = "planReady",
  ProcessInstanceId = "processInstanceId",
  SurvivingSpouse = "survivingSpouse",
  TaxId = "taxId",
  UpdatedAt = "updatedAt",
}

export type EstateScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<EstateScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<EstateScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<EstateScalarWhereWithAggregatesInput>>;
  boxBeneficiaryFolderId?: InputMaybe<StringNullableWithAggregatesFilter>;
  boxEstateRootFolderId?: InputMaybe<StringNullableWithAggregatesFilter>;
  boxExecutorFolderId?: InputMaybe<StringNullableWithAggregatesFilter>;
  channelPartner?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  currentWorkFLowStep?: InputMaybe<IntWithAggregatesFilter>;
  data?: InputMaybe<JsonNullableWithAggregatesFilter>;
  deleted?: InputMaybe<BoolWithAggregatesFilter>;
  estateReady?: InputMaybe<BoolWithAggregatesFilter>;
  hasProbate?: InputMaybe<BoolNullableWithAggregatesFilter>;
  hasTrust?: InputMaybe<BoolNullableWithAggregatesFilter>;
  hasWill?: InputMaybe<BoolNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  planReady?: InputMaybe<BoolWithAggregatesFilter>;
  processInstanceId?: InputMaybe<StringNullableWithAggregatesFilter>;
  survivingSpouse?: InputMaybe<BoolNullableWithAggregatesFilter>;
  taxId?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type EstateSumAggregate = {
  __typename?: "EstateSumAggregate";
  currentWorkFLowStep?: Maybe<Scalars["Int"]["output"]>;
};

export type EstateSumOrderByAggregateInput = {
  currentWorkFLowStep?: InputMaybe<SortOrder>;
};

export type EstateUpdateInput = {
  Asset?: InputMaybe<AssetUpdateManyWithoutEstateNestedInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutEstateNestedInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedUpdateManyWithoutEstateNestedInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutEstateNestedInput>;
  alixFee?: InputMaybe<AlixFeeUpdateOneWithoutEstateNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutEstateNestedInput>;
  boxBeneficiaryFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxEstateRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxExecutorFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  careTeamNotes?: InputMaybe<CareTeamNoteUpdateManyWithoutEstateNestedInput>;
  channelPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentWorkFLowStep?: InputMaybe<IntFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  details?: InputMaybe<EstateDetailUpdateManyWithoutEstateNestedInput>;
  estateReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEstateNestedInput>;
  hasProbate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasWill?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  milestones?: InputMaybe<MilestoneUpdateManyWithoutEstateNestedInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutEstateNestedInput>;
  planReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  processInstanceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survivingSpouse?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutEstateNestedInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput>;
};

export type EstateUpdateInput2 = {
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput2>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput2>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput2>;
};

export type EstateUpdateManyMutationInput = {
  boxBeneficiaryFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxEstateRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxExecutorFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channelPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentWorkFLowStep?: InputMaybe<IntFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  estateReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hasProbate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasWill?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  planReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  processInstanceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survivingSpouse?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EstateUpdateOneRequiredWithoutActionCardsNestedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutActionCardsInput>;
  create?: InputMaybe<EstateCreateWithoutActionCardsInput>;
  update?: InputMaybe<EstateUpdateToOneWithWhereWithoutActionCardsInput>;
  upsert?: InputMaybe<EstateUpsertWithoutActionCardsInput>;
};

export type EstateUpdateOneRequiredWithoutActionCardsNestedInput2 = {
  connect?: InputMaybe<EstateWhereUniqueInput2>;
};

export type EstateUpdateOneRequiredWithoutAlixFeeNestedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutAlixFeeInput>;
  create?: InputMaybe<EstateCreateWithoutAlixFeeInput>;
  update?: InputMaybe<EstateUpdateToOneWithWhereWithoutAlixFeeInput>;
  upsert?: InputMaybe<EstateUpsertWithoutAlixFeeInput>;
};

export type EstateUpdateOneRequiredWithoutAssetNestedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutAssetInput>;
  create?: InputMaybe<EstateCreateWithoutAssetInput>;
  update?: InputMaybe<EstateUpdateToOneWithWhereWithoutAssetInput>;
  upsert?: InputMaybe<EstateUpsertWithoutAssetInput>;
};

export type EstateUpdateOneRequiredWithoutBeneficiariesNestedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutBeneficiariesInput>;
  create?: InputMaybe<EstateCreateWithoutBeneficiariesInput>;
  update?: InputMaybe<EstateUpdateToOneWithWhereWithoutBeneficiariesInput>;
  upsert?: InputMaybe<EstateUpsertWithoutBeneficiariesInput>;
};

export type EstateUpdateOneRequiredWithoutCareTeamNotesNestedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutCareTeamNotesInput>;
  create?: InputMaybe<EstateCreateWithoutCareTeamNotesInput>;
  update?: InputMaybe<EstateUpdateToOneWithWhereWithoutCareTeamNotesInput>;
  upsert?: InputMaybe<EstateUpsertWithoutCareTeamNotesInput>;
};

export type EstateUpdateOneRequiredWithoutComponentsNestedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutComponentsInput>;
  create?: InputMaybe<EstateCreateWithoutComponentsInput>;
  update?: InputMaybe<EstateUpdateToOneWithWhereWithoutComponentsInput>;
  upsert?: InputMaybe<EstateUpsertWithoutComponentsInput>;
};

export type EstateUpdateOneRequiredWithoutContactsOnEstatesNestedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutContactsOnEstatesInput>;
  create?: InputMaybe<EstateCreateWithoutContactsOnEstatesInput>;
  update?: InputMaybe<EstateUpdateToOneWithWhereWithoutContactsOnEstatesInput>;
  upsert?: InputMaybe<EstateUpsertWithoutContactsOnEstatesInput>;
};

export type EstateUpdateOneRequiredWithoutDeceasedNestedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutDeceasedInput>;
  create?: InputMaybe<EstateCreateWithoutDeceasedInput>;
  update?: InputMaybe<EstateUpdateToOneWithWhereWithoutDeceasedInput>;
  upsert?: InputMaybe<EstateUpsertWithoutDeceasedInput>;
};

export type EstateUpdateOneRequiredWithoutDeceasedNestedInput2 = {
  connect?: InputMaybe<EstateWhereUniqueInput2>;
};

export type EstateUpdateOneRequiredWithoutDetailsNestedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutDetailsInput>;
  create?: InputMaybe<EstateCreateWithoutDetailsInput>;
  update?: InputMaybe<EstateUpdateToOneWithWhereWithoutDetailsInput>;
  upsert?: InputMaybe<EstateUpsertWithoutDetailsInput>;
};

export type EstateUpdateOneRequiredWithoutDocumentsProcessedNestedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutDocumentsProcessedInput>;
  create?: InputMaybe<EstateCreateWithoutDocumentsProcessedInput>;
  update?: InputMaybe<EstateUpdateToOneWithWhereWithoutDocumentsProcessedInput>;
  upsert?: InputMaybe<EstateUpsertWithoutDocumentsProcessedInput>;
};

export type EstateUpdateOneRequiredWithoutExpensesNestedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutExpensesInput>;
  create?: InputMaybe<EstateCreateWithoutExpensesInput>;
  update?: InputMaybe<EstateUpdateToOneWithWhereWithoutExpensesInput>;
  upsert?: InputMaybe<EstateUpsertWithoutExpensesInput>;
};

export type EstateUpdateOneRequiredWithoutMilestonesNestedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutMilestonesInput>;
  create?: InputMaybe<EstateCreateWithoutMilestonesInput>;
  update?: InputMaybe<EstateUpdateToOneWithWhereWithoutMilestonesInput>;
  upsert?: InputMaybe<EstateUpsertWithoutMilestonesInput>;
};

export type EstateUpdateOneRequiredWithoutTasksNestedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<EstateCreateWithoutTasksInput>;
  update?: InputMaybe<EstateUpdateToOneWithWhereWithoutTasksInput>;
  upsert?: InputMaybe<EstateUpsertWithoutTasksInput>;
};

export type EstateUpdateOneRequiredWithoutUsersNestedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<EstateCreateWithoutUsersInput>;
  update?: InputMaybe<EstateUpdateToOneWithWhereWithoutUsersInput>;
  upsert?: InputMaybe<EstateUpsertWithoutUsersInput>;
};

export type EstateUpdateOneWithoutNotificationsNestedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EstateCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<EstateCreateWithoutNotificationsInput>;
  delete?: InputMaybe<EstateWhereInput>;
  disconnect?: InputMaybe<EstateWhereInput>;
  update?: InputMaybe<EstateUpdateToOneWithWhereWithoutNotificationsInput>;
  upsert?: InputMaybe<EstateUpsertWithoutNotificationsInput>;
};

export type EstateUpdateToOneWithWhereWithoutActionCardsInput = {
  data: EstateUpdateWithoutActionCardsInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpdateToOneWithWhereWithoutAlixFeeInput = {
  data: EstateUpdateWithoutAlixFeeInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpdateToOneWithWhereWithoutAssetInput = {
  data: EstateUpdateWithoutAssetInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpdateToOneWithWhereWithoutBeneficiariesInput = {
  data: EstateUpdateWithoutBeneficiariesInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpdateToOneWithWhereWithoutCareTeamNotesInput = {
  data: EstateUpdateWithoutCareTeamNotesInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpdateToOneWithWhereWithoutComponentsInput = {
  data: EstateUpdateWithoutComponentsInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpdateToOneWithWhereWithoutContactsOnEstatesInput = {
  data: EstateUpdateWithoutContactsOnEstatesInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpdateToOneWithWhereWithoutDeceasedInput = {
  data: EstateUpdateWithoutDeceasedInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpdateToOneWithWhereWithoutDetailsInput = {
  data: EstateUpdateWithoutDetailsInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpdateToOneWithWhereWithoutDocumentsProcessedInput = {
  data: EstateUpdateWithoutDocumentsProcessedInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpdateToOneWithWhereWithoutExpensesInput = {
  data: EstateUpdateWithoutExpensesInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpdateToOneWithWhereWithoutMilestonesInput = {
  data: EstateUpdateWithoutMilestonesInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpdateToOneWithWhereWithoutNotificationsInput = {
  data: EstateUpdateWithoutNotificationsInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpdateToOneWithWhereWithoutTasksInput = {
  data: EstateUpdateWithoutTasksInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpdateToOneWithWhereWithoutUsersInput = {
  data: EstateUpdateWithoutUsersInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpdateWithoutActionCardsInput = {
  Asset?: InputMaybe<AssetUpdateManyWithoutEstateNestedInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutEstateNestedInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedUpdateManyWithoutEstateNestedInput>;
  alixFee?: InputMaybe<AlixFeeUpdateOneWithoutEstateNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutEstateNestedInput>;
  boxBeneficiaryFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxEstateRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxExecutorFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  careTeamNotes?: InputMaybe<CareTeamNoteUpdateManyWithoutEstateNestedInput>;
  channelPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentWorkFLowStep?: InputMaybe<IntFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  details?: InputMaybe<EstateDetailUpdateManyWithoutEstateNestedInput>;
  estateReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEstateNestedInput>;
  hasProbate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasWill?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  milestones?: InputMaybe<MilestoneUpdateManyWithoutEstateNestedInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutEstateNestedInput>;
  planReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  processInstanceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survivingSpouse?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutEstateNestedInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput>;
};

export type EstateUpdateWithoutAlixFeeInput = {
  Asset?: InputMaybe<AssetUpdateManyWithoutEstateNestedInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutEstateNestedInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedUpdateManyWithoutEstateNestedInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutEstateNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutEstateNestedInput>;
  boxBeneficiaryFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxEstateRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxExecutorFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  careTeamNotes?: InputMaybe<CareTeamNoteUpdateManyWithoutEstateNestedInput>;
  channelPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentWorkFLowStep?: InputMaybe<IntFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  details?: InputMaybe<EstateDetailUpdateManyWithoutEstateNestedInput>;
  estateReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEstateNestedInput>;
  hasProbate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasWill?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  milestones?: InputMaybe<MilestoneUpdateManyWithoutEstateNestedInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutEstateNestedInput>;
  planReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  processInstanceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survivingSpouse?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutEstateNestedInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput>;
};

export type EstateUpdateWithoutAssetInput = {
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutEstateNestedInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedUpdateManyWithoutEstateNestedInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutEstateNestedInput>;
  alixFee?: InputMaybe<AlixFeeUpdateOneWithoutEstateNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutEstateNestedInput>;
  boxBeneficiaryFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxEstateRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxExecutorFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  careTeamNotes?: InputMaybe<CareTeamNoteUpdateManyWithoutEstateNestedInput>;
  channelPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentWorkFLowStep?: InputMaybe<IntFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  details?: InputMaybe<EstateDetailUpdateManyWithoutEstateNestedInput>;
  estateReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEstateNestedInput>;
  hasProbate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasWill?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  milestones?: InputMaybe<MilestoneUpdateManyWithoutEstateNestedInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutEstateNestedInput>;
  planReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  processInstanceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survivingSpouse?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutEstateNestedInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput>;
};

export type EstateUpdateWithoutBeneficiariesInput = {
  Asset?: InputMaybe<AssetUpdateManyWithoutEstateNestedInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutEstateNestedInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedUpdateManyWithoutEstateNestedInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutEstateNestedInput>;
  alixFee?: InputMaybe<AlixFeeUpdateOneWithoutEstateNestedInput>;
  boxBeneficiaryFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxEstateRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxExecutorFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  careTeamNotes?: InputMaybe<CareTeamNoteUpdateManyWithoutEstateNestedInput>;
  channelPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentWorkFLowStep?: InputMaybe<IntFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  details?: InputMaybe<EstateDetailUpdateManyWithoutEstateNestedInput>;
  estateReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEstateNestedInput>;
  hasProbate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasWill?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  milestones?: InputMaybe<MilestoneUpdateManyWithoutEstateNestedInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutEstateNestedInput>;
  planReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  processInstanceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survivingSpouse?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutEstateNestedInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput>;
};

export type EstateUpdateWithoutCareTeamNotesInput = {
  Asset?: InputMaybe<AssetUpdateManyWithoutEstateNestedInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutEstateNestedInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedUpdateManyWithoutEstateNestedInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutEstateNestedInput>;
  alixFee?: InputMaybe<AlixFeeUpdateOneWithoutEstateNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutEstateNestedInput>;
  boxBeneficiaryFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxEstateRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxExecutorFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channelPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentWorkFLowStep?: InputMaybe<IntFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  details?: InputMaybe<EstateDetailUpdateManyWithoutEstateNestedInput>;
  estateReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEstateNestedInput>;
  hasProbate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasWill?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  milestones?: InputMaybe<MilestoneUpdateManyWithoutEstateNestedInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutEstateNestedInput>;
  planReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  processInstanceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survivingSpouse?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutEstateNestedInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput>;
};

export type EstateUpdateWithoutComponentsInput = {
  Asset?: InputMaybe<AssetUpdateManyWithoutEstateNestedInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutEstateNestedInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedUpdateManyWithoutEstateNestedInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutEstateNestedInput>;
  alixFee?: InputMaybe<AlixFeeUpdateOneWithoutEstateNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutEstateNestedInput>;
  boxBeneficiaryFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxEstateRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxExecutorFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  careTeamNotes?: InputMaybe<CareTeamNoteUpdateManyWithoutEstateNestedInput>;
  channelPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentWorkFLowStep?: InputMaybe<IntFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  details?: InputMaybe<EstateDetailUpdateManyWithoutEstateNestedInput>;
  estateReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEstateNestedInput>;
  hasProbate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasWill?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  milestones?: InputMaybe<MilestoneUpdateManyWithoutEstateNestedInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutEstateNestedInput>;
  planReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  processInstanceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survivingSpouse?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutEstateNestedInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput>;
};

export type EstateUpdateWithoutContactsOnEstatesInput = {
  Asset?: InputMaybe<AssetUpdateManyWithoutEstateNestedInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedUpdateManyWithoutEstateNestedInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutEstateNestedInput>;
  alixFee?: InputMaybe<AlixFeeUpdateOneWithoutEstateNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutEstateNestedInput>;
  boxBeneficiaryFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxEstateRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxExecutorFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  careTeamNotes?: InputMaybe<CareTeamNoteUpdateManyWithoutEstateNestedInput>;
  channelPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentWorkFLowStep?: InputMaybe<IntFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  details?: InputMaybe<EstateDetailUpdateManyWithoutEstateNestedInput>;
  estateReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEstateNestedInput>;
  hasProbate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasWill?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  milestones?: InputMaybe<MilestoneUpdateManyWithoutEstateNestedInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutEstateNestedInput>;
  planReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  processInstanceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survivingSpouse?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutEstateNestedInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput>;
};

export type EstateUpdateWithoutDeceasedInput = {
  Asset?: InputMaybe<AssetUpdateManyWithoutEstateNestedInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutEstateNestedInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedUpdateManyWithoutEstateNestedInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutEstateNestedInput>;
  alixFee?: InputMaybe<AlixFeeUpdateOneWithoutEstateNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutEstateNestedInput>;
  boxBeneficiaryFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxEstateRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxExecutorFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  careTeamNotes?: InputMaybe<CareTeamNoteUpdateManyWithoutEstateNestedInput>;
  channelPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentWorkFLowStep?: InputMaybe<IntFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  details?: InputMaybe<EstateDetailUpdateManyWithoutEstateNestedInput>;
  estateReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEstateNestedInput>;
  hasProbate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasWill?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  milestones?: InputMaybe<MilestoneUpdateManyWithoutEstateNestedInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutEstateNestedInput>;
  planReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  processInstanceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survivingSpouse?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutEstateNestedInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput>;
};

export type EstateUpdateWithoutDetailsInput = {
  Asset?: InputMaybe<AssetUpdateManyWithoutEstateNestedInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutEstateNestedInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedUpdateManyWithoutEstateNestedInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutEstateNestedInput>;
  alixFee?: InputMaybe<AlixFeeUpdateOneWithoutEstateNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutEstateNestedInput>;
  boxBeneficiaryFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxEstateRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxExecutorFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  careTeamNotes?: InputMaybe<CareTeamNoteUpdateManyWithoutEstateNestedInput>;
  channelPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentWorkFLowStep?: InputMaybe<IntFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  estateReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEstateNestedInput>;
  hasProbate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasWill?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  milestones?: InputMaybe<MilestoneUpdateManyWithoutEstateNestedInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutEstateNestedInput>;
  planReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  processInstanceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survivingSpouse?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutEstateNestedInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput>;
};

export type EstateUpdateWithoutDocumentsProcessedInput = {
  Asset?: InputMaybe<AssetUpdateManyWithoutEstateNestedInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutEstateNestedInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutEstateNestedInput>;
  alixFee?: InputMaybe<AlixFeeUpdateOneWithoutEstateNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutEstateNestedInput>;
  boxBeneficiaryFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxEstateRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxExecutorFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  careTeamNotes?: InputMaybe<CareTeamNoteUpdateManyWithoutEstateNestedInput>;
  channelPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentWorkFLowStep?: InputMaybe<IntFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  details?: InputMaybe<EstateDetailUpdateManyWithoutEstateNestedInput>;
  estateReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEstateNestedInput>;
  hasProbate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasWill?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  milestones?: InputMaybe<MilestoneUpdateManyWithoutEstateNestedInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutEstateNestedInput>;
  planReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  processInstanceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survivingSpouse?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutEstateNestedInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput>;
};

export type EstateUpdateWithoutExpensesInput = {
  Asset?: InputMaybe<AssetUpdateManyWithoutEstateNestedInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutEstateNestedInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedUpdateManyWithoutEstateNestedInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutEstateNestedInput>;
  alixFee?: InputMaybe<AlixFeeUpdateOneWithoutEstateNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutEstateNestedInput>;
  boxBeneficiaryFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxEstateRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxExecutorFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  careTeamNotes?: InputMaybe<CareTeamNoteUpdateManyWithoutEstateNestedInput>;
  channelPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentWorkFLowStep?: InputMaybe<IntFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  details?: InputMaybe<EstateDetailUpdateManyWithoutEstateNestedInput>;
  estateReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hasProbate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasWill?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  milestones?: InputMaybe<MilestoneUpdateManyWithoutEstateNestedInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutEstateNestedInput>;
  planReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  processInstanceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survivingSpouse?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutEstateNestedInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput>;
};

export type EstateUpdateWithoutMilestonesInput = {
  Asset?: InputMaybe<AssetUpdateManyWithoutEstateNestedInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutEstateNestedInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedUpdateManyWithoutEstateNestedInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutEstateNestedInput>;
  alixFee?: InputMaybe<AlixFeeUpdateOneWithoutEstateNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutEstateNestedInput>;
  boxBeneficiaryFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxEstateRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxExecutorFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  careTeamNotes?: InputMaybe<CareTeamNoteUpdateManyWithoutEstateNestedInput>;
  channelPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentWorkFLowStep?: InputMaybe<IntFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  details?: InputMaybe<EstateDetailUpdateManyWithoutEstateNestedInput>;
  estateReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEstateNestedInput>;
  hasProbate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasWill?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutEstateNestedInput>;
  planReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  processInstanceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survivingSpouse?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutEstateNestedInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput>;
};

export type EstateUpdateWithoutNotificationsInput = {
  Asset?: InputMaybe<AssetUpdateManyWithoutEstateNestedInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutEstateNestedInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedUpdateManyWithoutEstateNestedInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutEstateNestedInput>;
  alixFee?: InputMaybe<AlixFeeUpdateOneWithoutEstateNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutEstateNestedInput>;
  boxBeneficiaryFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxEstateRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxExecutorFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  careTeamNotes?: InputMaybe<CareTeamNoteUpdateManyWithoutEstateNestedInput>;
  channelPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentWorkFLowStep?: InputMaybe<IntFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  details?: InputMaybe<EstateDetailUpdateManyWithoutEstateNestedInput>;
  estateReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEstateNestedInput>;
  hasProbate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasWill?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  milestones?: InputMaybe<MilestoneUpdateManyWithoutEstateNestedInput>;
  planReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  processInstanceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survivingSpouse?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutEstateNestedInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput>;
};

export type EstateUpdateWithoutTasksInput = {
  Asset?: InputMaybe<AssetUpdateManyWithoutEstateNestedInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutEstateNestedInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedUpdateManyWithoutEstateNestedInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutEstateNestedInput>;
  alixFee?: InputMaybe<AlixFeeUpdateOneWithoutEstateNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutEstateNestedInput>;
  boxBeneficiaryFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxEstateRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxExecutorFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  careTeamNotes?: InputMaybe<CareTeamNoteUpdateManyWithoutEstateNestedInput>;
  channelPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentWorkFLowStep?: InputMaybe<IntFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  details?: InputMaybe<EstateDetailUpdateManyWithoutEstateNestedInput>;
  estateReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEstateNestedInput>;
  hasProbate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasWill?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  milestones?: InputMaybe<MilestoneUpdateManyWithoutEstateNestedInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutEstateNestedInput>;
  planReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  processInstanceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survivingSpouse?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput>;
};

export type EstateUpdateWithoutUsersInput = {
  Asset?: InputMaybe<AssetUpdateManyWithoutEstateNestedInput>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesUpdateManyWithoutEstateNestedInput>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedUpdateManyWithoutEstateNestedInput>;
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutEstateNestedInput>;
  alixFee?: InputMaybe<AlixFeeUpdateOneWithoutEstateNestedInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutEstateNestedInput>;
  boxBeneficiaryFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxEstateRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxExecutorFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  careTeamNotes?: InputMaybe<CareTeamNoteUpdateManyWithoutEstateNestedInput>;
  channelPartner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentWorkFLowStep?: InputMaybe<IntFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput>;
  deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  details?: InputMaybe<EstateDetailUpdateManyWithoutEstateNestedInput>;
  estateReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEstateNestedInput>;
  hasProbate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasTrust?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasWill?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  milestones?: InputMaybe<MilestoneUpdateManyWithoutEstateNestedInput>;
  notifications?: InputMaybe<EstateNotificationUpdateManyWithoutEstateNestedInput>;
  planReady?: InputMaybe<BoolFieldUpdateOperationsInput>;
  processInstanceId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survivingSpouse?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutEstateNestedInput>;
  taxId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EstateUpsertWithoutActionCardsInput = {
  create: EstateCreateWithoutActionCardsInput;
  update: EstateUpdateWithoutActionCardsInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpsertWithoutAlixFeeInput = {
  create: EstateCreateWithoutAlixFeeInput;
  update: EstateUpdateWithoutAlixFeeInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpsertWithoutAssetInput = {
  create: EstateCreateWithoutAssetInput;
  update: EstateUpdateWithoutAssetInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpsertWithoutBeneficiariesInput = {
  create: EstateCreateWithoutBeneficiariesInput;
  update: EstateUpdateWithoutBeneficiariesInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpsertWithoutCareTeamNotesInput = {
  create: EstateCreateWithoutCareTeamNotesInput;
  update: EstateUpdateWithoutCareTeamNotesInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpsertWithoutComponentsInput = {
  create: EstateCreateWithoutComponentsInput;
  update: EstateUpdateWithoutComponentsInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpsertWithoutContactsOnEstatesInput = {
  create: EstateCreateWithoutContactsOnEstatesInput;
  update: EstateUpdateWithoutContactsOnEstatesInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpsertWithoutDeceasedInput = {
  create: EstateCreateWithoutDeceasedInput;
  update: EstateUpdateWithoutDeceasedInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpsertWithoutDetailsInput = {
  create: EstateCreateWithoutDetailsInput;
  update: EstateUpdateWithoutDetailsInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpsertWithoutDocumentsProcessedInput = {
  create: EstateCreateWithoutDocumentsProcessedInput;
  update: EstateUpdateWithoutDocumentsProcessedInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpsertWithoutExpensesInput = {
  create: EstateCreateWithoutExpensesInput;
  update: EstateUpdateWithoutExpensesInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpsertWithoutMilestonesInput = {
  create: EstateCreateWithoutMilestonesInput;
  update: EstateUpdateWithoutMilestonesInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpsertWithoutNotificationsInput = {
  create: EstateCreateWithoutNotificationsInput;
  update: EstateUpdateWithoutNotificationsInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpsertWithoutTasksInput = {
  create: EstateCreateWithoutTasksInput;
  update: EstateUpdateWithoutTasksInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateUpsertWithoutUsersInput = {
  create: EstateCreateWithoutUsersInput;
  update: EstateUpdateWithoutUsersInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type EstateWhereInput = {
  AND?: InputMaybe<Array<EstateWhereInput>>;
  Asset?: InputMaybe<AssetListRelationFilter>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesListRelationFilter>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedListRelationFilter>;
  NOT?: InputMaybe<Array<EstateWhereInput>>;
  OR?: InputMaybe<Array<EstateWhereInput>>;
  actionCards?: InputMaybe<ActionCardListRelationFilter>;
  alixFee?: InputMaybe<AlixFeeNullableRelationFilter>;
  beneficiaries?: InputMaybe<BeneficiaryListRelationFilter>;
  boxBeneficiaryFolderId?: InputMaybe<StringNullableFilter>;
  boxEstateRootFolderId?: InputMaybe<StringNullableFilter>;
  boxExecutorFolderId?: InputMaybe<StringNullableFilter>;
  careTeamNotes?: InputMaybe<CareTeamNoteListRelationFilter>;
  channelPartner?: InputMaybe<StringNullableFilter>;
  components?: InputMaybe<ComponentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currentWorkFLowStep?: InputMaybe<IntFilter>;
  data?: InputMaybe<JsonNullableFilter>;
  deceased?: InputMaybe<DeceasedNullableRelationFilter>;
  deleted?: InputMaybe<BoolFilter>;
  details?: InputMaybe<EstateDetailListRelationFilter>;
  estateReady?: InputMaybe<BoolFilter>;
  expenses?: InputMaybe<ExpenseListRelationFilter>;
  hasProbate?: InputMaybe<BoolNullableFilter>;
  hasTrust?: InputMaybe<BoolNullableFilter>;
  hasWill?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<StringFilter>;
  milestones?: InputMaybe<MilestoneListRelationFilter>;
  notifications?: InputMaybe<EstateNotificationListRelationFilter>;
  planReady?: InputMaybe<BoolFilter>;
  processInstanceId?: InputMaybe<StringNullableFilter>;
  survivingSpouse?: InputMaybe<BoolNullableFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  taxId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<UsersOnEstatesListRelationFilter>;
};

export type EstateWhereUniqueInput = {
  AND?: InputMaybe<Array<EstateWhereInput>>;
  Asset?: InputMaybe<AssetListRelationFilter>;
  ContactsOnEstates?: InputMaybe<ContactsOnEstatesListRelationFilter>;
  DocumentsProcessed?: InputMaybe<DocumentsProcessedListRelationFilter>;
  NOT?: InputMaybe<Array<EstateWhereInput>>;
  OR?: InputMaybe<Array<EstateWhereInput>>;
  actionCards?: InputMaybe<ActionCardListRelationFilter>;
  alixFee?: InputMaybe<AlixFeeNullableRelationFilter>;
  beneficiaries?: InputMaybe<BeneficiaryListRelationFilter>;
  boxBeneficiaryFolderId?: InputMaybe<StringNullableFilter>;
  boxEstateRootFolderId?: InputMaybe<StringNullableFilter>;
  boxExecutorFolderId?: InputMaybe<StringNullableFilter>;
  careTeamNotes?: InputMaybe<CareTeamNoteListRelationFilter>;
  channelPartner?: InputMaybe<StringNullableFilter>;
  components?: InputMaybe<ComponentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currentWorkFLowStep?: InputMaybe<IntFilter>;
  data?: InputMaybe<JsonNullableFilter>;
  deceased?: InputMaybe<DeceasedNullableRelationFilter>;
  deleted?: InputMaybe<BoolFilter>;
  details?: InputMaybe<EstateDetailListRelationFilter>;
  estateReady?: InputMaybe<BoolFilter>;
  expenses?: InputMaybe<ExpenseListRelationFilter>;
  hasProbate?: InputMaybe<BoolNullableFilter>;
  hasTrust?: InputMaybe<BoolNullableFilter>;
  hasWill?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  milestones?: InputMaybe<MilestoneListRelationFilter>;
  notifications?: InputMaybe<EstateNotificationListRelationFilter>;
  planReady?: InputMaybe<BoolFilter>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  survivingSpouse?: InputMaybe<BoolNullableFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  taxId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<UsersOnEstatesListRelationFilter>;
};

export type EstateWhereUniqueInput2 = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type ExecutorCreateNestedOneWithoutEstateInput = {
  contact?: InputMaybe<ContactCreateInput2>;
};

export type Expense = {
  __typename?: "Expense";
  amount: Scalars["Decimal"]["output"];
  component?: Maybe<Component>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  date: Scalars["DateTimeISO"]["output"];
  estate: Estate;
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  paid: Scalars["Boolean"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type ExpenseComponentArgs = {
  where?: InputMaybe<ComponentWhereInput>;
};

export type ExpenseUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type Expense2 = {
  __typename?: "Expense2";
  amount: Scalars["Decimal"]["output"];
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  date: Scalars["DateTimeISO"]["output"];
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  paid: Scalars["Boolean"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type ExpenseAvgAggregate = {
  __typename?: "ExpenseAvgAggregate";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
};

export type ExpenseAvgOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
};

export type ExpenseCountAggregate = {
  __typename?: "ExpenseCountAggregate";
  _all: Scalars["Int"]["output"];
  amount: Scalars["Int"]["output"];
  componentId: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  date: Scalars["Int"]["output"];
  estateId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["Int"]["output"];
  paid: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  userId: Scalars["Int"]["output"];
};

export type ExpenseCountOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  paid?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ExpenseCreateInput = {
  amount: Scalars["Decimal"]["input"];
  component?: InputMaybe<ComponentCreateNestedOneWithoutExpensesInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date: Scalars["DateTimeISO"]["input"];
  estate: EstateCreateNestedOneWithoutExpensesInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  paid?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutExpensesInput>;
};

export type ExpenseCreateManyComponentInput = {
  amount: Scalars["Decimal"]["input"];
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date: Scalars["DateTimeISO"]["input"];
  estateId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  paid?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ExpenseCreateManyComponentInputEnvelope = {
  data: Array<ExpenseCreateManyComponentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ExpenseCreateManyEstateInput = {
  amount: Scalars["Decimal"]["input"];
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date: Scalars["DateTimeISO"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  paid?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ExpenseCreateManyEstateInputEnvelope = {
  data: Array<ExpenseCreateManyEstateInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ExpenseCreateManyInput = {
  amount: Scalars["Decimal"]["input"];
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date: Scalars["DateTimeISO"]["input"];
  estateId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  paid?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ExpenseCreateManyUserInput = {
  amount: Scalars["Decimal"]["input"];
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date: Scalars["DateTimeISO"]["input"];
  estateId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  paid?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type ExpenseCreateManyUserInputEnvelope = {
  data: Array<ExpenseCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ExpenseCreateNestedManyWithoutComponentInput = {
  connect?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ExpenseCreateOrConnectWithoutComponentInput>
  >;
  create?: InputMaybe<Array<ExpenseCreateWithoutComponentInput>>;
  createMany?: InputMaybe<ExpenseCreateManyComponentInputEnvelope>;
};

export type ExpenseCreateNestedManyWithoutEstateInput = {
  connect?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ExpenseCreateOrConnectWithoutEstateInput>>;
  create?: InputMaybe<Array<ExpenseCreateWithoutEstateInput>>;
  createMany?: InputMaybe<ExpenseCreateManyEstateInputEnvelope>;
};

export type ExpenseCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ExpenseCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ExpenseCreateWithoutUserInput>>;
  createMany?: InputMaybe<ExpenseCreateManyUserInputEnvelope>;
};

export type ExpenseCreateOrConnectWithoutComponentInput = {
  create: ExpenseCreateWithoutComponentInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseCreateOrConnectWithoutEstateInput = {
  create: ExpenseCreateWithoutEstateInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseCreateOrConnectWithoutUserInput = {
  create: ExpenseCreateWithoutUserInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseCreateWithoutComponentInput = {
  amount: Scalars["Decimal"]["input"];
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date: Scalars["DateTimeISO"]["input"];
  estate: EstateCreateNestedOneWithoutExpensesInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  paid?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutExpensesInput>;
};

export type ExpenseCreateWithoutEstateInput = {
  amount: Scalars["Decimal"]["input"];
  component?: InputMaybe<ComponentCreateNestedOneWithoutExpensesInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date: Scalars["DateTimeISO"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  paid?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutExpensesInput>;
};

export type ExpenseCreateWithoutUserInput = {
  amount: Scalars["Decimal"]["input"];
  component?: InputMaybe<ComponentCreateNestedOneWithoutExpensesInput>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date: Scalars["DateTimeISO"]["input"];
  estate: EstateCreateNestedOneWithoutExpensesInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  paid?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type ExpenseGroupBy = {
  __typename?: "ExpenseGroupBy";
  _avg?: Maybe<ExpenseAvgAggregate>;
  _count?: Maybe<ExpenseCountAggregate>;
  _max?: Maybe<ExpenseMaxAggregate>;
  _min?: Maybe<ExpenseMinAggregate>;
  _sum?: Maybe<ExpenseSumAggregate>;
  amount: Scalars["Decimal"]["output"];
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  date: Scalars["DateTimeISO"]["output"];
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  paid: Scalars["Boolean"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type ExpenseListRelationFilter = {
  every?: InputMaybe<ExpenseWhereInput>;
  none?: InputMaybe<ExpenseWhereInput>;
  some?: InputMaybe<ExpenseWhereInput>;
};

export type ExpenseMaxAggregate = {
  __typename?: "ExpenseMaxAggregate";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  date?: Maybe<Scalars["DateTimeISO"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  paid?: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type ExpenseMaxOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  paid?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ExpenseMinAggregate = {
  __typename?: "ExpenseMinAggregate";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  date?: Maybe<Scalars["DateTimeISO"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  paid?: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type ExpenseMinOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  paid?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ExpenseOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ExpenseOrderByWithAggregationInput = {
  _avg?: InputMaybe<ExpenseAvgOrderByAggregateInput>;
  _count?: InputMaybe<ExpenseCountOrderByAggregateInput>;
  _max?: InputMaybe<ExpenseMaxOrderByAggregateInput>;
  _min?: InputMaybe<ExpenseMinOrderByAggregateInput>;
  _sum?: InputMaybe<ExpenseSumOrderByAggregateInput>;
  amount?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  paid?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrderInput>;
};

export type ExpenseOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  component?: InputMaybe<ComponentOrderByWithRelationInput>;
  componentId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  estate?: InputMaybe<EstateOrderByWithRelationInput>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  paid?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
};

export enum ExpenseScalarFieldEnum {
  Amount = "amount",
  ComponentId = "componentId",
  CreatedAt = "createdAt",
  Date = "date",
  EstateId = "estateId",
  Id = "id",
  Name = "name",
  Paid = "paid",
  UpdatedAt = "updatedAt",
  UserId = "userId",
}

export type ExpenseScalarWhereInput = {
  AND?: InputMaybe<Array<ExpenseScalarWhereInput>>;
  NOT?: InputMaybe<Array<ExpenseScalarWhereInput>>;
  OR?: InputMaybe<Array<ExpenseScalarWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  paid?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type ExpenseScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ExpenseScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ExpenseScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ExpenseScalarWhereWithAggregatesInput>>;
  amount?: InputMaybe<DecimalWithAggregatesFilter>;
  componentId?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  date?: InputMaybe<DateTimeWithAggregatesFilter>;
  estateId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  paid?: InputMaybe<BoolWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type ExpenseSumAggregate = {
  __typename?: "ExpenseSumAggregate";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
};

export type ExpenseSumOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
};

export type ExpenseUpdateInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutExpensesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutExpensesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutExpensesNestedInput>;
};

export type ExpenseUpdateManyMutationInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExpenseUpdateManyWithWhereWithoutComponentInput = {
  data: ExpenseUpdateManyMutationInput;
  where: ExpenseScalarWhereInput;
};

export type ExpenseUpdateManyWithWhereWithoutEstateInput = {
  data: ExpenseUpdateManyMutationInput;
  where: ExpenseScalarWhereInput;
};

export type ExpenseUpdateManyWithWhereWithoutUserInput = {
  data: ExpenseUpdateManyMutationInput;
  where: ExpenseScalarWhereInput;
};

export type ExpenseUpdateManyWithoutComponentNestedInput = {
  connect?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ExpenseCreateOrConnectWithoutComponentInput>
  >;
  create?: InputMaybe<Array<ExpenseCreateWithoutComponentInput>>;
  createMany?: InputMaybe<ExpenseCreateManyComponentInputEnvelope>;
  delete?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ExpenseScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  set?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  update?: InputMaybe<Array<ExpenseUpdateWithWhereUniqueWithoutComponentInput>>;
  updateMany?: InputMaybe<
    Array<ExpenseUpdateManyWithWhereWithoutComponentInput>
  >;
  upsert?: InputMaybe<Array<ExpenseUpsertWithWhereUniqueWithoutComponentInput>>;
};

export type ExpenseUpdateManyWithoutEstateNestedInput = {
  connect?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ExpenseCreateOrConnectWithoutEstateInput>>;
  create?: InputMaybe<Array<ExpenseCreateWithoutEstateInput>>;
  createMany?: InputMaybe<ExpenseCreateManyEstateInputEnvelope>;
  delete?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ExpenseScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  set?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  update?: InputMaybe<Array<ExpenseUpdateWithWhereUniqueWithoutEstateInput>>;
  updateMany?: InputMaybe<Array<ExpenseUpdateManyWithWhereWithoutEstateInput>>;
  upsert?: InputMaybe<Array<ExpenseUpsertWithWhereUniqueWithoutEstateInput>>;
};

export type ExpenseUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ExpenseCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ExpenseCreateWithoutUserInput>>;
  createMany?: InputMaybe<ExpenseCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ExpenseScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  set?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  update?: InputMaybe<Array<ExpenseUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<ExpenseUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<ExpenseUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ExpenseUpdateWithWhereUniqueWithoutComponentInput = {
  data: ExpenseUpdateWithoutComponentInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseUpdateWithWhereUniqueWithoutEstateInput = {
  data: ExpenseUpdateWithoutEstateInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseUpdateWithWhereUniqueWithoutUserInput = {
  data: ExpenseUpdateWithoutUserInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseUpdateWithoutComponentInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutExpensesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutExpensesNestedInput>;
};

export type ExpenseUpdateWithoutEstateInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutExpensesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutExpensesNestedInput>;
};

export type ExpenseUpdateWithoutUserInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  component?: InputMaybe<ComponentUpdateOneWithoutExpensesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutExpensesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExpenseUpsertWithWhereUniqueWithoutComponentInput = {
  create: ExpenseCreateWithoutComponentInput;
  update: ExpenseUpdateWithoutComponentInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseUpsertWithWhereUniqueWithoutEstateInput = {
  create: ExpenseCreateWithoutEstateInput;
  update: ExpenseUpdateWithoutEstateInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseUpsertWithWhereUniqueWithoutUserInput = {
  create: ExpenseCreateWithoutUserInput;
  update: ExpenseUpdateWithoutUserInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseWhereInput = {
  AND?: InputMaybe<Array<ExpenseWhereInput>>;
  NOT?: InputMaybe<Array<ExpenseWhereInput>>;
  OR?: InputMaybe<Array<ExpenseWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  component?: InputMaybe<ComponentNullableRelationFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  paid?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type ExpenseWhereUniqueInput = {
  AND?: InputMaybe<Array<ExpenseWhereInput>>;
  NOT?: InputMaybe<Array<ExpenseWhereInput>>;
  OR?: InputMaybe<Array<ExpenseWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  component?: InputMaybe<ComponentNullableRelationFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  paid?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type ExpenseWhereUniqueInput2 = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export enum FeeType {
  Flat = "FLAT",
  Percentage = "PERCENTAGE",
}

export type FormTemplate = {
  __typename?: "FormTemplate";
  id: Scalars["ID"]["output"];
  title: Scalars["String"]["output"];
};

export type FormTemplateLink = {
  __typename?: "FormTemplateLink";
  createdAt: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  url: Scalars["String"]["output"];
};

export type GetContentListGroupedByEstateFilter = {
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  dateRange?: InputMaybe<DateRangeInput>;
  estateIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status?: InputMaybe<ContentStatus>;
};

export type GetLinkTokenResponse = {
  __typename?: "GetLinkTokenResponse";
  linkToken: Scalars["String"]["output"];
};

export type Info = {
  __typename?: "Info";
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Int"]["input"]>;
  divide?: InputMaybe<Scalars["Int"]["input"]>;
  increment?: InputMaybe<Scalars["Int"]["input"]>;
  multiply?: InputMaybe<Scalars["Int"]["input"]>;
  set?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars["JSON"]["input"]>;
  array_ends_with?: InputMaybe<Scalars["JSON"]["input"]>;
  array_starts_with?: InputMaybe<Scalars["JSON"]["input"]>;
  equals?: InputMaybe<Scalars["JSON"]["input"]>;
  gt?: InputMaybe<Scalars["JSON"]["input"]>;
  gte?: InputMaybe<Scalars["JSON"]["input"]>;
  lt?: InputMaybe<Scalars["JSON"]["input"]>;
  lte?: InputMaybe<Scalars["JSON"]["input"]>;
  not?: InputMaybe<Scalars["JSON"]["input"]>;
  path?: InputMaybe<Array<Scalars["String"]["input"]>>;
  string_contains?: InputMaybe<Scalars["String"]["input"]>;
  string_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  string_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars["JSON"]["input"]>;
  array_ends_with?: InputMaybe<Scalars["JSON"]["input"]>;
  array_starts_with?: InputMaybe<Scalars["JSON"]["input"]>;
  equals?: InputMaybe<Scalars["JSON"]["input"]>;
  gt?: InputMaybe<Scalars["JSON"]["input"]>;
  gte?: InputMaybe<Scalars["JSON"]["input"]>;
  lt?: InputMaybe<Scalars["JSON"]["input"]>;
  lte?: InputMaybe<Scalars["JSON"]["input"]>;
  not?: InputMaybe<Scalars["JSON"]["input"]>;
  path?: InputMaybe<Array<Scalars["String"]["input"]>>;
  string_contains?: InputMaybe<Scalars["String"]["input"]>;
  string_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  string_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export type JsonNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedJsonNullableFilter>;
  _min?: InputMaybe<NestedJsonNullableFilter>;
  array_contains?: InputMaybe<Scalars["JSON"]["input"]>;
  array_ends_with?: InputMaybe<Scalars["JSON"]["input"]>;
  array_starts_with?: InputMaybe<Scalars["JSON"]["input"]>;
  equals?: InputMaybe<Scalars["JSON"]["input"]>;
  gt?: InputMaybe<Scalars["JSON"]["input"]>;
  gte?: InputMaybe<Scalars["JSON"]["input"]>;
  lt?: InputMaybe<Scalars["JSON"]["input"]>;
  lte?: InputMaybe<Scalars["JSON"]["input"]>;
  not?: InputMaybe<Scalars["JSON"]["input"]>;
  path?: InputMaybe<Array<Scalars["String"]["input"]>>;
  string_contains?: InputMaybe<Scalars["String"]["input"]>;
  string_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  string_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export type JsonWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedJsonFilter>;
  _min?: InputMaybe<NestedJsonFilter>;
  array_contains?: InputMaybe<Scalars["JSON"]["input"]>;
  array_ends_with?: InputMaybe<Scalars["JSON"]["input"]>;
  array_starts_with?: InputMaybe<Scalars["JSON"]["input"]>;
  equals?: InputMaybe<Scalars["JSON"]["input"]>;
  gt?: InputMaybe<Scalars["JSON"]["input"]>;
  gte?: InputMaybe<Scalars["JSON"]["input"]>;
  lt?: InputMaybe<Scalars["JSON"]["input"]>;
  lte?: InputMaybe<Scalars["JSON"]["input"]>;
  not?: InputMaybe<Scalars["JSON"]["input"]>;
  path?: InputMaybe<Array<Scalars["String"]["input"]>>;
  string_contains?: InputMaybe<Scalars["String"]["input"]>;
  string_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  string_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export type LinkedAccount = {
  __typename?: "LinkedAccount";
  _count?: Maybe<LinkedAccountCount>;
  accountId: Scalars["String"]["output"];
  availableBalance?: Maybe<Scalars["Decimal"]["output"]>;
  balanceLimit?: Maybe<Scalars["Decimal"]["output"]>;
  component: Component;
  componentId: Scalars["String"]["output"];
  createdAt: Scalars["DateTimeISO"]["output"];
  currentBalance?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["String"]["output"];
  isoCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  lastUpdatedDatetime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  mask?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  officialName?: Maybe<Scalars["String"]["output"]>;
  persistentAccountId?: Maybe<Scalars["String"]["output"]>;
  subtype?: Maybe<Scalars["String"]["output"]>;
  transactions: Array<Transaction>;
  type: Scalars["String"]["output"];
  unofficialCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  verificationStatus?: Maybe<Scalars["String"]["output"]>;
};

export type LinkedAccountTransactionsArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type LinkedAccountAvgAggregate = {
  __typename?: "LinkedAccountAvgAggregate";
  availableBalance?: Maybe<Scalars["Decimal"]["output"]>;
  balanceLimit?: Maybe<Scalars["Decimal"]["output"]>;
  currentBalance?: Maybe<Scalars["Decimal"]["output"]>;
};

export type LinkedAccountAvgOrderByAggregateInput = {
  availableBalance?: InputMaybe<SortOrder>;
  balanceLimit?: InputMaybe<SortOrder>;
  currentBalance?: InputMaybe<SortOrder>;
};

export type LinkedAccountCount = {
  __typename?: "LinkedAccountCount";
  transactions: Scalars["Int"]["output"];
};

export type LinkedAccountCountTransactionsArgs = {
  where?: InputMaybe<TransactionWhereInput>;
};

export type LinkedAccountCountAggregate = {
  __typename?: "LinkedAccountCountAggregate";
  _all: Scalars["Int"]["output"];
  accountId: Scalars["Int"]["output"];
  availableBalance: Scalars["Int"]["output"];
  balanceLimit: Scalars["Int"]["output"];
  componentId: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  currentBalance: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  isoCurrencyCode: Scalars["Int"]["output"];
  lastUpdatedDatetime: Scalars["Int"]["output"];
  mask: Scalars["Int"]["output"];
  name: Scalars["Int"]["output"];
  officialName: Scalars["Int"]["output"];
  persistentAccountId: Scalars["Int"]["output"];
  subtype: Scalars["Int"]["output"];
  type: Scalars["Int"]["output"];
  unofficialCurrencyCode: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  verificationStatus: Scalars["Int"]["output"];
};

export type LinkedAccountCountOrderByAggregateInput = {
  accountId?: InputMaybe<SortOrder>;
  availableBalance?: InputMaybe<SortOrder>;
  balanceLimit?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currentBalance?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isoCurrencyCode?: InputMaybe<SortOrder>;
  lastUpdatedDatetime?: InputMaybe<SortOrder>;
  mask?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  officialName?: InputMaybe<SortOrder>;
  persistentAccountId?: InputMaybe<SortOrder>;
  subtype?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  unofficialCurrencyCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  verificationStatus?: InputMaybe<SortOrder>;
};

export type LinkedAccountCreateInput = {
  accountId: Scalars["String"]["input"];
  availableBalance?: InputMaybe<Scalars["Decimal"]["input"]>;
  balanceLimit?: InputMaybe<Scalars["Decimal"]["input"]>;
  component: ComponentCreateNestedOneWithoutLinkedAccountsInput;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentBalance?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isoCurrencyCode?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdatedDatetime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  mask?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  officialName?: InputMaybe<Scalars["String"]["input"]>;
  persistentAccountId?: InputMaybe<Scalars["String"]["input"]>;
  subtype?: InputMaybe<Scalars["String"]["input"]>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutLinkedAccountInput>;
  type: Scalars["String"]["input"];
  unofficialCurrencyCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  verificationStatus?: InputMaybe<Scalars["String"]["input"]>;
};

export type LinkedAccountCreateManyComponentInput = {
  accountId: Scalars["String"]["input"];
  availableBalance?: InputMaybe<Scalars["Decimal"]["input"]>;
  balanceLimit?: InputMaybe<Scalars["Decimal"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentBalance?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isoCurrencyCode?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdatedDatetime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  mask?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  officialName?: InputMaybe<Scalars["String"]["input"]>;
  persistentAccountId?: InputMaybe<Scalars["String"]["input"]>;
  subtype?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  unofficialCurrencyCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  verificationStatus?: InputMaybe<Scalars["String"]["input"]>;
};

export type LinkedAccountCreateManyComponentInputEnvelope = {
  data: Array<LinkedAccountCreateManyComponentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type LinkedAccountCreateManyInput = {
  accountId: Scalars["String"]["input"];
  availableBalance?: InputMaybe<Scalars["Decimal"]["input"]>;
  balanceLimit?: InputMaybe<Scalars["Decimal"]["input"]>;
  componentId: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentBalance?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isoCurrencyCode?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdatedDatetime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  mask?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  officialName?: InputMaybe<Scalars["String"]["input"]>;
  persistentAccountId?: InputMaybe<Scalars["String"]["input"]>;
  subtype?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  unofficialCurrencyCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  verificationStatus?: InputMaybe<Scalars["String"]["input"]>;
};

export type LinkedAccountCreateNestedManyWithoutComponentInput = {
  connect?: InputMaybe<Array<LinkedAccountWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LinkedAccountCreateOrConnectWithoutComponentInput>
  >;
  create?: InputMaybe<Array<LinkedAccountCreateWithoutComponentInput>>;
  createMany?: InputMaybe<LinkedAccountCreateManyComponentInputEnvelope>;
};

export type LinkedAccountCreateNestedOneWithoutTransactionsInput = {
  connect?: InputMaybe<LinkedAccountWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LinkedAccountCreateOrConnectWithoutTransactionsInput>;
  create?: InputMaybe<LinkedAccountCreateWithoutTransactionsInput>;
};

export type LinkedAccountCreateOrConnectWithoutComponentInput = {
  create: LinkedAccountCreateWithoutComponentInput;
  where: LinkedAccountWhereUniqueInput;
};

export type LinkedAccountCreateOrConnectWithoutTransactionsInput = {
  create: LinkedAccountCreateWithoutTransactionsInput;
  where: LinkedAccountWhereUniqueInput;
};

export type LinkedAccountCreateWithoutComponentInput = {
  accountId: Scalars["String"]["input"];
  availableBalance?: InputMaybe<Scalars["Decimal"]["input"]>;
  balanceLimit?: InputMaybe<Scalars["Decimal"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentBalance?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isoCurrencyCode?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdatedDatetime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  mask?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  officialName?: InputMaybe<Scalars["String"]["input"]>;
  persistentAccountId?: InputMaybe<Scalars["String"]["input"]>;
  subtype?: InputMaybe<Scalars["String"]["input"]>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutLinkedAccountInput>;
  type: Scalars["String"]["input"];
  unofficialCurrencyCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  verificationStatus?: InputMaybe<Scalars["String"]["input"]>;
};

export type LinkedAccountCreateWithoutTransactionsInput = {
  accountId: Scalars["String"]["input"];
  availableBalance?: InputMaybe<Scalars["Decimal"]["input"]>;
  balanceLimit?: InputMaybe<Scalars["Decimal"]["input"]>;
  component: ComponentCreateNestedOneWithoutLinkedAccountsInput;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentBalance?: InputMaybe<Scalars["Decimal"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isoCurrencyCode?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdatedDatetime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  mask?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  officialName?: InputMaybe<Scalars["String"]["input"]>;
  persistentAccountId?: InputMaybe<Scalars["String"]["input"]>;
  subtype?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  unofficialCurrencyCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  verificationStatus?: InputMaybe<Scalars["String"]["input"]>;
};

export type LinkedAccountGroupBy = {
  __typename?: "LinkedAccountGroupBy";
  _avg?: Maybe<LinkedAccountAvgAggregate>;
  _count?: Maybe<LinkedAccountCountAggregate>;
  _max?: Maybe<LinkedAccountMaxAggregate>;
  _min?: Maybe<LinkedAccountMinAggregate>;
  _sum?: Maybe<LinkedAccountSumAggregate>;
  accountId: Scalars["String"]["output"];
  availableBalance?: Maybe<Scalars["Decimal"]["output"]>;
  balanceLimit?: Maybe<Scalars["Decimal"]["output"]>;
  componentId: Scalars["String"]["output"];
  createdAt: Scalars["DateTimeISO"]["output"];
  currentBalance?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["String"]["output"];
  isoCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  lastUpdatedDatetime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  mask?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  officialName?: Maybe<Scalars["String"]["output"]>;
  persistentAccountId?: Maybe<Scalars["String"]["output"]>;
  subtype?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
  unofficialCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  verificationStatus?: Maybe<Scalars["String"]["output"]>;
};

export type LinkedAccountListRelationFilter = {
  every?: InputMaybe<LinkedAccountWhereInput>;
  none?: InputMaybe<LinkedAccountWhereInput>;
  some?: InputMaybe<LinkedAccountWhereInput>;
};

export type LinkedAccountMaxAggregate = {
  __typename?: "LinkedAccountMaxAggregate";
  accountId?: Maybe<Scalars["String"]["output"]>;
  availableBalance?: Maybe<Scalars["Decimal"]["output"]>;
  balanceLimit?: Maybe<Scalars["Decimal"]["output"]>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  currentBalance?: Maybe<Scalars["Decimal"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  isoCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  lastUpdatedDatetime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  mask?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  officialName?: Maybe<Scalars["String"]["output"]>;
  persistentAccountId?: Maybe<Scalars["String"]["output"]>;
  subtype?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  unofficialCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  verificationStatus?: Maybe<Scalars["String"]["output"]>;
};

export type LinkedAccountMaxOrderByAggregateInput = {
  accountId?: InputMaybe<SortOrder>;
  availableBalance?: InputMaybe<SortOrder>;
  balanceLimit?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currentBalance?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isoCurrencyCode?: InputMaybe<SortOrder>;
  lastUpdatedDatetime?: InputMaybe<SortOrder>;
  mask?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  officialName?: InputMaybe<SortOrder>;
  persistentAccountId?: InputMaybe<SortOrder>;
  subtype?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  unofficialCurrencyCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  verificationStatus?: InputMaybe<SortOrder>;
};

export type LinkedAccountMinAggregate = {
  __typename?: "LinkedAccountMinAggregate";
  accountId?: Maybe<Scalars["String"]["output"]>;
  availableBalance?: Maybe<Scalars["Decimal"]["output"]>;
  balanceLimit?: Maybe<Scalars["Decimal"]["output"]>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  currentBalance?: Maybe<Scalars["Decimal"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  isoCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  lastUpdatedDatetime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  mask?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  officialName?: Maybe<Scalars["String"]["output"]>;
  persistentAccountId?: Maybe<Scalars["String"]["output"]>;
  subtype?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  unofficialCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  verificationStatus?: Maybe<Scalars["String"]["output"]>;
};

export type LinkedAccountMinOrderByAggregateInput = {
  accountId?: InputMaybe<SortOrder>;
  availableBalance?: InputMaybe<SortOrder>;
  balanceLimit?: InputMaybe<SortOrder>;
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currentBalance?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isoCurrencyCode?: InputMaybe<SortOrder>;
  lastUpdatedDatetime?: InputMaybe<SortOrder>;
  mask?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  officialName?: InputMaybe<SortOrder>;
  persistentAccountId?: InputMaybe<SortOrder>;
  subtype?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  unofficialCurrencyCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  verificationStatus?: InputMaybe<SortOrder>;
};

export type LinkedAccountOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type LinkedAccountOrderByWithAggregationInput = {
  _avg?: InputMaybe<LinkedAccountAvgOrderByAggregateInput>;
  _count?: InputMaybe<LinkedAccountCountOrderByAggregateInput>;
  _max?: InputMaybe<LinkedAccountMaxOrderByAggregateInput>;
  _min?: InputMaybe<LinkedAccountMinOrderByAggregateInput>;
  _sum?: InputMaybe<LinkedAccountSumOrderByAggregateInput>;
  accountId?: InputMaybe<SortOrder>;
  availableBalance?: InputMaybe<SortOrderInput>;
  balanceLimit?: InputMaybe<SortOrderInput>;
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currentBalance?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isoCurrencyCode?: InputMaybe<SortOrderInput>;
  lastUpdatedDatetime?: InputMaybe<SortOrderInput>;
  mask?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  officialName?: InputMaybe<SortOrderInput>;
  persistentAccountId?: InputMaybe<SortOrderInput>;
  subtype?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  unofficialCurrencyCode?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  verificationStatus?: InputMaybe<SortOrderInput>;
};

export type LinkedAccountOrderByWithRelationInput = {
  accountId?: InputMaybe<SortOrder>;
  availableBalance?: InputMaybe<SortOrderInput>;
  balanceLimit?: InputMaybe<SortOrderInput>;
  component?: InputMaybe<ComponentOrderByWithRelationInput>;
  componentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currentBalance?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isoCurrencyCode?: InputMaybe<SortOrderInput>;
  lastUpdatedDatetime?: InputMaybe<SortOrderInput>;
  mask?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  officialName?: InputMaybe<SortOrderInput>;
  persistentAccountId?: InputMaybe<SortOrderInput>;
  subtype?: InputMaybe<SortOrderInput>;
  transactions?: InputMaybe<TransactionOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
  unofficialCurrencyCode?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  verificationStatus?: InputMaybe<SortOrderInput>;
};

export type LinkedAccountRelationFilter = {
  is?: InputMaybe<LinkedAccountWhereInput>;
  isNot?: InputMaybe<LinkedAccountWhereInput>;
};

export enum LinkedAccountScalarFieldEnum {
  AccountId = "accountId",
  AvailableBalance = "availableBalance",
  BalanceLimit = "balanceLimit",
  ComponentId = "componentId",
  CreatedAt = "createdAt",
  CurrentBalance = "currentBalance",
  Id = "id",
  IsoCurrencyCode = "isoCurrencyCode",
  LastUpdatedDatetime = "lastUpdatedDatetime",
  Mask = "mask",
  Name = "name",
  OfficialName = "officialName",
  PersistentAccountId = "persistentAccountId",
  Subtype = "subtype",
  Type = "type",
  UnofficialCurrencyCode = "unofficialCurrencyCode",
  UpdatedAt = "updatedAt",
  VerificationStatus = "verificationStatus",
}

export type LinkedAccountScalarWhereInput = {
  AND?: InputMaybe<Array<LinkedAccountScalarWhereInput>>;
  NOT?: InputMaybe<Array<LinkedAccountScalarWhereInput>>;
  OR?: InputMaybe<Array<LinkedAccountScalarWhereInput>>;
  accountId?: InputMaybe<StringFilter>;
  availableBalance?: InputMaybe<DecimalNullableFilter>;
  balanceLimit?: InputMaybe<DecimalNullableFilter>;
  componentId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currentBalance?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isoCurrencyCode?: InputMaybe<StringNullableFilter>;
  lastUpdatedDatetime?: InputMaybe<DateTimeNullableFilter>;
  mask?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  officialName?: InputMaybe<StringNullableFilter>;
  persistentAccountId?: InputMaybe<StringNullableFilter>;
  subtype?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringFilter>;
  unofficialCurrencyCode?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  verificationStatus?: InputMaybe<StringNullableFilter>;
};

export type LinkedAccountScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<LinkedAccountScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<LinkedAccountScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<LinkedAccountScalarWhereWithAggregatesInput>>;
  accountId?: InputMaybe<StringWithAggregatesFilter>;
  availableBalance?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  balanceLimit?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  componentId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  currentBalance?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isoCurrencyCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  lastUpdatedDatetime?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  mask?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  officialName?: InputMaybe<StringNullableWithAggregatesFilter>;
  persistentAccountId?: InputMaybe<StringNullableWithAggregatesFilter>;
  subtype?: InputMaybe<StringNullableWithAggregatesFilter>;
  type?: InputMaybe<StringWithAggregatesFilter>;
  unofficialCurrencyCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  verificationStatus?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type LinkedAccountSumAggregate = {
  __typename?: "LinkedAccountSumAggregate";
  availableBalance?: Maybe<Scalars["Decimal"]["output"]>;
  balanceLimit?: Maybe<Scalars["Decimal"]["output"]>;
  currentBalance?: Maybe<Scalars["Decimal"]["output"]>;
};

export type LinkedAccountSumOrderByAggregateInput = {
  availableBalance?: InputMaybe<SortOrder>;
  balanceLimit?: InputMaybe<SortOrder>;
  currentBalance?: InputMaybe<SortOrder>;
};

export type LinkedAccountUpdateInput = {
  accountId?: InputMaybe<StringFieldUpdateOperationsInput>;
  availableBalance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  balanceLimit?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  component?: InputMaybe<ComponentUpdateOneRequiredWithoutLinkedAccountsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentBalance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isoCurrencyCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lastUpdatedDatetime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  mask?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  officialName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  persistentAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subtype?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutLinkedAccountNestedInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  unofficialCurrencyCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  verificationStatus?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LinkedAccountUpdateManyMutationInput = {
  accountId?: InputMaybe<StringFieldUpdateOperationsInput>;
  availableBalance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  balanceLimit?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentBalance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isoCurrencyCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lastUpdatedDatetime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  mask?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  officialName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  persistentAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subtype?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  unofficialCurrencyCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  verificationStatus?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LinkedAccountUpdateManyWithWhereWithoutComponentInput = {
  data: LinkedAccountUpdateManyMutationInput;
  where: LinkedAccountScalarWhereInput;
};

export type LinkedAccountUpdateManyWithoutComponentNestedInput = {
  connect?: InputMaybe<Array<LinkedAccountWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LinkedAccountCreateOrConnectWithoutComponentInput>
  >;
  create?: InputMaybe<Array<LinkedAccountCreateWithoutComponentInput>>;
  createMany?: InputMaybe<LinkedAccountCreateManyComponentInputEnvelope>;
  delete?: InputMaybe<Array<LinkedAccountWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LinkedAccountScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LinkedAccountWhereUniqueInput>>;
  set?: InputMaybe<Array<LinkedAccountWhereUniqueInput>>;
  update?: InputMaybe<
    Array<LinkedAccountUpdateWithWhereUniqueWithoutComponentInput>
  >;
  updateMany?: InputMaybe<
    Array<LinkedAccountUpdateManyWithWhereWithoutComponentInput>
  >;
  upsert?: InputMaybe<
    Array<LinkedAccountUpsertWithWhereUniqueWithoutComponentInput>
  >;
};

export type LinkedAccountUpdateOneRequiredWithoutTransactionsNestedInput = {
  connect?: InputMaybe<LinkedAccountWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LinkedAccountCreateOrConnectWithoutTransactionsInput>;
  create?: InputMaybe<LinkedAccountCreateWithoutTransactionsInput>;
  update?: InputMaybe<LinkedAccountUpdateToOneWithWhereWithoutTransactionsInput>;
  upsert?: InputMaybe<LinkedAccountUpsertWithoutTransactionsInput>;
};

export type LinkedAccountUpdateToOneWithWhereWithoutTransactionsInput = {
  data: LinkedAccountUpdateWithoutTransactionsInput;
  where?: InputMaybe<LinkedAccountWhereInput>;
};

export type LinkedAccountUpdateWithWhereUniqueWithoutComponentInput = {
  data: LinkedAccountUpdateWithoutComponentInput;
  where: LinkedAccountWhereUniqueInput;
};

export type LinkedAccountUpdateWithoutComponentInput = {
  accountId?: InputMaybe<StringFieldUpdateOperationsInput>;
  availableBalance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  balanceLimit?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentBalance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isoCurrencyCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lastUpdatedDatetime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  mask?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  officialName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  persistentAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subtype?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutLinkedAccountNestedInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  unofficialCurrencyCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  verificationStatus?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LinkedAccountUpdateWithoutTransactionsInput = {
  accountId?: InputMaybe<StringFieldUpdateOperationsInput>;
  availableBalance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  balanceLimit?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  component?: InputMaybe<ComponentUpdateOneRequiredWithoutLinkedAccountsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentBalance?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isoCurrencyCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lastUpdatedDatetime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  mask?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  officialName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  persistentAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subtype?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  unofficialCurrencyCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  verificationStatus?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LinkedAccountUpsertWithWhereUniqueWithoutComponentInput = {
  create: LinkedAccountCreateWithoutComponentInput;
  update: LinkedAccountUpdateWithoutComponentInput;
  where: LinkedAccountWhereUniqueInput;
};

export type LinkedAccountUpsertWithoutTransactionsInput = {
  create: LinkedAccountCreateWithoutTransactionsInput;
  update: LinkedAccountUpdateWithoutTransactionsInput;
  where?: InputMaybe<LinkedAccountWhereInput>;
};

export type LinkedAccountWhereInput = {
  AND?: InputMaybe<Array<LinkedAccountWhereInput>>;
  NOT?: InputMaybe<Array<LinkedAccountWhereInput>>;
  OR?: InputMaybe<Array<LinkedAccountWhereInput>>;
  accountId?: InputMaybe<StringFilter>;
  availableBalance?: InputMaybe<DecimalNullableFilter>;
  balanceLimit?: InputMaybe<DecimalNullableFilter>;
  component?: InputMaybe<ComponentRelationFilter>;
  componentId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currentBalance?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isoCurrencyCode?: InputMaybe<StringNullableFilter>;
  lastUpdatedDatetime?: InputMaybe<DateTimeNullableFilter>;
  mask?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  officialName?: InputMaybe<StringNullableFilter>;
  persistentAccountId?: InputMaybe<StringNullableFilter>;
  subtype?: InputMaybe<StringNullableFilter>;
  transactions?: InputMaybe<TransactionListRelationFilter>;
  type?: InputMaybe<StringFilter>;
  unofficialCurrencyCode?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  verificationStatus?: InputMaybe<StringNullableFilter>;
};

export type LinkedAccountWhereUniqueInput = {
  AND?: InputMaybe<Array<LinkedAccountWhereInput>>;
  NOT?: InputMaybe<Array<LinkedAccountWhereInput>>;
  OR?: InputMaybe<Array<LinkedAccountWhereInput>>;
  accountId?: InputMaybe<Scalars["String"]["input"]>;
  availableBalance?: InputMaybe<DecimalNullableFilter>;
  balanceLimit?: InputMaybe<DecimalNullableFilter>;
  component?: InputMaybe<ComponentRelationFilter>;
  componentId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currentBalance?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isoCurrencyCode?: InputMaybe<StringNullableFilter>;
  lastUpdatedDatetime?: InputMaybe<DateTimeNullableFilter>;
  mask?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  officialName?: InputMaybe<StringNullableFilter>;
  persistentAccountId?: InputMaybe<StringNullableFilter>;
  subtype?: InputMaybe<StringNullableFilter>;
  transactions?: InputMaybe<TransactionListRelationFilter>;
  type?: InputMaybe<StringFilter>;
  unofficialCurrencyCode?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  verificationStatus?: InputMaybe<StringNullableFilter>;
};

/** Set to SUPPRESS to suppress sending the message. Leave it blank if you want an invitation sent to the user's email. */
export enum MessageAction {
  Suppress = "SUPPRESS",
}

export type Milestone = {
  __typename?: "Milestone";
  createdAt: Scalars["DateTimeISO"]["output"];
  date: Scalars["DateTimeISO"]["output"];
  dateType: MilestoneDateType;
  estate: Estate;
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type MilestoneCountAggregate = {
  __typename?: "MilestoneCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  date: Scalars["Int"]["output"];
  dateType: Scalars["Int"]["output"];
  estateId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type MilestoneCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  dateType?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MilestoneCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date: Scalars["DateTimeISO"]["input"];
  dateType?: InputMaybe<MilestoneDateType>;
  estate: EstateCreateNestedOneWithoutMilestonesInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type MilestoneCreateManyEstateInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date: Scalars["DateTimeISO"]["input"];
  dateType?: InputMaybe<MilestoneDateType>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type MilestoneCreateManyEstateInputEnvelope = {
  data: Array<MilestoneCreateManyEstateInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MilestoneCreateManyInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date: Scalars["DateTimeISO"]["input"];
  dateType?: InputMaybe<MilestoneDateType>;
  estateId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type MilestoneCreateNestedManyWithoutEstateInput = {
  connect?: InputMaybe<Array<MilestoneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<MilestoneCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<MilestoneCreateWithoutEstateInput>>;
  createMany?: InputMaybe<MilestoneCreateManyEstateInputEnvelope>;
};

export type MilestoneCreateOrConnectWithoutEstateInput = {
  create: MilestoneCreateWithoutEstateInput;
  where: MilestoneWhereUniqueInput;
};

export type MilestoneCreateWithoutEstateInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date: Scalars["DateTimeISO"]["input"];
  dateType?: InputMaybe<MilestoneDateType>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export enum MilestoneDateType {
  Exact = "EXACT",
  Fuzzy = "FUZZY",
  Month = "MONTH",
}

export type MilestoneGroupBy = {
  __typename?: "MilestoneGroupBy";
  _count?: Maybe<MilestoneCountAggregate>;
  _max?: Maybe<MilestoneMaxAggregate>;
  _min?: Maybe<MilestoneMinAggregate>;
  createdAt: Scalars["DateTimeISO"]["output"];
  date: Scalars["DateTimeISO"]["output"];
  dateType: MilestoneDateType;
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type MilestoneListRelationFilter = {
  every?: InputMaybe<MilestoneWhereInput>;
  none?: InputMaybe<MilestoneWhereInput>;
  some?: InputMaybe<MilestoneWhereInput>;
};

export type MilestoneMaxAggregate = {
  __typename?: "MilestoneMaxAggregate";
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  date?: Maybe<Scalars["DateTimeISO"]["output"]>;
  dateType?: Maybe<MilestoneDateType>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type MilestoneMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  dateType?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MilestoneMinAggregate = {
  __typename?: "MilestoneMinAggregate";
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  date?: Maybe<Scalars["DateTimeISO"]["output"]>;
  dateType?: Maybe<MilestoneDateType>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type MilestoneMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  dateType?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MilestoneOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MilestoneOrderByWithAggregationInput = {
  _count?: InputMaybe<MilestoneCountOrderByAggregateInput>;
  _max?: InputMaybe<MilestoneMaxOrderByAggregateInput>;
  _min?: InputMaybe<MilestoneMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  dateType?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MilestoneOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  dateType?: InputMaybe<SortOrder>;
  estate?: InputMaybe<EstateOrderByWithRelationInput>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum MilestoneScalarFieldEnum {
  CreatedAt = "createdAt",
  Date = "date",
  DateType = "dateType",
  EstateId = "estateId",
  Id = "id",
  Name = "name",
  UpdatedAt = "updatedAt",
}

export type MilestoneScalarWhereInput = {
  AND?: InputMaybe<Array<MilestoneScalarWhereInput>>;
  NOT?: InputMaybe<Array<MilestoneScalarWhereInput>>;
  OR?: InputMaybe<Array<MilestoneScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  dateType?: InputMaybe<EnumMilestoneDateTypeFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MilestoneScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<MilestoneScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<MilestoneScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<MilestoneScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  date?: InputMaybe<DateTimeWithAggregatesFilter>;
  dateType?: InputMaybe<EnumMilestoneDateTypeWithAggregatesFilter>;
  estateId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type MilestoneUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateType?: InputMaybe<EnumMilestoneDateTypeFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutMilestonesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MilestoneUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateType?: InputMaybe<EnumMilestoneDateTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MilestoneUpdateManyWithWhereWithoutEstateInput = {
  data: MilestoneUpdateManyMutationInput;
  where: MilestoneScalarWhereInput;
};

export type MilestoneUpdateManyWithoutEstateNestedInput = {
  connect?: InputMaybe<Array<MilestoneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<MilestoneCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<MilestoneCreateWithoutEstateInput>>;
  createMany?: InputMaybe<MilestoneCreateManyEstateInputEnvelope>;
  delete?: InputMaybe<Array<MilestoneWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MilestoneScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MilestoneWhereUniqueInput>>;
  set?: InputMaybe<Array<MilestoneWhereUniqueInput>>;
  update?: InputMaybe<Array<MilestoneUpdateWithWhereUniqueWithoutEstateInput>>;
  updateMany?: InputMaybe<
    Array<MilestoneUpdateManyWithWhereWithoutEstateInput>
  >;
  upsert?: InputMaybe<Array<MilestoneUpsertWithWhereUniqueWithoutEstateInput>>;
};

export type MilestoneUpdateWithWhereUniqueWithoutEstateInput = {
  data: MilestoneUpdateWithoutEstateInput;
  where: MilestoneWhereUniqueInput;
};

export type MilestoneUpdateWithoutEstateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateType?: InputMaybe<EnumMilestoneDateTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MilestoneUpsertWithWhereUniqueWithoutEstateInput = {
  create: MilestoneCreateWithoutEstateInput;
  update: MilestoneUpdateWithoutEstateInput;
  where: MilestoneWhereUniqueInput;
};

export type MilestoneWhereInput = {
  AND?: InputMaybe<Array<MilestoneWhereInput>>;
  NOT?: InputMaybe<Array<MilestoneWhereInput>>;
  OR?: InputMaybe<Array<MilestoneWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  dateType?: InputMaybe<EnumMilestoneDateTypeFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MilestoneWhereUniqueInput = {
  AND?: InputMaybe<Array<MilestoneWhereInput>>;
  NOT?: InputMaybe<Array<MilestoneWhereInput>>;
  OR?: InputMaybe<Array<MilestoneWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  dateType?: InputMaybe<EnumMilestoneDateTypeFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MoveDocumentsInput = {
  destinationFolder: Scalars["String"]["input"];
  documentIds: Array<Scalars["String"]["input"]>;
  estateId: Scalars["String"]["input"];
};

export type Mutation = {
  __typename?: "Mutation";
  /** Accept the current EULA version */
  acceptEULA: User2;
  addContactToEstate: Contact2;
  addContactToUser: Contact2;
  addTeamMembers: Scalars["Float"]["output"];
  /** Create a new estate by Admin. It also attempts to create a new Estates folder in Box. */
  adminCreateEstate: Estate2;
  /**
   * Create a process instance in Camunde for a given estateId by Admin
   * @deprecated This is no longer used and will be removed.
   */
  adminCreateProcessInstance: Estate2;
  /**
   * Create a new user with an estate by Admin
   * @deprecated Do not use this mutation. Use adminCreateEstate instead.
   */
  adminCreateUserWithEstate?: Maybe<User2>;
  /** Extract text from Bank Statement and run OpenAI chat query */
  analyzeBankStatement: Scalars["String"]["output"];
  /** Simply OpenAI chat query */
  askChat: Scalars["String"]["output"];
  /** Associate a user with an estate. */
  associateUserWithEstate?: Maybe<User2>;
  /** Authenticate a user with Box and return the access token. */
  authenticateBoxUser: AuthenticateBoxUserResponse;
  /** Extracts data from all the files in the provided directory */
  buildTableOfContentsByPages: Scalars["String"]["output"];
  /** Capture the login of the current user. Admins are not captured. */
  captureLogin: Scalars["Boolean"]["output"];
  /** Capture the login failure. */
  captureLoginFailure: Scalars["Boolean"]["output"];
  /** Mark a component as seen by the customer. This mutation ensures that the component belongs to the logged in user's estate. */
  componentSeen?: Maybe<Component2>;
  /** Configures the Alix fee to use a flat amount which will be charged regardless of total estate value. */
  configureAlixFeeAsFlatAmount?: Maybe<AlixFee2>;
  /**
   * Configures the Alix fee to use a fee calculated as 1% of the total estate value (with a minimum amount if the estate value is small).
   *      If minimum is not provided, will default to $9000.
   */
  configureAlixFeeAsPercentage?: Maybe<AlixFee2>;
  createAsset: Asset2;
  /** Create a new Box folder for the given estate id. */
  createBoxEstateFolder: Scalars["String"]["output"];
  createCareTeamNote: CareTeamNote2;
  createContact: Contact2;
  createDistribution: Scalars["Boolean"]["output"];
  /** Get a signed url for document to download. */
  createDownloadUrl: Scalars["String"]["output"];
  /** Create an expense - by customer */
  createExpense?: Maybe<Expense2>;
  /** create a new folder under the estate. Admins mutation */
  createFolder: Scalars["Boolean"]["output"];
  /** Call this mutation to get a Plaid link token */
  createLinkToken: GetLinkTokenResponse;
  createManyActionCard: AffectedRowsOutput;
  createManyAddress: AffectedRowsOutput;
  createManyAlixFee: AffectedRowsOutput;
  createManyAndReturnActionCard: Array<CreateManyAndReturnActionCard>;
  createManyAndReturnAddress: Array<CreateManyAndReturnAddress>;
  createManyAndReturnAlixFee: Array<CreateManyAndReturnAlixFee>;
  createManyAndReturnAsset: Array<CreateManyAndReturnAsset>;
  createManyAndReturnAssetAccount: Array<CreateManyAndReturnAssetAccount>;
  createManyAndReturnBeneficiary: Array<CreateManyAndReturnBeneficiary>;
  createManyAndReturnBeneficiaryDistribution: Array<CreateManyAndReturnBeneficiaryDistribution>;
  createManyAndReturnCareTeamNote: Array<CreateManyAndReturnCareTeamNote>;
  createManyAndReturnComponent: Array<CreateManyAndReturnComponent>;
  createManyAndReturnContact: Array<CreateManyAndReturnContact>;
  createManyAndReturnContactsOnEstates: Array<CreateManyAndReturnContactsOnEstates>;
  createManyAndReturnContent: Array<CreateManyAndReturnContent>;
  createManyAndReturnDeceased: Array<CreateManyAndReturnDeceased>;
  createManyAndReturnDeceasedContacts: Array<CreateManyAndReturnDeceasedContacts>;
  createManyAndReturnDeviceRegistration: Array<CreateManyAndReturnDeviceRegistration>;
  createManyAndReturnDocumentsProcessed: Array<CreateManyAndReturnDocumentsProcessed>;
  createManyAndReturnEULA: Array<CreateManyAndReturnEula>;
  createManyAndReturnEULAAcceptanceHistory: Array<CreateManyAndReturnEulaAcceptanceHistory>;
  createManyAndReturnEstate: Array<CreateManyAndReturnEstate>;
  createManyAndReturnEstateDetail: Array<CreateManyAndReturnEstateDetail>;
  createManyAndReturnEstateNotification: Array<CreateManyAndReturnEstateNotification>;
  createManyAndReturnExpense: Array<CreateManyAndReturnExpense>;
  createManyAndReturnLinkedAccount: Array<CreateManyAndReturnLinkedAccount>;
  createManyAndReturnMilestone: Array<CreateManyAndReturnMilestone>;
  createManyAndReturnTask: Array<CreateManyAndReturnTask>;
  createManyAndReturnTransaction: Array<CreateManyAndReturnTransaction>;
  createManyAndReturnUser: Array<CreateManyAndReturnUser>;
  createManyAndReturnUserLogin: Array<CreateManyAndReturnUserLogin>;
  createManyAndReturnUserNotification: Array<CreateManyAndReturnUserNotification>;
  createManyAndReturnUsersOnEstates: Array<CreateManyAndReturnUsersOnEstates>;
  createManyAsset: AffectedRowsOutput;
  createManyAssetAccount: AffectedRowsOutput;
  createManyBeneficiary: AffectedRowsOutput;
  createManyBeneficiaryDistribution: AffectedRowsOutput;
  createManyCareTeamNote: AffectedRowsOutput;
  createManyComponent: AffectedRowsOutput;
  createManyContact: AffectedRowsOutput;
  createManyContactsOnEstates: AffectedRowsOutput;
  createManyContent: AffectedRowsOutput;
  createManyDeceased: AffectedRowsOutput;
  createManyDeceasedContacts: AffectedRowsOutput;
  createManyDeviceRegistration: AffectedRowsOutput;
  createManyDocumentsProcessed: AffectedRowsOutput;
  createManyEULA: AffectedRowsOutput;
  createManyEULAAcceptanceHistory: AffectedRowsOutput;
  createManyEstate: AffectedRowsOutput;
  createManyEstateDetail: AffectedRowsOutput;
  createManyEstateNotification: AffectedRowsOutput;
  createManyExpense: AffectedRowsOutput;
  createManyLinkedAccount: AffectedRowsOutput;
  createManyMilestone: AffectedRowsOutput;
  createManyTask: AffectedRowsOutput;
  createManyTransaction: AffectedRowsOutput;
  createManyUser: AffectedRowsOutput;
  createManyUserLogin: AffectedRowsOutput;
  createManyUserNotification: AffectedRowsOutput;
  createManyUsersOnEstates: AffectedRowsOutput;
  /** ADMIN: Create new notification */
  createNotificationByGroup?: Maybe<EstateNotification2>;
  createOneActionCard: ActionCard2;
  createOneAddress: Address;
  createOneAlixFee: AlixFee;
  createOneAsset: Asset;
  createOneAssetAccount: AssetAccount;
  createOneBeneficiary: Beneficiary;
  createOneBeneficiaryDistribution: BeneficiaryDistribution;
  createOneCareTeamNote: CareTeamNote;
  createOneComponent: Component2;
  createOneContact: Contact;
  createOneContactsOnEstates: ContactsOnEstates;
  createOneContent: Content2;
  createOneDeceased: Deceased2;
  createOneDeceasedContacts: DeceasedContacts;
  createOneDeviceRegistration: DeviceRegistration;
  createOneDocumentsProcessed: DocumentsProcessed;
  createOneEULA: Eula;
  createOneEULAAcceptanceHistory: EulaAcceptanceHistory;
  createOneEstate: Estate;
  createOneEstateDetail: EstateDetail;
  createOneEstateNotification: EstateNotification;
  createOneExpense: Expense;
  createOneLinkedAccount: LinkedAccount;
  createOneMilestone: Milestone;
  createOneTask: Task;
  createOneTransaction: Transaction;
  createOneUser: User;
  createOneUserLogin: UserLogin;
  createOneUserNotification: UserNotification;
  createOneUsersOnEstates: UsersOnEstates;
  /** Get a signed url for document upload. Admins must provide an estateId */
  createUploadUrl: Scalars["String"]["output"];
  /** ADMIN Create a new user in Alix and also in cognito. */
  createUserCompletely: User2;
  /** ADMIN Create a new user and an estate in Alix and also in cognito. It also creates a new process instance in Camunda */
  createUserCompletelyWithEstate: User2;
  /** Create a new user with an estate. This mutation needs to be called after the user has been created in Cognito. */
  createUserWithEstate?: Maybe<User2>;
  deleteAsset: Scalars["Boolean"]["output"];
  deleteCareTeamNote: Scalars["Boolean"]["output"];
  deleteContact: Contact2;
  /** Deletes a file. */
  deleteDocument: Scalars["Boolean"]["output"];
  /** SUPER_ADMIN mutations to soft delete an estate */
  deleteEstate: Scalars["Boolean"]["output"];
  /** delete an expense */
  deleteExpense?: Maybe<Expense2>;
  deleteManyActionCard: AffectedRowsOutput;
  deleteManyAddress: AffectedRowsOutput;
  deleteManyAlixFee: AffectedRowsOutput;
  deleteManyAsset: AffectedRowsOutput;
  deleteManyAssetAccount: AffectedRowsOutput;
  deleteManyBeneficiary: AffectedRowsOutput;
  deleteManyBeneficiaryDistribution: AffectedRowsOutput;
  deleteManyCareTeamNote: AffectedRowsOutput;
  deleteManyComponent: AffectedRowsOutput;
  deleteManyContact: AffectedRowsOutput;
  deleteManyContactsOnEstates: AffectedRowsOutput;
  deleteManyContent: AffectedRowsOutput;
  deleteManyDeceased: AffectedRowsOutput;
  deleteManyDeceasedContacts: AffectedRowsOutput;
  deleteManyDeviceRegistration: AffectedRowsOutput;
  deleteManyDocumentsProcessed: AffectedRowsOutput;
  deleteManyEULA: AffectedRowsOutput;
  deleteManyEULAAcceptanceHistory: AffectedRowsOutput;
  deleteManyEstate: AffectedRowsOutput;
  deleteManyEstateDetail: AffectedRowsOutput;
  deleteManyEstateNotification: AffectedRowsOutput;
  deleteManyExpense: AffectedRowsOutput;
  deleteManyLinkedAccount: AffectedRowsOutput;
  deleteManyMilestone: AffectedRowsOutput;
  deleteManyTask: AffectedRowsOutput;
  deleteManyTransaction: AffectedRowsOutput;
  deleteManyUser: AffectedRowsOutput;
  deleteManyUserLogin: AffectedRowsOutput;
  deleteManyUserNotification: AffectedRowsOutput;
  deleteManyUsersOnEstates: AffectedRowsOutput;
  deleteOneActionCard?: Maybe<ActionCard2>;
  deleteOneAddress?: Maybe<Address>;
  deleteOneAlixFee?: Maybe<AlixFee>;
  deleteOneAsset?: Maybe<Asset>;
  deleteOneAssetAccount?: Maybe<AssetAccount>;
  deleteOneBeneficiary?: Maybe<Beneficiary>;
  deleteOneBeneficiaryDistribution?: Maybe<BeneficiaryDistribution>;
  deleteOneCareTeamNote?: Maybe<CareTeamNote>;
  deleteOneComponent?: Maybe<Component2>;
  deleteOneContact?: Maybe<Contact>;
  deleteOneContactsOnEstates?: Maybe<ContactsOnEstates>;
  deleteOneContent?: Maybe<Content2>;
  deleteOneDeceased?: Maybe<Deceased>;
  deleteOneDeceasedContacts?: Maybe<DeceasedContacts>;
  deleteOneDeviceRegistration?: Maybe<DeviceRegistration>;
  deleteOneDocumentsProcessed?: Maybe<DocumentsProcessed>;
  deleteOneEULA?: Maybe<Eula>;
  deleteOneEULAAcceptanceHistory?: Maybe<EulaAcceptanceHistory>;
  deleteOneEstate?: Maybe<Estate>;
  deleteOneEstateDetail?: Maybe<EstateDetail>;
  deleteOneEstateNotification?: Maybe<EstateNotification>;
  deleteOneExpense?: Maybe<Expense>;
  deleteOneLinkedAccount?: Maybe<LinkedAccount>;
  deleteOneMilestone?: Maybe<Milestone>;
  deleteOneTask?: Maybe<Task>;
  deleteOneTransaction?: Maybe<Transaction>;
  deleteOneUser?: Maybe<User>;
  deleteOneUserLogin?: Maybe<UserLogin>;
  deleteOneUserNotification?: Maybe<UserNotification>;
  deleteOneUsersOnEstates?: Maybe<UsersOnEstates>;
  /** ADMIN: Delete an Estate Notification and all related User Notifications */
  deleteUserNotifications: DeleteUserNotificationsResult;
  editCareTeamNote: CareTeamNote2;
  /** Get status of files being processed */
  getIsProcessingFiles: Scalars["Boolean"]["output"];
  /** Get status of files being processed */
  getStatusOfFilesBeingProcessed: Array<DocumentProcessed2>;
  /**
   * Call this mutation to link an account. Pass the public token recieved from Plaid
   *     and the component id as arguments.
   */
  linkAccount: Component2;
  /** Get a user token. */
  login: Scalars["String"]["output"];
  /** Update the AlixFee record to mark it as invoiced */
  markAlixFeeAsInvoiced?: Maybe<AlixFee2>;
  /** Marks a userNotification as seen by setting a timestamp on seenAt. Admins must provide a userId. */
  markNotificationsSeen: Scalars["Boolean"]["output"];
  /** Move file(s) for source to destination. Admins mutation */
  moveDocuments: Scalars["Boolean"]["output"];
  prefillFormTemplate: FormTemplateLink;
  /** Read file from S3 */
  readFile: Scalars["String"]["output"];
  registerDevice: DeviceRegistration2;
  removeContactFromEstate: Contact2;
  removeContactFromUser: Contact2;
  removeTeamMembers: Scalars["Float"]["output"];
  /** Rename a document */
  renameDocument: Scalars["Boolean"]["output"];
  /** Resend the invitation email to a user. */
  resendInvitation: Scalars["Boolean"]["output"];
  /** Reset an expired Cognito password for a user by Admin */
  resetExpiredCognitoPassword: User2;
  /** Restores document by removing delete marker. */
  restoreDocument: Scalars["Boolean"]["output"];
  /** Scrape missing money */
  scrapeMissingMoney: Scalars["String"]["output"];
  /** @deprecated Has been replaced by configureAlixFeeAsFlatAmount */
  setAlixFeeAmount?: Maybe<AlixFee2>;
  /** Sign up a new user and create an estate. No authentication is required. */
  signUpUser: User2;
  /** Extracts data from all the files in the provided directory */
  sortDocuments: Scalars["String"]["output"];
  /** Reads file from Box and sorts it */
  sortDocumentsFromBox: Array<Scalars["String"]["output"]>;
  /** Call this mutation when the customer takes action on an action card. */
  startActionCard: ActionCard2;
  /** Track a click on an element */
  trackClick: Scalars["Boolean"]["output"];
  /** Track a page view. */
  trackPageView: Scalars["Boolean"]["output"];
  /** Track a scroll event */
  trackScroll: Scalars["Boolean"]["output"];
  /** Track a section opened event */
  trackSectionOpened: Scalars["Boolean"]["output"];
  updateAsset?: Maybe<Asset2>;
  updateContact: Contact2;
  /** Update the logged in user. */
  updateCurrentUser?: Maybe<User2>;
  /** Update the ssn of the logged in user. */
  updateCurrentUserSsn?: Maybe<User2>;
  /** Update the ssn of the deceased. Must be an admin to use this mutation. */
  updateDeceasedSsn?: Maybe<Deceased2>;
  /** update an expense */
  updateExpense?: Maybe<Expense2>;
  updateManyActionCard: AffectedRowsOutput;
  updateManyAddress: AffectedRowsOutput;
  updateManyAlixFee: AffectedRowsOutput;
  updateManyAsset: AffectedRowsOutput;
  updateManyAssetAccount: AffectedRowsOutput;
  updateManyBeneficiary: AffectedRowsOutput;
  updateManyBeneficiaryDistribution: AffectedRowsOutput;
  updateManyCareTeamNote: AffectedRowsOutput;
  updateManyComponent: AffectedRowsOutput;
  updateManyContact: AffectedRowsOutput;
  updateManyContactsOnEstates: AffectedRowsOutput;
  updateManyContent: AffectedRowsOutput;
  updateManyDeceased: AffectedRowsOutput;
  updateManyDeceasedContacts: AffectedRowsOutput;
  updateManyDeviceRegistration: AffectedRowsOutput;
  updateManyDocumentsProcessed: AffectedRowsOutput;
  updateManyEULA: AffectedRowsOutput;
  updateManyEULAAcceptanceHistory: AffectedRowsOutput;
  updateManyEstate: AffectedRowsOutput;
  updateManyEstateDetail: AffectedRowsOutput;
  updateManyEstateNotification: AffectedRowsOutput;
  updateManyExpense: AffectedRowsOutput;
  updateManyLinkedAccount: AffectedRowsOutput;
  updateManyMilestone: AffectedRowsOutput;
  updateManyTask: AffectedRowsOutput;
  updateManyTransaction: AffectedRowsOutput;
  updateManyUser: AffectedRowsOutput;
  updateManyUserLogin: AffectedRowsOutput;
  updateManyUserNotification: AffectedRowsOutput;
  updateManyUsersOnEstates: AffectedRowsOutput;
  updateOneActionCard?: Maybe<ActionCard2>;
  updateOneAddress?: Maybe<Address>;
  updateOneAlixFee?: Maybe<AlixFee>;
  updateOneAsset?: Maybe<Asset>;
  updateOneAssetAccount?: Maybe<AssetAccount>;
  updateOneBeneficiary?: Maybe<Beneficiary>;
  updateOneBeneficiaryDistribution?: Maybe<BeneficiaryDistribution>;
  updateOneCareTeamNote?: Maybe<CareTeamNote>;
  updateOneComponent?: Maybe<Component2>;
  updateOneContact?: Maybe<Contact>;
  updateOneContactsOnEstates?: Maybe<ContactsOnEstates>;
  updateOneContent?: Maybe<Content2>;
  updateOneDeceased?: Maybe<Deceased2>;
  updateOneDeceasedContacts?: Maybe<DeceasedContacts>;
  updateOneDeviceRegistration?: Maybe<DeviceRegistration>;
  updateOneDocumentsProcessed?: Maybe<DocumentsProcessed>;
  updateOneEULA?: Maybe<Eula>;
  updateOneEULAAcceptanceHistory?: Maybe<EulaAcceptanceHistory>;
  updateOneEstate?: Maybe<Estate2>;
  updateOneEstateDetail?: Maybe<EstateDetail>;
  updateOneEstateNotification?: Maybe<EstateNotification>;
  updateOneExpense?: Maybe<Expense>;
  updateOneLinkedAccount?: Maybe<LinkedAccount>;
  updateOneMilestone?: Maybe<Milestone>;
  updateOneTask?: Maybe<Task>;
  updateOneTransaction?: Maybe<Transaction>;
  updateOneUser?: Maybe<User2>;
  updateOneUserLogin?: Maybe<UserLogin>;
  updateOneUserNotification?: Maybe<UserNotification>;
  updateOneUsersOnEstates?: Maybe<UsersOnEstates>;
  updateTeamMembers: Scalars["Float"]["output"];
  /** Update the estate for the logged in user. If the user has multiple estates, an estateId can be provided. */
  updateUserEstate?: Maybe<Estate2>;
  /** Update the ssn of the estate's deceased. */
  updateUserEstateDeceasedSsn?: Maybe<Deceased2>;
  /** ADMIN Update IDP attributes of a user. */
  updateUserIDPAttributes: User2;
  /** Update the ssn of a user. Must be an admin. */
  updateUserSsn?: Maybe<User2>;
  upsertOneActionCard: ActionCard;
  upsertOneAddress: Address;
  upsertOneAlixFee: AlixFee;
  upsertOneAsset: Asset;
  upsertOneAssetAccount: AssetAccount;
  upsertOneBeneficiary: Beneficiary;
  upsertOneBeneficiaryDistribution: BeneficiaryDistribution;
  upsertOneCareTeamNote: CareTeamNote;
  upsertOneComponent: Component;
  upsertOneContact: Contact;
  upsertOneContactsOnEstates: ContactsOnEstates;
  upsertOneContent: Content;
  upsertOneDeceased: Deceased;
  upsertOneDeceasedContacts: DeceasedContacts;
  upsertOneDeviceRegistration: DeviceRegistration;
  upsertOneDocumentsProcessed: DocumentsProcessed;
  upsertOneEULA: Eula;
  upsertOneEULAAcceptanceHistory: EulaAcceptanceHistory;
  upsertOneEstate: Estate;
  upsertOneEstateDetail: EstateDetail;
  upsertOneEstateNotification: EstateNotification;
  upsertOneExpense: Expense;
  upsertOneLinkedAccount: LinkedAccount;
  upsertOneMilestone: Milestone;
  upsertOneTask: Task;
  upsertOneTransaction: Transaction;
  upsertOneUser: User;
  upsertOneUserLogin: UserLogin;
  upsertOneUserNotification: UserNotification;
  upsertOneUsersOnEstates: UsersOnEstates;
  /** ADMIN mutation to update the core estate information for an estate */
  writeCoreEstateInformation: Scalars["Boolean"]["output"];
};

export type MutationAddContactToEstateArgs = {
  contactEstateRole: ContactEstateRoleType2;
  contactId: Scalars["String"]["input"];
  estateId: Scalars["String"]["input"];
};

export type MutationAddContactToUserArgs = {
  contactId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type MutationAddTeamMembersArgs = {
  estateId: Scalars["String"]["input"];
  userIds: Array<Scalars["String"]["input"]>;
};

export type MutationAdminCreateEstateArgs = {
  data: EstateCreateInput2;
};

export type MutationAdminCreateProcessInstanceArgs = {
  estateId: Scalars["String"]["input"];
};

export type MutationAdminCreateUserWithEstateArgs = {
  data: AdminCreateUserWithEstateInput;
};

export type MutationAnalyzeBankStatementArgs = {
  filePaths: Array<Scalars["String"]["input"]>;
};

export type MutationAskChatArgs = {
  systemMessage?: InputMaybe<Scalars["String"]["input"]>;
  userMessage: Scalars["String"]["input"];
};

export type MutationAssociateUserWithEstateArgs = {
  estateId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
  userRole: UserEstateRole;
};

export type MutationAuthenticateBoxUserArgs = {
  estateId: Scalars["String"]["input"];
};

export type MutationBuildTableOfContentsByPagesArgs = {
  estateId: Scalars["String"]["input"];
  inputDirectoryName: Scalars["String"]["input"];
  inputFileName?: InputMaybe<Scalars["String"]["input"]>;
  outputDirectoryName: Scalars["String"]["input"];
};

export type MutationCaptureLoginFailureArgs = {
  email: Scalars["String"]["input"];
  reason?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationComponentSeenArgs = {
  componentId: Scalars["String"]["input"];
  estateId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationConfigureAlixFeeAsFlatAmountArgs = {
  data: ConfigureAlixFeeAsFlatAmountInput;
};

export type MutationConfigureAlixFeeAsPercentageArgs = {
  data: ConfigureAlixFeeAsPercentageInput;
};

export type MutationCreateAssetArgs = {
  data: AssetInput;
};

export type MutationCreateBoxEstateFolderArgs = {
  estateId: Scalars["String"]["input"];
};

export type MutationCreateCareTeamNoteArgs = {
  input: CreateCareTeamNoteInput;
};

export type MutationCreateContactArgs = {
  data: ContactCreateInput2;
};

export type MutationCreateDistributionArgs = {
  componentId: Scalars["String"]["input"];
  data: Array<BeneficiaryDistributionInput>;
};

export type MutationCreateDownloadUrlArgs = {
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
};

export type MutationCreateExpenseArgs = {
  data: CreateExpenseInput;
};

export type MutationCreateFolderArgs = {
  data: CreateFolderInput;
};

export type MutationCreateManyActionCardArgs = {
  data: Array<ActionCardCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAddressArgs = {
  data: Array<AddressCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAlixFeeArgs = {
  data: Array<AlixFeeCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnActionCardArgs = {
  data: Array<ActionCardCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnAddressArgs = {
  data: Array<AddressCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnAlixFeeArgs = {
  data: Array<AlixFeeCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnAssetArgs = {
  data: Array<AssetCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnAssetAccountArgs = {
  data: Array<AssetAccountCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnBeneficiaryArgs = {
  data: Array<BeneficiaryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnBeneficiaryDistributionArgs = {
  data: Array<BeneficiaryDistributionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnCareTeamNoteArgs = {
  data: Array<CareTeamNoteCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnComponentArgs = {
  data: Array<ComponentCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnContactArgs = {
  data: Array<ContactCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnContactsOnEstatesArgs = {
  data: Array<ContactsOnEstatesCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnContentArgs = {
  data: Array<ContentCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnDeceasedArgs = {
  data: Array<DeceasedCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnDeceasedContactsArgs = {
  data: Array<DeceasedContactsCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnDeviceRegistrationArgs = {
  data: Array<DeviceRegistrationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnDocumentsProcessedArgs = {
  data: Array<DocumentsProcessedCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnEulaArgs = {
  data: Array<EulaCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnEulaAcceptanceHistoryArgs = {
  data: Array<EulaAcceptanceHistoryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnEstateArgs = {
  data: Array<EstateCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnEstateDetailArgs = {
  data: Array<EstateDetailCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnEstateNotificationArgs = {
  data: Array<EstateNotificationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnExpenseArgs = {
  data: Array<ExpenseCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnLinkedAccountArgs = {
  data: Array<LinkedAccountCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnMilestoneArgs = {
  data: Array<MilestoneCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnTaskArgs = {
  data: Array<TaskCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnTransactionArgs = {
  data: Array<TransactionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnUserArgs = {
  data: Array<UserCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnUserLoginArgs = {
  data: Array<UserLoginCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnUserNotificationArgs = {
  data: Array<UserNotificationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAndReturnUsersOnEstatesArgs = {
  data: Array<UsersOnEstatesCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAssetArgs = {
  data: Array<AssetCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyAssetAccountArgs = {
  data: Array<AssetAccountCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyBeneficiaryArgs = {
  data: Array<BeneficiaryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyBeneficiaryDistributionArgs = {
  data: Array<BeneficiaryDistributionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyCareTeamNoteArgs = {
  data: Array<CareTeamNoteCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyComponentArgs = {
  data: Array<ComponentCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyContactArgs = {
  data: Array<ContactCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyContactsOnEstatesArgs = {
  data: Array<ContactsOnEstatesCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyContentArgs = {
  data: Array<ContentCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyDeceasedArgs = {
  data: Array<DeceasedCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyDeceasedContactsArgs = {
  data: Array<DeceasedContactsCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyDeviceRegistrationArgs = {
  data: Array<DeviceRegistrationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyDocumentsProcessedArgs = {
  data: Array<DocumentsProcessedCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyEulaArgs = {
  data: Array<EulaCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyEulaAcceptanceHistoryArgs = {
  data: Array<EulaAcceptanceHistoryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyEstateArgs = {
  data: Array<EstateCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyEstateDetailArgs = {
  data: Array<EstateDetailCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyEstateNotificationArgs = {
  data: Array<EstateNotificationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyExpenseArgs = {
  data: Array<ExpenseCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyLinkedAccountArgs = {
  data: Array<LinkedAccountCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyMilestoneArgs = {
  data: Array<MilestoneCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyTaskArgs = {
  data: Array<TaskCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyTransactionArgs = {
  data: Array<TransactionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyUserArgs = {
  data: Array<UserCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyUserLoginArgs = {
  data: Array<UserLoginCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyUserNotificationArgs = {
  data: Array<UserNotificationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateManyUsersOnEstatesArgs = {
  data: Array<UsersOnEstatesCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateNotificationByGroupArgs = {
  data: CreateNotificationByGroupInput;
};

export type MutationCreateOneActionCardArgs = {
  data: ActionCardCreateInput2;
};

export type MutationCreateOneAddressArgs = {
  data?: InputMaybe<AddressCreateInput>;
};

export type MutationCreateOneAlixFeeArgs = {
  data: AlixFeeCreateInput;
};

export type MutationCreateOneAssetArgs = {
  data: AssetCreateInput;
};

export type MutationCreateOneAssetAccountArgs = {
  data: AssetAccountCreateInput;
};

export type MutationCreateOneBeneficiaryArgs = {
  data: BeneficiaryCreateInput;
};

export type MutationCreateOneBeneficiaryDistributionArgs = {
  data: BeneficiaryDistributionCreateInput;
};

export type MutationCreateOneCareTeamNoteArgs = {
  data: CareTeamNoteCreateInput;
};

export type MutationCreateOneComponentArgs = {
  data: ComponentCreateInput2;
};

export type MutationCreateOneContactArgs = {
  data: ContactCreateInput;
};

export type MutationCreateOneContactsOnEstatesArgs = {
  data: ContactsOnEstatesCreateInput;
};

export type MutationCreateOneContentArgs = {
  data: ContentCreateInput2;
};

export type MutationCreateOneDeceasedArgs = {
  data: DeceasedCreateInput2;
};

export type MutationCreateOneDeceasedContactsArgs = {
  data: DeceasedContactsCreateInput;
};

export type MutationCreateOneDeviceRegistrationArgs = {
  data: DeviceRegistrationCreateInput;
};

export type MutationCreateOneDocumentsProcessedArgs = {
  data: DocumentsProcessedCreateInput;
};

export type MutationCreateOneEulaArgs = {
  data: EulaCreateInput;
};

export type MutationCreateOneEulaAcceptanceHistoryArgs = {
  data: EulaAcceptanceHistoryCreateInput;
};

export type MutationCreateOneEstateArgs = {
  data: EstateCreateInput;
};

export type MutationCreateOneEstateDetailArgs = {
  data: EstateDetailCreateInput;
};

export type MutationCreateOneEstateNotificationArgs = {
  data: EstateNotificationCreateInput;
};

export type MutationCreateOneExpenseArgs = {
  data: ExpenseCreateInput;
};

export type MutationCreateOneLinkedAccountArgs = {
  data: LinkedAccountCreateInput;
};

export type MutationCreateOneMilestoneArgs = {
  data: MilestoneCreateInput;
};

export type MutationCreateOneTaskArgs = {
  data: TaskCreateInput;
};

export type MutationCreateOneTransactionArgs = {
  data: TransactionCreateInput;
};

export type MutationCreateOneUserArgs = {
  data: UserCreateInput;
};

export type MutationCreateOneUserLoginArgs = {
  data: UserLoginCreateInput;
};

export type MutationCreateOneUserNotificationArgs = {
  data: UserNotificationCreateInput;
};

export type MutationCreateOneUsersOnEstatesArgs = {
  data: UsersOnEstatesCreateInput;
};

export type MutationCreateUploadUrlArgs = {
  destinationFolder?: InputMaybe<Scalars["String"]["input"]>;
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  fileName: Scalars["String"]["input"];
};

export type MutationCreateUserCompletelyArgs = {
  data: CreateUserInput;
};

export type MutationCreateUserCompletelyWithEstateArgs = {
  data: CreateUserInput;
};

export type MutationDeleteAssetArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteCareTeamNoteArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteContactArgs = {
  contactId: Scalars["String"]["input"];
};

export type MutationDeleteDocumentArgs = {
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
};

export type MutationDeleteEstateArgs = {
  estateId: Scalars["String"]["input"];
};

export type MutationDeleteExpenseArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteManyActionCardArgs = {
  where?: InputMaybe<ActionCardWhereInput>;
};

export type MutationDeleteManyAddressArgs = {
  where?: InputMaybe<AddressWhereInput>;
};

export type MutationDeleteManyAlixFeeArgs = {
  where?: InputMaybe<AlixFeeWhereInput>;
};

export type MutationDeleteManyAssetArgs = {
  where?: InputMaybe<AssetWhereInput>;
};

export type MutationDeleteManyAssetAccountArgs = {
  where?: InputMaybe<AssetAccountWhereInput>;
};

export type MutationDeleteManyBeneficiaryArgs = {
  where?: InputMaybe<BeneficiaryWhereInput>;
};

export type MutationDeleteManyBeneficiaryDistributionArgs = {
  where?: InputMaybe<BeneficiaryDistributionWhereInput>;
};

export type MutationDeleteManyCareTeamNoteArgs = {
  where?: InputMaybe<CareTeamNoteWhereInput>;
};

export type MutationDeleteManyComponentArgs = {
  where?: InputMaybe<ComponentWhereInput>;
};

export type MutationDeleteManyContactArgs = {
  where?: InputMaybe<ContactWhereInput>;
};

export type MutationDeleteManyContactsOnEstatesArgs = {
  where?: InputMaybe<ContactsOnEstatesWhereInput>;
};

export type MutationDeleteManyContentArgs = {
  where?: InputMaybe<ContentWhereInput>;
};

export type MutationDeleteManyDeceasedArgs = {
  where?: InputMaybe<DeceasedWhereInput>;
};

export type MutationDeleteManyDeceasedContactsArgs = {
  where?: InputMaybe<DeceasedContactsWhereInput>;
};

export type MutationDeleteManyDeviceRegistrationArgs = {
  where?: InputMaybe<DeviceRegistrationWhereInput>;
};

export type MutationDeleteManyDocumentsProcessedArgs = {
  where?: InputMaybe<DocumentsProcessedWhereInput>;
};

export type MutationDeleteManyEulaArgs = {
  where?: InputMaybe<EulaWhereInput>;
};

export type MutationDeleteManyEulaAcceptanceHistoryArgs = {
  where?: InputMaybe<EulaAcceptanceHistoryWhereInput>;
};

export type MutationDeleteManyEstateArgs = {
  where?: InputMaybe<EstateWhereInput>;
};

export type MutationDeleteManyEstateDetailArgs = {
  where?: InputMaybe<EstateDetailWhereInput>;
};

export type MutationDeleteManyEstateNotificationArgs = {
  where?: InputMaybe<EstateNotificationWhereInput>;
};

export type MutationDeleteManyExpenseArgs = {
  where?: InputMaybe<ExpenseWhereInput>;
};

export type MutationDeleteManyLinkedAccountArgs = {
  where?: InputMaybe<LinkedAccountWhereInput>;
};

export type MutationDeleteManyMilestoneArgs = {
  where?: InputMaybe<MilestoneWhereInput>;
};

export type MutationDeleteManyTaskArgs = {
  where?: InputMaybe<TaskWhereInput>;
};

export type MutationDeleteManyTransactionArgs = {
  where?: InputMaybe<TransactionWhereInput>;
};

export type MutationDeleteManyUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type MutationDeleteManyUserLoginArgs = {
  where?: InputMaybe<UserLoginWhereInput>;
};

export type MutationDeleteManyUserNotificationArgs = {
  where?: InputMaybe<UserNotificationWhereInput>;
};

export type MutationDeleteManyUsersOnEstatesArgs = {
  where?: InputMaybe<UsersOnEstatesWhereInput>;
};

export type MutationDeleteOneActionCardArgs = {
  where: ActionCardWhereUniqueInput2;
};

export type MutationDeleteOneAddressArgs = {
  where: AddressWhereUniqueInput;
};

export type MutationDeleteOneAlixFeeArgs = {
  where: AlixFeeWhereUniqueInput;
};

export type MutationDeleteOneAssetArgs = {
  where: AssetWhereUniqueInput;
};

export type MutationDeleteOneAssetAccountArgs = {
  where: AssetAccountWhereUniqueInput;
};

export type MutationDeleteOneBeneficiaryArgs = {
  where: BeneficiaryWhereUniqueInput;
};

export type MutationDeleteOneBeneficiaryDistributionArgs = {
  where: BeneficiaryDistributionWhereUniqueInput;
};

export type MutationDeleteOneCareTeamNoteArgs = {
  where: CareTeamNoteWhereUniqueInput;
};

export type MutationDeleteOneComponentArgs = {
  where: ComponentWhereUniqueInput2;
};

export type MutationDeleteOneContactArgs = {
  where: ContactWhereUniqueInput;
};

export type MutationDeleteOneContactsOnEstatesArgs = {
  where: ContactsOnEstatesWhereUniqueInput;
};

export type MutationDeleteOneContentArgs = {
  where: ContentWhereUniqueInput2;
};

export type MutationDeleteOneDeceasedArgs = {
  where: DeceasedWhereUniqueInput;
};

export type MutationDeleteOneDeceasedContactsArgs = {
  where: DeceasedContactsWhereUniqueInput;
};

export type MutationDeleteOneDeviceRegistrationArgs = {
  where: DeviceRegistrationWhereUniqueInput;
};

export type MutationDeleteOneDocumentsProcessedArgs = {
  where: DocumentsProcessedWhereUniqueInput;
};

export type MutationDeleteOneEulaArgs = {
  where: EulaWhereUniqueInput;
};

export type MutationDeleteOneEulaAcceptanceHistoryArgs = {
  where: EulaAcceptanceHistoryWhereUniqueInput;
};

export type MutationDeleteOneEstateArgs = {
  where: EstateWhereUniqueInput;
};

export type MutationDeleteOneEstateDetailArgs = {
  where: EstateDetailWhereUniqueInput;
};

export type MutationDeleteOneEstateNotificationArgs = {
  where: EstateNotificationWhereUniqueInput;
};

export type MutationDeleteOneExpenseArgs = {
  where: ExpenseWhereUniqueInput;
};

export type MutationDeleteOneLinkedAccountArgs = {
  where: LinkedAccountWhereUniqueInput;
};

export type MutationDeleteOneMilestoneArgs = {
  where: MilestoneWhereUniqueInput;
};

export type MutationDeleteOneTaskArgs = {
  where: TaskWhereUniqueInput;
};

export type MutationDeleteOneTransactionArgs = {
  where: TransactionWhereUniqueInput;
};

export type MutationDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};

export type MutationDeleteOneUserLoginArgs = {
  where: UserLoginWhereUniqueInput;
};

export type MutationDeleteOneUserNotificationArgs = {
  where: UserNotificationWhereUniqueInput;
};

export type MutationDeleteOneUsersOnEstatesArgs = {
  where: UsersOnEstatesWhereUniqueInput;
};

export type MutationDeleteUserNotificationsArgs = {
  data: DeleteUserNotificationsInput;
};

export type MutationEditCareTeamNoteArgs = {
  id: Scalars["String"]["input"];
  input: EditCareTeamNoteInput;
};

export type MutationGetIsProcessingFilesArgs = {
  estateId: Scalars["String"]["input"];
};

export type MutationGetStatusOfFilesBeingProcessedArgs = {
  estateId: Scalars["String"]["input"];
};

export type MutationLinkAccountArgs = {
  componentId: Scalars["String"]["input"];
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  publicToken: Scalars["String"]["input"];
};

export type MutationLoginArgs = {
  password: Scalars["String"]["input"];
  username: Scalars["String"]["input"];
};

export type MutationMarkAlixFeeAsInvoicedArgs = {
  estateId: Scalars["String"]["input"];
};

export type MutationMarkNotificationsSeenArgs = {
  notificationIds: Array<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationMoveDocumentsArgs = {
  data: MoveDocumentsInput;
};

export type MutationPrefillFormTemplateArgs = {
  estateId: Scalars["String"]["input"];
  templateId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type MutationReadFileArgs = {
  filePath?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationRegisterDeviceArgs = {
  deviceToken: Scalars["String"]["input"];
};

export type MutationRemoveContactFromEstateArgs = {
  contactId: Scalars["String"]["input"];
  estateId: Scalars["String"]["input"];
};

export type MutationRemoveContactFromUserArgs = {
  contactId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type MutationRemoveTeamMembersArgs = {
  estateId: Scalars["String"]["input"];
  userIds: Array<Scalars["String"]["input"]>;
};

export type MutationRenameDocumentArgs = {
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  sourceKey: Scalars["String"]["input"];
  targetKey: Scalars["String"]["input"];
};

export type MutationResendInvitationArgs = {
  userId: Scalars["String"]["input"];
};

export type MutationResetExpiredCognitoPasswordArgs = {
  userId: Scalars["String"]["input"];
};

export type MutationRestoreDocumentArgs = {
  deleteMarkerId: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
};

export type MutationScrapeMissingMoneyArgs = {
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
};

export type MutationSetAlixFeeAmountArgs = {
  data: SetAlixFeeAmountInput;
};

export type MutationSignUpUserArgs = {
  data: SignUpUserInput;
};

export type MutationSortDocumentsArgs = {
  estateId: Scalars["String"]["input"];
  inputDirectoryName: Scalars["String"]["input"];
  outputDirectoryName: Scalars["String"]["input"];
};

export type MutationSortDocumentsFromBoxArgs = {
  estateId: Scalars["String"]["input"];
};

export type MutationStartActionCardArgs = {
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  reply?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationTrackClickArgs = {
  element: Scalars["String"]["input"];
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  properties?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type MutationTrackPageViewArgs = {
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  fromPush?: InputMaybe<Scalars["Boolean"]["input"]>;
  page: Scalars["String"]["input"];
};

export type MutationTrackScrollArgs = {
  elementId?: InputMaybe<Scalars["String"]["input"]>;
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationTrackSectionOpenedArgs = {
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  section: Scalars["String"]["input"];
};

export type MutationUpdateAssetArgs = {
  data: UpdateAssetInput;
  id: Scalars["String"]["input"];
};

export type MutationUpdateContactArgs = {
  contactId: Scalars["String"]["input"];
  data: ContactUpdateInput2;
};

export type MutationUpdateCurrentUserArgs = {
  data: UserUpdateInput2;
};

export type MutationUpdateCurrentUserSsnArgs = {
  ssn: Scalars["String"]["input"];
};

export type MutationUpdateDeceasedSsnArgs = {
  id: Scalars["String"]["input"];
  ssn: Scalars["String"]["input"];
};

export type MutationUpdateExpenseArgs = {
  data: UpdateExpenseInput;
  where: ExpenseWhereUniqueInput2;
};

export type MutationUpdateManyActionCardArgs = {
  data: ActionCardUpdateManyMutationInput;
  where?: InputMaybe<ActionCardWhereInput>;
};

export type MutationUpdateManyAddressArgs = {
  data: AddressUpdateManyMutationInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type MutationUpdateManyAlixFeeArgs = {
  data: AlixFeeUpdateManyMutationInput;
  where?: InputMaybe<AlixFeeWhereInput>;
};

export type MutationUpdateManyAssetArgs = {
  data: AssetUpdateManyMutationInput;
  where?: InputMaybe<AssetWhereInput>;
};

export type MutationUpdateManyAssetAccountArgs = {
  data: AssetAccountUpdateManyMutationInput;
  where?: InputMaybe<AssetAccountWhereInput>;
};

export type MutationUpdateManyBeneficiaryArgs = {
  data: BeneficiaryUpdateManyMutationInput;
  where?: InputMaybe<BeneficiaryWhereInput>;
};

export type MutationUpdateManyBeneficiaryDistributionArgs = {
  data: BeneficiaryDistributionUpdateManyMutationInput;
  where?: InputMaybe<BeneficiaryDistributionWhereInput>;
};

export type MutationUpdateManyCareTeamNoteArgs = {
  data: CareTeamNoteUpdateManyMutationInput;
  where?: InputMaybe<CareTeamNoteWhereInput>;
};

export type MutationUpdateManyComponentArgs = {
  data: ComponentUpdateManyMutationInput;
  where?: InputMaybe<ComponentWhereInput>;
};

export type MutationUpdateManyContactArgs = {
  data: ContactUpdateManyMutationInput;
  where?: InputMaybe<ContactWhereInput>;
};

export type MutationUpdateManyContactsOnEstatesArgs = {
  data: ContactsOnEstatesUpdateManyMutationInput;
  where?: InputMaybe<ContactsOnEstatesWhereInput>;
};

export type MutationUpdateManyContentArgs = {
  data: ContentUpdateManyMutationInput;
  where?: InputMaybe<ContentWhereInput>;
};

export type MutationUpdateManyDeceasedArgs = {
  data: DeceasedUpdateManyMutationInput;
  where?: InputMaybe<DeceasedWhereInput>;
};

export type MutationUpdateManyDeceasedContactsArgs = {
  data: DeceasedContactsUpdateManyMutationInput;
  where?: InputMaybe<DeceasedContactsWhereInput>;
};

export type MutationUpdateManyDeviceRegistrationArgs = {
  data: DeviceRegistrationUpdateManyMutationInput;
  where?: InputMaybe<DeviceRegistrationWhereInput>;
};

export type MutationUpdateManyDocumentsProcessedArgs = {
  data: DocumentsProcessedUpdateManyMutationInput;
  where?: InputMaybe<DocumentsProcessedWhereInput>;
};

export type MutationUpdateManyEulaArgs = {
  data: EulaUpdateManyMutationInput;
  where?: InputMaybe<EulaWhereInput>;
};

export type MutationUpdateManyEulaAcceptanceHistoryArgs = {
  data: EulaAcceptanceHistoryUpdateManyMutationInput;
  where?: InputMaybe<EulaAcceptanceHistoryWhereInput>;
};

export type MutationUpdateManyEstateArgs = {
  data: EstateUpdateManyMutationInput;
  where?: InputMaybe<EstateWhereInput>;
};

export type MutationUpdateManyEstateDetailArgs = {
  data: EstateDetailUpdateManyMutationInput;
  where?: InputMaybe<EstateDetailWhereInput>;
};

export type MutationUpdateManyEstateNotificationArgs = {
  data: EstateNotificationUpdateManyMutationInput;
  where?: InputMaybe<EstateNotificationWhereInput>;
};

export type MutationUpdateManyExpenseArgs = {
  data: ExpenseUpdateManyMutationInput;
  where?: InputMaybe<ExpenseWhereInput>;
};

export type MutationUpdateManyLinkedAccountArgs = {
  data: LinkedAccountUpdateManyMutationInput;
  where?: InputMaybe<LinkedAccountWhereInput>;
};

export type MutationUpdateManyMilestoneArgs = {
  data: MilestoneUpdateManyMutationInput;
  where?: InputMaybe<MilestoneWhereInput>;
};

export type MutationUpdateManyTaskArgs = {
  data: TaskUpdateManyMutationInput;
  where?: InputMaybe<TaskWhereInput>;
};

export type MutationUpdateManyTransactionArgs = {
  data: TransactionUpdateManyMutationInput;
  where?: InputMaybe<TransactionWhereInput>;
};

export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: InputMaybe<UserWhereInput>;
};

export type MutationUpdateManyUserLoginArgs = {
  data: UserLoginUpdateManyMutationInput;
  where?: InputMaybe<UserLoginWhereInput>;
};

export type MutationUpdateManyUserNotificationArgs = {
  data: UserNotificationUpdateManyMutationInput;
  where?: InputMaybe<UserNotificationWhereInput>;
};

export type MutationUpdateManyUsersOnEstatesArgs = {
  data: UsersOnEstatesUpdateManyMutationInput;
  where?: InputMaybe<UsersOnEstatesWhereInput>;
};

export type MutationUpdateOneActionCardArgs = {
  data: ActionCardUpdateInput2;
  where: ActionCardWhereUniqueInput2;
};

export type MutationUpdateOneAddressArgs = {
  data: AddressUpdateInput;
  where: AddressWhereUniqueInput;
};

export type MutationUpdateOneAlixFeeArgs = {
  data: AlixFeeUpdateInput;
  where: AlixFeeWhereUniqueInput;
};

export type MutationUpdateOneAssetArgs = {
  data: AssetUpdateInput;
  where: AssetWhereUniqueInput;
};

export type MutationUpdateOneAssetAccountArgs = {
  data: AssetAccountUpdateInput;
  where: AssetAccountWhereUniqueInput;
};

export type MutationUpdateOneBeneficiaryArgs = {
  data: BeneficiaryUpdateInput;
  where: BeneficiaryWhereUniqueInput;
};

export type MutationUpdateOneBeneficiaryDistributionArgs = {
  data: BeneficiaryDistributionUpdateInput;
  where: BeneficiaryDistributionWhereUniqueInput;
};

export type MutationUpdateOneCareTeamNoteArgs = {
  data: CareTeamNoteUpdateInput;
  where: CareTeamNoteWhereUniqueInput;
};

export type MutationUpdateOneComponentArgs = {
  data: ComponentUpdateInput2;
  where: ComponentWhereUniqueInput2;
};

export type MutationUpdateOneContactArgs = {
  data: ContactUpdateInput;
  where: ContactWhereUniqueInput;
};

export type MutationUpdateOneContactsOnEstatesArgs = {
  data: ContactsOnEstatesUpdateInput;
  where: ContactsOnEstatesWhereUniqueInput;
};

export type MutationUpdateOneContentArgs = {
  data: ContentUpdateInput2;
  where: ContentWhereUniqueInput2;
};

export type MutationUpdateOneDeceasedArgs = {
  data: DeceasedUpdateInput2;
  where: DeceasedWhereUniqueInput2;
};

export type MutationUpdateOneDeceasedContactsArgs = {
  data: DeceasedContactsUpdateInput;
  where: DeceasedContactsWhereUniqueInput;
};

export type MutationUpdateOneDeviceRegistrationArgs = {
  data: DeviceRegistrationUpdateInput;
  where: DeviceRegistrationWhereUniqueInput;
};

export type MutationUpdateOneDocumentsProcessedArgs = {
  data: DocumentsProcessedUpdateInput;
  where: DocumentsProcessedWhereUniqueInput;
};

export type MutationUpdateOneEulaArgs = {
  data: EulaUpdateInput;
  where: EulaWhereUniqueInput;
};

export type MutationUpdateOneEulaAcceptanceHistoryArgs = {
  data: EulaAcceptanceHistoryUpdateInput;
  where: EulaAcceptanceHistoryWhereUniqueInput;
};

export type MutationUpdateOneEstateArgs = {
  data: EstateUpdateInput2;
  where: EstateWhereUniqueInput2;
};

export type MutationUpdateOneEstateDetailArgs = {
  data: EstateDetailUpdateInput;
  where: EstateDetailWhereUniqueInput;
};

export type MutationUpdateOneEstateNotificationArgs = {
  data: EstateNotificationUpdateInput;
  where: EstateNotificationWhereUniqueInput;
};

export type MutationUpdateOneExpenseArgs = {
  data: ExpenseUpdateInput;
  where: ExpenseWhereUniqueInput;
};

export type MutationUpdateOneLinkedAccountArgs = {
  data: LinkedAccountUpdateInput;
  where: LinkedAccountWhereUniqueInput;
};

export type MutationUpdateOneMilestoneArgs = {
  data: MilestoneUpdateInput;
  where: MilestoneWhereUniqueInput;
};

export type MutationUpdateOneTaskArgs = {
  data: TaskUpdateInput;
  where: TaskWhereUniqueInput;
};

export type MutationUpdateOneTransactionArgs = {
  data: TransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};

export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput2;
  where: UserWhereUniqueInput2;
};

export type MutationUpdateOneUserLoginArgs = {
  data: UserLoginUpdateInput;
  where: UserLoginWhereUniqueInput;
};

export type MutationUpdateOneUserNotificationArgs = {
  data: UserNotificationUpdateInput;
  where: UserNotificationWhereUniqueInput;
};

export type MutationUpdateOneUsersOnEstatesArgs = {
  data: UsersOnEstatesUpdateInput;
  where: UsersOnEstatesWhereUniqueInput;
};

export type MutationUpdateTeamMembersArgs = {
  estateId: Scalars["String"]["input"];
  userIds: Array<Scalars["String"]["input"]>;
};

export type MutationUpdateUserEstateArgs = {
  data: ValidatedEstateUpdateInput;
  where?: InputMaybe<EstateWhereUniqueInput2>;
};

export type MutationUpdateUserEstateDeceasedSsnArgs = {
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  ssn: Scalars["String"]["input"];
};

export type MutationUpdateUserIdpAttributesArgs = {
  data: UpdateUserIdpInput;
  userId: Scalars["String"]["input"];
};

export type MutationUpdateUserSsnArgs = {
  id: Scalars["String"]["input"];
  ssn: Scalars["String"]["input"];
};

export type MutationUpsertOneActionCardArgs = {
  create: ActionCardCreateInput;
  update: ActionCardUpdateInput;
  where: ActionCardWhereUniqueInput;
};

export type MutationUpsertOneAddressArgs = {
  create: AddressCreateInput;
  update: AddressUpdateInput;
  where: AddressWhereUniqueInput;
};

export type MutationUpsertOneAlixFeeArgs = {
  create: AlixFeeCreateInput;
  update: AlixFeeUpdateInput;
  where: AlixFeeWhereUniqueInput;
};

export type MutationUpsertOneAssetArgs = {
  create: AssetCreateInput;
  update: AssetUpdateInput;
  where: AssetWhereUniqueInput;
};

export type MutationUpsertOneAssetAccountArgs = {
  create: AssetAccountCreateInput;
  update: AssetAccountUpdateInput;
  where: AssetAccountWhereUniqueInput;
};

export type MutationUpsertOneBeneficiaryArgs = {
  create: BeneficiaryCreateInput;
  update: BeneficiaryUpdateInput;
  where: BeneficiaryWhereUniqueInput;
};

export type MutationUpsertOneBeneficiaryDistributionArgs = {
  create: BeneficiaryDistributionCreateInput;
  update: BeneficiaryDistributionUpdateInput;
  where: BeneficiaryDistributionWhereUniqueInput;
};

export type MutationUpsertOneCareTeamNoteArgs = {
  create: CareTeamNoteCreateInput;
  update: CareTeamNoteUpdateInput;
  where: CareTeamNoteWhereUniqueInput;
};

export type MutationUpsertOneComponentArgs = {
  create: ComponentCreateInput;
  update: ComponentUpdateInput;
  where: ComponentWhereUniqueInput;
};

export type MutationUpsertOneContactArgs = {
  create: ContactCreateInput;
  update: ContactUpdateInput;
  where: ContactWhereUniqueInput;
};

export type MutationUpsertOneContactsOnEstatesArgs = {
  create: ContactsOnEstatesCreateInput;
  update: ContactsOnEstatesUpdateInput;
  where: ContactsOnEstatesWhereUniqueInput;
};

export type MutationUpsertOneContentArgs = {
  create: ContentCreateInput;
  update: ContentUpdateInput;
  where: ContentWhereUniqueInput;
};

export type MutationUpsertOneDeceasedArgs = {
  create: DeceasedCreateInput;
  update: DeceasedUpdateInput;
  where: DeceasedWhereUniqueInput;
};

export type MutationUpsertOneDeceasedContactsArgs = {
  create: DeceasedContactsCreateInput;
  update: DeceasedContactsUpdateInput;
  where: DeceasedContactsWhereUniqueInput;
};

export type MutationUpsertOneDeviceRegistrationArgs = {
  create: DeviceRegistrationCreateInput;
  update: DeviceRegistrationUpdateInput;
  where: DeviceRegistrationWhereUniqueInput;
};

export type MutationUpsertOneDocumentsProcessedArgs = {
  create: DocumentsProcessedCreateInput;
  update: DocumentsProcessedUpdateInput;
  where: DocumentsProcessedWhereUniqueInput;
};

export type MutationUpsertOneEulaArgs = {
  create: EulaCreateInput;
  update: EulaUpdateInput;
  where: EulaWhereUniqueInput;
};

export type MutationUpsertOneEulaAcceptanceHistoryArgs = {
  create: EulaAcceptanceHistoryCreateInput;
  update: EulaAcceptanceHistoryUpdateInput;
  where: EulaAcceptanceHistoryWhereUniqueInput;
};

export type MutationUpsertOneEstateArgs = {
  create: EstateCreateInput;
  update: EstateUpdateInput;
  where: EstateWhereUniqueInput;
};

export type MutationUpsertOneEstateDetailArgs = {
  create: EstateDetailCreateInput;
  update: EstateDetailUpdateInput;
  where: EstateDetailWhereUniqueInput;
};

export type MutationUpsertOneEstateNotificationArgs = {
  create: EstateNotificationCreateInput;
  update: EstateNotificationUpdateInput;
  where: EstateNotificationWhereUniqueInput;
};

export type MutationUpsertOneExpenseArgs = {
  create: ExpenseCreateInput;
  update: ExpenseUpdateInput;
  where: ExpenseWhereUniqueInput;
};

export type MutationUpsertOneLinkedAccountArgs = {
  create: LinkedAccountCreateInput;
  update: LinkedAccountUpdateInput;
  where: LinkedAccountWhereUniqueInput;
};

export type MutationUpsertOneMilestoneArgs = {
  create: MilestoneCreateInput;
  update: MilestoneUpdateInput;
  where: MilestoneWhereUniqueInput;
};

export type MutationUpsertOneTaskArgs = {
  create: TaskCreateInput;
  update: TaskUpdateInput;
  where: TaskWhereUniqueInput;
};

export type MutationUpsertOneTransactionArgs = {
  create: TransactionCreateInput;
  update: TransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};

export type MutationUpsertOneUserArgs = {
  create: UserCreateInput;
  update: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type MutationUpsertOneUserLoginArgs = {
  create: UserLoginCreateInput;
  update: UserLoginUpdateInput;
  where: UserLoginWhereUniqueInput;
};

export type MutationUpsertOneUserNotificationArgs = {
  create: UserNotificationCreateInput;
  update: UserNotificationUpdateInput;
  where: UserNotificationWhereUniqueInput;
};

export type MutationUpsertOneUsersOnEstatesArgs = {
  create: UsersOnEstatesCreateInput;
  update: UsersOnEstatesUpdateInput;
  where: UsersOnEstatesWhereUniqueInput;
};

export type MutationWriteCoreEstateInformationArgs = {
  deceased: DeceasedInput2;
  estateId: Scalars["String"]["input"];
  executors?: InputMaybe<Array<ExecutorCreateNestedOneWithoutEstateInput>>;
  hasTrust?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWill?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars["Boolean"]["input"]>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars["Boolean"]["input"]>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedBoolNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars["Boolean"]["input"]>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars["Boolean"]["input"]>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  gt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  gte?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  in?: InputMaybe<Array<Scalars["DateTimeISO"]["input"]>>;
  lt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lte?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTimeISO"]["input"]>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  gt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  gte?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  in?: InputMaybe<Array<Scalars["DateTimeISO"]["input"]>>;
  lt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lte?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTimeISO"]["input"]>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  gt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  gte?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  in?: InputMaybe<Array<Scalars["DateTimeISO"]["input"]>>;
  lt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lte?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTimeISO"]["input"]>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  gt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  gte?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  in?: InputMaybe<Array<Scalars["DateTimeISO"]["input"]>>;
  lt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  lte?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTimeISO"]["input"]>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars["Decimal"]["input"]>;
  gt?: InputMaybe<Scalars["Decimal"]["input"]>;
  gte?: InputMaybe<Scalars["Decimal"]["input"]>;
  in?: InputMaybe<Array<Scalars["Decimal"]["input"]>>;
  lt?: InputMaybe<Scalars["Decimal"]["input"]>;
  lte?: InputMaybe<Scalars["Decimal"]["input"]>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars["Decimal"]["input"]>>;
};

export type NestedDecimalNullableFilter = {
  equals?: InputMaybe<Scalars["Decimal"]["input"]>;
  gt?: InputMaybe<Scalars["Decimal"]["input"]>;
  gte?: InputMaybe<Scalars["Decimal"]["input"]>;
  in?: InputMaybe<Array<Scalars["Decimal"]["input"]>>;
  lt?: InputMaybe<Scalars["Decimal"]["input"]>;
  lte?: InputMaybe<Scalars["Decimal"]["input"]>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Decimal"]["input"]>>;
};

export type NestedDecimalNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDecimalNullableFilter>;
  _min?: InputMaybe<NestedDecimalNullableFilter>;
  _sum?: InputMaybe<NestedDecimalNullableFilter>;
  equals?: InputMaybe<Scalars["Decimal"]["input"]>;
  gt?: InputMaybe<Scalars["Decimal"]["input"]>;
  gte?: InputMaybe<Scalars["Decimal"]["input"]>;
  in?: InputMaybe<Array<Scalars["Decimal"]["input"]>>;
  lt?: InputMaybe<Scalars["Decimal"]["input"]>;
  lte?: InputMaybe<Scalars["Decimal"]["input"]>;
  not?: InputMaybe<NestedDecimalNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["Decimal"]["input"]>>;
};

export type NestedDecimalWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDecimalFilter>;
  _min?: InputMaybe<NestedDecimalFilter>;
  _sum?: InputMaybe<NestedDecimalFilter>;
  equals?: InputMaybe<Scalars["Decimal"]["input"]>;
  gt?: InputMaybe<Scalars["Decimal"]["input"]>;
  gte?: InputMaybe<Scalars["Decimal"]["input"]>;
  in?: InputMaybe<Array<Scalars["Decimal"]["input"]>>;
  lt?: InputMaybe<Scalars["Decimal"]["input"]>;
  lte?: InputMaybe<Scalars["Decimal"]["input"]>;
  not?: InputMaybe<NestedDecimalWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["Decimal"]["input"]>>;
};

export type NestedEnumActionCardStatusFilter = {
  equals?: InputMaybe<ActionCardStatus>;
  in?: InputMaybe<Array<ActionCardStatus>>;
  not?: InputMaybe<NestedEnumActionCardStatusFilter>;
  notIn?: InputMaybe<Array<ActionCardStatus>>;
};

export type NestedEnumActionCardStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumActionCardStatusFilter>;
  _min?: InputMaybe<NestedEnumActionCardStatusFilter>;
  equals?: InputMaybe<ActionCardStatus>;
  in?: InputMaybe<Array<ActionCardStatus>>;
  not?: InputMaybe<NestedEnumActionCardStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ActionCardStatus>>;
};

export type NestedEnumActionCardTypeFilter = {
  equals?: InputMaybe<ActionCardType>;
  in?: InputMaybe<Array<ActionCardType>>;
  not?: InputMaybe<NestedEnumActionCardTypeFilter>;
  notIn?: InputMaybe<Array<ActionCardType>>;
};

export type NestedEnumActionCardTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumActionCardTypeFilter>;
  _min?: InputMaybe<NestedEnumActionCardTypeFilter>;
  equals?: InputMaybe<ActionCardType>;
  in?: InputMaybe<Array<ActionCardType>>;
  not?: InputMaybe<NestedEnumActionCardTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ActionCardType>>;
};

export type NestedEnumAssetCategoryFilter = {
  equals?: InputMaybe<AssetCategory>;
  in?: InputMaybe<Array<AssetCategory>>;
  not?: InputMaybe<NestedEnumAssetCategoryFilter>;
  notIn?: InputMaybe<Array<AssetCategory>>;
};

export type NestedEnumAssetCategoryWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumAssetCategoryFilter>;
  _min?: InputMaybe<NestedEnumAssetCategoryFilter>;
  equals?: InputMaybe<AssetCategory>;
  in?: InputMaybe<Array<AssetCategory>>;
  not?: InputMaybe<NestedEnumAssetCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<AssetCategory>>;
};

export type NestedEnumAssetStatusFilter = {
  equals?: InputMaybe<AssetStatus>;
  in?: InputMaybe<Array<AssetStatus>>;
  not?: InputMaybe<NestedEnumAssetStatusFilter>;
  notIn?: InputMaybe<Array<AssetStatus>>;
};

export type NestedEnumAssetStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumAssetStatusFilter>;
  _min?: InputMaybe<NestedEnumAssetStatusFilter>;
  equals?: InputMaybe<AssetStatus>;
  in?: InputMaybe<Array<AssetStatus>>;
  not?: InputMaybe<NestedEnumAssetStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<AssetStatus>>;
};

export type NestedEnumAssetSubCategoryFilter = {
  equals?: InputMaybe<AssetSubCategory>;
  in?: InputMaybe<Array<AssetSubCategory>>;
  not?: InputMaybe<NestedEnumAssetSubCategoryFilter>;
  notIn?: InputMaybe<Array<AssetSubCategory>>;
};

export type NestedEnumAssetSubCategoryWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumAssetSubCategoryFilter>;
  _min?: InputMaybe<NestedEnumAssetSubCategoryFilter>;
  equals?: InputMaybe<AssetSubCategory>;
  in?: InputMaybe<Array<AssetSubCategory>>;
  not?: InputMaybe<NestedEnumAssetSubCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<AssetSubCategory>>;
};

export type NestedEnumAssigneeTypeNullableFilter = {
  equals?: InputMaybe<AssigneeType>;
  in?: InputMaybe<Array<AssigneeType>>;
  not?: InputMaybe<NestedEnumAssigneeTypeNullableFilter>;
  notIn?: InputMaybe<Array<AssigneeType>>;
};

export type NestedEnumAssigneeTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumAssigneeTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumAssigneeTypeNullableFilter>;
  equals?: InputMaybe<AssigneeType>;
  in?: InputMaybe<Array<AssigneeType>>;
  not?: InputMaybe<NestedEnumAssigneeTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<AssigneeType>>;
};

export type NestedEnumBeneficiaryTypeFilter = {
  equals?: InputMaybe<BeneficiaryType>;
  in?: InputMaybe<Array<BeneficiaryType>>;
  not?: InputMaybe<NestedEnumBeneficiaryTypeFilter>;
  notIn?: InputMaybe<Array<BeneficiaryType>>;
};

export type NestedEnumBeneficiaryTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumBeneficiaryTypeFilter>;
  _min?: InputMaybe<NestedEnumBeneficiaryTypeFilter>;
  equals?: InputMaybe<BeneficiaryType>;
  in?: InputMaybe<Array<BeneficiaryType>>;
  not?: InputMaybe<NestedEnumBeneficiaryTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<BeneficiaryType>>;
};

export type NestedEnumComponentStatusFilter = {
  equals?: InputMaybe<ComponentStatus>;
  in?: InputMaybe<Array<ComponentStatus>>;
  not?: InputMaybe<NestedEnumComponentStatusFilter>;
  notIn?: InputMaybe<Array<ComponentStatus>>;
};

export type NestedEnumComponentStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumComponentStatusFilter>;
  _min?: InputMaybe<NestedEnumComponentStatusFilter>;
  equals?: InputMaybe<ComponentStatus>;
  in?: InputMaybe<Array<ComponentStatus>>;
  not?: InputMaybe<NestedEnumComponentStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ComponentStatus>>;
};

export type NestedEnumComponentSubTypeFilter = {
  equals?: InputMaybe<ComponentSubType>;
  in?: InputMaybe<Array<ComponentSubType>>;
  not?: InputMaybe<NestedEnumComponentSubTypeFilter>;
  notIn?: InputMaybe<Array<ComponentSubType>>;
};

export type NestedEnumComponentSubTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumComponentSubTypeFilter>;
  _min?: InputMaybe<NestedEnumComponentSubTypeFilter>;
  equals?: InputMaybe<ComponentSubType>;
  in?: InputMaybe<Array<ComponentSubType>>;
  not?: InputMaybe<NestedEnumComponentSubTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ComponentSubType>>;
};

export type NestedEnumComponentTypeFilter = {
  equals?: InputMaybe<ComponentType>;
  in?: InputMaybe<Array<ComponentType>>;
  not?: InputMaybe<NestedEnumComponentTypeFilter>;
  notIn?: InputMaybe<Array<ComponentType>>;
};

export type NestedEnumComponentTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumComponentTypeFilter>;
  _min?: InputMaybe<NestedEnumComponentTypeFilter>;
  equals?: InputMaybe<ComponentType>;
  in?: InputMaybe<Array<ComponentType>>;
  not?: InputMaybe<NestedEnumComponentTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ComponentType>>;
};

export type NestedEnumContactEstateRoleNullableFilter = {
  equals?: InputMaybe<ContactEstateRole>;
  in?: InputMaybe<Array<ContactEstateRole>>;
  not?: InputMaybe<NestedEnumContactEstateRoleNullableFilter>;
  notIn?: InputMaybe<Array<ContactEstateRole>>;
};

export type NestedEnumContactEstateRoleNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumContactEstateRoleNullableFilter>;
  _min?: InputMaybe<NestedEnumContactEstateRoleNullableFilter>;
  equals?: InputMaybe<ContactEstateRole>;
  in?: InputMaybe<Array<ContactEstateRole>>;
  not?: InputMaybe<NestedEnumContactEstateRoleNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ContactEstateRole>>;
};

export type NestedEnumContentCompleteByTypeFilter = {
  equals?: InputMaybe<ContentCompleteByType>;
  in?: InputMaybe<Array<ContentCompleteByType>>;
  not?: InputMaybe<NestedEnumContentCompleteByTypeFilter>;
  notIn?: InputMaybe<Array<ContentCompleteByType>>;
};

export type NestedEnumContentCompleteByTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumContentCompleteByTypeFilter>;
  _min?: InputMaybe<NestedEnumContentCompleteByTypeFilter>;
  equals?: InputMaybe<ContentCompleteByType>;
  in?: InputMaybe<Array<ContentCompleteByType>>;
  not?: InputMaybe<NestedEnumContentCompleteByTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ContentCompleteByType>>;
};

export type NestedEnumContentStatusFilter = {
  equals?: InputMaybe<ContentStatus>;
  in?: InputMaybe<Array<ContentStatus>>;
  not?: InputMaybe<NestedEnumContentStatusFilter>;
  notIn?: InputMaybe<Array<ContentStatus>>;
};

export type NestedEnumContentStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumContentStatusFilter>;
  _min?: InputMaybe<NestedEnumContentStatusFilter>;
  equals?: InputMaybe<ContentStatus>;
  in?: InputMaybe<Array<ContentStatus>>;
  not?: InputMaybe<NestedEnumContentStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ContentStatus>>;
};

export type NestedEnumContentTypeNullableFilter = {
  equals?: InputMaybe<ContentType>;
  in?: InputMaybe<Array<ContentType>>;
  not?: InputMaybe<NestedEnumContentTypeNullableFilter>;
  notIn?: InputMaybe<Array<ContentType>>;
};

export type NestedEnumContentTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumContentTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumContentTypeNullableFilter>;
  equals?: InputMaybe<ContentType>;
  in?: InputMaybe<Array<ContentType>>;
  not?: InputMaybe<NestedEnumContentTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ContentType>>;
};

export type NestedEnumDeceasedContactTypeNullableFilter = {
  equals?: InputMaybe<DeceasedContactType>;
  in?: InputMaybe<Array<DeceasedContactType>>;
  not?: InputMaybe<NestedEnumDeceasedContactTypeNullableFilter>;
  notIn?: InputMaybe<Array<DeceasedContactType>>;
};

export type NestedEnumDeceasedContactTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumDeceasedContactTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumDeceasedContactTypeNullableFilter>;
  equals?: InputMaybe<DeceasedContactType>;
  in?: InputMaybe<Array<DeceasedContactType>>;
  not?: InputMaybe<NestedEnumDeceasedContactTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<DeceasedContactType>>;
};

export type NestedEnumFeeTypeFilter = {
  equals?: InputMaybe<FeeType>;
  in?: InputMaybe<Array<FeeType>>;
  not?: InputMaybe<NestedEnumFeeTypeFilter>;
  notIn?: InputMaybe<Array<FeeType>>;
};

export type NestedEnumFeeTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumFeeTypeFilter>;
  _min?: InputMaybe<NestedEnumFeeTypeFilter>;
  equals?: InputMaybe<FeeType>;
  in?: InputMaybe<Array<FeeType>>;
  not?: InputMaybe<NestedEnumFeeTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<FeeType>>;
};

export type NestedEnumMilestoneDateTypeFilter = {
  equals?: InputMaybe<MilestoneDateType>;
  in?: InputMaybe<Array<MilestoneDateType>>;
  not?: InputMaybe<NestedEnumMilestoneDateTypeFilter>;
  notIn?: InputMaybe<Array<MilestoneDateType>>;
};

export type NestedEnumMilestoneDateTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumMilestoneDateTypeFilter>;
  _min?: InputMaybe<NestedEnumMilestoneDateTypeFilter>;
  equals?: InputMaybe<MilestoneDateType>;
  in?: InputMaybe<Array<MilestoneDateType>>;
  not?: InputMaybe<NestedEnumMilestoneDateTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<MilestoneDateType>>;
};

export type NestedEnumProcessingStatusFilter = {
  equals?: InputMaybe<ProcessingStatus>;
  in?: InputMaybe<Array<ProcessingStatus>>;
  not?: InputMaybe<NestedEnumProcessingStatusFilter>;
  notIn?: InputMaybe<Array<ProcessingStatus>>;
};

export type NestedEnumProcessingStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumProcessingStatusFilter>;
  _min?: InputMaybe<NestedEnumProcessingStatusFilter>;
  equals?: InputMaybe<ProcessingStatus>;
  in?: InputMaybe<Array<ProcessingStatus>>;
  not?: InputMaybe<NestedEnumProcessingStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ProcessingStatus>>;
};

export type NestedEnumRelatedElementTypeNullableFilter = {
  equals?: InputMaybe<RelatedElementType>;
  in?: InputMaybe<Array<RelatedElementType>>;
  not?: InputMaybe<NestedEnumRelatedElementTypeNullableFilter>;
  notIn?: InputMaybe<Array<RelatedElementType>>;
};

export type NestedEnumRelatedElementTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumRelatedElementTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumRelatedElementTypeNullableFilter>;
  equals?: InputMaybe<RelatedElementType>;
  in?: InputMaybe<Array<RelatedElementType>>;
  not?: InputMaybe<NestedEnumRelatedElementTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<RelatedElementType>>;
};

export type NestedEnumSexNullableFilter = {
  equals?: InputMaybe<Sex>;
  in?: InputMaybe<Array<Sex>>;
  not?: InputMaybe<NestedEnumSexNullableFilter>;
  notIn?: InputMaybe<Array<Sex>>;
};

export type NestedEnumSexNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumSexNullableFilter>;
  _min?: InputMaybe<NestedEnumSexNullableFilter>;
  equals?: InputMaybe<Sex>;
  in?: InputMaybe<Array<Sex>>;
  not?: InputMaybe<NestedEnumSexNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Sex>>;
};

export type NestedEnumSfxNullableFilter = {
  equals?: InputMaybe<Sfx>;
  in?: InputMaybe<Array<Sfx>>;
  not?: InputMaybe<NestedEnumSfxNullableFilter>;
  notIn?: InputMaybe<Array<Sfx>>;
};

export type NestedEnumSfxNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumSfxNullableFilter>;
  _min?: InputMaybe<NestedEnumSfxNullableFilter>;
  equals?: InputMaybe<Sfx>;
  in?: InputMaybe<Array<Sfx>>;
  not?: InputMaybe<NestedEnumSfxNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Sfx>>;
};

export type NestedEnumTaskStatusFilter = {
  equals?: InputMaybe<TaskStatus>;
  in?: InputMaybe<Array<TaskStatus>>;
  not?: InputMaybe<NestedEnumTaskStatusFilter>;
  notIn?: InputMaybe<Array<TaskStatus>>;
};

export type NestedEnumTaskStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumTaskStatusFilter>;
  _min?: InputMaybe<NestedEnumTaskStatusFilter>;
  equals?: InputMaybe<TaskStatus>;
  in?: InputMaybe<Array<TaskStatus>>;
  not?: InputMaybe<NestedEnumTaskStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<TaskStatus>>;
};

export type NestedEnumUserEstateRoleNullableFilter = {
  equals?: InputMaybe<UserEstateRole>;
  in?: InputMaybe<Array<UserEstateRole>>;
  not?: InputMaybe<NestedEnumUserEstateRoleNullableFilter>;
  notIn?: InputMaybe<Array<UserEstateRole>>;
};

export type NestedEnumUserEstateRoleNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumUserEstateRoleNullableFilter>;
  _min?: InputMaybe<NestedEnumUserEstateRoleNullableFilter>;
  equals?: InputMaybe<UserEstateRole>;
  in?: InputMaybe<Array<UserEstateRole>>;
  not?: InputMaybe<NestedEnumUserEstateRoleNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<UserEstateRole>>;
};

export type NestedEnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type NestedEnumUserTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumUserTypeFilter>;
  _min?: InputMaybe<NestedEnumUserTypeFilter>;
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  gt?: InputMaybe<Scalars["Float"]["input"]>;
  gte?: InputMaybe<Scalars["Float"]["input"]>;
  in?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  lt?: InputMaybe<Scalars["Float"]["input"]>;
  lte?: InputMaybe<Scalars["Float"]["input"]>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]["input"]>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type NestedJsonFilter = {
  array_contains?: InputMaybe<Scalars["JSON"]["input"]>;
  array_ends_with?: InputMaybe<Scalars["JSON"]["input"]>;
  array_starts_with?: InputMaybe<Scalars["JSON"]["input"]>;
  equals?: InputMaybe<Scalars["JSON"]["input"]>;
  gt?: InputMaybe<Scalars["JSON"]["input"]>;
  gte?: InputMaybe<Scalars["JSON"]["input"]>;
  lt?: InputMaybe<Scalars["JSON"]["input"]>;
  lte?: InputMaybe<Scalars["JSON"]["input"]>;
  not?: InputMaybe<Scalars["JSON"]["input"]>;
  path?: InputMaybe<Array<Scalars["String"]["input"]>>;
  string_contains?: InputMaybe<Scalars["String"]["input"]>;
  string_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  string_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export type NestedJsonNullableFilter = {
  array_contains?: InputMaybe<Scalars["JSON"]["input"]>;
  array_ends_with?: InputMaybe<Scalars["JSON"]["input"]>;
  array_starts_with?: InputMaybe<Scalars["JSON"]["input"]>;
  equals?: InputMaybe<Scalars["JSON"]["input"]>;
  gt?: InputMaybe<Scalars["JSON"]["input"]>;
  gte?: InputMaybe<Scalars["JSON"]["input"]>;
  lt?: InputMaybe<Scalars["JSON"]["input"]>;
  lte?: InputMaybe<Scalars["JSON"]["input"]>;
  not?: InputMaybe<Scalars["JSON"]["input"]>;
  path?: InputMaybe<Array<Scalars["String"]["input"]>>;
  string_contains?: InputMaybe<Scalars["String"]["input"]>;
  string_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  string_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

/** What user group (by role) the notification is targeted to. */
export enum NotificationGroup {
  All = "ALL",
  Beneficiary = "BENEFICIARY",
  Executor = "EXECUTOR",
}

export type NotificationLogEntry = {
  __typename?: "NotificationLogEntry";
  actionCardId?: Maybe<Scalars["String"]["output"]>;
  componentId?: Maybe<Scalars["String"]["output"]>;
  contentId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  estateId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  notificationReceipts: Array<NotificationReceipt>;
  route?: Maybe<Scalars["String"]["output"]>;
  routingError?: Maybe<RoutingError>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type NotificationReceipt = {
  __typename?: "NotificationReceipt";
  createdAt: Scalars["DateTimeISO"]["output"];
  notificationId: Scalars["String"]["output"];
  seenAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  user?: Maybe<NotificationUser>;
};

export type NotificationUser = {
  __typename?: "NotificationUser";
  firstName: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  userId: Scalars["String"]["output"];
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type NullableDecimalFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Decimal"]["input"]>;
  divide?: InputMaybe<Scalars["Decimal"]["input"]>;
  increment?: InputMaybe<Scalars["Decimal"]["input"]>;
  multiply?: InputMaybe<Scalars["Decimal"]["input"]>;
  set?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export type NullableEnumAssigneeTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<AssigneeType>;
};

export type NullableEnumContactEstateRoleFieldUpdateOperationsInput = {
  set?: InputMaybe<ContactEstateRole>;
};

export type NullableEnumContentTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ContentType>;
};

export type NullableEnumDeceasedContactTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<DeceasedContactType>;
};

export type NullableEnumRelatedElementTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<RelatedElementType>;
};

export type NullableEnumSexFieldUpdateOperationsInput = {
  set?: InputMaybe<Sex>;
};

export type NullableEnumSfxFieldUpdateOperationsInput = {
  set?: InputMaybe<Sfx>;
};

export type NullableEnumUserEstateRoleFieldUpdateOperationsInput = {
  set?: InputMaybe<UserEstateRole>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["String"]["input"]>;
};

export enum NullsOrder {
  First = "first",
  Last = "last",
}

export enum ProcessingStatus {
  Completed = "COMPLETED",
  Duplicate = "DUPLICATE",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  Pending = "PENDING",
}

export type Query = {
  __typename?: "Query";
  actionCard?: Maybe<ActionCard2>;
  actionCards: Array<ActionCard>;
  address?: Maybe<Address>;
  addresses: Array<Address>;
  aggregateActionCard: AggregateActionCard;
  aggregateAddress: AggregateAddress;
  aggregateAlixFee: AggregateAlixFee;
  aggregateAsset: AggregateAsset;
  aggregateAssetAccount: AggregateAssetAccount;
  aggregateBeneficiary: AggregateBeneficiary;
  aggregateBeneficiaryDistribution: AggregateBeneficiaryDistribution;
  aggregateCareTeamNote: AggregateCareTeamNote;
  aggregateComponent: AggregateComponent;
  aggregateContact: AggregateContact;
  aggregateContactsOnEstates: AggregateContactsOnEstates;
  aggregateContent: AggregateContent;
  aggregateDeceased: AggregateDeceased;
  aggregateDeceasedContacts: AggregateDeceasedContacts;
  aggregateDeviceRegistration: AggregateDeviceRegistration;
  aggregateDocumentsProcessed: AggregateDocumentsProcessed;
  aggregateEULA: AggregateEula;
  aggregateEULAAcceptanceHistory: AggregateEulaAcceptanceHistory;
  aggregateEstate: AggregateEstate;
  aggregateEstateDetail: AggregateEstateDetail;
  aggregateEstateNotification: AggregateEstateNotification;
  aggregateExpense: AggregateExpense;
  aggregateLinkedAccount: AggregateLinkedAccount;
  aggregateMilestone: AggregateMilestone;
  aggregateTask: AggregateTask;
  aggregateTransaction: AggregateTransaction;
  aggregateUser: AggregateUser;
  aggregateUserLogin: AggregateUserLogin;
  aggregateUserNotification: AggregateUserNotification;
  aggregateUsersOnEstates: AggregateUsersOnEstates;
  alixFee?: Maybe<AlixFee>;
  alixFees: Array<AlixFee>;
  asset?: Maybe<Asset>;
  assetAccount?: Maybe<AssetAccount>;
  assetAccounts: Array<AssetAccount>;
  assets: Array<Asset>;
  beneficiaries: Array<Beneficiary>;
  beneficiary?: Maybe<Beneficiary>;
  beneficiaryDistribution?: Maybe<BeneficiaryDistribution>;
  beneficiaryDistributions: Array<BeneficiaryDistribution>;
  careTeamNote?: Maybe<CareTeamNote2>;
  careTeamNotes: Array<CareTeamNote>;
  careTeamNotesForEstate: Array<CareTeamNote2>;
  component?: Maybe<Component>;
  components: Array<Component>;
  contact?: Maybe<Contact>;
  contacts: Array<Contact>;
  content?: Maybe<Content>;
  contents: Array<Content>;
  deceased?: Maybe<Deceased>;
  deceaseds: Array<Deceased>;
  deviceRegistration?: Maybe<DeviceRegistration>;
  deviceRegistrations: Array<DeviceRegistration>;
  documentsProcessed?: Maybe<DocumentsProcessed>;
  documentsProcesseds: Array<DocumentsProcessed>;
  eULA?: Maybe<Eula>;
  eULAAcceptanceHistories: Array<EulaAcceptanceHistory>;
  eULAAcceptanceHistory?: Maybe<EulaAcceptanceHistory>;
  eULAS: Array<Eula>;
  estate?: Maybe<Estate2>;
  estateDetail?: Maybe<EstateDetail>;
  estateDetails: Array<EstateDetail>;
  /**
   * Get the estate for the logged in user. If the user has multiple estates, an estateId can be provided.
   *       If estateId is not provided, the first estate for the user will be returned.
   */
  estateDistributionBreakdown?: Maybe<EstateDistributionBreakdown>;
  estateNotification?: Maybe<EstateNotification>;
  estateNotifications: Array<EstateNotification>;
  estates: Array<Estate2>;
  expense?: Maybe<Expense>;
  expenses: Array<Expense>;
  fetchContentCardsDueInDateRange: Array<Content2>;
  findFirstActionCard?: Maybe<ActionCard>;
  findFirstActionCardOrThrow?: Maybe<ActionCard>;
  findFirstAddress?: Maybe<Address>;
  findFirstAddressOrThrow?: Maybe<Address>;
  findFirstAlixFee?: Maybe<AlixFee>;
  findFirstAlixFeeOrThrow?: Maybe<AlixFee>;
  findFirstAsset?: Maybe<Asset>;
  findFirstAssetAccount?: Maybe<AssetAccount>;
  findFirstAssetAccountOrThrow?: Maybe<AssetAccount>;
  findFirstAssetOrThrow?: Maybe<Asset>;
  findFirstBeneficiary?: Maybe<Beneficiary>;
  findFirstBeneficiaryDistribution?: Maybe<BeneficiaryDistribution>;
  findFirstBeneficiaryDistributionOrThrow?: Maybe<BeneficiaryDistribution>;
  findFirstBeneficiaryOrThrow?: Maybe<Beneficiary>;
  findFirstCareTeamNote?: Maybe<CareTeamNote>;
  findFirstCareTeamNoteOrThrow?: Maybe<CareTeamNote>;
  findFirstComponent?: Maybe<Component>;
  findFirstComponentOrThrow?: Maybe<Component>;
  findFirstContact?: Maybe<Contact>;
  findFirstContactOrThrow?: Maybe<Contact>;
  findFirstContactsOnEstates?: Maybe<ContactsOnEstates>;
  findFirstContactsOnEstatesOrThrow?: Maybe<ContactsOnEstates>;
  findFirstContent?: Maybe<Content>;
  findFirstContentOrThrow?: Maybe<Content>;
  findFirstDeceased?: Maybe<Deceased>;
  findFirstDeceasedContacts?: Maybe<DeceasedContacts>;
  findFirstDeceasedContactsOrThrow?: Maybe<DeceasedContacts>;
  findFirstDeceasedOrThrow?: Maybe<Deceased>;
  findFirstDeviceRegistration?: Maybe<DeviceRegistration>;
  findFirstDeviceRegistrationOrThrow?: Maybe<DeviceRegistration>;
  findFirstDocumentsProcessed?: Maybe<DocumentsProcessed>;
  findFirstDocumentsProcessedOrThrow?: Maybe<DocumentsProcessed>;
  findFirstEULA?: Maybe<Eula>;
  findFirstEULAAcceptanceHistory?: Maybe<EulaAcceptanceHistory>;
  findFirstEULAAcceptanceHistoryOrThrow?: Maybe<EulaAcceptanceHistory>;
  findFirstEULAOrThrow?: Maybe<Eula>;
  findFirstEstate?: Maybe<Estate>;
  findFirstEstateDetail?: Maybe<EstateDetail>;
  findFirstEstateDetailOrThrow?: Maybe<EstateDetail>;
  findFirstEstateNotification?: Maybe<EstateNotification>;
  findFirstEstateNotificationOrThrow?: Maybe<EstateNotification>;
  findFirstEstateOrThrow?: Maybe<Estate>;
  findFirstExpense?: Maybe<Expense>;
  findFirstExpenseOrThrow?: Maybe<Expense>;
  findFirstLinkedAccount?: Maybe<LinkedAccount>;
  findFirstLinkedAccountOrThrow?: Maybe<LinkedAccount>;
  findFirstMilestone?: Maybe<Milestone>;
  findFirstMilestoneOrThrow?: Maybe<Milestone>;
  findFirstTask?: Maybe<Task>;
  findFirstTaskOrThrow?: Maybe<Task>;
  findFirstTransaction?: Maybe<Transaction>;
  findFirstTransactionOrThrow?: Maybe<Transaction>;
  findFirstUser?: Maybe<User>;
  findFirstUserLogin?: Maybe<UserLogin>;
  findFirstUserLoginOrThrow?: Maybe<UserLogin>;
  findFirstUserNotification?: Maybe<UserNotification>;
  findFirstUserNotificationOrThrow?: Maybe<UserNotification>;
  findFirstUserOrThrow?: Maybe<User>;
  findFirstUsersOnEstates?: Maybe<UsersOnEstates>;
  findFirstUsersOnEstatesOrThrow?: Maybe<UsersOnEstates>;
  findManyContactsOnEstates: Array<ContactsOnEstates>;
  findManyDeceasedContacts: Array<DeceasedContacts>;
  findManyUsersOnEstates: Array<UsersOnEstates>;
  findUniqueContactsOnEstates?: Maybe<ContactsOnEstates>;
  findUniqueContactsOnEstatesOrThrow?: Maybe<ContactsOnEstates>;
  findUniqueDeceasedContacts?: Maybe<DeceasedContacts>;
  findUniqueDeceasedContactsOrThrow?: Maybe<DeceasedContacts>;
  findUniqueUsersOnEstates?: Maybe<UsersOnEstates>;
  findUniqueUsersOnEstatesOrThrow?: Maybe<UsersOnEstates>;
  formTemplate: FormTemplate;
  formTemplates: Array<FormTemplate>;
  getActionCard?: Maybe<ActionCard>;
  getAddress?: Maybe<Address>;
  /** Returns the Alix fee information */
  getAlixFee?: Maybe<AlixFee2>;
  /** Get all Internal Users. */
  getAllInternalUsers?: Maybe<Array<User2>>;
  getAsset?: Maybe<Asset2>;
  getAssetAccount?: Maybe<AssetAccount>;
  getAssets: Array<Asset2>;
  getAssetsByEstateId: Array<Asset2>;
  getAssetsByEstateIdAndCategory: Array<Asset2>;
  getBeneficiary?: Maybe<Beneficiary>;
  getBeneficiaryDistribution?: Maybe<BeneficiaryDistribution>;
  getCareTeamNote?: Maybe<CareTeamNote>;
  getComponent?: Maybe<Component>;
  /** Get the component types/subtypes hierarchy. */
  getComponentTypes: Array<ComponentTypeItem>;
  getContact?: Maybe<Contact>;
  getContactById?: Maybe<Contact2>;
  getContactByUserId?: Maybe<Contact2>;
  getContactsList: Array<Contact2>;
  getContent?: Maybe<Content2>;
  /** Either an estateId or a date range are required for this query. If a status is provided, then the query will search for contents whose status matches the provided status. Content Cards will be returned grouped their estateId and then by their status. If a status is included in the query, then only contents with that status will be returned. */
  getContentListGroupedByEstateId: Array<ContentGroupedByEstateItem>;
  /** Query to get a list of all core estate information for all estates */
  getCoreEstateInformationList: Array<CoreEstateInformationListItem>;
  /** Query to get a single core estate information item by it's estate id */
  getCoreEstateInformationListItem: CoreEstateInformationListItem;
  /** Get the logged in user. */
  getCurrentUser?: Maybe<User2>;
  getDeceased?: Maybe<Deceased>;
  getDeviceRegistration?: Maybe<DeviceRegistration>;
  getDocumentsProcessed?: Maybe<DocumentsProcessed>;
  getEULA?: Maybe<Eula>;
  getEULAAcceptanceHistory?: Maybe<EulaAcceptanceHistory>;
  /** Get the estate by estateId. */
  getEstate: Estate2;
  getEstateComponent?: Maybe<Component2>;
  getEstateContents: Array<Content2>;
  getEstateDetail?: Maybe<EstateDetail>;
  getEstateNotification?: Maybe<EstateNotification>;
  /** ADMIN: Get all notifications for all users of an estate. */
  getEstateNotifications?: Maybe<Array<EstateNotification2>>;
  getExpense?: Maybe<Expense>;
  /** Get a Box access token for the current user. */
  getFolderItems: Scalars["String"]["output"];
  getLinkedAccount?: Maybe<LinkedAccount>;
  getMilestone?: Maybe<Milestone>;
  /** Get the most recent EULA. */
  getMostRecentEULA?: Maybe<Eula2>;
  /** ADMIN: Get all notifications for all users of an estate along with their receipts. */
  getNotificationLog: Array<NotificationLogEntry>;
  getSettlementPlan: SettlementPlan;
  /** ADMIN to get all non-deleted estates */
  getSimpleEstateList: Array<Estate2>;
  getTask?: Maybe<Task>;
  getTeamMembers: Array<TeamMember>;
  getTransaction?: Maybe<Transaction>;
  /** Get count of unseen notifications of the logged in user. */
  getUnseenNotificationsCount: Scalars["Int"]["output"];
  getUser?: Maybe<User2>;
  /**
   * Get the estate for the logged in user. If the user has multiple estates, an estateId can be provided.
   *       If estateId is not provided, the first estate for the user will be returned.
   */
  getUserEstate?: Maybe<Estate2>;
  /** Get active notifications of the logged in user. Set unseen: true to get only unseen notifications. */
  getUserEstateNotifications?: Maybe<Array<UserEstateNotification>>;
  getUserLogin?: Maybe<UserLogin>;
  getUserNotification?: Maybe<UserNotification>;
  groupByActionCard: Array<ActionCardGroupBy>;
  groupByAddress: Array<AddressGroupBy>;
  groupByAlixFee: Array<AlixFeeGroupBy>;
  groupByAsset: Array<AssetGroupBy>;
  groupByAssetAccount: Array<AssetAccountGroupBy>;
  groupByBeneficiary: Array<BeneficiaryGroupBy>;
  groupByBeneficiaryDistribution: Array<BeneficiaryDistributionGroupBy>;
  groupByCareTeamNote: Array<CareTeamNoteGroupBy>;
  groupByComponent: Array<ComponentGroupBy>;
  groupByContact: Array<ContactGroupBy>;
  groupByContactsOnEstates: Array<ContactsOnEstatesGroupBy>;
  groupByContent: Array<ContentGroupBy>;
  groupByDeceased: Array<DeceasedGroupBy>;
  groupByDeceasedContacts: Array<DeceasedContactsGroupBy>;
  groupByDeviceRegistration: Array<DeviceRegistrationGroupBy>;
  groupByDocumentsProcessed: Array<DocumentsProcessedGroupBy>;
  groupByEULA: Array<EulaGroupBy>;
  groupByEULAAcceptanceHistory: Array<EulaAcceptanceHistoryGroupBy>;
  groupByEstate: Array<EstateGroupBy>;
  groupByEstateDetail: Array<EstateDetailGroupBy>;
  groupByEstateNotification: Array<EstateNotificationGroupBy>;
  groupByExpense: Array<ExpenseGroupBy>;
  groupByLinkedAccount: Array<LinkedAccountGroupBy>;
  groupByMilestone: Array<MilestoneGroupBy>;
  groupByTask: Array<TaskGroupBy>;
  groupByTransaction: Array<TransactionGroupBy>;
  groupByUser: Array<UserGroupBy>;
  groupByUserLogin: Array<UserLoginGroupBy>;
  groupByUserNotification: Array<UserNotificationGroupBy>;
  groupByUsersOnEstates: Array<UsersOnEstatesGroupBy>;
  /** Check if the EULA is current. */
  isEULAcurrent: Scalars["Boolean"]["output"];
  linkedAccount?: Maybe<LinkedAccount>;
  linkedAccounts: Array<LinkedAccount>;
  /** List all the files and Directories in the automation-input directory */
  listAutomationInputs: Array<Scalars["String"]["output"]>;
  /** List all documents marked for deletion. Admins must provide an estateId */
  listDeletedDocuments: Array<EstateDocument>;
  /** Get a signed url for document upload. Admins must provide an estateId */
  listDocuments: Array<EstateDocument>;
  milestone?: Maybe<Milestone>;
  milestones: Array<Milestone>;
  task?: Maybe<Task>;
  tasks: Array<Task>;
  transaction?: Maybe<Transaction>;
  transactions: Array<Transaction>;
  user?: Maybe<User>;
  userLogin?: Maybe<UserLogin>;
  userLogins: Array<UserLogin>;
  userNotification?: Maybe<UserNotification>;
  userNotifications: Array<UserNotification>;
  users: Array<User2>;
};

export type QueryActionCardArgs = {
  where: ActionCardWhereUniqueInput2;
};

export type QueryActionCardsArgs = {
  cursor?: InputMaybe<ActionCardWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActionCardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActionCardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActionCardWhereInput>;
};

export type QueryAddressArgs = {
  where: AddressWhereUniqueInput;
};

export type QueryAddressesArgs = {
  cursor?: InputMaybe<AddressWhereUniqueInput>;
  distinct?: InputMaybe<Array<AddressScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AddressOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AddressWhereInput>;
};

export type QueryAggregateActionCardArgs = {
  cursor?: InputMaybe<ActionCardWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ActionCardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActionCardWhereInput>;
};

export type QueryAggregateAddressArgs = {
  cursor?: InputMaybe<AddressWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AddressOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AddressWhereInput>;
};

export type QueryAggregateAlixFeeArgs = {
  cursor?: InputMaybe<AlixFeeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AlixFeeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AlixFeeWhereInput>;
};

export type QueryAggregateAssetArgs = {
  cursor?: InputMaybe<AssetWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AssetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetWhereInput>;
};

export type QueryAggregateAssetAccountArgs = {
  cursor?: InputMaybe<AssetAccountWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AssetAccountOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetAccountWhereInput>;
};

export type QueryAggregateBeneficiaryArgs = {
  cursor?: InputMaybe<BeneficiaryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BeneficiaryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<BeneficiaryWhereInput>;
};

export type QueryAggregateBeneficiaryDistributionArgs = {
  cursor?: InputMaybe<BeneficiaryDistributionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BeneficiaryDistributionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<BeneficiaryDistributionWhereInput>;
};

export type QueryAggregateCareTeamNoteArgs = {
  cursor?: InputMaybe<CareTeamNoteWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CareTeamNoteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CareTeamNoteWhereInput>;
};

export type QueryAggregateComponentArgs = {
  cursor?: InputMaybe<ComponentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ComponentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ComponentWhereInput>;
};

export type QueryAggregateContactArgs = {
  cursor?: InputMaybe<ContactWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ContactOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContactWhereInput>;
};

export type QueryAggregateContactsOnEstatesArgs = {
  cursor?: InputMaybe<ContactsOnEstatesWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ContactsOnEstatesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContactsOnEstatesWhereInput>;
};

export type QueryAggregateContentArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContentWhereInput>;
};

export type QueryAggregateDeceasedArgs = {
  cursor?: InputMaybe<DeceasedWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DeceasedOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeceasedWhereInput>;
};

export type QueryAggregateDeceasedContactsArgs = {
  cursor?: InputMaybe<DeceasedContactsWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DeceasedContactsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeceasedContactsWhereInput>;
};

export type QueryAggregateDeviceRegistrationArgs = {
  cursor?: InputMaybe<DeviceRegistrationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DeviceRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeviceRegistrationWhereInput>;
};

export type QueryAggregateDocumentsProcessedArgs = {
  cursor?: InputMaybe<DocumentsProcessedWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DocumentsProcessedOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DocumentsProcessedWhereInput>;
};

export type QueryAggregateEulaArgs = {
  cursor?: InputMaybe<EulaWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EulaOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EulaWhereInput>;
};

export type QueryAggregateEulaAcceptanceHistoryArgs = {
  cursor?: InputMaybe<EulaAcceptanceHistoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EulaAcceptanceHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EulaAcceptanceHistoryWhereInput>;
};

export type QueryAggregateEstateArgs = {
  cursor?: InputMaybe<EstateWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EstateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateWhereInput>;
};

export type QueryAggregateEstateDetailArgs = {
  cursor?: InputMaybe<EstateDetailWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EstateDetailOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateDetailWhereInput>;
};

export type QueryAggregateEstateNotificationArgs = {
  cursor?: InputMaybe<EstateNotificationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EstateNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateNotificationWhereInput>;
};

export type QueryAggregateExpenseArgs = {
  cursor?: InputMaybe<ExpenseWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ExpenseOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ExpenseWhereInput>;
};

export type QueryAggregateLinkedAccountArgs = {
  cursor?: InputMaybe<LinkedAccountWhereUniqueInput>;
  orderBy?: InputMaybe<Array<LinkedAccountOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LinkedAccountWhereInput>;
};

export type QueryAggregateMilestoneArgs = {
  cursor?: InputMaybe<MilestoneWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MilestoneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MilestoneWhereInput>;
};

export type QueryAggregateTaskArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TaskWhereInput>;
};

export type QueryAggregateTransactionArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type QueryAggregateUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryAggregateUserLoginArgs = {
  cursor?: InputMaybe<UserLoginWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserLoginOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserLoginWhereInput>;
};

export type QueryAggregateUserNotificationArgs = {
  cursor?: InputMaybe<UserNotificationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserNotificationWhereInput>;
};

export type QueryAggregateUsersOnEstatesArgs = {
  cursor?: InputMaybe<UsersOnEstatesWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UsersOnEstatesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UsersOnEstatesWhereInput>;
};

export type QueryAlixFeeArgs = {
  where: AlixFeeWhereUniqueInput;
};

export type QueryAlixFeesArgs = {
  cursor?: InputMaybe<AlixFeeWhereUniqueInput>;
  distinct?: InputMaybe<Array<AlixFeeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AlixFeeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AlixFeeWhereInput>;
};

export type QueryAssetArgs = {
  where: AssetWhereUniqueInput;
};

export type QueryAssetAccountArgs = {
  where: AssetAccountWhereUniqueInput;
};

export type QueryAssetAccountsArgs = {
  cursor?: InputMaybe<AssetAccountWhereUniqueInput>;
  distinct?: InputMaybe<Array<AssetAccountScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AssetAccountOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetAccountWhereInput>;
};

export type QueryAssetsArgs = {
  cursor?: InputMaybe<AssetWhereUniqueInput>;
  distinct?: InputMaybe<Array<AssetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AssetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetWhereInput>;
};

export type QueryBeneficiariesArgs = {
  cursor?: InputMaybe<BeneficiaryWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<BeneficiaryWhereInput>;
};

export type QueryBeneficiaryArgs = {
  where: BeneficiaryWhereUniqueInput;
};

export type QueryBeneficiaryDistributionArgs = {
  where: BeneficiaryDistributionWhereUniqueInput;
};

export type QueryBeneficiaryDistributionsArgs = {
  cursor?: InputMaybe<BeneficiaryDistributionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryDistributionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryDistributionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<BeneficiaryDistributionWhereInput>;
};

export type QueryCareTeamNoteArgs = {
  id: Scalars["String"]["input"];
};

export type QueryCareTeamNotesArgs = {
  cursor?: InputMaybe<CareTeamNoteWhereUniqueInput>;
  distinct?: InputMaybe<Array<CareTeamNoteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CareTeamNoteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CareTeamNoteWhereInput>;
};

export type QueryCareTeamNotesForEstateArgs = {
  estateId: Scalars["String"]["input"];
};

export type QueryComponentArgs = {
  where: ComponentWhereUniqueInput;
};

export type QueryComponentsArgs = {
  cursor?: InputMaybe<ComponentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ComponentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ComponentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ComponentWhereInput>;
};

export type QueryContactArgs = {
  where: ContactWhereUniqueInput;
};

export type QueryContactsArgs = {
  cursor?: InputMaybe<ContactWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContactScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContactOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContactWhereInput>;
};

export type QueryContentArgs = {
  where: ContentWhereUniqueInput;
};

export type QueryContentsArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContentWhereInput>;
};

export type QueryDeceasedArgs = {
  where: DeceasedWhereUniqueInput;
};

export type QueryDeceasedsArgs = {
  cursor?: InputMaybe<DeceasedWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeceasedScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeceasedOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeceasedWhereInput>;
};

export type QueryDeviceRegistrationArgs = {
  where: DeviceRegistrationWhereUniqueInput;
};

export type QueryDeviceRegistrationsArgs = {
  cursor?: InputMaybe<DeviceRegistrationWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeviceRegistrationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeviceRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeviceRegistrationWhereInput>;
};

export type QueryDocumentsProcessedArgs = {
  where: DocumentsProcessedWhereUniqueInput;
};

export type QueryDocumentsProcessedsArgs = {
  cursor?: InputMaybe<DocumentsProcessedWhereUniqueInput>;
  distinct?: InputMaybe<Array<DocumentsProcessedScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DocumentsProcessedOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DocumentsProcessedWhereInput>;
};

export type QueryEUlaArgs = {
  where: EulaWhereUniqueInput;
};

export type QueryEUlaAcceptanceHistoriesArgs = {
  cursor?: InputMaybe<EulaAcceptanceHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<EulaAcceptanceHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EulaAcceptanceHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EulaAcceptanceHistoryWhereInput>;
};

export type QueryEUlaAcceptanceHistoryArgs = {
  where: EulaAcceptanceHistoryWhereUniqueInput;
};

export type QueryEUlasArgs = {
  cursor?: InputMaybe<EulaWhereUniqueInput>;
  distinct?: InputMaybe<Array<EulaScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EulaOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EulaWhereInput>;
};

export type QueryEstateArgs = {
  where: EstateWhereUniqueInput2;
};

export type QueryEstateDetailArgs = {
  where: EstateDetailWhereUniqueInput;
};

export type QueryEstateDetailsArgs = {
  cursor?: InputMaybe<EstateDetailWhereUniqueInput>;
  distinct?: InputMaybe<Array<EstateDetailScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EstateDetailOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateDetailWhereInput>;
};

export type QueryEstateDistributionBreakdownArgs = {
  estateId: Scalars["String"]["input"];
};

export type QueryEstateNotificationArgs = {
  where: EstateNotificationWhereUniqueInput;
};

export type QueryEstateNotificationsArgs = {
  cursor?: InputMaybe<EstateNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<EstateNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EstateNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateNotificationWhereInput>;
};

export type QueryExpenseArgs = {
  where: ExpenseWhereUniqueInput;
};

export type QueryExpensesArgs = {
  cursor?: InputMaybe<ExpenseWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExpenseScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExpenseOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ExpenseWhereInput>;
};

export type QueryFetchContentCardsDueInDateRangeArgs = {
  endDate: Scalars["DateTimeISO"]["input"];
  startDate: Scalars["DateTimeISO"]["input"];
};

export type QueryFindFirstActionCardArgs = {
  cursor?: InputMaybe<ActionCardWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActionCardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActionCardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActionCardWhereInput>;
};

export type QueryFindFirstActionCardOrThrowArgs = {
  cursor?: InputMaybe<ActionCardWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActionCardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActionCardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActionCardWhereInput>;
};

export type QueryFindFirstAddressArgs = {
  cursor?: InputMaybe<AddressWhereUniqueInput>;
  distinct?: InputMaybe<Array<AddressScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AddressOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AddressWhereInput>;
};

export type QueryFindFirstAddressOrThrowArgs = {
  cursor?: InputMaybe<AddressWhereUniqueInput>;
  distinct?: InputMaybe<Array<AddressScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AddressOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AddressWhereInput>;
};

export type QueryFindFirstAlixFeeArgs = {
  cursor?: InputMaybe<AlixFeeWhereUniqueInput>;
  distinct?: InputMaybe<Array<AlixFeeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AlixFeeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AlixFeeWhereInput>;
};

export type QueryFindFirstAlixFeeOrThrowArgs = {
  cursor?: InputMaybe<AlixFeeWhereUniqueInput>;
  distinct?: InputMaybe<Array<AlixFeeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AlixFeeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AlixFeeWhereInput>;
};

export type QueryFindFirstAssetArgs = {
  cursor?: InputMaybe<AssetWhereUniqueInput>;
  distinct?: InputMaybe<Array<AssetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AssetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetWhereInput>;
};

export type QueryFindFirstAssetAccountArgs = {
  cursor?: InputMaybe<AssetAccountWhereUniqueInput>;
  distinct?: InputMaybe<Array<AssetAccountScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AssetAccountOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetAccountWhereInput>;
};

export type QueryFindFirstAssetAccountOrThrowArgs = {
  cursor?: InputMaybe<AssetAccountWhereUniqueInput>;
  distinct?: InputMaybe<Array<AssetAccountScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AssetAccountOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetAccountWhereInput>;
};

export type QueryFindFirstAssetOrThrowArgs = {
  cursor?: InputMaybe<AssetWhereUniqueInput>;
  distinct?: InputMaybe<Array<AssetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AssetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetWhereInput>;
};

export type QueryFindFirstBeneficiaryArgs = {
  cursor?: InputMaybe<BeneficiaryWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<BeneficiaryWhereInput>;
};

export type QueryFindFirstBeneficiaryDistributionArgs = {
  cursor?: InputMaybe<BeneficiaryDistributionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryDistributionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryDistributionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<BeneficiaryDistributionWhereInput>;
};

export type QueryFindFirstBeneficiaryDistributionOrThrowArgs = {
  cursor?: InputMaybe<BeneficiaryDistributionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryDistributionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryDistributionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<BeneficiaryDistributionWhereInput>;
};

export type QueryFindFirstBeneficiaryOrThrowArgs = {
  cursor?: InputMaybe<BeneficiaryWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<BeneficiaryWhereInput>;
};

export type QueryFindFirstCareTeamNoteArgs = {
  cursor?: InputMaybe<CareTeamNoteWhereUniqueInput>;
  distinct?: InputMaybe<Array<CareTeamNoteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CareTeamNoteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CareTeamNoteWhereInput>;
};

export type QueryFindFirstCareTeamNoteOrThrowArgs = {
  cursor?: InputMaybe<CareTeamNoteWhereUniqueInput>;
  distinct?: InputMaybe<Array<CareTeamNoteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CareTeamNoteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CareTeamNoteWhereInput>;
};

export type QueryFindFirstComponentArgs = {
  cursor?: InputMaybe<ComponentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ComponentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ComponentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ComponentWhereInput>;
};

export type QueryFindFirstComponentOrThrowArgs = {
  cursor?: InputMaybe<ComponentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ComponentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ComponentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ComponentWhereInput>;
};

export type QueryFindFirstContactArgs = {
  cursor?: InputMaybe<ContactWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContactScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContactOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContactWhereInput>;
};

export type QueryFindFirstContactOrThrowArgs = {
  cursor?: InputMaybe<ContactWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContactScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContactOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContactWhereInput>;
};

export type QueryFindFirstContactsOnEstatesArgs = {
  cursor?: InputMaybe<ContactsOnEstatesWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContactsOnEstatesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContactsOnEstatesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContactsOnEstatesWhereInput>;
};

export type QueryFindFirstContactsOnEstatesOrThrowArgs = {
  cursor?: InputMaybe<ContactsOnEstatesWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContactsOnEstatesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContactsOnEstatesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContactsOnEstatesWhereInput>;
};

export type QueryFindFirstContentArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContentWhereInput>;
};

export type QueryFindFirstContentOrThrowArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContentWhereInput>;
};

export type QueryFindFirstDeceasedArgs = {
  cursor?: InputMaybe<DeceasedWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeceasedScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeceasedOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeceasedWhereInput>;
};

export type QueryFindFirstDeceasedContactsArgs = {
  cursor?: InputMaybe<DeceasedContactsWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeceasedContactsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeceasedContactsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeceasedContactsWhereInput>;
};

export type QueryFindFirstDeceasedContactsOrThrowArgs = {
  cursor?: InputMaybe<DeceasedContactsWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeceasedContactsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeceasedContactsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeceasedContactsWhereInput>;
};

export type QueryFindFirstDeceasedOrThrowArgs = {
  cursor?: InputMaybe<DeceasedWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeceasedScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeceasedOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeceasedWhereInput>;
};

export type QueryFindFirstDeviceRegistrationArgs = {
  cursor?: InputMaybe<DeviceRegistrationWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeviceRegistrationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeviceRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeviceRegistrationWhereInput>;
};

export type QueryFindFirstDeviceRegistrationOrThrowArgs = {
  cursor?: InputMaybe<DeviceRegistrationWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeviceRegistrationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeviceRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeviceRegistrationWhereInput>;
};

export type QueryFindFirstDocumentsProcessedArgs = {
  cursor?: InputMaybe<DocumentsProcessedWhereUniqueInput>;
  distinct?: InputMaybe<Array<DocumentsProcessedScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DocumentsProcessedOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DocumentsProcessedWhereInput>;
};

export type QueryFindFirstDocumentsProcessedOrThrowArgs = {
  cursor?: InputMaybe<DocumentsProcessedWhereUniqueInput>;
  distinct?: InputMaybe<Array<DocumentsProcessedScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DocumentsProcessedOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DocumentsProcessedWhereInput>;
};

export type QueryFindFirstEulaArgs = {
  cursor?: InputMaybe<EulaWhereUniqueInput>;
  distinct?: InputMaybe<Array<EulaScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EulaOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EulaWhereInput>;
};

export type QueryFindFirstEulaAcceptanceHistoryArgs = {
  cursor?: InputMaybe<EulaAcceptanceHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<EulaAcceptanceHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EulaAcceptanceHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EulaAcceptanceHistoryWhereInput>;
};

export type QueryFindFirstEulaAcceptanceHistoryOrThrowArgs = {
  cursor?: InputMaybe<EulaAcceptanceHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<EulaAcceptanceHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EulaAcceptanceHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EulaAcceptanceHistoryWhereInput>;
};

export type QueryFindFirstEulaOrThrowArgs = {
  cursor?: InputMaybe<EulaWhereUniqueInput>;
  distinct?: InputMaybe<Array<EulaScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EulaOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EulaWhereInput>;
};

export type QueryFindFirstEstateArgs = {
  cursor?: InputMaybe<EstateWhereUniqueInput>;
  distinct?: InputMaybe<Array<EstateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EstateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateWhereInput>;
};

export type QueryFindFirstEstateDetailArgs = {
  cursor?: InputMaybe<EstateDetailWhereUniqueInput>;
  distinct?: InputMaybe<Array<EstateDetailScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EstateDetailOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateDetailWhereInput>;
};

export type QueryFindFirstEstateDetailOrThrowArgs = {
  cursor?: InputMaybe<EstateDetailWhereUniqueInput>;
  distinct?: InputMaybe<Array<EstateDetailScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EstateDetailOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateDetailWhereInput>;
};

export type QueryFindFirstEstateNotificationArgs = {
  cursor?: InputMaybe<EstateNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<EstateNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EstateNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateNotificationWhereInput>;
};

export type QueryFindFirstEstateNotificationOrThrowArgs = {
  cursor?: InputMaybe<EstateNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<EstateNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EstateNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateNotificationWhereInput>;
};

export type QueryFindFirstEstateOrThrowArgs = {
  cursor?: InputMaybe<EstateWhereUniqueInput>;
  distinct?: InputMaybe<Array<EstateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EstateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateWhereInput>;
};

export type QueryFindFirstExpenseArgs = {
  cursor?: InputMaybe<ExpenseWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExpenseScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExpenseOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ExpenseWhereInput>;
};

export type QueryFindFirstExpenseOrThrowArgs = {
  cursor?: InputMaybe<ExpenseWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExpenseScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExpenseOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ExpenseWhereInput>;
};

export type QueryFindFirstLinkedAccountArgs = {
  cursor?: InputMaybe<LinkedAccountWhereUniqueInput>;
  distinct?: InputMaybe<Array<LinkedAccountScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LinkedAccountOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LinkedAccountWhereInput>;
};

export type QueryFindFirstLinkedAccountOrThrowArgs = {
  cursor?: InputMaybe<LinkedAccountWhereUniqueInput>;
  distinct?: InputMaybe<Array<LinkedAccountScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LinkedAccountOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LinkedAccountWhereInput>;
};

export type QueryFindFirstMilestoneArgs = {
  cursor?: InputMaybe<MilestoneWhereUniqueInput>;
  distinct?: InputMaybe<Array<MilestoneScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MilestoneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MilestoneWhereInput>;
};

export type QueryFindFirstMilestoneOrThrowArgs = {
  cursor?: InputMaybe<MilestoneWhereUniqueInput>;
  distinct?: InputMaybe<Array<MilestoneScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MilestoneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MilestoneWhereInput>;
};

export type QueryFindFirstTaskArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TaskWhereInput>;
};

export type QueryFindFirstTaskOrThrowArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TaskWhereInput>;
};

export type QueryFindFirstTransactionArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type QueryFindFirstTransactionOrThrowArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type QueryFindFirstUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryFindFirstUserLoginArgs = {
  cursor?: InputMaybe<UserLoginWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserLoginScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserLoginOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserLoginWhereInput>;
};

export type QueryFindFirstUserLoginOrThrowArgs = {
  cursor?: InputMaybe<UserLoginWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserLoginScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserLoginOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserLoginWhereInput>;
};

export type QueryFindFirstUserNotificationArgs = {
  cursor?: InputMaybe<UserNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserNotificationWhereInput>;
};

export type QueryFindFirstUserNotificationOrThrowArgs = {
  cursor?: InputMaybe<UserNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserNotificationWhereInput>;
};

export type QueryFindFirstUserOrThrowArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryFindFirstUsersOnEstatesArgs = {
  cursor?: InputMaybe<UsersOnEstatesWhereUniqueInput>;
  distinct?: InputMaybe<Array<UsersOnEstatesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UsersOnEstatesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UsersOnEstatesWhereInput>;
};

export type QueryFindFirstUsersOnEstatesOrThrowArgs = {
  cursor?: InputMaybe<UsersOnEstatesWhereUniqueInput>;
  distinct?: InputMaybe<Array<UsersOnEstatesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UsersOnEstatesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UsersOnEstatesWhereInput>;
};

export type QueryFindManyContactsOnEstatesArgs = {
  cursor?: InputMaybe<ContactsOnEstatesWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContactsOnEstatesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContactsOnEstatesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContactsOnEstatesWhereInput>;
};

export type QueryFindManyDeceasedContactsArgs = {
  cursor?: InputMaybe<DeceasedContactsWhereUniqueInput>;
  distinct?: InputMaybe<Array<DeceasedContactsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DeceasedContactsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeceasedContactsWhereInput>;
};

export type QueryFindManyUsersOnEstatesArgs = {
  cursor?: InputMaybe<UsersOnEstatesWhereUniqueInput>;
  distinct?: InputMaybe<Array<UsersOnEstatesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UsersOnEstatesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UsersOnEstatesWhereInput>;
};

export type QueryFindUniqueContactsOnEstatesArgs = {
  where: ContactsOnEstatesWhereUniqueInput;
};

export type QueryFindUniqueContactsOnEstatesOrThrowArgs = {
  where: ContactsOnEstatesWhereUniqueInput;
};

export type QueryFindUniqueDeceasedContactsArgs = {
  where: DeceasedContactsWhereUniqueInput;
};

export type QueryFindUniqueDeceasedContactsOrThrowArgs = {
  where: DeceasedContactsWhereUniqueInput;
};

export type QueryFindUniqueUsersOnEstatesArgs = {
  where: UsersOnEstatesWhereUniqueInput;
};

export type QueryFindUniqueUsersOnEstatesOrThrowArgs = {
  where: UsersOnEstatesWhereUniqueInput;
};

export type QueryFormTemplateArgs = {
  templateId: Scalars["String"]["input"];
};

export type QueryGetActionCardArgs = {
  where: ActionCardWhereUniqueInput;
};

export type QueryGetAddressArgs = {
  where: AddressWhereUniqueInput;
};

export type QueryGetAlixFeeArgs = {
  estateId: Scalars["String"]["input"];
};

export type QueryGetAssetArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetAssetAccountArgs = {
  where: AssetAccountWhereUniqueInput;
};

export type QueryGetAssetsByEstateIdArgs = {
  estateId: Scalars["String"]["input"];
};

export type QueryGetAssetsByEstateIdAndCategoryArgs = {
  category?: InputMaybe<AssetCategory>;
  estateId: Scalars["String"]["input"];
  subcategory?: InputMaybe<AssetSubCategory>;
};

export type QueryGetBeneficiaryArgs = {
  where: BeneficiaryWhereUniqueInput;
};

export type QueryGetBeneficiaryDistributionArgs = {
  where: BeneficiaryDistributionWhereUniqueInput;
};

export type QueryGetCareTeamNoteArgs = {
  where: CareTeamNoteWhereUniqueInput;
};

export type QueryGetComponentArgs = {
  where: ComponentWhereUniqueInput;
};

export type QueryGetContactArgs = {
  where: ContactWhereUniqueInput;
};

export type QueryGetContactByIdArgs = {
  contactId: Scalars["String"]["input"];
};

export type QueryGetContactByUserIdArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetContactsListArgs = {
  orderBy?: InputMaybe<ContactOrderByInput2>;
  where?: InputMaybe<ContactWhereInput2>;
};

export type QueryGetContentArgs = {
  where: ContentWhereUniqueInput2;
};

export type QueryGetContentListGroupedByEstateIdArgs = {
  filter: GetContentListGroupedByEstateFilter;
};

export type QueryGetCoreEstateInformationListItemArgs = {
  estateId: Scalars["String"]["input"];
};

export type QueryGetDeceasedArgs = {
  where: DeceasedWhereUniqueInput;
};

export type QueryGetDeviceRegistrationArgs = {
  where: DeviceRegistrationWhereUniqueInput;
};

export type QueryGetDocumentsProcessedArgs = {
  where: DocumentsProcessedWhereUniqueInput;
};

export type QueryGetEulaArgs = {
  where: EulaWhereUniqueInput;
};

export type QueryGetEulaAcceptanceHistoryArgs = {
  where: EulaAcceptanceHistoryWhereUniqueInput;
};

export type QueryGetEstateArgs = {
  estateId: Scalars["String"]["input"];
};

export type QueryGetEstateComponentArgs = {
  componentId: Scalars["String"]["input"];
};

export type QueryGetEstateContentsArgs = {
  estateId: Scalars["String"]["input"];
  filter?: InputMaybe<EstateContentsFilterInput>;
};

export type QueryGetEstateDetailArgs = {
  where: EstateDetailWhereUniqueInput;
};

export type QueryGetEstateNotificationArgs = {
  where: EstateNotificationWhereUniqueInput;
};

export type QueryGetEstateNotificationsArgs = {
  estateId: Scalars["String"]["input"];
};

export type QueryGetExpenseArgs = {
  where: ExpenseWhereUniqueInput;
};

export type QueryGetFolderItemsArgs = {
  folderId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetLinkedAccountArgs = {
  where: LinkedAccountWhereUniqueInput;
};

export type QueryGetMilestoneArgs = {
  where: MilestoneWhereUniqueInput;
};

export type QueryGetNotificationLogArgs = {
  estateId: Scalars["String"]["input"];
};

export type QueryGetSettlementPlanArgs = {
  estateId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetSimpleEstateListArgs = {
  filters?: InputMaybe<EstateFilter>;
};

export type QueryGetTaskArgs = {
  where: TaskWhereUniqueInput;
};

export type QueryGetTeamMembersArgs = {
  estateId: Scalars["String"]["input"];
};

export type QueryGetTransactionArgs = {
  where: TransactionWhereUniqueInput;
};

export type QueryGetUnseenNotificationsCountArgs = {
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetUserArgs = {
  where: UserWhereUniqueInput2;
};

export type QueryGetUserEstateArgs = {
  estateId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetUserEstateNotificationsArgs = {
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  unseen?: InputMaybe<Scalars["Boolean"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetUserLoginArgs = {
  where: UserLoginWhereUniqueInput;
};

export type QueryGetUserNotificationArgs = {
  where: UserNotificationWhereUniqueInput;
};

export type QueryGroupByActionCardArgs = {
  by: Array<ActionCardScalarFieldEnum>;
  having?: InputMaybe<ActionCardScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ActionCardOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActionCardWhereInput>;
};

export type QueryGroupByAddressArgs = {
  by: Array<AddressScalarFieldEnum>;
  having?: InputMaybe<AddressScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<AddressOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AddressWhereInput>;
};

export type QueryGroupByAlixFeeArgs = {
  by: Array<AlixFeeScalarFieldEnum>;
  having?: InputMaybe<AlixFeeScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<AlixFeeOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AlixFeeWhereInput>;
};

export type QueryGroupByAssetArgs = {
  by: Array<AssetScalarFieldEnum>;
  having?: InputMaybe<AssetScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<AssetOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetWhereInput>;
};

export type QueryGroupByAssetAccountArgs = {
  by: Array<AssetAccountScalarFieldEnum>;
  having?: InputMaybe<AssetAccountScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<AssetAccountOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetAccountWhereInput>;
};

export type QueryGroupByBeneficiaryArgs = {
  by: Array<BeneficiaryScalarFieldEnum>;
  having?: InputMaybe<BeneficiaryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<BeneficiaryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<BeneficiaryWhereInput>;
};

export type QueryGroupByBeneficiaryDistributionArgs = {
  by: Array<BeneficiaryDistributionScalarFieldEnum>;
  having?: InputMaybe<BeneficiaryDistributionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<
    Array<BeneficiaryDistributionOrderByWithAggregationInput>
  >;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<BeneficiaryDistributionWhereInput>;
};

export type QueryGroupByCareTeamNoteArgs = {
  by: Array<CareTeamNoteScalarFieldEnum>;
  having?: InputMaybe<CareTeamNoteScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CareTeamNoteOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CareTeamNoteWhereInput>;
};

export type QueryGroupByComponentArgs = {
  by: Array<ComponentScalarFieldEnum>;
  having?: InputMaybe<ComponentScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ComponentOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ComponentWhereInput>;
};

export type QueryGroupByContactArgs = {
  by: Array<ContactScalarFieldEnum>;
  having?: InputMaybe<ContactScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ContactOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContactWhereInput>;
};

export type QueryGroupByContactsOnEstatesArgs = {
  by: Array<ContactsOnEstatesScalarFieldEnum>;
  having?: InputMaybe<ContactsOnEstatesScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ContactsOnEstatesOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContactsOnEstatesWhereInput>;
};

export type QueryGroupByContentArgs = {
  by: Array<ContentScalarFieldEnum>;
  having?: InputMaybe<ContentScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ContentOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContentWhereInput>;
};

export type QueryGroupByDeceasedArgs = {
  by: Array<DeceasedScalarFieldEnum>;
  having?: InputMaybe<DeceasedScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<DeceasedOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeceasedWhereInput>;
};

export type QueryGroupByDeceasedContactsArgs = {
  by: Array<DeceasedContactsScalarFieldEnum>;
  having?: InputMaybe<DeceasedContactsScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<DeceasedContactsOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeceasedContactsWhereInput>;
};

export type QueryGroupByDeviceRegistrationArgs = {
  by: Array<DeviceRegistrationScalarFieldEnum>;
  having?: InputMaybe<DeviceRegistrationScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<DeviceRegistrationOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DeviceRegistrationWhereInput>;
};

export type QueryGroupByDocumentsProcessedArgs = {
  by: Array<DocumentsProcessedScalarFieldEnum>;
  having?: InputMaybe<DocumentsProcessedScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<DocumentsProcessedOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DocumentsProcessedWhereInput>;
};

export type QueryGroupByEulaArgs = {
  by: Array<EulaScalarFieldEnum>;
  having?: InputMaybe<EulaScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<EulaOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EulaWhereInput>;
};

export type QueryGroupByEulaAcceptanceHistoryArgs = {
  by: Array<EulaAcceptanceHistoryScalarFieldEnum>;
  having?: InputMaybe<EulaAcceptanceHistoryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<EulaAcceptanceHistoryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EulaAcceptanceHistoryWhereInput>;
};

export type QueryGroupByEstateArgs = {
  by: Array<EstateScalarFieldEnum>;
  having?: InputMaybe<EstateScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<EstateOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateWhereInput>;
};

export type QueryGroupByEstateDetailArgs = {
  by: Array<EstateDetailScalarFieldEnum>;
  having?: InputMaybe<EstateDetailScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<EstateDetailOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateDetailWhereInput>;
};

export type QueryGroupByEstateNotificationArgs = {
  by: Array<EstateNotificationScalarFieldEnum>;
  having?: InputMaybe<EstateNotificationScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<EstateNotificationOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EstateNotificationWhereInput>;
};

export type QueryGroupByExpenseArgs = {
  by: Array<ExpenseScalarFieldEnum>;
  having?: InputMaybe<ExpenseScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ExpenseOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ExpenseWhereInput>;
};

export type QueryGroupByLinkedAccountArgs = {
  by: Array<LinkedAccountScalarFieldEnum>;
  having?: InputMaybe<LinkedAccountScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<LinkedAccountOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LinkedAccountWhereInput>;
};

export type QueryGroupByMilestoneArgs = {
  by: Array<MilestoneScalarFieldEnum>;
  having?: InputMaybe<MilestoneScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<MilestoneOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MilestoneWhereInput>;
};

export type QueryGroupByTaskArgs = {
  by: Array<TaskScalarFieldEnum>;
  having?: InputMaybe<TaskScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TaskOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TaskWhereInput>;
};

export type QueryGroupByTransactionArgs = {
  by: Array<TransactionScalarFieldEnum>;
  having?: InputMaybe<TransactionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type QueryGroupByUserArgs = {
  by: Array<UserScalarFieldEnum>;
  having?: InputMaybe<UserScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryGroupByUserLoginArgs = {
  by: Array<UserLoginScalarFieldEnum>;
  having?: InputMaybe<UserLoginScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserLoginOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserLoginWhereInput>;
};

export type QueryGroupByUserNotificationArgs = {
  by: Array<UserNotificationScalarFieldEnum>;
  having?: InputMaybe<UserNotificationScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserNotificationOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserNotificationWhereInput>;
};

export type QueryGroupByUsersOnEstatesArgs = {
  by: Array<UsersOnEstatesScalarFieldEnum>;
  having?: InputMaybe<UsersOnEstatesScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UsersOnEstatesOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UsersOnEstatesWhereInput>;
};

export type QueryLinkedAccountArgs = {
  where: LinkedAccountWhereUniqueInput;
};

export type QueryLinkedAccountsArgs = {
  cursor?: InputMaybe<LinkedAccountWhereUniqueInput>;
  distinct?: InputMaybe<Array<LinkedAccountScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LinkedAccountOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LinkedAccountWhereInput>;
};

export type QueryListDeletedDocumentsArgs = {
  estateId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryListDocumentsArgs = {
  directory?: InputMaybe<Scalars["String"]["input"]>;
  estateId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryMilestoneArgs = {
  where: MilestoneWhereUniqueInput;
};

export type QueryMilestonesArgs = {
  cursor?: InputMaybe<MilestoneWhereUniqueInput>;
  distinct?: InputMaybe<Array<MilestoneScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MilestoneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MilestoneWhereInput>;
};

export type QueryTaskArgs = {
  where: TaskWhereUniqueInput;
};

export type QueryTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TaskWhereInput>;
};

export type QueryTransactionArgs = {
  where: TransactionWhereUniqueInput;
};

export type QueryTransactionsArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};

export type QueryUserLoginArgs = {
  where: UserLoginWhereUniqueInput;
};

export type QueryUserLoginsArgs = {
  cursor?: InputMaybe<UserLoginWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserLoginScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserLoginOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserLoginWhereInput>;
};

export type QueryUserNotificationArgs = {
  where: UserNotificationWhereUniqueInput;
};

export type QueryUserNotificationsArgs = {
  cursor?: InputMaybe<UserNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserNotificationWhereInput>;
};

export type QueryUsersArgs = {
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput2>>;
  where?: InputMaybe<UserWhereInput2>;
};

export enum QueryMode {
  Default = "default",
  Insensitive = "insensitive",
}

export enum RelatedElementType {
  ActionCard = "ActionCard",
  Content = "Content",
}

export type RouteElementInput = {
  id: Scalars["ID"]["input"];
  order: Scalars["Float"]["input"];
  type: RouteElementType2;
};

/** Entity type to which a Notification route will link */
export enum RouteElementType2 {
  ActionCard = "ACTION_CARD",
  EstateComponent = "ESTATE_COMPONENT",
  EstateContent = "ESTATE_CONTENT",
}

export type RoutingError = {
  __typename?: "RoutingError";
  message?: Maybe<Scalars["String"]["output"]>;
};

export type SetAlixFeeAmountInput = {
  amount: Scalars["Decimal"]["input"];
  estateId: Scalars["String"]["input"];
};

export type SettlementPlan = {
  __typename?: "SettlementPlan";
  estimatedCompletionDate?: Maybe<Scalars["DateTimeISO"]["output"]>;
  id: Scalars["ID"]["output"];
  items: Array<SettlementPlanItem>;
};

export type SettlementPlanItem = {
  __typename?: "SettlementPlanItem";
  count: Scalars["BigInt"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export enum Sex {
  Female = "FEMALE",
  Male = "MALE",
  Nonbinary = "NONBINARY",
}

/** Sex of a Contact or other entity representing a person */
export enum SexType2 {
  Female = "FEMALE",
  Male = "MALE",
  Nonbinary = "NONBINARY",
}

export enum Sfx {
  I = "I",
  Ii = "II",
  Iii = "III",
  Iv = "IV",
  Ix = "IX",
  Jr = "JR",
  Sr = "SR",
  V = "V",
  Vi = "VI",
  Vii = "VII",
  Viii = "VIII",
  X = "X",
}

/** Suffix of a Contact or other entity representing a person */
export enum SfxType2 {
  I = "I",
  Ii = "II",
  Iii = "III",
  Iv = "IV",
  Ix = "IX",
  Jr = "JR",
  Sr = "SR",
  V = "V",
  Vi = "VI",
  Vii = "VII",
  Viii = "VIII",
  X = "X",
}

export type SignUpUserInput = {
  channelPartner?: InputMaybe<Scalars["String"]["input"]>;
  deceasedDateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  deceasedDateOfDeath?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  deceasedFirstName?: InputMaybe<Scalars["String"]["input"]>;
  deceasedLastName?: InputMaybe<Scalars["String"]["input"]>;
  deceasedState?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
  userRole?: InputMaybe<UserEstateRole>;
};

export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["String"]["input"]>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars["String"]["input"]>>;
  has?: InputMaybe<Scalars["String"]["input"]>;
  hasEvery?: InputMaybe<Array<Scalars["String"]["input"]>>;
  hasSome?: InputMaybe<Array<Scalars["String"]["input"]>>;
  isEmpty?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type Task = {
  __typename?: "Task";
  _count?: Maybe<TaskCount>;
  actionCards: Array<ActionCard>;
  completeByDateEnd?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completeByDateStart?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completeByName?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  estate: Estate;
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  important: Scalars["Boolean"]["output"];
  level: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  onCompleteMessage?: Maybe<Scalars["String"]["output"]>;
  order: Scalars["Int"]["output"];
  parentTask?: Maybe<Task>;
  parentTaskId?: Maybe<Scalars["String"]["output"]>;
  shortName?: Maybe<Scalars["String"]["output"]>;
  status: TaskStatus;
  subTasks: Array<Task>;
  taskId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  workflowTaskId?: Maybe<Scalars["String"]["output"]>;
};

export type TaskActionCardsArgs = {
  cursor?: InputMaybe<ActionCardWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActionCardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActionCardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActionCardWhereInput>;
};

export type TaskParentTaskArgs = {
  where?: InputMaybe<TaskWhereInput>;
};

export type TaskSubTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TaskWhereInput>;
};

export type Task2 = {
  __typename?: "Task2";
  completeByDateEnd?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completeByDateStart?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completeByName?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  important: Scalars["Boolean"]["output"];
  level: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  onCompleteMessage?: Maybe<Scalars["String"]["output"]>;
  order: Scalars["Int"]["output"];
  parentTaskId?: Maybe<Scalars["String"]["output"]>;
  shortName?: Maybe<Scalars["String"]["output"]>;
  status: TaskStatus;
  subTasks?: Maybe<Array<Task2>>;
  taskId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  workflowTaskId?: Maybe<Scalars["String"]["output"]>;
};

export type TaskAvgAggregate = {
  __typename?: "TaskAvgAggregate";
  level?: Maybe<Scalars["Float"]["output"]>;
  order?: Maybe<Scalars["Float"]["output"]>;
};

export type TaskAvgOrderByAggregateInput = {
  level?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
};

export type TaskCount = {
  __typename?: "TaskCount";
  actionCards: Scalars["Int"]["output"];
  subTasks: Scalars["Int"]["output"];
};

export type TaskCountActionCardsArgs = {
  where?: InputMaybe<ActionCardWhereInput>;
};

export type TaskCountSubTasksArgs = {
  where?: InputMaybe<TaskWhereInput>;
};

export type TaskCountAggregate = {
  __typename?: "TaskCountAggregate";
  _all: Scalars["Int"]["output"];
  completeByDateEnd: Scalars["Int"]["output"];
  completeByDateStart: Scalars["Int"]["output"];
  completeByName: Scalars["Int"]["output"];
  completedAt: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  description: Scalars["Int"]["output"];
  estateId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  important: Scalars["Int"]["output"];
  level: Scalars["Int"]["output"];
  name: Scalars["Int"]["output"];
  onCompleteMessage: Scalars["Int"]["output"];
  order: Scalars["Int"]["output"];
  parentTaskId: Scalars["Int"]["output"];
  shortName: Scalars["Int"]["output"];
  status: Scalars["Int"]["output"];
  taskId: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  workflowTaskId: Scalars["Int"]["output"];
};

export type TaskCountOrderByAggregateInput = {
  completeByDateEnd?: InputMaybe<SortOrder>;
  completeByDateStart?: InputMaybe<SortOrder>;
  completeByName?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  important?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  onCompleteMessage?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  parentTaskId?: InputMaybe<SortOrder>;
  shortName?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  taskId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  workflowTaskId?: InputMaybe<SortOrder>;
};

export type TaskCreateInput = {
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutTaskInput>;
  completeByDateEnd?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completeByDateStart?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completeByName?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  estate: EstateCreateNestedOneWithoutTasksInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  important?: InputMaybe<Scalars["Boolean"]["input"]>;
  level?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  onCompleteMessage?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  parentTask?: InputMaybe<TaskCreateNestedOneWithoutSubTasksInput>;
  shortName?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<TaskStatus>;
  subTasks?: InputMaybe<TaskCreateNestedManyWithoutParentTaskInput>;
  taskId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  workflowTaskId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TaskCreateManyEstateInput = {
  completeByDateEnd?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completeByDateStart?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completeByName?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  important?: InputMaybe<Scalars["Boolean"]["input"]>;
  level?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  onCompleteMessage?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  parentTaskId?: InputMaybe<Scalars["String"]["input"]>;
  shortName?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<TaskStatus>;
  taskId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  workflowTaskId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TaskCreateManyEstateInputEnvelope = {
  data: Array<TaskCreateManyEstateInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TaskCreateManyInput = {
  completeByDateEnd?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completeByDateStart?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completeByName?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  estateId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  important?: InputMaybe<Scalars["Boolean"]["input"]>;
  level?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  onCompleteMessage?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  parentTaskId?: InputMaybe<Scalars["String"]["input"]>;
  shortName?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<TaskStatus>;
  taskId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  workflowTaskId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TaskCreateManyParentTaskInput = {
  completeByDateEnd?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completeByDateStart?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completeByName?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  estateId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  important?: InputMaybe<Scalars["Boolean"]["input"]>;
  level?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  onCompleteMessage?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  shortName?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<TaskStatus>;
  taskId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  workflowTaskId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TaskCreateManyParentTaskInputEnvelope = {
  data: Array<TaskCreateManyParentTaskInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TaskCreateNestedManyWithoutEstateInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutEstateInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutEstateInput>>;
  createMany?: InputMaybe<TaskCreateManyEstateInputEnvelope>;
};

export type TaskCreateNestedManyWithoutParentTaskInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TaskCreateOrConnectWithoutParentTaskInput>
  >;
  create?: InputMaybe<Array<TaskCreateWithoutParentTaskInput>>;
  createMany?: InputMaybe<TaskCreateManyParentTaskInputEnvelope>;
};

export type TaskCreateNestedOneWithoutActionCardsInput = {
  connect?: InputMaybe<TaskWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaskCreateOrConnectWithoutActionCardsInput>;
  create?: InputMaybe<TaskCreateWithoutActionCardsInput>;
};

export type TaskCreateNestedOneWithoutSubTasksInput = {
  connect?: InputMaybe<TaskWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaskCreateOrConnectWithoutSubTasksInput>;
  create?: InputMaybe<TaskCreateWithoutSubTasksInput>;
};

export type TaskCreateOrConnectWithoutActionCardsInput = {
  create: TaskCreateWithoutActionCardsInput;
  where: TaskWhereUniqueInput;
};

export type TaskCreateOrConnectWithoutEstateInput = {
  create: TaskCreateWithoutEstateInput;
  where: TaskWhereUniqueInput;
};

export type TaskCreateOrConnectWithoutParentTaskInput = {
  create: TaskCreateWithoutParentTaskInput;
  where: TaskWhereUniqueInput;
};

export type TaskCreateOrConnectWithoutSubTasksInput = {
  create: TaskCreateWithoutSubTasksInput;
  where: TaskWhereUniqueInput;
};

export type TaskCreateWithoutActionCardsInput = {
  completeByDateEnd?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completeByDateStart?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completeByName?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  estate: EstateCreateNestedOneWithoutTasksInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  important?: InputMaybe<Scalars["Boolean"]["input"]>;
  level?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  onCompleteMessage?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  parentTask?: InputMaybe<TaskCreateNestedOneWithoutSubTasksInput>;
  shortName?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<TaskStatus>;
  subTasks?: InputMaybe<TaskCreateNestedManyWithoutParentTaskInput>;
  taskId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  workflowTaskId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TaskCreateWithoutEstateInput = {
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutTaskInput>;
  completeByDateEnd?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completeByDateStart?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completeByName?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  important?: InputMaybe<Scalars["Boolean"]["input"]>;
  level?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  onCompleteMessage?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  parentTask?: InputMaybe<TaskCreateNestedOneWithoutSubTasksInput>;
  shortName?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<TaskStatus>;
  subTasks?: InputMaybe<TaskCreateNestedManyWithoutParentTaskInput>;
  taskId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  workflowTaskId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TaskCreateWithoutParentTaskInput = {
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutTaskInput>;
  completeByDateEnd?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completeByDateStart?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completeByName?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  estate: EstateCreateNestedOneWithoutTasksInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  important?: InputMaybe<Scalars["Boolean"]["input"]>;
  level?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  onCompleteMessage?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  shortName?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<TaskStatus>;
  subTasks?: InputMaybe<TaskCreateNestedManyWithoutParentTaskInput>;
  taskId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  workflowTaskId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TaskCreateWithoutSubTasksInput = {
  actionCards?: InputMaybe<ActionCardCreateNestedManyWithoutTaskInput>;
  completeByDateEnd?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completeByDateStart?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  completeByName?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  estate: EstateCreateNestedOneWithoutTasksInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  important?: InputMaybe<Scalars["Boolean"]["input"]>;
  level?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  onCompleteMessage?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  parentTask?: InputMaybe<TaskCreateNestedOneWithoutSubTasksInput>;
  shortName?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<TaskStatus>;
  taskId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  workflowTaskId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TaskEstateIdTaskIdCompoundUniqueInput = {
  estateId: Scalars["String"]["input"];
  taskId: Scalars["String"]["input"];
};

export type TaskGroupBy = {
  __typename?: "TaskGroupBy";
  _avg?: Maybe<TaskAvgAggregate>;
  _count?: Maybe<TaskCountAggregate>;
  _max?: Maybe<TaskMaxAggregate>;
  _min?: Maybe<TaskMinAggregate>;
  _sum?: Maybe<TaskSumAggregate>;
  completeByDateEnd?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completeByDateStart?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completeByName?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  estateId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  important: Scalars["Boolean"]["output"];
  level: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  onCompleteMessage?: Maybe<Scalars["String"]["output"]>;
  order: Scalars["Int"]["output"];
  parentTaskId?: Maybe<Scalars["String"]["output"]>;
  shortName?: Maybe<Scalars["String"]["output"]>;
  status: TaskStatus;
  taskId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  workflowTaskId?: Maybe<Scalars["String"]["output"]>;
};

export type TaskListRelationFilter = {
  every?: InputMaybe<TaskWhereInput>;
  none?: InputMaybe<TaskWhereInput>;
  some?: InputMaybe<TaskWhereInput>;
};

export type TaskMaxAggregate = {
  __typename?: "TaskMaxAggregate";
  completeByDateEnd?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completeByDateStart?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completeByName?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  important?: Maybe<Scalars["Boolean"]["output"]>;
  level?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  onCompleteMessage?: Maybe<Scalars["String"]["output"]>;
  order?: Maybe<Scalars["Int"]["output"]>;
  parentTaskId?: Maybe<Scalars["String"]["output"]>;
  shortName?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<TaskStatus>;
  taskId?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  workflowTaskId?: Maybe<Scalars["String"]["output"]>;
};

export type TaskMaxOrderByAggregateInput = {
  completeByDateEnd?: InputMaybe<SortOrder>;
  completeByDateStart?: InputMaybe<SortOrder>;
  completeByName?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  important?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  onCompleteMessage?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  parentTaskId?: InputMaybe<SortOrder>;
  shortName?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  taskId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  workflowTaskId?: InputMaybe<SortOrder>;
};

export type TaskMinAggregate = {
  __typename?: "TaskMinAggregate";
  completeByDateEnd?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completeByDateStart?: Maybe<Scalars["DateTimeISO"]["output"]>;
  completeByName?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  important?: Maybe<Scalars["Boolean"]["output"]>;
  level?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  onCompleteMessage?: Maybe<Scalars["String"]["output"]>;
  order?: Maybe<Scalars["Int"]["output"]>;
  parentTaskId?: Maybe<Scalars["String"]["output"]>;
  shortName?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<TaskStatus>;
  taskId?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  workflowTaskId?: Maybe<Scalars["String"]["output"]>;
};

export type TaskMinOrderByAggregateInput = {
  completeByDateEnd?: InputMaybe<SortOrder>;
  completeByDateStart?: InputMaybe<SortOrder>;
  completeByName?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  important?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  onCompleteMessage?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  parentTaskId?: InputMaybe<SortOrder>;
  shortName?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  taskId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  workflowTaskId?: InputMaybe<SortOrder>;
};

export type TaskNullableRelationFilter = {
  is?: InputMaybe<TaskWhereInput>;
  isNot?: InputMaybe<TaskWhereInput>;
};

export type TaskOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TaskOrderByWithAggregationInput = {
  _avg?: InputMaybe<TaskAvgOrderByAggregateInput>;
  _count?: InputMaybe<TaskCountOrderByAggregateInput>;
  _max?: InputMaybe<TaskMaxOrderByAggregateInput>;
  _min?: InputMaybe<TaskMinOrderByAggregateInput>;
  _sum?: InputMaybe<TaskSumOrderByAggregateInput>;
  completeByDateEnd?: InputMaybe<SortOrderInput>;
  completeByDateStart?: InputMaybe<SortOrderInput>;
  completeByName?: InputMaybe<SortOrderInput>;
  completedAt?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  important?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  onCompleteMessage?: InputMaybe<SortOrderInput>;
  order?: InputMaybe<SortOrder>;
  parentTaskId?: InputMaybe<SortOrderInput>;
  shortName?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  taskId?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  workflowTaskId?: InputMaybe<SortOrderInput>;
};

export type TaskOrderByWithRelationInput = {
  actionCards?: InputMaybe<ActionCardOrderByRelationAggregateInput>;
  completeByDateEnd?: InputMaybe<SortOrderInput>;
  completeByDateStart?: InputMaybe<SortOrderInput>;
  completeByName?: InputMaybe<SortOrderInput>;
  completedAt?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  estate?: InputMaybe<EstateOrderByWithRelationInput>;
  estateId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  important?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  onCompleteMessage?: InputMaybe<SortOrderInput>;
  order?: InputMaybe<SortOrder>;
  parentTask?: InputMaybe<TaskOrderByWithRelationInput>;
  parentTaskId?: InputMaybe<SortOrderInput>;
  shortName?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  subTasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  taskId?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  workflowTaskId?: InputMaybe<SortOrderInput>;
};

export enum TaskScalarFieldEnum {
  CompleteByDateEnd = "completeByDateEnd",
  CompleteByDateStart = "completeByDateStart",
  CompleteByName = "completeByName",
  CompletedAt = "completedAt",
  CreatedAt = "createdAt",
  Description = "description",
  EstateId = "estateId",
  Id = "id",
  Important = "important",
  Level = "level",
  Name = "name",
  OnCompleteMessage = "onCompleteMessage",
  Order = "order",
  ParentTaskId = "parentTaskId",
  ShortName = "shortName",
  Status = "status",
  TaskId = "taskId",
  UpdatedAt = "updatedAt",
  WorkflowTaskId = "workflowTaskId",
}

export type TaskScalarWhereInput = {
  AND?: InputMaybe<Array<TaskScalarWhereInput>>;
  NOT?: InputMaybe<Array<TaskScalarWhereInput>>;
  OR?: InputMaybe<Array<TaskScalarWhereInput>>;
  completeByDateEnd?: InputMaybe<DateTimeNullableFilter>;
  completeByDateStart?: InputMaybe<DateTimeNullableFilter>;
  completeByName?: InputMaybe<StringNullableFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  important?: InputMaybe<BoolFilter>;
  level?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  onCompleteMessage?: InputMaybe<StringNullableFilter>;
  order?: InputMaybe<IntFilter>;
  parentTaskId?: InputMaybe<StringNullableFilter>;
  shortName?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumTaskStatusFilter>;
  taskId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workflowTaskId?: InputMaybe<StringNullableFilter>;
};

export type TaskScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TaskScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TaskScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TaskScalarWhereWithAggregatesInput>>;
  completeByDateEnd?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  completeByDateStart?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  completeByName?: InputMaybe<StringNullableWithAggregatesFilter>;
  completedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  estateId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  important?: InputMaybe<BoolWithAggregatesFilter>;
  level?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  onCompleteMessage?: InputMaybe<StringNullableWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  parentTaskId?: InputMaybe<StringNullableWithAggregatesFilter>;
  shortName?: InputMaybe<StringNullableWithAggregatesFilter>;
  status?: InputMaybe<EnumTaskStatusWithAggregatesFilter>;
  taskId?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  workflowTaskId?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export enum TaskStatus {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  Draft = "DRAFT",
  InProgress = "IN_PROGRESS",
  NotReady = "NOT_READY",
  Paused = "PAUSED",
  Ready = "READY",
}

export type TaskSumAggregate = {
  __typename?: "TaskSumAggregate";
  level?: Maybe<Scalars["Int"]["output"]>;
  order?: Maybe<Scalars["Int"]["output"]>;
};

export type TaskSumOrderByAggregateInput = {
  level?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
};

export type TaskUpdateInput = {
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutTaskNestedInput>;
  completeByDateEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completeByDateStart?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completeByName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutTasksNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  important?: InputMaybe<BoolFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onCompleteMessage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  parentTask?: InputMaybe<TaskUpdateOneWithoutSubTasksNestedInput>;
  shortName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTaskStatusFieldUpdateOperationsInput>;
  subTasks?: InputMaybe<TaskUpdateManyWithoutParentTaskNestedInput>;
  taskId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workflowTaskId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type TaskUpdateManyMutationInput = {
  completeByDateEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completeByDateStart?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completeByName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  important?: InputMaybe<BoolFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onCompleteMessage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shortName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTaskStatusFieldUpdateOperationsInput>;
  taskId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workflowTaskId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type TaskUpdateManyWithWhereWithoutEstateInput = {
  data: TaskUpdateManyMutationInput;
  where: TaskScalarWhereInput;
};

export type TaskUpdateManyWithWhereWithoutParentTaskInput = {
  data: TaskUpdateManyMutationInput;
  where: TaskScalarWhereInput;
};

export type TaskUpdateManyWithoutEstateNestedInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutEstateInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutEstateInput>>;
  createMany?: InputMaybe<TaskCreateManyEstateInputEnvelope>;
  delete?: InputMaybe<Array<TaskWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskUpdateWithWhereUniqueWithoutEstateInput>>;
  updateMany?: InputMaybe<Array<TaskUpdateManyWithWhereWithoutEstateInput>>;
  upsert?: InputMaybe<Array<TaskUpsertWithWhereUniqueWithoutEstateInput>>;
};

export type TaskUpdateManyWithoutParentTaskNestedInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TaskCreateOrConnectWithoutParentTaskInput>
  >;
  create?: InputMaybe<Array<TaskCreateWithoutParentTaskInput>>;
  createMany?: InputMaybe<TaskCreateManyParentTaskInputEnvelope>;
  delete?: InputMaybe<Array<TaskWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskUpdateWithWhereUniqueWithoutParentTaskInput>>;
  updateMany?: InputMaybe<Array<TaskUpdateManyWithWhereWithoutParentTaskInput>>;
  upsert?: InputMaybe<Array<TaskUpsertWithWhereUniqueWithoutParentTaskInput>>;
};

export type TaskUpdateOneWithoutActionCardsNestedInput = {
  connect?: InputMaybe<TaskWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaskCreateOrConnectWithoutActionCardsInput>;
  create?: InputMaybe<TaskCreateWithoutActionCardsInput>;
  delete?: InputMaybe<TaskWhereInput>;
  disconnect?: InputMaybe<TaskWhereInput>;
  update?: InputMaybe<TaskUpdateToOneWithWhereWithoutActionCardsInput>;
  upsert?: InputMaybe<TaskUpsertWithoutActionCardsInput>;
};

export type TaskUpdateOneWithoutSubTasksNestedInput = {
  connect?: InputMaybe<TaskWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaskCreateOrConnectWithoutSubTasksInput>;
  create?: InputMaybe<TaskCreateWithoutSubTasksInput>;
  delete?: InputMaybe<TaskWhereInput>;
  disconnect?: InputMaybe<TaskWhereInput>;
  update?: InputMaybe<TaskUpdateToOneWithWhereWithoutSubTasksInput>;
  upsert?: InputMaybe<TaskUpsertWithoutSubTasksInput>;
};

export type TaskUpdateToOneWithWhereWithoutActionCardsInput = {
  data: TaskUpdateWithoutActionCardsInput;
  where?: InputMaybe<TaskWhereInput>;
};

export type TaskUpdateToOneWithWhereWithoutSubTasksInput = {
  data: TaskUpdateWithoutSubTasksInput;
  where?: InputMaybe<TaskWhereInput>;
};

export type TaskUpdateWithWhereUniqueWithoutEstateInput = {
  data: TaskUpdateWithoutEstateInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpdateWithWhereUniqueWithoutParentTaskInput = {
  data: TaskUpdateWithoutParentTaskInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpdateWithoutActionCardsInput = {
  completeByDateEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completeByDateStart?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completeByName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutTasksNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  important?: InputMaybe<BoolFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onCompleteMessage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  parentTask?: InputMaybe<TaskUpdateOneWithoutSubTasksNestedInput>;
  shortName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTaskStatusFieldUpdateOperationsInput>;
  subTasks?: InputMaybe<TaskUpdateManyWithoutParentTaskNestedInput>;
  taskId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workflowTaskId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type TaskUpdateWithoutEstateInput = {
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutTaskNestedInput>;
  completeByDateEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completeByDateStart?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completeByName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  important?: InputMaybe<BoolFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onCompleteMessage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  parentTask?: InputMaybe<TaskUpdateOneWithoutSubTasksNestedInput>;
  shortName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTaskStatusFieldUpdateOperationsInput>;
  subTasks?: InputMaybe<TaskUpdateManyWithoutParentTaskNestedInput>;
  taskId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workflowTaskId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type TaskUpdateWithoutParentTaskInput = {
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutTaskNestedInput>;
  completeByDateEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completeByDateStart?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completeByName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutTasksNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  important?: InputMaybe<BoolFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onCompleteMessage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shortName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTaskStatusFieldUpdateOperationsInput>;
  subTasks?: InputMaybe<TaskUpdateManyWithoutParentTaskNestedInput>;
  taskId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workflowTaskId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type TaskUpdateWithoutSubTasksInput = {
  actionCards?: InputMaybe<ActionCardUpdateManyWithoutTaskNestedInput>;
  completeByDateEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completeByDateStart?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  completeByName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutTasksNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  important?: InputMaybe<BoolFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onCompleteMessage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  parentTask?: InputMaybe<TaskUpdateOneWithoutSubTasksNestedInput>;
  shortName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTaskStatusFieldUpdateOperationsInput>;
  taskId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workflowTaskId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type TaskUpsertWithWhereUniqueWithoutEstateInput = {
  create: TaskCreateWithoutEstateInput;
  update: TaskUpdateWithoutEstateInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpsertWithWhereUniqueWithoutParentTaskInput = {
  create: TaskCreateWithoutParentTaskInput;
  update: TaskUpdateWithoutParentTaskInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpsertWithoutActionCardsInput = {
  create: TaskCreateWithoutActionCardsInput;
  update: TaskUpdateWithoutActionCardsInput;
  where?: InputMaybe<TaskWhereInput>;
};

export type TaskUpsertWithoutSubTasksInput = {
  create: TaskCreateWithoutSubTasksInput;
  update: TaskUpdateWithoutSubTasksInput;
  where?: InputMaybe<TaskWhereInput>;
};

export type TaskWhereInput = {
  AND?: InputMaybe<Array<TaskWhereInput>>;
  NOT?: InputMaybe<Array<TaskWhereInput>>;
  OR?: InputMaybe<Array<TaskWhereInput>>;
  actionCards?: InputMaybe<ActionCardListRelationFilter>;
  completeByDateEnd?: InputMaybe<DateTimeNullableFilter>;
  completeByDateStart?: InputMaybe<DateTimeNullableFilter>;
  completeByName?: InputMaybe<StringNullableFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  important?: InputMaybe<BoolFilter>;
  level?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  onCompleteMessage?: InputMaybe<StringNullableFilter>;
  order?: InputMaybe<IntFilter>;
  parentTask?: InputMaybe<TaskNullableRelationFilter>;
  parentTaskId?: InputMaybe<StringNullableFilter>;
  shortName?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumTaskStatusFilter>;
  subTasks?: InputMaybe<TaskListRelationFilter>;
  taskId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workflowTaskId?: InputMaybe<StringNullableFilter>;
};

export type TaskWhereUniqueInput = {
  AND?: InputMaybe<Array<TaskWhereInput>>;
  NOT?: InputMaybe<Array<TaskWhereInput>>;
  OR?: InputMaybe<Array<TaskWhereInput>>;
  actionCards?: InputMaybe<ActionCardListRelationFilter>;
  completeByDateEnd?: InputMaybe<DateTimeNullableFilter>;
  completeByDateStart?: InputMaybe<DateTimeNullableFilter>;
  completeByName?: InputMaybe<StringNullableFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  estateId_taskId?: InputMaybe<TaskEstateIdTaskIdCompoundUniqueInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  important?: InputMaybe<BoolFilter>;
  level?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  onCompleteMessage?: InputMaybe<StringNullableFilter>;
  order?: InputMaybe<IntFilter>;
  parentTask?: InputMaybe<TaskNullableRelationFilter>;
  parentTaskId?: InputMaybe<StringNullableFilter>;
  shortName?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumTaskStatusFilter>;
  subTasks?: InputMaybe<TaskListRelationFilter>;
  taskId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workflowTaskId?: InputMaybe<StringNullableFilter>;
};

export type TeamMember = {
  __typename?: "TeamMember";
  email: Scalars["String"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  type: UserType;
};

export type Transaction = {
  __typename?: "Transaction";
  accountId: Scalars["String"]["output"];
  accountOwner?: Maybe<Scalars["String"]["output"]>;
  amount: Scalars["Decimal"]["output"];
  authorizedDate?: Maybe<Scalars["DateTimeISO"]["output"]>;
  authorizedDatetime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  category: Array<Scalars["String"]["output"]>;
  categoryId?: Maybe<Scalars["String"]["output"]>;
  checkNumber?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  date: Scalars["DateTimeISO"]["output"];
  dateTime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  id: Scalars["String"]["output"];
  isoCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  linkedAccount: LinkedAccount;
  linkedAccountId: Scalars["String"]["output"];
  location?: Maybe<Scalars["JSON"]["output"]>;
  merchantName?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  originalDescription?: Maybe<Scalars["String"]["output"]>;
  paymentChannel?: Maybe<Scalars["String"]["output"]>;
  paymentMeta?: Maybe<Scalars["JSON"]["output"]>;
  pending: Scalars["Boolean"]["output"];
  pendingTransactionId?: Maybe<Scalars["String"]["output"]>;
  personalFinanceCategory?: Maybe<Scalars["JSON"]["output"]>;
  transactionCode?: Maybe<Scalars["String"]["output"]>;
  transactionId: Scalars["String"]["output"];
  transactionType?: Maybe<Scalars["String"]["output"]>;
  unofficialCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type TransactionAvgAggregate = {
  __typename?: "TransactionAvgAggregate";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
};

export type TransactionAvgOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
};

export type TransactionCountAggregate = {
  __typename?: "TransactionCountAggregate";
  _all: Scalars["Int"]["output"];
  accountId: Scalars["Int"]["output"];
  accountOwner: Scalars["Int"]["output"];
  amount: Scalars["Int"]["output"];
  authorizedDate: Scalars["Int"]["output"];
  authorizedDatetime: Scalars["Int"]["output"];
  category: Scalars["Int"]["output"];
  categoryId: Scalars["Int"]["output"];
  checkNumber: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  date: Scalars["Int"]["output"];
  dateTime: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  isoCurrencyCode: Scalars["Int"]["output"];
  linkedAccountId: Scalars["Int"]["output"];
  location: Scalars["Int"]["output"];
  merchantName: Scalars["Int"]["output"];
  name: Scalars["Int"]["output"];
  originalDescription: Scalars["Int"]["output"];
  paymentChannel: Scalars["Int"]["output"];
  paymentMeta: Scalars["Int"]["output"];
  pending: Scalars["Int"]["output"];
  pendingTransactionId: Scalars["Int"]["output"];
  personalFinanceCategory: Scalars["Int"]["output"];
  transactionCode: Scalars["Int"]["output"];
  transactionId: Scalars["Int"]["output"];
  transactionType: Scalars["Int"]["output"];
  unofficialCurrencyCode: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type TransactionCountOrderByAggregateInput = {
  accountId?: InputMaybe<SortOrder>;
  accountOwner?: InputMaybe<SortOrder>;
  amount?: InputMaybe<SortOrder>;
  authorizedDate?: InputMaybe<SortOrder>;
  authorizedDatetime?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  checkNumber?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  dateTime?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isoCurrencyCode?: InputMaybe<SortOrder>;
  linkedAccountId?: InputMaybe<SortOrder>;
  location?: InputMaybe<SortOrder>;
  merchantName?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  originalDescription?: InputMaybe<SortOrder>;
  paymentChannel?: InputMaybe<SortOrder>;
  paymentMeta?: InputMaybe<SortOrder>;
  pending?: InputMaybe<SortOrder>;
  pendingTransactionId?: InputMaybe<SortOrder>;
  personalFinanceCategory?: InputMaybe<SortOrder>;
  transactionCode?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  transactionType?: InputMaybe<SortOrder>;
  unofficialCurrencyCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TransactionCreateInput = {
  accountId: Scalars["String"]["input"];
  accountOwner?: InputMaybe<Scalars["String"]["input"]>;
  amount: Scalars["Decimal"]["input"];
  authorizedDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  authorizedDatetime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  category?: InputMaybe<TransactionCreatecategoryInput>;
  categoryId?: InputMaybe<Scalars["String"]["input"]>;
  checkNumber?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date: Scalars["DateTimeISO"]["input"];
  dateTime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isoCurrencyCode?: InputMaybe<Scalars["String"]["input"]>;
  linkedAccount: LinkedAccountCreateNestedOneWithoutTransactionsInput;
  location?: InputMaybe<Scalars["JSON"]["input"]>;
  merchantName?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  originalDescription?: InputMaybe<Scalars["String"]["input"]>;
  paymentChannel?: InputMaybe<Scalars["String"]["input"]>;
  paymentMeta?: InputMaybe<Scalars["JSON"]["input"]>;
  pending?: InputMaybe<Scalars["Boolean"]["input"]>;
  pendingTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  personalFinanceCategory?: InputMaybe<Scalars["JSON"]["input"]>;
  transactionCode?: InputMaybe<Scalars["String"]["input"]>;
  transactionId: Scalars["String"]["input"];
  transactionType?: InputMaybe<Scalars["String"]["input"]>;
  unofficialCurrencyCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type TransactionCreateManyInput = {
  accountId: Scalars["String"]["input"];
  accountOwner?: InputMaybe<Scalars["String"]["input"]>;
  amount: Scalars["Decimal"]["input"];
  authorizedDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  authorizedDatetime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  category?: InputMaybe<TransactionCreatecategoryInput>;
  categoryId?: InputMaybe<Scalars["String"]["input"]>;
  checkNumber?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date: Scalars["DateTimeISO"]["input"];
  dateTime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isoCurrencyCode?: InputMaybe<Scalars["String"]["input"]>;
  linkedAccountId: Scalars["String"]["input"];
  location?: InputMaybe<Scalars["JSON"]["input"]>;
  merchantName?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  originalDescription?: InputMaybe<Scalars["String"]["input"]>;
  paymentChannel?: InputMaybe<Scalars["String"]["input"]>;
  paymentMeta?: InputMaybe<Scalars["JSON"]["input"]>;
  pending?: InputMaybe<Scalars["Boolean"]["input"]>;
  pendingTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  personalFinanceCategory?: InputMaybe<Scalars["JSON"]["input"]>;
  transactionCode?: InputMaybe<Scalars["String"]["input"]>;
  transactionId: Scalars["String"]["input"];
  transactionType?: InputMaybe<Scalars["String"]["input"]>;
  unofficialCurrencyCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type TransactionCreateManyLinkedAccountInput = {
  accountId: Scalars["String"]["input"];
  accountOwner?: InputMaybe<Scalars["String"]["input"]>;
  amount: Scalars["Decimal"]["input"];
  authorizedDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  authorizedDatetime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  category?: InputMaybe<TransactionCreatecategoryInput>;
  categoryId?: InputMaybe<Scalars["String"]["input"]>;
  checkNumber?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date: Scalars["DateTimeISO"]["input"];
  dateTime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isoCurrencyCode?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["JSON"]["input"]>;
  merchantName?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  originalDescription?: InputMaybe<Scalars["String"]["input"]>;
  paymentChannel?: InputMaybe<Scalars["String"]["input"]>;
  paymentMeta?: InputMaybe<Scalars["JSON"]["input"]>;
  pending?: InputMaybe<Scalars["Boolean"]["input"]>;
  pendingTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  personalFinanceCategory?: InputMaybe<Scalars["JSON"]["input"]>;
  transactionCode?: InputMaybe<Scalars["String"]["input"]>;
  transactionId: Scalars["String"]["input"];
  transactionType?: InputMaybe<Scalars["String"]["input"]>;
  unofficialCurrencyCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type TransactionCreateManyLinkedAccountInputEnvelope = {
  data: Array<TransactionCreateManyLinkedAccountInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TransactionCreateNestedManyWithoutLinkedAccountInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TransactionCreateOrConnectWithoutLinkedAccountInput>
  >;
  create?: InputMaybe<Array<TransactionCreateWithoutLinkedAccountInput>>;
  createMany?: InputMaybe<TransactionCreateManyLinkedAccountInputEnvelope>;
};

export type TransactionCreateOrConnectWithoutLinkedAccountInput = {
  create: TransactionCreateWithoutLinkedAccountInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateWithoutLinkedAccountInput = {
  accountId: Scalars["String"]["input"];
  accountOwner?: InputMaybe<Scalars["String"]["input"]>;
  amount: Scalars["Decimal"]["input"];
  authorizedDate?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  authorizedDatetime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  category?: InputMaybe<TransactionCreatecategoryInput>;
  categoryId?: InputMaybe<Scalars["String"]["input"]>;
  checkNumber?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  date: Scalars["DateTimeISO"]["input"];
  dateTime?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isoCurrencyCode?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["JSON"]["input"]>;
  merchantName?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  originalDescription?: InputMaybe<Scalars["String"]["input"]>;
  paymentChannel?: InputMaybe<Scalars["String"]["input"]>;
  paymentMeta?: InputMaybe<Scalars["JSON"]["input"]>;
  pending?: InputMaybe<Scalars["Boolean"]["input"]>;
  pendingTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  personalFinanceCategory?: InputMaybe<Scalars["JSON"]["input"]>;
  transactionCode?: InputMaybe<Scalars["String"]["input"]>;
  transactionId: Scalars["String"]["input"];
  transactionType?: InputMaybe<Scalars["String"]["input"]>;
  unofficialCurrencyCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type TransactionCreatecategoryInput = {
  set: Array<Scalars["String"]["input"]>;
};

export type TransactionGroupBy = {
  __typename?: "TransactionGroupBy";
  _avg?: Maybe<TransactionAvgAggregate>;
  _count?: Maybe<TransactionCountAggregate>;
  _max?: Maybe<TransactionMaxAggregate>;
  _min?: Maybe<TransactionMinAggregate>;
  _sum?: Maybe<TransactionSumAggregate>;
  accountId: Scalars["String"]["output"];
  accountOwner?: Maybe<Scalars["String"]["output"]>;
  amount: Scalars["Decimal"]["output"];
  authorizedDate?: Maybe<Scalars["DateTimeISO"]["output"]>;
  authorizedDatetime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  category?: Maybe<Array<Scalars["String"]["output"]>>;
  categoryId?: Maybe<Scalars["String"]["output"]>;
  checkNumber?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  date: Scalars["DateTimeISO"]["output"];
  dateTime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  id: Scalars["String"]["output"];
  isoCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  linkedAccountId: Scalars["String"]["output"];
  location?: Maybe<Scalars["JSON"]["output"]>;
  merchantName?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  originalDescription?: Maybe<Scalars["String"]["output"]>;
  paymentChannel?: Maybe<Scalars["String"]["output"]>;
  paymentMeta?: Maybe<Scalars["JSON"]["output"]>;
  pending: Scalars["Boolean"]["output"];
  pendingTransactionId?: Maybe<Scalars["String"]["output"]>;
  personalFinanceCategory?: Maybe<Scalars["JSON"]["output"]>;
  transactionCode?: Maybe<Scalars["String"]["output"]>;
  transactionId: Scalars["String"]["output"];
  transactionType?: Maybe<Scalars["String"]["output"]>;
  unofficialCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type TransactionListRelationFilter = {
  every?: InputMaybe<TransactionWhereInput>;
  none?: InputMaybe<TransactionWhereInput>;
  some?: InputMaybe<TransactionWhereInput>;
};

export type TransactionMaxAggregate = {
  __typename?: "TransactionMaxAggregate";
  accountId?: Maybe<Scalars["String"]["output"]>;
  accountOwner?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  authorizedDate?: Maybe<Scalars["DateTimeISO"]["output"]>;
  authorizedDatetime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  categoryId?: Maybe<Scalars["String"]["output"]>;
  checkNumber?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  date?: Maybe<Scalars["DateTimeISO"]["output"]>;
  dateTime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  isoCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  linkedAccountId?: Maybe<Scalars["String"]["output"]>;
  merchantName?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  originalDescription?: Maybe<Scalars["String"]["output"]>;
  paymentChannel?: Maybe<Scalars["String"]["output"]>;
  pending?: Maybe<Scalars["Boolean"]["output"]>;
  pendingTransactionId?: Maybe<Scalars["String"]["output"]>;
  transactionCode?: Maybe<Scalars["String"]["output"]>;
  transactionId?: Maybe<Scalars["String"]["output"]>;
  transactionType?: Maybe<Scalars["String"]["output"]>;
  unofficialCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type TransactionMaxOrderByAggregateInput = {
  accountId?: InputMaybe<SortOrder>;
  accountOwner?: InputMaybe<SortOrder>;
  amount?: InputMaybe<SortOrder>;
  authorizedDate?: InputMaybe<SortOrder>;
  authorizedDatetime?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  checkNumber?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  dateTime?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isoCurrencyCode?: InputMaybe<SortOrder>;
  linkedAccountId?: InputMaybe<SortOrder>;
  merchantName?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  originalDescription?: InputMaybe<SortOrder>;
  paymentChannel?: InputMaybe<SortOrder>;
  pending?: InputMaybe<SortOrder>;
  pendingTransactionId?: InputMaybe<SortOrder>;
  transactionCode?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  transactionType?: InputMaybe<SortOrder>;
  unofficialCurrencyCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TransactionMinAggregate = {
  __typename?: "TransactionMinAggregate";
  accountId?: Maybe<Scalars["String"]["output"]>;
  accountOwner?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  authorizedDate?: Maybe<Scalars["DateTimeISO"]["output"]>;
  authorizedDatetime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  categoryId?: Maybe<Scalars["String"]["output"]>;
  checkNumber?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  date?: Maybe<Scalars["DateTimeISO"]["output"]>;
  dateTime?: Maybe<Scalars["DateTimeISO"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  isoCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  linkedAccountId?: Maybe<Scalars["String"]["output"]>;
  merchantName?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  originalDescription?: Maybe<Scalars["String"]["output"]>;
  paymentChannel?: Maybe<Scalars["String"]["output"]>;
  pending?: Maybe<Scalars["Boolean"]["output"]>;
  pendingTransactionId?: Maybe<Scalars["String"]["output"]>;
  transactionCode?: Maybe<Scalars["String"]["output"]>;
  transactionId?: Maybe<Scalars["String"]["output"]>;
  transactionType?: Maybe<Scalars["String"]["output"]>;
  unofficialCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
};

export type TransactionMinOrderByAggregateInput = {
  accountId?: InputMaybe<SortOrder>;
  accountOwner?: InputMaybe<SortOrder>;
  amount?: InputMaybe<SortOrder>;
  authorizedDate?: InputMaybe<SortOrder>;
  authorizedDatetime?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  checkNumber?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  dateTime?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isoCurrencyCode?: InputMaybe<SortOrder>;
  linkedAccountId?: InputMaybe<SortOrder>;
  merchantName?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  originalDescription?: InputMaybe<SortOrder>;
  paymentChannel?: InputMaybe<SortOrder>;
  pending?: InputMaybe<SortOrder>;
  pendingTransactionId?: InputMaybe<SortOrder>;
  transactionCode?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  transactionType?: InputMaybe<SortOrder>;
  unofficialCurrencyCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TransactionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TransactionOrderByWithAggregationInput = {
  _avg?: InputMaybe<TransactionAvgOrderByAggregateInput>;
  _count?: InputMaybe<TransactionCountOrderByAggregateInput>;
  _max?: InputMaybe<TransactionMaxOrderByAggregateInput>;
  _min?: InputMaybe<TransactionMinOrderByAggregateInput>;
  _sum?: InputMaybe<TransactionSumOrderByAggregateInput>;
  accountId?: InputMaybe<SortOrder>;
  accountOwner?: InputMaybe<SortOrderInput>;
  amount?: InputMaybe<SortOrder>;
  authorizedDate?: InputMaybe<SortOrderInput>;
  authorizedDatetime?: InputMaybe<SortOrderInput>;
  category?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrderInput>;
  checkNumber?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  dateTime?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isoCurrencyCode?: InputMaybe<SortOrderInput>;
  linkedAccountId?: InputMaybe<SortOrder>;
  location?: InputMaybe<SortOrderInput>;
  merchantName?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  originalDescription?: InputMaybe<SortOrderInput>;
  paymentChannel?: InputMaybe<SortOrderInput>;
  paymentMeta?: InputMaybe<SortOrderInput>;
  pending?: InputMaybe<SortOrder>;
  pendingTransactionId?: InputMaybe<SortOrderInput>;
  personalFinanceCategory?: InputMaybe<SortOrderInput>;
  transactionCode?: InputMaybe<SortOrderInput>;
  transactionId?: InputMaybe<SortOrder>;
  transactionType?: InputMaybe<SortOrderInput>;
  unofficialCurrencyCode?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TransactionOrderByWithRelationInput = {
  accountId?: InputMaybe<SortOrder>;
  accountOwner?: InputMaybe<SortOrderInput>;
  amount?: InputMaybe<SortOrder>;
  authorizedDate?: InputMaybe<SortOrderInput>;
  authorizedDatetime?: InputMaybe<SortOrderInput>;
  category?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrderInput>;
  checkNumber?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  dateTime?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isoCurrencyCode?: InputMaybe<SortOrderInput>;
  linkedAccount?: InputMaybe<LinkedAccountOrderByWithRelationInput>;
  linkedAccountId?: InputMaybe<SortOrder>;
  location?: InputMaybe<SortOrderInput>;
  merchantName?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  originalDescription?: InputMaybe<SortOrderInput>;
  paymentChannel?: InputMaybe<SortOrderInput>;
  paymentMeta?: InputMaybe<SortOrderInput>;
  pending?: InputMaybe<SortOrder>;
  pendingTransactionId?: InputMaybe<SortOrderInput>;
  personalFinanceCategory?: InputMaybe<SortOrderInput>;
  transactionCode?: InputMaybe<SortOrderInput>;
  transactionId?: InputMaybe<SortOrder>;
  transactionType?: InputMaybe<SortOrderInput>;
  unofficialCurrencyCode?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum TransactionScalarFieldEnum {
  AccountId = "accountId",
  AccountOwner = "accountOwner",
  Amount = "amount",
  AuthorizedDate = "authorizedDate",
  AuthorizedDatetime = "authorizedDatetime",
  Category = "category",
  CategoryId = "categoryId",
  CheckNumber = "checkNumber",
  CreatedAt = "createdAt",
  Date = "date",
  DateTime = "dateTime",
  Id = "id",
  IsoCurrencyCode = "isoCurrencyCode",
  LinkedAccountId = "linkedAccountId",
  Location = "location",
  MerchantName = "merchantName",
  Name = "name",
  OriginalDescription = "originalDescription",
  PaymentChannel = "paymentChannel",
  PaymentMeta = "paymentMeta",
  Pending = "pending",
  PendingTransactionId = "pendingTransactionId",
  PersonalFinanceCategory = "personalFinanceCategory",
  TransactionCode = "transactionCode",
  TransactionId = "transactionId",
  TransactionType = "transactionType",
  UnofficialCurrencyCode = "unofficialCurrencyCode",
  UpdatedAt = "updatedAt",
}

export type TransactionScalarWhereInput = {
  AND?: InputMaybe<Array<TransactionScalarWhereInput>>;
  NOT?: InputMaybe<Array<TransactionScalarWhereInput>>;
  OR?: InputMaybe<Array<TransactionScalarWhereInput>>;
  accountId?: InputMaybe<StringFilter>;
  accountOwner?: InputMaybe<StringNullableFilter>;
  amount?: InputMaybe<DecimalFilter>;
  authorizedDate?: InputMaybe<DateTimeNullableFilter>;
  authorizedDatetime?: InputMaybe<DateTimeNullableFilter>;
  category?: InputMaybe<StringNullableListFilter>;
  categoryId?: InputMaybe<StringNullableFilter>;
  checkNumber?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  dateTime?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isoCurrencyCode?: InputMaybe<StringNullableFilter>;
  linkedAccountId?: InputMaybe<StringFilter>;
  location?: InputMaybe<JsonNullableFilter>;
  merchantName?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  originalDescription?: InputMaybe<StringNullableFilter>;
  paymentChannel?: InputMaybe<StringNullableFilter>;
  paymentMeta?: InputMaybe<JsonNullableFilter>;
  pending?: InputMaybe<BoolFilter>;
  pendingTransactionId?: InputMaybe<StringNullableFilter>;
  personalFinanceCategory?: InputMaybe<JsonNullableFilter>;
  transactionCode?: InputMaybe<StringNullableFilter>;
  transactionId?: InputMaybe<StringFilter>;
  transactionType?: InputMaybe<StringNullableFilter>;
  unofficialCurrencyCode?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TransactionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  accountId?: InputMaybe<StringWithAggregatesFilter>;
  accountOwner?: InputMaybe<StringNullableWithAggregatesFilter>;
  amount?: InputMaybe<DecimalWithAggregatesFilter>;
  authorizedDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  authorizedDatetime?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  category?: InputMaybe<StringNullableListFilter>;
  categoryId?: InputMaybe<StringNullableWithAggregatesFilter>;
  checkNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  date?: InputMaybe<DateTimeWithAggregatesFilter>;
  dateTime?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isoCurrencyCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  linkedAccountId?: InputMaybe<StringWithAggregatesFilter>;
  location?: InputMaybe<JsonNullableWithAggregatesFilter>;
  merchantName?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  originalDescription?: InputMaybe<StringNullableWithAggregatesFilter>;
  paymentChannel?: InputMaybe<StringNullableWithAggregatesFilter>;
  paymentMeta?: InputMaybe<JsonNullableWithAggregatesFilter>;
  pending?: InputMaybe<BoolWithAggregatesFilter>;
  pendingTransactionId?: InputMaybe<StringNullableWithAggregatesFilter>;
  personalFinanceCategory?: InputMaybe<JsonNullableWithAggregatesFilter>;
  transactionCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  transactionId?: InputMaybe<StringWithAggregatesFilter>;
  transactionType?: InputMaybe<StringNullableWithAggregatesFilter>;
  unofficialCurrencyCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type TransactionSumAggregate = {
  __typename?: "TransactionSumAggregate";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
};

export type TransactionSumOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
};

export type TransactionUpdateInput = {
  accountId?: InputMaybe<StringFieldUpdateOperationsInput>;
  accountOwner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  authorizedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  authorizedDatetime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  category?: InputMaybe<TransactionUpdatecategoryInput>;
  categoryId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  checkNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isoCurrencyCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkedAccount?: InputMaybe<LinkedAccountUpdateOneRequiredWithoutTransactionsNestedInput>;
  location?: InputMaybe<Scalars["JSON"]["input"]>;
  merchantName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentChannel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMeta?: InputMaybe<Scalars["JSON"]["input"]>;
  pending?: InputMaybe<BoolFieldUpdateOperationsInput>;
  pendingTransactionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  personalFinanceCategory?: InputMaybe<Scalars["JSON"]["input"]>;
  transactionCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transactionId?: InputMaybe<StringFieldUpdateOperationsInput>;
  transactionType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  unofficialCurrencyCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TransactionUpdateManyMutationInput = {
  accountId?: InputMaybe<StringFieldUpdateOperationsInput>;
  accountOwner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  authorizedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  authorizedDatetime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  category?: InputMaybe<TransactionUpdatecategoryInput>;
  categoryId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  checkNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isoCurrencyCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<Scalars["JSON"]["input"]>;
  merchantName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentChannel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMeta?: InputMaybe<Scalars["JSON"]["input"]>;
  pending?: InputMaybe<BoolFieldUpdateOperationsInput>;
  pendingTransactionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  personalFinanceCategory?: InputMaybe<Scalars["JSON"]["input"]>;
  transactionCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transactionId?: InputMaybe<StringFieldUpdateOperationsInput>;
  transactionType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  unofficialCurrencyCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TransactionUpdateManyWithWhereWithoutLinkedAccountInput = {
  data: TransactionUpdateManyMutationInput;
  where: TransactionScalarWhereInput;
};

export type TransactionUpdateManyWithoutLinkedAccountNestedInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TransactionCreateOrConnectWithoutLinkedAccountInput>
  >;
  create?: InputMaybe<Array<TransactionCreateWithoutLinkedAccountInput>>;
  createMany?: InputMaybe<TransactionCreateManyLinkedAccountInputEnvelope>;
  delete?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransactionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  set?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TransactionUpdateWithWhereUniqueWithoutLinkedAccountInput>
  >;
  updateMany?: InputMaybe<
    Array<TransactionUpdateManyWithWhereWithoutLinkedAccountInput>
  >;
  upsert?: InputMaybe<
    Array<TransactionUpsertWithWhereUniqueWithoutLinkedAccountInput>
  >;
};

export type TransactionUpdateWithWhereUniqueWithoutLinkedAccountInput = {
  data: TransactionUpdateWithoutLinkedAccountInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpdateWithoutLinkedAccountInput = {
  accountId?: InputMaybe<StringFieldUpdateOperationsInput>;
  accountOwner?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  authorizedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  authorizedDatetime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  category?: InputMaybe<TransactionUpdatecategoryInput>;
  categoryId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  checkNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isoCurrencyCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<Scalars["JSON"]["input"]>;
  merchantName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  originalDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentChannel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMeta?: InputMaybe<Scalars["JSON"]["input"]>;
  pending?: InputMaybe<BoolFieldUpdateOperationsInput>;
  pendingTransactionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  personalFinanceCategory?: InputMaybe<Scalars["JSON"]["input"]>;
  transactionCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transactionId?: InputMaybe<StringFieldUpdateOperationsInput>;
  transactionType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  unofficialCurrencyCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TransactionUpdatecategoryInput = {
  push?: InputMaybe<Array<Scalars["String"]["input"]>>;
  set?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type TransactionUpsertWithWhereUniqueWithoutLinkedAccountInput = {
  create: TransactionCreateWithoutLinkedAccountInput;
  update: TransactionUpdateWithoutLinkedAccountInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionWhereInput = {
  AND?: InputMaybe<Array<TransactionWhereInput>>;
  NOT?: InputMaybe<Array<TransactionWhereInput>>;
  OR?: InputMaybe<Array<TransactionWhereInput>>;
  accountId?: InputMaybe<StringFilter>;
  accountOwner?: InputMaybe<StringNullableFilter>;
  amount?: InputMaybe<DecimalFilter>;
  authorizedDate?: InputMaybe<DateTimeNullableFilter>;
  authorizedDatetime?: InputMaybe<DateTimeNullableFilter>;
  category?: InputMaybe<StringNullableListFilter>;
  categoryId?: InputMaybe<StringNullableFilter>;
  checkNumber?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  dateTime?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isoCurrencyCode?: InputMaybe<StringNullableFilter>;
  linkedAccount?: InputMaybe<LinkedAccountRelationFilter>;
  linkedAccountId?: InputMaybe<StringFilter>;
  location?: InputMaybe<JsonNullableFilter>;
  merchantName?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  originalDescription?: InputMaybe<StringNullableFilter>;
  paymentChannel?: InputMaybe<StringNullableFilter>;
  paymentMeta?: InputMaybe<JsonNullableFilter>;
  pending?: InputMaybe<BoolFilter>;
  pendingTransactionId?: InputMaybe<StringNullableFilter>;
  personalFinanceCategory?: InputMaybe<JsonNullableFilter>;
  transactionCode?: InputMaybe<StringNullableFilter>;
  transactionId?: InputMaybe<StringFilter>;
  transactionType?: InputMaybe<StringNullableFilter>;
  unofficialCurrencyCode?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TransactionWhereUniqueInput = {
  AND?: InputMaybe<Array<TransactionWhereInput>>;
  NOT?: InputMaybe<Array<TransactionWhereInput>>;
  OR?: InputMaybe<Array<TransactionWhereInput>>;
  accountId?: InputMaybe<StringFilter>;
  accountOwner?: InputMaybe<StringNullableFilter>;
  amount?: InputMaybe<DecimalFilter>;
  authorizedDate?: InputMaybe<DateTimeNullableFilter>;
  authorizedDatetime?: InputMaybe<DateTimeNullableFilter>;
  category?: InputMaybe<StringNullableListFilter>;
  categoryId?: InputMaybe<StringNullableFilter>;
  checkNumber?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  dateTime?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isoCurrencyCode?: InputMaybe<StringNullableFilter>;
  linkedAccount?: InputMaybe<LinkedAccountRelationFilter>;
  linkedAccountId?: InputMaybe<StringFilter>;
  location?: InputMaybe<JsonNullableFilter>;
  merchantName?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  originalDescription?: InputMaybe<StringNullableFilter>;
  paymentChannel?: InputMaybe<StringNullableFilter>;
  paymentMeta?: InputMaybe<JsonNullableFilter>;
  pending?: InputMaybe<BoolFilter>;
  pendingTransactionId?: InputMaybe<StringNullableFilter>;
  personalFinanceCategory?: InputMaybe<JsonNullableFilter>;
  transactionCode?: InputMaybe<StringNullableFilter>;
  transactionId?: InputMaybe<Scalars["String"]["input"]>;
  transactionType?: InputMaybe<StringNullableFilter>;
  unofficialCurrencyCode?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UpdateAssetInput = {
  assetAccount?: InputMaybe<AssetAccountInput>;
  assetCategory?: AssetCategory;
  description: Scalars["String"]["input"];
  dodValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  estimatedValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  finalValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  inTrust?: Scalars["Boolean"]["input"];
  probateEstate?: InputMaybe<Scalars["String"]["input"]>;
  probateJurisdiction?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<AssetStatus>;
};

export type UpdateExpenseInput = {
  amount?: InputMaybe<Scalars["Decimal"]["input"]>;
  componentId?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserIdpInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type User = {
  __typename?: "User";
  Contact: Array<Contact>;
  EULAAcceptanceHistories: Array<EulaAcceptanceHistory>;
  _count?: Maybe<UserCount>;
  active: Scalars["Boolean"]["output"];
  address?: Maybe<Scalars["String"]["output"]>;
  avatar?: Maybe<Scalars["String"]["output"]>;
  beneficiaries: Array<Beneficiary>;
  boxUserId?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  components: Array<Component>;
  contents: Array<Content>;
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  currentAddress?: Maybe<Address>;
  currentAddressId?: Maybe<Scalars["String"]["output"]>;
  dateOfBirth?: Maybe<Scalars["DateTimeISO"]["output"]>;
  deviceRegistration?: Maybe<DeviceRegistration>;
  email: Scalars["String"]["output"];
  estates: Array<UsersOnEstates>;
  expenses: Array<Expense>;
  externalId: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  forcePasswordReset: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  logins: Array<UserLogin>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  notifications: Array<UserNotification>;
  partnerId?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  sex?: Maybe<Sex>;
  sfx?: Maybe<Sfx>;
  ssn?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  tempPassword?: Maybe<Scalars["String"]["output"]>;
  type: UserType;
  updatedAt: Scalars["DateTimeISO"]["output"];
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type UserContactArgs = {
  cursor?: InputMaybe<ContactWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContactScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContactOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContactWhereInput>;
};

export type UserEulaAcceptanceHistoriesArgs = {
  cursor?: InputMaybe<EulaAcceptanceHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<EulaAcceptanceHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EulaAcceptanceHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<EulaAcceptanceHistoryWhereInput>;
};

export type UserBeneficiariesArgs = {
  cursor?: InputMaybe<BeneficiaryWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeneficiaryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeneficiaryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<BeneficiaryWhereInput>;
};

export type UserComponentsArgs = {
  cursor?: InputMaybe<ComponentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ComponentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ComponentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ComponentWhereInput>;
};

export type UserContentsArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ContentWhereInput>;
};

export type UserCurrentAddressArgs = {
  where?: InputMaybe<AddressWhereInput>;
};

export type UserDeviceRegistrationArgs = {
  where?: InputMaybe<DeviceRegistrationWhereInput>;
};

export type UserEstatesArgs = {
  cursor?: InputMaybe<UsersOnEstatesWhereUniqueInput>;
  distinct?: InputMaybe<Array<UsersOnEstatesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UsersOnEstatesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UsersOnEstatesWhereInput>;
};

export type UserExpensesArgs = {
  cursor?: InputMaybe<ExpenseWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExpenseScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExpenseOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ExpenseWhereInput>;
};

export type UserLoginsArgs = {
  cursor?: InputMaybe<UserLoginWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserLoginScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserLoginOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserLoginWhereInput>;
};

export type UserNotificationsArgs = {
  cursor?: InputMaybe<UserNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserNotificationWhereInput>;
};

export type User2 = {
  __typename?: "User2";
  EULAAcceptanceHistories?: Maybe<Array<EulaAcceptanceHistory2>>;
  active: Scalars["Boolean"]["output"];
  address?: Maybe<Scalars["String"]["output"]>;
  avatar?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  dateOfBirth?: Maybe<Scalars["DateTimeISO"]["output"]>;
  deviceRegistration?: Maybe<DeviceRegistration2>;
  email: Scalars["String"]["output"];
  estates: Array<UsersOnEstates2>;
  expenses?: Maybe<Array<Expense2>>;
  externalId?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  forcePasswordReset: Scalars["Boolean"]["output"];
  fullSsn?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  notifications: Array<UserNotification2>;
  partnerId?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  ssnLast4?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  type: UserType;
  updatedAt: Scalars["DateTimeISO"]["output"];
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type UserCount = {
  __typename?: "UserCount";
  Contact: Scalars["Int"]["output"];
  EULAAcceptanceHistories: Scalars["Int"]["output"];
  beneficiaries: Scalars["Int"]["output"];
  components: Scalars["Int"]["output"];
  contents: Scalars["Int"]["output"];
  estates: Scalars["Int"]["output"];
  expenses: Scalars["Int"]["output"];
  logins: Scalars["Int"]["output"];
  notifications: Scalars["Int"]["output"];
};

export type UserCountContactArgs = {
  where?: InputMaybe<ContactWhereInput>;
};

export type UserCountEulaAcceptanceHistoriesArgs = {
  where?: InputMaybe<EulaAcceptanceHistoryWhereInput>;
};

export type UserCountBeneficiariesArgs = {
  where?: InputMaybe<BeneficiaryWhereInput>;
};

export type UserCountComponentsArgs = {
  where?: InputMaybe<ComponentWhereInput>;
};

export type UserCountContentsArgs = {
  where?: InputMaybe<ContentWhereInput>;
};

export type UserCountEstatesArgs = {
  where?: InputMaybe<UsersOnEstatesWhereInput>;
};

export type UserCountExpensesArgs = {
  where?: InputMaybe<ExpenseWhereInput>;
};

export type UserCountLoginsArgs = {
  where?: InputMaybe<UserLoginWhereInput>;
};

export type UserCountNotificationsArgs = {
  where?: InputMaybe<UserNotificationWhereInput>;
};

export type UserCountAggregate = {
  __typename?: "UserCountAggregate";
  _all: Scalars["Int"]["output"];
  active: Scalars["Int"]["output"];
  address: Scalars["Int"]["output"];
  avatar: Scalars["Int"]["output"];
  boxUserId: Scalars["Int"]["output"];
  city: Scalars["Int"]["output"];
  country: Scalars["Int"]["output"];
  county: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  currentAddressId: Scalars["Int"]["output"];
  dateOfBirth: Scalars["Int"]["output"];
  email: Scalars["Int"]["output"];
  externalId: Scalars["Int"]["output"];
  firstName: Scalars["Int"]["output"];
  forcePasswordReset: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  lastName: Scalars["Int"]["output"];
  middleName: Scalars["Int"]["output"];
  partnerId: Scalars["Int"]["output"];
  phone: Scalars["Int"]["output"];
  sex: Scalars["Int"]["output"];
  sfx: Scalars["Int"]["output"];
  ssn: Scalars["Int"]["output"];
  state: Scalars["Int"]["output"];
  tempPassword: Scalars["Int"]["output"];
  type: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  zip: Scalars["Int"]["output"];
};

export type UserCountOrderByAggregateInput = {
  active?: InputMaybe<SortOrder>;
  address?: InputMaybe<SortOrder>;
  avatar?: InputMaybe<SortOrder>;
  boxUserId?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  county?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currentAddressId?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  forcePasswordReset?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  middleName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  sex?: InputMaybe<SortOrder>;
  sfx?: InputMaybe<SortOrder>;
  ssn?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  tempPassword?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type UserCreateInput = {
  Contact?: InputMaybe<ContactCreateNestedManyWithoutUserInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutUserInput>;
  boxUserId?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutCreatedByUserInput>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutUserInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentAddress?: InputMaybe<AddressCreateNestedOneWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  deviceRegistration?: InputMaybe<DeviceRegistrationCreateNestedOneWithoutUserInput>;
  email: Scalars["String"]["input"];
  estates?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutUserInput>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUserInput>;
  externalId: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  forcePasswordReset?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  logins?: InputMaybe<UserLoginCreateNestedManyWithoutUserInput>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  notifications?: InputMaybe<UserNotificationCreateNestedManyWithoutUserInput>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  tempPassword?: InputMaybe<Scalars["String"]["input"]>;
  type: UserType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserCreateManyCurrentAddressInput = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  boxUserId?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  email: Scalars["String"]["input"];
  externalId: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  forcePasswordReset?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  tempPassword?: InputMaybe<Scalars["String"]["input"]>;
  type: UserType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserCreateManyCurrentAddressInputEnvelope = {
  data: Array<UserCreateManyCurrentAddressInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UserCreateManyInput = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  boxUserId?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentAddressId?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  email: Scalars["String"]["input"];
  externalId: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  forcePasswordReset?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  tempPassword?: InputMaybe<Scalars["String"]["input"]>;
  type: UserType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserCreateNestedManyWithoutCurrentAddressInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserCreateOrConnectWithoutCurrentAddressInput>
  >;
  create?: InputMaybe<Array<UserCreateWithoutCurrentAddressInput>>;
  createMany?: InputMaybe<UserCreateManyCurrentAddressInputEnvelope>;
};

export type UserCreateNestedOneWithoutBeneficiariesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBeneficiariesInput>;
  create?: InputMaybe<UserCreateWithoutBeneficiariesInput>;
};

export type UserCreateNestedOneWithoutComponentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutComponentsInput>;
  create?: InputMaybe<UserCreateWithoutComponentsInput>;
};

export type UserCreateNestedOneWithoutComponentsInput2 = {
  connect?: InputMaybe<UserWhereUniqueInput2>;
};

export type UserCreateNestedOneWithoutContactInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutContactInput>;
  create?: InputMaybe<UserCreateWithoutContactInput>;
};

export type UserCreateNestedOneWithoutContentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutContentsInput>;
  create?: InputMaybe<UserCreateWithoutContentsInput>;
};

export type UserCreateNestedOneWithoutContentsInput2 = {
  connect?: InputMaybe<UserWhereUniqueInput2>;
};

export type UserCreateNestedOneWithoutDeviceRegistrationInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDeviceRegistrationInput>;
  create?: InputMaybe<UserCreateWithoutDeviceRegistrationInput>;
};

export type UserCreateNestedOneWithoutEulaAcceptanceHistoriesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutEulaAcceptanceHistoriesInput>;
  create?: InputMaybe<UserCreateWithoutEulaAcceptanceHistoriesInput>;
};

export type UserCreateNestedOneWithoutEstatesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutEstatesInput>;
  create?: InputMaybe<UserCreateWithoutEstatesInput>;
};

export type UserCreateNestedOneWithoutExpensesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutExpensesInput>;
  create?: InputMaybe<UserCreateWithoutExpensesInput>;
};

export type UserCreateNestedOneWithoutLoginsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLoginsInput>;
  create?: InputMaybe<UserCreateWithoutLoginsInput>;
};

export type UserCreateNestedOneWithoutNotificationsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<UserCreateWithoutNotificationsInput>;
};

export type UserCreateOrConnectWithoutBeneficiariesInput = {
  create: UserCreateWithoutBeneficiariesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutComponentsInput = {
  create: UserCreateWithoutComponentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutContactInput = {
  create: UserCreateWithoutContactInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutContentsInput = {
  create: UserCreateWithoutContentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCurrentAddressInput = {
  create: UserCreateWithoutCurrentAddressInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutDeviceRegistrationInput = {
  create: UserCreateWithoutDeviceRegistrationInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutEulaAcceptanceHistoriesInput = {
  create: UserCreateWithoutEulaAcceptanceHistoriesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutEstatesInput = {
  create: UserCreateWithoutEstatesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutExpensesInput = {
  create: UserCreateWithoutExpensesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLoginsInput = {
  create: UserCreateWithoutLoginsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutNotificationsInput = {
  create: UserCreateWithoutNotificationsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutBeneficiariesInput = {
  Contact?: InputMaybe<ContactCreateNestedManyWithoutUserInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  boxUserId?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutCreatedByUserInput>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutUserInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentAddress?: InputMaybe<AddressCreateNestedOneWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  deviceRegistration?: InputMaybe<DeviceRegistrationCreateNestedOneWithoutUserInput>;
  email: Scalars["String"]["input"];
  estates?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutUserInput>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUserInput>;
  externalId: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  forcePasswordReset?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  logins?: InputMaybe<UserLoginCreateNestedManyWithoutUserInput>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  notifications?: InputMaybe<UserNotificationCreateNestedManyWithoutUserInput>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  tempPassword?: InputMaybe<Scalars["String"]["input"]>;
  type: UserType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserCreateWithoutComponentsInput = {
  Contact?: InputMaybe<ContactCreateNestedManyWithoutUserInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutUserInput>;
  boxUserId?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutUserInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentAddress?: InputMaybe<AddressCreateNestedOneWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  deviceRegistration?: InputMaybe<DeviceRegistrationCreateNestedOneWithoutUserInput>;
  email: Scalars["String"]["input"];
  estates?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutUserInput>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUserInput>;
  externalId: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  forcePasswordReset?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  logins?: InputMaybe<UserLoginCreateNestedManyWithoutUserInput>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  notifications?: InputMaybe<UserNotificationCreateNestedManyWithoutUserInput>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  tempPassword?: InputMaybe<Scalars["String"]["input"]>;
  type: UserType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserCreateWithoutContactInput = {
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutUserInput>;
  boxUserId?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutCreatedByUserInput>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutUserInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentAddress?: InputMaybe<AddressCreateNestedOneWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  deviceRegistration?: InputMaybe<DeviceRegistrationCreateNestedOneWithoutUserInput>;
  email: Scalars["String"]["input"];
  estates?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutUserInput>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUserInput>;
  externalId: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  forcePasswordReset?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  logins?: InputMaybe<UserLoginCreateNestedManyWithoutUserInput>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  notifications?: InputMaybe<UserNotificationCreateNestedManyWithoutUserInput>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  tempPassword?: InputMaybe<Scalars["String"]["input"]>;
  type: UserType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserCreateWithoutContentsInput = {
  Contact?: InputMaybe<ContactCreateNestedManyWithoutUserInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutUserInput>;
  boxUserId?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutCreatedByUserInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentAddress?: InputMaybe<AddressCreateNestedOneWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  deviceRegistration?: InputMaybe<DeviceRegistrationCreateNestedOneWithoutUserInput>;
  email: Scalars["String"]["input"];
  estates?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutUserInput>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUserInput>;
  externalId: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  forcePasswordReset?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  logins?: InputMaybe<UserLoginCreateNestedManyWithoutUserInput>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  notifications?: InputMaybe<UserNotificationCreateNestedManyWithoutUserInput>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  tempPassword?: InputMaybe<Scalars["String"]["input"]>;
  type: UserType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserCreateWithoutCurrentAddressInput = {
  Contact?: InputMaybe<ContactCreateNestedManyWithoutUserInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutUserInput>;
  boxUserId?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutCreatedByUserInput>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutUserInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  deviceRegistration?: InputMaybe<DeviceRegistrationCreateNestedOneWithoutUserInput>;
  email: Scalars["String"]["input"];
  estates?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutUserInput>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUserInput>;
  externalId: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  forcePasswordReset?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  logins?: InputMaybe<UserLoginCreateNestedManyWithoutUserInput>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  notifications?: InputMaybe<UserNotificationCreateNestedManyWithoutUserInput>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  tempPassword?: InputMaybe<Scalars["String"]["input"]>;
  type: UserType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserCreateWithoutDeviceRegistrationInput = {
  Contact?: InputMaybe<ContactCreateNestedManyWithoutUserInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutUserInput>;
  boxUserId?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutCreatedByUserInput>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutUserInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentAddress?: InputMaybe<AddressCreateNestedOneWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  email: Scalars["String"]["input"];
  estates?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutUserInput>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUserInput>;
  externalId: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  forcePasswordReset?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  logins?: InputMaybe<UserLoginCreateNestedManyWithoutUserInput>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  notifications?: InputMaybe<UserNotificationCreateNestedManyWithoutUserInput>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  tempPassword?: InputMaybe<Scalars["String"]["input"]>;
  type: UserType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserCreateWithoutEulaAcceptanceHistoriesInput = {
  Contact?: InputMaybe<ContactCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutUserInput>;
  boxUserId?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutCreatedByUserInput>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutUserInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentAddress?: InputMaybe<AddressCreateNestedOneWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  deviceRegistration?: InputMaybe<DeviceRegistrationCreateNestedOneWithoutUserInput>;
  email: Scalars["String"]["input"];
  estates?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutUserInput>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUserInput>;
  externalId: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  forcePasswordReset?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  logins?: InputMaybe<UserLoginCreateNestedManyWithoutUserInput>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  notifications?: InputMaybe<UserNotificationCreateNestedManyWithoutUserInput>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  tempPassword?: InputMaybe<Scalars["String"]["input"]>;
  type: UserType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserCreateWithoutEstatesInput = {
  Contact?: InputMaybe<ContactCreateNestedManyWithoutUserInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutUserInput>;
  boxUserId?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutCreatedByUserInput>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutUserInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentAddress?: InputMaybe<AddressCreateNestedOneWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  deviceRegistration?: InputMaybe<DeviceRegistrationCreateNestedOneWithoutUserInput>;
  email: Scalars["String"]["input"];
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUserInput>;
  externalId: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  forcePasswordReset?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  logins?: InputMaybe<UserLoginCreateNestedManyWithoutUserInput>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  notifications?: InputMaybe<UserNotificationCreateNestedManyWithoutUserInput>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  tempPassword?: InputMaybe<Scalars["String"]["input"]>;
  type: UserType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserCreateWithoutExpensesInput = {
  Contact?: InputMaybe<ContactCreateNestedManyWithoutUserInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutUserInput>;
  boxUserId?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutCreatedByUserInput>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutUserInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentAddress?: InputMaybe<AddressCreateNestedOneWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  deviceRegistration?: InputMaybe<DeviceRegistrationCreateNestedOneWithoutUserInput>;
  email: Scalars["String"]["input"];
  estates?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutUserInput>;
  externalId: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  forcePasswordReset?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  logins?: InputMaybe<UserLoginCreateNestedManyWithoutUserInput>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  notifications?: InputMaybe<UserNotificationCreateNestedManyWithoutUserInput>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  tempPassword?: InputMaybe<Scalars["String"]["input"]>;
  type: UserType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserCreateWithoutLoginsInput = {
  Contact?: InputMaybe<ContactCreateNestedManyWithoutUserInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutUserInput>;
  boxUserId?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutCreatedByUserInput>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutUserInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentAddress?: InputMaybe<AddressCreateNestedOneWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  deviceRegistration?: InputMaybe<DeviceRegistrationCreateNestedOneWithoutUserInput>;
  email: Scalars["String"]["input"];
  estates?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutUserInput>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUserInput>;
  externalId: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  forcePasswordReset?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  notifications?: InputMaybe<UserNotificationCreateNestedManyWithoutUserInput>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  tempPassword?: InputMaybe<Scalars["String"]["input"]>;
  type: UserType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserCreateWithoutNotificationsInput = {
  Contact?: InputMaybe<ContactCreateNestedManyWithoutUserInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  beneficiaries?: InputMaybe<BeneficiaryCreateNestedManyWithoutUserInput>;
  boxUserId?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  components?: InputMaybe<ComponentCreateNestedManyWithoutCreatedByUserInput>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutUserInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  currentAddress?: InputMaybe<AddressCreateNestedOneWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  deviceRegistration?: InputMaybe<DeviceRegistrationCreateNestedOneWithoutUserInput>;
  email: Scalars["String"]["input"];
  estates?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutUserInput>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUserInput>;
  externalId: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  forcePasswordReset?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  logins?: InputMaybe<UserLoginCreateNestedManyWithoutUserInput>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<Sex>;
  sfx?: InputMaybe<Sfx>;
  ssn?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  tempPassword?: InputMaybe<Scalars["String"]["input"]>;
  type: UserType;
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserEstateNotification = {
  __typename?: "UserEstateNotification";
  createdAt: Scalars["DateTimeISO"]["output"];
  estateId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  message: Scalars["String"]["output"];
  route?: Maybe<Scalars["String"]["output"]>;
  routingError?: Maybe<RoutingError>;
  seenAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  userId: Scalars["ID"]["output"];
};

export enum UserEstateRole {
  Beneficiery = "BENEFICIERY",
  Executor = "EXECUTOR",
  TeamMember = "TEAM_MEMBER",
}

export type UserGroupBy = {
  __typename?: "UserGroupBy";
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  active: Scalars["Boolean"]["output"];
  address?: Maybe<Scalars["String"]["output"]>;
  avatar?: Maybe<Scalars["String"]["output"]>;
  boxUserId?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  currentAddressId?: Maybe<Scalars["String"]["output"]>;
  dateOfBirth?: Maybe<Scalars["DateTimeISO"]["output"]>;
  email: Scalars["String"]["output"];
  externalId: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  forcePasswordReset: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  middleName?: Maybe<Scalars["String"]["output"]>;
  partnerId?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  sex?: Maybe<Sex>;
  sfx?: Maybe<Sfx>;
  ssn?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  tempPassword?: Maybe<Scalars["String"]["output"]>;
  type: UserType;
  updatedAt: Scalars["DateTimeISO"]["output"];
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserLogin = {
  __typename?: "UserLogin";
  createdAt: Scalars["DateTimeISO"]["output"];
  id: Scalars["String"]["output"];
  loginAt: Scalars["DateTimeISO"]["output"];
  loginIp: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
};

export type UserLoginCountAggregate = {
  __typename?: "UserLoginCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  loginAt: Scalars["Int"]["output"];
  loginIp: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  userId: Scalars["Int"]["output"];
};

export type UserLoginCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  loginAt?: InputMaybe<SortOrder>;
  loginIp?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserLoginCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  loginAt: Scalars["DateTimeISO"]["input"];
  loginIp: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user: UserCreateNestedOneWithoutLoginsInput;
};

export type UserLoginCreateManyInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  loginAt: Scalars["DateTimeISO"]["input"];
  loginIp: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type UserLoginCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  loginAt: Scalars["DateTimeISO"]["input"];
  loginIp: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type UserLoginCreateManyUserInputEnvelope = {
  data: Array<UserLoginCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UserLoginCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserLoginWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserLoginCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserLoginCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserLoginCreateManyUserInputEnvelope>;
};

export type UserLoginCreateOrConnectWithoutUserInput = {
  create: UserLoginCreateWithoutUserInput;
  where: UserLoginWhereUniqueInput;
};

export type UserLoginCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  loginAt: Scalars["DateTimeISO"]["input"];
  loginIp: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type UserLoginGroupBy = {
  __typename?: "UserLoginGroupBy";
  _count?: Maybe<UserLoginCountAggregate>;
  _max?: Maybe<UserLoginMaxAggregate>;
  _min?: Maybe<UserLoginMinAggregate>;
  createdAt: Scalars["DateTimeISO"]["output"];
  id: Scalars["String"]["output"];
  loginAt: Scalars["DateTimeISO"]["output"];
  loginIp: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  userId: Scalars["String"]["output"];
};

export type UserLoginListRelationFilter = {
  every?: InputMaybe<UserLoginWhereInput>;
  none?: InputMaybe<UserLoginWhereInput>;
  some?: InputMaybe<UserLoginWhereInput>;
};

export type UserLoginMaxAggregate = {
  __typename?: "UserLoginMaxAggregate";
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  loginAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  loginIp?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type UserLoginMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  loginAt?: InputMaybe<SortOrder>;
  loginIp?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserLoginMinAggregate = {
  __typename?: "UserLoginMinAggregate";
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  loginAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  loginIp?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type UserLoginMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  loginAt?: InputMaybe<SortOrder>;
  loginIp?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserLoginOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserLoginOrderByWithAggregationInput = {
  _count?: InputMaybe<UserLoginCountOrderByAggregateInput>;
  _max?: InputMaybe<UserLoginMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserLoginMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  loginAt?: InputMaybe<SortOrder>;
  loginIp?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserLoginOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  loginAt?: InputMaybe<SortOrder>;
  loginIp?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserLoginScalarFieldEnum {
  CreatedAt = "createdAt",
  Id = "id",
  LoginAt = "loginAt",
  LoginIp = "loginIp",
  UpdatedAt = "updatedAt",
  UserId = "userId",
}

export type UserLoginScalarWhereInput = {
  AND?: InputMaybe<Array<UserLoginScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserLoginScalarWhereInput>>;
  OR?: InputMaybe<Array<UserLoginScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  loginAt?: InputMaybe<DateTimeFilter>;
  loginIp?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserLoginScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserLoginScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserLoginScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserLoginScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  loginAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  loginIp?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserLoginUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  loginAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  loginIp?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutLoginsNestedInput>;
};

export type UserLoginUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  loginAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  loginIp?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserLoginUpdateManyWithWhereWithoutUserInput = {
  data: UserLoginUpdateManyMutationInput;
  where: UserLoginScalarWhereInput;
};

export type UserLoginUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserLoginWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserLoginCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserLoginCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserLoginCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserLoginWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserLoginScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserLoginWhereUniqueInput>>;
  set?: InputMaybe<Array<UserLoginWhereUniqueInput>>;
  update?: InputMaybe<Array<UserLoginUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserLoginUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserLoginUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserLoginUpdateWithWhereUniqueWithoutUserInput = {
  data: UserLoginUpdateWithoutUserInput;
  where: UserLoginWhereUniqueInput;
};

export type UserLoginUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  loginAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  loginIp?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserLoginUpsertWithWhereUniqueWithoutUserInput = {
  create: UserLoginCreateWithoutUserInput;
  update: UserLoginUpdateWithoutUserInput;
  where: UserLoginWhereUniqueInput;
};

export type UserLoginWhereInput = {
  AND?: InputMaybe<Array<UserLoginWhereInput>>;
  NOT?: InputMaybe<Array<UserLoginWhereInput>>;
  OR?: InputMaybe<Array<UserLoginWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  loginAt?: InputMaybe<DateTimeFilter>;
  loginIp?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserLoginWhereUniqueInput = {
  AND?: InputMaybe<Array<UserLoginWhereInput>>;
  NOT?: InputMaybe<Array<UserLoginWhereInput>>;
  OR?: InputMaybe<Array<UserLoginWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  loginAt?: InputMaybe<DateTimeFilter>;
  loginIp?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserMaxAggregate = {
  __typename?: "UserMaxAggregate";
  active?: Maybe<Scalars["Boolean"]["output"]>;
  address?: Maybe<Scalars["String"]["output"]>;
  avatar?: Maybe<Scalars["String"]["output"]>;
  boxUserId?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  currentAddressId?: Maybe<Scalars["String"]["output"]>;
  dateOfBirth?: Maybe<Scalars["DateTimeISO"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  externalId?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  forcePasswordReset?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  partnerId?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  sex?: Maybe<Sex>;
  sfx?: Maybe<Sfx>;
  ssn?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  tempPassword?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type UserMaxOrderByAggregateInput = {
  active?: InputMaybe<SortOrder>;
  address?: InputMaybe<SortOrder>;
  avatar?: InputMaybe<SortOrder>;
  boxUserId?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  county?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currentAddressId?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  forcePasswordReset?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  middleName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  sex?: InputMaybe<SortOrder>;
  sfx?: InputMaybe<SortOrder>;
  ssn?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  tempPassword?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type UserMinAggregate = {
  __typename?: "UserMinAggregate";
  active?: Maybe<Scalars["Boolean"]["output"]>;
  address?: Maybe<Scalars["String"]["output"]>;
  avatar?: Maybe<Scalars["String"]["output"]>;
  boxUserId?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  currentAddressId?: Maybe<Scalars["String"]["output"]>;
  dateOfBirth?: Maybe<Scalars["DateTimeISO"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  externalId?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  forcePasswordReset?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  partnerId?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  sex?: Maybe<Sex>;
  sfx?: Maybe<Sfx>;
  ssn?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  tempPassword?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type UserMinOrderByAggregateInput = {
  active?: InputMaybe<SortOrder>;
  address?: InputMaybe<SortOrder>;
  avatar?: InputMaybe<SortOrder>;
  boxUserId?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  county?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currentAddressId?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  forcePasswordReset?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  middleName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  sex?: InputMaybe<SortOrder>;
  sfx?: InputMaybe<SortOrder>;
  ssn?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  tempPassword?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type UserNotification = {
  __typename?: "UserNotification";
  createdAt: Scalars["DateTimeISO"]["output"];
  notification: EstateNotification;
  notificationId: Scalars["String"]["output"];
  seenAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  user: User;
  userId: Scalars["String"]["output"];
};

export type UserNotification2 = {
  __typename?: "UserNotification2";
  createdAt: Scalars["DateTimeISO"]["output"];
  notification?: Maybe<EstateNotification2>;
  notificationId: Scalars["String"]["output"];
  seenAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  user?: Maybe<User2>;
  userId: Scalars["String"]["output"];
};

export type UserNotificationCountAggregate = {
  __typename?: "UserNotificationCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  notificationId: Scalars["Int"]["output"];
  seenAt: Scalars["Int"]["output"];
  userId: Scalars["Int"]["output"];
};

export type UserNotificationCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  notificationId?: InputMaybe<SortOrder>;
  seenAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserNotificationCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  notification: EstateNotificationCreateNestedOneWithoutUserNotificationsInput;
  seenAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user: UserCreateNestedOneWithoutNotificationsInput;
};

export type UserNotificationCreateManyInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  notificationId: Scalars["String"]["input"];
  seenAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type UserNotificationCreateManyNotificationInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  seenAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type UserNotificationCreateManyNotificationInputEnvelope = {
  data: Array<UserNotificationCreateManyNotificationInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UserNotificationCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  notificationId: Scalars["String"]["input"];
  seenAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type UserNotificationCreateManyUserInputEnvelope = {
  data: Array<UserNotificationCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UserNotificationCreateNestedManyWithoutNotificationInput = {
  connect?: InputMaybe<Array<UserNotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserNotificationCreateOrConnectWithoutNotificationInput>
  >;
  create?: InputMaybe<Array<UserNotificationCreateWithoutNotificationInput>>;
  createMany?: InputMaybe<UserNotificationCreateManyNotificationInputEnvelope>;
};

export type UserNotificationCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserNotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserNotificationCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<UserNotificationCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserNotificationCreateManyUserInputEnvelope>;
};

export type UserNotificationCreateOrConnectWithoutNotificationInput = {
  create: UserNotificationCreateWithoutNotificationInput;
  where: UserNotificationWhereUniqueInput;
};

export type UserNotificationCreateOrConnectWithoutUserInput = {
  create: UserNotificationCreateWithoutUserInput;
  where: UserNotificationWhereUniqueInput;
};

export type UserNotificationCreateWithoutNotificationInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  seenAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  user: UserCreateNestedOneWithoutNotificationsInput;
};

export type UserNotificationCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  notification: EstateNotificationCreateNestedOneWithoutUserNotificationsInput;
  seenAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
};

export type UserNotificationGroupBy = {
  __typename?: "UserNotificationGroupBy";
  _count?: Maybe<UserNotificationCountAggregate>;
  _max?: Maybe<UserNotificationMaxAggregate>;
  _min?: Maybe<UserNotificationMinAggregate>;
  createdAt: Scalars["DateTimeISO"]["output"];
  notificationId: Scalars["String"]["output"];
  seenAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  userId: Scalars["String"]["output"];
};

export type UserNotificationListRelationFilter = {
  every?: InputMaybe<UserNotificationWhereInput>;
  none?: InputMaybe<UserNotificationWhereInput>;
  some?: InputMaybe<UserNotificationWhereInput>;
};

export type UserNotificationMaxAggregate = {
  __typename?: "UserNotificationMaxAggregate";
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  notificationId?: Maybe<Scalars["String"]["output"]>;
  seenAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type UserNotificationMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  notificationId?: InputMaybe<SortOrder>;
  seenAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserNotificationMinAggregate = {
  __typename?: "UserNotificationMinAggregate";
  createdAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  notificationId?: Maybe<Scalars["String"]["output"]>;
  seenAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type UserNotificationMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  notificationId?: InputMaybe<SortOrder>;
  seenAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserNotificationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserNotificationOrderByWithAggregationInput = {
  _count?: InputMaybe<UserNotificationCountOrderByAggregateInput>;
  _max?: InputMaybe<UserNotificationMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserNotificationMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  notificationId?: InputMaybe<SortOrder>;
  seenAt?: InputMaybe<SortOrderInput>;
  userId?: InputMaybe<SortOrder>;
};

export type UserNotificationOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  notification?: InputMaybe<EstateNotificationOrderByWithRelationInput>;
  notificationId?: InputMaybe<SortOrder>;
  seenAt?: InputMaybe<SortOrderInput>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserNotificationScalarFieldEnum {
  CreatedAt = "createdAt",
  NotificationId = "notificationId",
  SeenAt = "seenAt",
  UserId = "userId",
}

export type UserNotificationScalarWhereInput = {
  AND?: InputMaybe<Array<UserNotificationScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserNotificationScalarWhereInput>>;
  OR?: InputMaybe<Array<UserNotificationScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  notificationId?: InputMaybe<StringFilter>;
  seenAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserNotificationScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserNotificationScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserNotificationScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserNotificationScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  notificationId?: InputMaybe<StringWithAggregatesFilter>;
  seenAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserNotificationUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  notification?: InputMaybe<EstateNotificationUpdateOneRequiredWithoutUserNotificationsNestedInput>;
  seenAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutNotificationsNestedInput>;
};

export type UserNotificationUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  seenAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserNotificationUpdateManyWithWhereWithoutNotificationInput = {
  data: UserNotificationUpdateManyMutationInput;
  where: UserNotificationScalarWhereInput;
};

export type UserNotificationUpdateManyWithWhereWithoutUserInput = {
  data: UserNotificationUpdateManyMutationInput;
  where: UserNotificationScalarWhereInput;
};

export type UserNotificationUpdateManyWithoutNotificationNestedInput = {
  connect?: InputMaybe<Array<UserNotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserNotificationCreateOrConnectWithoutNotificationInput>
  >;
  create?: InputMaybe<Array<UserNotificationCreateWithoutNotificationInput>>;
  createMany?: InputMaybe<UserNotificationCreateManyNotificationInputEnvelope>;
  delete?: InputMaybe<Array<UserNotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserNotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserNotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<UserNotificationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UserNotificationUpdateWithWhereUniqueWithoutNotificationInput>
  >;
  updateMany?: InputMaybe<
    Array<UserNotificationUpdateManyWithWhereWithoutNotificationInput>
  >;
  upsert?: InputMaybe<
    Array<UserNotificationUpsertWithWhereUniqueWithoutNotificationInput>
  >;
};

export type UserNotificationUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserNotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserNotificationCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<UserNotificationCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserNotificationCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserNotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserNotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserNotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<UserNotificationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UserNotificationUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<UserNotificationUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<UserNotificationUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type UserNotificationUpdateWithWhereUniqueWithoutNotificationInput = {
  data: UserNotificationUpdateWithoutNotificationInput;
  where: UserNotificationWhereUniqueInput;
};

export type UserNotificationUpdateWithWhereUniqueWithoutUserInput = {
  data: UserNotificationUpdateWithoutUserInput;
  where: UserNotificationWhereUniqueInput;
};

export type UserNotificationUpdateWithoutNotificationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  seenAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutNotificationsNestedInput>;
};

export type UserNotificationUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  notification?: InputMaybe<EstateNotificationUpdateOneRequiredWithoutUserNotificationsNestedInput>;
  seenAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type UserNotificationUpsertWithWhereUniqueWithoutNotificationInput = {
  create: UserNotificationCreateWithoutNotificationInput;
  update: UserNotificationUpdateWithoutNotificationInput;
  where: UserNotificationWhereUniqueInput;
};

export type UserNotificationUpsertWithWhereUniqueWithoutUserInput = {
  create: UserNotificationCreateWithoutUserInput;
  update: UserNotificationUpdateWithoutUserInput;
  where: UserNotificationWhereUniqueInput;
};

export type UserNotificationUserIdNotificationIdCompoundUniqueInput = {
  notificationId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type UserNotificationWhereInput = {
  AND?: InputMaybe<Array<UserNotificationWhereInput>>;
  NOT?: InputMaybe<Array<UserNotificationWhereInput>>;
  OR?: InputMaybe<Array<UserNotificationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  notification?: InputMaybe<EstateNotificationRelationFilter>;
  notificationId?: InputMaybe<StringFilter>;
  seenAt?: InputMaybe<DateTimeNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserNotificationWhereUniqueInput = {
  AND?: InputMaybe<Array<UserNotificationWhereInput>>;
  NOT?: InputMaybe<Array<UserNotificationWhereInput>>;
  OR?: InputMaybe<Array<UserNotificationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  notification?: InputMaybe<EstateNotificationRelationFilter>;
  notificationId?: InputMaybe<StringFilter>;
  seenAt?: InputMaybe<DateTimeNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  userId_notificationId?: InputMaybe<UserNotificationUserIdNotificationIdCompoundUniqueInput>;
};

export type UserNullableRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserOrderByWithAggregationInput = {
  _count?: InputMaybe<UserCountOrderByAggregateInput>;
  _max?: InputMaybe<UserMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserMinOrderByAggregateInput>;
  active?: InputMaybe<SortOrder>;
  address?: InputMaybe<SortOrderInput>;
  avatar?: InputMaybe<SortOrderInput>;
  boxUserId?: InputMaybe<SortOrderInput>;
  city?: InputMaybe<SortOrderInput>;
  country?: InputMaybe<SortOrderInput>;
  county?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  currentAddressId?: InputMaybe<SortOrderInput>;
  dateOfBirth?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  forcePasswordReset?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  middleName?: InputMaybe<SortOrderInput>;
  partnerId?: InputMaybe<SortOrderInput>;
  phone?: InputMaybe<SortOrderInput>;
  sex?: InputMaybe<SortOrderInput>;
  sfx?: InputMaybe<SortOrderInput>;
  ssn?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<SortOrderInput>;
  tempPassword?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrderInput>;
};

export type UserOrderByWithRelationInput = {
  Contact?: InputMaybe<ContactOrderByRelationAggregateInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryOrderByRelationAggregateInput>;
  active?: InputMaybe<SortOrder>;
  address?: InputMaybe<SortOrderInput>;
  avatar?: InputMaybe<SortOrderInput>;
  beneficiaries?: InputMaybe<BeneficiaryOrderByRelationAggregateInput>;
  boxUserId?: InputMaybe<SortOrderInput>;
  city?: InputMaybe<SortOrderInput>;
  components?: InputMaybe<ComponentOrderByRelationAggregateInput>;
  contents?: InputMaybe<ContentOrderByRelationAggregateInput>;
  country?: InputMaybe<SortOrderInput>;
  county?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  currentAddress?: InputMaybe<AddressOrderByWithRelationInput>;
  currentAddressId?: InputMaybe<SortOrderInput>;
  dateOfBirth?: InputMaybe<SortOrderInput>;
  deviceRegistration?: InputMaybe<DeviceRegistrationOrderByWithRelationInput>;
  email?: InputMaybe<SortOrder>;
  estates?: InputMaybe<UsersOnEstatesOrderByRelationAggregateInput>;
  expenses?: InputMaybe<ExpenseOrderByRelationAggregateInput>;
  externalId?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  forcePasswordReset?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  logins?: InputMaybe<UserLoginOrderByRelationAggregateInput>;
  middleName?: InputMaybe<SortOrderInput>;
  notifications?: InputMaybe<UserNotificationOrderByRelationAggregateInput>;
  partnerId?: InputMaybe<SortOrderInput>;
  phone?: InputMaybe<SortOrderInput>;
  sex?: InputMaybe<SortOrderInput>;
  sfx?: InputMaybe<SortOrderInput>;
  ssn?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<SortOrderInput>;
  tempPassword?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrderInput>;
};

export type UserOrderByWithRelationInput2 = {
  active?: InputMaybe<SortOrder>;
  address?: InputMaybe<SortOrderInput>;
  avatar?: InputMaybe<SortOrderInput>;
  city?: InputMaybe<SortOrderInput>;
  country?: InputMaybe<SortOrderInput>;
  county?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrderInput>;
  forcePasswordReset?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrderInput>;
  partnerId?: InputMaybe<SortOrderInput>;
  phone?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrderInput>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  Active = "active",
  Address = "address",
  Avatar = "avatar",
  BoxUserId = "boxUserId",
  City = "city",
  Country = "country",
  County = "county",
  CreatedAt = "createdAt",
  CurrentAddressId = "currentAddressId",
  DateOfBirth = "dateOfBirth",
  Email = "email",
  ExternalId = "externalId",
  FirstName = "firstName",
  ForcePasswordReset = "forcePasswordReset",
  Id = "id",
  LastName = "lastName",
  MiddleName = "middleName",
  PartnerId = "partnerId",
  Phone = "phone",
  Sex = "sex",
  Sfx = "sfx",
  Ssn = "ssn",
  State = "state",
  TempPassword = "tempPassword",
  Type = "type",
  UpdatedAt = "updatedAt",
  Zip = "zip",
}

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  address?: InputMaybe<StringNullableFilter>;
  avatar?: InputMaybe<StringNullableFilter>;
  boxUserId?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  county?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currentAddressId?: InputMaybe<StringNullableFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  externalId?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringFilter>;
  forcePasswordReset?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringFilter>;
  middleName?: InputMaybe<StringNullableFilter>;
  partnerId?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  sex?: InputMaybe<EnumSexNullableFilter>;
  sfx?: InputMaybe<EnumSfxNullableFilter>;
  ssn?: InputMaybe<StringNullableFilter>;
  state?: InputMaybe<StringNullableFilter>;
  tempPassword?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumUserTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type UserScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  active?: InputMaybe<BoolWithAggregatesFilter>;
  address?: InputMaybe<StringNullableWithAggregatesFilter>;
  avatar?: InputMaybe<StringNullableWithAggregatesFilter>;
  boxUserId?: InputMaybe<StringNullableWithAggregatesFilter>;
  city?: InputMaybe<StringNullableWithAggregatesFilter>;
  country?: InputMaybe<StringNullableWithAggregatesFilter>;
  county?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  currentAddressId?: InputMaybe<StringNullableWithAggregatesFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  externalId?: InputMaybe<StringWithAggregatesFilter>;
  firstName?: InputMaybe<StringWithAggregatesFilter>;
  forcePasswordReset?: InputMaybe<BoolWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  lastName?: InputMaybe<StringWithAggregatesFilter>;
  middleName?: InputMaybe<StringNullableWithAggregatesFilter>;
  partnerId?: InputMaybe<StringNullableWithAggregatesFilter>;
  phone?: InputMaybe<StringNullableWithAggregatesFilter>;
  sex?: InputMaybe<EnumSexNullableWithAggregatesFilter>;
  sfx?: InputMaybe<EnumSfxNullableWithAggregatesFilter>;
  ssn?: InputMaybe<StringNullableWithAggregatesFilter>;
  state?: InputMaybe<StringNullableWithAggregatesFilter>;
  tempPassword?: InputMaybe<StringNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumUserTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  zip?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export enum UserType {
  Admin = "ADMIN",
  Careteam = "CARETEAM",
  Customer = "CUSTOMER",
  Internal = "INTERNAL",
  Ops = "OPS",
}

export type UserUpdateInput = {
  Contact?: InputMaybe<ContactUpdateManyWithoutUserNestedInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutUserNestedInput>;
  boxUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutCreatedByUserNestedInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutUserNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentAddress?: InputMaybe<AddressUpdateOneWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deviceRegistration?: InputMaybe<DeviceRegistrationUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  estates?: InputMaybe<UsersOnEstatesUpdateManyWithoutUserNestedInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  forcePasswordReset?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logins?: InputMaybe<UserLoginUpdateManyWithoutUserNestedInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<UserNotificationUpdateManyWithoutUserNestedInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tempPassword?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateInput2 = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  county?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  estates?: InputMaybe<UsersOnEstatesUpdateManyWithoutUserNestedInput2>;
  externalId?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<UserType>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserUpdateManyMutationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  forcePasswordReset?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tempPassword?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutCurrentAddressInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutCurrentAddressNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserCreateOrConnectWithoutCurrentAddressInput>
  >;
  create?: InputMaybe<Array<UserCreateWithoutCurrentAddressInput>>;
  createMany?: InputMaybe<UserCreateManyCurrentAddressInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UserUpdateWithWhereUniqueWithoutCurrentAddressInput>
  >;
  updateMany?: InputMaybe<
    Array<UserUpdateManyWithWhereWithoutCurrentAddressInput>
  >;
  upsert?: InputMaybe<
    Array<UserUpsertWithWhereUniqueWithoutCurrentAddressInput>
  >;
};

export type UserUpdateOneRequiredWithoutDeviceRegistrationNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDeviceRegistrationInput>;
  create?: InputMaybe<UserCreateWithoutDeviceRegistrationInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutDeviceRegistrationInput>;
  upsert?: InputMaybe<UserUpsertWithoutDeviceRegistrationInput>;
};

export type UserUpdateOneRequiredWithoutEulaAcceptanceHistoriesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutEulaAcceptanceHistoriesInput>;
  create?: InputMaybe<UserCreateWithoutEulaAcceptanceHistoriesInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutEulaAcceptanceHistoriesInput>;
  upsert?: InputMaybe<UserUpsertWithoutEulaAcceptanceHistoriesInput>;
};

export type UserUpdateOneRequiredWithoutEstatesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutEstatesInput>;
  create?: InputMaybe<UserCreateWithoutEstatesInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutEstatesInput>;
  upsert?: InputMaybe<UserUpsertWithoutEstatesInput>;
};

export type UserUpdateOneRequiredWithoutLoginsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLoginsInput>;
  create?: InputMaybe<UserCreateWithoutLoginsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutLoginsInput>;
  upsert?: InputMaybe<UserUpsertWithoutLoginsInput>;
};

export type UserUpdateOneRequiredWithoutNotificationsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<UserCreateWithoutNotificationsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutNotificationsInput>;
  upsert?: InputMaybe<UserUpsertWithoutNotificationsInput>;
};

export type UserUpdateOneWithoutBeneficiariesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBeneficiariesInput>;
  create?: InputMaybe<UserCreateWithoutBeneficiariesInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutBeneficiariesInput>;
  upsert?: InputMaybe<UserUpsertWithoutBeneficiariesInput>;
};

export type UserUpdateOneWithoutComponentsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutComponentsInput>;
  create?: InputMaybe<UserCreateWithoutComponentsInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutComponentsInput>;
  upsert?: InputMaybe<UserUpsertWithoutComponentsInput>;
};

export type UserUpdateOneWithoutContactNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutContactInput>;
  create?: InputMaybe<UserCreateWithoutContactInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutContactInput>;
  upsert?: InputMaybe<UserUpsertWithoutContactInput>;
};

export type UserUpdateOneWithoutContentsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutContentsInput>;
  create?: InputMaybe<UserCreateWithoutContentsInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutContentsInput>;
  upsert?: InputMaybe<UserUpsertWithoutContentsInput>;
};

export type UserUpdateOneWithoutContentsNestedInput2 = {
  connect?: InputMaybe<UserWhereUniqueInput2>;
};

export type UserUpdateOneWithoutExpensesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutExpensesInput>;
  create?: InputMaybe<UserCreateWithoutExpensesInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutExpensesInput>;
  upsert?: InputMaybe<UserUpsertWithoutExpensesInput>;
};

export type UserUpdateToOneWithWhereWithoutBeneficiariesInput = {
  data: UserUpdateWithoutBeneficiariesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutComponentsInput = {
  data: UserUpdateWithoutComponentsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutContactInput = {
  data: UserUpdateWithoutContactInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutContentsInput = {
  data: UserUpdateWithoutContentsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutDeviceRegistrationInput = {
  data: UserUpdateWithoutDeviceRegistrationInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutEulaAcceptanceHistoriesInput = {
  data: UserUpdateWithoutEulaAcceptanceHistoriesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutEstatesInput = {
  data: UserUpdateWithoutEstatesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutExpensesInput = {
  data: UserUpdateWithoutExpensesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutLoginsInput = {
  data: UserUpdateWithoutLoginsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutNotificationsInput = {
  data: UserUpdateWithoutNotificationsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateWithWhereUniqueWithoutCurrentAddressInput = {
  data: UserUpdateWithoutCurrentAddressInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutBeneficiariesInput = {
  Contact?: InputMaybe<ContactUpdateManyWithoutUserNestedInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  boxUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutCreatedByUserNestedInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutUserNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentAddress?: InputMaybe<AddressUpdateOneWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deviceRegistration?: InputMaybe<DeviceRegistrationUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  estates?: InputMaybe<UsersOnEstatesUpdateManyWithoutUserNestedInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  forcePasswordReset?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logins?: InputMaybe<UserLoginUpdateManyWithoutUserNestedInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<UserNotificationUpdateManyWithoutUserNestedInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tempPassword?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutComponentsInput = {
  Contact?: InputMaybe<ContactUpdateManyWithoutUserNestedInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutUserNestedInput>;
  boxUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutUserNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentAddress?: InputMaybe<AddressUpdateOneWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deviceRegistration?: InputMaybe<DeviceRegistrationUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  estates?: InputMaybe<UsersOnEstatesUpdateManyWithoutUserNestedInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  forcePasswordReset?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logins?: InputMaybe<UserLoginUpdateManyWithoutUserNestedInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<UserNotificationUpdateManyWithoutUserNestedInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tempPassword?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutContactInput = {
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutUserNestedInput>;
  boxUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutCreatedByUserNestedInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutUserNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentAddress?: InputMaybe<AddressUpdateOneWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deviceRegistration?: InputMaybe<DeviceRegistrationUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  estates?: InputMaybe<UsersOnEstatesUpdateManyWithoutUserNestedInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  forcePasswordReset?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logins?: InputMaybe<UserLoginUpdateManyWithoutUserNestedInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<UserNotificationUpdateManyWithoutUserNestedInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tempPassword?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutContentsInput = {
  Contact?: InputMaybe<ContactUpdateManyWithoutUserNestedInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutUserNestedInput>;
  boxUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutCreatedByUserNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentAddress?: InputMaybe<AddressUpdateOneWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deviceRegistration?: InputMaybe<DeviceRegistrationUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  estates?: InputMaybe<UsersOnEstatesUpdateManyWithoutUserNestedInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  forcePasswordReset?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logins?: InputMaybe<UserLoginUpdateManyWithoutUserNestedInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<UserNotificationUpdateManyWithoutUserNestedInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tempPassword?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCurrentAddressInput = {
  Contact?: InputMaybe<ContactUpdateManyWithoutUserNestedInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutUserNestedInput>;
  boxUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutCreatedByUserNestedInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutUserNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deviceRegistration?: InputMaybe<DeviceRegistrationUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  estates?: InputMaybe<UsersOnEstatesUpdateManyWithoutUserNestedInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  forcePasswordReset?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logins?: InputMaybe<UserLoginUpdateManyWithoutUserNestedInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<UserNotificationUpdateManyWithoutUserNestedInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tempPassword?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutDeviceRegistrationInput = {
  Contact?: InputMaybe<ContactUpdateManyWithoutUserNestedInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutUserNestedInput>;
  boxUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutCreatedByUserNestedInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutUserNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentAddress?: InputMaybe<AddressUpdateOneWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  estates?: InputMaybe<UsersOnEstatesUpdateManyWithoutUserNestedInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  forcePasswordReset?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logins?: InputMaybe<UserLoginUpdateManyWithoutUserNestedInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<UserNotificationUpdateManyWithoutUserNestedInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tempPassword?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutEulaAcceptanceHistoriesInput = {
  Contact?: InputMaybe<ContactUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutUserNestedInput>;
  boxUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutCreatedByUserNestedInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutUserNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentAddress?: InputMaybe<AddressUpdateOneWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deviceRegistration?: InputMaybe<DeviceRegistrationUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  estates?: InputMaybe<UsersOnEstatesUpdateManyWithoutUserNestedInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  forcePasswordReset?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logins?: InputMaybe<UserLoginUpdateManyWithoutUserNestedInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<UserNotificationUpdateManyWithoutUserNestedInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tempPassword?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutEstatesInput = {
  Contact?: InputMaybe<ContactUpdateManyWithoutUserNestedInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutUserNestedInput>;
  boxUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutCreatedByUserNestedInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutUserNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentAddress?: InputMaybe<AddressUpdateOneWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deviceRegistration?: InputMaybe<DeviceRegistrationUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  forcePasswordReset?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logins?: InputMaybe<UserLoginUpdateManyWithoutUserNestedInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<UserNotificationUpdateManyWithoutUserNestedInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tempPassword?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutExpensesInput = {
  Contact?: InputMaybe<ContactUpdateManyWithoutUserNestedInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutUserNestedInput>;
  boxUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutCreatedByUserNestedInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutUserNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentAddress?: InputMaybe<AddressUpdateOneWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deviceRegistration?: InputMaybe<DeviceRegistrationUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  estates?: InputMaybe<UsersOnEstatesUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  forcePasswordReset?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logins?: InputMaybe<UserLoginUpdateManyWithoutUserNestedInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<UserNotificationUpdateManyWithoutUserNestedInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tempPassword?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutLoginsInput = {
  Contact?: InputMaybe<ContactUpdateManyWithoutUserNestedInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutUserNestedInput>;
  boxUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutCreatedByUserNestedInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutUserNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentAddress?: InputMaybe<AddressUpdateOneWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deviceRegistration?: InputMaybe<DeviceRegistrationUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  estates?: InputMaybe<UsersOnEstatesUpdateManyWithoutUserNestedInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  forcePasswordReset?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<UserNotificationUpdateManyWithoutUserNestedInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tempPassword?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutNotificationsInput = {
  Contact?: InputMaybe<ContactUpdateManyWithoutUserNestedInput>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  beneficiaries?: InputMaybe<BeneficiaryUpdateManyWithoutUserNestedInput>;
  boxUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  components?: InputMaybe<ComponentUpdateManyWithoutCreatedByUserNestedInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutUserNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  county?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currentAddress?: InputMaybe<AddressUpdateOneWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deviceRegistration?: InputMaybe<DeviceRegistrationUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  estates?: InputMaybe<UsersOnEstatesUpdateManyWithoutUserNestedInput>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  forcePasswordReset?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logins?: InputMaybe<UserLoginUpdateManyWithoutUserNestedInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sex?: InputMaybe<NullableEnumSexFieldUpdateOperationsInput>;
  sfx?: InputMaybe<NullableEnumSfxFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tempPassword?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumUserTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpsertWithWhereUniqueWithoutCurrentAddressInput = {
  create: UserCreateWithoutCurrentAddressInput;
  update: UserUpdateWithoutCurrentAddressInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutBeneficiariesInput = {
  create: UserCreateWithoutBeneficiariesInput;
  update: UserUpdateWithoutBeneficiariesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutComponentsInput = {
  create: UserCreateWithoutComponentsInput;
  update: UserUpdateWithoutComponentsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutContactInput = {
  create: UserCreateWithoutContactInput;
  update: UserUpdateWithoutContactInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutContentsInput = {
  create: UserCreateWithoutContentsInput;
  update: UserUpdateWithoutContentsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutDeviceRegistrationInput = {
  create: UserCreateWithoutDeviceRegistrationInput;
  update: UserUpdateWithoutDeviceRegistrationInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutEulaAcceptanceHistoriesInput = {
  create: UserCreateWithoutEulaAcceptanceHistoriesInput;
  update: UserUpdateWithoutEulaAcceptanceHistoriesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutEstatesInput = {
  create: UserCreateWithoutEstatesInput;
  update: UserUpdateWithoutEstatesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutExpensesInput = {
  create: UserCreateWithoutExpensesInput;
  update: UserUpdateWithoutExpensesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutLoginsInput = {
  create: UserCreateWithoutLoginsInput;
  update: UserUpdateWithoutLoginsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutNotificationsInput = {
  create: UserCreateWithoutNotificationsInput;
  update: UserUpdateWithoutNotificationsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  Contact?: InputMaybe<ContactListRelationFilter>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryListRelationFilter>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  address?: InputMaybe<StringNullableFilter>;
  avatar?: InputMaybe<StringNullableFilter>;
  beneficiaries?: InputMaybe<BeneficiaryListRelationFilter>;
  boxUserId?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  components?: InputMaybe<ComponentListRelationFilter>;
  contents?: InputMaybe<ContentListRelationFilter>;
  country?: InputMaybe<StringNullableFilter>;
  county?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currentAddress?: InputMaybe<AddressNullableRelationFilter>;
  currentAddressId?: InputMaybe<StringNullableFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableFilter>;
  deviceRegistration?: InputMaybe<DeviceRegistrationNullableRelationFilter>;
  email?: InputMaybe<StringFilter>;
  estates?: InputMaybe<UsersOnEstatesListRelationFilter>;
  expenses?: InputMaybe<ExpenseListRelationFilter>;
  externalId?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringFilter>;
  forcePasswordReset?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringFilter>;
  logins?: InputMaybe<UserLoginListRelationFilter>;
  middleName?: InputMaybe<StringNullableFilter>;
  notifications?: InputMaybe<UserNotificationListRelationFilter>;
  partnerId?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  sex?: InputMaybe<EnumSexNullableFilter>;
  sfx?: InputMaybe<EnumSfxNullableFilter>;
  ssn?: InputMaybe<StringNullableFilter>;
  state?: InputMaybe<StringNullableFilter>;
  tempPassword?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumUserTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type UserWhereInput2 = {
  AND?: InputMaybe<Array<UserWhereInput2>>;
  NOT?: InputMaybe<Array<UserWhereInput2>>;
  OR?: InputMaybe<Array<UserWhereInput2>>;
  active?: InputMaybe<BoolFilter>;
  address?: InputMaybe<StringNullableFilter>;
  avatar?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  county?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  externalId?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  forcePasswordReset?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  partnerId?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  state?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumUserTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type UserWhereUniqueInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  Contact?: InputMaybe<ContactListRelationFilter>;
  EULAAcceptanceHistories?: InputMaybe<EulaAcceptanceHistoryListRelationFilter>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  address?: InputMaybe<StringNullableFilter>;
  avatar?: InputMaybe<StringNullableFilter>;
  beneficiaries?: InputMaybe<BeneficiaryListRelationFilter>;
  boxUserId?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<StringNullableFilter>;
  components?: InputMaybe<ComponentListRelationFilter>;
  contents?: InputMaybe<ContentListRelationFilter>;
  country?: InputMaybe<StringNullableFilter>;
  county?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currentAddress?: InputMaybe<AddressNullableRelationFilter>;
  currentAddressId?: InputMaybe<StringNullableFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableFilter>;
  deviceRegistration?: InputMaybe<DeviceRegistrationNullableRelationFilter>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  estates?: InputMaybe<UsersOnEstatesListRelationFilter>;
  expenses?: InputMaybe<ExpenseListRelationFilter>;
  externalId?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<StringFilter>;
  forcePasswordReset?: InputMaybe<BoolFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<StringFilter>;
  logins?: InputMaybe<UserLoginListRelationFilter>;
  middleName?: InputMaybe<StringNullableFilter>;
  notifications?: InputMaybe<UserNotificationListRelationFilter>;
  partnerId?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sex?: InputMaybe<EnumSexNullableFilter>;
  sfx?: InputMaybe<EnumSfxNullableFilter>;
  ssn?: InputMaybe<StringNullableFilter>;
  state?: InputMaybe<StringNullableFilter>;
  tempPassword?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumUserTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type UserWhereUniqueInput2 = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type UsersOnEstates = {
  __typename?: "UsersOnEstates";
  boxUserRootFolderId?: Maybe<Scalars["String"]["output"]>;
  estate: Estate;
  estateId: Scalars["String"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
  userRole?: Maybe<UserEstateRole>;
};

export type UsersOnEstates2 = {
  __typename?: "UsersOnEstates2";
  boxUserRootFolderId?: Maybe<Scalars["String"]["output"]>;
  estate: Estate2;
  estateId: Scalars["String"]["output"];
  user: User2;
  userId: Scalars["String"]["output"];
  userRole?: Maybe<UserEstateRole>;
};

export type UsersOnEstatesCountAggregate = {
  __typename?: "UsersOnEstatesCountAggregate";
  _all: Scalars["Int"]["output"];
  boxUserRootFolderId: Scalars["Int"]["output"];
  estateId: Scalars["Int"]["output"];
  userId: Scalars["Int"]["output"];
  userRole: Scalars["Int"]["output"];
};

export type UsersOnEstatesCountOrderByAggregateInput = {
  boxUserRootFolderId?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  userRole?: InputMaybe<SortOrder>;
};

export type UsersOnEstatesCreateInput = {
  boxUserRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  estate: EstateCreateNestedOneWithoutUsersInput;
  user: UserCreateNestedOneWithoutEstatesInput;
  userRole?: InputMaybe<UserEstateRole>;
};

export type UsersOnEstatesCreateManyEstateInput = {
  boxUserRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["String"]["input"];
  userRole?: InputMaybe<UserEstateRole>;
};

export type UsersOnEstatesCreateManyEstateInputEnvelope = {
  data: Array<UsersOnEstatesCreateManyEstateInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UsersOnEstatesCreateManyInput = {
  boxUserRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  estateId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
  userRole?: InputMaybe<UserEstateRole>;
};

export type UsersOnEstatesCreateManyUserInput = {
  boxUserRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  estateId: Scalars["String"]["input"];
  userRole?: InputMaybe<UserEstateRole>;
};

export type UsersOnEstatesCreateManyUserInputEnvelope = {
  data: Array<UsersOnEstatesCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UsersOnEstatesCreateNestedManyWithoutEstateInput = {
  connect?: InputMaybe<Array<UsersOnEstatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UsersOnEstatesCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<UsersOnEstatesCreateWithoutEstateInput>>;
  createMany?: InputMaybe<UsersOnEstatesCreateManyEstateInputEnvelope>;
};

export type UsersOnEstatesCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UsersOnEstatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UsersOnEstatesCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<UsersOnEstatesCreateWithoutUserInput>>;
  createMany?: InputMaybe<UsersOnEstatesCreateManyUserInputEnvelope>;
};

export type UsersOnEstatesCreateNestedManyWithoutUserInput2 = {
  connect?: InputMaybe<Array<UsersOnEstatesWhereUniqueInput2>>;
  create?: InputMaybe<Array<UsersOnEstatesCreateWithoutUserInput2>>;
};

export type UsersOnEstatesCreateOrConnectWithoutEstateInput = {
  create: UsersOnEstatesCreateWithoutEstateInput;
  where: UsersOnEstatesWhereUniqueInput;
};

export type UsersOnEstatesCreateOrConnectWithoutUserInput = {
  create: UsersOnEstatesCreateWithoutUserInput;
  where: UsersOnEstatesWhereUniqueInput;
};

export type UsersOnEstatesCreateWithoutEstateInput = {
  boxUserRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  user: UserCreateNestedOneWithoutEstatesInput;
  userRole?: InputMaybe<UserEstateRole>;
};

export type UsersOnEstatesCreateWithoutUserInput = {
  boxUserRootFolderId?: InputMaybe<Scalars["String"]["input"]>;
  estate: EstateCreateNestedOneWithoutUsersInput;
  userRole?: InputMaybe<UserEstateRole>;
};

export type UsersOnEstatesCreateWithoutUserInput2 = {
  estate: EstateCreateNestedOneWithoutUsersInput2;
  userRole?: InputMaybe<UserEstateRole>;
};

export type UsersOnEstatesGroupBy = {
  __typename?: "UsersOnEstatesGroupBy";
  _count?: Maybe<UsersOnEstatesCountAggregate>;
  _max?: Maybe<UsersOnEstatesMaxAggregate>;
  _min?: Maybe<UsersOnEstatesMinAggregate>;
  boxUserRootFolderId?: Maybe<Scalars["String"]["output"]>;
  estateId: Scalars["String"]["output"];
  userId: Scalars["String"]["output"];
  userRole?: Maybe<UserEstateRole>;
};

export type UsersOnEstatesListRelationFilter = {
  every?: InputMaybe<UsersOnEstatesWhereInput>;
  none?: InputMaybe<UsersOnEstatesWhereInput>;
  some?: InputMaybe<UsersOnEstatesWhereInput>;
};

export type UsersOnEstatesMaxAggregate = {
  __typename?: "UsersOnEstatesMaxAggregate";
  boxUserRootFolderId?: Maybe<Scalars["String"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
  userRole?: Maybe<UserEstateRole>;
};

export type UsersOnEstatesMaxOrderByAggregateInput = {
  boxUserRootFolderId?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  userRole?: InputMaybe<SortOrder>;
};

export type UsersOnEstatesMinAggregate = {
  __typename?: "UsersOnEstatesMinAggregate";
  boxUserRootFolderId?: Maybe<Scalars["String"]["output"]>;
  estateId?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
  userRole?: Maybe<UserEstateRole>;
};

export type UsersOnEstatesMinOrderByAggregateInput = {
  boxUserRootFolderId?: InputMaybe<SortOrder>;
  estateId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  userRole?: InputMaybe<SortOrder>;
};

export type UsersOnEstatesOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UsersOnEstatesOrderByWithAggregationInput = {
  _count?: InputMaybe<UsersOnEstatesCountOrderByAggregateInput>;
  _max?: InputMaybe<UsersOnEstatesMaxOrderByAggregateInput>;
  _min?: InputMaybe<UsersOnEstatesMinOrderByAggregateInput>;
  boxUserRootFolderId?: InputMaybe<SortOrderInput>;
  estateId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  userRole?: InputMaybe<SortOrderInput>;
};

export type UsersOnEstatesOrderByWithRelationInput = {
  boxUserRootFolderId?: InputMaybe<SortOrderInput>;
  estate?: InputMaybe<EstateOrderByWithRelationInput>;
  estateId?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  userRole?: InputMaybe<SortOrderInput>;
};

export type UsersOnEstatesOrderByWithRelationInput2 = {
  estateId?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput2>;
  userId?: InputMaybe<SortOrder>;
  userRole?: InputMaybe<SortOrderInput>;
};

export enum UsersOnEstatesScalarFieldEnum {
  BoxUserRootFolderId = "boxUserRootFolderId",
  EstateId = "estateId",
  UserId = "userId",
  UserRole = "userRole",
}

export type UsersOnEstatesScalarWhereInput = {
  AND?: InputMaybe<Array<UsersOnEstatesScalarWhereInput>>;
  NOT?: InputMaybe<Array<UsersOnEstatesScalarWhereInput>>;
  OR?: InputMaybe<Array<UsersOnEstatesScalarWhereInput>>;
  boxUserRootFolderId?: InputMaybe<StringNullableFilter>;
  estateId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
  userRole?: InputMaybe<EnumUserEstateRoleNullableFilter>;
};

export type UsersOnEstatesScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UsersOnEstatesScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UsersOnEstatesScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UsersOnEstatesScalarWhereWithAggregatesInput>>;
  boxUserRootFolderId?: InputMaybe<StringNullableWithAggregatesFilter>;
  estateId?: InputMaybe<StringWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
  userRole?: InputMaybe<EnumUserEstateRoleNullableWithAggregatesFilter>;
};

export type UsersOnEstatesUpdateInput = {
  boxUserRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutUsersNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutEstatesNestedInput>;
  userRole?: InputMaybe<NullableEnumUserEstateRoleFieldUpdateOperationsInput>;
};

export type UsersOnEstatesUpdateManyMutationInput = {
  boxUserRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userRole?: InputMaybe<NullableEnumUserEstateRoleFieldUpdateOperationsInput>;
};

export type UsersOnEstatesUpdateManyWithWhereWithoutEstateInput = {
  data: UsersOnEstatesUpdateManyMutationInput;
  where: UsersOnEstatesScalarWhereInput;
};

export type UsersOnEstatesUpdateManyWithWhereWithoutUserInput = {
  data: UsersOnEstatesUpdateManyMutationInput;
  where: UsersOnEstatesScalarWhereInput;
};

export type UsersOnEstatesUpdateManyWithoutEstateNestedInput = {
  connect?: InputMaybe<Array<UsersOnEstatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UsersOnEstatesCreateOrConnectWithoutEstateInput>
  >;
  create?: InputMaybe<Array<UsersOnEstatesCreateWithoutEstateInput>>;
  createMany?: InputMaybe<UsersOnEstatesCreateManyEstateInputEnvelope>;
  delete?: InputMaybe<Array<UsersOnEstatesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UsersOnEstatesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UsersOnEstatesWhereUniqueInput>>;
  set?: InputMaybe<Array<UsersOnEstatesWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UsersOnEstatesUpdateWithWhereUniqueWithoutEstateInput>
  >;
  updateMany?: InputMaybe<
    Array<UsersOnEstatesUpdateManyWithWhereWithoutEstateInput>
  >;
  upsert?: InputMaybe<
    Array<UsersOnEstatesUpsertWithWhereUniqueWithoutEstateInput>
  >;
};

export type UsersOnEstatesUpdateManyWithoutEstateNestedInput2 = {
  connect?: InputMaybe<Array<UsersOnEstatesWhereUniqueInput2>>;
  delete?: InputMaybe<Array<UsersOnEstatesWhereUniqueInput2>>;
};

export type UsersOnEstatesUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UsersOnEstatesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UsersOnEstatesCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<UsersOnEstatesCreateWithoutUserInput>>;
  createMany?: InputMaybe<UsersOnEstatesCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UsersOnEstatesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UsersOnEstatesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UsersOnEstatesWhereUniqueInput>>;
  set?: InputMaybe<Array<UsersOnEstatesWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UsersOnEstatesUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<UsersOnEstatesUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<UsersOnEstatesUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type UsersOnEstatesUpdateManyWithoutUserNestedInput2 = {
  connect?: InputMaybe<Array<UsersOnEstatesWhereUniqueInput2>>;
  create?: InputMaybe<Array<UsersOnEstatesCreateWithoutUserInput2>>;
};

export type UsersOnEstatesUpdateWithWhereUniqueWithoutEstateInput = {
  data: UsersOnEstatesUpdateWithoutEstateInput;
  where: UsersOnEstatesWhereUniqueInput;
};

export type UsersOnEstatesUpdateWithWhereUniqueWithoutUserInput = {
  data: UsersOnEstatesUpdateWithoutUserInput;
  where: UsersOnEstatesWhereUniqueInput;
};

export type UsersOnEstatesUpdateWithoutEstateInput = {
  boxUserRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutEstatesNestedInput>;
  userRole?: InputMaybe<NullableEnumUserEstateRoleFieldUpdateOperationsInput>;
};

export type UsersOnEstatesUpdateWithoutUserInput = {
  boxUserRootFolderId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutUsersNestedInput>;
  userRole?: InputMaybe<NullableEnumUserEstateRoleFieldUpdateOperationsInput>;
};

export type UsersOnEstatesUpsertWithWhereUniqueWithoutEstateInput = {
  create: UsersOnEstatesCreateWithoutEstateInput;
  update: UsersOnEstatesUpdateWithoutEstateInput;
  where: UsersOnEstatesWhereUniqueInput;
};

export type UsersOnEstatesUpsertWithWhereUniqueWithoutUserInput = {
  create: UsersOnEstatesCreateWithoutUserInput;
  update: UsersOnEstatesUpdateWithoutUserInput;
  where: UsersOnEstatesWhereUniqueInput;
};

export type UsersOnEstatesUserIdEstateIdCompoundUniqueInput = {
  estateId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type UsersOnEstatesUserIdEstateIdCompoundUniqueInput2 = {
  estateId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type UsersOnEstatesWhereInput = {
  AND?: InputMaybe<Array<UsersOnEstatesWhereInput>>;
  NOT?: InputMaybe<Array<UsersOnEstatesWhereInput>>;
  OR?: InputMaybe<Array<UsersOnEstatesWhereInput>>;
  boxUserRootFolderId?: InputMaybe<StringNullableFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  userRole?: InputMaybe<EnumUserEstateRoleNullableFilter>;
};

export type UsersOnEstatesWhereInput2 = {
  AND?: InputMaybe<Array<UsersOnEstatesWhereInput2>>;
  NOT?: InputMaybe<Array<UsersOnEstatesWhereInput2>>;
  OR?: InputMaybe<Array<UsersOnEstatesWhereInput2>>;
  estateId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
  userRole?: InputMaybe<EnumUserEstateRoleNullableFilter>;
};

export type UsersOnEstatesWhereUniqueInput = {
  AND?: InputMaybe<Array<UsersOnEstatesWhereInput>>;
  NOT?: InputMaybe<Array<UsersOnEstatesWhereInput>>;
  OR?: InputMaybe<Array<UsersOnEstatesWhereInput>>;
  boxUserRootFolderId?: InputMaybe<StringNullableFilter>;
  estate?: InputMaybe<EstateRelationFilter>;
  estateId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  userId_estateId?: InputMaybe<UsersOnEstatesUserIdEstateIdCompoundUniqueInput>;
  userRole?: InputMaybe<EnumUserEstateRoleNullableFilter>;
};

export type UsersOnEstatesWhereUniqueInput2 = {
  userId_estateId?: InputMaybe<UsersOnEstatesUserIdEstateIdCompoundUniqueInput2>;
};

export type ValidatedEstateUpdateInput = {
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput2>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput2>;
  processInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput2>;
};

export type AdminCreateOneEstateWithDeceasedMutationVariables = Exact<{
  data: EstateCreateInput2;
}>;

export type AdminCreateOneEstateWithDeceasedMutation = {
  __typename?: "Mutation";
  adminCreateEstate: {
    __typename?: "Estate2";
    id: string;
    deceased?: {
      __typename?: "Deceased2";
      firstName: string;
      lastName: string;
      dateOfBirth?: any | null;
      dateOfDeath?: any | null;
      state?: string | null;
    } | null;
  };
};

export type AdminCreateUserWithEstateMutationVariables = Exact<{
  data: AdminCreateUserWithEstateInput;
}>;

export type AdminCreateUserWithEstateMutation = {
  __typename?: "Mutation";
  adminCreateUserWithEstate?: {
    __typename?: "User2";
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    id: string;
  } | null;
};

export type AuthenticateBoxUserMutationVariables = Exact<{
  estateId: Scalars["String"]["input"];
}>;

export type AuthenticateBoxUserMutation = {
  __typename?: "Mutation";
  authenticateBoxUser: {
    __typename?: "AuthenticateBoxUserResponse";
    accessToken: string;
    expiresAt: any;
    rootFolderId: string;
  };
};

export type CaptureLoginMutationVariables = Exact<{ [key: string]: never }>;

export type CaptureLoginMutation = {
  __typename?: "Mutation";
  captureLogin: boolean;
};

export type CaptureLoginFailureMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type CaptureLoginFailureMutation = {
  __typename?: "Mutation";
  captureLoginFailure: boolean;
};

export type CreateFolderMutationVariables = Exact<{
  data: CreateFolderInput;
}>;

export type CreateFolderMutation = {
  __typename?: "Mutation";
  createFolder: boolean;
};

export type CreateNotificationByGroupMutationVariables = Exact<{
  data: CreateNotificationByGroupInput;
}>;

export type CreateNotificationByGroupMutation = {
  __typename?: "Mutation";
  createNotificationByGroup?: {
    __typename?: "EstateNotification2";
    title: string;
    message: string;
  } | null;
};

export type CreateOneActionCardMutationVariables = Exact<{
  data: ActionCardCreateInput2;
}>;

export type CreateOneActionCardMutation = {
  __typename?: "Mutation";
  createOneActionCard: { __typename?: "ActionCard2"; id: string };
};

export type CreateOneComponentMutationVariables = Exact<{
  data: ComponentCreateInput2;
}>;

export type CreateOneComponentMutation = {
  __typename?: "Mutation";
  createOneComponent: {
    __typename?: "Component2";
    id: string;
    accountHolder?: string | null;
    accountNumber?: string | null;
    address?: string | null;
    appraisedValue?: any | null;
    balance?: any | null;
    billingPeriod?: string | null;
    charge?: any | null;
    city?: string | null;
    contactName?: string | null;
    contactType?: string | null;
    country?: string | null;
    createdById?: string | null;
    descriptor: string;
    email?: string | null;
    estateId: string;
    estimatedValue?: any | null;
    finalValue?: any | null;
    inTrust?: boolean | null;
    lastCheckDate?: any | null;
    lastPaymentAmount?: any | null;
    lastPaymentDate?: any | null;
    loanAmount?: any | null;
    mortgageBank?: string | null;
    name: string;
    paidOff?: boolean | null;
    phone?: string | null;
    state?: string | null;
    registrationExpiresAt?: any | null;
    status: ComponentStatus;
    subType: ComponentSubType;
    type: ComponentType;
    zip?: string | null;
  };
};

export type CreateOneContentMutationVariables = Exact<{
  data: ContentCreateInput2;
}>;

export type CreateOneContentMutation = {
  __typename?: "Mutation";
  createOneContent: { __typename?: "Content2"; id: string };
};

export type CreateOneDeceasedMutationVariables = Exact<{
  data: DeceasedCreateInput2;
}>;

export type CreateOneDeceasedMutation = {
  __typename?: "Mutation";
  createOneDeceased: {
    __typename?: "Deceased2";
    id: string;
    firstName: string;
    lastName: string;
    podState?: string | null;
  };
};

export type CreateUploadUrlMutationVariables = Exact<{
  destinationFolder?: InputMaybe<Scalars["String"]["input"]>;
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  fileName: Scalars["String"]["input"];
}>;

export type CreateUploadUrlMutation = {
  __typename?: "Mutation";
  createUploadUrl: string;
};

export type CreateUserCompletelyMutationVariables = Exact<{
  data: CreateUserInput;
}>;

export type CreateUserCompletelyMutation = {
  __typename?: "Mutation";
  createUserCompletely: {
    __typename?: "User2";
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  };
};

export type CreateUserCompletelyWithEstateMutationVariables = Exact<{
  data: CreateUserInput;
}>;

export type CreateUserCompletelyWithEstateMutation = {
  __typename?: "Mutation";
  createUserCompletelyWithEstate: {
    __typename?: "User2";
    id: string;
    email: string;
  };
};

export type CreateUserWithEstateMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateUserWithEstateMutation = {
  __typename?: "Mutation";
  createUserWithEstate?: {
    __typename?: "User2";
    id: string;
    active: boolean;
    forcePasswordReset: boolean;
    type: UserType;
    email: string;
    dateOfBirth?: any | null;
    firstName?: string | null;
    lastName?: string | null;
    address?: string | null;
    city?: string | null;
    county?: string | null;
    state?: string | null;
    country?: string | null;
    phone?: string | null;
    partnerId?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type CustomerAddDeceasedToEstateMutationVariables = Exact<{
  data: ValidatedEstateUpdateInput;
  where?: InputMaybe<EstateWhereUniqueInput2>;
}>;

export type CustomerAddDeceasedToEstateMutation = {
  __typename?: "Mutation";
  updateUserEstate?: {
    __typename?: "Estate2";
    id: string;
    deceased?: {
      __typename?: "Deceased2";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  } | null;
};

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteDocumentMutation = {
  __typename?: "Mutation";
  deleteDocument: boolean;
};

export type DeleteOneActionCardMutationVariables = Exact<{
  where: ActionCardWhereUniqueInput2;
}>;

export type DeleteOneActionCardMutation = {
  __typename?: "Mutation";
  deleteOneActionCard?: { __typename?: "ActionCard2"; id: string } | null;
};

export type DeleteOneComponentMutationVariables = Exact<{
  where: ComponentWhereUniqueInput2;
}>;

export type DeleteOneComponentMutation = {
  __typename?: "Mutation";
  deleteOneComponent?: { __typename?: "Component2"; id: string } | null;
};

export type DeleteOneContentMutationVariables = Exact<{
  where: ContentWhereUniqueInput2;
}>;

export type DeleteOneContentMutation = {
  __typename?: "Mutation";
  deleteOneContent?: { __typename?: "Content2"; id: string } | null;
};

export type DeleteUserNotificationsMutationVariables = Exact<{
  data: DeleteUserNotificationsInput;
}>;

export type DeleteUserNotificationsMutation = {
  __typename?: "Mutation";
  deleteUserNotifications: {
    __typename?: "DeleteUserNotificationsResult";
    estateId: string;
    estateNotificationId: string;
    message: string;
    title: string;
  };
};

export type MarkNotificationsSeenMutationVariables = Exact<{
  notificationIds:
    | Array<Scalars["String"]["input"]>
    | Scalars["String"]["input"];
  userId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type MarkNotificationsSeenMutation = {
  __typename?: "Mutation";
  markNotificationsSeen: boolean;
};

export type MoveDocumentsMutationVariables = Exact<{
  data: MoveDocumentsInput;
}>;

export type MoveDocumentsMutation = {
  __typename?: "Mutation";
  moveDocuments: boolean;
};

export type PrefillFormTemplateMutationVariables = Exact<{
  templateId: Scalars["String"]["input"];
  estateId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
}>;

export type PrefillFormTemplateMutation = {
  __typename?: "Mutation";
  prefillFormTemplate: {
    __typename?: "FormTemplateLink";
    createdAt: string;
    id: string;
    url: string;
  };
};

export type RenameDocumentMutationVariables = Exact<{
  targetKey: Scalars["String"]["input"];
  sourceKey: Scalars["String"]["input"];
  estateId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type RenameDocumentMutation = {
  __typename?: "Mutation";
  renameDocument: boolean;
};

export type RestoreDocumentMutationVariables = Exact<{
  deleteMarkerId: Scalars["String"]["input"];
  restoreDocumentId: Scalars["String"]["input"];
}>;

export type RestoreDocumentMutation = {
  __typename?: "Mutation";
  restoreDocument: boolean;
};

export type SignUpUserMutationVariables = Exact<{
  data: SignUpUserInput;
}>;

export type SignUpUserMutation = {
  __typename?: "Mutation";
  signUpUser: { __typename?: "User2"; id: string; email: string };
};

export type StartActionCardMutationVariables = Exact<{
  startActionCardId: Scalars["String"]["input"];
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  reply?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type StartActionCardMutation = {
  __typename?: "Mutation";
  startActionCard: {
    __typename?: "ActionCard2";
    id: string;
    estateId: string;
    componentId?: string | null;
    estateDetailId?: string | null;
    type: ActionCardType;
    status: ActionCardStatus;
    name: string;
    description?: string | null;
    assignee?: AssigneeType | null;
    formFieldName?: string | null;
    encryptedReply: boolean;
    appointmentTime?: any | null;
    appointmentLocation?: string | null;
    calendarIcs?: string | null;
    calendarLink?: string | null;
    documentDownloadUrl?: string | null;
    documentUploadUrl?: string | null;
    documentSignUrl?: string | null;
    mapUrl?: string | null;
    promoted: boolean;
    createdAt: any;
    updatedAt: any;
    estateDetail?: {
      __typename?: "EstateDetail2";
      encrypted: boolean;
      value: any;
    } | null;
  };
};

export type TrackClickMutationVariables = Exact<{
  element: Scalars["String"]["input"];
  estateId: Scalars["String"]["input"];
}>;

export type TrackClickMutation = {
  __typename?: "Mutation";
  trackClick: boolean;
};

export type TrackPageViewMutationVariables = Exact<{
  page: Scalars["String"]["input"];
  estateId: Scalars["String"]["input"];
}>;

export type TrackPageViewMutation = {
  __typename?: "Mutation";
  trackPageView: boolean;
};

export type TrackScrollMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  status?: InputMaybe<Scalars["String"]["input"]>;
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  elementId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type TrackScrollMutation = {
  __typename?: "Mutation";
  trackScroll: boolean;
};

export type TrackSectionOpenedMutationVariables = Exact<{
  section: Scalars["String"]["input"];
  estateId: Scalars["String"]["input"];
}>;

export type TrackSectionOpenedMutation = {
  __typename?: "Mutation";
  trackSectionOpened: boolean;
};

export type UpdateOneActionCardMutationVariables = Exact<{
  data: ActionCardUpdateInput2;
  where: ActionCardWhereUniqueInput2;
}>;

export type UpdateOneActionCardMutation = {
  __typename?: "Mutation";
  updateOneActionCard?: { __typename?: "ActionCard2"; id: string } | null;
};

export type UpdateOneComponentMutationVariables = Exact<{
  data: ComponentUpdateInput2;
  where: ComponentWhereUniqueInput2;
}>;

export type UpdateOneComponentMutation = {
  __typename?: "Mutation";
  updateOneComponent?: { __typename?: "Component2"; id: string } | null;
};

export type UpdateOneContentMutationVariables = Exact<{
  data: ContentUpdateInput2;
  where: ContentWhereUniqueInput2;
}>;

export type UpdateOneContentMutation = {
  __typename?: "Mutation";
  updateOneContent?: {
    __typename?: "Content2";
    id: string;
    header: string;
    status: ContentStatus;
    completeBy: any;
    completedAt?: any | null;
  } | null;
};

export type UpdateOneDeceasedMutationVariables = Exact<{
  data: DeceasedUpdateInput2;
  where: DeceasedWhereUniqueInput2;
}>;

export type UpdateOneDeceasedMutation = {
  __typename?: "Mutation";
  updateOneDeceased?: {
    __typename?: "Deceased2";
    id: string;
    firstName: string;
    lastName: string;
    podState?: string | null;
    state?: string | null;
    dateOfBirth?: any | null;
    dateOfDeath?: any | null;
  } | null;
};

export type UpdateOneEstateMutationVariables = Exact<{
  data: EstateUpdateInput2;
  where: EstateWhereUniqueInput2;
}>;

export type UpdateOneEstateMutation = {
  __typename?: "Mutation";
  updateOneEstate?: { __typename?: "Estate2"; id: string } | null;
};

export type UpdateOneUserMutationVariables = Exact<{
  data: UserUpdateInput2;
  where: UserWhereUniqueInput2;
}>;

export type UpdateOneUserMutation = {
  __typename?: "Mutation";
  updateOneUser?: {
    __typename?: "User2";
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
};

export type AdminGetUsersOnEstateQueryVariables = Exact<{
  where: EstateWhereUniqueInput2;
  orderBy?: InputMaybe<
    | Array<UsersOnEstatesOrderByWithRelationInput2>
    | UsersOnEstatesOrderByWithRelationInput2
  >;
}>;

export type AdminGetUsersOnEstateQuery = {
  __typename?: "Query";
  estate?: {
    __typename?: "Estate2";
    users: Array<{
      __typename?: "UsersOnEstates2";
      userRole?: UserEstateRole | null;
      user: {
        __typename?: "User2";
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      };
    }>;
  } | null;
};

export type FormTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type FormTemplatesQuery = {
  __typename?: "Query";
  formTemplates: Array<{
    __typename?: "FormTemplate";
    id: string;
    title: string;
  }>;
};

export type GetActionCardQueryVariables = Exact<{
  where: ActionCardWhereUniqueInput2;
}>;

export type GetActionCardQuery = {
  __typename?: "Query";
  actionCard?: {
    __typename?: "ActionCard2";
    id: string;
    estateId: string;
    componentId?: string | null;
    estateDetailId?: string | null;
    type: ActionCardType;
    status: ActionCardStatus;
    name: string;
    description?: string | null;
    assignee?: AssigneeType | null;
    formFieldName?: string | null;
    encryptedReply: boolean;
    appointmentTime?: any | null;
    appointmentLocation?: string | null;
    calendarIcs?: string | null;
    calendarLink?: string | null;
    documentDownloadUrl?: string | null;
    documentUploadUrl?: string | null;
    documentSignUrl?: string | null;
    mapUrl?: string | null;
    promoted: boolean;
    createdAt: any;
    updatedAt: any;
    estateDetail?: {
      __typename?: "EstateDetail2";
      value: any;
      id: string;
      decryptedValue?: any | null;
    } | null;
  } | null;
};

export type GetAssetsByEstateIdQueryVariables = Exact<{
  estateId: Scalars["String"]["input"];
}>;

export type GetAssetsByEstateIdQuery = {
  __typename?: "Query";
  getAssetsByEstateId: Array<{
    __typename?: "Asset2";
    status?: AssetStatus | null;
    probateJurisdiction?: string | null;
    probateEstate?: string | null;
    inTrust: boolean;
    id: string;
    finalValue?: any | null;
    estimatedValue?: any | null;
    estateId: string;
    dodValue?: any | null;
    description?: string | null;
    assetCategory: AssetCategory;
    assetAccount?: {
      __typename?: "AssetAccount2";
      accountNumber?: string | null;
      assetSubcategory?: AssetSubCategory | null;
      institution?: string | null;
    } | null;
  }>;
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  __typename?: "Query";
  getCurrentUser?: {
    __typename?: "User2";
    id: string;
    forcePasswordReset: boolean;
    active: boolean;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    avatar?: string | null;
  } | null;
};

export type GetEstateComponentQueryVariables = Exact<{
  componentId: Scalars["String"]["input"];
  orderBy?: InputMaybe<
    Array<ContentOrderByWithRelationInput2> | ContentOrderByWithRelationInput2
  >;
}>;

export type GetEstateComponentQuery = {
  __typename?: "Query";
  getEstateComponent?: {
    __typename?: "Component2";
    id: string;
    name: string;
    type: ComponentType;
    subType: ComponentSubType;
    descriptor: string;
    contents: Array<{
      __typename?: "Content2";
      id: string;
      header: string;
      body: string;
      status: ContentStatus;
      displayDate: string;
      completeBy: any;
      completeByType: ContentCompleteByType;
      completedAt?: any | null;
      user?: {
        __typename?: "User2";
        firstName?: string | null;
        lastName?: string | null;
        avatar?: string | null;
      } | null;
    }>;
    actionCards: Array<{
      __typename?: "ActionCard2";
      id: string;
      name: string;
      status: ActionCardStatus;
      type: ActionCardType;
    }>;
    notifications: Array<{
      __typename?: "EstateNotification2";
      id: string;
      title: string;
      message: string;
    }>;
  } | null;
};

export type GetEstateComponentSummaryQueryVariables = Exact<{
  componentId: Scalars["String"]["input"];
}>;

export type GetEstateComponentSummaryQuery = {
  __typename?: "Query";
  getEstateComponent?: {
    __typename?: "Component2";
    id: string;
    accountHolder?: string | null;
    accountNumber?: string | null;
    address?: string | null;
    city?: string | null;
    country?: string | null;
    descriptor: string;
    estimatedValue?: any | null;
    finalValue?: any | null;
    name: string;
    state?: string | null;
    status: ComponentStatus;
    subType: ComponentSubType;
    type: ComponentType;
    zip?: string | null;
  } | null;
};

export type GetEstateDistributionBreakdownQueryVariables = Exact<{
  estateId: Scalars["String"]["input"];
}>;

export type GetEstateDistributionBreakdownQuery = {
  __typename?: "Query";
  estateDistributionBreakdown?: {
    __typename?: "EstateDistributionBreakdown";
    debts: any;
    totalValue: any;
    estimatedDistribution: any;
    feeCalculationInfo?: {
      __typename?: "Info";
      message: string;
      title: string;
    } | null;
    distributionInfo?: {
      __typename?: "Info";
      message: string;
      title: string;
    } | null;
    alixFee: {
      __typename?: "AlixFeeDisplay";
      amount: any;
      status: string;
      updatedAt: any;
      type: AlixFeeType2;
    };
  } | null;
};

export type GetEstateNotificationsQueryVariables = Exact<{
  estateId: Scalars["String"]["input"];
}>;

export type GetEstateNotificationsQuery = {
  __typename?: "Query";
  getEstateNotifications?: Array<{
    __typename?: "EstateNotification2";
    componentId?: string | null;
    createdAt: any;
    estateId?: string | null;
    id: string;
    message: string;
    title: string;
    updatedAt: any;
    userNotifications: Array<{
      __typename?: "UserNotification2";
      userId: string;
      seenAt?: any | null;
      user?: {
        __typename?: "User2";
        firstName?: string | null;
        lastName?: string | null;
        estates: Array<{
          __typename?: "UsersOnEstates2";
          userRole?: UserEstateRole | null;
        }>;
      } | null;
    }>;
  }> | null;
};

export type GetEstatePieDataQueryVariables = Exact<{
  estateId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetEstatePieDataQuery = {
  __typename?: "Query";
  getUserEstate?: {
    __typename?: "Estate2";
    id: string;
    componentBreakDown?: Array<{
      __typename?: "ComponentBreakDown";
      type: string;
      subType: string;
      sum: any;
      percentage: any;
      count: any;
      total: any;
    }> | null;
  } | null;
};

export type GetKeyDatesQueryVariables = Exact<{
  estateId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetKeyDatesQuery = {
  __typename?: "Query";
  getUserEstate?: {
    __typename?: "Estate2";
    keyDates: Array<{
      __typename?: "Content2";
      id: string;
      header: string;
      body: string;
      status: ContentStatus;
      displayDate: string;
      componentId?: string | null;
      completeBy: any;
      completeByType: ContentCompleteByType;
      completedAt?: any | null;
      user?: {
        __typename?: "User2";
        firstName?: string | null;
        lastName?: string | null;
        avatar?: string | null;
      } | null;
    }>;
  } | null;
};

export type GetMostRecentEulaQueryVariables = Exact<{ [key: string]: never }>;

export type GetMostRecentEulaQuery = {
  __typename?: "Query";
  getMostRecentEULA?: {
    __typename?: "EULA2";
    id: string;
    version: string;
    pdfLink?: string | null;
  } | null;
};

export type GetNotificationLogQueryVariables = Exact<{
  estateId: Scalars["String"]["input"];
}>;

export type GetNotificationLogQuery = {
  __typename?: "Query";
  getNotificationLog: Array<{
    __typename?: "NotificationLogEntry";
    estateId?: string | null;
    id: string;
    title: string;
    message: string;
    createdAt: any;
    updatedAt: any;
    actionCardId?: string | null;
    componentId?: string | null;
    contentId?: string | null;
    route?: string | null;
    notificationReceipts: Array<{
      __typename?: "NotificationReceipt";
      createdAt: any;
      notificationId: string;
      seenAt?: any | null;
      user?: {
        __typename?: "NotificationUser";
        userId: string;
        firstName: string;
        lastName: string;
      } | null;
    }>;
    routingError?: {
      __typename?: "RoutingError";
      message?: string | null;
    } | null;
  }>;
};

export type GetOneContentQueryVariables = Exact<{
  where: ContentWhereUniqueInput2;
}>;

export type GetOneContentQuery = {
  __typename?: "Query";
  getContent?: {
    __typename?: "Content2";
    id: string;
    completeByType: ContentCompleteByType;
    completeBy: any;
    displayDate: string;
    body: string;
    componentId?: string | null;
    header: string;
    status: ContentStatus;
    userId?: string | null;
    type?: ContentType | null;
    user?: {
      __typename?: "User2";
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: string | null;
    } | null;
  } | null;
};

export type GetRecentActivityQueryVariables = Exact<{
  estateId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetRecentActivityQuery = {
  __typename?: "Query";
  getUserEstate?: {
    __typename?: "Estate2";
    recentActivity: Array<{
      __typename?: "Content2";
      id: string;
      header: string;
      body: string;
      status: ContentStatus;
      displayDate: string;
      componentId?: string | null;
      completeBy: any;
      completeByType: ContentCompleteByType;
      completedAt?: any | null;
      type?: ContentType | null;
      user?: {
        __typename?: "User2";
        firstName?: string | null;
        lastName?: string | null;
        avatar?: string | null;
      } | null;
    }>;
  } | null;
};

export type GetSimpleEstateListQueryVariables = Exact<{
  where?: InputMaybe<UsersOnEstatesWhereInput2>;
}>;

export type GetSimpleEstateListQuery = {
  __typename?: "Query";
  getSimpleEstateList: Array<{
    __typename?: "Estate2";
    id: string;
    deceased?: {
      __typename?: "Deceased2";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
    users: Array<{
      __typename?: "UsersOnEstates2";
      user: {
        __typename?: "User2";
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      };
    }>;
  }>;
};

export type GetUnseenNotificationsCountQueryVariables = Exact<{
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetUnseenNotificationsCountQuery = {
  __typename?: "Query";
  getUnseenNotificationsCount: number;
};

export type GetUserQueryVariables = Exact<{
  where: UserWhereUniqueInput2;
}>;

export type GetUserQuery = {
  __typename?: "Query";
  getUser?: {
    __typename?: "User2";
    id: string;
    active: boolean;
    type: UserType;
    email: string;
    dateOfBirth?: any | null;
    firstName?: string | null;
    lastName?: string | null;
    address?: string | null;
    city?: string | null;
    county?: string | null;
    state?: string | null;
    country?: string | null;
    phone?: string | null;
    partnerId?: string | null;
    ssnLast4?: string | null;
    fullSsn?: string | null;
    createdAt: any;
  } | null;
};

export type GetUserActionCardQueryVariables = Exact<{
  where?: InputMaybe<ActionCardWhereInput2>;
  estateId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetUserActionCardQuery = {
  __typename?: "Query";
  getUserEstate?: {
    __typename?: "Estate2";
    actionCards: Array<{
      __typename?: "ActionCard2";
      id: string;
      estateId: string;
      componentId?: string | null;
      completedAt?: any | null;
      estateDetailId?: string | null;
      taskId?: string | null;
      type: ActionCardType;
      status: ActionCardStatus;
      name: string;
      description?: string | null;
      encryptedReply: boolean;
      locked: boolean;
      assignee?: AssigneeType | null;
      formFieldName?: string | null;
      appointmentTime?: any | null;
      appointmentLocation?: string | null;
      calendarIcs?: string | null;
      calendarLink?: string | null;
      documentDownloadUrl?: string | null;
      documentUploadUrl?: string | null;
      documentSignUrl?: string | null;
      mapUrl?: string | null;
      createdAt: any;
      updatedAt: any;
      component?: {
        __typename?: "Component2";
        id: string;
        name: string;
        subType: ComponentSubType;
        type: ComponentType;
        accountNumber?: string | null;
        descriptor: string;
      } | null;
      task?: {
        __typename?: "Task2";
        name: string;
        shortName?: string | null;
      } | null;
      estateDetail?: {
        __typename?: "EstateDetail2";
        id: string;
        name: string;
        updatedAt: any;
        value: any;
      } | null;
    }>;
  } | null;
};

export type GetUserEstateActionCardsQueryVariables = Exact<{
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  where?: InputMaybe<ActionCardWhereInput2>;
  orderBy?: InputMaybe<
    | Array<ActionCardOrderByWithRelationInput2>
    | ActionCardOrderByWithRelationInput2
  >;
}>;

export type GetUserEstateActionCardsQuery = {
  __typename?: "Query";
  getUserEstate?: {
    __typename?: "Estate2";
    id: string;
    deceased?: {
      __typename?: "Deceased2";
      id: string;
      firstName: string;
      lastName: string;
      podState?: string | null;
      state?: string | null;
      dateOfBirth?: any | null;
      dateOfDeath?: any | null;
    } | null;
    componentBreakDown?: Array<{
      __typename?: "ComponentBreakDown";
      type: string;
      subType: string;
      sum: any;
      percentage: any;
      count: any;
      total: any;
    }> | null;
    actionCards: Array<{
      __typename?: "ActionCard2";
      id: string;
      estateId: string;
      componentId?: string | null;
      completedAt?: any | null;
      estateDetailId?: string | null;
      type: ActionCardType;
      status: ActionCardStatus;
      name: string;
      description?: string | null;
      encryptedReply: boolean;
      locked: boolean;
      assignee?: AssigneeType | null;
      formFieldName?: string | null;
      appointmentTime?: any | null;
      appointmentLocation?: string | null;
      calendarIcs?: string | null;
      calendarLink?: string | null;
      documentDownloadUrl?: string | null;
      documentUploadUrl?: string | null;
      documentSignUrl?: string | null;
      mapUrl?: string | null;
      createdAt: any;
      updatedAt: any;
      component?: {
        __typename?: "Component2";
        id: string;
        name: string;
        subType: ComponentSubType;
        type: ComponentType;
        accountNumber?: string | null;
        descriptor: string;
      } | null;
      task?: {
        __typename?: "Task2";
        name: string;
        shortName?: string | null;
      } | null;
      estateDetail?: {
        __typename?: "EstateDetail2";
        id: string;
        name: string;
        updatedAt: any;
        value: any;
      } | null;
    }>;
  } | null;
};

export type GetUserEstateIdQueryVariables = Exact<{
  estateId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetUserEstateIdQuery = {
  __typename?: "Query";
  getUserEstate?: { __typename?: "Estate2"; id: string } | null;
};

export type GetUserEstateNotificationsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars["String"]["input"]>;
  estateId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetUserEstateNotificationsQuery = {
  __typename?: "Query";
  getUserEstateNotifications?: Array<{
    __typename?: "UserEstateNotification";
    createdAt: any;
    estateId: string;
    id: string;
    message: string;
    route?: string | null;
    seenAt?: any | null;
    title: string;
    updatedAt: any;
    userId: string;
    routingError?: {
      __typename?: "RoutingError";
      message?: string | null;
    } | null;
  }> | null;
};

export type GetUserEstateQueryVariables = Exact<{
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  where?: InputMaybe<UsersOnEstatesWhereInput2>;
}>;

export type GetUserEstateQuery = {
  __typename?: "Query";
  getUserEstate?: {
    __typename?: "Estate2";
    id: string;
    hasProbate?: boolean | null;
    hasTrust?: boolean | null;
    hasWill?: boolean | null;
    deceased?: {
      __typename?: "Deceased2";
      id: string;
      firstName: string;
      lastName: string;
      podState?: string | null;
      state?: string | null;
      dateOfBirth?: any | null;
      dateOfDeath?: any | null;
    } | null;
    estateComponents: Array<{
      __typename?: "Component2";
      id: string;
      estateId: string;
      type: ComponentType;
      subType: ComponentSubType;
      name: string;
      descriptor: string;
      finalValue?: any | null;
      estimatedValue?: any | null;
      appraisedValue?: any | null;
      loanAmount?: any | null;
      mortgageBank?: string | null;
      accountNumber?: string | null;
      accountHolder?: string | null;
      address?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      country?: string | null;
      contactName?: string | null;
      contactType?: string | null;
      phone?: string | null;
      email?: string | null;
      balance?: any | null;
      lastCheckDate?: any | null;
      inTrust?: boolean | null;
      createdById?: string | null;
      status: ComponentStatus;
      completedAt?: any | null;
      registrationExpiresAt?: any | null;
      paidOff?: boolean | null;
      lastPaymentAmount?: any | null;
      lastPaymentDate?: any | null;
      billingPeriod?: string | null;
      charge?: any | null;
    }>;
    componentBreakDown?: Array<{
      __typename?: "ComponentBreakDown";
      type: string;
      subType: string;
      sum: any;
      percentage: any;
      count: any;
      total: any;
    }> | null;
    users: Array<{
      __typename?: "UsersOnEstates2";
      user: {
        __typename?: "User2";
        firstName?: string | null;
        lastName?: string | null;
        id: string;
      };
    }>;
  } | null;
};

export type GetUsersListQueryVariables = Exact<{
  orderBy?: InputMaybe<
    Array<UserOrderByWithRelationInput2> | UserOrderByWithRelationInput2
  >;
  where?: InputMaybe<UserWhereInput2>;
}>;

export type GetUsersListQuery = {
  __typename?: "Query";
  users: Array<{
    __typename?: "User2";
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    address?: string | null;
    avatar?: string | null;
  }>;
};

export type IsEulaCurrentQueryVariables = Exact<{ [key: string]: never }>;

export type IsEulaCurrentQuery = {
  __typename?: "Query";
  isEULAcurrent: boolean;
};

export type ListDeletedDocumentsQueryVariables = Exact<{
  estateId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ListDeletedDocumentsQuery = {
  __typename?: "Query";
  listDeletedDocuments: Array<{
    __typename?: "EstateDocument";
    id: string;
    isDir: boolean;
    modDate: any;
    name: string;
    signedUrl?: string | null;
    deleteMarkerId?: string | null;
  }>;
};

export type ListDocumentsQueryVariables = Exact<{
  estateId?: InputMaybe<Scalars["String"]["input"]>;
  directory?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ListDocumentsQuery = {
  __typename?: "Query";
  listDocuments: Array<{
    __typename?: "EstateDocument";
    id: string;
    isDir: boolean;
    modDate: any;
    name: string;
    size: number;
    signedUrl?: string | null;
    uploadedAt?: any | null;
    contentType?: string | null;
  }>;
};

export const AdminCreateOneEstateWithDeceasedDocument = gql`
  mutation AdminCreateOneEstateWithDeceased($data: EstateCreateInput2!) {
    adminCreateEstate(data: $data) {
      id
      deceased {
        firstName
        lastName
        dateOfBirth
        dateOfDeath
        state
      }
    }
  }
`;
export type AdminCreateOneEstateWithDeceasedMutationFn =
  Apollo.MutationFunction<
    AdminCreateOneEstateWithDeceasedMutation,
    AdminCreateOneEstateWithDeceasedMutationVariables
  >;

/**
 * __useAdminCreateOneEstateWithDeceasedMutation__
 *
 * To run a mutation, you first call `useAdminCreateOneEstateWithDeceasedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateOneEstateWithDeceasedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateOneEstateWithDeceasedMutation, { data, loading, error }] = useAdminCreateOneEstateWithDeceasedMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminCreateOneEstateWithDeceasedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateOneEstateWithDeceasedMutation,
    AdminCreateOneEstateWithDeceasedMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    AdminCreateOneEstateWithDeceasedMutation,
    AdminCreateOneEstateWithDeceasedMutationVariables
  >(AdminCreateOneEstateWithDeceasedDocument, options);
}
export type AdminCreateOneEstateWithDeceasedMutationHookResult = ReturnType<
  typeof useAdminCreateOneEstateWithDeceasedMutation
>;
export type AdminCreateOneEstateWithDeceasedMutationResult =
  Apollo.MutationResult<AdminCreateOneEstateWithDeceasedMutation>;
export type AdminCreateOneEstateWithDeceasedMutationOptions =
  Apollo.BaseMutationOptions<
    AdminCreateOneEstateWithDeceasedMutation,
    AdminCreateOneEstateWithDeceasedMutationVariables
  >;
export const AdminCreateUserWithEstateDocument = gql`
  mutation AdminCreateUserWithEstate($data: AdminCreateUserWithEstateInput!) {
    adminCreateUserWithEstate(data: $data) {
      email
      firstName
      lastName
      id
    }
  }
`;
export type AdminCreateUserWithEstateMutationFn = Apollo.MutationFunction<
  AdminCreateUserWithEstateMutation,
  AdminCreateUserWithEstateMutationVariables
>;

/**
 * __useAdminCreateUserWithEstateMutation__
 *
 * To run a mutation, you first call `useAdminCreateUserWithEstateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateUserWithEstateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateUserWithEstateMutation, { data, loading, error }] = useAdminCreateUserWithEstateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminCreateUserWithEstateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateUserWithEstateMutation,
    AdminCreateUserWithEstateMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    AdminCreateUserWithEstateMutation,
    AdminCreateUserWithEstateMutationVariables
  >(AdminCreateUserWithEstateDocument, options);
}
export type AdminCreateUserWithEstateMutationHookResult = ReturnType<
  typeof useAdminCreateUserWithEstateMutation
>;
export type AdminCreateUserWithEstateMutationResult =
  Apollo.MutationResult<AdminCreateUserWithEstateMutation>;
export type AdminCreateUserWithEstateMutationOptions =
  Apollo.BaseMutationOptions<
    AdminCreateUserWithEstateMutation,
    AdminCreateUserWithEstateMutationVariables
  >;
export const AuthenticateBoxUserDocument = gql`
  mutation AuthenticateBoxUser($estateId: String!) {
    authenticateBoxUser(estateId: $estateId) {
      accessToken
      expiresAt
      rootFolderId
    }
  }
`;
export type AuthenticateBoxUserMutationFn = Apollo.MutationFunction<
  AuthenticateBoxUserMutation,
  AuthenticateBoxUserMutationVariables
>;

/**
 * __useAuthenticateBoxUserMutation__
 *
 * To run a mutation, you first call `useAuthenticateBoxUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateBoxUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateBoxUserMutation, { data, loading, error }] = useAuthenticateBoxUserMutation({
 *   variables: {
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useAuthenticateBoxUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthenticateBoxUserMutation,
    AuthenticateBoxUserMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    AuthenticateBoxUserMutation,
    AuthenticateBoxUserMutationVariables
  >(AuthenticateBoxUserDocument, options);
}
export type AuthenticateBoxUserMutationHookResult = ReturnType<
  typeof useAuthenticateBoxUserMutation
>;
export type AuthenticateBoxUserMutationResult =
  Apollo.MutationResult<AuthenticateBoxUserMutation>;
export type AuthenticateBoxUserMutationOptions = Apollo.BaseMutationOptions<
  AuthenticateBoxUserMutation,
  AuthenticateBoxUserMutationVariables
>;
export const CaptureLoginDocument = gql`
  mutation CaptureLogin {
    captureLogin
  }
`;
export type CaptureLoginMutationFn = Apollo.MutationFunction<
  CaptureLoginMutation,
  CaptureLoginMutationVariables
>;

/**
 * __useCaptureLoginMutation__
 *
 * To run a mutation, you first call `useCaptureLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaptureLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [captureLoginMutation, { data, loading, error }] = useCaptureLoginMutation({
 *   variables: {
 *   },
 * });
 */
export function useCaptureLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CaptureLoginMutation,
    CaptureLoginMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    CaptureLoginMutation,
    CaptureLoginMutationVariables
  >(CaptureLoginDocument, options);
}
export type CaptureLoginMutationHookResult = ReturnType<
  typeof useCaptureLoginMutation
>;
export type CaptureLoginMutationResult =
  Apollo.MutationResult<CaptureLoginMutation>;
export type CaptureLoginMutationOptions = Apollo.BaseMutationOptions<
  CaptureLoginMutation,
  CaptureLoginMutationVariables
>;
export const CaptureLoginFailureDocument = gql`
  mutation CaptureLoginFailure($email: String!) {
    captureLoginFailure(email: $email)
  }
`;
export type CaptureLoginFailureMutationFn = Apollo.MutationFunction<
  CaptureLoginFailureMutation,
  CaptureLoginFailureMutationVariables
>;

/**
 * __useCaptureLoginFailureMutation__
 *
 * To run a mutation, you first call `useCaptureLoginFailureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaptureLoginFailureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [captureLoginFailureMutation, { data, loading, error }] = useCaptureLoginFailureMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCaptureLoginFailureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CaptureLoginFailureMutation,
    CaptureLoginFailureMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    CaptureLoginFailureMutation,
    CaptureLoginFailureMutationVariables
  >(CaptureLoginFailureDocument, options);
}
export type CaptureLoginFailureMutationHookResult = ReturnType<
  typeof useCaptureLoginFailureMutation
>;
export type CaptureLoginFailureMutationResult =
  Apollo.MutationResult<CaptureLoginFailureMutation>;
export type CaptureLoginFailureMutationOptions = Apollo.BaseMutationOptions<
  CaptureLoginFailureMutation,
  CaptureLoginFailureMutationVariables
>;
export const CreateFolderDocument = gql`
  mutation CreateFolder($data: CreateFolderInput!) {
    createFolder(data: $data)
  }
`;
export type CreateFolderMutationFn = Apollo.MutationFunction<
  CreateFolderMutation,
  CreateFolderMutationVariables
>;

/**
 * __useCreateFolderMutation__
 *
 * To run a mutation, you first call `useCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderMutation, { data, loading, error }] = useCreateFolderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFolderMutation,
    CreateFolderMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    CreateFolderMutation,
    CreateFolderMutationVariables
  >(CreateFolderDocument, options);
}
export type CreateFolderMutationHookResult = ReturnType<
  typeof useCreateFolderMutation
>;
export type CreateFolderMutationResult =
  Apollo.MutationResult<CreateFolderMutation>;
export type CreateFolderMutationOptions = Apollo.BaseMutationOptions<
  CreateFolderMutation,
  CreateFolderMutationVariables
>;
export const CreateNotificationByGroupDocument = gql`
  mutation CreateNotificationByGroup($data: CreateNotificationByGroupInput!) {
    createNotificationByGroup(data: $data) {
      title
      message
    }
  }
`;
export type CreateNotificationByGroupMutationFn = Apollo.MutationFunction<
  CreateNotificationByGroupMutation,
  CreateNotificationByGroupMutationVariables
>;

/**
 * __useCreateNotificationByGroupMutation__
 *
 * To run a mutation, you first call `useCreateNotificationByGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationByGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationByGroupMutation, { data, loading, error }] = useCreateNotificationByGroupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNotificationByGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNotificationByGroupMutation,
    CreateNotificationByGroupMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    CreateNotificationByGroupMutation,
    CreateNotificationByGroupMutationVariables
  >(CreateNotificationByGroupDocument, options);
}
export type CreateNotificationByGroupMutationHookResult = ReturnType<
  typeof useCreateNotificationByGroupMutation
>;
export type CreateNotificationByGroupMutationResult =
  Apollo.MutationResult<CreateNotificationByGroupMutation>;
export type CreateNotificationByGroupMutationOptions =
  Apollo.BaseMutationOptions<
    CreateNotificationByGroupMutation,
    CreateNotificationByGroupMutationVariables
  >;
export const CreateOneActionCardDocument = gql`
  mutation CreateOneActionCard($data: ActionCardCreateInput2!) {
    createOneActionCard(data: $data) {
      id
    }
  }
`;
export type CreateOneActionCardMutationFn = Apollo.MutationFunction<
  CreateOneActionCardMutation,
  CreateOneActionCardMutationVariables
>;

/**
 * __useCreateOneActionCardMutation__
 *
 * To run a mutation, you first call `useCreateOneActionCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneActionCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneActionCardMutation, { data, loading, error }] = useCreateOneActionCardMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneActionCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneActionCardMutation,
    CreateOneActionCardMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    CreateOneActionCardMutation,
    CreateOneActionCardMutationVariables
  >(CreateOneActionCardDocument, options);
}
export type CreateOneActionCardMutationHookResult = ReturnType<
  typeof useCreateOneActionCardMutation
>;
export type CreateOneActionCardMutationResult =
  Apollo.MutationResult<CreateOneActionCardMutation>;
export type CreateOneActionCardMutationOptions = Apollo.BaseMutationOptions<
  CreateOneActionCardMutation,
  CreateOneActionCardMutationVariables
>;
export const CreateOneComponentDocument = gql`
  mutation CreateOneComponent($data: ComponentCreateInput2!) {
    createOneComponent(data: $data) {
      id
      accountHolder
      accountNumber
      address
      appraisedValue
      balance
      billingPeriod
      charge
      city
      contactName
      contactType
      country
      createdById
      descriptor
      email
      estateId
      estimatedValue
      finalValue
      inTrust
      lastCheckDate
      lastPaymentAmount
      lastPaymentDate
      loanAmount
      mortgageBank
      name
      paidOff
      phone
      state
      registrationExpiresAt
      status
      subType
      type
      zip
    }
  }
`;
export type CreateOneComponentMutationFn = Apollo.MutationFunction<
  CreateOneComponentMutation,
  CreateOneComponentMutationVariables
>;

/**
 * __useCreateOneComponentMutation__
 *
 * To run a mutation, you first call `useCreateOneComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneComponentMutation, { data, loading, error }] = useCreateOneComponentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneComponentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneComponentMutation,
    CreateOneComponentMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    CreateOneComponentMutation,
    CreateOneComponentMutationVariables
  >(CreateOneComponentDocument, options);
}
export type CreateOneComponentMutationHookResult = ReturnType<
  typeof useCreateOneComponentMutation
>;
export type CreateOneComponentMutationResult =
  Apollo.MutationResult<CreateOneComponentMutation>;
export type CreateOneComponentMutationOptions = Apollo.BaseMutationOptions<
  CreateOneComponentMutation,
  CreateOneComponentMutationVariables
>;
export const CreateOneContentDocument = gql`
  mutation CreateOneContent($data: ContentCreateInput2!) {
    createOneContent(data: $data) {
      id
    }
  }
`;
export type CreateOneContentMutationFn = Apollo.MutationFunction<
  CreateOneContentMutation,
  CreateOneContentMutationVariables
>;

/**
 * __useCreateOneContentMutation__
 *
 * To run a mutation, you first call `useCreateOneContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneContentMutation, { data, loading, error }] = useCreateOneContentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneContentMutation,
    CreateOneContentMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    CreateOneContentMutation,
    CreateOneContentMutationVariables
  >(CreateOneContentDocument, options);
}
export type CreateOneContentMutationHookResult = ReturnType<
  typeof useCreateOneContentMutation
>;
export type CreateOneContentMutationResult =
  Apollo.MutationResult<CreateOneContentMutation>;
export type CreateOneContentMutationOptions = Apollo.BaseMutationOptions<
  CreateOneContentMutation,
  CreateOneContentMutationVariables
>;
export const CreateOneDeceasedDocument = gql`
  mutation CreateOneDeceased($data: DeceasedCreateInput2!) {
    createOneDeceased(data: $data) {
      id
      firstName
      lastName
      podState
    }
  }
`;
export type CreateOneDeceasedMutationFn = Apollo.MutationFunction<
  CreateOneDeceasedMutation,
  CreateOneDeceasedMutationVariables
>;

/**
 * __useCreateOneDeceasedMutation__
 *
 * To run a mutation, you first call `useCreateOneDeceasedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneDeceasedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneDeceasedMutation, { data, loading, error }] = useCreateOneDeceasedMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneDeceasedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneDeceasedMutation,
    CreateOneDeceasedMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    CreateOneDeceasedMutation,
    CreateOneDeceasedMutationVariables
  >(CreateOneDeceasedDocument, options);
}
export type CreateOneDeceasedMutationHookResult = ReturnType<
  typeof useCreateOneDeceasedMutation
>;
export type CreateOneDeceasedMutationResult =
  Apollo.MutationResult<CreateOneDeceasedMutation>;
export type CreateOneDeceasedMutationOptions = Apollo.BaseMutationOptions<
  CreateOneDeceasedMutation,
  CreateOneDeceasedMutationVariables
>;
export const CreateUploadUrlDocument = gql`
  mutation CreateUploadUrl(
    $destinationFolder: String
    $estateId: String
    $fileName: String!
  ) {
    createUploadUrl(
      destinationFolder: $destinationFolder
      estateId: $estateId
      fileName: $fileName
    )
  }
`;
export type CreateUploadUrlMutationFn = Apollo.MutationFunction<
  CreateUploadUrlMutation,
  CreateUploadUrlMutationVariables
>;

/**
 * __useCreateUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreateUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadUrlMutation, { data, loading, error }] = useCreateUploadUrlMutation({
 *   variables: {
 *      destinationFolder: // value for 'destinationFolder'
 *      estateId: // value for 'estateId'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useCreateUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUploadUrlMutation,
    CreateUploadUrlMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    CreateUploadUrlMutation,
    CreateUploadUrlMutationVariables
  >(CreateUploadUrlDocument, options);
}
export type CreateUploadUrlMutationHookResult = ReturnType<
  typeof useCreateUploadUrlMutation
>;
export type CreateUploadUrlMutationResult =
  Apollo.MutationResult<CreateUploadUrlMutation>;
export type CreateUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateUploadUrlMutation,
  CreateUploadUrlMutationVariables
>;
export const CreateUserCompletelyDocument = gql`
  mutation CreateUserCompletely($data: CreateUserInput!) {
    createUserCompletely(data: $data) {
      id
      firstName
      lastName
      email
    }
  }
`;
export type CreateUserCompletelyMutationFn = Apollo.MutationFunction<
  CreateUserCompletelyMutation,
  CreateUserCompletelyMutationVariables
>;

/**
 * __useCreateUserCompletelyMutation__
 *
 * To run a mutation, you first call `useCreateUserCompletelyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserCompletelyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserCompletelyMutation, { data, loading, error }] = useCreateUserCompletelyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserCompletelyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserCompletelyMutation,
    CreateUserCompletelyMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    CreateUserCompletelyMutation,
    CreateUserCompletelyMutationVariables
  >(CreateUserCompletelyDocument, options);
}
export type CreateUserCompletelyMutationHookResult = ReturnType<
  typeof useCreateUserCompletelyMutation
>;
export type CreateUserCompletelyMutationResult =
  Apollo.MutationResult<CreateUserCompletelyMutation>;
export type CreateUserCompletelyMutationOptions = Apollo.BaseMutationOptions<
  CreateUserCompletelyMutation,
  CreateUserCompletelyMutationVariables
>;
export const CreateUserCompletelyWithEstateDocument = gql`
  mutation CreateUserCompletelyWithEstate($data: CreateUserInput!) {
    createUserCompletelyWithEstate(data: $data) {
      id
      email
    }
  }
`;
export type CreateUserCompletelyWithEstateMutationFn = Apollo.MutationFunction<
  CreateUserCompletelyWithEstateMutation,
  CreateUserCompletelyWithEstateMutationVariables
>;

/**
 * __useCreateUserCompletelyWithEstateMutation__
 *
 * To run a mutation, you first call `useCreateUserCompletelyWithEstateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserCompletelyWithEstateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserCompletelyWithEstateMutation, { data, loading, error }] = useCreateUserCompletelyWithEstateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserCompletelyWithEstateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserCompletelyWithEstateMutation,
    CreateUserCompletelyWithEstateMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    CreateUserCompletelyWithEstateMutation,
    CreateUserCompletelyWithEstateMutationVariables
  >(CreateUserCompletelyWithEstateDocument, options);
}
export type CreateUserCompletelyWithEstateMutationHookResult = ReturnType<
  typeof useCreateUserCompletelyWithEstateMutation
>;
export type CreateUserCompletelyWithEstateMutationResult =
  Apollo.MutationResult<CreateUserCompletelyWithEstateMutation>;
export type CreateUserCompletelyWithEstateMutationOptions =
  Apollo.BaseMutationOptions<
    CreateUserCompletelyWithEstateMutation,
    CreateUserCompletelyWithEstateMutationVariables
  >;
export const CreateUserWithEstateDocument = gql`
  mutation CreateUserWithEstate {
    createUserWithEstate {
      id
      active
      forcePasswordReset
      type
      email
      dateOfBirth
      firstName
      lastName
      address
      city
      county
      state
      country
      phone
      partnerId
      createdAt
      updatedAt
    }
  }
`;
export type CreateUserWithEstateMutationFn = Apollo.MutationFunction<
  CreateUserWithEstateMutation,
  CreateUserWithEstateMutationVariables
>;

/**
 * __useCreateUserWithEstateMutation__
 *
 * To run a mutation, you first call `useCreateUserWithEstateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserWithEstateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserWithEstateMutation, { data, loading, error }] = useCreateUserWithEstateMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateUserWithEstateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserWithEstateMutation,
    CreateUserWithEstateMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    CreateUserWithEstateMutation,
    CreateUserWithEstateMutationVariables
  >(CreateUserWithEstateDocument, options);
}
export type CreateUserWithEstateMutationHookResult = ReturnType<
  typeof useCreateUserWithEstateMutation
>;
export type CreateUserWithEstateMutationResult =
  Apollo.MutationResult<CreateUserWithEstateMutation>;
export type CreateUserWithEstateMutationOptions = Apollo.BaseMutationOptions<
  CreateUserWithEstateMutation,
  CreateUserWithEstateMutationVariables
>;
export const CustomerAddDeceasedToEstateDocument = gql`
  mutation CustomerAddDeceasedToEstate(
    $data: ValidatedEstateUpdateInput!
    $where: EstateWhereUniqueInput2
  ) {
    updateUserEstate(data: $data, where: $where) {
      id
      deceased {
        id
        firstName
        lastName
      }
    }
  }
`;
export type CustomerAddDeceasedToEstateMutationFn = Apollo.MutationFunction<
  CustomerAddDeceasedToEstateMutation,
  CustomerAddDeceasedToEstateMutationVariables
>;

/**
 * __useCustomerAddDeceasedToEstateMutation__
 *
 * To run a mutation, you first call `useCustomerAddDeceasedToEstateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAddDeceasedToEstateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAddDeceasedToEstateMutation, { data, loading, error }] = useCustomerAddDeceasedToEstateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCustomerAddDeceasedToEstateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomerAddDeceasedToEstateMutation,
    CustomerAddDeceasedToEstateMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    CustomerAddDeceasedToEstateMutation,
    CustomerAddDeceasedToEstateMutationVariables
  >(CustomerAddDeceasedToEstateDocument, options);
}
export type CustomerAddDeceasedToEstateMutationHookResult = ReturnType<
  typeof useCustomerAddDeceasedToEstateMutation
>;
export type CustomerAddDeceasedToEstateMutationResult =
  Apollo.MutationResult<CustomerAddDeceasedToEstateMutation>;
export type CustomerAddDeceasedToEstateMutationOptions =
  Apollo.BaseMutationOptions<
    CustomerAddDeceasedToEstateMutation,
    CustomerAddDeceasedToEstateMutationVariables
  >;
export const DeleteDocumentDocument = gql`
  mutation DeleteDocument($id: String!) {
    deleteDocument(id: $id)
  }
`;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >(DeleteDocumentDocument, options);
}
export type DeleteDocumentMutationHookResult = ReturnType<
  typeof useDeleteDocumentMutation
>;
export type DeleteDocumentMutationResult =
  Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>;
export const DeleteOneActionCardDocument = gql`
  mutation DeleteOneActionCard($where: ActionCardWhereUniqueInput2!) {
    deleteOneActionCard(where: $where) {
      id
    }
  }
`;
export type DeleteOneActionCardMutationFn = Apollo.MutationFunction<
  DeleteOneActionCardMutation,
  DeleteOneActionCardMutationVariables
>;

/**
 * __useDeleteOneActionCardMutation__
 *
 * To run a mutation, you first call `useDeleteOneActionCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneActionCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneActionCardMutation, { data, loading, error }] = useDeleteOneActionCardMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOneActionCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOneActionCardMutation,
    DeleteOneActionCardMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    DeleteOneActionCardMutation,
    DeleteOneActionCardMutationVariables
  >(DeleteOneActionCardDocument, options);
}
export type DeleteOneActionCardMutationHookResult = ReturnType<
  typeof useDeleteOneActionCardMutation
>;
export type DeleteOneActionCardMutationResult =
  Apollo.MutationResult<DeleteOneActionCardMutation>;
export type DeleteOneActionCardMutationOptions = Apollo.BaseMutationOptions<
  DeleteOneActionCardMutation,
  DeleteOneActionCardMutationVariables
>;
export const DeleteOneComponentDocument = gql`
  mutation DeleteOneComponent($where: ComponentWhereUniqueInput2!) {
    deleteOneComponent(where: $where) {
      id
    }
  }
`;
export type DeleteOneComponentMutationFn = Apollo.MutationFunction<
  DeleteOneComponentMutation,
  DeleteOneComponentMutationVariables
>;

/**
 * __useDeleteOneComponentMutation__
 *
 * To run a mutation, you first call `useDeleteOneComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneComponentMutation, { data, loading, error }] = useDeleteOneComponentMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOneComponentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOneComponentMutation,
    DeleteOneComponentMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    DeleteOneComponentMutation,
    DeleteOneComponentMutationVariables
  >(DeleteOneComponentDocument, options);
}
export type DeleteOneComponentMutationHookResult = ReturnType<
  typeof useDeleteOneComponentMutation
>;
export type DeleteOneComponentMutationResult =
  Apollo.MutationResult<DeleteOneComponentMutation>;
export type DeleteOneComponentMutationOptions = Apollo.BaseMutationOptions<
  DeleteOneComponentMutation,
  DeleteOneComponentMutationVariables
>;
export const DeleteOneContentDocument = gql`
  mutation DeleteOneContent($where: ContentWhereUniqueInput2!) {
    deleteOneContent(where: $where) {
      id
    }
  }
`;
export type DeleteOneContentMutationFn = Apollo.MutationFunction<
  DeleteOneContentMutation,
  DeleteOneContentMutationVariables
>;

/**
 * __useDeleteOneContentMutation__
 *
 * To run a mutation, you first call `useDeleteOneContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneContentMutation, { data, loading, error }] = useDeleteOneContentMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOneContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOneContentMutation,
    DeleteOneContentMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    DeleteOneContentMutation,
    DeleteOneContentMutationVariables
  >(DeleteOneContentDocument, options);
}
export type DeleteOneContentMutationHookResult = ReturnType<
  typeof useDeleteOneContentMutation
>;
export type DeleteOneContentMutationResult =
  Apollo.MutationResult<DeleteOneContentMutation>;
export type DeleteOneContentMutationOptions = Apollo.BaseMutationOptions<
  DeleteOneContentMutation,
  DeleteOneContentMutationVariables
>;
export const DeleteUserNotificationsDocument = gql`
  mutation DeleteUserNotifications($data: DeleteUserNotificationsInput!) {
    deleteUserNotifications(data: $data) {
      estateId
      estateNotificationId
      message
      title
    }
  }
`;
export type DeleteUserNotificationsMutationFn = Apollo.MutationFunction<
  DeleteUserNotificationsMutation,
  DeleteUserNotificationsMutationVariables
>;

/**
 * __useDeleteUserNotificationsMutation__
 *
 * To run a mutation, you first call `useDeleteUserNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserNotificationsMutation, { data, loading, error }] = useDeleteUserNotificationsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteUserNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserNotificationsMutation,
    DeleteUserNotificationsMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    DeleteUserNotificationsMutation,
    DeleteUserNotificationsMutationVariables
  >(DeleteUserNotificationsDocument, options);
}
export type DeleteUserNotificationsMutationHookResult = ReturnType<
  typeof useDeleteUserNotificationsMutation
>;
export type DeleteUserNotificationsMutationResult =
  Apollo.MutationResult<DeleteUserNotificationsMutation>;
export type DeleteUserNotificationsMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserNotificationsMutation,
  DeleteUserNotificationsMutationVariables
>;
export const MarkNotificationsSeenDocument = gql`
  mutation MarkNotificationsSeen(
    $notificationIds: [String!]!
    $userId: String
  ) {
    markNotificationsSeen(notificationIds: $notificationIds, userId: $userId)
  }
`;
export type MarkNotificationsSeenMutationFn = Apollo.MutationFunction<
  MarkNotificationsSeenMutation,
  MarkNotificationsSeenMutationVariables
>;

/**
 * __useMarkNotificationsSeenMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsSeenMutation, { data, loading, error }] = useMarkNotificationsSeenMutation({
 *   variables: {
 *      notificationIds: // value for 'notificationIds'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMarkNotificationsSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkNotificationsSeenMutation,
    MarkNotificationsSeenMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    MarkNotificationsSeenMutation,
    MarkNotificationsSeenMutationVariables
  >(MarkNotificationsSeenDocument, options);
}
export type MarkNotificationsSeenMutationHookResult = ReturnType<
  typeof useMarkNotificationsSeenMutation
>;
export type MarkNotificationsSeenMutationResult =
  Apollo.MutationResult<MarkNotificationsSeenMutation>;
export type MarkNotificationsSeenMutationOptions = Apollo.BaseMutationOptions<
  MarkNotificationsSeenMutation,
  MarkNotificationsSeenMutationVariables
>;
export const MoveDocumentsDocument = gql`
  mutation MoveDocuments($data: MoveDocumentsInput!) {
    moveDocuments(data: $data)
  }
`;
export type MoveDocumentsMutationFn = Apollo.MutationFunction<
  MoveDocumentsMutation,
  MoveDocumentsMutationVariables
>;

/**
 * __useMoveDocumentsMutation__
 *
 * To run a mutation, you first call `useMoveDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveDocumentsMutation, { data, loading, error }] = useMoveDocumentsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMoveDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveDocumentsMutation,
    MoveDocumentsMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    MoveDocumentsMutation,
    MoveDocumentsMutationVariables
  >(MoveDocumentsDocument, options);
}
export type MoveDocumentsMutationHookResult = ReturnType<
  typeof useMoveDocumentsMutation
>;
export type MoveDocumentsMutationResult =
  Apollo.MutationResult<MoveDocumentsMutation>;
export type MoveDocumentsMutationOptions = Apollo.BaseMutationOptions<
  MoveDocumentsMutation,
  MoveDocumentsMutationVariables
>;
export const PrefillFormTemplateDocument = gql`
  mutation PrefillFormTemplate(
    $templateId: String!
    $estateId: String!
    $userId: String!
  ) {
    prefillFormTemplate(
      templateId: $templateId
      estateId: $estateId
      userId: $userId
    ) {
      createdAt
      id
      url
    }
  }
`;
export type PrefillFormTemplateMutationFn = Apollo.MutationFunction<
  PrefillFormTemplateMutation,
  PrefillFormTemplateMutationVariables
>;

/**
 * __usePrefillFormTemplateMutation__
 *
 * To run a mutation, you first call `usePrefillFormTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrefillFormTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prefillFormTemplateMutation, { data, loading, error }] = usePrefillFormTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      estateId: // value for 'estateId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePrefillFormTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PrefillFormTemplateMutation,
    PrefillFormTemplateMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    PrefillFormTemplateMutation,
    PrefillFormTemplateMutationVariables
  >(PrefillFormTemplateDocument, options);
}
export type PrefillFormTemplateMutationHookResult = ReturnType<
  typeof usePrefillFormTemplateMutation
>;
export type PrefillFormTemplateMutationResult =
  Apollo.MutationResult<PrefillFormTemplateMutation>;
export type PrefillFormTemplateMutationOptions = Apollo.BaseMutationOptions<
  PrefillFormTemplateMutation,
  PrefillFormTemplateMutationVariables
>;
export const RenameDocumentDocument = gql`
  mutation RenameDocument(
    $targetKey: String!
    $sourceKey: String!
    $estateId: String
  ) {
    renameDocument(
      targetKey: $targetKey
      sourceKey: $sourceKey
      estateId: $estateId
    )
  }
`;
export type RenameDocumentMutationFn = Apollo.MutationFunction<
  RenameDocumentMutation,
  RenameDocumentMutationVariables
>;

/**
 * __useRenameDocumentMutation__
 *
 * To run a mutation, you first call `useRenameDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameDocumentMutation, { data, loading, error }] = useRenameDocumentMutation({
 *   variables: {
 *      targetKey: // value for 'targetKey'
 *      sourceKey: // value for 'sourceKey'
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useRenameDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenameDocumentMutation,
    RenameDocumentMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    RenameDocumentMutation,
    RenameDocumentMutationVariables
  >(RenameDocumentDocument, options);
}
export type RenameDocumentMutationHookResult = ReturnType<
  typeof useRenameDocumentMutation
>;
export type RenameDocumentMutationResult =
  Apollo.MutationResult<RenameDocumentMutation>;
export type RenameDocumentMutationOptions = Apollo.BaseMutationOptions<
  RenameDocumentMutation,
  RenameDocumentMutationVariables
>;
export const RestoreDocumentDocument = gql`
  mutation RestoreDocument(
    $deleteMarkerId: String!
    $restoreDocumentId: String!
  ) {
    restoreDocument(deleteMarkerId: $deleteMarkerId, id: $restoreDocumentId)
  }
`;
export type RestoreDocumentMutationFn = Apollo.MutationFunction<
  RestoreDocumentMutation,
  RestoreDocumentMutationVariables
>;

/**
 * __useRestoreDocumentMutation__
 *
 * To run a mutation, you first call `useRestoreDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreDocumentMutation, { data, loading, error }] = useRestoreDocumentMutation({
 *   variables: {
 *      deleteMarkerId: // value for 'deleteMarkerId'
 *      restoreDocumentId: // value for 'restoreDocumentId'
 *   },
 * });
 */
export function useRestoreDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreDocumentMutation,
    RestoreDocumentMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    RestoreDocumentMutation,
    RestoreDocumentMutationVariables
  >(RestoreDocumentDocument, options);
}
export type RestoreDocumentMutationHookResult = ReturnType<
  typeof useRestoreDocumentMutation
>;
export type RestoreDocumentMutationResult =
  Apollo.MutationResult<RestoreDocumentMutation>;
export type RestoreDocumentMutationOptions = Apollo.BaseMutationOptions<
  RestoreDocumentMutation,
  RestoreDocumentMutationVariables
>;
export const SignUpUserDocument = gql`
  mutation SignUpUser($data: SignUpUserInput!) {
    signUpUser(data: $data) {
      id
      email
    }
  }
`;
export type SignUpUserMutationFn = Apollo.MutationFunction<
  SignUpUserMutation,
  SignUpUserMutationVariables
>;

/**
 * __useSignUpUserMutation__
 *
 * To run a mutation, you first call `useSignUpUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpUserMutation, { data, loading, error }] = useSignUpUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignUpUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpUserMutation,
    SignUpUserMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<SignUpUserMutation, SignUpUserMutationVariables>(
    SignUpUserDocument,
    options,
  );
}
export type SignUpUserMutationHookResult = ReturnType<
  typeof useSignUpUserMutation
>;
export type SignUpUserMutationResult =
  Apollo.MutationResult<SignUpUserMutation>;
export type SignUpUserMutationOptions = Apollo.BaseMutationOptions<
  SignUpUserMutation,
  SignUpUserMutationVariables
>;
export const StartActionCardDocument = gql`
  mutation StartActionCard(
    $startActionCardId: String!
    $estateId: String
    $reply: String
  ) {
    startActionCard(
      id: $startActionCardId
      estateId: $estateId
      reply: $reply
    ) {
      id
      estateId
      componentId
      estateDetailId
      type
      status
      name
      description
      assignee
      formFieldName
      encryptedReply
      appointmentTime
      appointmentLocation
      calendarIcs
      calendarLink
      documentDownloadUrl
      documentUploadUrl
      documentSignUrl
      mapUrl
      promoted
      createdAt
      updatedAt
      estateDetail {
        encrypted
        value
      }
    }
  }
`;
export type StartActionCardMutationFn = Apollo.MutationFunction<
  StartActionCardMutation,
  StartActionCardMutationVariables
>;

/**
 * __useStartActionCardMutation__
 *
 * To run a mutation, you first call `useStartActionCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartActionCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startActionCardMutation, { data, loading, error }] = useStartActionCardMutation({
 *   variables: {
 *      startActionCardId: // value for 'startActionCardId'
 *      estateId: // value for 'estateId'
 *      reply: // value for 'reply'
 *   },
 * });
 */
export function useStartActionCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartActionCardMutation,
    StartActionCardMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    StartActionCardMutation,
    StartActionCardMutationVariables
  >(StartActionCardDocument, options);
}
export type StartActionCardMutationHookResult = ReturnType<
  typeof useStartActionCardMutation
>;
export type StartActionCardMutationResult =
  Apollo.MutationResult<StartActionCardMutation>;
export type StartActionCardMutationOptions = Apollo.BaseMutationOptions<
  StartActionCardMutation,
  StartActionCardMutationVariables
>;
export const TrackClickDocument = gql`
  mutation TrackClick($element: String!, $estateId: String!) {
    trackClick(element: $element, estateId: $estateId)
  }
`;
export type TrackClickMutationFn = Apollo.MutationFunction<
  TrackClickMutation,
  TrackClickMutationVariables
>;

/**
 * __useTrackClickMutation__
 *
 * To run a mutation, you first call `useTrackClickMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackClickMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackClickMutation, { data, loading, error }] = useTrackClickMutation({
 *   variables: {
 *      element: // value for 'element'
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useTrackClickMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackClickMutation,
    TrackClickMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<TrackClickMutation, TrackClickMutationVariables>(
    TrackClickDocument,
    options,
  );
}
export type TrackClickMutationHookResult = ReturnType<
  typeof useTrackClickMutation
>;
export type TrackClickMutationResult =
  Apollo.MutationResult<TrackClickMutation>;
export type TrackClickMutationOptions = Apollo.BaseMutationOptions<
  TrackClickMutation,
  TrackClickMutationVariables
>;
export const TrackPageViewDocument = gql`
  mutation TrackPageView($page: String!, $estateId: String!) {
    trackPageView(page: $page, estateId: $estateId)
  }
`;
export type TrackPageViewMutationFn = Apollo.MutationFunction<
  TrackPageViewMutation,
  TrackPageViewMutationVariables
>;

/**
 * __useTrackPageViewMutation__
 *
 * To run a mutation, you first call `useTrackPageViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackPageViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackPageViewMutation, { data, loading, error }] = useTrackPageViewMutation({
 *   variables: {
 *      page: // value for 'page'
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useTrackPageViewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackPageViewMutation,
    TrackPageViewMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    TrackPageViewMutation,
    TrackPageViewMutationVariables
  >(TrackPageViewDocument, options);
}
export type TrackPageViewMutationHookResult = ReturnType<
  typeof useTrackPageViewMutation
>;
export type TrackPageViewMutationResult =
  Apollo.MutationResult<TrackPageViewMutation>;
export type TrackPageViewMutationOptions = Apollo.BaseMutationOptions<
  TrackPageViewMutation,
  TrackPageViewMutationVariables
>;
export const TrackScrollDocument = gql`
  mutation TrackScroll(
    $name: String!
    $status: String
    $estateId: String
    $elementId: String
  ) {
    trackScroll(
      name: $name
      status: $status
      estateId: $estateId
      elementId: $elementId
    )
  }
`;
export type TrackScrollMutationFn = Apollo.MutationFunction<
  TrackScrollMutation,
  TrackScrollMutationVariables
>;

/**
 * __useTrackScrollMutation__
 *
 * To run a mutation, you first call `useTrackScrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackScrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackScrollMutation, { data, loading, error }] = useTrackScrollMutation({
 *   variables: {
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      estateId: // value for 'estateId'
 *      elementId: // value for 'elementId'
 *   },
 * });
 */
export function useTrackScrollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackScrollMutation,
    TrackScrollMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<TrackScrollMutation, TrackScrollMutationVariables>(
    TrackScrollDocument,
    options,
  );
}
export type TrackScrollMutationHookResult = ReturnType<
  typeof useTrackScrollMutation
>;
export type TrackScrollMutationResult =
  Apollo.MutationResult<TrackScrollMutation>;
export type TrackScrollMutationOptions = Apollo.BaseMutationOptions<
  TrackScrollMutation,
  TrackScrollMutationVariables
>;
export const TrackSectionOpenedDocument = gql`
  mutation TrackSectionOpened($section: String!, $estateId: String!) {
    trackSectionOpened(section: $section, estateId: $estateId)
  }
`;
export type TrackSectionOpenedMutationFn = Apollo.MutationFunction<
  TrackSectionOpenedMutation,
  TrackSectionOpenedMutationVariables
>;

/**
 * __useTrackSectionOpenedMutation__
 *
 * To run a mutation, you first call `useTrackSectionOpenedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackSectionOpenedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackSectionOpenedMutation, { data, loading, error }] = useTrackSectionOpenedMutation({
 *   variables: {
 *      section: // value for 'section'
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useTrackSectionOpenedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackSectionOpenedMutation,
    TrackSectionOpenedMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    TrackSectionOpenedMutation,
    TrackSectionOpenedMutationVariables
  >(TrackSectionOpenedDocument, options);
}
export type TrackSectionOpenedMutationHookResult = ReturnType<
  typeof useTrackSectionOpenedMutation
>;
export type TrackSectionOpenedMutationResult =
  Apollo.MutationResult<TrackSectionOpenedMutation>;
export type TrackSectionOpenedMutationOptions = Apollo.BaseMutationOptions<
  TrackSectionOpenedMutation,
  TrackSectionOpenedMutationVariables
>;
export const UpdateOneActionCardDocument = gql`
  mutation UpdateOneActionCard(
    $data: ActionCardUpdateInput2!
    $where: ActionCardWhereUniqueInput2!
  ) {
    updateOneActionCard(data: $data, where: $where) {
      id
    }
  }
`;
export type UpdateOneActionCardMutationFn = Apollo.MutationFunction<
  UpdateOneActionCardMutation,
  UpdateOneActionCardMutationVariables
>;

/**
 * __useUpdateOneActionCardMutation__
 *
 * To run a mutation, you first call `useUpdateOneActionCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneActionCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneActionCardMutation, { data, loading, error }] = useUpdateOneActionCardMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneActionCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOneActionCardMutation,
    UpdateOneActionCardMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    UpdateOneActionCardMutation,
    UpdateOneActionCardMutationVariables
  >(UpdateOneActionCardDocument, options);
}
export type UpdateOneActionCardMutationHookResult = ReturnType<
  typeof useUpdateOneActionCardMutation
>;
export type UpdateOneActionCardMutationResult =
  Apollo.MutationResult<UpdateOneActionCardMutation>;
export type UpdateOneActionCardMutationOptions = Apollo.BaseMutationOptions<
  UpdateOneActionCardMutation,
  UpdateOneActionCardMutationVariables
>;
export const UpdateOneComponentDocument = gql`
  mutation UpdateOneComponent(
    $data: ComponentUpdateInput2!
    $where: ComponentWhereUniqueInput2!
  ) {
    updateOneComponent(data: $data, where: $where) {
      id
    }
  }
`;
export type UpdateOneComponentMutationFn = Apollo.MutationFunction<
  UpdateOneComponentMutation,
  UpdateOneComponentMutationVariables
>;

/**
 * __useUpdateOneComponentMutation__
 *
 * To run a mutation, you first call `useUpdateOneComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneComponentMutation, { data, loading, error }] = useUpdateOneComponentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneComponentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOneComponentMutation,
    UpdateOneComponentMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    UpdateOneComponentMutation,
    UpdateOneComponentMutationVariables
  >(UpdateOneComponentDocument, options);
}
export type UpdateOneComponentMutationHookResult = ReturnType<
  typeof useUpdateOneComponentMutation
>;
export type UpdateOneComponentMutationResult =
  Apollo.MutationResult<UpdateOneComponentMutation>;
export type UpdateOneComponentMutationOptions = Apollo.BaseMutationOptions<
  UpdateOneComponentMutation,
  UpdateOneComponentMutationVariables
>;
export const UpdateOneContentDocument = gql`
  mutation UpdateOneContent(
    $data: ContentUpdateInput2!
    $where: ContentWhereUniqueInput2!
  ) {
    updateOneContent(data: $data, where: $where) {
      id
      header
      status
      completeBy
      completedAt
    }
  }
`;
export type UpdateOneContentMutationFn = Apollo.MutationFunction<
  UpdateOneContentMutation,
  UpdateOneContentMutationVariables
>;

/**
 * __useUpdateOneContentMutation__
 *
 * To run a mutation, you first call `useUpdateOneContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneContentMutation, { data, loading, error }] = useUpdateOneContentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOneContentMutation,
    UpdateOneContentMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    UpdateOneContentMutation,
    UpdateOneContentMutationVariables
  >(UpdateOneContentDocument, options);
}
export type UpdateOneContentMutationHookResult = ReturnType<
  typeof useUpdateOneContentMutation
>;
export type UpdateOneContentMutationResult =
  Apollo.MutationResult<UpdateOneContentMutation>;
export type UpdateOneContentMutationOptions = Apollo.BaseMutationOptions<
  UpdateOneContentMutation,
  UpdateOneContentMutationVariables
>;
export const UpdateOneDeceasedDocument = gql`
  mutation UpdateOneDeceased(
    $data: DeceasedUpdateInput2!
    $where: DeceasedWhereUniqueInput2!
  ) {
    updateOneDeceased(data: $data, where: $where) {
      id
      firstName
      lastName
      podState
      state
      dateOfBirth
      dateOfDeath
    }
  }
`;
export type UpdateOneDeceasedMutationFn = Apollo.MutationFunction<
  UpdateOneDeceasedMutation,
  UpdateOneDeceasedMutationVariables
>;

/**
 * __useUpdateOneDeceasedMutation__
 *
 * To run a mutation, you first call `useUpdateOneDeceasedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneDeceasedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneDeceasedMutation, { data, loading, error }] = useUpdateOneDeceasedMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneDeceasedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOneDeceasedMutation,
    UpdateOneDeceasedMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    UpdateOneDeceasedMutation,
    UpdateOneDeceasedMutationVariables
  >(UpdateOneDeceasedDocument, options);
}
export type UpdateOneDeceasedMutationHookResult = ReturnType<
  typeof useUpdateOneDeceasedMutation
>;
export type UpdateOneDeceasedMutationResult =
  Apollo.MutationResult<UpdateOneDeceasedMutation>;
export type UpdateOneDeceasedMutationOptions = Apollo.BaseMutationOptions<
  UpdateOneDeceasedMutation,
  UpdateOneDeceasedMutationVariables
>;
export const UpdateOneEstateDocument = gql`
  mutation UpdateOneEstate(
    $data: EstateUpdateInput2!
    $where: EstateWhereUniqueInput2!
  ) {
    updateOneEstate(data: $data, where: $where) {
      id
    }
  }
`;
export type UpdateOneEstateMutationFn = Apollo.MutationFunction<
  UpdateOneEstateMutation,
  UpdateOneEstateMutationVariables
>;

/**
 * __useUpdateOneEstateMutation__
 *
 * To run a mutation, you first call `useUpdateOneEstateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneEstateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneEstateMutation, { data, loading, error }] = useUpdateOneEstateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneEstateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOneEstateMutation,
    UpdateOneEstateMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    UpdateOneEstateMutation,
    UpdateOneEstateMutationVariables
  >(UpdateOneEstateDocument, options);
}
export type UpdateOneEstateMutationHookResult = ReturnType<
  typeof useUpdateOneEstateMutation
>;
export type UpdateOneEstateMutationResult =
  Apollo.MutationResult<UpdateOneEstateMutation>;
export type UpdateOneEstateMutationOptions = Apollo.BaseMutationOptions<
  UpdateOneEstateMutation,
  UpdateOneEstateMutationVariables
>;
export const UpdateOneUserDocument = gql`
  mutation UpdateOneUser(
    $data: UserUpdateInput2!
    $where: UserWhereUniqueInput2!
  ) {
    updateOneUser(data: $data, where: $where) {
      id
      firstName
      lastName
      email
    }
  }
`;
export type UpdateOneUserMutationFn = Apollo.MutationFunction<
  UpdateOneUserMutation,
  UpdateOneUserMutationVariables
>;

/**
 * __useUpdateOneUserMutation__
 *
 * To run a mutation, you first call `useUpdateOneUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneUserMutation, { data, loading, error }] = useUpdateOneUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOneUserMutation,
    UpdateOneUserMutationVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useMutation<
    UpdateOneUserMutation,
    UpdateOneUserMutationVariables
  >(UpdateOneUserDocument, options);
}
export type UpdateOneUserMutationHookResult = ReturnType<
  typeof useUpdateOneUserMutation
>;
export type UpdateOneUserMutationResult =
  Apollo.MutationResult<UpdateOneUserMutation>;
export type UpdateOneUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateOneUserMutation,
  UpdateOneUserMutationVariables
>;
export const AdminGetUsersOnEstateDocument = gql`
  query AdminGetUsersOnEstate(
    $where: EstateWhereUniqueInput2!
    $orderBy: [UsersOnEstatesOrderByWithRelationInput2!]
  ) {
    estate(where: $where) {
      users(orderBy: $orderBy) {
        user {
          id
          firstName
          lastName
        }
        userRole
      }
    }
  }
`;

/**
 * __useAdminGetUsersOnEstateQuery__
 *
 * To run a query within a React component, call `useAdminGetUsersOnEstateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetUsersOnEstateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetUsersOnEstateQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAdminGetUsersOnEstateQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetUsersOnEstateQuery,
    AdminGetUsersOnEstateQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<
    AdminGetUsersOnEstateQuery,
    AdminGetUsersOnEstateQueryVariables
  >(AdminGetUsersOnEstateDocument, options);
}
export function useAdminGetUsersOnEstateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetUsersOnEstateQuery,
    AdminGetUsersOnEstateQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<
    AdminGetUsersOnEstateQuery,
    AdminGetUsersOnEstateQueryVariables
  >(AdminGetUsersOnEstateDocument, options);
}
export type AdminGetUsersOnEstateQueryHookResult = ReturnType<
  typeof useAdminGetUsersOnEstateQuery
>;
export type AdminGetUsersOnEstateLazyQueryHookResult = ReturnType<
  typeof useAdminGetUsersOnEstateLazyQuery
>;
export type AdminGetUsersOnEstateQueryResult = Apollo.QueryResult<
  AdminGetUsersOnEstateQuery,
  AdminGetUsersOnEstateQueryVariables
>;
export const FormTemplatesDocument = gql`
  query FormTemplates {
    formTemplates {
      id
      title
    }
  }
`;

/**
 * __useFormTemplatesQuery__
 *
 * To run a query within a React component, call `useFormTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FormTemplatesQuery,
    FormTemplatesQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<FormTemplatesQuery, FormTemplatesQueryVariables>(
    FormTemplatesDocument,
    options,
  );
}
export function useFormTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FormTemplatesQuery,
    FormTemplatesQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<FormTemplatesQuery, FormTemplatesQueryVariables>(
    FormTemplatesDocument,
    options,
  );
}
export type FormTemplatesQueryHookResult = ReturnType<
  typeof useFormTemplatesQuery
>;
export type FormTemplatesLazyQueryHookResult = ReturnType<
  typeof useFormTemplatesLazyQuery
>;
export type FormTemplatesQueryResult = Apollo.QueryResult<
  FormTemplatesQuery,
  FormTemplatesQueryVariables
>;
export const GetActionCardDocument = gql`
  query GetActionCard($where: ActionCardWhereUniqueInput2!) {
    actionCard(where: $where) {
      estateDetail {
        value
        id
        decryptedValue
      }
      id
      estateId
      componentId
      estateDetailId
      type
      status
      name
      description
      assignee
      formFieldName
      encryptedReply
      appointmentTime
      appointmentLocation
      calendarIcs
      calendarLink
      documentDownloadUrl
      documentUploadUrl
      documentSignUrl
      mapUrl
      promoted
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetActionCardQuery__
 *
 * To run a query within a React component, call `useGetActionCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionCardQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetActionCardQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActionCardQuery,
    GetActionCardQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<GetActionCardQuery, GetActionCardQueryVariables>(
    GetActionCardDocument,
    options,
  );
}
export function useGetActionCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActionCardQuery,
    GetActionCardQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<GetActionCardQuery, GetActionCardQueryVariables>(
    GetActionCardDocument,
    options,
  );
}
export type GetActionCardQueryHookResult = ReturnType<
  typeof useGetActionCardQuery
>;
export type GetActionCardLazyQueryHookResult = ReturnType<
  typeof useGetActionCardLazyQuery
>;
export type GetActionCardQueryResult = Apollo.QueryResult<
  GetActionCardQuery,
  GetActionCardQueryVariables
>;
export const GetAssetsByEstateIdDocument = gql`
  query GetAssetsByEstateId($estateId: String!) {
    getAssetsByEstateId(estateId: $estateId) {
      assetAccount {
        accountNumber
        assetSubcategory
        institution
      }
      status
      probateJurisdiction
      probateEstate
      inTrust
      id
      finalValue
      estimatedValue
      estateId
      dodValue
      description
      assetCategory
    }
  }
`;

/**
 * __useGetAssetsByEstateIdQuery__
 *
 * To run a query within a React component, call `useGetAssetsByEstateIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsByEstateIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsByEstateIdQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetAssetsByEstateIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetsByEstateIdQuery,
    GetAssetsByEstateIdQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<
    GetAssetsByEstateIdQuery,
    GetAssetsByEstateIdQueryVariables
  >(GetAssetsByEstateIdDocument, options);
}
export function useGetAssetsByEstateIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetsByEstateIdQuery,
    GetAssetsByEstateIdQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<
    GetAssetsByEstateIdQuery,
    GetAssetsByEstateIdQueryVariables
  >(GetAssetsByEstateIdDocument, options);
}
export type GetAssetsByEstateIdQueryHookResult = ReturnType<
  typeof useGetAssetsByEstateIdQuery
>;
export type GetAssetsByEstateIdLazyQueryHookResult = ReturnType<
  typeof useGetAssetsByEstateIdLazyQuery
>;
export type GetAssetsByEstateIdQueryResult = Apollo.QueryResult<
  GetAssetsByEstateIdQuery,
  GetAssetsByEstateIdQueryVariables
>;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      forcePasswordReset
      active
      firstName
      lastName
      email
      avatar
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const GetEstateComponentDocument = gql`
  query GetEstateComponent(
    $componentId: String!
    $orderBy: [ContentOrderByWithRelationInput2!]
  ) {
    getEstateComponent(componentId: $componentId) {
      id
      name
      type
      subType
      descriptor
      contents(orderBy: $orderBy) {
        id
        header
        body
        status
        user {
          firstName
          lastName
          avatar
        }
        displayDate
        completeBy
        completeByType
        completedAt
      }
      actionCards {
        id
        name
        status
        type
      }
      notifications {
        id
        title
        message
      }
    }
  }
`;

/**
 * __useGetEstateComponentQuery__
 *
 * To run a query within a React component, call `useGetEstateComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstateComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstateComponentQuery({
 *   variables: {
 *      componentId: // value for 'componentId'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetEstateComponentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEstateComponentQuery,
    GetEstateComponentQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<
    GetEstateComponentQuery,
    GetEstateComponentQueryVariables
  >(GetEstateComponentDocument, options);
}
export function useGetEstateComponentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEstateComponentQuery,
    GetEstateComponentQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<
    GetEstateComponentQuery,
    GetEstateComponentQueryVariables
  >(GetEstateComponentDocument, options);
}
export type GetEstateComponentQueryHookResult = ReturnType<
  typeof useGetEstateComponentQuery
>;
export type GetEstateComponentLazyQueryHookResult = ReturnType<
  typeof useGetEstateComponentLazyQuery
>;
export type GetEstateComponentQueryResult = Apollo.QueryResult<
  GetEstateComponentQuery,
  GetEstateComponentQueryVariables
>;
export const GetEstateComponentSummaryDocument = gql`
  query GetEstateComponentSummary($componentId: String!) {
    getEstateComponent(componentId: $componentId) {
      id
      accountHolder
      accountNumber
      address
      city
      country
      descriptor
      estimatedValue
      finalValue
      name
      state
      status
      subType
      type
      zip
    }
  }
`;

/**
 * __useGetEstateComponentSummaryQuery__
 *
 * To run a query within a React component, call `useGetEstateComponentSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstateComponentSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstateComponentSummaryQuery({
 *   variables: {
 *      componentId: // value for 'componentId'
 *   },
 * });
 */
export function useGetEstateComponentSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEstateComponentSummaryQuery,
    GetEstateComponentSummaryQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<
    GetEstateComponentSummaryQuery,
    GetEstateComponentSummaryQueryVariables
  >(GetEstateComponentSummaryDocument, options);
}
export function useGetEstateComponentSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEstateComponentSummaryQuery,
    GetEstateComponentSummaryQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<
    GetEstateComponentSummaryQuery,
    GetEstateComponentSummaryQueryVariables
  >(GetEstateComponentSummaryDocument, options);
}
export type GetEstateComponentSummaryQueryHookResult = ReturnType<
  typeof useGetEstateComponentSummaryQuery
>;
export type GetEstateComponentSummaryLazyQueryHookResult = ReturnType<
  typeof useGetEstateComponentSummaryLazyQuery
>;
export type GetEstateComponentSummaryQueryResult = Apollo.QueryResult<
  GetEstateComponentSummaryQuery,
  GetEstateComponentSummaryQueryVariables
>;
export const GetEstateDistributionBreakdownDocument = gql`
  query getEstateDistributionBreakdown($estateId: String!) {
    estateDistributionBreakdown(estateId: $estateId) {
      feeCalculationInfo {
        message
        title
      }
      distributionInfo {
        message
        title
      }
      alixFee {
        amount
        status
        updatedAt
        type
      }
      debts
      totalValue
      estimatedDistribution
    }
  }
`;

/**
 * __useGetEstateDistributionBreakdownQuery__
 *
 * To run a query within a React component, call `useGetEstateDistributionBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstateDistributionBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstateDistributionBreakdownQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetEstateDistributionBreakdownQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEstateDistributionBreakdownQuery,
    GetEstateDistributionBreakdownQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<
    GetEstateDistributionBreakdownQuery,
    GetEstateDistributionBreakdownQueryVariables
  >(GetEstateDistributionBreakdownDocument, options);
}
export function useGetEstateDistributionBreakdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEstateDistributionBreakdownQuery,
    GetEstateDistributionBreakdownQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<
    GetEstateDistributionBreakdownQuery,
    GetEstateDistributionBreakdownQueryVariables
  >(GetEstateDistributionBreakdownDocument, options);
}
export type GetEstateDistributionBreakdownQueryHookResult = ReturnType<
  typeof useGetEstateDistributionBreakdownQuery
>;
export type GetEstateDistributionBreakdownLazyQueryHookResult = ReturnType<
  typeof useGetEstateDistributionBreakdownLazyQuery
>;
export type GetEstateDistributionBreakdownQueryResult = Apollo.QueryResult<
  GetEstateDistributionBreakdownQuery,
  GetEstateDistributionBreakdownQueryVariables
>;
export const GetEstateNotificationsDocument = gql`
  query GetEstateNotifications($estateId: String!) {
    getEstateNotifications(estateId: $estateId) {
      componentId
      createdAt
      estateId
      id
      message
      title
      updatedAt
      userNotifications {
        user {
          firstName
          lastName
          estates {
            userRole
          }
        }
        userId
        seenAt
      }
    }
  }
`;

/**
 * __useGetEstateNotificationsQuery__
 *
 * To run a query within a React component, call `useGetEstateNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstateNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstateNotificationsQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetEstateNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEstateNotificationsQuery,
    GetEstateNotificationsQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<
    GetEstateNotificationsQuery,
    GetEstateNotificationsQueryVariables
  >(GetEstateNotificationsDocument, options);
}
export function useGetEstateNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEstateNotificationsQuery,
    GetEstateNotificationsQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<
    GetEstateNotificationsQuery,
    GetEstateNotificationsQueryVariables
  >(GetEstateNotificationsDocument, options);
}
export type GetEstateNotificationsQueryHookResult = ReturnType<
  typeof useGetEstateNotificationsQuery
>;
export type GetEstateNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetEstateNotificationsLazyQuery
>;
export type GetEstateNotificationsQueryResult = Apollo.QueryResult<
  GetEstateNotificationsQuery,
  GetEstateNotificationsQueryVariables
>;
export const GetEstatePieDataDocument = gql`
  query GetEstatePieData($estateId: String) {
    getUserEstate(estateId: $estateId) {
      id
      componentBreakDown {
        type
        subType
        sum
        percentage
        count
        total
      }
    }
  }
`;

/**
 * __useGetEstatePieDataQuery__
 *
 * To run a query within a React component, call `useGetEstatePieDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstatePieDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstatePieDataQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetEstatePieDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEstatePieDataQuery,
    GetEstatePieDataQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<GetEstatePieDataQuery, GetEstatePieDataQueryVariables>(
    GetEstatePieDataDocument,
    options,
  );
}
export function useGetEstatePieDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEstatePieDataQuery,
    GetEstatePieDataQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<
    GetEstatePieDataQuery,
    GetEstatePieDataQueryVariables
  >(GetEstatePieDataDocument, options);
}
export type GetEstatePieDataQueryHookResult = ReturnType<
  typeof useGetEstatePieDataQuery
>;
export type GetEstatePieDataLazyQueryHookResult = ReturnType<
  typeof useGetEstatePieDataLazyQuery
>;
export type GetEstatePieDataQueryResult = Apollo.QueryResult<
  GetEstatePieDataQuery,
  GetEstatePieDataQueryVariables
>;
export const GetKeyDatesDocument = gql`
  query GetKeyDates($estateId: String) {
    getUserEstate(estateId: $estateId) {
      keyDates {
        id
        header
        body
        status
        user {
          firstName
          lastName
          avatar
        }
        displayDate
        componentId
        completeBy
        completeByType
        completedAt
      }
    }
  }
`;

/**
 * __useGetKeyDatesQuery__
 *
 * To run a query within a React component, call `useGetKeyDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeyDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeyDatesQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetKeyDatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetKeyDatesQuery,
    GetKeyDatesQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<GetKeyDatesQuery, GetKeyDatesQueryVariables>(
    GetKeyDatesDocument,
    options,
  );
}
export function useGetKeyDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetKeyDatesQuery,
    GetKeyDatesQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<GetKeyDatesQuery, GetKeyDatesQueryVariables>(
    GetKeyDatesDocument,
    options,
  );
}
export type GetKeyDatesQueryHookResult = ReturnType<typeof useGetKeyDatesQuery>;
export type GetKeyDatesLazyQueryHookResult = ReturnType<
  typeof useGetKeyDatesLazyQuery
>;
export type GetKeyDatesQueryResult = Apollo.QueryResult<
  GetKeyDatesQuery,
  GetKeyDatesQueryVariables
>;
export const GetMostRecentEulaDocument = gql`
  query GetMostRecentEULA {
    getMostRecentEULA {
      id
      version
      pdfLink
    }
  }
`;

/**
 * __useGetMostRecentEulaQuery__
 *
 * To run a query within a React component, call `useGetMostRecentEulaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostRecentEulaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostRecentEulaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMostRecentEulaQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMostRecentEulaQuery,
    GetMostRecentEulaQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<
    GetMostRecentEulaQuery,
    GetMostRecentEulaQueryVariables
  >(GetMostRecentEulaDocument, options);
}
export function useGetMostRecentEulaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMostRecentEulaQuery,
    GetMostRecentEulaQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<
    GetMostRecentEulaQuery,
    GetMostRecentEulaQueryVariables
  >(GetMostRecentEulaDocument, options);
}
export type GetMostRecentEulaQueryHookResult = ReturnType<
  typeof useGetMostRecentEulaQuery
>;
export type GetMostRecentEulaLazyQueryHookResult = ReturnType<
  typeof useGetMostRecentEulaLazyQuery
>;
export type GetMostRecentEulaQueryResult = Apollo.QueryResult<
  GetMostRecentEulaQuery,
  GetMostRecentEulaQueryVariables
>;
export const GetNotificationLogDocument = gql`
  query GetNotificationLog($estateId: String!) {
    getNotificationLog(estateId: $estateId) {
      estateId
      id
      title
      message
      notificationReceipts {
        createdAt
        notificationId
        seenAt
        user {
          userId
          firstName
          lastName
        }
      }
      createdAt
      updatedAt
      actionCardId
      componentId
      contentId
      route
      routingError {
        message
      }
    }
  }
`;

/**
 * __useGetNotificationLogQuery__
 *
 * To run a query within a React component, call `useGetNotificationLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationLogQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetNotificationLogQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotificationLogQuery,
    GetNotificationLogQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<
    GetNotificationLogQuery,
    GetNotificationLogQueryVariables
  >(GetNotificationLogDocument, options);
}
export function useGetNotificationLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationLogQuery,
    GetNotificationLogQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<
    GetNotificationLogQuery,
    GetNotificationLogQueryVariables
  >(GetNotificationLogDocument, options);
}
export type GetNotificationLogQueryHookResult = ReturnType<
  typeof useGetNotificationLogQuery
>;
export type GetNotificationLogLazyQueryHookResult = ReturnType<
  typeof useGetNotificationLogLazyQuery
>;
export type GetNotificationLogQueryResult = Apollo.QueryResult<
  GetNotificationLogQuery,
  GetNotificationLogQueryVariables
>;
export const GetOneContentDocument = gql`
  query GetOneContent($where: ContentWhereUniqueInput2!) {
    getContent(where: $where) {
      id
      completeByType
      completeBy
      displayDate
      body
      componentId
      header
      status
      userId
      type
      user {
        id
        firstName
        lastName
        avatar
      }
    }
  }
`;

/**
 * __useGetOneContentQuery__
 *
 * To run a query within a React component, call `useGetOneContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneContentQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetOneContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOneContentQuery,
    GetOneContentQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<GetOneContentQuery, GetOneContentQueryVariables>(
    GetOneContentDocument,
    options,
  );
}
export function useGetOneContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneContentQuery,
    GetOneContentQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<GetOneContentQuery, GetOneContentQueryVariables>(
    GetOneContentDocument,
    options,
  );
}
export type GetOneContentQueryHookResult = ReturnType<
  typeof useGetOneContentQuery
>;
export type GetOneContentLazyQueryHookResult = ReturnType<
  typeof useGetOneContentLazyQuery
>;
export type GetOneContentQueryResult = Apollo.QueryResult<
  GetOneContentQuery,
  GetOneContentQueryVariables
>;
export const GetRecentActivityDocument = gql`
  query GetRecentActivity($estateId: String) {
    getUserEstate(estateId: $estateId) {
      recentActivity {
        id
        header
        body
        status
        user {
          firstName
          lastName
          avatar
        }
        displayDate
        componentId
        completeBy
        completeByType
        completedAt
        type
      }
    }
  }
`;

/**
 * __useGetRecentActivityQuery__
 *
 * To run a query within a React component, call `useGetRecentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentActivityQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetRecentActivityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRecentActivityQuery,
    GetRecentActivityQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<
    GetRecentActivityQuery,
    GetRecentActivityQueryVariables
  >(GetRecentActivityDocument, options);
}
export function useGetRecentActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecentActivityQuery,
    GetRecentActivityQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<
    GetRecentActivityQuery,
    GetRecentActivityQueryVariables
  >(GetRecentActivityDocument, options);
}
export type GetRecentActivityQueryHookResult = ReturnType<
  typeof useGetRecentActivityQuery
>;
export type GetRecentActivityLazyQueryHookResult = ReturnType<
  typeof useGetRecentActivityLazyQuery
>;
export type GetRecentActivityQueryResult = Apollo.QueryResult<
  GetRecentActivityQuery,
  GetRecentActivityQueryVariables
>;
export const GetSimpleEstateListDocument = gql`
  query GetSimpleEstateList($where: UsersOnEstatesWhereInput2) {
    getSimpleEstateList {
      id
      deceased {
        id
        firstName
        lastName
      }
      users(where: $where) {
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useGetSimpleEstateListQuery__
 *
 * To run a query within a React component, call `useGetSimpleEstateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimpleEstateListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimpleEstateListQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSimpleEstateListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSimpleEstateListQuery,
    GetSimpleEstateListQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<
    GetSimpleEstateListQuery,
    GetSimpleEstateListQueryVariables
  >(GetSimpleEstateListDocument, options);
}
export function useGetSimpleEstateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSimpleEstateListQuery,
    GetSimpleEstateListQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<
    GetSimpleEstateListQuery,
    GetSimpleEstateListQueryVariables
  >(GetSimpleEstateListDocument, options);
}
export type GetSimpleEstateListQueryHookResult = ReturnType<
  typeof useGetSimpleEstateListQuery
>;
export type GetSimpleEstateListLazyQueryHookResult = ReturnType<
  typeof useGetSimpleEstateListLazyQuery
>;
export type GetSimpleEstateListQueryResult = Apollo.QueryResult<
  GetSimpleEstateListQuery,
  GetSimpleEstateListQueryVariables
>;
export const GetUnseenNotificationsCountDocument = gql`
  query GetUnseenNotificationsCount($estateId: String, $userId: String) {
    getUnseenNotificationsCount(estateId: $estateId, userId: $userId)
  }
`;

/**
 * __useGetUnseenNotificationsCountQuery__
 *
 * To run a query within a React component, call `useGetUnseenNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnseenNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnseenNotificationsCountQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUnseenNotificationsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUnseenNotificationsCountQuery,
    GetUnseenNotificationsCountQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<
    GetUnseenNotificationsCountQuery,
    GetUnseenNotificationsCountQueryVariables
  >(GetUnseenNotificationsCountDocument, options);
}
export function useGetUnseenNotificationsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnseenNotificationsCountQuery,
    GetUnseenNotificationsCountQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<
    GetUnseenNotificationsCountQuery,
    GetUnseenNotificationsCountQueryVariables
  >(GetUnseenNotificationsCountDocument, options);
}
export type GetUnseenNotificationsCountQueryHookResult = ReturnType<
  typeof useGetUnseenNotificationsCountQuery
>;
export type GetUnseenNotificationsCountLazyQueryHookResult = ReturnType<
  typeof useGetUnseenNotificationsCountLazyQuery
>;
export type GetUnseenNotificationsCountQueryResult = Apollo.QueryResult<
  GetUnseenNotificationsCountQuery,
  GetUnseenNotificationsCountQueryVariables
>;
export const GetUserDocument = gql`
  query GetUser($where: UserWhereUniqueInput2!) {
    getUser(where: $where) {
      id
      active
      type
      email
      dateOfBirth
      firstName
      lastName
      address
      city
      county
      state
      country
      phone
      partnerId
      ssnLast4
      fullSsn
      createdAt
    }
  }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options,
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options,
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const GetUserActionCardDocument = gql`
  query GetUserActionCard($where: ActionCardWhereInput2, $estateId: String) {
    getUserEstate(estateId: $estateId) {
      actionCards(where: $where) {
        id
        estateId
        componentId
        completedAt
        estateDetailId
        taskId
        type
        status
        name
        description
        encryptedReply
        locked
        assignee
        formFieldName
        appointmentTime
        appointmentLocation
        calendarIcs
        calendarLink
        documentDownloadUrl
        documentUploadUrl
        documentSignUrl
        mapUrl
        createdAt
        updatedAt
        component {
          id
          name
          subType
          type
          accountNumber
          descriptor
        }
        task {
          name
          shortName
        }
        estateDetail {
          id
          name
          updatedAt
          value
        }
      }
    }
  }
`;

/**
 * __useGetUserActionCardQuery__
 *
 * To run a query within a React component, call `useGetUserActionCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserActionCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserActionCardQuery({
 *   variables: {
 *      where: // value for 'where'
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetUserActionCardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserActionCardQuery,
    GetUserActionCardQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<
    GetUserActionCardQuery,
    GetUserActionCardQueryVariables
  >(GetUserActionCardDocument, options);
}
export function useGetUserActionCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserActionCardQuery,
    GetUserActionCardQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<
    GetUserActionCardQuery,
    GetUserActionCardQueryVariables
  >(GetUserActionCardDocument, options);
}
export type GetUserActionCardQueryHookResult = ReturnType<
  typeof useGetUserActionCardQuery
>;
export type GetUserActionCardLazyQueryHookResult = ReturnType<
  typeof useGetUserActionCardLazyQuery
>;
export type GetUserActionCardQueryResult = Apollo.QueryResult<
  GetUserActionCardQuery,
  GetUserActionCardQueryVariables
>;
export const GetUserEstateActionCardsDocument = gql`
  query GetUserEstateActionCards(
    $estateId: String
    $where: ActionCardWhereInput2
    $orderBy: [ActionCardOrderByWithRelationInput2!]
  ) {
    getUserEstate(estateId: $estateId) {
      id
      deceased {
        id
        firstName
        lastName
        podState
        state
        dateOfBirth
        dateOfDeath
      }
      componentBreakDown {
        type
        subType
        sum
        percentage
        count
        total
      }
      actionCards(where: $where, orderBy: $orderBy) {
        id
        estateId
        componentId
        completedAt
        estateDetailId
        type
        status
        name
        description
        encryptedReply
        locked
        assignee
        formFieldName
        appointmentTime
        appointmentLocation
        calendarIcs
        calendarLink
        documentDownloadUrl
        documentUploadUrl
        documentSignUrl
        mapUrl
        createdAt
        updatedAt
        component {
          id
          name
          subType
          type
          accountNumber
          descriptor
        }
        task {
          name
          shortName
        }
        estateDetail {
          id
          name
          updatedAt
          value
        }
      }
    }
  }
`;

/**
 * __useGetUserEstateActionCardsQuery__
 *
 * To run a query within a React component, call `useGetUserEstateActionCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEstateActionCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEstateActionCardsQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetUserEstateActionCardsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserEstateActionCardsQuery,
    GetUserEstateActionCardsQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<
    GetUserEstateActionCardsQuery,
    GetUserEstateActionCardsQueryVariables
  >(GetUserEstateActionCardsDocument, options);
}
export function useGetUserEstateActionCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserEstateActionCardsQuery,
    GetUserEstateActionCardsQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<
    GetUserEstateActionCardsQuery,
    GetUserEstateActionCardsQueryVariables
  >(GetUserEstateActionCardsDocument, options);
}
export type GetUserEstateActionCardsQueryHookResult = ReturnType<
  typeof useGetUserEstateActionCardsQuery
>;
export type GetUserEstateActionCardsLazyQueryHookResult = ReturnType<
  typeof useGetUserEstateActionCardsLazyQuery
>;
export type GetUserEstateActionCardsQueryResult = Apollo.QueryResult<
  GetUserEstateActionCardsQuery,
  GetUserEstateActionCardsQueryVariables
>;
export const GetUserEstateIdDocument = gql`
  query GetUserEstateId($estateId: String) {
    getUserEstate(estateId: $estateId) {
      id
    }
  }
`;

/**
 * __useGetUserEstateIdQuery__
 *
 * To run a query within a React component, call `useGetUserEstateIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEstateIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEstateIdQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetUserEstateIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserEstateIdQuery,
    GetUserEstateIdQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<GetUserEstateIdQuery, GetUserEstateIdQueryVariables>(
    GetUserEstateIdDocument,
    options,
  );
}
export function useGetUserEstateIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserEstateIdQuery,
    GetUserEstateIdQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<
    GetUserEstateIdQuery,
    GetUserEstateIdQueryVariables
  >(GetUserEstateIdDocument, options);
}
export type GetUserEstateIdQueryHookResult = ReturnType<
  typeof useGetUserEstateIdQuery
>;
export type GetUserEstateIdLazyQueryHookResult = ReturnType<
  typeof useGetUserEstateIdLazyQuery
>;
export type GetUserEstateIdQueryResult = Apollo.QueryResult<
  GetUserEstateIdQuery,
  GetUserEstateIdQueryVariables
>;
export const GetUserEstateNotificationsDocument = gql`
  query GetUserEstateNotifications($userId: String, $estateId: String) {
    getUserEstateNotifications(userId: $userId, estateId: $estateId) {
      createdAt
      estateId
      id
      message
      route
      routingError {
        message
      }
      seenAt
      title
      updatedAt
      userId
    }
  }
`;

/**
 * __useGetUserEstateNotificationsQuery__
 *
 * To run a query within a React component, call `useGetUserEstateNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEstateNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEstateNotificationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetUserEstateNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserEstateNotificationsQuery,
    GetUserEstateNotificationsQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<
    GetUserEstateNotificationsQuery,
    GetUserEstateNotificationsQueryVariables
  >(GetUserEstateNotificationsDocument, options);
}
export function useGetUserEstateNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserEstateNotificationsQuery,
    GetUserEstateNotificationsQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<
    GetUserEstateNotificationsQuery,
    GetUserEstateNotificationsQueryVariables
  >(GetUserEstateNotificationsDocument, options);
}
export type GetUserEstateNotificationsQueryHookResult = ReturnType<
  typeof useGetUserEstateNotificationsQuery
>;
export type GetUserEstateNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetUserEstateNotificationsLazyQuery
>;
export type GetUserEstateNotificationsQueryResult = Apollo.QueryResult<
  GetUserEstateNotificationsQuery,
  GetUserEstateNotificationsQueryVariables
>;
export const GetUserEstateDocument = gql`
  query GetUserEstate($estateId: String, $where: UsersOnEstatesWhereInput2) {
    getUserEstate(estateId: $estateId) {
      id
      deceased {
        id
        firstName
        lastName
        podState
        state
        dateOfBirth
        dateOfDeath
      }
      hasProbate
      hasTrust
      hasWill
      estateComponents {
        id
        estateId
        type
        subType
        name
        descriptor
        finalValue
        estimatedValue
        appraisedValue
        loanAmount
        mortgageBank
        accountNumber
        accountHolder
        address
        city
        state
        zip
        country
        contactName
        contactType
        phone
        email
        balance
        lastCheckDate
        inTrust
        createdById
        status
        completedAt
        registrationExpiresAt
        paidOff
        lastPaymentAmount
        lastPaymentDate
        billingPeriod
        charge
      }
      componentBreakDown {
        type
        subType
        sum
        percentage
        count
        total
      }
      users(where: $where) {
        user {
          firstName
          lastName
          id
        }
      }
    }
  }
`;

/**
 * __useGetUserEstateQuery__
 *
 * To run a query within a React component, call `useGetUserEstateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEstateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEstateQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUserEstateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserEstateQuery,
    GetUserEstateQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<GetUserEstateQuery, GetUserEstateQueryVariables>(
    GetUserEstateDocument,
    options,
  );
}
export function useGetUserEstateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserEstateQuery,
    GetUserEstateQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<GetUserEstateQuery, GetUserEstateQueryVariables>(
    GetUserEstateDocument,
    options,
  );
}
export type GetUserEstateQueryHookResult = ReturnType<
  typeof useGetUserEstateQuery
>;
export type GetUserEstateLazyQueryHookResult = ReturnType<
  typeof useGetUserEstateLazyQuery
>;
export type GetUserEstateQueryResult = Apollo.QueryResult<
  GetUserEstateQuery,
  GetUserEstateQueryVariables
>;
export const GetUsersListDocument = gql`
  query GetUsersList(
    $orderBy: [UserOrderByWithRelationInput2!]
    $where: UserWhereInput2
  ) {
    users(orderBy: $orderBy, where: $where) {
      id
      firstName
      lastName
      email
      address
      avatar
    }
  }
`;

/**
 * __useGetUsersListQuery__
 *
 * To run a query within a React component, call `useGetUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersListQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUsersListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUsersListQuery,
    GetUsersListQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<GetUsersListQuery, GetUsersListQueryVariables>(
    GetUsersListDocument,
    options,
  );
}
export function useGetUsersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersListQuery,
    GetUsersListQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<GetUsersListQuery, GetUsersListQueryVariables>(
    GetUsersListDocument,
    options,
  );
}
export type GetUsersListQueryHookResult = ReturnType<
  typeof useGetUsersListQuery
>;
export type GetUsersListLazyQueryHookResult = ReturnType<
  typeof useGetUsersListLazyQuery
>;
export type GetUsersListQueryResult = Apollo.QueryResult<
  GetUsersListQuery,
  GetUsersListQueryVariables
>;
export const IsEulaCurrentDocument = gql`
  query IsEULACurrent {
    isEULAcurrent
  }
`;

/**
 * __useIsEulaCurrentQuery__
 *
 * To run a query within a React component, call `useIsEulaCurrentQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEulaCurrentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEulaCurrentQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsEulaCurrentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IsEulaCurrentQuery,
    IsEulaCurrentQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<IsEulaCurrentQuery, IsEulaCurrentQueryVariables>(
    IsEulaCurrentDocument,
    options,
  );
}
export function useIsEulaCurrentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsEulaCurrentQuery,
    IsEulaCurrentQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<IsEulaCurrentQuery, IsEulaCurrentQueryVariables>(
    IsEulaCurrentDocument,
    options,
  );
}
export type IsEulaCurrentQueryHookResult = ReturnType<
  typeof useIsEulaCurrentQuery
>;
export type IsEulaCurrentLazyQueryHookResult = ReturnType<
  typeof useIsEulaCurrentLazyQuery
>;
export type IsEulaCurrentQueryResult = Apollo.QueryResult<
  IsEulaCurrentQuery,
  IsEulaCurrentQueryVariables
>;
export const ListDeletedDocumentsDocument = gql`
  query ListDeletedDocuments($estateId: String) {
    listDeletedDocuments(estateId: $estateId) {
      id
      isDir
      modDate
      name
      signedUrl
      deleteMarkerId
    }
  }
`;

/**
 * __useListDeletedDocumentsQuery__
 *
 * To run a query within a React component, call `useListDeletedDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDeletedDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDeletedDocumentsQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useListDeletedDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListDeletedDocumentsQuery,
    ListDeletedDocumentsQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<
    ListDeletedDocumentsQuery,
    ListDeletedDocumentsQueryVariables
  >(ListDeletedDocumentsDocument, options);
}
export function useListDeletedDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListDeletedDocumentsQuery,
    ListDeletedDocumentsQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<
    ListDeletedDocumentsQuery,
    ListDeletedDocumentsQueryVariables
  >(ListDeletedDocumentsDocument, options);
}
export type ListDeletedDocumentsQueryHookResult = ReturnType<
  typeof useListDeletedDocumentsQuery
>;
export type ListDeletedDocumentsLazyQueryHookResult = ReturnType<
  typeof useListDeletedDocumentsLazyQuery
>;
export type ListDeletedDocumentsQueryResult = Apollo.QueryResult<
  ListDeletedDocumentsQuery,
  ListDeletedDocumentsQueryVariables
>;
export const ListDocumentsDocument = gql`
  query ListDocuments($estateId: String, $directory: String) {
    listDocuments(estateId: $estateId, directory: $directory) {
      id
      isDir
      modDate
      name
      size
      signedUrl
      uploadedAt
      contentType
    }
  }
`;

/**
 * __useListDocumentsQuery__
 *
 * To run a query within a React component, call `useListDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDocumentsQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *      directory: // value for 'directory'
 *   },
 * });
 */
export function useListDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListDocumentsQuery,
    ListDocumentsQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useQuery<ListDocumentsQuery, ListDocumentsQueryVariables>(
    ListDocumentsDocument,
    options,
  );
}
export function useListDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListDocumentsQuery,
    ListDocumentsQueryVariables
  >,
) {
  const options = {
    ...defaultOptions,
    ...baseOptions, 
  };
  return Apollo.useLazyQuery<ListDocumentsQuery, ListDocumentsQueryVariables>(
    ListDocumentsDocument,
    options,
  );
}
export type ListDocumentsQueryHookResult = ReturnType<
  typeof useListDocumentsQuery
>;
export type ListDocumentsLazyQueryHookResult = ReturnType<
  typeof useListDocumentsLazyQuery
>;
export type ListDocumentsQueryResult = Apollo.QueryResult<
  ListDocumentsQuery,
  ListDocumentsQueryVariables
>;
