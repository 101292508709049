import { gql } from '@apollo/client';

export const GET_USER_ESTATE_QUERY = gql`
query GetUserEstate($estateId: String, $where: UsersOnEstatesWhereInput2) {
  getUserEstate(estateId: $estateId) {
    id
    deceased {
      id
      firstName
      lastName
      podState
      state
      dateOfBirth
      dateOfDeath
    }
    hasProbate
    hasTrust
    hasWill
    estateComponents {
      id
      estateId
      type
      subType
      name
      descriptor
      finalValue
      estimatedValue
      appraisedValue
      loanAmount
      mortgageBank
      accountNumber
      accountHolder
      address
      city
      state
      zip
      country
      contactName
      contactType
      phone
      email
      balance
      lastCheckDate
      inTrust
      createdById
      status
      completedAt
      registrationExpiresAt
      paidOff
      lastPaymentAmount
      lastPaymentDate
      billingPeriod
      charge
    }
    componentBreakDown {
      type
      subType
      sum
      percentage
      count
      total
    }
    users(where: $where) {
      user {
        firstName
        lastName
        id
      }
    }
  }
}
`;

